import { format } from 'date-fns'
import { every, isEmpty, isNumber, omitBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useUIContext } from '../../../common/contexts/UIContext'
import { selectAction } from '../../../common/events'
import {
  DataRecord,
  RecurrenceFrequency,
  TickleableRecord,
} from '@eleventhlabs/capture-shared'
import {
  convertISOtoDisplay,
  emptyFn,
  getDateModelDescription,
  immediateExecute,
  nlpInputToDateProp,
  tokenFormatISO,
} from '../../../common/utils'
import {
  DatePickerQuickDate,
  InputHandlers,
  RecordDateModel,
  RecurrenceModel,
  ReminderModel,
} from '../Schedule.types'

import {
  getNoDateModel,
  getQuickDateIndex,
  getQuickDateModel,
  isNoDate,
  isQuickDate,
  useQuickDates,
} from './useQuickDates'
import { useStartOfTodayISO } from '../../../common/hooks/useStartOfTodayISO'
import { Theme } from '../../../common/stationary'

export interface ScheduleHandlersData extends InputHandlers {
  quickDates: DatePickerQuickDate[]
  selectedIndex?: number
  inputValue: string
  validInput?: boolean
  dateModel: RecordDateModel
  previewModel?: RecordDateModel
  hadInitialRecurrenceOrTime: boolean
  onSchedule: (event: KeyboardEvent) => void
  onShortcutMouseEnter: (index: number) => void
  onShortcutMouseLeave: (index: number) => void
  setDate: (isoDate: string | null) => void
  setTime: (time: string | null) => void
  setReminder: (minutesBefore: number | null) => void
  setRecurrence: (recurrence: RecurrenceModel | null) => void
}

export const useScheduleHandlers = (
  theme: Theme,
  records: DataRecord[],
  onSelect: (
    isoDate: string | null,
    isoTime?: string | null,
    recurrence?: RecurrenceModel | null,
    reminder?: ReminderModel | null,
  ) => void,
  close: () => void = emptyFn,
): ScheduleHandlersData => {
  const initialDateModel: RecordDateModel = getCommonDateModel(
    records as TickleableRecord[],
  )
  const [editedDateModel, setEditedDateModel] = useState<RecordDateModel>({})
  const currentDate = editedDateModel.isoDate ?? initialDateModel.isoDate
  const validInput = !isEmpty(editedDateModel) && currentDate !== undefined

  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [hoveredIndex, setHoveredIndex] = useState<number>()
  const [inputValue, setInputValue] = useState(
    getDateModelDescription(initialDateModel.isoDate),
  )

  const { isMouseRecentlyActive } = useUIContext()

  const onSubmit = useCallback(
    (payload: RecordDateModel = {}) => {
      const editedModel = {
        ...editedDateModel,
        ...payload,
      }
      const isoDate =
        editedModel.isoDate !== undefined
          ? editedModel.isoDate
          : initialDateModel.isoDate
      if (isoDate === undefined) return
      onSelect(
        isoDate,
        editedModel.isoLocalTime,
        editedModel.recurrence,
        editedModel.reminder,
      )
      close()
    },
    [initialDateModel, editedDateModel, onSelect, close],
  )

  const handleChange = useCallback(
    (value: string) => {
      setInputValue(value)
      let translatedInputModel
      if (isNoDate(value)) {
        translatedInputModel = getNoDateModel()
      } else {
        const {
          isoDate: jsDateValue,
          recurrence,
          isoLocalTime,
          reminder,
        } = nlpInputToDateProp(value ?? ``)
        const isoDate = jsDateValue
          ? format(jsDateValue, tokenFormatISO)
          : undefined
        translatedInputModel = {
          isoDate,
          recurrence,
          isoLocalTime,
          reminder,
        }
      }
      if (isQuickDate(translatedInputModel.isoDate))
        setSelectedIndex(getQuickDateIndex(translatedInputModel.isoDate))
      setEditedDateModel(
        omitBy(
          {
            ...editedDateModel,
            ...translatedInputModel,
          },
          (v) => v === undefined,
        ),
      )
    },
    [editedDateModel],
  )

  // purpose: set calculated state on load based on initial input
  useEffect(() => {
    handleChange(inputValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const quickDates = useQuickDates(theme, onSubmit)

  const onShortcutMouseEnter = useCallback(
    (index: number) => {
      if (!isMouseRecentlyActive) return
      setHoveredIndex(index)
    },
    [isMouseRecentlyActive],
  )

  const onShortcutMouseLeave = useCallback(
    (index: number) => {
      if (index !== hoveredIndex) return
      setHoveredIndex(undefined)
    },
    [hoveredIndex],
  )

  const updateInputValue = useCallback(
    (value: string) => {
      setInputValue(value)
      handleChange(value)
    },
    [handleChange],
  )

  const onDownArrow = useCallback(() => {
    const newIndex = Math.min((selectedIndex ?? -1) + 1, quickDates.length - 1)
    setSelectedIndex(newIndex)
    updateInputValue(quickDates[newIndex].nlpInputText)
  }, [selectedIndex, quickDates, updateInputValue])

  const onUpArrow = useCallback(() => {
    if (selectedIndex === undefined) return
    const newIndex = selectedIndex === 0 ? undefined : selectedIndex - 1
    setSelectedIndex(newIndex)
    updateInputValue(
      newIndex != undefined ? quickDates[newIndex].nlpInputText : ``,
    )
  }, [selectedIndex, quickDates, updateInputValue])

  const onEnter = useCallback(() => {
    if (selectedIndex == undefined) return
    quickDates[selectedIndex].onClick()
    close()
  }, [selectedIndex, quickDates, close])

  const onSchedule = close
  const onEsc = close
  const onComplete = emptyFn
  const onMove = emptyFn

  const handleSearchInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case `Tab`:
          event.preventDefault()
          break
        case `ArrowDown`: {
          if (event.shiftKey) return
          event.preventDefault()
          onDownArrow()
          immediateExecute(() => selectAction({ target: `dialog-input` }))
          break
        }
        case `ArrowUp`: {
          if (event.shiftKey) return
          event.preventDefault()
          onUpArrow()
          immediateExecute(() => selectAction({ target: `dialog-input` }))
          break
        }
        case `Enter`: {
          event.preventDefault()
          if (selectedIndex !== undefined) {
            quickDates[selectedIndex].onClick()
            close()
            return
          }
          if (!isEmpty(editedDateModel)) {
            onSubmit()
            close()
            return
          }
          onEnter()
          break
        }
        case `Escape`:
          onEsc()
          break
        default:
      }
    },
    [
      quickDates,
      selectedIndex,
      editedDateModel,
      onDownArrow,
      onUpArrow,
      onEsc,
      onSubmit,
      onEnter,
      close,
    ],
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = (event.target as any)?.value
      setSelectedIndex(undefined)
      handleChange(value ?? ``)
    },
    [handleChange],
  )

  const dateModel = useMemo(
    () =>
      computeVisualDateModel(
        initialDateModel,
        editedDateModel,
        hoveredIndex !== undefined
          ? getQuickDateModel(quickDates[hoveredIndex].nlpInputText)
          : selectedIndex !== undefined
          ? getQuickDateModel(quickDates[selectedIndex].nlpInputText)
          : {},
      ),
    [
      initialDateModel,
      editedDateModel,
      quickDates,
      selectedIndex,
      hoveredIndex,
    ],
  )

  const previewModel = useMemo(
    () =>
      hoveredIndex !== undefined
        ? computeVisualDateModel(initialDateModel, editedDateModel, {})
        : undefined,
    [initialDateModel, editedDateModel, hoveredIndex],
  )

  const setDate = useCallback(
    (isoDate: string | null) => {
      setSelectedIndex(undefined)
      if (isoDate === null) setEditedDateModel({})
      else setEditedDateModel((model) => ({ ...model, isoDate }))
      immediateExecute(() => selectAction({ target: `dialog-input` }))
      updateInputValue(getDateModelDescription(isoDate))
    },
    [updateInputValue],
  )

  const todayIso = useStartOfTodayISO().today
  const setTime = useCallback(
    (time: string | null) => {
      const currentDate = editedDateModel.isoDate ?? initialDateModel.isoDate
      const shouldAddToday = time != undefined && !currentDate
      const shouldCreateReminder =
        time &&
        ((initialDateModel.reminder && editedDateModel.reminder === null) ||
          (!initialDateModel.reminder && !editedDateModel.reminder))
      setEditedDateModel((model) => {
        const newModel: RecordDateModel = {
          ...model,
          isoLocalTime: time,
        }
        if (shouldAddToday) newModel.isoDate = todayIso
        if (time === null) newModel.reminder = null
        if (shouldCreateReminder) newModel.reminder = { minutesBefore: 0 }
        return newModel
      })
      if (shouldAddToday && time != null) {
        setInputValue(`Today at ${convertISOtoDisplay(time)}`)
        immediateExecute(() => selectAction({ target: `dialog-input` }))
      }
    },
    [editedDateModel, initialDateModel, todayIso],
  )

  const setReminder = useCallback(
    (minutesBefore: number | null) =>
      setEditedDateModel((model) => ({
        ...model,
        reminder: minutesBefore ? ({ minutesBefore } as any) : null,
      })),
    [],
  )

  const setRecurrence = useCallback(
    (recurrence: RecurrenceModel | null) =>
      setEditedDateModel((model) => ({ ...model, recurrence })),
    [],
  )

  const handlers = useMemo(
    () => ({
      onDownArrow,
      onUpArrow,
      onEnter,
      onSchedule,
      onComplete,
      onMove,
      onEsc,
      onChange,
      onSubmit,
      handleSearchInputKeyDown,
      onShortcutMouseEnter,
      onShortcutMouseLeave,
      quickDates,
      selectedIndex: hoveredIndex ?? selectedIndex,
      hadInitialRecurrenceOrTime: !!(
        initialDateModel.isoLocalTime || initialDateModel.recurrence
      ),
      inputValue:
        hoveredIndex !== undefined
          ? quickDates[hoveredIndex].nlpInputText
          : inputValue,
      validInput,
      dateModel,
      previewModel,
      setDate,
      setTime,
      setReminder,
      setRecurrence,
    }),
    [
      onDownArrow,
      onUpArrow,
      onEnter,
      onSchedule,
      onComplete,
      onMove,
      onEsc,
      onChange,
      onSubmit,
      handleSearchInputKeyDown,
      onShortcutMouseEnter,
      onShortcutMouseLeave,
      quickDates,
      hoveredIndex,
      selectedIndex,
      inputValue,
      validInput,
      dateModel,
      previewModel,
      initialDateModel.isoLocalTime,
      initialDateModel.recurrence,
      setDate,
      setTime,
      setReminder,
      setRecurrence,
    ],
  )

  return handlers
}

function getCommonDateModel(records: TickleableRecord[]): RecordDateModel {
  const model: RecordDateModel = {}
  if (isEmpty(records)) return model
  const firstDate = records[0].tickler?.isoDate
  if (
    firstDate &&
    every(records, (record) => record.tickler?.isoDate === firstDate)
  )
    model.isoDate = firstDate
  const firstTime = records[0].tickler?.isoLocalTime
  if (
    firstTime &&
    every(records, (record) => record.tickler?.isoLocalTime === firstTime)
  )
    model.isoLocalTime = firstTime
  const firstRecurrence = records[0].tickler?.recurrence
  if (
    firstRecurrence &&
    every(records, (record) =>
      isSameRecurrence(firstRecurrence, record.tickler?.recurrence),
    )
  )
    model.recurrence = firstRecurrence
  const firstReminder = records[0].tickler?.reminderId
    ? records[0].reminders[records[0].tickler?.reminderId]
    : undefined
  if (
    firstReminder &&
    isNumber(firstReminder?.minutesBefore) &&
    every(records, (record) =>
      record.tickler?.reminderId
        ? record.reminders[record.tickler?.reminderId].minutesBefore ===
          firstReminder.minutesBefore
        : false,
    )
  )
    model.reminder = { minutesBefore: firstReminder.minutesBefore }

  return model
}

function isSameRecurrence(
  recurrenceA: RecurrenceModel | undefined,
  recurrenceB: RecurrenceModel | undefined,
) {
  return (
    recurrenceA?.frequency === recurrenceB?.frequency ||
    (recurrenceA?.interval === recurrenceB?.interval &&
      ((recurrenceA?.frequency === RecurrenceFrequency.Weekly &&
        recurrenceA?.byDayOfWeek === recurrenceB?.byDayOfWeek) ||
        (recurrenceA?.frequency === RecurrenceFrequency.Monthly &&
          recurrenceA?.byDayOfWeek[0] === recurrenceB?.byDayOfWeek[0] &&
          recurrenceA?.byDayOfWeek[1] === recurrenceB?.byDayOfWeek[1]) ||
        (recurrenceA?.frequency === RecurrenceFrequency.Yearly &&
          recurrenceA?.byDayOfWeek[0] === recurrenceB?.byDayOfWeek[0] &&
          recurrenceA?.byDayOfWeek[1] === recurrenceB?.byDayOfWeek[1])))
  )
}

function computeVisualDateModel(
  initialDateModel: RecordDateModel,
  editedDateModel: RecordDateModel,
  quickDateModel: RecordDateModel,
): RecordDateModel {
  const base: RecordDateModel = {
    ...initialDateModel,
    ...editedDateModel,
    ...quickDateModel,
  }
  return base
}
