import React from 'react'

import { UIContextValue, useUIContext } from '../../common/contexts/UIContext'

export interface WithUIContext extends UIContextValue {}

export function withUIContext<T>(
  WrappedComponent: React.FC<T & WithUIContext>,
): React.FC<T> {
  const ComponentWithUIContext = (props: T) => {
    const contextValue = useUIContext()

    return <WrappedComponent {...props} {...contextValue} />
  }

  ComponentWithUIContext.displayName = `ComponentWithUIContext`

  return ComponentWithUIContext
}
