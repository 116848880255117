import { useEffect } from 'react'
import {
  AppFocusState,
  registerAppStateChangeCallback,
  unregisterAppStateChangeCallback,
} from './appStateChangeManager'

/**
 * Registers a callback function to be executed whenever the app is in the foreground.
 *
 * IMPORTANT: Callers must ensure that the signature of the callback function does not change often.
 * Frequent changes in the function signature will lead to the function being registered and unregistered a lot,
 * which can lead to performance issues.
 *
 * @param {Function} fn - The callback function to be executed.
 * @param {Object} options - An object containing the options for the execution of the callback.
 * @param {boolean} options.executeImmediately - If true, the callback function will be executed immediately if the app is currently in the foreground.
 * @param {boolean} options.runOnce - If true, the callback function will be cleaned up after the first execution.
 *
 */
export const useExecuteOnForeground = (
  fn: () => void | Promise<void>,
  {
    executeImmediately = false,
    runOnce = false,
  }: { executeImmediately: boolean; runOnce: boolean },
) => {
  useEffect(() => {
    registerAppStateChangeCallback(fn, {
      executeImmediately,
      runOnce,
      executeWhen: AppFocusState.FOREGROUND,
    })
    return () => {
      unregisterAppStateChangeCallback(fn)
    }
  }, [fn, executeImmediately, runOnce])
}
