import { useCallback } from 'react'

import { UpdateRecordPayload } from '@eleventhlabs/capture-shared'
import { ShowAnnotationsToggleTriggerValueType } from '../../common/analytics/capture-analytics-actions'
import { useUIContext } from '../../common/contexts/UIContext'
import { RecordItemData } from '../../common/data/recordGroups'
import { EditorsManager } from '../../common/EditorsManager'
import { BaseSelection } from '../../common/libs/selection'
import { NoteHandlers } from '../../common/noteEventHandlers'
import {
  RecordItemHandlers,
  useFullScreenRecordEventHandlers,
} from '../../common/recordItemEventHandlers'
import { PropertiesListEventHandlers } from '../../common/recordItemEventHandlers/propertiesListEventHandlers'

interface UseFullScreenEventHandlers {
  shouldDisplayNotes: boolean
  toggleNotesVisibilityForRecordIds: (
    recordIds: string[],
    trigger: ShowAnnotationsToggleTriggerValueType,
  ) => boolean
  recordHandlers: RecordItemHandlers
  noteHandlers: NoteHandlers
  propertiesListEventHandlers: PropertiesListEventHandlers
  onClickBelowRecord: () => void
}

export const useFullScreenEventHandlers = (
  record: RecordItemData,
  selection: BaseSelection,
  updateRecord: (recordId: string, payload: UpdateRecordPayload) => void,
  skipOccurrence: (recordId: string) => void,
): UseFullScreenEventHandlers => {
  const recordId = record.id

  const { shouldDisplayNotesForRecordId, toggleNotesVisibilityForRecordIds } =
    useUIContext()

  const onClickBelowRecord = useCallback(() => {
    EditorsManager.effects.focus({
      id: recordId,
      position: `endAndAddNewLineIfNotEmptyLast`,
    })
  }, [recordId])

  const [recordHandlers, noteHandlers, propertiesListEventHandlers] =
    useFullScreenRecordEventHandlers(
      record,
      selection,
      updateRecord,
      skipOccurrence,
    )

  return {
    shouldDisplayNotes: shouldDisplayNotesForRecordId(recordId),
    toggleNotesVisibilityForRecordIds,
    recordHandlers,
    noteHandlers,
    propertiesListEventHandlers,
    onClickBelowRecord,
  }
}
