import * as Analytics from '../../../analytics/capture-analytics-actions'
import { useActiveScreen } from '../../../analytics/capture-analytics-web'
import { useDerivedStateContext } from '../../../contexts/DerivedStateContext'
import { useDialogContext } from '../../../contexts/DialogContext'
import { useActiveRecordIds } from '../../../hooks/useActiveRecordIds'
import { ExecActionFunction } from '../../Action.type'

export type GlobalDialogActionDefs =
  | OpenCalendarDialogActionDef
  | OpenCommandBarActionDef
  | OpenGoToScreenActionDef
  | OpenMoveToDialogActionDef
  | OpenScheduleDialogActionDef

export const useExecGlobalDialogAction = () => {
  const activeScreen = useActiveScreen()
  const {
    openScheduleDialog,
    openSendToDialog,
    openCalendarDialog,
    openQuickSwitcherDialog,
    openCommandBarDialog,
  } = useDialogContext()
  const { visibleItemIds } = useDerivedStateContext()
  const { activeRecordIfSingle } = useActiveRecordIds(visibleItemIds)

  // @ts-ignore
  const execGlobalDialogAction: ExecActionFunction<GlobalDialogActionDefs> = (
    action,
  ) => {
    switch (action.type) {
      case `OPEN_CALENDAR_DIALOG`: {
        return openCalendarDialog()
      }
      case `OPEN_COMMAND_BAR`: {
        Analytics.commandBarFlowStarted({
          activeScreen,
          commandBarFlowStartedTrigger: action.trigger,
        })
        return openCommandBarDialog()
      }
      case `OPEN_GO_TO_DIALOG`: {
        Analytics.quickSwitcherFlowStarted({
          activeScreen,
          quickSwitcherFlowStartedTrigger: action.trigger,
        })
        return openQuickSwitcherDialog()
      }
      case `OPEN_MOVE_TO_DIALOG`: {
        Analytics.moveToPickerFlowStarted({
          activeScreen,
          moveToPickerFlowStartedTrigger: action.trigger,
        })
        return openSendToDialog(
          activeRecordIfSingle(action.recordId),
          action.shouldHideSelectionActionBar,
        )
      }
      case `OPEN_SCHEDULE_DIALOG`: {
        Analytics.datePickerFlowStarted({
          activeScreen,
          datePickerFlowStartedTrigger: action.trigger,
        })
        return openScheduleDialog(
          action.recordId,
          action.shouldHideSelectionActionBar,
        )
      }
      default: {
        throw new Error('Unexpected Global Dialog action type')
      }
    }
  }
  return execGlobalDialogAction
}

type OpenCalendarDialogActionDef = {
  type: 'OPEN_CALENDAR_DIALOG'
  payload: {}
  returnType: void
}

type OpenCommandBarActionDef = {
  type: 'OPEN_COMMAND_BAR'
  payload: {
    trigger: Analytics.CommandBarFlowStartedTriggerValueType
  }
  returnType: void
}

type OpenGoToScreenActionDef = {
  type: 'OPEN_GO_TO_DIALOG'
  payload: {
    trigger: Analytics.QuickSwitcherFlowStartedTriggerValueType
  }
  returnType: void
}

type OpenMoveToDialogActionDef = {
  type: 'OPEN_MOVE_TO_DIALOG'
  payload: {
    recordId?: string
    trigger: Analytics.MoveToPickerFlowStartedTriggerValueType
    shouldHideSelectionActionBar?: boolean
  }
  returnType: void
}

type OpenScheduleDialogActionDef = {
  type: 'OPEN_SCHEDULE_DIALOG'
  payload: {
    recordId?: string
    trigger: Analytics.DatePickerFlowStartedTriggerValueType
    shouldHideSelectionActionBar?: boolean
  }
  returnType: number
}
