import { filter, flatMap, forEach, isEmpty } from 'lodash'

import {
  DataRecord,
  isNoteableRecord,
  NoteableRecord,
  NoteRecord,
  RichText,
} from '@eleventhlabs/capture-shared'

import { EditorsManager } from '../../../../EditorsManager'

export const closeAnnotations = (
  records: Record<string, DataRecord>,
  recordIds: string[],
  setNotesVisibilityForRecordIds: (ids: string[], visibility: boolean) => void,
) => {
  if (recordIds.length === 0) {
    return {
      notesClosed: 0,
    }
  }

  const selectedRecords = recordIds.map((id) => records[id])
  const recordsWithNotes = selectedRecords.filter(
    (record) => isNoteableRecord(record) && !isEmpty(record.notes),
  ) as NoteableRecord[]

  const recordsWithNotesId = recordsWithNotes.map((record) => record.id)
  setNotesVisibilityForRecordIds(recordsWithNotesId, false)

  const notesClosed = recordsWithNotesId.length
  recordsWithNotes.forEach((record) =>
    forEach(record.notes, (noteId) => {
      EditorsManager.effects.blur(noteId)
    }),
  )

  return {
    notesClosed,
  }
}
