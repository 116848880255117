import { WriteTransaction } from 'replicache'

import { RCMutation } from '@eleventhlabs/capture-shared'

import { removeFromChildrenPropOfParents, updateRecord } from './utils'

export const softDeleteRecords = async (
  tx: WriteTransaction,
  { recordIds, softDeletedAt }: RCMutation.SoftDeleteRecordArgs,
) => {
  for (const recordId of recordIds) {
    await softDeleteRecord(tx, recordId, softDeletedAt)
  }
}

const softDeleteRecord = async (
  tx: WriteTransaction,
  recordId: string,
  softDeletedAt: number,
) => {
  await updateRecord(tx, recordId, {
    isSoftDeleted: true,
    softDeletedAt,
  })

  await removeFromChildrenPropOfParents(tx, [recordId])
}
