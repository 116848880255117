import { CURRENT_APP_VERSION, Env } from '../env'
import { getSessionJwt } from '../utils/auth/stytch'

const appendClientVersion = (url: string): string => {
  let urlObj = new URL(url)

  // Create params object from search params
  let params = new URLSearchParams(urlObj.search)

  // Delete client_version param if it already exists
  params.delete('cv')

  // Prepend the client_version
  params = new URLSearchParams(`cv=${CURRENT_APP_VERSION}&${params.toString()}`)

  // Replace existing search params with new ones
  urlObj.search = params.toString()

  return urlObj.toString()
}

/**
 * Make an API call. Use this function whenever we need to make an API call in the app. It
 * performs some side effects to modify the request before it goes out.
 *
 * @param url URL to call
 * @param options
 * @returns
 */
export const apiCall = async (
  url: string,
  options?: RequestInit,
): Promise<Response> => {
  const finalUrl = appendClientVersion(url)

  const sendOptions = {
    ...options,
    headers: buildAPIRequestHeaders(options?.headers),
  }

  return fetch(finalUrl, sendOptions)
}

export const buildAPIRequestHeaders = (incomingHeaders?: HeadersInit) => {
  let headers = incomingHeaders || {}

  //@ts-ignore
  if (!Env.isSSR && window.todesktop) {
    headers = { ...headers, 'x-capture-todesktop': 'true' }
  }

  return { ...headers, authorization: `Bearer ${getSessionJwt() || ''}` }
}

export const isSuccessStatusCode = (statusCode: number) => {
  return statusCode >= 200 && statusCode < 300
}
