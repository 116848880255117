import {
  Frack,
  ItemRecord,
  ListRecord,
  RecordDict,
  splitByCompleted,
} from '@eleventhlabs/capture-shared'
import { createNoteListData, getNotesForRecord } from '../../notes'
import { RecordItemData } from '../types'
import { RecordViewType } from './RecordViewType'
import { getTagListForRecord } from './tags'

export interface ListRecordsData {
  id: string
  records: RecordItemData[]
  completedRecords: RecordItemData[]
}

export const getListRecords = (
  records: RecordDict,
  listId: string,
): ListRecordsData => {
  const [recordIds, completedRecordIds] = splitByCompleted(
    records,
    getRecordIdsForListId(records, listId),
  )

  return {
    id: listId,
    records: recordIds.map((id: string) => ({
      ...(records[id] as ItemRecord),
      tags: getTagListForRecord(records, id, RecordViewType.SectionGroup),
      notesList: createNoteListData(getNotesForRecord(records, id)),
    })),
    completedRecords: completedRecordIds.map((id: string) => ({
      ...(records[id] as ItemRecord),
      tags: getTagListForRecord(records, id, RecordViewType.SectionGroup),
      notesList: createNoteListData(getNotesForRecord(records, id)),
    })),
  }
}

const getRecordIdsForListId = (records: RecordDict, listId: string) => {
  return Frack.toArray(
    (records[listId] as ListRecord | undefined)?.children ?? {},
  )
}
