import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import { DropEvent, FileRejection } from 'react-dropzone'

import Analytics, {
  AnnotationCreatedTriggerValueType,
  ShowAnnotationsToggleTriggerValueType,
} from '../../common/analytics/capture-analytics-actions'
import { useExecContext } from '../../common/contexts/ExecContext'
import { useUIContext } from '../../common/contexts/UIContext'
import { DialogType } from '../../common/dialogs/types'
import { BaseSelection } from '../../common/libs/selection'
import { NoteHandlers } from '../../common/noteEventHandlers'
import {
  MiniBarEventHandlers,
  RecordItemHandlers,
  useRecordItemEventHandlers,
} from '../../common/recordItemEventHandlers'

interface UseScreenEventHandlers {
  shouldDisplayNotesForRecordId: (recordId: string) => boolean
  toggleNotesVisibilityForRecordIds: (
    recordIds: string[],
    trigger: ShowAnnotationsToggleTriggerValueType,
  ) => boolean
  addNoteAtBottomOfList: (
    recordId: string,
    triggerCreatedAnnotation: AnnotationCreatedTriggerValueType,
    triggerShowAnnotation: ShowAnnotationsToggleTriggerValueType,
  ) => void
  reschedule: (event: unknown, ids: string[]) => void
  onFileDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
    groupId?: string,
    index?: number,
  ) => void
  recordHandlers: RecordItemHandlers
  noteHandlers: NoteHandlers
  miniBarHandlers: MiniBarEventHandlers
}

export const useScreenEventHandlers = (
  selection: BaseSelection,
): UseScreenEventHandlers => {
  const { exec } = useExecContext()

  const { shouldDisplayNotesForRecordId, toggleNotesVisibilityForRecordIds } =
    useUIContext()

  const _addNoteAtBottomOfList = useCallback(
    (
      recordId: string,
      createAnnotationTrigger: AnnotationCreatedTriggerValueType,
      showAnnotationsTrigger: ShowAnnotationsToggleTriggerValueType,
    ) => {
      exec({
        type: 'ADD_ANNOTATION',
        recordId,
        createAnnotationTrigger,
        showAnnotationsTrigger,
      })
    },
    [exec],
  )

  const onFileDrop: UseScreenEventHandlers['onFileDrop'] = useCallback(
    (acceptedFiles, fileRejections, event, groupId, index) => {
      exec({
        type: 'CREATE_FILE_RECORD_AT_START_OF_GROUP',
        files: acceptedFiles,
        groupId,
        index,
        trigger: Analytics.RecordCreatedTrigger.FILE_DROP,
      })
    },
    [exec],
  )

  const { showView } = useUIContext()
  const reschedule = useCallback(
    (_event: unknown, ids: string[]) => {
      if (isEmpty(ids)) return
      selection.set(ids)
      showView(DialogType.Schedule)
    },
    [selection, showView],
  )

  const [recordHandlers, noteHandlers, miniBarHandlers] =
    useRecordItemEventHandlers(selection)

  return {
    shouldDisplayNotesForRecordId,
    toggleNotesVisibilityForRecordIds,
    addNoteAtBottomOfList: _addNoteAtBottomOfList,
    reschedule,
    onFileDrop,
    recordHandlers,
    noteHandlers,
    miniBarHandlers,
  }
}
