import {
  DefaultKeyMappedActions,
  Key as SharedKey,
  latestKeyboardShortcuts,
} from '@eleventhlabs/capture-shared'
import { invert, isEmpty, mapValues } from 'lodash'
import { useMemo } from 'react'
import { useModelStoreContext } from '../contexts/ModelStoreContext'
import { ClientKey, Key } from '../hooks/useKeys'

export const useKeyMappedActions = () => {
  const { store } = useModelStoreContext()

  const user = store.user

  const keyboardShortcuts = user.data.keyboardShortcuts

  // If keyboard shortcuts don't exist for some reason, default to the latest
  if (!keyboardShortcuts) return latestKeyboardShortcuts

  const computedKeyboardShortcuts =
    keyboardShortcuts.currentComputedKeyboardShortcuts

  // If keyboard shortcuts don't exist for some reason, default to the latest
  if (isEmpty(computedKeyboardShortcuts)) return latestKeyboardShortcuts

  return useMemo(
    () =>
      updateKeyboardShortcutsWithPlatformSpecificKeys(
        computedKeyboardShortcuts as DefaultKeyMappedActions,
      ),
    [computedKeyboardShortcuts],
  ) as DefaultKeyMappedActions
}

const updateKeyboardShortcutsWithPlatformSpecificKeys = (
  keyboardShortcuts: DefaultKeyMappedActions,
): DefaultKeyMappedActions => {
  const macLikeSharedKeyValueToName = invert(SharedKey) as Record<
    string,
    keyof ClientKey
  >

  const updatedKeyMappedShortcuts = mapValues(keyboardShortcuts, (action) => {
    return {
      keys: action.keys.map((key) => {
        // Use the macLike inverse key mapped to get the correct key
        const mappedKey = macLikeSharedKeyValueToName[key]
        if (mappedKey) {
          return Key[mappedKey as keyof ClientKey]
        }
        return key // Return the original key if no mapping is found
      }),
    }
  })

  return updatedKeyMappedShortcuts as DefaultKeyMappedActions
}
