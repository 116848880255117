type ParamDictionary = Record<string, string | number>
import { URLMetadata } from '@eleventhlabs/capture-shared'
import { API } from '../api'

export function withSearchParams(url: string, params: ParamDictionary): string {
  const urlObj = new URL(url)

  Object.keys(params).forEach((key) => {
    urlObj.searchParams.append(key, params[key].toString())
  })

  return urlObj.toString()
}

export const getUrlMetadata = async (url: string): Promise<URLMetadata> => {
  return await API.getUrlMetadata(url).then((data) => ({
    title: data?.title,
    description: data?.description,
    domain: data?.displayUrl,
    favicon: data?.logo,
    thumbnail: data?.image,
  }))
}
