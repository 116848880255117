import React from 'react'

import Analytics from '../../common/analytics/capture-analytics-actions'
import useScreenLoadedAnalytics from '../../common/analytics/helpers/hooks/useScreenLoadedAnalytics'
import { RecordItemData } from '../../common/data/recordGroups'
import { NoteHandlers } from '../../common/noteEventHandlers'
import { RecordItemHandlers } from '../../common/recordItemEventHandlers'
import { PropertiesListEventHandlers } from '../../common/recordItemEventHandlers/propertiesListEventHandlers'
import { styled } from '../../common/stationary'
import { FullScreenRecordItem } from '../../components/FullScreenRecordItem'
import Header from '../../components/Header'
import { withRecordItemEventHandlers } from '../../components/RecordGroup/withRecordItemEventHandlers'
import { withRecordDetailsHotKeys } from '../enhancers/withRecordDetailsHotKeys'
import { withRecordDetailsScreen } from '../enhancers/withRecordDetailsScreen'
import DetailsHeader from './components/DetailsHeader'
import { isMobile } from '../../common/utils/env'

export interface DetailsFullScreenProps {
  recordId: string
  renderSidebar?: () => React.ReactNode
}

export interface DetailsScreenProps extends DetailsFullScreenProps {
  className?: string
  record: RecordItemData
  noteHandlers: NoteHandlers
  propertiesListEventHandlers: PropertiesListEventHandlers
  recordHandlers: RecordItemHandlers
  shouldDisplayNotes: boolean
  isCompleted: boolean
  isMouseRecentlyActive: boolean
  renderHeader: () => React.ReactNode
  onClickBelowRecord: () => void
  onTouchStart: (event: React.TouchEvent) => void
  onTouchMove: (event: React.TouchEvent) => void
}

const FullScreenRecordItemWithHandlers =
  withRecordItemEventHandlers(FullScreenRecordItem)

const DetailsScreen = ({
  className,
  record,
  noteHandlers,
  propertiesListEventHandlers,
  recordHandlers,
  shouldDisplayNotes,
  isMouseRecentlyActive,
  onClickBelowRecord,
  onTouchMove,
  onTouchStart,
}: DetailsScreenProps) => {
  useScreenLoadedAnalytics(Analytics.ScreenType.FULL_SCREEN, record.id)

  return (
    <S.Container onTouchStart={onTouchStart} onTouchMove={onTouchMove}>
      <Header />
      <S.Details className={className}>
        <DetailsHeader
          record={record}
          tags={record.tags}
          onTagClick={recordHandlers.onTagClick}
          skipOccurrence={recordHandlers.skipOccurrence}
          isCompleted={record.isCompleted}
          notesOpen={shouldDisplayNotes}
          propertiesListEventHandlers={propertiesListEventHandlers}
        />
        <S.Content>
          <FullScreenRecordItemWithHandlers
            record={record}
            isMouseActive={isMouseRecentlyActive}
            {...recordHandlers}
            noteHandlers={noteHandlers}
            shouldDisplayNotes={shouldDisplayNotes}
          />
        </S.Content>
        <S.Bottom
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClick={onClickBelowRecord}
        />
      </S.Details>
    </S.Container>
  )
}

export default withRecordDetailsScreen(withRecordDetailsHotKeys(DetailsScreen))

const S = {
  Container: styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: `relative`,
    minHeight: '100vh',
    minWidth: isMobile ? '100vw' : '',
    margin: '0 4px',
    padding: '56px 20px 0', // HeaderDimension.height
    boxShadow: theme.effects.boxShadow.elevation[3],
    background: theme.colors.surface.base,
  })),
  Details: styled.div({
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    maxWidth: 832,
  }),
  Content: styled.div({
    position: `relative`,
  }),
  Bottom: styled.div({
    display: `flex`,
    flex: `1 0 256px`,
    position: `relative`,
    minHeight: `256px`,
  }),
}
