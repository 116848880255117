import {
  Frack,
  InboxRecord,
  RecordDict,
  TextRecord,
  splitByCompleted,
} from '@eleventhlabs/capture-shared'
import { createNoteListData, getNotesForRecord } from '../../notes'
import { RecordItemData } from '../types'
import { RecordViewType } from './RecordViewType'
import { getTagListForRecord } from './tags'

export interface InboxRecordsData {
  records: RecordItemData[]
  completedRecords: RecordItemData[]
}

export const getInboxRecords = (
  records: RecordDict,
  inbox: InboxRecord,
): InboxRecordsData => {
  const [uncompleteIds, completedIds] = splitByCompleted(
    records,
    Frack.toArray(inbox.children),
  )
  return {
    records: uncompleteIds.map((id: string) => ({
      ...(records[id] as TextRecord),
      tags: getTagListForRecord(records, id, RecordViewType.SectionGroup),
      notesList: createNoteListData(getNotesForRecord(records, id)),
    })),
    completedRecords: completedIds.map((id: string) => ({
      ...(records[id] as TextRecord),
      tags: getTagListForRecord(records, id, RecordViewType.SectionGroup),
      notesList: createNoteListData(getNotesForRecord(records, id)),
    })),
  }
}
