import { EmojiData, Picker } from 'emoji-mart'
import React from 'react'

import { Button, Icon, cx, styled, withMenu } from '../../../common/stationary'

interface EmojiPickerPopoverProps {
  className?: string
  close: () => void
  disabled?: boolean
  emoji?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onSelect: (emoji: EmojiData) => void
  visible: boolean
}

export const EmojiPickerPopover: React.FC<EmojiPickerPopoverProps> = ({
  className,
  close,
  disabled = false,
  emoji,
  onClick,
  onSelect,
  visible,
}) => (
  <S.EmojiPickerPopover
    className={cx(className, { isMenuVisible: visible })}
    disabled={disabled}
    menuProps={{
      children: (
        <Picker
          native
          autoFocus
          title="Pick your emoji…"
          emoji={emoji}
          onSelect={onSelect}
        />
      ),
      open: visible,
      onClose: close,
    }}
    onClick={onClick}
    popperProps={{ offset: [-8, 4], placement: 'right-start' }}
    variant="plain"
  >
    <Icon boxSize={18} value={emoji} variant="emoji" />
  </S.EmojiPickerPopover>
)

const S = {
  EmojiPickerPopover: styled(withMenu(Button))(({ theme }) => ({
    display: `flex`,
    alignItems: `crenter`,
    justifyContent: `center`,
    height: 28,
    width: 28,
    borderRadius: 6,

    '&.isMenuVisible': {
      background: `${theme.colors.alpha.tone.mediumWeak} !important`,
    },
  })),
}
