import { isBefore, parseISO } from 'date-fns'
import { every, flatMap } from 'lodash'
import { RouteType } from '../../routes'

import { RecordDict, TickleableRecord } from '@eleventhlabs/capture-shared'
import { RecordGroupData } from './types'

export const getNextItem = (
  recordGroups: RecordGroupData[],
  recordId: string,
): string | undefined => {
  const recordIds = flatMap(recordGroups, (g) => g.items.map((i) => i.id))
  const index = recordIds.indexOf(recordId)
  return recordIds[index + 1]
}

export const getPrevItem = (
  recordGroups: RecordGroupData[],
  recordId: string,
): string | undefined => {
  const recordIds = flatMap(recordGroups, (g) => g.items.map((i) => i.id))
  const index = recordIds.indexOf(recordId)
  return recordIds[index - 1]
}

export const getAreAllOverdue = (
  records: RecordDict,
  todayIso: string,
  ids: string[],
) => {
  const isBeforeToday = (str: string): boolean =>
    isBefore(parseISO(str), parseISO(todayIso))
  return every(ids, (id) => {
    const record = records[id] as TickleableRecord
    return isBeforeToday(record.tickler?.isoDate ?? todayIso)
  })
}
