import { isEmpty } from 'lodash'
import React, { useState } from 'react'

import { RecordItemData } from '../../common/data/recordGroups'
import { NoteHandlers as GenericNoteHandlers } from '../../common/noteEventHandlers'
import FullScreenRecordContent from './components/FullScreenRecordContent'
import { NoteList } from './components/NoteList/NoteList'
import { FullScreenRecordItemHandlers } from './types'
import { usePlaceholderText } from '../RecordItem/hooks/usePlaceholderText'
import { useShouldAutofocus } from '../RecordItem/hooks/useShouldAutofocus'
import ContentErrorBoundary from '../../common/errors/ContentErrorBoundary'
import { cx, styled } from '../../common/stationary'

export interface FullScreenRecordItemProps
  extends FullScreenRecordItemHandlers {
  className?: string
  isMouseActive?: boolean
  record: RecordItemData
  noteHandlers: GenericNoteHandlers
  shouldDisplayNotes: boolean
}

export const FullScreenRecordItem: React.FC<FullScreenRecordItemProps> = ({
  className,
  isMouseActive,
  onBlur,
  onClick,
  onFocus,
  onMouseDown,
  onChange,
  onPaste,
  onKeyDown,
  onArrowLeftOrUpAtBoundary,
  record,
  noteHandlers,
  shouldDisplayNotes,
  renameFile,
}) => {
  const hasNotes = !isEmpty(record.notesList?.notes)
  const notesOpen = hasNotes && shouldDisplayNotes

  const { shouldAutofocus } = useShouldAutofocus(record.id)
  const [placeholderType, setPlaceholderType] = useState<string | undefined>()
  const { placeholder } = usePlaceholderText(record.id, placeholderType)

  return (
    <S.FullScreenRecordItem
      className={cx(className, `item`, {
        isMouseActive,
      })}
    >
      {notesOpen ? (
        <S.Notes
          {...record.notesList}
          parentId={record.id}
          handlers={noteHandlers}
          isMouseActive={isMouseActive}
          isSelected={false}
        />
      ) : null}
      <S.RecordContent onMouseDown={onMouseDown}>
        <FullScreenRecordContent
          autofocus={shouldAutofocus}
          placeholder={placeholder}
          setPlaceholderType={setPlaceholderType}
          placeholderType={placeholderType}
          record={record}
          onChange={onChange}
          onPaste={onPaste}
          onBlur={onBlur}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onArrowLeftOrUpAtBoundary={onArrowLeftOrUpAtBoundary}
          renameFile={renameFile}
          notesOpen={notesOpen}
          error={{
            fallbackComponent: ContentErrorBoundary,
            fallbackComponentProps: { record },
          }}
        />
      </S.RecordContent>
    </S.FullScreenRecordItem>
  )
}

const S = {
  FullScreenRecordItem: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `column`,
    position: `relative`,
    minHeight: 72,
    background: theme.colors.surface.base,
    outline: `none`,
    marginBottom: 8,
    borderRadius: 8,
  })),
  Notes: styled(NoteList)(({ theme }) => ({
    display: `flex`,
    border: `none`,
    marginTop: 12,
    borderRadius: 8,
    boxShadow: `0px 1px 3px ${theme.colors.alpha.tone.weak}, 0px 0px 12px ${theme.colors.alpha.tone.weak}, 0px 0px 0px 1px ${theme.colors.alpha.tone.weak}`,
  })),
  RecordContent: styled.div({
    display: `flex`,
    flex: 1,
    width: `100%`,
    overflow: `hidden`,
    marginTop: 28,
  }),
}
