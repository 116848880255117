import { RichText } from '@eleventhlabs/capture-shared'
import React, { useCallback, useMemo, useState } from 'react'

import { cx } from '../../../common/stationary'

type BaseComponent = {
  richText: RichText
  onChange?: (value: RichText) => void
  editorClassName?: string
}

export const withCustomStyleForHeaderOrMultiline = <T extends BaseComponent>(
  Component: React.FC<T>,
): React.FC<T> => {
  const WrappedComponent: React.FC<T> = ({
    editorClassName,
    richText,
    onChange: handleChange,
    ...props
  }) => {
    const [startsWithHeadingOrIsMultiline, setStartsWithHeadingOrIsMultiline] =
      useState(
        RichText.startsWithHeading(richText) || RichText.isMultiline(richText),
      )
    const onChange = useCallback(
      (value: RichText) => {
        setStartsWithHeadingOrIsMultiline(
          RichText.startsWithHeading(value) || RichText.isMultiline(value),
        )
        handleChange && handleChange(value)
      },
      [handleChange],
    )
    const _editorClassName = useMemo(
      () =>
        cx(editorClassName, {
          startsWithHeadingOrIsMultiline,
        }),
      [editorClassName, startsWithHeadingOrIsMultiline],
    )
    return (
      <Component
        {...(props as T)}
        editorClassName={_editorClassName}
        onChange={onChange}
        richText={richText}
      />
    )
  }
  return WrappedComponent
}
