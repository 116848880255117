import { CSSProperties } from 'react'

import { InteractiveInbox } from './InteractiveInbox'
import { ProgressBar } from './ProgressBar'

export interface WidgetProps {
  style?: CSSProperties
}

export type WidgetType = keyof typeof Widgets

/*
 * Widgets
 */
export const Widgets = {
  ProgressBar: {
    component: ProgressBar,
  },
  InteractiveInbox: {
    component: InteractiveInbox,
  },
}
