import { isSSROrWindowNotAvailable } from '../utils'

export function getBooleanQueryParam(name: string) {
  const queryParams = getQueryParams()
  const queryValue = queryParams[name]
  return queryValue ? JSON.parse(queryValue) : false
}

export function getQueryParams() {
  if (isSSROrWindowNotAvailable()) return {}
  const params = new URL(window.location.href).searchParams
  const queryParams: { [key: string]: string } = {}
  for (const [key, value] of params.entries()) {
    queryParams[key] = value
  }
  return queryParams
}
