import { intersection } from 'lodash'
import { MouseEvent } from 'react'

import { BaseEvent } from './common/types'
import { EventQueue, ValidListener } from './types'

export const processQueueForListener = (
  queue: EventQueue,
  listener: ValidListener,
): BaseEvent[] => {
  // check if the new listener matches any of the existing events (from oldest to newest)
  const eventsMatched = queue.events.reduce(
    (prev: BaseEvent[], cur: BaseEvent) => {
      const matches = listener.handler(cur as any)
      return matches ? [...prev, cur] : prev
    },
    [],
  )

  // return events not processed
  return queue.events.filter((event) => !eventsMatched.includes(event))
}

export const processQueueForAllListeners = (queue: EventQueue): BaseEvent[] => {
  // if there aren't any listeners, return the queue's events
  if (queue.listeners.length == 0) return queue.events

  const eventsMatched = queue.listeners.map((listener) =>
    processQueueForListener(queue, listener),
  )
  return intersection(...eventsMatched)
}

export const preventDefault = (event: MouseEvent) => {
  event.preventDefault()
}
