import React, { useMemo } from 'react'

import { useDialogContext } from '../../contexts/DialogContext'
import { DialogType } from '../../dialogs/types'
import CommandBarDialog from './CommandBarDialog'

const useCommandBarDialog = (
  isViewVisible: (action: DialogType) => boolean,
  selectedRecordIds: string[],
) => {
  const { closeDialog } = useDialogContext()

  const isOpen = isViewVisible(DialogType.CommandBar)

  const commandBarComponent = useMemo(
    () =>
      isOpen ? (
        <CommandBarDialog
          isOpen={isOpen}
          selectedRecordIds={selectedRecordIds}
          onDismiss={closeDialog}
        />
      ) : null,
    [isOpen, selectedRecordIds, closeDialog],
  )

  return {
    commandBarComponent,
  }
}

export default useCommandBarDialog
