import React, { createContext, useContext, useMemo, useState } from 'react'

export interface QuickCaptureInteractionContextValue {
  didPressKeyboardShortcut: boolean
  setDidPressKeyboardShortcut: (didPress: boolean) => void
  didClickTrayIcon: boolean
  setDidClickTrayIcon: (didClick: boolean) => void
}

/*
 * Context
 */

const defaultValue: QuickCaptureInteractionContextValue = {
  didPressKeyboardShortcut: false,
  setDidPressKeyboardShortcut: (_didPress: boolean) => {},
  didClickTrayIcon: false,
  setDidClickTrayIcon: (_didPress: boolean) => {},
}

export const QuickCaptureInteractionContext =
  createContext<QuickCaptureInteractionContextValue>(defaultValue)
QuickCaptureInteractionContext.displayName = `QuickCaptureInteractionContext`

/*
 * Provider
 */

export interface QuickCaptureInteractionContextProviderProps {
  children: React.ReactNode
}

export const QuickCaptureInteractionProvider = ({
  children,
}: QuickCaptureInteractionContextProviderProps) => {
  const [didPressKeyboardShortcut, setDidPressKeyboardShortcut] =
    useState<boolean>(false)
  const [didClickTrayIcon, setDidClickTrayIcon] = useState<boolean>(false)

  return (
    <QuickCaptureInteractionContext.Provider
      value={{
        didPressKeyboardShortcut,
        setDidPressKeyboardShortcut,
        didClickTrayIcon,
        setDidClickTrayIcon,
      }}
    >
      {children}
    </QuickCaptureInteractionContext.Provider>
  )
}

/*
 * Hooks
 */

export const useQuickCaptureInteraction =
  (): QuickCaptureInteractionContextValue => {
    const context = useContext(QuickCaptureInteractionContext)

    if (context === undefined) {
      throw new Error(
        `useLogger must be used within an QuickCaptureInteractionContext`,
      )
    }

    return context
  }
