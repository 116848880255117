import {
  INVITE_CODE_KEY,
  UserDataKeyboardShortcuts,
  userDataKeyboardShortcutsSchema,
} from '@eleventhlabs/capture-shared'
import { merge } from 'lodash'
import { Replicache } from 'replicache'
import * as z from 'zod'

import {
  replicacheUserRepository,
  useSubscribeIfNotNull,
} from '../../replicache'
import { IDBUserDataOnboarding } from '../../replicache/models'
import { defaultUserDataKeyboardShortcuts } from './types'

export type ModelStoreUser = {
  id: string
  username: string
  timezone?: string
  phoneNumber?: string
  lastModified: number
  createdAt: number
  data: {
    onboarding: UserDataOnboarding
    keyboardShortcuts: UserDataKeyboardShortcuts
  }
  inviteCode: string
}

export const useUser = (
  rep: Replicache | null,
  def: ModelStoreUser,
  deps?: any[],
): ModelStoreUser => {
  // Get the value stored in IDB
  const stored =
    useSubscribeIfNotNull(
      rep,
      replicacheUserRepository.safeGetUser,
      def,
      deps,
    ) ?? def

  // Transform to latest version expected by the client
  if (stored.data === undefined) {
    stored.data = {}
  }

  stored.data.onboarding = normalizeOnboarding(stored.data.onboarding ?? {})

  if (stored.data.keyboardShortcuts === undefined) {
    stored.data.keyboardShortcuts = defaultUserDataKeyboardShortcuts
  }

  if (stored.createdAt === undefined) {
    stored.createdAt = 0
  }

  if (stored.lastModified === undefined) {
    stored.lastModified = 0
  }

  const inviteCode =
    useSubscribeIfNotNull<string>(
      rep,
      async (tx) => {
        const parse = z.string().safeParse(await tx.get(INVITE_CODE_KEY))
        return parse.success ? parse.data : ''
      },
      '',
      deps,
    ) ?? ''

  // Parse as user
  return modelStoreUserSchema.parse({ ...(stored ?? {}), inviteCode })
}

// ----------------------
// ONBOARDING SAVE POINTS
// ----------------------

const timestamp = z.number()
const savePointsSchema = z.record(timestamp)
const completedSlidesSchema = z.record(timestamp)
const trackSchema = z.object({
  isComplete: z.boolean(),
  completedAt: z.number().optional(),
  savePoints: savePointsSchema,
  completedSlides: completedSlidesSchema,
})
const tracksSchema = z.object({
  Desktop: trackSchema,
  Mobile: trackSchema,
  MobileWeb: trackSchema,
  Web: trackSchema,
})

const modelStoreUserDataOnboardingSchema = z.object({
  track: tracksSchema,
})

const modelStoreUserSchema = z.object({
  id: z.string().uuid(),
  username: z.string(),
  timezone: z.string().optional(),
  phoneNumber: z.string().optional(),
  lastModified: z.number(),
  createdAt: z.number(),
  inviteCode: z.string(),
  data: z.object({
    onboarding: modelStoreUserDataOnboardingSchema,
    keyboardShortcuts: userDataKeyboardShortcutsSchema,
  }),
})

export type UserDataOnboarding = z.TypeOf<
  typeof modelStoreUserDataOnboardingSchema
>

const createDefaultTrack = () => ({
  savePoints: {},
  completedSlides: {},
  isComplete: false,
})

export const defaultOnboarding: UserDataOnboarding = {
  track: {
    Desktop: createDefaultTrack(),
    Mobile: createDefaultTrack(),
    MobileWeb: createDefaultTrack(),
    Web: createDefaultTrack(),
  },
}

function normalizeOnboarding(o: IDBUserDataOnboarding): UserDataOnboarding {
  const defaults: UserDataOnboarding = {
    track: {
      Web: {
        isComplete: true,
        savePoints: {},
        completedSlides: {},
      },
      Mobile: {
        isComplete: true,
        savePoints: {},
        completedSlides: {},
      },
      MobileWeb: {
        isComplete: true,
        savePoints: {},
        completedSlides: {},
      },
      Desktop: {
        isComplete: true,
        savePoints: {},
        completedSlides: {},
      },
    },
  }
  return merge({}, defaults, o)
}
