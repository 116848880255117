import {
  RecordType,
  RichText,
  CreateRecordPayload,
  CURRENT_RICH_TEXT_VERSION,
} from '@eleventhlabs/capture-shared'
import { first } from 'lodash'
import { RecordGroupsCommands } from '../../../../../../data/recordGroups'
import { BaseSelection } from '../../../../../../libs/selection'
import { RecordItemTargetType } from '../../../../../../recordItem'
import { Records } from '../../../../../../records'
import {
  CreateRecordResult,
  CreateRecordAtStartOfGroupArgs,
  CreateFileRecordExtras,
  CreateRecordAtFirstPositionArgs,
} from '../types'
import { createRecordPayloadFromRichText } from './createRecordPayload'

export const createRecordInsertBelow = (
  selection: BaseSelection,
  recordGroupCommands: RecordGroupsCommands,
  setRecentlyCreatedRecords: (
    value: React.SetStateAction<Record<string, RecordItemTargetType>>,
  ) => void,
): CreateRecordResult => {
  let result: CreateRecordResult
  const focus = selection.ids[0]
  if (focus === undefined) {
    result = recordGroupCommands.createAtFirstInsertPosition({
      type: RecordType.Text,
    })
  } else {
    result = recordGroupCommands.createBelowAnchor(
      { type: RecordType.Text },
      focus,
    )
  }
  if (!result) return

  setRecentlyCreatedRecords((recentlyCreatedRecords) => ({
    ...recentlyCreatedRecords,
    // We know result is not undefined here because we're checking above.
    [result!.id]: RecordItemTargetType.Text,
  }))

  return result
}

export const createRecordAtStartOfGroup = async (
  recordId: string,
  recordGroupCommands: RecordGroupsCommands,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
  args: CreateRecordAtStartOfGroupArgs,
): Promise<CreateRecordResult> => {
  let newRecordId: string

  const incomingRichText = args.richText
  const emptyRichText =
    args.targetType === RecordItemTargetType.Page
      ? RichText.createEmptyRichTextWithHeading()
      : RichText.create()

  let payload: CreateRecordPayload
  if (incomingRichText) {
    payload = await createRecordPayloadFromRichText(incomingRichText)
  } else {
    payload = {
      type: RecordType.Text,
      id: recordId,
      richText: { value: emptyRichText, version: CURRENT_RICH_TEXT_VERSION },
    }
  }

  const result = recordGroupCommands.createAtIndexInGroup(
    payload,
    args.groupId,
    0,
  )
  if (!result) return
  newRecordId = result.id

  const focusNewRecord = args.focusNewRecord ?? true
  if (focusNewRecord) {
    setRecentlyCreatedRecords((recentlyCreatedRecords) => ({
      ...recentlyCreatedRecords,
      [newRecordId]: args.targetType || RecordItemTargetType.Text,
    }))
  }

  if (args.openRecordInFullScreen) {
    // Navigate to the DetailView for the record
    args.navigate(newRecordId)

    // Navigate to current screen when exiting DetailView
    args.setLastRoute(args.currentRoute)

    args.setLastSelection(args.currentSelection)
  }

  return result
}

export const createRecordAtFirstPosition = async (
  recordId: string,
  recordGroupCommands: RecordGroupsCommands,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
  args: CreateRecordAtFirstPositionArgs,
): Promise<CreateRecordResult> => {
  const incomingRichText = args.richText
  const emptyRichText =
    args.targetType === RecordItemTargetType.Page
      ? RichText.createEmptyRichTextWithHeading()
      : RichText.create()

  let payload: CreateRecordPayload
  if (incomingRichText) {
    payload = await createRecordPayloadFromRichText(incomingRichText)
  } else {
    payload = {
      type: RecordType.Text,
      id: recordId,
      richText: { value: emptyRichText, version: CURRENT_RICH_TEXT_VERSION },
    }
  }

  const result = recordGroupCommands.createAtFirstInsertPosition(payload)
  if (!result) return
  const newRecordId = result.id

  const focusNewRecord = args.focusNewRecord ?? true
  if (focusNewRecord) {
    setRecentlyCreatedRecords((recentlyCreatedRecords) => ({
      ...recentlyCreatedRecords,
      [result.id]: args.targetType || RecordItemTargetType.Text,
    }))
  }

  if (args.openRecordInFullScreen) {
    // Navigate to the DetailView for the record
    args.navigate(newRecordId)

    // Navigate to current screen when exiting DetailView
    args.setLastRoute(args.currentRoute)

    args.setLastSelection(args.currentSelection)
  }

  return result
}

export const createFileRecord = (
  recordGroupCommands: RecordGroupsCommands,
  groupId: string | undefined,
  index: number | undefined,
  extras: CreateFileRecordExtras,
): CreateRecordResult => {
  const file = first(extras.acceptedFiles)
  if (!file) return

  let recordId: string | undefined
  let result: CreateRecordResult
  if (groupId) {
    result = recordGroupCommands.createAtIndexInGroup(
      { type: RecordType.File },
      groupId,
      index ?? 0,
    )
    if (!result) return
    recordId = result.id
  } else {
    result = recordGroupCommands.createAtFirstInsertPosition({
      type: RecordType.File,
    })
    if (!result) return
    recordId = result.id
  }

  Records.addFileToRecord(
    recordId,
    file,
    extras.updateRecord,
    extras.deleteRecords,
    extras.setSelectionDefaultId,
    extras.uploadFile,
  )

  return result
}
