import { useEffect } from 'react'

import { useUIContext } from '../contexts/UIContext'
import { emptyFn } from '../utils'

export const useBodyWithoutScrollBarOnNoChrome = () => {
  const { isMouseRecentlyActive } = useUIContext()

  useEffect(() => {
    if (!isMouseRecentlyActive) {
      const css = `body::-webkit-scrollbar-thumb { background: transparent !important }`
      const style = document.createElement(`style`)
      style.appendChild(document.createTextNode(css))
      const appended = document
        .getElementsByTagName(`body`)[0]
        .appendChild(style)
      return () =>
        document.getElementsByTagName(`body`)[0].removeChild(appended)
    }
    return emptyFn
  }, [isMouseRecentlyActive])
}
