import { EditorsManager } from '../../../common/EditorsManager'
import { useUIContext } from '../../../common/contexts/UIContext'
import { RecordItemTargetType } from '../../../common/recordItem'

export const usePlaceholderText = (
  recordId: string,
  placeholderType?: string,
  isNote?: boolean,
  recordType?: RecordItemTargetType,
) => {
  const { recentlyCreatedRecords } = useUIContext()
  const recentlyCreatedRecordType = recentlyCreatedRecords[recordId]
  const isH1 = placeholderType === 'h1'
  const isH2 = placeholderType === 'h2'
  const isH3 = placeholderType === 'h3'

  const placeholderRecordType = recordType ?? recentlyCreatedRecordType

  let placeholder

  switch (placeholderRecordType) {
    case RecordItemTargetType.Bookmark:
      placeholder = 'Paste a link...'
      break
    case RecordItemTargetType.Page:
    case RecordItemTargetType.Text:
      if (isNote) placeholder = 'Empty'
      else placeholder = "What's on your mind?"
      break
    default:
      if (EditorsManager.get.isEmptyHeading(recordId))
        placeholder = isH1
          ? `Heading 1`
          : isH2
          ? `Heading 2`
          : isH3
          ? `Heading 3`
          : 'Empty'
      else placeholder = 'Empty'
  }
  return {
    placeholder,
  }
}
