import React, { useCallback } from 'react'

import { TrashId } from '@eleventhlabs/capture-shared'

import Analytics from '../../common/analytics/capture-analytics-actions'
import useScreenLoadedAnalytics from '../../common/analytics/helpers/hooks/useScreenLoadedAnalytics'
import { useUIContext } from '../../common/contexts/UIContext'
import { TrashRecordGroupId } from '../../common/data/recordGroups/selectors/trash'
import { useScrollWindowAtEdgesOnDrag } from '../../common/hooks/listeners/useScrollWindowAtEdgesOnDrag'
import {
  screenInnerStyles,
  screenStyles,
  styled,
} from '../../common/stationary'
import { isMobile } from '../../common/utils/env'
import Header from '../../components/Header/Header'
import { RecordGroup } from '../../components/RecordGroup'
import { ClickOutsideListener } from '../../components/helpers/ClickOutsideListener'
import { withDragLayer } from '../enhancers/withDragLayer'
import { withRecordGroupHotKeys } from '../enhancers/withRecordGroupHotKeys'
import { withRecordGroupScreen } from '../enhancers/withRecordGroupScreen'
import { CommonRecordGroupScreenProps } from '../types'
import TrashHeader from './components/TrashHeader'
import { OverdueRecordGroupId } from '../../common/data/recordGroups/selectors/today'
import {
  EmptyStateText,
  RecordGroupEmptyState,
} from '../../components/RecordGroupEmptyState'
import { useDragToSelectRecords } from '../../common/dragToSelect/useDragToSelect'

export type TrashProps = {}

export type TrashScreenProps = TrashProps & CommonRecordGroupScreenProps

const TrashScreen = ({
  className,
  createFileRecordAtStartOfGroup,
  createRecordAtStartOfGroup,
  focusedId,
  groups,
  miniBarHandlers,
  noteHandlers,
  onClickOutside,
  recordHandlers,
  routeType,
  selectedIds,
  shouldDisplayNotesForRecordId,
  uploadProgress,
}: TrashScreenProps) => {
  const { isMouseRecentlyActive } = useUIContext()

  const dragBoundaryRef = useScrollWindowAtEdgesOnDrag<HTMLDivElement>()
  useScreenLoadedAnalytics(Analytics.ScreenType.TRASH, TrashId)
  useDragToSelectRecords(dragBoundaryRef)

  return (
    <S.TrashScreen ref={dragBoundaryRef}>
      <Header />
      <ClickOutsideListener onClickOutside={onClickOutside}>
        <S.TrashScreenInner className={className}>
          <TrashHeader />
          {groups.map((group) => (
            <RecordGroup
              showCaptureButtons={
                !isMobile &&
                group.id !== OverdueRecordGroupId &&
                group.id !== TrashRecordGroupId
              }
              createFileRecordAtStartOfGroup={createFileRecordAtStartOfGroup}
              emptyStateRenderer={() => (
                <RecordGroupEmptyState>
                  <EmptyStateText>
                    Nothing in <strong>Trash</strong>
                  </EmptyStateText>
                </RecordGroupEmptyState>
              )}
              focusedId={focusedId}
              isMouseActive={isMouseRecentlyActive}
              key={`${group.type}-${group.id}`}
              miniBarHandlers={miniBarHandlers}
              noteHandlers={noteHandlers}
              recordHandlers={recordHandlers}
              routeType={routeType}
              selectedIds={selectedIds}
              shouldDisplayNotesForRecordId={shouldDisplayNotesForRecordId}
              createRecordAtStartOfGroup={createRecordAtStartOfGroup}
              uploadProgress={uploadProgress}
              {...group}
            />
          ))}
        </S.TrashScreenInner>
      </ClickOutsideListener>
    </S.TrashScreen>
  )
}

export default withDragLayer(
  withRecordGroupScreen(withRecordGroupHotKeys(TrashScreen)),
)

const S = {
  TrashScreen: styled.div({
    ...screenStyles,
  }),
  TrashScreenInner: styled.div({
    ...screenInnerStyles,
  }),
}
