import { isMobile } from '../../../common/utils/env'
import { AuthStyles as S } from '../AuthStyles'
import React from 'react'
import { Helmet } from 'react-helmet'
import LoginFooter from './LoginFooter'
import ResetPasswordEmailSentMessage from './ResetPasswordEmailSentMessage'

interface PasswordEmailSentMessageProps {
  resetLoginState?: () => void
  handleMagicLinkLoginStart?: () => void
}

/**
 * PasswordEmailSentMessage
 */

const PasswordEmailSentMessage: React.FC<PasswordEmailSentMessageProps> = ({
  resetLoginState,
  handleMagicLinkLoginStart,
}) => {
  return (
    <S.AuthScreen>
      <Helmet>
        <title>Capture - Log in</title>
      </Helmet>
      <S.Main>
        <ResetPasswordEmailSentMessage
          resetLoginState={resetLoginState}
          handleMagicLinkLoginStart={handleMagicLinkLoginStart}
          showAlternativeLoginMethod
        />
      </S.Main>
      {!isMobile && <LoginFooter />}
    </S.AuthScreen>
  )
}

export default PasswordEmailSentMessage
