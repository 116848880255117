import React from 'react'

import { useModelStoreContext } from '../../common/contexts/ModelStoreContext'
import {
  KeyMappedActionIdType,
  useKeyMappedActionHotkeys,
} from '../../common/keyMappableActions'
import { DetailsScreenProps } from '../DetailsScreen/DetailsScreen'
import { useCommonHotkeysForFullscreen } from '../hooks/useCommonHotkeysForFullscreen'
import { useScreenHotKeys } from '../hooks/useScreenHotKeys'

export function withRecordDetailsHotKeys<T extends DetailsScreenProps>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const ComponentWithRecordGroupHotKeys = (props: T) => {
    const { store } = useModelStoreContext()
    const recordId = props.recordId

    const handlers = useCommonHotkeysForFullscreen(store.records[recordId])

    // register hotkeys for keyMappableActions

    useKeyMappedActionHotkeys(fullscreenActionsToMap)

    // register hot keys

    useScreenHotKeys(handlers)

    // wrapped component

    return <WrappedComponent {...props} />
  }

  return ComponentWithRecordGroupHotKeys
}

const fullscreenActionsToMap: KeyMappedActionIdType[] = [
  'KEY_MAPPED_FULLSCREEN_CLOSE',
  'KEY_MAPPED_OPEN_COMMAND_BAR_DIALOG',
  'KEY_MAPPED_OPEN_GO_TO_DIALOG',
  'KEY_MAPPED_OPEN_KEYBOARD_SHORTCUT_DIALOG',
  'KEY_MAPPED_OPEN_MOVE_TO_DIALOG',
  'KEY_MAPPED_OPEN_SCHEDULE_TO_DIALOG',
  'KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE',
  'KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN',
  'KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE',
]
