import { add, eachDayOfInterval, formatISO } from 'date-fns'

import { TodayNextFewWeeksSpan } from '../../../../data/recordGroups/selectors/today'
import { RouteType } from '../../../../routes'
import {
  SortDirection,
  SortRecordsBy,
  SortRecordsPayload,
} from '@eleventhlabs/capture-shared'

export const sortByLocation = (
  routeType: RouteType,
  direction: SortDirection,
  sortRecords: (payload: SortRecordsPayload) => void,
) => {
  let isoDates: string[]
  if (routeType === RouteType.Today) {
    isoDates = eachDayOfInterval({
      start: Date.now(),
      end: add(Date.now(), { weeks: TodayNextFewWeeksSpan }),
    }).map((date) => formatISO(date, { representation: `date` }))
  } else throw new Error(`Cannot sort by location outside of Today screen`)
  sortRecords({
    type: SortRecordsBy.Location,
    direction,
    dates: [`overdue`, ...isoDates],
  })
}
