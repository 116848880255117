import { COMPLETED } from '../../../../utils/uiConstants'
import { ModelStore } from '../../../modelStore'
import { RecordGroupData, RecordGroupType } from '../../types'
import { createRecordGroupData } from '../common'
import { getListRecords } from '../lists'

export const createListRecordGroups = (
  data: ModelStore,
  listId?: string,
  showCompleted?: boolean,
): RecordGroupData[] => {
  if (!listId) return []
  const listData = getListRecords(data.records, listId)

  const groups = [
    createRecordGroupData(
      listData.records,
      RecordGroupType.ListMain,
      createListRecordGroupId(listId, RecordGroupType.ListMain),
    ),
  ]

  if (showCompleted) {
    groups.push(
      createRecordGroupData(
        listData.completedRecords,
        RecordGroupType.ListComplete,
        createListRecordGroupId(listId, RecordGroupType.ListComplete),
        COMPLETED,
      ),
    )
  }

  return groups
}

const createListRecordGroupId = (listId: string, type: RecordGroupType) => {
  return `${listId}_${type}`
}

export const getDataFromListRecordGroupId = (
  groupId: string,
): {
  listId: string
  type: RecordGroupType
} => {
  const values = groupId.split(`_`)
  const listId = values[0]
  let type: RecordGroupType
  if (values[1] === RecordGroupType.ListComplete)
    type = RecordGroupType.ListComplete
  else if (values[1] === RecordGroupType.ListMain)
    type = RecordGroupType.ListMain
  else throw new Error(``)
  return {
    listId,
    type,
  }
}
