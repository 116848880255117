import React, { useCallback } from 'react'

import { Button, cx, styled } from '../../../common/stationary'
import { emptyFn } from '../../../common/utils'

export interface DateShortcutItemProps {
  index: number
  className?: string
  iconColor: string
  icon: React.ReactNode
  metaText?: string
  title: string
  isSelected?: boolean
  onClick?: () => void
  onMouseEnter?: (index: number) => void
  onMouseLeave?: (index: number) => void
}

const DateShortcutItem = ({
  index,
  iconColor,
  icon,
  metaText,
  title,
  className,
  isSelected,
  onClick = emptyFn,
  onMouseEnter = emptyFn,
  onMouseLeave = emptyFn,
}: DateShortcutItemProps) => {
  const _onMouseEnter = useCallback(() => {
    onMouseEnter(index)
  }, [onMouseEnter, index])

  const _onMouseLeave = useCallback(() => {
    onMouseLeave(index)
  }, [onMouseLeave, index])

  return (
    <S.DateShortcutItem
      className={cx(className, {
        isSelected,
      })}
      onClick={onClick}
      onMouseOver={_onMouseEnter}
      onMouseOut={_onMouseLeave}
    >
      <S.DateShortcutButton style={{ color: iconColor }}>
        {icon}
        <S.DateShortcutTitle
          className={cx({
            isSelected,
          })}
        >
          {title}
          {metaText ? (
            <S.DateShortcutMetaText>{metaText}</S.DateShortcutMetaText>
          ) : undefined}
        </S.DateShortcutTitle>
      </S.DateShortcutButton>
    </S.DateShortcutItem>
  )
}

export default DateShortcutItem

const S = {
  DateShortcutItem: styled.li(({ theme }) => ({
    userSelect: `none`,
    borderRadius: 6,
    cursor: 'pointer',

    '&.isSelected': {
      background: theme.colors.alpha.tone.weak,
    },
  })),
  DateShortcutButton: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    outline: `none`,
    height: 40,
    width: `100%`,
    padding: `0 16px 0 12px`,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 600,
    textAlign: `left`,
  })),
  DateShortcutTitle: styled.span(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 10,
    color: theme.colors.text[700],

    '&.isSelected': {
      color: theme.colors.text[900],
    },
  })),
  DateShortcutMetaText: styled.div(({ theme }) => ({
    ...theme.text.publicSans['11.5:16'],
    fontWeight: 500,
    textAlign: `right`,
    color: theme.colors.text[700],
  })),
}
