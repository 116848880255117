export enum CommandId {
  COLLECTION_RENAME = 'COLLECTION_RENAME',
  COLLECTION_SET_EMOJI = 'COLLECTION_SET_EMOJI',
  CREATE_COLLECTION = 'CREATE_COLLECTION',
  CREATE_RECORD = 'CREATE_RECORD',
  EXIT_FULLSCREEN = 'EXIT_FULLSCREEN',
  EXPAND_TO_FULLSCREEN = 'EXPAND_TO_FULLSCREEN',
  FOCUS_FILE_TITLE_INPUT = 'FOCUS_FILE_TITLE_INPUT',
  GO_TO_INBOX = 'GO_TO_INBOX',
  GO_TO_SCREEN = 'GO_TO_SCREEN',
  GO_TO_TRASH = 'GO_TO_TRASH',
  GO_TO_TODAY = 'GO_TO_TODAY',
  LOGOUT = 'LOGOUT',
  OPEN_CALENDAR_DIALOG = 'OPEN_CALENDAR_DIALOG',
  OPEN_MOVE_TO_DIALOG = 'OPEN_MOVE_TO_DIALOG',
  OPEN_SCHEDULE_DIALOG = 'OPEN_SCHEDULE_DIALOG',
  TOGGLE_KEYBOARD_SHORTCUTS_PANE = 'TOGGLE_KEYBOARD_SHORTCUTS_PANE',
  RECORD_ADD_NOTE = 'RECORD_ADD_NOTE',
  RECORD_CONVERT_TO_TEXT = 'RECORD_CONVERT_TO_TEXT',
  RECORD_CONVERT_TO_URL = 'RECORD_CONVERT_TO_URL',
  RECORD_ITEMS_COLLAPSE = 'RECORD_ITEMS_COLLAPSE',
  RECORD_ITEMS_EXPAND = 'RECORD_ITEMS_EXPAND',
  RECORD_ITEMS_HIDE_NOTES = 'RECORD_ITEMS_HIDE_NOTES',
  RECORD_ITEMS_SHOW_NOTES = 'RECORD_ITEMS_SHOW_NOTES',
  RECORDS_COPY = 'RECORDS_COPY',
  RECORDS_MARK_DONE = 'RECORDS_MARK_DONE',
  RECORDS_MARK_NOT_DONE = 'RECORDS_MARK_NOT_DONE',
  RECORDS_MOVE_TO_TRASH = 'RECORDS_MOVE_TO_TRASH',
  RECORDS_REMOVE_DATE = 'RECORDS_REMOVE_DATE',
  RECORDS_REMOVE_NOTE = 'RECORDS_REMOVE_NOTE',
  RECORDS_RESTORE = 'RECORDS_RESTORE',
  RECORDS_SELECT_ALL = 'RECORDS_SELECT_ALL',
  RECORDS_SORT_BY_DATE = 'RECORDS_SORT_BY_DATE',
  RECORDS_SORT_BY_LOCATION = 'RECORDS_SORT_BY_LOCATION',
  THEME_SWITCH_TO_DARK = 'THEME_SWITCH_TO_DARK',
  THEME_SWITCH_TO_LIGHT = 'THEME_SWITCH_TO_LIGHT',
  THEME_SWITCH_TO_SYSTEM = 'THEME_SWITCH_TO_SYSTEM',
  TOGGLE_NEXT_FOURTEEN_DAYS = 'TOGGLE_NEXT_FOURTEEN_DAYS',
  TOGGLE_SHOW_COMPLETED = 'TOGGLE_SHOW_COMPLETED',
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  VIEW_JWT = 'VIEW_JWT',
}
