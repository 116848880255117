import { decode } from 'html-entities'
import { isEmpty } from 'lodash'
import React from 'react'

import { styled } from '../../../../../common/stationary'

interface URLDescriptionProps {
  description?: string
  loading?: boolean
}

export const URLDescription: React.FC<URLDescriptionProps> = ({
  description,
  loading,
}) => {
  return loading ? null : (
    <S.Description>
      · {!isEmpty(description) ? decode(description) : `No description.`}
    </S.Description>
  )
}

const S = {
  Description: styled.div(({ theme }) => ({
    display: `block`,
    flex: 1,
    whiteSpace: `nowrap`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    alignItems: `flex-start`,
    justifyContent: `flex-start`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: '24px',
    color: theme.colors.text[700],
  })),
}
