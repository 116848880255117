import { useCallback } from 'react'

import { useExecContext } from '../../contexts/ExecContext'
import { RecordItemData } from '../../data/recordGroups'
import { EditorsManager } from '../../EditorsManager'
import { BaseSelection } from '../../libs/selection'
import { NoteRecord } from '@eleventhlabs/capture-shared'
import { NoteRichTextEventHandlers } from './NoteRichTextEventHandlers'
import { onBackspaceWhenEmpty as handleBackspaceWhenEmpty } from './onBackspaceWhenEmpty'
import { onKeyDown as handleKeyDown } from './onKeyDown'

export const useNoteRichTextEventHandlersForFullscreen = (
  record: RecordItemData,
  notes: Record<string, NoteRecord>,
  selection: BaseSelection,
): NoteRichTextEventHandlers => {
  const { exec } = useExecContext()

  const selectRecordBydId = useCallback(
    (id) => selection.set([id]),
    [selection],
  )

  const onArrowRightOrDownAtBoundary = useCallback(
    (noteId: string) => {
      const recordId = notes[noteId]?.parentId

      if (!record) return false

      EditorsManager.effects.blur(noteId)
      EditorsManager.effects.focus({ id: recordId, position: `start` })

      return true
    },
    [record, notes],
  )

  const onBackspaceWhenEmpty = useCallback(
    (noteId: string) => {
      const recordId = notes[noteId]?.parentId

      if (!record) return false

      const result = handleBackspaceWhenEmpty(exec, noteId)
      selectRecordBydId(recordId)

      return result
    },
    [record, notes, exec, selectRecordBydId],
  )

  const onChange = useCallback(
    (richText, noteId) => {
      exec({ type: 'NOTE_CHANGE', noteId, richText })
    },
    [exec],
  )

  const onKeyDown = useCallback(
    (
      e: React.KeyboardEvent<HTMLDivElement>,
      noteId: string,
      recordId: string,
    ) => {
      return handleKeyDown(e, exec, noteId, recordId)
    },
    [exec],
  )

  return {
    onArrowRightOrDownAtBoundary,
    onBackspaceWhenEmpty,
    onChange,
    onKeyDown,
  }
}
