import { useStableCallback } from '../utils'
import { ExecActionFunction } from './Action.type'
import {
  DefaultActionDefinitions,
  useExecDefaultAction,
} from './groups/default/useExecDefaultAction'
import {
  GlobalDialogActionDefs,
  useExecGlobalDialogAction,
} from './groups/globalDialog/useExecGlobalDialogAction'

type ActionDefinitions = DefaultActionDefinitions | GlobalDialogActionDefs
export type ExecAction = ExecActionFunction<ActionDefinitions>

export const useExec = (): ExecAction => {
  // Action Groups
  const execGlobalDialogAction = useExecGlobalDialogAction()
  const execDefaultAction = useExecDefaultAction()

  // @ts-ignore
  const exec: ExecActionFunction<ActionDefinitions> = (action) => {
    switch (action.type) {
      case `OPEN_CALENDAR_DIALOG`:
      case `OPEN_COMMAND_BAR`:
      case `OPEN_GO_TO_DIALOG`:
      case `OPEN_MOVE_TO_DIALOG`:
      case `OPEN_SCHEDULE_DIALOG`: {
        return execGlobalDialogAction(action)
      }
      case `TOGGLE_KEYBOARD_SHORTCUTS_PANE`:
      case 'CREATE_FILE_RECORD_AT_START_OF_GROUP':
      case 'CREATE_FILE_RECORD_AT_FIRST_POSITION':
      case 'CREATE_RECORD_AT_FIRST_POSITION':
      case 'CREATE_RECORD_INSERT_BELOW':
      case 'FILE_RECORD_OPEN_SRC_IN_TAB':
      case 'NOTE_ARROW_LEFT_OR_UP_AT_BOUNDARY':
      case 'NOTE_BLURRED':
      case 'NOTE_CHANGE':
      case 'NOTE_ESCAPED':
      case 'NOTE_FOCUSED':
      case 'NOTE_KEY_DOWN_LOWER_THAN':
      case 'NOTE_MOUSE_DOWN':
      case 'RECORD_HANDLE_CLICKED':
      case 'RECORD_ITEMS_COLLAPSE':
      case 'RECORD_ITEMS_EXPAND':
      case 'RECORD_ITEMS_HIDE_NOTES':
      case 'RECORD_ITEMS_SHOW_NOTES':
      case 'RECORD_ITEMS_TOGGLE_IS_EXPANDED':
      case 'RECORD_RENAME_FILE':
      case 'RECORD_SKIP_OCCURRENCE':
      case 'RECORDS_DELETE_NOTES':
      case 'RECORDS_MARK_DONE':
      case 'RECORDS_MARK_NOT_DONE':
      case 'RECORDS_REMOVE_TICKLER':
      case 'RECORDS_TOGGLE_IS_COMPLETED':
      case 'URL_RECORD_OPEN_SRC_IN_TAB':
      case `ADD_ANNOTATION`:
      case `CONVERT_RECORD_TO_TEXT`:
      case `CONVERT_RECORD_TO_URL`:
      case `COPY`:
      case `CREATE_COLLECTION`:
      case `CREATE_RECORD_AT_START_OF_GROUP`:
      case `DELETE_RECORDS`:
      case `EMPTY_TRASH`:
      case `EXIT_FULLSCREEN`:
      case `EXPAND_TO_FULLSCREEN`:
      case `FOCUS_FILE_TITLE_INPUT`:
      case `GO_TO_INBOX`:
      case `GO_TO_TRASH`:
      case `GO_TO_TODAY`:
      case `LOGOUT`:
      case `MOVE_RECORDS_TO_TRASH`:
      case `MOVE_RECORDS`:
      case `NAVIGATE_TO`:
      case `NOTE_ARROW_RIGHT_OR_DOWN_AT_BOUNDARY`:
      case `ONBOARDING_SUBMIT_QUICK_CAPTURE`:
      case `PLACE_TAG_CLICK`:
      case `RECORD_ARROW_LEFT_OR_UP_AT_BOUNDARY`:
      case `RECORD_ARROW_RIGHT_OR_DOWN_AT_BOUNDARY`:
      case `RECORD_BACKSPACE_AT_FIRST_POINT_WHEN_NOT_EMPTY`:
      case `RECORD_BACKSPACE_WHEN_EMPTY`:
      case `RECORD_BLURRED`:
      case `RECORD_CHANGE`:
      case `RECORD_CLICKED`:
      case `RECORD_FOCUSED`:
      case `RECORD_KEY_DOWN_ENTER`:
      case `RECORD_MOUSE_DOWN`:
      case `RECORD_PASTE_FROM_CLIPBOARD`:
      case `REMOVE_ANNOTATION`:
      case `REMOVE_DATE`:
      case `REMOVE_LIST`:
      case `RENAME_COLLECTION`:
      case `RESTORE_RECORDS`:
      case `SELECT_ALL_RECORDS`:
      case `SELECT_RECORD`:
      case `SET_COLLECTION_EMOJI`:
      case `SORT_BY_DATE`:
      case `SORT_BY_LOCATION`:
      case `SWITCH_TO_DARK_THEME`:
      case `SWITCH_TO_LIGHT_THEME`:
      case `SWITCH_TO_SYSTEM_THEME`:
      case `TOGGLE_ANNOTATIONS`:
      case `TOGGLE_NEXT_FOURTEEN_DAYS`:
      case `TOGGLE_SHOW_COMPLETED`:
      case `TOGGLE_SIDEBAR`:
      case `UPDATE_RECORD_TICKLER`:
      case 'VIEW_JWT': {
        return execDefaultAction(action)
      }
      default:
        throw new Error(`Unexpected action type`)
    }
  }

  return useStableCallback(exec)
}
