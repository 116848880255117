import {
  groupBy,
  keyBy,
  mapValues,
  sortBy,
  sortedIndexBy,
  values,
} from 'lodash'
import { ReadTransaction } from 'replicache'

import {
  DataRecord,
  DeleteableRecord,
  inOverdueOrder,
  isDeleteableRecord,
  RecordWithTickler,
} from '@eleventhlabs/capture-shared'

export const getAllRecords = async (tx: ReadTransaction) => {
  const recordEntries = await tx.scan({ prefix: `record/` }).toArray()
  return keyBy(recordEntries, `id`)
}

export const getRecordsWithTicklerForDay = async (
  tx: ReadTransaction,
  isoDate: string,
  includeSoftDeleted: boolean,
): Promise<RecordWithTickler[]> => {
  let recordsWithDate = (await tx
    .scan({ indexName: `tickler`, prefix: isoDate })
    .toArray()) as unknown as RecordWithTickler[]

  if (!includeSoftDeleted) {
    recordsWithDate = recordsWithDate.filter((r) => !r.isSoftDeleted)
  }

  return sortBy(recordsWithDate, `dateFIndex`)
}

export const getRecordsBeforeDay = async (
  tx: ReadTransaction,
  isoDate: string,
  includeSoftDeleted: boolean,
): Promise<RecordWithTickler[]> => {
  const records: RecordWithTickler[] = []
  for await (const [_, r] of tx.scan({ indexName: `tickler` }).entries()) {
    const record = r as unknown as RecordWithTickler
    // We can stop iterating once we reach the first out-of-bound date
    if (!includeSoftDeleted && record.isSoftDeleted) continue
    if (record.tickler.isoDate >= isoDate) break
    records.push(record)
  }

  return inOverdueOrder(records)
}

export const getOverdueRecordsForDate = async (
  tx: ReadTransaction,
  thresholdISO: string,
  includeSoftDeleted: boolean,
) => {
  const records: RecordWithTickler[] = []
  for await (const [_, r] of tx.scan({ indexName: `tickler` }).entries()) {
    const record = r as unknown as RecordWithTickler
    // We can stop iterating once we reach the first out-of-bound date
    if (record.tickler.isoDate >= thresholdISO) break
    if (!includeSoftDeleted && record.isSoftDeleted) continue
    if (record.isCompleted) continue
    records.push(record)
  }
  return inOverdueOrder(records)
}

export const getRecordsWithTicklerISOInRange = async (
  tx: ReadTransaction,
  {
    startISO,
    endISO,
    includeSoftDeleted,
  }: { startISO: string; endISO: string; includeSoftDeleted: boolean },
) => {
  const records = []
  for await (const [_, r] of tx.scan({ indexName: `tickler` }).entries()) {
    const record = r as unknown as RecordWithTickler
    if (record.tickler.isoDate < startISO) continue
    if (!includeSoftDeleted && record.isSoftDeleted) continue
    // We can stop iterating once we reach the first out-of-bound date
    if (record.tickler.isoDate > endISO) break
    records.push(record)
  }
  return mapValues(groupBy(records, `tickler.isoDate`), (r) =>
    sortBy(r, `dateFIndex`),
  )
}

export const getSoftDeletedRecords = async (tx: ReadTransaction) => {
  const records = (await getAllRecords(tx)) as unknown as Record<
    string,
    DataRecord
  >
  const softDeletedRecords: DeleteableRecord[] = []
  for (const r of values(records)) {
    if (isDeleteableRecord(r) && r.isSoftDeleted) {
      softDeletedRecords.splice(
        sortedIndexBy(softDeletedRecords, r, `softDeletedAt`),
        0,
        r,
      )
    }
  }
  return softDeletedRecords.reverse()
}
