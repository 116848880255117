import { first } from 'lodash'
import { useCallback, useMemo } from 'react'

import { useSelectionContext } from '../contexts/SelectionContext'
import { useRouteFromLocation } from '../routes'

export const useActiveRecordIds = (visibleItemIds?: string[]) => {
  const { focusedRecordId, selection } = useSelectionContext()
  const selectedIds = selection.ids

  const selectedIdsInVisibilityOrder = useMemo(
    () =>
      visibleItemIds
        ? [...selectedIds].sort(
            (a, b) => visibleItemIds.indexOf(b) - visibleItemIds.indexOf(a),
          )
        : selectedIds,
    [selectedIds, visibleItemIds],
  )

  const { recordId: fullscreenRecordId } = useRouteFromLocation()

  const firstActiveRecord = useCallback(
    (forcedRecordId?: string) =>
      getFirstActiveRecord(
        forcedRecordId,
        fullscreenRecordId,
        focusedRecordId,
        selectedIds,
      ),
    [fullscreenRecordId, focusedRecordId, selectedIds],
  )

  const activeRecordIfSingle = useCallback(
    (forcedRecordId?: string) =>
      getActiveRecordIfSingle(
        forcedRecordId,
        fullscreenRecordId,
        focusedRecordId,
        selectedIds,
      ),
    [fullscreenRecordId, focusedRecordId, selectedIds],
  )

  const activeRecords = useCallback(
    (forcedRecordId?: string) =>
      getActiveRecords(
        forcedRecordId,
        fullscreenRecordId,
        focusedRecordId,
        selectedIdsInVisibilityOrder,
      ),
    [fullscreenRecordId, focusedRecordId, selectedIdsInVisibilityOrder],
  )

  return useMemo(
    () => ({
      fullscreenRecordId,
      firstActiveRecord,
      activeRecordIfSingle,
      activeRecords,
    }),
    [
      fullscreenRecordId,
      firstActiveRecord,
      activeRecordIfSingle,
      activeRecords,
    ],
  )
}

function getActiveRecords(
  forcedRecordId: string | undefined,
  fullscreenRecordId: string | undefined,
  focusedRecordId: string | undefined,
  selectedIds: string[],
): string[] {
  if (forcedRecordId) return [forcedRecordId]
  if (fullscreenRecordId) return [fullscreenRecordId]
  if (selectedIds) return selectedIds
  if (focusedRecordId) return [focusedRecordId]
  return []
}

function getFirstActiveRecord(
  forcedRecordId: string | undefined,
  fullscreenRecordId: string | undefined,
  focusedRecordId: string | undefined,
  selectedIds: string[],
): string | undefined {
  const activeRecords = getActiveRecords(
    forcedRecordId,
    fullscreenRecordId,
    focusedRecordId,
    selectedIds,
  )
  return first(activeRecords)
}

function getActiveRecordIfSingle(
  forcedRecordId: string | undefined,
  fullscreenRecordId: string | undefined,
  focusedRecordId: string | undefined,
  selectedIds: string[],
): string | undefined {
  const activeRecords = getActiveRecords(
    forcedRecordId,
    fullscreenRecordId,
    focusedRecordId,
    selectedIds,
  )
  return activeRecords.length === 1 ? first(activeRecords) : undefined
}
