import { RefObject, useEffect, useRef, useState } from 'react'

export interface IUseBoundaryScroll {
  cursorPosition: number
  pointerPositionY: number
  isScrollAllowed: boolean
}

enum ScrollDirection {
  TOP = 'top',
  BOTTOM = 'bottom',
  STABLE = 'stable',
}

const EDGE_HEIGHT = 70
const scrollSpeed = 10

function getScrollDirection({
  cursorPosition,
  upperBounds = Infinity,
  lowerBounds = -Infinity,
}: {
  cursorPosition: number | undefined
  upperBounds: number | undefined
  lowerBounds: number | undefined
}): ScrollDirection {
  if (!cursorPosition) {
    return ScrollDirection.STABLE
  }
  if (cursorPosition > lowerBounds - EDGE_HEIGHT) {
    return ScrollDirection.BOTTOM
  }
  if (cursorPosition < upperBounds + EDGE_HEIGHT) {
    return ScrollDirection.TOP
  }
  return ScrollDirection.STABLE
}

export const useBoundaryScroll = (ref: RefObject<HTMLElement | null>) => {
  const [config, setConfig] = useState<Partial<IUseBoundaryScroll>>({
    isScrollAllowed: false,
    pointerPositionY: 0,
  })

  const scrollTimer = useRef<null | NodeJS.Timeout>(null)

  const { isScrollAllowed, pointerPositionY = 0 } = config

  const bounds = ref.current?.getBoundingClientRect()
  const scrollDirection = getScrollDirection({
    cursorPosition: pointerPositionY,
    upperBounds: bounds?.top,
    lowerBounds: bounds?.bottom,
  })

  // Calculate easing intensity
  let intensity = 1
  if (scrollDirection === 'top') {
    const topBound = bounds?.top ?? 0
    intensity = (topBound + EDGE_HEIGHT - pointerPositionY) / EDGE_HEIGHT
  } else if (scrollDirection === 'bottom') {
    const bottomBound = bounds?.bottom ?? 0
    intensity = (pointerPositionY - (bottomBound - EDGE_HEIGHT)) / EDGE_HEIGHT
  }

  useEffect(() => {
    if (!isScrollAllowed && scrollTimer.current) {
      clearInterval(scrollTimer.current)
      return
    }

    if (scrollDirection !== 'stable' && isScrollAllowed) {
      const scrollDirectionModifier = scrollDirection === 'top' ? -1 : 1

      scrollTimer.current = setInterval(() => {
        ref.current?.scrollBy(
          0,
          scrollSpeed * scrollDirectionModifier * intensity,
        )
      }, 1)
    }
    return () => {
      if (scrollTimer.current) {
        clearInterval(scrollTimer.current)
      }
    }
  }, [isScrollAllowed, scrollDirection, ref, scrollSpeed, pointerPositionY])

  return { updatePosition: setConfig } as const
}
