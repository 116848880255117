export enum EventType {
  Focus = `focus`,
  FocusFullScreenRecord = `focusFullScreenRecord`,
  Select = `select`,
  Scroll = `scroll`,
  Trigger = `trigger`,
}

export interface BaseEvent {
  type: EventType
  payload: any
}

export type EventHandler<T extends BaseEvent> = (event: T) => boolean
export type UnsubscribeHandler = () => void

export interface EventListener<T extends BaseEvent> {
  type: EventType
  handler: EventHandler<T>
}
