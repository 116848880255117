import Tippy, { TippyProps } from '@tippyjs/react/headless'
import React from 'react'

import { Keycap, KeycapGroup } from './Keycap'
import { Tooltip, TooltipProps, TooltipRow } from './Tooltip'
import { styled } from '../styled'

export type WithTooltipProps<T = {}> = T & {
  /**
   * @link https://atomiks.github.io/tippyjs/v6/all-props/
   */
  tippyProps?: TippyProps
  tooltip?:
    | {
        title: React.ReactNode
        keycaps?: React.ReactNode[]
      }
    | {
        title: React.ReactNode
        keycaps?: React.ReactNode[]
      }[]
  tooltipProps?: TooltipProps
}

export function withTooltip<T extends React.ElementType>(Component: T) {
  const ComponentWithTooltip: React.FC<
    WithTooltipProps<React.ComponentPropsWithoutRef<T>>
  > = ({
    tippyProps: {
      delay,
      offset,
      placement = 'bottom',
      touch,
      visible,
      ...tippyProps
    } = {},
    tooltip,
    tooltipProps,
    ...props
  }) => {
    const tooltipArr = tooltip
      ? Array.isArray(tooltip)
        ? tooltip
        : [tooltip]
      : undefined

    const ComponentType = Component as React.ElementType

    return (
      <Tippy
        render={(attrs) => (
          <Tooltip {...attrs} {...tooltipProps}>
            {tooltipArr &&
              tooltipArr.map(({ title, keycaps }, index) => (
                <TooltipRow key={index}>
                  {title}
                  {keycaps && (
                    <S.KeycapGroup>
                      {keycaps.map((keycap, index) => (
                        <Keycap key={index}>{keycap}</Keycap>
                      ))}
                    </S.KeycapGroup>
                  )}
                </TooltipRow>
              ))}
          </Tooltip>
        )}
        delay={delay ?? [200, 0]}
        offset={offset ?? [0, 0]}
        visible={visible}
        placement={placement}
        touch={touch ?? ['hold', 500]}
        {...tippyProps}
      >
        <ComponentType {...props} />
      </Tippy>
    )
  }

  return ComponentWithTooltip
}

const S = {
  KeycapGroup: styled(KeycapGroup)(() => ({
    display: 'inline-block',
    marginLeft: 6,
    /**
     * Vertically align KeycapGroup, doing this "correctly" is not worth solving
     * @link https://christopheraue.net/design/vertical-align
     */
    transform: 'translateY(-1px)',
  })),
}
