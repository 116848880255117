import { WriteTransaction } from 'replicache'

import {
  RCMutation,
  getNextSkipOccurrence,
  zonedIsoDateFromUtcAndTz,
} from '@eleventhlabs/capture-shared'

import { getTickleableRecord, updateTickler } from './utils'

export const skipOccurrences = async (
  tx: WriteTransaction,
  { recordIds, executedAtOnClient, tzOnClient }: RCMutation.SkipOccurrencesArgs,
) => {
  for (const recordId of recordIds) {
    await skipOccurrence(tx, recordId, executedAtOnClient, tzOnClient)
  }
  // TODO:
  // Group by nextOccurrenceDate (dateGroup)
  // For each dateGroup, setFIndexes so skipped are at start
}

const skipOccurrence = async (
  tx: WriteTransaction,
  recordId: string,
  executedAtOnClient: number,
  tzOnClient?: string,
) => {
  const record = await getTickleableRecord(tx, recordId)
  if (record.tickler?.recurrence === undefined) return

  const todayIsoWhenExecutedOnClient = zonedIsoDateFromUtcAndTz(
    executedAtOnClient,
    tzOnClient,
  )
  const { isoDate: nextIsoDate } = getNextSkipOccurrence(
    record.tickler,
    todayIsoWhenExecutedOnClient,
  )
  if (nextIsoDate === undefined) throw new Error(``)

  await updateTickler(tx, record, { isoDate: nextIsoDate })
}
