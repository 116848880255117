import { useCallback } from 'react'

import { useNavigate } from '@gatsbyjs/reach-router'

import { focusAction, scrollAction } from '../events'
import { getListUrl } from '../routes'
import { immediateExecute } from '../utils'

export interface PostNavigateOptions {
  focus?: boolean
  scroll?: boolean
}

export const useNavigateToList = () => {
  const navigate = useNavigate()

  const navigateToList = useCallback(
    (id: string, options: PostNavigateOptions = { scroll: true }) => {
      navigate(getListUrl(id)).then(() => {
        if (options.scroll !== false)
          immediateExecute(() =>
            scrollAction({ target: `outline`, to: { id } }),
          )
        if (options.focus) focusAction({ id })
      })
    },
    [navigate],
  )

  return navigateToList
}
