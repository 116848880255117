import React from 'react'
import { ListChildComponentProps } from 'react-window'

import { OutlineListItemProps } from './OutlineListItem'
import { OutlineListItemData } from './OutlineListItemData'

export interface OutlineListItemRowProps
  extends Omit<ListChildComponentProps, 'style'> {
  data: OutlineListItemData
}

export const withPropsForSingleRow = <T,>(
  WrappedComponent: React.FC<OutlineListItemProps & { index: number }>,
) => {
  const OutlineListItemRow: React.FC<T & OutlineListItemRowProps> = ({
    index,
    data,
  }) => {
    const { items, activeItem } = data
    const item = items[index]
    const isActive = activeItem === item.id
    return <WrappedComponent index={index} isActive={isActive} {...item} />
  }
  return OutlineListItemRow
}
