import React from 'react'

import { RouteIcon } from '../../RouteIcon'
import { styled } from '../../../common/stationary'
import {
  RouteType,
  SystemListsRouteType,
  isSystemListId,
} from '../../../common/routes'

export interface ResultItemPreProps {
  id?: string
  emoji?: string
}

const ResultItemPre = ({ emoji, id }: ResultItemPreProps) => (
  <S.Content>
    <RouteIcon
      boxSize={16}
      routeType={
        id && isSystemListId(id) ? SystemListsRouteType[id] : RouteType.List
      }
      value={emoji ? { emoji } : undefined}
    />
  </S.Content>
)

export default ResultItemPre

const S = {
  Content: styled.div({
    display: `flex`,
    justifyContent: `flex-end`,
    paddingRight: 8,
    width: 32,
    userSelect: `none`,
  }),
}
