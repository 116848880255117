import { useMemo } from 'react'

import { RecordDict } from '@eleventhlabs/capture-shared'
import { ModelStoreCommands } from '../../common/data/modelStore'
import { RecordGroupsCommands } from '../../common/data/recordGroups'
import { createInboxRecordGroupsCommands } from '../../common/data/recordGroups/commands/inbox'
import { createListRecordGroupsCommands } from '../../common/data/recordGroups/commands/list'
import { createTodayRecordGroupsCommands } from '../../common/data/recordGroups/commands/today'
import { useStartOfTodayISO } from '../../common/hooks/useStartOfTodayISO'
import { RouteType } from '../../common/routes'

export const defaultRecordGroupCommands: RecordGroupsCommands = {
  createAtFirstInsertPosition: () => undefined,
  createAtIndexInGroup: () => undefined,
  addItemAtBottomOfGroup: () => undefined,
  createBelowAnchor: () => undefined,
  moveBelowAnchor: () => undefined,
  moveAboveAnchor: () => undefined,
  moveToStartOfGroup: () => undefined,
  moveToEndOfGroup: () => undefined,
}

export const useRecordGroupCommands = (
  routeType: RouteType,
  records: RecordDict,
  commands: ModelStoreCommands,
  activeDateIso: string | undefined,
  listId: string | undefined,
): RecordGroupsCommands => {
  const todayIso = useStartOfTodayISO().today
  let recordGroupCommands = defaultRecordGroupCommands

  switch (routeType) {
    case RouteType.Today:
      recordGroupCommands = createTodayRecordGroupsCommands(
        activeDateIso ?? todayIso,
        todayIso,
        records,
        commands,
      )
      break
    case RouteType.List: {
      if (!listId) throw new Error(`Cannot find listId`)
      recordGroupCommands = createListRecordGroupsCommands(
        listId,
        records,
        commands,
      )
      break
    }
    case RouteType.Inbox:
      recordGroupCommands = createInboxRecordGroupsCommands(records, commands)
      break
  }

  return useMemo(
    () => recordGroupCommands,
    [activeDateIso, todayIso, listId, records, commands, routeType], // eslint-disable-line
  )
}
