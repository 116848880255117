import React, { useCallback, useRef } from 'react'

import { Modal } from '../../../common/dialogs/components/Modal'
import { useHotkeysThreshold } from '../../hooks/useHotkeysThreshold'
import { Key, Priorities, useKeys } from '../../hooks/useKeys'
import CommandBar from '../component/CommandBar'
import { useCommandBar } from '../component/hooks/useCommandBar'
import { styled } from '../../../common/stationary'

interface CommandBarDialogProps {
  isOpen?: boolean
  selectedRecordIds: string[]
  onDismiss: (e?: React.SyntheticEvent) => void
}

export const CommandBarDialog: React.FC<CommandBarDialogProps> = ({
  isOpen,
  selectedRecordIds,
  onDismiss,
}) => {
  // const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const close = useCallback(() => onDismiss && onDismiss(), [onDismiss])

  useKeys(Key.Esc, close, Priorities.Dialog, true)
  useHotkeysThreshold(Priorities.Dialog)

  const { query, groupData, handlers } = useCommandBar(close)

  return (
    <Modal
      // ref={ref}
      aria-label="CommandBar"
      open={isOpen ?? false}
      // style={{
      //   maxWidth: CommandBarDimension.maxWidth,
      //   maxHeight: CommandBarDimension.maxHeight,
      // }}
      onClose={close}
    >
      <S.CommandBar>
        <CommandBar
          query={query}
          inputRef={inputRef}
          placeholder="Type a command..."
          groupData={groupData}
          handlers={handlers}
          selectionCount={selectedRecordIds.length}
        />
      </S.CommandBar>
    </Modal>
  )
}

export default CommandBarDialog

export const CommandBarDimension = {
  maxHeight: 400,
  maxWidth: 576,
}

const S = {
  CommandBar: styled.div({
    marginTop: 96,
    width: `100%`,
    maxHeight: 400,
    maxWidth: 576,
    height: `fit-content`,
    background: `white`,
    borderRadius: `10px`,
    // margin: `0 auto`,
    overflow: `hidden`,
  }),
}
