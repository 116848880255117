import { isEmpty } from 'lodash'

import { DataRecord, isNoteableRecord } from '@eleventhlabs/capture-shared'

import {
  AnnotationCreatedTriggerValueType,
  ShowAnnotationsToggleTriggerValueType,
} from '../../../../analytics/capture-analytics-actions'
import { RecordItemTargetType } from '../../../../recordItem/RecordItemTargetType'

export const openAnnotations = (
  records: Record<string, DataRecord>,
  recordIds: string[],
  shouldDisplayNotesForRecordId: (id: string) => boolean,
  addNoteAtBottomOfList:
    | ((
        parentId: string,
        triggerCreatedAnnotation: AnnotationCreatedTriggerValueType,
        triggerShowAnnotation: ShowAnnotationsToggleTriggerValueType,
      ) => void)
    | undefined,
  setNotesVisibilityForRecordIds: (ids: string[], visibility: boolean) => void,
  showAnnotationsTrigger: ShowAnnotationsToggleTriggerValueType,
  createAnnotationTrigger: AnnotationCreatedTriggerValueType,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
) => {
  let notesOpened = 0
  let notesCreated = 0
  let newNoteId: string | undefined

  if (recordIds.length === 0) {
    return {
      notesCreated,
      notesOpened,
    }
  }

  const selectedRecords = recordIds.map((id) => records[id])

  if (selectedRecords.length === 1) {
    const record = selectedRecords[0]
    if (!isNoteableRecord(record)) {
      return {
        notesCreated,
        notesOpened,
      }
    }
    const hasNotes = !isEmpty(record.notes)
    if (hasNotes) {
      const firstNote = (record?.notes ?? [])[0]
      if (shouldDisplayNotesForRecordId(record.id))
        // set recentlyCreatedRecords
        setRecentlyCreatedRecords((recentlyCreatedRecords) => ({
          ...recentlyCreatedRecords,
          [firstNote]: RecordItemTargetType.Text,
        }))
      else {
        setNotesVisibilityForRecordIds([record.id], true)
        // set recentlyCreatedRecords
        setRecentlyCreatedRecords((recentlyCreatedRecords) => ({
          ...recentlyCreatedRecords,
          [firstNote]: RecordItemTargetType.Text,
        }))
      }
      notesOpened += 1
    } else {
      const newId =
        addNoteAtBottomOfList &&
        addNoteAtBottomOfList(
          selectedRecords[0].id,
          createAnnotationTrigger,
          showAnnotationsTrigger,
        )
      notesCreated += 1
      if (newId) newNoteId = newId
    }
  } else {
    const recordsWithNotes = selectedRecords
      .filter((record) => isNoteableRecord(record) && !isEmpty(record.notes))
      .map((record) => record.id)
    setNotesVisibilityForRecordIds(recordsWithNotes, true)

    notesOpened += recordsWithNotes.length
  }

  return {
    notesCreated,
    notesOpened,
    newNoteId,
  }
}
