// Taken from https://gist.github.com/gragland/a32d08580b7e0604ff02cb069826ca2f

import { useState, useRef, useEffect, MutableRefObject } from 'react'

export const useHover = <T>(): [MutableRefObject<T>, boolean] => {
  const [value, setValue] = useState<boolean>(false)
  const ref: any = useRef<T | null>(null)

  const handleMouseEnter = (): void => setValue(true)
  const handleMouseLeave = (): void => setValue(false)

  useEffect(
    () => {
      const node: any = ref.current

      if (node) {
        node.addEventListener('mouseenter', handleMouseEnter)
        node.addEventListener('mouseleave', handleMouseLeave)

        return () => {
          node.removeEventListener('mouseenter', handleMouseEnter)
          node.removeEventListener('mouseleave', handleMouseLeave)
        }
      }
    },
    [ref.current], // Recall only if ref changes
  )

  return [ref, value]
}
