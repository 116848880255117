// Used for Android and iOS deep linking.
import React, { useEffect } from 'react'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Env } from '../common/env'

const AppUrlListener: React.FC<any> = () => {
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // LOCAL DEV: change to ngrok url
      const slug = event.url.split(Env.customAppDeeplinkProtocol).pop()
      if (slug) {
        window.location.replace(slug)
      }
      // If no match, do nothing - let regular routing
      // logic take over
    })
  }, [])

  return null
}

export default AppUrlListener
