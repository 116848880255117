import { parseISO, startOfToday } from 'date-fns'
import { map, values } from 'lodash'
import React, { useCallback } from 'react'

import {
  RecurrenceFrequency,
  RecurrenceProp,
} from '@eleventhlabs/capture-shared'
import {
  doesRecurrenceMatchDate,
  getByDayOfWeekFromDate,
  getDayOfMonthFromDate,
  getDayOfWeekFromDate,
  getFrequencyTitle,
  getMonthOfYearFromDate,
  getWeekOfMonthFromDate,
} from '../../../../common/utils'
import { RecurrenceModel } from '../../Schedule.types'
import { css, cx, styled } from '../../../../common/stationary'

interface RecurrenceOption {
  id: string
  value: (date: Date) => RecurrenceProp | undefined
}

const recurrenceOptions: Record<string, RecurrenceOption> = {
  [`NoRepeat`]: {
    id: `NoRepeat`,
    value: () => undefined,
  },
  [RecurrenceFrequency.Daily]: {
    id: RecurrenceFrequency.Daily,
    value: () => ({
      frequency: RecurrenceFrequency.Daily,
      interval: 1,
      byDayOfWeek: [],
    }),
  },
  [RecurrenceFrequency.Weekly]: {
    id: RecurrenceFrequency.Weekly,
    value: (date: Date) => ({
      frequency: RecurrenceFrequency.Weekly,
      interval: 1,
      byDayOfWeek: [getDayOfWeekFromDate(date)],
    }),
  },
  [RecurrenceFrequency.Monthly]: {
    id: RecurrenceFrequency.Monthly,
    value: (date: Date) => ({
      frequency: RecurrenceFrequency.Monthly,
      interval: 1,
      byDayOfWeek: [
        {
          dayOfWeek: getDayOfWeekFromDate(date),
          weekOfMonth: getWeekOfMonthFromDate(date),
        },
      ],
    }),
  },
  [RecurrenceFrequency.Yearly]: {
    id: RecurrenceFrequency.Yearly,
    value: (date: Date) => ({
      frequency: RecurrenceFrequency.Yearly,
      interval: 1,
      byDayOfWeek: [
        {
          dayOfMonth: getDayOfMonthFromDate(date),
          monthOfYear: getMonthOfYearFromDate(date),
        },
      ],
    }),
  },
}

export interface RecurrenceInputProps {
  date: string | undefined
  value: RecurrenceModel | undefined
  setRecurrence: (recurrence: RecurrenceModel | null) => void
}

export const RecurrenceInput = ({
  date,
  value,
  setRecurrence,
}: RecurrenceInputProps) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event?.target?.value
      if (!date) return
      setRecurrence(recurrenceOptions[value]?.value(parseISO(date)) ?? null)
    },
    [date, setRecurrence],
  )

  const doesRecurrenceMatch = date
    ? doesRecurrenceMatchDate(parseISO(date), value as RecurrenceProp)
    : true
  const selectedId = doesRecurrenceMatch
    ? value?.frequency ?? `NoRepeat`
    : `custom`

  return (
    <S.Tag>
      <S.Select
        disabled={!date}
        className={cx({
          noRepeat: !value?.frequency,
        })}
        // classNameInner={css({
        //   userSelect: `none`,
        //   lineHeight: `20px`,
        // })}
        onChange={onChange}
        // sizeOverride={{
        //   dimension: {
        //     height: 26,
        //     paddingHorizontal: 6,
        //   },
        //   style: {},
        // }}
        value={selectedId}
      >
        {map(values(recurrenceOptions), (mode) => (
          <option value={mode.id} key={mode.id}>
            {getFrequencyTitle(
              mode.id as RecurrenceFrequency,
              getByDayOfWeekFromDate(
                mode.id as RecurrenceFrequency,
                date ? parseISO(date) : startOfToday(),
              ),
            )}
          </option>
        ))}
        {!doesRecurrenceMatch && value ? (
          <option value={`custom`}>
            {getFrequencyTitle(value.frequency, value.byDayOfWeek)}
          </option>
        ) : null}
      </S.Select>
    </S.Tag>
  )
}

const S = {
  Tag: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    cursor: `pointer`,
    border: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    borderRadius: 6,
  })),
  Select: styled.select(({ theme }) => ({
    display: `flex`,
    fontWeight: 500,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `26px`,
    height: `26px`,
    color: theme.colors.text[900],
    borderRadius: 6,
    userSelect: `none`,
    width: 200,
    padding: '0 12px',

    '&.noRepeat': {
      color: theme.colors.text[700],
    },

    '& select': {
      outline: `none`,
    },
  })),
}
