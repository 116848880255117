import { useCallback, useMemo } from 'react'

import { useExecContext } from '../../../common/contexts/ExecContext'
import { useUIContext } from '../../../common/contexts/UIContext'
import { focusAction, triggerAction } from '../../../common/events'
import { ModalName } from '../../../common/modals/core/modalConfig'
import { useModal } from '../../../common/modals/core/hooks/useModal'
import { MenuAction } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'
import { createInputId } from '../../../common/utils/input'
import { useShowCompletedAction } from '../actions/useShowCompletedAction'

export function useListScreenActions(listId?: string) {
  const { exec } = useExecContext()
  const { setShowProfileActions, showCompleted } = useUIContext()
  const { openModal } = useModal()

  const showCompletedAction = useShowCompletedAction(showCompleted)

  const inputId = createInputId(listId)

  const onEditClick = useCallback(
    (event) => {
      event.preventDefault()
      focusAction({ id: inputId })
    },
    [inputId],
  )

  const onDeleteClick = useCallback(
    (event) => {
      event.preventDefault()

      openModal(ModalName.CONFIRM_DELETE_LIST_MODAL, {
        listId: listId ?? '',
      })

      setShowProfileActions(false)
    },
    [listId, exec, setShowProfileActions],
  )

  const openEmojiPicker = useCallback((event) => {
    event.preventDefault()
    triggerAction({ target: `open-list-emoji-picker` })
  }, [])

  const actions: MenuAction[] = useMemo(() => {
    const actions: MenuAction[] = [
      {
        type: `menuItem`,
        menuItemProps: {
          onClick: onEditClick,
          onMouseDown: (e) => e.preventDefault(),
          iconProps: {
            variant: 'glyphCaret',
          },
          title: `Rename`,
        },
      },
      {
        type: `menuItem`,
        menuItemProps: {
          onClick: openEmojiPicker,
          onMouseDown: (e) => e.preventDefault(),
          iconProps: {
            variant: 'glyphStar',
          },
          title: `Change Emoji`,
        },
      },
      {
        type: `menuItem`,
        menuItemProps: {
          onClick: onDeleteClick,
          onMouseDown: (e) => e.preventDefault(),
          iconProps: {
            variant: 'glyphTrash',
          },
          title: `Delete`,
        },
      },
    ]

    // Show completed first on mobile
    if (isMobile) {
      actions.unshift(showCompletedAction)
    }

    return actions
  }, [onEditClick, openEmojiPicker, onDeleteClick, showCompleted])

  return actions
}
