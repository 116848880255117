import { some } from 'lodash'

import { ModelStore } from '../../../../data/modelStore'
import { CompleteableRecord, RecordDict } from '@eleventhlabs/capture-shared'

export type ToggleRecordsIsCompleted =
  | {
      succeeded: true
      payload: {
        setToValue: boolean
        count: number
        affectedRecordIds: string[]
      }
    }
  | { succeeded: false }

export const toggleIsCompleted = (
  targetId: string | undefined,
  activeIds: string[],
  records: ModelStore['records'],
  _completeRecords: (recordIds: string[]) => void,
  _uncompleteRecords: (recordIds: string[]) => void,
): ToggleRecordsIsCompleted => {
  if (targetId === undefined && activeIds.length === 0) {
    return { succeeded: false }
  }

  const recordIds = targetId ? [targetId] : activeIds

  const areAnyNotComplete = getAreAnyNotComplete(recordIds, records)
  const isCompleting = areAnyNotComplete
  const recordIdsToUpdate = recordIds.filter((id) => {
    const record = records[id] as CompleteableRecord
    return record.isCompleted !== isCompleting
  })

  if (areAnyNotComplete) _completeRecords(recordIdsToUpdate)
  else _uncompleteRecords(recordIdsToUpdate)

  return {
    succeeded: true,
    payload: {
      setToValue: isCompleting,
      count: recordIdsToUpdate.length,
      affectedRecordIds: recordIdsToUpdate,
    },
  }
}

export const getAreAnyNotComplete = (
  recordIds: string[],
  records: RecordDict,
): boolean => {
  return some(
    recordIds.map((id) => {
      const record = records[id] as CompleteableRecord
      return record ? !record.isCompleted : false
    }),
  )
}
