import React from 'react'

import {
  MenuAction,
  Switch,
  styled,
  withTooltip,
} from '../../../common/stationary'

export interface QuickActionsProps {
  actions: MenuAction[]
}

export const QuickActions: React.FC<QuickActionsProps> = ({ actions }) => {
  return (
    <S.QuickActions>
      {actions.map(({ type, menuItemProps }, index) => {
        switch (type) {
          case `menuItem`:
            if (menuItemProps?.switchProps)
              return (
                <S.QuickAction
                  key={index}
                  tooltip={{
                    title: menuItemProps?.title,
                    keycaps: menuItemProps.keycaps,
                  }}
                >
                  {menuItemProps?.title}
                  <Switch {...menuItemProps.switchProps} />
                </S.QuickAction>
              )
          case `divider`:
          default:
            return null
        }
      })}
    </S.QuickActions>
  )
}

export default QuickActions

const S = {
  QuickActions: styled.div({
    display: `flex`,
    alignItems: `center`,
  }),
  QuickAction: styled(withTooltip('label'))(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '8px 12px',
    borderRadius: 8,
    ...theme.text.publicSans['13.5:20'],
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',

    '&:hover': {
      background: theme.colors.alpha.tone.weak,
    },
    '&:active': {
      background: theme.colors.alpha.tone.mediumWeak,
    },
  })),
}
