import { TippyProps } from '@tippyjs/react'
import React, { useEffect, useMemo, useState } from 'react'

import { useSelectionContext } from '../common/contexts/SelectionContext'
import { useKeyCaps } from '../common/keyMappableActions/useKeyCaps'
import {
  Icon,
  cx,
  styled,
  styledMixin,
  withTooltip,
} from '../common/stationary'
import { useExec } from '../common/useExec'
import { getAreAnyNotComplete } from '../common/useExec/groups/default/actions'
import { useModelStoreContext } from '../common/contexts/ModelStoreContext'
import { NoteableRecord } from '@eleventhlabs/capture-shared'
import { useUIContext } from '../common/contexts/UIContext'

// Shared tippyProps for every ActionButton
const actionButtonTippyProps: TippyProps = {
  placement: 'top',
  trigger: 'mouseenter', // Necessary to prevent Tippy from appearing on focus after a Modal is closed
}

export function SelectionActionBar() {
  const exec = useExec()
  const { selection, selectedRecords } = useSelectionContext()
  const keyCaps = useKeyCaps()
  const { store } = useModelStoreContext()
  const { shouldDisplayNotesForRecordId } = useUIContext()

  // The count of selectedRecords that we want to render in SelectedCount
  const [stableCount, setStableCount] = useState(0)

  // Set stableCount on selectedRecords.length change
  useEffect(() => {
    const nextCount = selectedRecords.length

    // To prevent SelectedCount "flash", only set stableCount if nextCount > 0
    if (nextCount > 0) setStableCount(nextCount)
  }, [setStableCount, selectedRecords.length])

  // Whether the selection should archive or unarchive on ActionButton click
  const selectionShouldArchive = getAreAnyNotComplete(
    selection.ids,
    store.records,
  )

  // Whether the selection should show or hide annotation on ActionButton click
  const selectionShouldShowAnnotation = selectedRecords[0]
    ? !shouldDisplayNotesForRecordId(selectedRecords[0].id)
    : true

  // Configure ActionButton onClick and tooltip props
  const actionButtonProps = useMemo(
    () => ({
      close: {
        onClick: () => {
          selection.clear()
        },
        tooltip: {
          title: 'Clear selection',
          keycaps: ['Esc'],
        },
      },
      archive: {
        onClick: () => {
          exec({
            type: 'RECORDS_TOGGLE_IS_COMPLETED',
            trigger: 'Selection Action Bar',
          })
        },
        tooltip: {
          title: selectionShouldArchive ? 'Archive' : 'Unarchive',
          keycaps: keyCaps['KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE'],
        },
      },
      moveTo: {
        onClick: () => {
          exec({
            type: 'OPEN_MOVE_TO_DIALOG',
            trigger: 'Selection Action Bar',
          })
        },
        tooltip: {
          title: 'Move',
          keycaps: keyCaps['KEY_MAPPED_OPEN_MOVE_TO_DIALOG'],
        },
      },
      schedule: {
        onClick: () => {
          exec({
            type: 'OPEN_SCHEDULE_DIALOG',
            trigger: 'Selection Action Bar',
          })
        },
        tooltip: {
          title: 'Schedule',
          keycaps: keyCaps['KEY_MAPPED_OPEN_SCHEDULE_TO_DIALOG'],
        },
      },
      annotate: {
        onClick: () => {
          exec({
            type: 'TOGGLE_ANNOTATIONS',
            showAnnotationsTrigger: 'Selection Action Bar',
            createAnnotationTrigger: 'Selection Action Bar',
          })
        },
        tooltip: {
          title: selectionShouldShowAnnotation
            ? 'Show annotation'
            : 'Hide annotation',
          keycaps: keyCaps['KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN'],
        },
      },
      fullPage: {
        onClick: () => {
          exec({
            type: 'EXPAND_TO_FULLSCREEN',
            trigger: 'Selection Action Bar',
            triggeredByKeyboard: false,
            triggeredByUI: true,
          })
        },
        tooltip: {
          title: 'Open note view',
          keycaps: keyCaps['KEY_MAPPED_FULLSCREEN_OPEN'],
        },
      },
      moveToTrash: {
        onClick: () => {
          exec({
            type: 'MOVE_RECORDS_TO_TRASH',
            trigger: `Selection Action Bar`,
            triggeredByKeyboard: false,
            triggeredByUI: true,
          })
        },
        tooltip: {
          title: 'Move to Trash',
          keycaps: keyCaps['KEY_MAPPED_RECORD_DELETE'],
        },
      },
    }),
    [exec, selection, keyCaps],
  )

  return (
    <S.SelectionActionBar
      className={cx({
        isVisible: selection.shouldShowSelectionActionBar,
      })}
    >
      <S.ActionBarGroup>
        <S.ActionButtonWithTooltip
          tippyProps={actionButtonTippyProps}
          {...actionButtonProps.close}
        >
          <Icon boxSize={16} variant="glyphX" />
        </S.ActionButtonWithTooltip>
        <S.SelectedCount>{stableCount} selected</S.SelectedCount>
      </S.ActionBarGroup>
      <S.Divider />
      <S.ActionBarGroup>
        <S.ActionButtonWithTooltip
          tippyProps={actionButtonTippyProps}
          {...actionButtonProps.archive}
        >
          <Icon
            boxSize={16}
            variant={
              selectionShouldArchive ? 'glyphCheckCircle' : 'glyphArrowNCircle'
            }
          />
        </S.ActionButtonWithTooltip>
        <S.ActionButtonWithTooltip
          tippyProps={actionButtonTippyProps}
          {...actionButtonProps.moveTo}
        >
          <Icon boxSize={16} variant="glyphArrowESquircle" />
        </S.ActionButtonWithTooltip>
        <S.ActionButtonWithTooltip
          tippyProps={actionButtonTippyProps}
          {...actionButtonProps.schedule}
        >
          <Icon boxSize={16} variant="glyphCalendarSingleSquircle" />
        </S.ActionButtonWithTooltip>
        <S.ActionButtonWithTooltip
          disabled={selectedRecords.length > 1}
          tippyProps={actionButtonTippyProps}
          {...actionButtonProps.annotate}
        >
          <Icon boxSize={16} variant="glyphBlurbSquircle" />
        </S.ActionButtonWithTooltip>
      </S.ActionBarGroup>
      <S.Divider />
      <S.ActionBarGroup>
        <S.ActionButtonWithTooltip
          disabled={selectedRecords.length > 1}
          tippyProps={actionButtonTippyProps}
          {...actionButtonProps.fullPage}
        >
          <Icon boxSize={16} variant="glyphArrowNeSw" />
        </S.ActionButtonWithTooltip>
      </S.ActionBarGroup>
      <S.Divider />
      <S.ActionBarGroup>
        <S.ActionButtonWithTooltip
          tippyProps={actionButtonTippyProps}
          {...actionButtonProps.moveToTrash}
        >
          <Icon boxSize={16} variant="glyphTrash" />
        </S.ActionButtonWithTooltip>
      </S.ActionBarGroup>
    </S.SelectionActionBar>
  )
}

const ACTION_BAR_HEIGHT = 44

const ACTION_BUTTON_HEIGHT = 32

const ACTION_BAR_SPACING = (ACTION_BAR_HEIGHT - ACTION_BUTTON_HEIGHT) / 2

const ActionButtonMixin = styledMixin`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 6px;

  transition: opacity 0.1s ease;

  :before {
    content: "";
    position: absolute;
    inset: -2px;
  }
  svg {
    font-size: 20px;
  }
  :hover:not(:disabled) {
    background: ${({ theme }) => theme.colors.alpha.tone.weak};
    cursor: pointer;
  }
  :disabled {
    opacity: 0.4;
  }
`

const S = {
  SelectionActionBar: styled.div`
    display: flex;
    align-items: center;
    gap: ${ACTION_BAR_SPACING}px;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    height: ${ACTION_BAR_HEIGHT}px;
    padding: ${ACTION_BAR_SPACING}px;
    border: 1px solid ${({ theme }) => theme.colors.alpha.tone.strong};
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.effects.boxShadow.elevation[2]};
    background: ${({ theme }) => theme.colors.surface.base};
    visibility: hidden;
    transform: scale(0.99);

    &.isVisible {
      visibility: visible;
      transform: translateY(0);

      // This will transition to (but not from) .isVisible
      transition: transform 0.1s ease;
    }
  `,
  ActionBarGroup: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
  `,
  ActionButton: styled.button`
    ${ActionButtonMixin};
  `,
  ActionButtonWithTooltip: styled(withTooltip('button'))`
    ${ActionButtonMixin};
  `,
  SelectedCount: styled.div`
    min-width: 78px; // Prevent layout shift for single digit numbers
    padding: 0 ${ACTION_BAR_SPACING}px;
    ${({ theme }) => theme.text.publicSans['13.5:20']};
  `,
  Divider: styled.div`
    height: 16px;
    width: 1px;
    margin: 0 ${ACTION_BAR_SPACING}px;
    background: ${({ theme }) => theme.colors.alpha.tone.medium};
  `,
}
