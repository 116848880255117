import React from 'react'

import DialogInput from '../../DialogInput'
import { Icon, styled } from '../../../common/stationary'
import { GO_TO, GO_TO_PLACEHOLDER } from '../../../common/utils/uiConstants'

export interface QuickSwitcherHeaderProps {
  inputRef?: React.RefObject<HTMLInputElement>
  inputValue?: string
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export const QuickSwitcherHeader = ({
  inputRef,
  inputValue = ``,
  onChange,
  onKeyDown,
  placeholder,
}: QuickSwitcherHeaderProps) => {
  return (
    <S.Header>
      <S.InfosContainer>
        <S.InfosLeft>
          <S.InfoTitle>
            <S.Icon boxSize={12} variant="glyphArrowRight12" />
            <S.Title>{GO_TO}</S.Title>
          </S.InfoTitle>
        </S.InfosLeft>
      </S.InfosContainer>
      <S.Input
        inputRef={inputRef}
        onKeyDown={onKeyDown}
        placeholder={GO_TO_PLACEHOLDER}
        inputValue={inputValue}
        onChange={onChange}
      />
    </S.Header>
  )
}

export default QuickSwitcherHeader

const S = {
  Header: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `column`,
    padding: 16,
    userSelect: `none`,
    borderBottom: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
  })),
  InfosContainer: styled.div({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
  }),
  InfosLeft: styled.ul({
    flex: 1,
    display: `flex`,
  }),
  InfosRight: styled.ul({
    display: `flex`,
    height: `100%`,
  }),
  InfoTitle: styled.ul(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    boxShadow: `0 0 0 1px ${theme.colors.alpha.border.weak}`,
    borderRadius: 6,
    outline: `none`,
    userSelect: `none`,
    padding: `2px 6px 2px 4px`,
  })),
  Icon: styled(Icon)(({ theme }) => ({
    marginRight: 4,
    color: theme.colors.text[500],
  })),
  Title: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    fontWeight: `bold`,
    lineHeight: `20px`,
    color: theme.colors.text[500],
  })),
  InfoSelected: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-end`,
    outline: `none`,
    userSelect: `none`,
  }),
  InfoTagStatic: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: `0 4px`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    color: theme.colors.text[500],
  })),
  Input: styled(DialogInput)({
    padding: `8px 0 0 0`,
  }),
}
