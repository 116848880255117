import {
  KeycapRootVariantName,
  keycapStyles,
  KeycapUnstyled,
  KeycapUnstyledProps,
} from '@eleventhlabs/stationary'

import { excludeItems, styled } from '../styled'

export type KeycapVariantName = KeycapRootVariantName

export interface KeycapStyledProps {
  variant?: KeycapVariantName
}

export type KeycapProps = KeycapUnstyledProps & KeycapStyledProps

export const Keycap = styled(KeycapUnstyled, {
  shouldForwardProp: excludeItems([`variant`]),
})<KeycapStyledProps>(({ theme, variant = `gray` }) => ({
  display: `inline-block`,
  height: 16,
  minWidth: 16,
  paddingLeft: 4,
  paddingRight: 4,
  borderRadius: 3,
  ...theme.text.publicSans[`11.5:16`],
  textAlign: 'center',

  ...(variant && keycapStyles.rootVariants[variant]({ theme })),
}))

export const KeycapGroup = styled.div({
  height: 16,
  fontSize: 0,

  '>*:not(:only-of-type):not(:last-of-type)': {
    marginRight: 2,
  },
})
