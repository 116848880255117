import { isEmpty } from 'lodash'
import { triggerAction } from '../../../../events'
import { BaseSelection, findNearestValidId } from '../../../../libs/selection'

export const softDeleteRecords = (
  visibleItemIds: string[],
  selection: BaseSelection,
  softDelete: (records: string[]) => void,
  recordsToSoftDelete?: string[],
) => {
  const recordsBeingSoftDeleted = recordsToSoftDelete || selection.ids
  if (isEmpty(recordsBeingSoftDeleted)) return
  const newDefaultId = findNearestValidId(
    visibleItemIds,
    recordsBeingSoftDeleted,
  )
  triggerAction({
    target: `update-default`,
    invalidIds: recordsBeingSoftDeleted,
  })

  softDelete(recordsBeingSoftDeleted)
  return recordsBeingSoftDeleted.length
}
