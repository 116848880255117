import { filter, isEmpty, isUndefined } from 'lodash'
import React, { useCallback, useMemo } from 'react'

import { useModelStoreContext } from '../../../contexts/ModelStoreContext'
import { useUIContext } from '../../../contexts/UIContext'
import { useActiveRecordIds } from '../../../hooks/useActiveRecordIds'
import { useRouteFromLocation } from '../../../routes'
import {
  CommandBarDomain,
  GroupItem,
  ItemType,
  ResultGroup,
} from '../CommandBar.types'
import ResultItemContent from '../components/ResultItemContent'
import ResultItemPost from '../components/ResultItemPost'
import ResultItemPre from '../components/ResultItemPre'
import { domainsToItems, filterItems } from '../helpers'
import { useThemeName } from '../../../contexts/ThemeNameContext'

export const useCommandBarData = (
  commandBarDomains: CommandBarDomain[],
  query: string | undefined,
  selectedIndex: number | undefined,
  recentPicks: string[],
): ResultGroup => {
  const { themeName } = useThemeName()
  const { routeType } = useRouteFromLocation()
  const { store } = useModelStoreContext()
  const {
    showCompleted,
    isLeftHidden,
    shouldDisplayNotesForRecordId,
    todayFilters: { showNextFourteenDays },
    recordItemExpandState,
  } = useUIContext()
  const { activeRecords } = useActiveRecordIds()

  const selectedRecords = useMemo(
    () => activeRecords().map((id) => store.records[id]),
    [activeRecords, store.records],
  )
  const groupItems: GroupItem[] = useMemo(() => {
    let filteredItems = filterItems(
      domainsToItems(commandBarDomains),
      query ?? ``,
      routeType,
      activeRecords(),
      selectedRecords,
      themeName,
      showCompleted,
      isLeftHidden,
      showNextFourteenDays,
      shouldDisplayNotesForRecordId,
      recordItemExpandState,
    )
    if (isEmpty(filteredItems))
      filteredItems = filterItems(
        domainsToItems(commandBarDomains),
        query ?? ``,
        routeType,
        activeRecords(),
        selectedRecords,
        themeName,
        showCompleted,
        isLeftHidden,
        showNextFourteenDays,
        shouldDisplayNotesForRecordId,
        recordItemExpandState,
        false,
      )

    if (isEmpty(query)) {
      return filter(
        [
          ...recentPicks.map((id) =>
            filteredItems.find((item) => item.id === id),
          ),
          ...filteredItems.filter((item) => !recentPicks.includes(item.id)),
        ],
        (item) => !isUndefined(item),
      ) as GroupItem[]
    }

    return filteredItems
  }, [
    query,
    recentPicks,
    routeType,
    selectedRecords,
    activeRecords,
    themeName,
    showCompleted,
    isLeftHidden,
    showNextFourteenDays,
    shouldDisplayNotesForRecordId,
  ])

  const isSelected = useCallback(
    (index: number) => index === selectedIndex,
    [selectedIndex],
  )

  return useMemo(
    () => ({
      items: groupItems.map((item, index) => ({
        id: item.id,
        type: item.type,
        renderPre:
          item.type === ItemType.Action ? (
            <ResultItemPre variant={item.icon} />
          ) : undefined,
        renderContent: (
          <ResultItemContent
            type={item.type}
            content={item.title}
            isActive={index === selectedIndex}
          />
        ),
        renderPost: <ResultItemPost shortcut={item.shortcut} />,
        isSelected,
      })),
    }),
    [groupItems, selectedIndex, isSelected],
  )
}
