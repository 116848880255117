import { useEffect } from 'react'
import Analytics, { ScreenTypeValueType } from '../../capture-analytics-actions'

const useScreenLoadedAnalytics = (
  screenType: ScreenTypeValueType,
  screenId: string,
) => {
  useEffect(() => {
    Analytics.screenLoaded({ screenType, screenId })
  }, [screenId])
}
export default useScreenLoadedAnalytics
