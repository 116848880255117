import { useLayoutEffect, useState } from 'react'

import { useModelStoreContext } from '../contexts/ModelStoreContext'
import { useReplicacheContext } from '../replicache/ReplicacheContext'
import { getTrackTypeForDevice } from './getTrackTypeForDevice'

export function useIsOnboardingCompleted(): boolean | null | undefined {
  const modelStoreValue = useIsOnboardingCompletedFromModelStore()

  // Keep localStorage value up to date.
  // Used by LoadingScreen to enable skeleton instead of generic loader.
  useSyncWithLocalStorage(modelStoreValue)

  return modelStoreValue
}

const track = getTrackTypeForDevice()

function useIsOnboardingCompletedFromModelStore(): boolean | undefined {
  const { isReady } = useReplicacheContext()
  const { store } = useModelStoreContext()

  return isReady
    ? store.user.data.onboarding.track[track].isComplete
    : undefined
}

// ---------------------------------------------
// Storing isOnboardingCompleted in localStorage
// ---------------------------------------------
// Used to enable skeleton loading state if user has authenticated before.

const isOnboardingCompletedKey = 'isOnboardingCompleted'

function useSyncWithLocalStorage(value: boolean | undefined) {
  useLayoutEffect(() => {
    if (value !== undefined && value !== null) {
      setValueToLocalStorage(value)
    }
  }, [value])
}

export const useIsOnboardingCompletedFromLocalStorage = () => {
  const [localStorageValue, setLocalStorageValue] = useState<
    boolean | null | undefined
  >(undefined)
  useLayoutEffect(() => {
    setLocalStorageValue(getValueFromLocalStorage())
  }, [])
  return localStorageValue
}

function getValueFromLocalStorage(): boolean | null {
  const value = localStorage.getItem(isOnboardingCompletedKey)
  return deserialize(value)
}

function setValueToLocalStorage(value: boolean): void {
  localStorage.setItem(isOnboardingCompletedKey, serialize(value))
}

function serialize(value: boolean): string {
  return value ? 'true' : 'false'
}

const deserialize = (value: string | null) => {
  if (value === 'true') return true
  else if (value === 'false') return false
  else if (value === null) return null
  else {
    console.error(
      `Unexpected value in localStorage for key: ${isOnboardingCompletedKey}`,
    )
    return null
  }
}

//

export { getTrackTypeForDevice } from './getTrackTypeForDevice'
