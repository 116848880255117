import { useCallback } from 'react'

import { useActiveScreen } from '../../../../../analytics/capture-analytics-web'
import { focusNext, focusPrev } from '../utils/focusHelpers'

export const useFocusNextRecord = (recordIds: string[]) => {
  const activeScreen = useActiveScreen()
  return useCallback(
    (recordId: string) => {
      const success = focusNext(recordId, recordIds)
      if (!success) return
    },
    [activeScreen, recordIds],
  )
}

export const useFocusPrevRecord = (recordIds: string[]) => {
  const activeScreen = useActiveScreen()
  return useCallback(
    (recordId) => {
      const success = focusPrev(recordId, recordIds)
      if (!success) return
    },
    [activeScreen, recordIds],
  )
}
