import { useUIContext } from '../../../common/contexts/UIContext'
import { RouteType } from '../../../common/routes'
import { MenuAction } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'
import { useShowCompletedAction } from '../actions/useShowCompletedAction'
import { useListScreenActions } from './useListScreenActions'

export function useScreenActions(
  routeType?: RouteType,
  listId?: string,
): MenuAction[] {
  const { showCompleted } = useUIContext()

  const listScreenActions = useListScreenActions(listId)
  const showCompletedAction = useShowCompletedAction(showCompleted)

  if (routeType === RouteType.List && listId) {
    return listScreenActions
  }

  return isMobile ? [showCompletedAction] : []
}
