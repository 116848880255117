import { useEffect } from 'react'

import { ScrollTarget, subscribeToScrollEvent } from '../../events'
import { ScrollOptions, useScroll } from './useScroll'

export const useScrollIntoView = <T extends HTMLElement>(
  id: string,
  target: ScrollTarget,
  options?: ScrollOptions,
) => {
  const { htmlRef, scrollIntoView } = useScroll<T>(options)

  useEffect(
    () =>
      subscribeToScrollEvent(({ payload }) => {
        if (payload.target === target && payload.to.id === id) {
          scrollIntoView(payload.options)
          return true
        }
        return false
      }),
    [target, id, scrollIntoView, options],
  )

  return {
    htmlRef,
  }
}
