import { err, ok } from 'neverthrow'
import { apiCall } from '../api/client'
import { SessionTokens } from '../contexts/AuthContext'
import { Env } from '../env'

const authenticateUrl = `${Env.captureApiUrl}/authenticate`

type AuthenticateParams = {
  sessionTokens?: SessionTokens
  code_verifier?: string
}

export async function authenticateApiCall(params: AuthenticateParams = {}) {
  // Extract the current query parameters
  const queryParams = window.location.search
  // Append them to your API endpoint
  const url = `${authenticateUrl}${queryParams}`
  try {
    const result = await apiCall(url, {
      method: `POST`,
      headers: {
        'Content-Type': `application/json; charset=utf-8`,
      },
      body: JSON.stringify(params),
    })
    const json = await result.json()
    return result.status <= 299 ? ok(json) : err(json)
  } catch (e) {
    return err(e)
  }
}
