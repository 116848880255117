import { PostNavigateOptions } from '../../../../hooks/useNavigateToList'

export const expandToFullscreen = (
  recordId: string,
  currentRoute: string,
  setLastRoute: (route: string) => void,
  navigate: (id: string, options?: PostNavigateOptions) => void,
  currentSelection: string[],
  setLastSelection: (value: string[]) => void,
  currentDefaultId: string | undefined,
  setLastDefaultId: (value: string | undefined) => void,
) => {
  if (!recordId) return
  setLastRoute(currentRoute)
  setLastSelection([])
  setLastDefaultId(currentDefaultId)
  navigate(recordId)
}
