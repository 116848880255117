import React, { useEffect, useMemo } from 'react'
import { VariableSizeList } from 'react-window'

import { useScrollCommands } from '../../../common/hooks/scroll/useScrollCommands'
import { useScrollable } from '../../../common/hooks/scroll/useScrollable'
import {
  VariableVirtualListAutosized,
  VirtualListAutosized,
} from '../../../common/virtualized'
import { ResultGroup } from '../MoveTo.types'

type ListProps = React.ComponentProps<typeof VirtualListAutosized>

interface ListPropsWithData extends ListProps {
  itemData: ResultGroup
}

type VariableListProps = React.ComponentProps<
  typeof VariableVirtualListAutosized
>

interface VariableListPropsWithData extends VariableListProps {
  itemData: ResultGroup
}

interface WithData {
  itemData: ResultGroup
}

export function withScrollerRef<T extends ListPropsWithData>(
  WrappedComponent: React.FC<T>,
): React.FC<T>
export function withScrollerRef<T extends VariableListPropsWithData>(
  WrappedComponent: React.FC<T>,
): React.FC<T>
export function withScrollerRef<T extends WithData>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const WithScrollerRef: React.FC<T> = (props: T) => {
    const items = props.itemData.items
    const ids = useMemo(() => items.map((item) => item.id), [items])
    const { scrollRef, scrollCommands } =
      useScrollCommands<VariableSizeList>(ids)

    useScrollable(`move-to`, scrollCommands)

    useEffect(() => scrollRef.current?.resetAfterIndex(0), [scrollRef, items])

    return <WrappedComponent ref={scrollRef} {...props} />
  }

  WithScrollerRef.displayName = `WithScollerRef`
  return WithScrollerRef
}
