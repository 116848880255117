export const recordsToRefs: { [id: string]: React.RefObject<HTMLDivElement> } =
  {}

export const RecordsManager = {
  getById: (id: string) => {
    if (!recordsToRefs[id]) return null

    return recordsToRefs[id]
  },
  register: (id: string, ref: React.RefObject<HTMLDivElement>) => {
    recordsToRefs[id] = ref
  },

  unregister: (id: string) => {
    delete recordsToRefs[id]
  },
}
