import { useUIContext } from '../../../common/contexts/UIContext'

export const useShouldAutofocus = (recordId: string) => {
  const { recentlyCreatedRecords } = useUIContext()

  const shouldAutofocus = !!recentlyCreatedRecords[recordId]

  return {
    shouldAutofocus,
  }
}
