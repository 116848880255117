import { userDataKeyboardShortcutsSchema } from '@eleventhlabs/capture-shared'
import * as z from 'zod'

const timestamp = z.number()
const savePointsSchema = z.record(timestamp)
const completedSlidesSchema = z.record(timestamp)
const trackSchema = z.object({
  isComplete: z.boolean(),
  completedAt: z.number().optional(),
  savePoints: savePointsSchema.optional(),
  completedSlides: completedSlidesSchema.optional(),
})

const tracksSchema = z.object({
  Desktop: trackSchema.optional(),
  Mobile: trackSchema.optional(),
  MobileWeb: trackSchema.optional(),
  Web: trackSchema.optional(),
})

const userDataOnboardingSchema = z.object({
  track: tracksSchema.optional(),
})

export type IDBUserDataOnboarding = z.TypeOf<typeof userDataOnboardingSchema>
export type IDBUserDataOnboardingTrackSchema = z.TypeOf<typeof trackSchema>

export const IDBUserDataSchema = z.object({
  keyboardShortcuts: userDataKeyboardShortcutsSchema.optional(),
  onboarding: userDataOnboardingSchema.optional(),
})

export const IDBUserSchema = z.object({
  id: z.string().uuid(),
  username: z.string(),
  timezone: z.string().optional(),
  phoneNumber: z.string().optional(),
  lastModified: z.number().optional(),
  createdAt: z.number().optional(),
  data: IDBUserDataSchema.optional(),
})

export type IDBUser = z.TypeOf<typeof IDBUserSchema>
