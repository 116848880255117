import React, { useCallback, useMemo } from 'react'

import {
  DataRecord,
  RecordDict,
  RecordWithTickler,
  RecurrencePayload,
  TickleableRecord,
  UpdateTicklerPayload,
} from '@eleventhlabs/capture-shared'

import {
  RecurrenceModel,
  ReminderModel,
} from '../../components/Schedule/Schedule.types'
import ScheduleDialog from '../../components/ScheduleDialog'
import { ExecAction } from '../useExec'
import { useDialogContext } from '../contexts/DialogContext'
import { getDateForRecords } from '../records'
import { DialogType } from './types'

const useScheduleDialog = (
  records: RecordDict,
  focusedRecordId: string | undefined,
  selectedRecordIds: string[],
  selectedRecords: DataRecord[],
  isViewVisible: (action: DialogType) => boolean,
  exec: ExecAction,
) => {
  const { closeDialog } = useDialogContext()

  const activeRecordDueDate =
    focusedRecordId && records[focusedRecordId]
      ? (records[focusedRecordId] as RecordWithTickler).tickler?.isoDate
      : getDateForRecords(selectedRecords as TickleableRecord[])

  const handleDateSelect = useCallback(
    (
      isoDate: string | null,
      isoTime?: string | null,
      recurrence?: RecurrenceModel | null,
      reminder?: ReminderModel | null,
    ) => {
      const payload: UpdateTicklerPayload = { isoDate }
      if (isoTime !== undefined) payload.isoLocalTime = isoTime
      if (recurrence !== undefined)
        payload.recurrence =
          recurrence === null ? null : recurrenceModelToPayload(recurrence)
      if (reminder !== undefined) payload.reminder = reminder
      exec({ type: `UPDATE_RECORD_TICKLER`, ticklerPayload: payload })
      closeDialog()
    },
    [closeDialog, exec],
  )

  const isOpen = isViewVisible(DialogType.Schedule)

  const scheduleComponent = useMemo(
    () =>
      isOpen ? (
        <ScheduleDialog
          activeRecordDueDate={activeRecordDueDate}
          isOpen={isOpen}
          onDateSelect={handleDateSelect}
          onDismiss={closeDialog}
          selectedRecordIds={selectedRecordIds}
        />
      ) : null,
    [
      selectedRecordIds,
      isOpen,
      activeRecordDueDate,
      handleDateSelect,
      closeDialog,
    ],
  )

  return {
    scheduleComponent,
    activeRecordDueDate,
  }
}

const recurrenceModelToPayload = (
  model: RecurrenceModel,
): RecurrencePayload => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    interval: model.interval,
    frequency: model.frequency,
    byDayOfWeek: model.byDayOfWeek,
  } as RecurrencePayload
}

export default useScheduleDialog
