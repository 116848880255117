import { addWeeks, formatISO, startOfToday } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'

import {
  InboxRecordId,
  isItemRecord,
  ItemRecord,
  RecordDict,
  RecordType,
  RootRecordId,
} from '@eleventhlabs/capture-shared'

import { values } from 'lodash'
import { useStartOfTodayISO } from '../../hooks/useStartOfTodayISO'
import { useReplicacheContext } from '../../replicache/ReplicacheContext'
import { CustomReplicache } from '../../replicache/types'
import { useOverdueRecords } from '../../replicache/useOverdueRecords'
import { useRecords } from '../../replicache/useRecords'
import { useSoftDeletedRecords } from '../../replicache/useSoftDeletedRecords'
import { useWithTicklerIsoInRange } from '../../replicache/useWithTicklerIsoInRange'
import { useModelStoreCommands } from './commands'
import { ModelStoreCommands } from './ModelStoreCommands'
import { defaultUserDataKeyboardShortcuts, ModelStore } from './types'
import { defaultOnboarding, useUser } from './useUser'

export interface UseModelStore {
  store: ModelStore
  commands: ModelStoreCommands
}

const emptyModelStore: ModelStore = {
  records: {
    [`${InboxRecordId}`]: {
      id: InboxRecordId,
      type: RecordType.Inbox,
      children: {},
    },
    [`${RootRecordId}`]: {
      id: RootRecordId,
      type: RecordType.Root,
      children: {},
    },
  },
  overdueRecords: [],
  softDeletedRecords: [],
  recordsWithDates: {},
  smsRecords: [],
  // When we fetch the user from IDB, we parse the value from IDB.
  //   That parsing enforces the id to be a uuid
  //   We need the fallback user to have a uuid, otherwise parsing fails
  user: {
    id: `20354d7a-e4fe-47af-8ff6-187bca92f3f9`,
    username: ``,
    createdAt: 0,
    lastModified: 0,
    inviteCode: '',
    data: {
      keyboardShortcuts: defaultUserDataKeyboardShortcuts,
      onboarding: defaultOnboarding,
    },
  },
}

export const useModelStore = (userId: string): UseModelStore => {
  const { rep } = useReplicacheContext()

  // Use RC as source of truth for store
  const user = useUser(rep, emptyModelStore.user)
  const records = useRecords(rep, emptyModelStore.records)

  const commands = useModelStoreCommands(rep as CustomReplicache, records)

  const todayIso = useStartOfTodayISO().today
  const inTwoWeeksISO = formatISO(addWeeks(startOfToday(), 2), {
    representation: `date`,
  })
  const includeSoftDeleted = false
  const recordsWithDates = useWithTicklerIsoInRange(
    rep,
    todayIso,
    inTwoWeeksISO,
    includeSoftDeleted,
    emptyModelStore.recordsWithDates,
  )
  const overdueRecords = useOverdueRecords(
    rep,
    todayIso,
    emptyModelStore.overdueRecords,
    [todayIso],
  )

  const softDeletedRecords = useSoftDeletedRecords(
    rep,
    emptyModelStore.softDeletedRecords,
  )

  const smsRecords = useSmsRecords(records)

  const store = useMemo(
    () => ({
      user,
      records,
      smsRecords,
      overdueRecords,
      softDeletedRecords,
      recordsWithDates,
    }),
    [
      user,
      records,
      overdueRecords,
      recordsWithDates,
      softDeletedRecords,
      smsRecords,
    ],
  )

  return useMemo(
    () => ({
      commands,
      store,
    }),
    [commands, store],
  )
}

const useSmsRecords = (records: RecordDict): ItemRecord[] => {
  const [smsRecords, setSmsRecords] = useState<ItemRecord[]>([])
  useEffect(() => {
    const arr: Array<ItemRecord> = []
    for (const r of values(records)) {
      if (
        isItemRecord(r) &&
        r.capturedFromSource === 'SMS' &&
        !r.isSoftDeleted
      ) {
        arr.push(r)
      }
    }
    arr.sort((a, b) => {
      // If 'capturedAt' is undefined for both 'a' and 'b', sort by 'id'
      if (a.capturedAt === undefined && b.capturedAt === undefined) {
        return a.id < b.id ? -1 : 1
      }

      // If 'capturedAt' is undefined for 'a', 'a' should come after 'b'
      if (a.capturedAt === undefined) {
        return 1
      }

      // If 'capturedAt' is undefined for 'b', 'b' should come after 'a'
      if (b.capturedAt === undefined) {
        return -1
      }

      // If 'capturedAt' is equal for both 'a' and 'b', sort by 'id'
      if (a.capturedAt === b.capturedAt) {
        return a.id < b.id ? -1 : 1
      }

      // Otherwise, sort by 'capturedAt' in descending order
      return b.capturedAt - a.capturedAt
    })
    setSmsRecords(arr)
  }, [records])
  return smsRecords
}
