import React, { useCallback, useState } from 'react'

import { titleOrDefault } from '../../../common/records'
import {
  Button,
  Icon,
  extractRGB,
  screenHeaderStyles,
  stackBackgroundStyles,
  styled,
  useTheme,
} from '../../../common/stationary'
import { RouteType, getMetadataForRoute } from '../../../common/routes'
import { RouteIcon } from '../../../components/RouteIcon'
import { useModal } from '../../../common/modals/core/hooks/useModal'
import { ModalName } from '../../../common/modals/core/modalConfig'
import { SelectionActionBar } from '../../../components/SelectionActionBar'
import { CAPTURE_BUTTONS_HEIGHT } from '../../../components/CaptureButtons/CaptureButtons'
import { isMobile } from '../../../common/utils/env'

const TrashHeader: React.FC = () => {
  const { openModal } = useModal()
  const theme = useTheme()
  const metadata = getMetadataForRoute({ routeType: RouteType.Trash }, theme)

  const onEmptyTrashButtonClicked = useCallback(() => {
    openModal(ModalName.CONFIRM_EMPTY_TRASH_MODAL)
  }, [openModal])

  if (!metadata) return null

  return (
    <S.TrashHeader>
      <S.Content>
        <S.Icon>
          <RouteIcon boxSize={32} routeType={metadata.type} />
        </S.Icon>
        <S.TrashHeaderText>
          <S.TrashHeaderTitle>
            {titleOrDefault(metadata.title)}
          </S.TrashHeaderTitle>
        </S.TrashHeaderText>
        <S.EmptyButton onClick={onEmptyTrashButtonClicked} variant="simple">
          <Icon boxSize={16} variant="glyphTrash" />
          <div style={{ marginLeft: 4 }}>Empty Trash</div>
        </S.EmptyButton>
      </S.Content>
      {!isMobile && (
        <S.SelectionActionBarWrapper>
          <SelectionActionBar />
        </S.SelectionActionBarWrapper>
      )}
    </S.TrashHeader>
  )
}

export default TrashHeader

export const TrashHeaderHeight = 48

const S = {
  TrashHeader: styled.div({
    ...screenHeaderStyles,
  }),
  Content: styled.div({
    display: `flex`,
    flex: `0 0 64px`,
    width: `100%`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    margin: `24px 0 8px 0`,
  }),
  Icon: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginRight: 12,
    userSelect: `none`,
    outline: `none`,
  }),
  EmptyButton: styled(Button)(({ theme }) => ({
    padding: '0 8px',
    height: 32,
    borderRadius: 6,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 600,
    color: theme.colors.red[700],
  })),
  TrashHeaderText: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flex: `1`,
    marginLeft: 4,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    overflowX: `hidden`,
    fontWeight: `bold`,
    ...theme.text.publicSans['33:40'],
  })),
  TrashHeaderTitle: styled.div({
    width: `100%`,
  }),
  SelectionActionBarWrapper: styled.div`
    position: relative;
    height: ${CAPTURE_BUTTONS_HEIGHT}px;
    width: 100%;
    margin-bottom: 16px;

    :before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 2px;
      left: -20px;
      right: -20px;
      z-index: -1;
      border-radius: 0 0 8px 8px;
      background: linear-gradient(
        rgba(${({ theme }) => extractRGB(theme.colors.surface.lower)} / 1),
        rgba(${({ theme }) => extractRGB(theme.colors.surface.lower)} / 1),
        rgba(${({ theme }) => extractRGB(theme.colors.surface.lower)} / 0)
      );
    }
    :after {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 0;
      right: 0;
      z-index: -1;
      border-radius: 8px;
      ${({ theme }) =>
        stackBackgroundStyles({
          backgroundColors: [
            theme.colors.surface.lower,
            theme.colors.alpha.tone.extraWeak,
          ],
        })};
    }
  `,
}
