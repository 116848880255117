import { useCallback, useRef } from 'react'

const OFFSET_TOP = 100 // accounts for header
const OFFSET_BOTTOM = 10 // accounts for a small margin to not get right at the bottom

const MINIMUM_VISIBILITY = 0

type ScrollPosition = `smart` | `top` | `bottom` | `minimal`

export interface ScrollOptions {
  offsetTop?: number
  offsetBottom?: number
  position?: ScrollPosition
  navigateIfFullyVisible?: boolean
  minimumVisibile?: number
  elementBounds?: DOMRect
  extraOffsetTop?: number // Hack-y way to add addition offset to scrollIntoView
}

const defaultOptions = {
  offsetTop: OFFSET_TOP,
  offsetBottom: OFFSET_BOTTOM,
  position: `smart` as ScrollPosition,
}

export const useScroll = <T extends HTMLElement>(
  scrollOptions: ScrollOptions = defaultOptions,
) => {
  const htmlRef = useRef<T>(null)

  const scrollIntoView = useCallback((eventOptions: ScrollOptions = {}) => {
    if (htmlRef.current) {
      const options = { ...scrollOptions, ...eventOptions }
      const { top: _top, bottom } = options.elementBounds
        ? options.elementBounds
        : htmlRef.current.getBoundingClientRect()
      const top = _top - (options.extraOffsetTop ?? 0)
      if (
        isVisible(top, bottom, window.innerHeight, options) &&
        !options.navigateIfFullyVisible
      )
        return
      window.scrollBy({
        top: scrollDelta(top, bottom, window.innerHeight, options),
        behavior: `auto`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    htmlRef,
    scrollIntoView,
  }
}

function isVisible(
  top: number,
  bottom: number,
  windowHeight: number,
  options: ScrollOptions,
): boolean {
  const viewportTop = options.offsetTop ?? defaultOptions.offsetTop
  const viewportBottom =
    windowHeight - (options.offsetBottom ?? defaultOptions.offsetBottom)

  if (top < viewportTop) return false
  if (bottom > viewportBottom) return false

  return true
}

function scrollDelta(
  top: number,
  bottom: number,
  windowHeight: number,
  options: ScrollOptions,
): number {
  const viewportTop = options.offsetTop ?? defaultOptions.offsetTop
  const viewportBottom =
    windowHeight - (options.offsetBottom ?? defaultOptions.offsetBottom)
  const position = options.position ?? defaultOptions.position

  if (top < viewportTop && position === `smart`) {
    return top - viewportTop
  }
  if (bottom > viewportBottom && position === `smart`) {
    return bottom - viewportBottom
  }

  if (position === `top`) return top - viewportTop
  if (position === `bottom`) return bottom - viewportBottom

  const minimumVisibile = Math.min(
    options.minimumVisibile ?? MINIMUM_VISIBILITY,
    bottom - top,
  )
  const visible = Math.max(
    Math.min(bottom, viewportBottom) - Math.max(top, viewportTop),
    0,
  )
  if (visible < minimumVisibile && position === `minimal`) {
    if (top < viewportTop && bottom > viewportBottom) return 0
    if (top < viewportTop) {
      return visible - minimumVisibile
    }
    if (bottom > viewportBottom) {
      return minimumVisibile - visible
    }
  }

  return 0
}
