import { size } from 'lodash'

import { titleOrDefault } from '../../../records'
import {
  DataRecord,
  InboxRecordId,
  ParentableRecord,
  RecordDict,
  RecordWithTickler,
  RootRecordId,
  isListRecord,
  isNoteableRecord,
  isWithTickler,
} from '@eleventhlabs/capture-shared'
import { dateStringToDateTagText } from '../../../utils'
import { NotesTag, PlaceTag, Tag, TagType } from '../../tags'
import { RecordViewType } from './RecordViewType'

export const getTagListForRecord = (
  records: RecordDict,
  recordId: string,
  recordViewType: RecordViewType,
): Tag[] => {
  switch (recordViewType) {
    case RecordViewType.DateGroup:
      return getTagsForRecord(
        records,
        [TagType.NOTES, TagType.DATE, TagType.PLACE],
        recordId,
      )
    case RecordViewType.OverdueGroup:
      return getTagsForRecord(
        records,
        [TagType.NOTES, TagType.DATE, TagType.PLACE],
        recordId,
      )
    case RecordViewType.SectionGroup:
      return getTagsForRecord(records, [TagType.NOTES, TagType.DATE], recordId)
    default:
      return []
  }
}

const getTagsForRecord = (
  records: RecordDict,
  tags: TagType[],
  recordId: string,
): Tag[] => {
  return withoutUndefined(
    tags.map((t) => getTagOfTypeForRecord(records, t, recordId)),
  )
}

const withoutUndefined = <T>(arr: Array<T | undefined>) => {
  return arr.filter((i) => i !== undefined) as T[]
}

const getTagOfTypeForRecord = (
  records: RecordDict,
  t: TagType,
  recordId: string,
): Tag | undefined => {
  if (t === TagType.DATE) {
    const record = records[recordId]
    return isWithTickler(record) ? dateToTag(record) : undefined
  } else if (t === TagType.NOTES) {
    return getNotesTagForRecord(records[recordId])
  } else if (t === TagType.PLACE) {
    const record = records[recordId] as ParentableRecord
    return getPlaceTagForRecord(records, record)
  } else {
    console.warn('Unknown tag type: please review.')
    return
  }
}

const getPlaceTagForRecord = (
  records: RecordDict,
  record: ParentableRecord,
): PlaceTag | undefined => {
  let text = ``
  let emoji: string | undefined
  let isParentList: boolean | undefined
  if (record.parentId === InboxRecordId) {
    text = `Inbox`
  } else if (record.parentId === RootRecordId) {
    text = `Sidebar`
  } else {
    if (record.parentId === undefined)
      throw new Error(`Expected record to have parent`)
    const parent = records[record.parentId]
    if (isListRecord(parent)) {
      text = titleOrDefault(parent.title)
      emoji = parent.emoji
      isParentList = true
    } else {
      console.warn('Unknown place tag: please review.')
      return
    }
  }

  return {
    type: TagType.PLACE,
    text,
    parentId: record.parentId,
    emoji,
    isParentList,
  }
}

const getNotesTagForRecord = (record: DataRecord): NotesTag | undefined => {
  const count = isNoteableRecord(record) ? size(record.notes) : 0

  return count > 0
    ? {
        type: TagType.NOTES,
        text: `${count}`,
      }
    : undefined
}

const dateToTag = ({
  tickler,
  reminders,
}: RecordWithTickler): Tag | undefined =>
  tickler !== undefined
    ? {
        type: TagType.DATE,
        date: tickler,
        reminder: tickler.reminderId
          ? reminders[tickler.reminderId]
          : undefined,
        text: dateStringToDateTagText(tickler.isoDate),
      }
    : undefined
