import {
  CURRENT_RICH_TEXT_VERSION,
  DataRecord,
  Editor,
  FileMetadata,
  RICH_TEXT_EDITOR_CLIPBOARD_MIME_TYPE,
  RICH_TEXT_EDITOR_CLIPBOARD_TYPE,
  RecordType,
  RichText,
  TickleableRecord,
  URLMetadata,
  UpdateNoteRecordPayload,
  UpdateRecordPayload,
  UpdateTextRecordPayload,
  isCompleteableRecord,
  isTickleableRecord,
} from '@eleventhlabs/capture-shared'
import { every, filter, flatMap, isEmpty, join, map } from 'lodash'

import { wrapHtmlForCopy } from './clipboard'
import { RecordGroupData } from './data/recordGroups'
import { IconConfig, Theme, getIconConfigPresets } from './stationary'
import { isLink } from './utils'
import { isImage, isVideo } from './files'

export const getIconConfigForRecord = (
  { record }: { record?: DataRecord },
  theme?: Theme,
): IconConfig => {
  switch (record?.type) {
    case RecordType.Inbox: {
      return getIconConfigPresets(`Inbox`, theme)
    }
    case RecordType.List: {
      return {
        ...getIconConfigPresets(`List`, theme),
        ...(record && 'emoji' in record ? { value: record.emoji } : undefined),
      }
    }
    case RecordType.Text: {
      return getIconConfigPresets(`Text`, theme)
    }
    case RecordType.URL: {
      return getIconConfigPresets(`URL`, theme)
    }
    case RecordType.Note: {
      return getIconConfigPresets(`Note`, theme)
    }
    case RecordType.File: {
      const image = isImage(record?.fileMetadata?.mimeType ?? ``)
      const video = isVideo(record?.fileMetadata?.mimeType ?? ``)
      return image
        ? getIconConfigPresets(`FileImage`, theme)
        : video
        ? getIconConfigPresets(`FileVideo`, theme)
        : getIconConfigPresets(`File`, theme)
    }
    default: {
      return {}
    }
  }
}

export const getDateForRecords = (
  records: TickleableRecord[],
): string | undefined => {
  if (records.length === 0) return undefined
  const firstRecord = records[0]
  const allHaveSameDate = records
    .filter((r) => isTickleableRecord(r))
    .map((r) => r.tickler?.isoDate)
    .every((v) => v === firstRecord.tickler?.isoDate)
  return allHaveSameDate ? firstRecord.tickler?.isoDate : undefined
}

export const allCompleted = (records: DataRecord[]): boolean => {
  return every(
    records,
    (record) => isCompleteableRecord(record) && record.isCompleted,
  )
}

export const titleOrDefault = (title: string | undefined) => {
  return isEmpty(title) || title == undefined ? `Untitled` : title
}

export function canBeConvertedToUrlRecord(record: DataRecord): boolean {
  return (
    record.type === RecordType.Text &&
    isLink(RichText.getRawText(record.richText.value).trim())
  )
}

const convertToUrlRecord = async (
  recordId: string,
  url: string,
  updateRecord: (recordId: string, payload: UpdateRecordPayload) => void,
  updateRecordText: (
    recordId: string,
    payload: UpdateTextRecordPayload | UpdateNoteRecordPayload,
  ) => void,
  setSelectionDefaultId: (id: string) => void,
  getUrlMetaData: (url: string) => Promise<URLMetadata>,
): Promise<boolean> => {
  updateRecord(recordId, { type: RecordType.URL })
  const urlMetadata = await getUrlMetaData(url)
  const success = !isMetadataEmpty(urlMetadata)

  if (success) {
    updateRecord(recordId, { url, urlMetadata })
    setSelectionDefaultId(recordId)
  } else {
    updateRecordText(recordId, {
      type: RecordType.Text,
      richText: {
        value: RichText.create(url),
        version: CURRENT_RICH_TEXT_VERSION,
      },
    })
  }
  return success
}

export function canBeConvertedToTextRecord(record: DataRecord): boolean {
  return !!(
    (record.type === RecordType.URL || record.type === RecordType.File) &&
    record.url
  )
}

const convertToTextRecord = (
  record: DataRecord,
  updateRecordText: (
    recordId: string,
    payload: UpdateTextRecordPayload | UpdateNoteRecordPayload,
  ) => void,
) => {
  if (record.type === RecordType.URL || record.type === RecordType.File)
    updateRecordText(record.id, {
      type: RecordType.Text,
      richText: {
        value: RichText.create(record?.url ?? ``),
        version: CURRENT_RICH_TEXT_VERSION,
      },
    })
}

const addFileToRecord = async (
  recordId: string,
  file: File,
  updateRecord: (recordId: string, payload: UpdateRecordPayload) => void,
  deleteRecords: (recordIds: string[]) => void,
  setSelectionDefaultId: (id: string) => void,
  uploadFile: (recordId: string, file: File) => Promise<FileMetadata>,
): Promise<boolean> => {
  const fileMetadata = await uploadFile(recordId, file)
  const success = !isMetadataEmpty(fileMetadata)
  if (success) {
    updateRecord(recordId, { url: fileMetadata.url, fileMetadata })
    setSelectionDefaultId(recordId)

    // TODO: Investigate creating thumbnails here.
    // May be a big lift on clients depending on the # of thumbnails / file
    // post-processing needed.
  } else {
    deleteRecords([recordId])
  }
  return success
}

export const Records = {
  convertToUrlRecord,
  convertToTextRecord,
  addFileToRecord,
  getDateForRecords,
}

export const getVisibleRecordIdsFromGroups = (
  groups: RecordGroupData[],
  showCompleted: boolean,
) => {
  return flatMap(groups, (group) =>
    map(
      filter(group.items, (item) => !item.isCompleted || showCompleted),
      (item) => item.id,
    ),
  )
}

export type IMPORT_TEXT_TYPE = 'markdown' | 'html' | 'slate'

export const importRichTextFromValue = (
  value: string,
  mimeType: IMPORT_TEXT_TYPE = `markdown`,
): RichText => {
  switch (mimeType) {
    case `html`:
      return RichText.deserializeFromHtml(value)
    case `markdown`:
      return RichText.deserializeFromMarkdown(value)
    case `slate`: {
      const decoded = decodeURIComponent(window.atob(value))
      const parsed = JSON.parse(decoded) as RichText
      return parsed
    }
    default:
      return RichText.deserializeFromMarkdown(value)
  }
}

export type EXPORT_TEXT_TYPE =
  | 'raw'
  | 'markdown'
  | 'html'
  | typeof RICH_TEXT_EDITOR_CLIPBOARD_TYPE

interface ExportText {
  type: EXPORT_TEXT_TYPE
  text: string
  mimeType:
    | `text/plain`
    | `text/html`
    | `default`
    | typeof RICH_TEXT_EDITOR_CLIPBOARD_MIME_TYPE
}

export const getMarkdownTextFromRecords = (records: DataRecord[]): string => {
  const SEPARATOR = `\n`

  const text = join(
    map(records, (record) => {
      switch (record.type) {
        case RecordType.Text:
          return RichText.serializeToMarkdown(record?.richText.value)
        case RecordType.URL: {
          if (records.length === 1) return record.url ?? ``
          return record.url
            ? serializeURLToMarkdown(
                record.urlMetadata?.title ?? record.url,
                record.url,
              )
            : ``
        }
        case RecordType.File: {
          if (records.length === 1) return record.url ?? ``
          return record.url
            ? serializeURLToMarkdown(
                record.fileMetadata?.name ?? record.url,
                record.url,
              )
            : ``
        }
        default:
          return ``
      }
    }),
    SEPARATOR,
  )
  return text
}
const serializeURLToMarkdown = (title: string, url: string): string => {
  return `[${title}]${url}`
}

export const getHtmlTextFromRecords = (records: DataRecord[]): ExportText => {
  const SEPARATOR = ``

  const text = join(
    map(records, (record) => {
      switch (record.type) {
        case RecordType.Text:
          return RichText.serializeToHtml(record?.richText.value ?? [])
        case RecordType.URL:
          return record.url
            ? serializeURLToHtml(
                record.urlMetadata?.title ?? record.url,
                record.url,
              )
            : ``
        case RecordType.File:
          return record.url
            ? serializeURLToHtml(
                record.fileMetadata?.name ?? record.url,
                record.url,
              )
            : ``
        default:
          return ``
      }
    }),
    SEPARATOR,
  )

  return wrapHtmlForCopy(text)
}

const serializeURLToHtml = (title: string, url: string): string => {
  return `<div><a target="_blank" title="${title}" href="${url}">${title}</a></div>`
}

export const getRichTextEncodedTextFromRecords = (
  records: DataRecord[],
): ExportText => {
  const richText = flatMap(records, (record) => {
    switch (record.type) {
      case RecordType.Text:
        return record?.richText.value ?? []
      case RecordType.URL: {
        return record.url
          ? serializeURLRecordToRichText(
              record.urlMetadata?.title ?? record.url,
              record.url,
            )
          : []
      }
      case RecordType.File: {
        return record.url
          ? serializeURLRecordToRichText(
              record.fileMetadata?.name ?? record.url,
              record.url,
            )
          : []
      }
      default:
        return []
    }
  })

  return Editor.get.richTextWrappedForCopy(richText[0])
}

export const serializeURLRecordToRichText = (
  title: string,
  url: string,
): RichText => {
  return RichText.create(url)
}

export const isMetadataEmpty = (metadata: URLMetadata | FileMetadata) => {
  return every(metadata, (value) => value === undefined)
}
