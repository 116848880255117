import React from 'react'

import { preventDefault } from '../../../../common/events/utils'
import { styled, withTooltip } from '../../../../common/stationary'

interface SkipIconProps {
  className?: string
  onClick: () => void
}

const SkipIcon = React.forwardRef<HTMLDivElement, SkipIconProps>(
  ({ className, onClick }, ref) => {
    return (
      <S.Container
        className={className}
        onClick={onClick}
        onMouseDown={preventDefault}
        ref={ref}
      >
        <S.Text>Skip</S.Text>
      </S.Container>
    )
  },
)

export const SkipIconWithTooltip = withTooltip(SkipIcon)

const S = {
  Container: styled.div(({ theme }) => ({
    display: `flex`,
    marginLeft: 6,
    color: theme.colors.text[700],

    '&:hover': {
      color: theme.colors.text[900],
    },
  })),
  Text: styled.div({
    display: `flex`,
    fontWeight: 500,
    textDecoration: `underline`,
  }),
}
