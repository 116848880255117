import { Env } from '../../../../common/env'
import { getTrackTypeForDevice } from '../../../../common/onboarding/getTrackTypeForDevice'
import { SlideType, Slides } from '../slides'
import { WidgetType } from '../widgets'

export type TrackItem = [
  slideType: SlideType,
  widgetTypes: WidgetType[],
  isCheckpoint: boolean,
  forceSkip?: boolean,
]

export type TrackType = 'Web' | 'MobileWeb' | 'Mobile' | 'Desktop'

/**
 * @link https://stackoverflow.com/questions/19877924/what-is-the-list-of-possible-values-for-navigator-platform-as-of-today
 */

export const Tracks: Record<TrackType, TrackItem[]> = {
  Web: [
    ['IntroInbox', ['ProgressBar', 'InteractiveInbox'], true],
    ['DemoQuickCapture', ['ProgressBar', 'InteractiveInbox'], false],
    [
      'InstallDesktopApp',
      ['ProgressBar'],
      true,
      !Env.isSSR && navigator.platform.indexOf('Mac') === -1,
    ],
    ['InstallMobileApp', ['ProgressBar'], true],
    [
      'InstallChromeExtension',
      ['ProgressBar'],
      true,
      !Env.isSSR && navigator.userAgent.indexOf('Chrome') === -1,
    ],
  ],
  MobileWeb: [],
  Mobile: [
    ['IntroTextYourself', ['ProgressBar'], true],
    ['VerifyPhone', ['ProgressBar'], false],
    ['DemoMessages', ['ProgressBar', 'InteractiveInbox'], true],
    ['AddToContacts', ['ProgressBar'], true],
    ['PinToMessages', ['ProgressBar'], false],
    ['IntroReminders', ['ProgressBar', 'InteractiveInbox'], true],
    ['InstallBrowserApp', ['ProgressBar'], true],
  ],
  Desktop: [
    ['DesktopKeyboardShortcut', ['ProgressBar'], true],
    ['DesktopMenuBarShortcut', ['ProgressBar'], true],
    ['DesktopKeepInDock', ['ProgressBar'], true],
  ],
}

export function getTrackItemIndex(
  trackType: TrackType,
  slideType: SlideType,
): number {
  return Tracks[trackType].findIndex((trackItem) => {
    return trackItem[0] === slideType
  })
}

export function getSlideType(trackItem: TrackItem): SlideType {
  return trackItem[0]
}

export function getSlide(trackItem: TrackItem) {
  return Slides[getSlideType(trackItem)]
}

export function getWidgetTypes(trackItem: TrackItem): WidgetType[] {
  return trackItem[1]
}

export function getIsCheckpoint(trackItem: TrackItem): boolean {
  return trackItem[2]
}

export function getShouldSkipTrackItem(trackItem: TrackItem): boolean {
  return trackItem[3] ?? false
}

export const trackType: TrackType = getTrackTypeForDevice()
export const track = Tracks[trackType]
