import React from 'react'

import { cx, styled } from '../../common/stationary'

export interface ShortcutItemProps {
  keyString: string
  className?: string
}

export const ShortcutItem: React.FC<ShortcutItemProps> = ({
  keyString,
  className,
}) => {
  return (
    <S.ShortcutItem className={cx(`key`, className)}>
      <S.Key>{keyString}</S.Key>
    </S.ShortcutItem>
  )
}

const S = {
  ShortcutItem: styled.div({
    display: `flex`,
    flexDirection: `row`,
    marginLeft: `4px`,
    flex: `0 0`,
  }),
  Key: styled.div(({ theme }) => ({
    display: `flex`,
    height: `20px`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    ...theme.text.publicSans['11.5:16'],
    fontWeight: 500,
    padding: `2px 6px`,
    color: theme.colors.text[700],
    background: theme.colors.alpha.tone.mediumWeak,
    boxSizing: `border-box`,
    borderRadius: 4,
  })),
}
