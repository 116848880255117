import { RecordDict, TextRecord } from '@eleventhlabs/capture-shared'
import { createNoteListData, getNotesForRecord } from '../../notes'
import { RecordItemData } from '../types'
import { RecordViewType } from './RecordViewType'
import { getTagListForRecord } from './tags'

export const getRecordForViewType = (
  records: RecordDict,
  recordId: string,
  viewType: RecordViewType,
): RecordItemData => {
  return {
    ...(records[recordId] as TextRecord),
    tags: getTagListForRecord(records, recordId, viewType),
    notesList: createNoteListData(getNotesForRecord(records, recordId)),
  }
}
