import { DataRecord } from '@eleventhlabs/capture-shared'
import { useMemo } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { RecordItemData } from '../data/recordGroups'

interface UseMailToLinkArgs {
  error: Error
  record?: DataRecord | RecordItemData
  isFullPage?: boolean
}

const UNKNOWN = 'UNKNOWN'
const LEXICAL_ERROR_CODE_STRING_REGEX = /^Minified Lexical error #\d+/

export const useMailToContactTemplateLink = ({
  error,
  record,
  isFullPage,
}: UseMailToLinkArgs): string => {
  const { user } = useAuth()

  const userId = (user?.trusted_metadata.captureId as string) ?? UNKNOWN
  const recordId = record?.id ?? UNKNOWN
  const richTextVersion =
    record && 'richText' in record ? record.richText.version : undefined

  let errorMessageOrCode = error.message
  const lexicalErrorCode = error.message.match(LEXICAL_ERROR_CODE_STRING_REGEX)
  if (lexicalErrorCode) {
    // Extract the error code from the editor error
    errorMessageOrCode = `Editor: ${lexicalErrorCode[0].match(/#\d+/)}`
  }

  if (isFullPage) {
    errorMessageOrCode = `APP_CRASH: ` + errorMessageOrCode
  }

  return useMemo(() => {
    let mailToLink = `mailto:membership@capture.so?subject=Help!%20Something's%20wrong%20with%20Capture%20&body=Tell%20us%20what's%20going%20on%3A%0D%0A%0D%0A--%0D%0A%0D%0AAdditional%20information%20that%20will%20help%20us%20understand%20your%20issue%20(please%20don't%20delete!)%3A%0D%0AError%3A%20${errorMessageOrCode}%0D%0ARecord%3A%20${recordId}%0D%0AUser%3A%20${userId}%0D%0A`

    if (richTextVersion) {
      mailToLink += `Rich%20Text%20Version%3A%20${richTextVersion}%0D%0A`
    }

    return mailToLink
  }, [record, error, richTextVersion])
}
