import React, { useCallback } from 'react'
import { Subtract } from 'utility-types'

import { NoteHandlers as GenericNoteHandlers } from '../../../common/noteEventHandlers'
import { NoteRecord } from '@eleventhlabs/capture-shared'
import { NoteHandlers } from '../types'

export const withNoteEventHandlers = <
  T extends NoteHandlers & { note: NoteRecord },
>(
  WrappedComponent: React.FC<T>,
) => {
  const ComponentWithEventHandlers: React.FC<
    Subtract<T, NoteHandlers> & GenericNoteHandlers & { note: NoteRecord }
  > = (props) => {
    const {
      onArrowLeftOrUpAtBoundary,
      onArrowRightOrDownAtBoundary,
      onBackspaceWhenEmpty,
      onBlur,
      onPaste,
      onClick,
      onFocus,
      onKeyDown,
      onMouseDown,
      onChange,
      ...rest
    } = props

    const noteId = props.note.id
    const recordId = props.note.parentId

    const _onBlur = useCallback(
      (e) => {
        onBlur && onBlur(e, noteId)
      },
      [onBlur, noteId],
    )

    const _onFocus = useCallback(
      (e) => {
        onFocus && onFocus(e, noteId)
      },
      [onFocus, noteId],
    )

    const _onMouseDown = useCallback(
      (e) => {
        onMouseDown && onMouseDown(e, noteId)
      },
      [onMouseDown, noteId],
    )

    const _onChange = useCallback(
      (value) => {
        onChange && onChange(value, noteId)
      },
      [onChange, noteId],
    )

    const _onPaste = useCallback(
      (e) => {
        onPaste && onPaste(e, recordId)
      },
      [onPaste, recordId],
    )

    const _onArrowRightOrDownAtBoundary = useCallback(() => {
      onArrowRightOrDownAtBoundary && onArrowRightOrDownAtBoundary(noteId)
    }, [onArrowRightOrDownAtBoundary, noteId])

    const _onArrowLeftOrUpAtBoundary = useCallback(() => {
      onArrowLeftOrUpAtBoundary && onArrowLeftOrUpAtBoundary(noteId)
    }, [onArrowLeftOrUpAtBoundary, noteId])

    const _onBackspaceWhenEmpty = useCallback(() => {
      onBackspaceWhenEmpty && onBackspaceWhenEmpty(noteId)
    }, [onBackspaceWhenEmpty, noteId])

    const _onKeyDown = useCallback(
      (e) => {
        onKeyDown && onKeyDown(e, noteId, recordId)
      },
      [onKeyDown, noteId, recordId],
    )

    const _onClick = useCallback(
      (e) => {
        onClick && onClick(e, noteId)
      },
      [onClick, noteId],
    )

    return (
      <WrappedComponent
        onArrowLeftOrUpAtBoundary={_onArrowLeftOrUpAtBoundary}
        onArrowRightOrDownAtBoundary={_onArrowRightOrDownAtBoundary}
        onBackspaceWhenEmpty={_onBackspaceWhenEmpty}
        onBlur={_onBlur}
        onChange={_onChange}
        onPaste={_onPaste}
        onFocus={_onFocus}
        onKeyDown={_onKeyDown}
        onMouseDown={_onMouseDown}
        onClick={_onClick}
        {...(rest as T)}
      />
    )
  }
  return ComponentWithEventHandlers
}
