import React, { useMemo } from 'react'

import QuickSwitcherDialog from '../../components/QuickSwitcherDialog'
import { useDialogContext } from '../contexts/DialogContext'
import { DialogType } from './types'

const useQuickSwitcherDialog = (
  isViewVisible: (action: DialogType) => boolean,
) => {
  const { closeDialog } = useDialogContext()

  const isOpen = isViewVisible(DialogType.QuickSwitcher)

  const quickSwitcherComponent = useMemo(
    () =>
      isOpen ? (
        <QuickSwitcherDialog isOpen={isOpen} onDismiss={closeDialog} />
      ) : null,
    [isOpen, closeDialog],
  )

  return {
    quickSwitcherComponent,
  }
}

export default useQuickSwitcherDialog
