import { isMobile } from '../utils/env'

// -----------------------
// STYTCH SESSION DURATION
// -----------------------
//
// Stytch projects are configured with a maximum session duration length
// https://stytch.com/dashboard/sdk-configuration
//
// The maxmium Stytch session duration is 527040 minutes (~1 year)
// https://stytch.com/docs/api/errors/400#invalid_session_duration_minutes

// ************************************************************************
// UPDATE THE SDK CONFIG BEFORE CHANGING THE SESSION DURATIONS IN THIS FILE
// ************************************************************************

const ONE_YEAR_ISH_IN_MINUTES = 526000
const MOBILE_SESSION_DURATION = ONE_YEAR_ISH_IN_MINUTES

const TWO_WEEKS_IN_MINUTES = 20160
const DEFAULT_SESSION_DURATION = TWO_WEEKS_IN_MINUTES

export function getSessionDurationMinutes() {
  return isMobile ? MOBILE_SESSION_DURATION : DEFAULT_SESSION_DURATION
}
