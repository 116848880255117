import { useEffect, useState } from 'react'

import { startOfTodayISO } from '../utils'

/**
 * Returns startOfTodayISO(), but changes when the day change
 */

const POLL_INTERVAL = 5 * 1000 // we check if the day changed every 5 seconds

export const useStartOfTodayISO = () => {
  const [today, setToday] = useState(startOfTodayISO())

  useEffect(() => {
    // Set up polling for date changes every POLL_INTERVAL ms
    // (A sidenote about JS timers - they run on CPU time, not calendar time. This means that
    // while the computer is in sleep mode, the timer is not ticking. That's why we need to
    // check once a minute instead of calculating time to midnight and setting a precise alarm.)
    const intervalID = setInterval(() => {
      const _today = startOfTodayISO()
      if (today !== _today) setToday(_today)
    }, POLL_INTERVAL)

    return () => clearInterval(intervalID)
  }, [today])

  return {
    today,
  }
}
