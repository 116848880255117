import * as SentryCapacitor from '@sentry/capacitor'
import * as SentryReact from '@sentry/react'
import { Replay } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { UserInfo } from '../data/auth'
import { CURRENT_APP_VERSION, Env } from '../env'
import { getPlatformForAnalytics } from '../analytics/helpers'
import { User } from '@stytch/vanilla-js'
import { envIsDev } from '../utils/env'

export interface LogExtras {
  user: string
  recordId: string
  message: string
  name: string
  stack: string
  content: any
}

export interface ILogger {
  error: (message: string, extras?: Partial<LogExtras>) => void
  message: (message: string, extras?: Partial<LogExtras>) => void
}

export enum LoggerType {
  CONSOLE = 'console',
  SENTRY = 'sentry',
}

export class ConsoleLogger implements ILogger {
  type = LoggerType.CONSOLE

  constructor() {}

  error = (message: string, extras?: Partial<LogExtras>): void => {
    console.log(`[ConsoleLogger ERROR]: ${message}`)
  }

  message = (message: string, extras?: Partial<LogExtras>): void => {
    console.log(`[ConsoleLogger MESSAGE]: ${message}`)
  }
}

export class SentryLogger implements ILogger {
  type = LoggerType.SENTRY

  constructor(user?: User | null) {
    const client = SentryCapacitor.getCurrentHub().getClient()
    const isInitialized = client !== undefined

    // We use the same sentry client across all environments / sessions so if it's already
    // been initialized, don't do anything. Sentry throws this error if multiple instances
    // are initialized: https://eleventh-labs.sentry.io/issues/3953470399/?environment=PRODUCTION&project=4504602833715200&query=is:unresolved
    if (!isInitialized) {
      SentryCapacitor.init(
        {
          // Safe for this to be public: https://docs.sentry.io/product/sentry-basics/dsn-explainer/
          dsn: `https://2aa2d0194392406e801a56086cf81efc@o4504602829979648.ingest.sentry.io/4504602833715200`,
          integrations: [
            new BrowserTracing(),
            new Replay({
              maskAllText: true,
              maskAllInputs: true,
              blockAllMedia: true,
            }),
          ],
          // Proxy tunnel for adblockers
          tunnel: Env.logUrl,
          environment: Env.appEnv,

          // Sample rates for performance and session replays 10%
          tracesSampleRate: envIsDev ? 1.0 : 0.1,
          replaysSessionSampleRate: envIsDev ? 1.0 : 0.1,
          replaysOnErrorSampleRate: 1.0,
          release: CURRENT_APP_VERSION,
          beforeSend: (event) => {
            // Augment the event with platform
            event.tags = event.tags || {}
            event.tags.platform = getPlatformForAnalytics()
            return event
          },
        },
        SentryReact.init,
      )

      const userId = user?.trusted_metadata.captureId as string
      if (userId) SentryCapacitor.setUser({ id: userId })
    }
  }

  error(message: string, extras?: Partial<LogExtras>): void {
    SentryCapacitor.captureException(message, {
      extra: extras,
      level: 'error',
    })
  }

  message(message: string, extras?: Partial<LogExtras>): void {
    SentryCapacitor.captureEvent({ message, extra: extras, level: 'info' })
  }
}

export const Logger = SentryLogger
