import { useCallback } from 'react'

import { useNavigate } from '@gatsbyjs/reach-router'

import { RouteType, getUrl, useRouteFromLocation } from '../../common/routes'
import { useActiveScreen } from '../../common/analytics/capture-analytics-web'
import { useExecContext } from '../../common/contexts/ExecContext'
import Analytics from '../../common/analytics/capture-analytics-actions'
import { isMobile } from '../../common/utils/env'

export const useOnListItemClick = () => {
  const { exec } = useExecContext()
  const toggleSideBar = useCallback(() => {
    // TODO: fix sidebar analytics
    exec({ type: 'TOGGLE_SIDEBAR', trigger: `Sidebar Toggle Header Button` })
  }, [exec])
  const navigate = useNavigate()
  const activeScreen = useActiveScreen()
  const { routeType: currentRoute, listId } = useRouteFromLocation()
  const onListItemClick = useCallback(
    (id: string) => {
      if (listId === id) {
        window.scrollTo(0, 0)
        return
      }
      if (currentRoute === id) return
      const screenType = Analytics.ScreenType.USER_LIST
      Analytics.screenNavigationStarted({
        activeScreen,
        screenNavigationStartedTrigger:
          Analytics.ScreenNavigationStartedTrigger.SIDEBAR_ITEM_CLICK,
        screenType,
      })
      navigate(getUrl({ type: RouteType.List, listId: id }))
      if (isMobile) toggleSideBar()
    },
    [activeScreen, currentRoute, listId, navigate],
  )
  return onListItemClick
}
