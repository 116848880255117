import { useCallback, useMemo } from 'react'

import { RecordItemData } from '../../data/recordGroups'
import { MenuAction } from '../../stationary'
import {
  ADD_NOTE,
  COMPLETE,
  HIDE_NOTE,
  SHOW_NOTE,
  UNCOMPLETE,
} from '../../utils/uiConstants'
import { PropertiesListEventHandlers } from './types'
import { useKeyCaps } from '../../keyMappableActions/useKeyCaps'

export const usePropertiesListActions = (
  record: RecordItemData,
  handlers: PropertiesListEventHandlers,
  closeDropDown: () => void,
  notesOpen?: boolean,
): MenuAction[] => {
  const keyCaps = useKeyCaps()

  const {
    moveRecords: _moveRecords,
    completeRecords: _completeRecords,
    scheduleRecords: _scheduleRecords,
    addNoteToRecord: _addNoteToRecord,
    hideNote: _hideNote,
    moveRecordsToTrash: _moveRecordsToTrash,
  } = handlers
  const recordId = record.id
  const isCompleted = !!record.isCompleted

  const completeRecords = useCallback(() => {
    _completeRecords(recordId)
    closeDropDown()
  }, [recordId, _completeRecords, closeDropDown])

  const moveRecords = useCallback(() => {
    _moveRecords(recordId)
    closeDropDown()
  }, [recordId, _moveRecords, closeDropDown])

  const scheduleRecords = useCallback(() => {
    _scheduleRecords(recordId)
    closeDropDown()
  }, [recordId, _scheduleRecords, closeDropDown])

  const addNoteToRecord = useCallback(() => {
    _addNoteToRecord(recordId)
    closeDropDown()
  }, [recordId, _addNoteToRecord, closeDropDown])

  const moveRecordsToTrash = useCallback(() => {
    _moveRecordsToTrash(recordId)
    closeDropDown()
  }, [recordId, _moveRecordsToTrash, closeDropDown])

  const hideNote = useCallback(() => {
    _hideNote(recordId)
    closeDropDown()
  }, [recordId, _hideNote, closeDropDown])

  const hasNotes = record.notesList.notes && record.notesList.notes.length > 0

  const isSoftDeleted = record.isSoftDeleted

  const actions: MenuAction[] = useMemo(
    () => [
      {
        type: `menuItem`,
        menuItemProps: {
          title: isCompleted ? UNCOMPLETE : COMPLETE,
          iconProps: {
            variant: isCompleted ? `glyphArrowNCircle` : `glyphCheckCircle`,
          },
          keycaps: keyCaps['KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE'],
          onMouseDown: completeRecords,
        },
      },
      {
        type: `menuItem`,
        menuItemProps: {
          title: `Move`,
          iconProps: {
            variant: 'glyphArrowESquircle',
          },
          keycaps: keyCaps['KEY_MAPPED_OPEN_MOVE_TO_DIALOG'],
          onMouseDown: moveRecords,
        },
      },
      {
        type: `menuItem`,
        menuItemProps: {
          title: `Schedule`,
          iconProps: {
            variant: 'glyphCalendarSingleSquircle',
          },
          keycaps: keyCaps['KEY_MAPPED_OPEN_SCHEDULE_TO_DIALOG'],
          onMouseDown: scheduleRecords,
        },
      },
      {
        type: `menuItem`,
        menuItemProps: {
          title: hasNotes ? (notesOpen ? HIDE_NOTE : SHOW_NOTE) : ADD_NOTE,
          iconProps: {
            variant: 'glyphBlurbSquircle',
          },
          keycaps: keyCaps['KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN'],
          onMouseDown: hasNotes
            ? notesOpen
              ? hideNote
              : addNoteToRecord
            : addNoteToRecord,
        },
      },
      ...(!isSoftDeleted
        ? [
            { type: `divider` } as any,
            {
              type: `menuItem`,
              menuItemProps: {
                title: `Move to trash`,
                iconProps: {
                  variant: 'glyphTrash',
                },
                keycaps: keyCaps['KEY_MAPPED_RECORD_DELETE'],
                onMouseDown: moveRecordsToTrash,
              },
            } as any,
          ]
        : []),
    ],
    [
      addNoteToRecord,
      completeRecords,
      hasNotes,
      isCompleted,
      isSoftDeleted,
      moveRecords,
      moveRecordsToTrash,
      notesOpen,
      scheduleRecords,
    ],
  )

  return actions
}
