import { useCallback, useState } from 'react'

import { PermanentlyDeleteFlowStartedTriggerValueType } from '../../../common/analytics/capture-analytics-actions'
import { RecordItemData } from '../../../common/data/recordGroups'
import {
  MiniBarEventHandlers,
  useMiniBarActions,
} from '../../../common/recordItemEventHandlers'

export const useMiniBar = (
  record: RecordItemData,
  miniBarHandlers: MiniBarEventHandlers,
  fullyExpanded: boolean,
  setHover: (value: boolean) => void,
  setOver: (value: boolean) => void,
  isLongRecord: boolean,
  isMaxHeightInTransition: boolean,
  notesOpen: boolean,
  hasNotes: boolean,
) => {
  const [miniBarActions] = useMiniBarActions(
    record,
    miniBarHandlers,
    fullyExpanded,
    isLongRecord,
    isMaxHeightInTransition,
    notesOpen,
    hasNotes,
  )

  // purpose: correct visibility of minibar

  const onMouseEnter = useCallback(() => setHover(true), [setHover])
  const onMouseOver = useCallback(() => setOver(true), [setOver])
  const onMouseLeave = useCallback(() => {
    setHover(false)
    setOver(false)
  }, [setHover, setOver])

  return {
    onMouseEnter,
    onMouseOver,
    onMouseLeave,
    miniBarActions,
  }
}
