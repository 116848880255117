import { RecordGroupData, RecordGroupType, RecordItemData } from '../types'

export const createRecordGroupData = (
  items: RecordItemData[],
  type: RecordGroupType,
  id: string,
  title?: string,
  subTitle?: string,
): RecordGroupData => ({
  id,
  type,
  title,
  subTitle,
  items,
  showToggle: getShowToggleForRecordGroupType(type),
  showMoveTo: getShowMoveToForRecordGroupType(type, id),
  showCount: getShowCountForRecordGroupType(type),
})

const getShowToggleForRecordGroupType = (type: RecordGroupType): boolean => {
  switch (type) {
    case RecordGroupType.Overdue:
    case RecordGroupType.Date:
      return true
    case RecordGroupType.ListComplete:
    case RecordGroupType.ListMain:
      return false
    default:
      return false
  }
}

const getShowMoveToForRecordGroupType = (
  type: RecordGroupType,
  id: string,
): boolean => {
  switch (type) {
    case RecordGroupType.Overdue:
    case RecordGroupType.Date:
    case RecordGroupType.ListComplete:
    case RecordGroupType.ListMain:
    default:
      return false
  }
}

const getShowCountForRecordGroupType = (type: RecordGroupType): boolean => {
  switch (type) {
    case RecordGroupType.Overdue:
    case RecordGroupType.Date:
    case RecordGroupType.ListMain:
      return false
    case RecordGroupType.ListComplete:
      return true
    default:
      return false
  }
}
