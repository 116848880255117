import { useCallback, useState } from 'react'
import { ListOnScrollProps } from 'react-window'

export const useScrollMemory = () => {
  const [scrollOffset, setScrollOffset] = useState<number | undefined>()

  const onScroll = useCallback((props: ListOnScrollProps) => {
    setScrollOffset(props.scrollOffset)
  }, [])

  return {
    onScroll,
    scrollOffset,
  }
}
