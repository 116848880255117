import {
  Editor,
  EditorLocation,
  RichText,
  RichTextOptions,
} from '@eleventhlabs/capture-shared'
import { createRichTextValue } from '@eleventhlabs/capture-shared/lib/editor/frameworks/lexical'
import { DebouncedEditor } from '../../components/RichTextEditor/debounce/withDebounce'
import { FocusEvent } from '../events'

export const editors: { [id: string]: Editor } = {}

export const editorAnalytics: {
  [id: string]: {
    editingStartTime: number
    shouldFlush: boolean
  }
} = {}

export const EditorsManager = {
  effects: {
    appendAndFocusAtIntersection: (id: string, toAppend: RichText) => {
      const editor = editors[id]
      if (!editor) return
      Editor.effects.appendAndFocusAtIntersection(editor, toAppend)
    },
    blur: (id: string) => {
      const editor = editors[id]
      if (!editor) return
      Editor.effects.blur(editor)
    },
    copyToClipboard: (id: string) => {
      const editor = editors[id]
      if (!editor) return
      Editor.effects.copyToClipboard && Editor.effects.copyToClipboard(editor)
    },
    deleteAllAfterSelectionInclusive: (id: string) => {
      const editor = editors[id]
      if (!editor) return
      Editor.effects.deleteAllAfterSelectionInclusive(editor)
    },
    focus: ({ id, position = `end` }: FocusEvent['payload']) => {
      const editor = editors[id]
      if (!editor) return
      Editor.effects.focus(editor, position)
    },
    paste: (id: string, value: RichText) => {
      const editor = editors[id]
      if (!editor) return
      Editor.effects.paste(editor, value)
    },
    _set: (id: string, value: RichText, selection?: EditorLocation) => {
      DebouncedEditor.cancelPending(id)
      // Do not use Transforms because we don't want to fire onChange events
      const editor = editors[id]
      if (!editor) return
      Editor.effects._set(editor, value, selection)
    },

    clear: (id: string) => {
      const editor = editors[id]
      if (!editor) return

      Editor.effects._set(editor, RichText.create())
    },
  },
  get: {
    /**
     * Is the editor visually empty to the user? Depending on rich text implementation,
     * this may be different from whether the rich text _contents_ is empty
     */
    isVisuallyEmpty: (id: string) => {
      const editor = editors[id]
      if (!editor) return false

      return (
        EditorsManager.get.isEmptyHeading(id) ||
        EditorsManager.get.isEmptyParagraph(id)
      )
    },
    isEmptyParagraph: (id: string) => {
      const editor = editors[id]
      if (!editor) return true
      return Editor.get.isEmptyParagraph(editor)
    },
    isEmptyHeading: (id: string) => {
      const editor = editors[id]
      if (!editor) return true
      return Editor.get.isEmptyHeading(editor)
    },
    isFirstLineParagraph: (id: string) => {
      const editor = editors[id]
      if (!editor) return true
      return Editor.get.isFirstLineParagraph(editor)
    },
    isSelectionEmpty: (id: string) => {
      const editor = editors[id]
      if (!editor) return true
      return Editor.get.isSelectionEmpty(editor)
    },
    isSelectionFocusAtEnd: (id: string) => {
      const editor = editors[id]
      if (!editor) return true
      return Editor.get.isSelectionFocusAtEnd(editor)
    },
    isSelectionFocusAtStart: (id: string) => {
      const editor = editors[id]
      if (!editor) return true
      return Editor.get.isSelectionFocusAtStart(editor)
    },
    richText: (id: string, opts?: RichTextOptions): RichText => {
      const editor = editors[id]
      if (!editor) return createRichTextValue()

      return Editor.get.richText(editor, opts)
    },
    valuesIfSplitAtSelection: (id: string) => {
      const editor = editors[id]
      if (!editor) {
        return { head: createRichTextValue(), tail: createRichTextValue() }
      }

      return Editor.get.valuesIfSplitAtSelection(editor)
    },
  },
  analytics: {
    // Initialize the analytics for the editor.
    register: (id: string, args?: { shouldFlush: boolean }) => {
      if (editorAnalytics[id]) delete editorAnalytics[id]

      editorAnalytics[id] = {
        editingStartTime: Date.now(),
        shouldFlush: args?.shouldFlush ?? false,
      }
    },
    isRegistered: (id: string) => {
      return editorAnalytics[id] !== undefined
    },
    // Mark the analytics payload as dirty / flushable.
    // Used to denote that the analytics have "nontrivial" edits
    validate: (id: string) => {
      if (!editorAnalytics[id] || editorAnalytics[id].shouldFlush) return

      // Mark the analytic payload as flushable
      editorAnalytics[id] = { ...editorAnalytics[id], shouldFlush: true }
    },
    // Get analytics info for the editor.
    // Used to send to downstream services.
    flush: (id: string) => {
      if (!editorAnalytics[id] || !editors[id]) return
      if (!editorAnalytics[id].shouldFlush) {
        // No valid updates, don't actually send anything
        delete editorAnalytics[id]
        return
      }

      const initialAnalyticsData = editorAnalytics[id]

      const millisecondsInAMinute = 1000 * 60

      const stats = Editor.get.stats(editors[id])

      const analytics = {
        totalMinutesEdited:
          (Date.now() - initialAnalyticsData.editingStartTime) /
          millisecondsInAMinute,
        ...stats,
      }

      delete editorAnalytics[id]

      return analytics
    },
  },
  isRegistered: (id: string) => {
    return editors[id] !== undefined
  },
  register: (id: string, editor: Editor) => {
    editors[id] = editor
  },
  unregister: (id: string) => {
    delete editors[id]
  },
}
