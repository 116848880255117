import { WriteTransaction } from 'replicache'

import { OnboardingTrack, RCMutation } from '@eleventhlabs/capture-shared'
import { cloneDeep, omit, set } from 'lodash'
import { IDBUser } from '../models/IDBUser'
import { replicacheUserRepository } from '../replicacheUserRepository'

export const userUncompleteOnboardingSlides = async (
  tx: WriteTransaction,
  { track, slides }: RCMutation.UserUncompleteOnboardingSlides,
) => {
  const user = await replicacheUserRepository.getUser(tx)
  const updatedUser = removeFromCompletedSlidesAndUpdateUser(
    user,
    track,
    slides,
  )
  await replicacheUserRepository.putUser(tx, updatedUser)
}

function removeFromCompletedSlidesAndUpdateUser(
  user: IDBUser,
  trackType: OnboardingTrack,
  slidesToUncomplete: string[],
): IDBUser {
  const userObj = cloneDeep(user)
  initializeTrackIfNotInitialized(userObj, trackType)

  const userDataOnboardingTrack = user.data?.onboarding?.track
  const currentCompletedSlides =
    userDataOnboardingTrack &&
    userDataOnboardingTrack[trackType]?.completedSlides

  const completeSlidesWithSlidesRemoved = omit(
    currentCompletedSlides,
    slidesToUncomplete,
  )

  const path = ['data', 'onboarding', 'track', trackType, 'completedSlides']

  return set(userObj, path, completeSlidesWithSlidesRemoved)
}

function initializeTrackIfNotInitialized(u: IDBUser, track: OnboardingTrack) {
  const isTrackNotInitialized = u.data?.onboarding?.track === undefined
  if (isTrackNotInitialized) initializeTrack(u, track)
}

function initializeTrack(u: IDBUser, track: OnboardingTrack) {
  set(u, `data.onboarding.track.${track}`, {
    savePoints: {},
    completedSlides: {},
    isComplete: true,
  })
}
