import { debounce, find, isEmpty, sortBy } from 'lodash'
import { useCallback, useLayoutEffect, useRef } from 'react'

import { useUIContext } from '../../../common/contexts/UIContext'

const VISIBILITY_THRESHOLD_FOR_BEING_ACTIVE = 0

const ONE_SECOND_IN_MS = 1000
// time that have to elapsed since the last time the debounce function was invoked
const DEBOUNCE_TIMEOUT = 0.05 * ONE_SECOND_IN_MS
// maximum time that the function can be delayed before it's invoked
const DEBOUNCE_MAX_WAIT = 0.1 * ONE_SECOND_IN_MS

interface ItemVisibilityStatus {
  id: string
  pixelsVisible: number
  offsetFromTopViewport: number
}

export interface UseVisibleItemAsActiveData {
  visibilityStatusUpdate: (
    id: string,
    pixelsVisible: number,
    offsetFromTopViewport: number,
  ) => void
}

export const useVisibleItemAsActive = (
  itemIds: string[],
): UseVisibleItemAsActiveData => {
  const items = useRef<ItemVisibilityStatus[]>([])
  const {
    todayFilters: { setTodayDate: setActiveItem },
  } = useUIContext()

  const visibilityStatusUpdate = useCallback(
    (id: string, pixelsVisible: number, offsetFromTopViewport: number) => {
      if (!items.current) return
      items.current = [
        ...items.current
          .filter((item) => itemIds.includes(item.id))
          .filter((item) => item.id !== id),
        { id, pixelsVisible, offsetFromTopViewport },
      ]
    },
    [itemIds],
  )

  const updateActiveItem = useCallback(() => {
    if (isEmpty(items.current)) return
    const sortedItems = sortBy(items.current, [`offsetFromTopViewport`])

    const computedActive =
      find(
        sortedItems,
        (item) => item.pixelsVisible > VISIBILITY_THRESHOLD_FOR_BEING_ACTIVE,
      ) ?? sortedItems[0]
    setActiveItem(computedActive.id)
  }, [setActiveItem])

  useLayoutEffect(() => {
    const delayedUpdateVisibility = debounce(
      updateActiveItem,
      DEBOUNCE_TIMEOUT,
      {
        maxWait: DEBOUNCE_MAX_WAIT,
      },
    )

    window.addEventListener(`scroll`, delayedUpdateVisibility)

    return () => window.removeEventListener(`scroll`, delayedUpdateVisibility)
  }, [updateActiveItem])

  return {
    visibilityStatusUpdate,
  }
}
