import React, { ReactNode, createContext, useContext } from 'react'

import { ModelStore } from '../data/modelStore'
import { ModelStoreCommands, useModelStore } from '../data/modelStore'
import { useAuth } from './AuthContext'

export interface ModelStoreContextValue {
  store: ModelStore
  commands: ModelStoreCommands
}

/*
 * Context
 */

export const ModelStoreContext = createContext<
  ModelStoreContextValue | undefined
>(undefined)
ModelStoreContext.displayName = `ModelStoreContext`

/*
 * Provider
 */

export interface ModelStoreProviderProps {
  children: ReactNode
}

export const ModelStoreProvider = ({ children }: ModelStoreProviderProps) => {
  const { user } = useAuth()

  const userId = (user?.trusted_metadata.captureId as string) ?? ``
  const contextValue = useModelStore(userId)
  return (
    <ModelStoreContext.Provider value={contextValue}>
      {children}
    </ModelStoreContext.Provider>
  )
}

/*
 * Hooks
 */

export const useModelStoreContext = (): ModelStoreContextValue => {
  const context = useContext(ModelStoreContext)

  if (context === undefined) {
    throw new Error(
      `useModelStoreContext must be used within an ModelStoreContext.Provider`,
    )
  }

  return context
}
