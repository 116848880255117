import { useCallback } from 'react'

import { emptyFn } from '../../utils'
import { useLocalStorageBoolean } from '../LocalStorageContext'

export interface ShowCompletedData {
  showCompleted: boolean
  setShowCompleted: (value: boolean) => void
  toggleShowCompleted: () => boolean
}

export const defaultShowCompleted = {
  showCompleted: false,
  setShowCompleted: emptyFn,
  toggleShowCompleted: () => false,
}

export const useShowCompleted = () => {
  const [showCompleted, setShowCompleted] = useLocalStorageBoolean(
    `showCompleted`,
    false,
  )

  const toggleShowCompleted = useCallback(() => {
    const nextValue = !showCompleted
    setShowCompleted(nextValue)
    return nextValue
  }, [showCompleted, setShowCompleted])

  return {
    showCompleted,
    setShowCompleted,
    toggleShowCompleted,
  }
}
