import React, { useState } from 'react'

import { useSnackbarContext } from '../../common/contexts/SnackbarContext'
import { Button, Icon, styled } from '../../common/stationary'
import { Snackbar } from '../../common/stationary/components/Snackbar'
import { safeNavigate } from '../../common/navigate'
import { RouteType, RoutesPath } from '../../common/routes'
import { useAuth } from '../../providers'

export function withSnackbar<T extends {}>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const ComponentWithSnackbar = (props: T) => {
    const {
      isOpen: isSnackbarOpen,
      closeSnackbar,
      actionButtonText,
      onActionButtonClick,
      softDeleteConfirmationCount,
      setSoftDeleteConfirmationCount,
      onSoftDeleteConfirmationClose,
    } = useSnackbarContext()

    const { isChromeExtensionAuthFlow, setIsChromeExtensionAuthFlow } =
      useAuth()

    const onSoftDeleteConfirmationClick = () => {
      setSoftDeleteConfirmationCount(0)
      safeNavigate('/trash')
    }

    // TODO: Extract the snackbar contents / data in a reusable way when we want to trigger
    // different kinds of snackbars
    return (
      <>
        <WrappedComponent {...props} />
        <Snackbar
          onClose={closeSnackbar}
          icon={'glyphWarning'}
          open={isSnackbarOpen}
          autoHideDuration={null}
          closeOnClickAway={false}
        >
          <S.SnackText>
            <span style={{ fontWeight: 'bold' }}>Capture has updated.</span>
            {` Please ${actionButtonText} the app to get the newest version!`}
          </S.SnackText>
          <S.SnackReloadButtonWrapper>
            <S.SnackReloadButton onClick={onActionButtonClick}>
              {actionButtonText}
            </S.SnackReloadButton>
          </S.SnackReloadButtonWrapper>
        </Snackbar>
        <Snackbar
          open={isChromeExtensionAuthFlow}
          onClose={() => {
            setIsChromeExtensionAuthFlow(false)
            safeNavigate(location.pathname)
          }}
        >
          <S.SnackbarInner>
            🌟 You are now logged into the Capture Web Clipper. 🌟
            <S.SnackbarLower>
              Click the icon to save links to your inbox.
            </S.SnackbarLower>
          </S.SnackbarInner>
        </Snackbar>
        <Snackbar
          onClose={onSoftDeleteConfirmationClose}
          open={softDeleteConfirmationCount > 0}
          autoHideDuration={4000}
          closeOnClickAway={false}
        >
          <S.SoftDeleteConfirmation>
            <S.ConfirmationText>
              {softDeleteConfirmationCount} item
              {softDeleteConfirmationCount > 1 ? 's' : ''} move to{' '}
              <S.ConfirmationCollectionText>
                <span>
                  <Icon boxSize={16} variant="glyphTrash" />
                  Trash
                </span>
              </S.ConfirmationCollectionText>
            </S.ConfirmationText>
            <S.ConfirmationButton onClick={onSoftDeleteConfirmationClick}>
              View
            </S.ConfirmationButton>
          </S.SoftDeleteConfirmation>
        </Snackbar>
      </>
    )
  }

  ComponentWithSnackbar.displayName = `ComponentWithSnackbar`

  return ComponentWithSnackbar
}

const S = {
  ActionButton: styled(Button)(({ theme }) => ({
    bottom: 50,
    right: 100,
    backgroundColor: 'red',
    display: `flex`,
    flexDirection: `row`,
    alignItems: `flex-start`,
    padding: 6,

    position: `absolute`,
    width: 338,
    height: 88,
    top: 616,

    background: theme.colors.surface.base,

    border: theme.colors.alpha.border.weak,

    boxShadow: theme.effects.boxShadow.elevation[3],
    borderRadius: 6,
  })),
  SnackText: styled.div(({ theme }) => ({
    width: `240px`,
    height: `48px`,

    fontStyle: `normal`,
    fontSize: 13.5,

    color: theme.colors.text[900],
    lineHeight: `20px`,

    order: 0,
    flexGrow: 0,
  })),
  SnackReloadButtonWrapper: styled.div(() => ({
    width: `100%`,
    height: `100%`,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 6,
  })),
  SnackReloadButton: styled.button(() => ({
    fontWeight: 'bold',
    fontSize: 11.5,
    cursor: 'pointer',
    letterSpacing: `0.05em`,
    textTransform: `uppercase`,
  })),
  SnackbarInner: styled.div({
    height: '100%',
    marginTop: 12,
    marginLeft: -24,
  }),
  SnackbarLower: styled.div({
    marginLeft: 22,
  }),
  CloseButtonWrapper: styled.div({
    width: 28,
    height: 28,
    padding: 8,
    order: 1,
  }),
  CloseButton: styled(Icon)({
    cursor: 'pointer',

    opacity: 0.8,

    flex: `none`,
    flexGrow: 0,
  }),
  SoftDeleteConfirmation: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    ${({ theme }) => theme.text.publicSans['13.5:20']};
  `,
  ConfirmationText: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 6px;
  `,
  ConfirmationCollectionText: styled.div`
    display: inline-block;

    span {
      display: flex;
      align-items: center;
      gap: 2px;
      font-weight: 600;
    }
  `,
  ConfirmationButton: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 6px;
    ${({ theme }) => theme.text.publicSans['11.5:16']};
    letter-spacing: ${({ theme }) =>
      theme.text.publicSans.uppercase.letterSpacing};
    text-transform: ${({ theme }) =>
      theme.text.publicSans.uppercase.textTransform};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text[700]};
    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.colors.text[900]};
    }
  `,
}
