import { findIndex, isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'

import {
  Frack,
  RecordDict,
  RecordType,
  RootRecord,
  RootRecordId,
} from '@eleventhlabs/capture-shared'

import Analytics from '../../common/analytics/capture-analytics-actions'
import { useActiveScreen } from '../../common/analytics/capture-analytics-web'
import { useDerivedStateContext } from '../../common/contexts/DerivedStateContext'
import { useExecContext } from '../../common/contexts/ExecContext'
import { RecordGroupType } from '../../common/data/recordGroups'
import { EditorsManager } from '../../common/EditorsManager'
import { useNavigateToList } from '../../common/hooks/useNavigateToList'
import { BaseSelection } from '../../common/libs/selection'
import { RoutesId, RouteType, useRouteFromLocation } from '../../common/routes'
import { SelectionHotKeyCommands } from '../../common/selection/useSelectionHotkeyCommands'

export interface CommonScreenHotKeys {
  onCmdDown: (e: KeyboardEvent) => void
  onCmdUp: (e: KeyboardEvent) => void
  onDownArrow: (e: KeyboardEvent) => void
  onEnter: (e: KeyboardEvent) => void
  onEsc: (e: KeyboardEvent) => void
  onShiftDown: (e: KeyboardEvent) => void
  onShiftUp: (e: KeyboardEvent) => void
  onTab: () => void
  onUpArrow: (e: KeyboardEvent) => void
}

export const useCommonScreenHotKeys = (
  records: RecordDict,
  selectionHotKeyCommands: SelectionHotKeyCommands,
  selection: BaseSelection,
  routeType: RouteType,
): CommonScreenHotKeys => {
  const { exec } = useExecContext()

  const activeScreen = useActiveScreen()

  const onDownArrow = useCallback(
    (e) => {
      e.preventDefault()
      const affectedRecords = selectionHotKeyCommands.onDownArrow()
      if (!isEmpty(affectedRecords)) {
        Analytics.recordSelected({
          activeScreen,
          recordSelectedTrigger: Analytics.RecordSelectedTrigger.KEYBOARD_ARROW,
          recordIds: affectedRecords,
        })
      }
    },
    [activeScreen, selectionHotKeyCommands],
  )

  const onShiftDown = useCallback(
    (e) => {
      e.preventDefault()
      const affectedRecords = selectionHotKeyCommands.onShiftDown()
      if (!isEmpty(affectedRecords)) {
        Analytics.recordSelected({
          activeScreen,
          recordSelectedTrigger: Analytics.RecordSelectedTrigger.KEYBOARD_ARROW,
          recordIds: affectedRecords,
        })
      }
    },
    [activeScreen, selectionHotKeyCommands],
  )

  const numSelectedRecords = selection.ids.length
  const onCmdDown = useCallback(
    (e) => {
      e.preventDefault()
      selectionHotKeyCommands.onCmdDown()
      if (numSelectedRecords > 0) {
        Analytics.recordsReordered({
          activeScreen,
          numRecords: numSelectedRecords,
          recordIds: selection.ids,
        })
      }
    },
    [activeScreen, numSelectedRecords, selectionHotKeyCommands],
  )

  const onUpArrow = useCallback(
    (e) => {
      e.preventDefault()
      const affectedRecords = selectionHotKeyCommands.onUpArrow()
      if (!isEmpty(affectedRecords)) {
        Analytics.recordSelected({
          activeScreen,
          recordSelectedTrigger: Analytics.RecordSelectedTrigger.KEYBOARD_ARROW,
          recordIds: affectedRecords,
        })
      }
    },
    [activeScreen, selectionHotKeyCommands],
  )

  const onShiftUp = useCallback(
    (e) => {
      e.preventDefault()
      const affectedRecords = selectionHotKeyCommands.onShiftUp()
      if (!isEmpty(affectedRecords)) {
        Analytics.recordSelected({
          activeScreen,
          recordSelectedTrigger: Analytics.RecordSelectedTrigger.KEYBOARD_ARROW,
          recordIds: affectedRecords,
        })
      }
    },
    [activeScreen, selectionHotKeyCommands],
  )

  const onCmdUp = useCallback(
    (e) => {
      e.preventDefault()
      selectionHotKeyCommands.onCmdUp()
      if (numSelectedRecords > 0) {
        Analytics.recordsReordered({
          activeScreen,
          numRecords: numSelectedRecords,
          recordIds: selection.ids,
        })
      }
    },
    [activeScreen, numSelectedRecords, selectionHotKeyCommands],
  )

  const onEsc = useCallback(
    (e) => {
      e.preventDefault()
      selectionHotKeyCommands.onEsc()
    },
    [selectionHotKeyCommands],
  )

  const onEnter = useCallback(
    (e) => {
      e.preventDefault()
      const selectionFocus = selection.ids[0]
      if (selection.ids.length === 1) {
        const record = records[selectionFocus]
        const recordId = record.id
        if (record.type === RecordType.File) {
          exec({ type: 'FILE_RECORD_OPEN_SRC_IN_TAB', recordId })
        } else if (record.type === RecordType.URL) {
          exec({ type: 'URL_RECORD_OPEN_SRC_IN_TAB', recordId })
        } else if (record.type === RecordType.Text) {
          EditorsManager.effects.focus({
            id: selectionFocus,
            position: `endOfFirstLine`,
          })
        } else {
          throw new Error(`Unexpected record type`)
        }
      }
    },
    [records, activeScreen, selection, exec],
  )

  const { listId } = useRouteFromLocation()
  const navigateToList = useNavigateToList()
  const root = useMemo(() => {
    return records[RootRecordId] as RootRecord
  }, [records])
  const onTab = useCallback(() => {
    const navOrder = [
      RoutesId[RouteType.Inbox],
      RoutesId[RouteType.Today],
      ...Frack.toArray(root.children),
    ]
    const currentScreenId =
      routeType === RouteType.List ? listId : RoutesId[routeType]
    if (currentScreenId === undefined) {
      console.error(`screen id undefined`)
      return
    }
    const index = findIndex(navOrder, (v) => v === currentScreenId)
    if (index === -1) {
      console.error(`Undefined "next screen" on Tab key.`)
      return
    }
    if (index === navOrder.length - 1) return
    const targetList = navOrder[index + 1]
    if (targetList) navigateToList(targetList)
  }, [routeType, listId, root, navigateToList])

  const onShiftTab = useCallback(() => {
    const navOrder = [
      RoutesId[RouteType.Inbox],
      RoutesId[RouteType.Today],
      ...Frack.toArray(root.children),
    ]
    const currentScreenId =
      routeType === RouteType.List ? listId : RoutesId[routeType]
    if (currentScreenId === undefined) throw new Error(`Unexpected screen id`)
    const index = findIndex(navOrder, (v) => v === currentScreenId)
    if (index === -1) {
      console.error(`Undefined "prev screen" on Tab key.`)
      return
    }
    if (index === 0) return
    const targetList = navOrder[index - 1]
    if (targetList) navigateToList(targetList)
  }, [routeType, listId, root, navigateToList])

  return useMemo(
    () => ({
      onCmdDown,
      onCmdUp,
      onDownArrow,
      onEnter,
      onEsc,
      onShiftDown,
      onShiftTab,
      onShiftUp,
      onTab,
      onUpArrow,
    }),
    [
      onCmdDown,
      onCmdUp,
      onDownArrow,
      onEnter,
      onEsc,
      onShiftDown,
      onShiftTab,
      onShiftUp,
      onTab,
      onUpArrow,
    ],
  )
}
