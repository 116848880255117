import { useAuth } from '../../contexts/AuthContext'
import { useSelectionContext } from '../../contexts/SelectionContext'
import { WSEventName, getChannel, ws } from '../../websocket'
import { getPrivateUserChannelName } from '../../websocket/utils'
import { useOnlineStatus } from './useOnlineStatus'
import { useEffect } from 'react'

export const useBlurOtherClientsWhenOnline = () => {
  const { isOnline } = useOnlineStatus()
  const { focusedRecordId } = useSelectionContext()
  const { userId } = useAuth()

  useEffect(() => {
    // If client gained access to network and a record is currently focused, send blur event
    if (isOnline && userId && focusedRecordId) {
      ws.sendClientEvent(userId, {
        name: WSEventName.RECORD_EDITING_STARTED,
        payload: {
          recordId: focusedRecordId,
        },
      })
    }
  }, [isOnline, focusedRecordId, userId])
}
