import { useState } from 'react'

/**
 * Returns the current online status of the user.
 *
 * @return {{ isOnline: boolean }} An object containing the online status of the user.
 */
export const useOnlineStatus = (): { isOnline: boolean } => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  window.addEventListener('online', () => setIsOnline(true))
  window.addEventListener('offline', () => setIsOnline(false))

  return { isOnline }
}
