import React from 'react'
import { Helmet } from 'react-helmet'
import warningIconSrc from '../../../assets/onboarding/warning-icon.png'
import { AuthErrorState } from '../../../common/contexts/AuthContext'
import { isMobile } from '../../../common/utils/env'
import { AuthStyles as S } from '../AuthStyles'
import AuthLogo from './AuthLogo'
import LoginFooter from './LoginFooter'

interface PkceErrorMessageProps {
  setErrorState: (error?: AuthErrorState) => void
}

/**
 * PkceErrorMessage
 */

const PkceErrorMessage: React.FC<PkceErrorMessageProps> = ({
  setErrorState,
}) => (
  <S.AuthScreen>
    <Helmet>
      <title>Capture - Log in</title>
    </Helmet>
    <S.Main>
      <S.AuthRelatedMessage>
        <S.AuthHeader>
          <AuthLogo
            href={!isMobile ? '/' : undefined}
            secondaryIconSrc={warningIconSrc}
          />
          <S.AuthTitle>
            <strong>There was a problem with your Capture login</strong>
          </S.AuthTitle>
        </S.AuthHeader>
        <S.AuthBody>
          <S.ActionHint>
            Please try logging in again. Make sure to use the{' '}
            <strong>same device or browser</strong> when you open the login link
            from your email
          </S.ActionHint>
          <S.GoToLogin onClick={() => setErrorState(undefined)}>
            Log in
          </S.GoToLogin>
          <S.ContactUs>
            Still having issues?
            <a href="mailto:membership@capture.so">Contact us</a>
          </S.ContactUs>
        </S.AuthBody>
      </S.AuthRelatedMessage>
    </S.Main>
    {!isMobile && <LoginFooter />}
  </S.AuthScreen>
)

export default PkceErrorMessage
