import { isEmpty } from 'lodash'
import React from 'react'

import DialogInput from '../../DialogInput'
import { Icon, styled } from '../../../common/stationary'
import { RECORDS } from '../../../common/utils/uiConstants'

export interface ScheduleHeaderProps {
  inputRef?: React.RefObject<HTMLInputElement>
  selectedRecordIds: string[]
  inputValue: string
  validInput?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
  onBlur?: () => void
  handleSearchInputKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void
}

export const ScheduleHeader = ({
  inputRef,
  selectedRecordIds,
  inputValue,
  validInput,
  onChange,
  handleSearchInputKeyDown,
  onSubmit,
}: ScheduleHeaderProps) => {
  return (
    <S.Header>
      <S.InfosContainer>
        <S.InfosLeft>
          <S.InfoItem>
            <S.Icon boxSize={16} variant="glyphCalEmpty" />
            <S.Title>Schedule</S.Title>
          </S.InfoItem>
        </S.InfosLeft>
        {!isEmpty(selectedRecordIds) ? (
          <S.InfosRight>
            <S.Selected>
              <S.InfoTagStatic>
                {selectedRecordIds.length} selected
              </S.InfoTagStatic>
            </S.Selected>
          </S.InfosRight>
        ) : null}
      </S.InfosContainer>
      <DialogInput
        inputRef={inputRef}
        onKeyDown={handleSearchInputKeyDown}
        placeholder="Type a date..."
        inputValue={inputValue}
        validInput={validInput}
        onSubmit={onSubmit}
        onChange={onChange}
      />
      <S.Disclaimer>
        Any changes will affect all selected {RECORDS}.
      </S.Disclaimer>
    </S.Header>
  )
}

export default ScheduleHeader

const S = {
  Header: styled.div({
    display: `flex`,
    flexDirection: `column`,
    padding: 16,
    userSelect: `none`,
  }),
  InfosContainer: styled.div({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
  }),
  InfosLeft: styled.ul({
    flex: 1,
    display: `flex`,
  }),
  InfosRight: styled.ul({
    display: `flex`,
    height: `100%`,
  }),
  InfoItem: styled.li(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    boxShadow: `0 0 0 1px ${theme.colors.alpha.border.weak}`,
    borderRadius: 6,
    outline: `none`,
    userSelect: `none`,
    padding: 4,
  })),
  Selected: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    outline: `none`,
    userSelect: `none`,
    padding: 4,
  }),
  Icon: styled(Icon)(({ theme }) => ({
    marginRight: 4,
    color: theme.colors.text[500],
  })),
  Title: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    fontWeight: `bold`,
    lineHeight: `20px`,
    color: theme.colors.text[700],
  })),
  InfoTagStatic: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: `0 4px`,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[700],
    borderRadius: 6,
  })),
  Disclaimer: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 16px`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    fontSize: theme.text.publicSans['13.5:20'].fontSize,
    lineHeight: `16px`,
    color: theme.colors.text[500],
    padding: `0 4px`,
  })),
}
