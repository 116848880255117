import { useCallback } from 'react'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { SlideType } from './../components/slides'
import { trackType } from './../components/tracks'

export function useCompleteOnboardingSlides() {
  const { commands } = useModelStoreContext()

  const completeSlides = useCallback(
    (slides: SlideType[]) => {
      commands.userCompleteOnboardingSlides(trackType, slides)
    },
    [trackType],
  )

  const uncompleteSlides = useCallback(
    (slides: SlideType[]) => {
      commands.userUncompleteOnboardingSlides(trackType, slides)
    },
    [trackType],
  )

  return { completeSlides, uncompleteSlides }
}
