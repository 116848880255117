import { isEmpty } from 'lodash'
import React from 'react'

import { Tag, TagType } from '../../../common/data/tags'
import { emptyFn } from '../../../common/utils'
import { RecordItemTagOnMouse } from '../types'
import TagStripItem from './TagStripItem'
import { styled, propertiesStripStyles } from '../../../common/stationary'

interface TagStripProps {
  className?: string
  tags: Tag[]
  onTagClick: RecordItemTagOnMouse
  skipOccurrence: () => void
  isCompleted?: boolean
  notesOpen?: boolean
}

export const TagStrip: React.FC<TagStripProps> = ({
  className,
  tags,
  onTagClick = emptyFn,
  skipOccurrence,
  isCompleted,
  notesOpen,
}) => {
  const sortedTags = sortStripTags(tags)

  return !isEmpty(sortedTags) ? (
    <S.TagStrip className={className}>
      {sortedTags.map((tag, index) => (
        <React.Fragment key={tag.text}>
          {tag.type === TagType.PLACE ? <S.Filler /> : null}
          {tag.type === TagType.NOTES && index > 0 ? <S.Separator /> : null}
          <TagStripItem
            onTagClick={(event) => onTagClick(event, tag)}
            skipOccurrence={skipOccurrence}
            tag={tag}
            isCompleted={isCompleted}
            notesOpen={notesOpen}
          />
        </React.Fragment>
      ))}
    </S.TagStrip>
  ) : null
}

function sortStripTags(tags: Tag[]) {
  return [
    ...tags.filter((tag) => tag.type === TagType.DATE),
    ...tags.filter((tag) => tag.type === TagType.NOTES),
    ...tags.filter((tag) => tag.type === TagType.PLACE),
  ]
}

const S = {
  TagStrip: styled.div(({ theme }) => ({
    ...propertiesStripStyles({ theme }),

    padding: `0 15px 0 22px`,
    pointerEvents: `none`,
  })),
  Filler: styled.div({
    display: `flex`,
    flex: 1,
  }),
  Separator: styled.div(({ theme }) => ({
    display: `flex`,
    height: 12,
    width: 1,
    marginLeft: 8,
    marginRight: 8,
    background: theme.colors.alpha.tone.strong,
  })),
}
