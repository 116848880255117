import { useCallback, useEffect, useState } from 'react'

import {
  convertReminderMinutesToDisplay,
  nlpInputToReminder,
} from '../../../../common/utils'

export const useReminderInput = (
  value: number | undefined,
  setReminder: (minutes: number | null) => void,
  close: () => void,
) => {
  const [inputValue, setInputValue] = useState(``)

  useEffect(() => {
    setInputValue(convertReminderMinutesToDisplay(value))
  }, [value])

  const isValidInput = useCallback((value: string | undefined): boolean => {
    if (!value) return false
    return !!nlpInputToReminder(value)
  }, [])

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const inputNumber = nlpInputToReminder(inputValue)
      if (event.key === `Enter` && inputNumber !== undefined) {
        setReminder(inputNumber)
        close()
        return true
      }
      return false
    },
    [inputValue, setReminder, close],
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value
      setInputValue(value ?? ``)
    },
    [setInputValue],
  )

  const onSubmit = useCallback(() => {
    const inputNumber = nlpInputToReminder(inputValue)
    if (inputNumber === undefined) return
    setReminder(inputNumber)
    close()
  }, [inputValue, setReminder, close])

  return {
    inputValue,
    onKeyDown,
    onChange,
    onSubmit,
    isValidInput,
  }
}
