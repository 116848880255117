import { find } from 'lodash'

import { DataRecord, isNoteableRecord } from '@eleventhlabs/capture-shared'

export function getParentId(
  noteId: string | undefined,
  records: Record<string, DataRecord>,
) {
  if (!noteId) return
  const parentRecord = find(
    records,
    (record) => isNoteableRecord(record) && record.notes.includes(noteId),
  )
  return parentRecord?.id
}
