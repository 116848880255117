import { useMemo, useRef } from 'react'

import { ScrollCommands } from './types'

interface List {
  scrollToItem: (
    index: number,
    align?: 'auto' | 'smart' | 'center' | 'end' | 'start' | undefined,
  ) => void
  scrollTo: (scrollOffset: number) => void
}

interface UseScrollCommands<T> {
  scrollCommands: ScrollCommands
  scrollRef: React.RefObject<T>
}

export const useScrollCommands = <T extends List>(
  itemIds: string[],
  align?: 'auto' | 'smart' | 'center' | 'end' | 'start' | undefined,
): UseScrollCommands<T> => {
  const listRef = useRef<T>(null)

  const scrollCommands = useMemo(
    () => ({
      scrollToId: (id: string) => {
        const index = itemIds.indexOf(id)
        listRef.current?.scrollToItem(index, align)
      },
      scrollToIndex: (index: number) => {
        listRef.current?.scrollToItem(index, align)
      },
      scrollToPosition: (position: number) => {
        listRef.current?.scrollTo(position)
      },
    }),
    [itemIds, align],
  )

  const result = useMemo(
    () => ({
      scrollCommands,
      scrollRef: listRef,
    }),
    [scrollCommands],
  )

  return result
}
