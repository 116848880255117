import React, { ComponentPropsWithRef } from 'react'

import { styled } from '../styled'

export interface CountProps extends ComponentPropsWithRef<`div`> {
  max?: number
  showZero?: boolean
  value: number
}

export const Count: React.FC<CountProps> = ({
  max,
  showZero = false,
  value,
  ...props
}) => {
  const count = max === undefined || value <= max ? `${value}` : `${max}+`

  return value !== 0 || showZero ? <S.Count {...props}>{count}</S.Count> : null
}

const S = {
  Count: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    height: 20,
    minWidth: 20,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    ...theme.text.publicSans[`13.5:20`],
    fontWeight: 600,
  })),
}
