import { WriteTransaction } from 'replicache'

import { RCMutation } from '@eleventhlabs/capture-shared'

import {
  getTickleableRecord,
  updateFIndexesForMoveIntoDateGroup,
  updateTickler,
} from './utils'

export const moveIntoDateGroup = async (
  tx: WriteTransaction,
  {
    dateGroup,
    executedAtOnClient,
    point,
    recordIds,
    tzOnClient,
  }: RCMutation.MoveIntoDateGroupArgs,
): Promise<void> => {
  await updateFIndexesForMoveIntoDateGroup(
    tx,
    recordIds,
    dateGroup,
    point,
    executedAtOnClient,
    tzOnClient,
  )
  if (dateGroup !== `overdue`) {
    for (const recordId of recordIds) {
      const record = await getTickleableRecord(tx, recordId)
      await updateTickler(tx, record, { isoDate: dateGroup })
    }
  }
}
