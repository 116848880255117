import {
  DataRecord,
  Frack,
  isDeleteableRecord,
  RecordType,
} from '@eleventhlabs/capture-shared'
import { NavigateFn } from '@gatsbyjs/reach-router'
import { filter } from 'lodash'

import { RoutesPath } from '../../../../routes'

export const removeList = (
  records: Record<string, DataRecord>,
  navigate: NavigateFn,
  deleteRecords: (recordIds: string[]) => void,
  listId?: string,
  currentActiveList?: string,
) => {
  if (!listId) return
  const listRecord = records[listId]
  if (listRecord.type !== RecordType.List)
    throw new Error(`Unexpected record type`)
  const softDeletedRecordsFromThisList = getAllSoftDeletedRecords(records)
    .filter(
      (record) => isDeleteableRecord(record) && record.parentId === listId,
    )
    .map((r) => r.id)
  deleteRecords([...softDeletedRecordsFromThisList, listId])
  // if we delete the active list, navigate to Inbox
  if (listId === currentActiveList) {
    navigate(RoutesPath.Inbox)
  }
}

const getAllSoftDeletedRecords = (records: Record<string, DataRecord>) => {
  return filter(records, (r) => {
    return isDeleteableRecord(r) && r.isSoftDeleted
  })
}
