import React from 'react'

import { HandleResultAction, MoveItemData } from '../MoveTo.types'
import DestinationFinder from './DestinationFinder'
import { css } from '../../../common/stationary'

export interface MoveToBodyProps {
  query: string
  items: MoveItemData[]
  handleResultClick: HandleResultAction
  onItemHover: (id: string) => void
}

const MoveToBody = ({
  query,
  items,
  handleResultClick,
  onItemHover,
}: MoveToBodyProps) => {
  return (
    <div
      className={css({
        Move: {
          padding: 0,
        },
      })}
    >
      <DestinationFinder
        query={query}
        items={items}
        onResultClick={handleResultClick}
        onItemHover={onItemHover}
      />
    </div>
  )
}

export default MoveToBody
