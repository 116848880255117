import { cx } from '@emotion/css'
import { PasswordStrengthCheckResponse } from '@stytch/vanilla-js'
import React, { FC, FormEvent, useState } from 'react'
import HidePasswordIcon from '../../../assets/icon/icon-hide-password.svg'
import ShowPasswordIcon from '../../../assets/icon/icon-show-password.svg'
import { styled } from '../../../common/stationary'
import { useAuth } from '../../../providers'
import { AuthStyles as S } from '../../LoginScreen/AuthStyles'
import PasswordStrengthAndHintDisplay from './PasswordStrengthAndHint'
import { PASSWORD_RESET, PASSWORD_SET } from '../../../common/utils/uiConstants'

/**
 * ResetPasswordForm
 */

export const TOTAL_POSSIBLE_SCORE = 4
export const ERROR_THRESHOLD = 2

interface ResetPasswordFormProps {
  isUserWithPassword: boolean
  isLoading: boolean
  onSubmit?: (event: FormEvent<HTMLFormElement>, username: string) => void
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
  confirmationPassword: string
  setConfirmationPassword: React.Dispatch<React.SetStateAction<string>>
  resetErrors?: () => void
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = (
  props: ResetPasswordFormProps,
) => {
  const {
    isUserWithPassword,
    onSubmit = () => undefined,
    isLoading,
    setConfirmationPassword,
    confirmationPassword,
    password,
    setPassword,
    resetErrors,
  } = props

  const { strengthCheck, email } = useAuth()
  const [passwordStrengthCheckResponse, setPasswordStrengthCheckResponse] =
    useState<PasswordStrengthCheckResponse>()
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const isPasswordValid = passwordStrengthCheckResponse?.valid_password

  const checkPasswordStrength = async (passwordToCheck: string) => {
    const response = await strengthCheck(passwordToCheck)
    setPasswordStrengthCheckResponse(response)
  }

  const getShowHideIcon = () => {
    return showPassword ? (
      <ResetStyles.HidePasswordIcon onClick={handleTogglePassword} />
    ) : (
      <ResetStyles.ShowPasswordIcon onClick={handleTogglePassword} />
    )
  }

  return (
    <S.AuthForm onSubmit={(event) => onSubmit(event, password)}>
      <ResetStyles.HiddenInput
        type="email"
        id="email"
        name="email"
        defaultValue={email}
      />
      <S.InputWithIcon>
        <S.AuthPassword
          name="newPassword"
          type={showPassword ? 'text' : 'password'}
          onChange={(event) => {
            resetErrors && resetErrors()
            checkPasswordStrength(event.target.value)
            setPassword(event.target.value)
          }}
          value={password}
          placeholder={isUserWithPassword ? 'New password' : 'Password'}
        />
        <S.InlineIconPositioning>{getShowHideIcon()}</S.InlineIconPositioning>
      </S.InputWithIcon>
      <S.AuthPassword
        name="confirmationPassword"
        type={showPassword ? 'text' : 'password'}
        onChange={(event) => {
          resetErrors && resetErrors()
          setConfirmationPassword(event.target.value)
        }}
        value={confirmationPassword}
        placeholder={
          isUserWithPassword ? 'Confirm new password' : 'Confirm Password'
        }
      />
      <PasswordStrengthAndHintDisplay
        passwordStrengthCheckResponse={passwordStrengthCheckResponse}
      />
      <S.AuthSubmit type="submit" disabled={isLoading || !isPasswordValid}>
        {isUserWithPassword ? PASSWORD_RESET : PASSWORD_SET} password
        {isLoading && (
          <S.SubmitIcon
            className={cx({ isLoading })}
            boxSize={16}
            variant="glyphCircleThreeQuarters"
          />
        )}
      </S.AuthSubmit>
    </S.AuthForm>
  )
}

export default ResetPasswordForm

const ResetStyles = {
  ShowPasswordIcon: styled(ShowPasswordIcon)(() => ({
    height: 16,
    cursor: 'pointer',
  })),
  HidePasswordIcon: styled(HidePasswordIcon)(() => ({
    height: 16,
    cursor: 'pointer',
  })),
  OldPasswordInput: styled(S.InputWithIcon)(() => ({
    marginBottom: 8,
  })),
  HiddenInput: styled.input(() => ({
    height: 0,
    width: 0,
  })),
}
