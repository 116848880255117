import levenshtein from 'js-levenshtein'
import { every, filter, first, flatMap, isEmpty, slice, some } from 'lodash'

import {
  DataRecord,
  isCompleteableRecord,
  isNoteableRecord,
  isTickleableRecord,
  RecordType,
} from '@eleventhlabs/capture-shared'

import { getIsExpanded, RecordItemExpandStateDict } from '../../expandRecord'
import {
  canBeConvertedToTextRecord,
  canBeConvertedToUrlRecord,
} from '../../records'
import { RouteType } from '../../routes'
import {
  CommandBarCommand,
  CommandBarDomain,
  CommandFilter,
  GroupItem,
  ItemType,
} from './CommandBar.types'
import { ThemeName, themesConfig } from '../../stationary'

export const filterItems = (
  items: GroupItem[],
  query: string,
  routeType: RouteType,
  selectedIds: string[],
  records: DataRecord[],
  themeName: ThemeName,
  showCompleted: boolean,
  isLeftHidden: boolean,
  showNextFourteenDays: boolean,
  shouldDisplayNotesForRecordId: (id: string) => boolean,
  recordItemExpandState: RecordItemExpandStateDict,
  exact = true,
): GroupItem[] => {
  const filtered: GroupItem[] = []
  const mappedItems = items
    .filter((item) => {
      const queryWords = query.split(` `).map((word) => word.toLowerCase())
      const keywords = item.keywords.map((keyword) => keyword.toLowerCase())

      return (
        query === `` ||
        every(queryWords, (word) => {
          if (isEmpty(word)) return true
          return some(keywords, (keyword) => {
            if (exact) return keyword.includes(word)
            if (word.length > 3)
              return levenshtein(word, keyword) < 3 || keyword.includes(word)
            return false
          })
        })
      )
    })
    .filter((item) =>
      checkFilters(
        item.filters,
        routeType,
        selectedIds,
        records,
        themeName,
        showCompleted,
        isLeftHidden,
        showNextFourteenDays,
        shouldDisplayNotesForRecordId,
        recordItemExpandState,
      ),
    )

  // remove all group items that doesn't have any children
  mappedItems.forEach((item, index) => {
    if (
      item.type === ItemType.Group &&
      mappedItems[index + 1]?.type === ItemType.Group
    )
      return
    filtered.push(item)
  })

  return filtered
}

export const domainsToItems = (domains: CommandBarDomain[]): GroupItem[] => {
  return slice(
    flatMap(domains, (domain) => [
      {
        id: `domain:${domain.title}`,
        type: ItemType.Group,
        title: domain.title,
        keywords: [],
        filters: [],
        icon: `glyphCircle`,
      },
      ...commandsToItems(domain.commands),
    ]),
    1, // remove first group
  )
}

export const commandsToItems = (commands: CommandBarCommand[]): GroupItem[] => {
  return commands.map((command) => ({
    ...command,
    type: ItemType.Action,
    title: command.label,
    keywords: command.keywords,
    shortcut: command.shortcut ?? [],
    icon: command.icon,
  }))
}

export const checkFilters = (
  filters: CommandFilter[],
  routeType: RouteType,
  selectedIds: string[],
  records: DataRecord[],
  themeName: ThemeName,
  showCompleted: boolean,
  isLeftHidden: boolean,
  showNextFourteenDays: boolean,
  shouldDisplayNotesForRecordId: (id: string) => boolean,
  recordItemExpandState: RecordItemExpandStateDict,
): boolean => {
  return every(filters, (cmdFilter) => {
    switch (cmdFilter) {
      case CommandFilter.AT_LEAST_ONE_SELECTION:
        return !isEmpty(selectedIds)
      case CommandFilter.ACTIVE_SCREEN_IS_NOT_TRASH:
        return routeType !== RouteType.Trash
      case CommandFilter.NONE_COLLAPSED:
        return (
          records.filter((r) => !getIsExpanded(recordItemExpandState, r))
            .length === 0
        )
      case CommandFilter.AT_LEAST_ONE_COLLAPSED:
        return (
          records.filter((r) => !getIsExpanded(recordItemExpandState, r))
            .length > 0
        )
      case CommandFilter.ALL_HAVE_NOTES_AND_OPEN:
        const allRecordsHaveNotes =
          records.filter((r) => isNoteableRecord(r) && r.notes.length === 0)
            .length === 0
        const allNotesOpen =
          selectedIds.filter((id) => !shouldDisplayNotesForRecordId(id))
            .length === 0
        return allRecordsHaveNotes && allNotesOpen
      case CommandFilter.AT_LEAST_ONE_NOTE_CLOSED_OR_NO_NOTES:
        const noRecordsHaveNotes =
          records.filter((r) => isNoteableRecord(r) && r.notes.length > 0)
            .length === 0
        const atLeastOneClosed =
          selectedIds.filter((id) => !shouldDisplayNotesForRecordId(id))
            .length > 0
        return noRecordsHaveNotes || atLeastOneClosed
      case CommandFilter.RECORD_HAS_ANNOTATIONS:
        return some(
          records,
          (record) => !isEmpty(isNoteableRecord(record) ? record.notes : {}),
        )
      case CommandFilter.RECORD_HAS_NO_ANNOTATIONS:
        return every(records, (record) =>
          isEmpty(isNoteableRecord(record) ? record.notes : {}),
        )
      case CommandFilter.RECORD_HAS_ANNOTATIONS_OPEN:
        return some(records, (record) =>
          shouldDisplayNotesForRecordId(record.id),
        )
      case CommandFilter.RECORD_HAS_ANNOTATIONS_CLOSED:
        return some(
          filter(
            records,
            (record) => !isEmpty(isNoteableRecord(record) ? record.notes : {}),
          ),
          (record) => !shouldDisplayNotesForRecordId(record.id),
        )
      case CommandFilter.RECORDS_CAN_BE_CONVERTED_TO_URL:
        return every(records, (record) => canBeConvertedToUrlRecord(record))
      case CommandFilter.RECORDS_CAN_BE_CONVERTED_TO_TEXT:
        return every(records, (record) => canBeConvertedToTextRecord(record))
      case CommandFilter.RECORD_HAS_DATE:
        return some(
          records,
          (record) => isTickleableRecord(record) && !!record.tickler,
        )
      case CommandFilter.AT_LEAST_ONE_UNCOMPLETED:
        return some(
          records,
          (record) => isCompleteableRecord(record) && !record.isCompleted,
        )
      case CommandFilter.ALL_COMPLETED:
        return every(
          records,
          (record) => isCompleteableRecord(record) && !!record.isCompleted,
        )
      case CommandFilter.SINGLE_SELECTION:
        return selectedIds.length === 1
      case CommandFilter.RECORD_TYPE_IS_FILE:
        return some(records, (record) => record.type === RecordType.File)
      case CommandFilter.ACTIVE_SCREEN_IS_COLLECTION:
        return routeType === RouteType.List
      case CommandFilter.ACTIVE_SCREEN_IS_TODAY:
        return routeType === RouteType.Today
      case CommandFilter.ACTIVE_SCREEN_IS_TODAY:
        return routeType === RouteType.Today
      case CommandFilter.ACTIVE_SCREEN_IS_TRASH:
        return routeType === RouteType.Trash
      case CommandFilter.ACTIVE_SCREEN_IS_COLLECTION_INBOX:
        return routeType === RouteType.List || routeType === RouteType.Inbox
      case CommandFilter.ACTIVE_SCREEN_IS_NOT_INBOX:
        return routeType !== RouteType.Inbox
      case CommandFilter.ACTIVE_SCREEN_IS_NOT_TODAY:
        return routeType !== RouteType.Today
      case CommandFilter.ACTIVE_SCREEN_IS_FULLSCREEN:
        return routeType === RouteType.Details
      case CommandFilter.ACTIVE_SCREEN_IS_NOT_FULLSCREEN:
        return routeType !== RouteType.Details
      case CommandFilter.ACTIVE_SCREEN_IS_RECORD_GROUP:
        return routeType !== RouteType.Details
      case CommandFilter.LIGHT_MODE_ACTIVE:
        return themeName === themesConfig.StationaryLight.name
      case CommandFilter.DARK_MODE_ACTIVE:
        return themeName === themesConfig.StationaryDark.name
      case CommandFilter.SHOWING_COMPLETED:
        return !!showCompleted
      case CommandFilter.NOT_SHOWING_COMPLETED:
        return !showCompleted
      case CommandFilter.SIDEBAR_COLLAPSED:
        return !!isLeftHidden
      case CommandFilter.SIDEBAR_EXPANDED:
        return !isLeftHidden
      case CommandFilter.SHOWING_EMPTY_DATES:
        return !!showNextFourteenDays
      case CommandFilter.NOT_SHOWING_EMPTY_DATES:
        return !showNextFourteenDays
      default:
        return false
    }
  })
}
