import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { DragSourceType } from '../../../../common/dnd/drag/types'
import {
  DropTargetType,
  OutlineRecordDropProps,
} from '../../../../common/dnd/drop/types'
import { useRegisterDropTarget } from '../../../../common/dnd/drop/useRegisterDropTarget'
import { focusAction, subscribeToFocusEvent } from '../../../../common/events'
import { useFocus } from '../../../../common/hooks/useFocus'
import { titleOrDefault } from '../../../../common/records'
import { isRouteType, isSystemListRoute } from '../../../../common/routes'
import { Count, cx, styled, useTheme } from '../../../../common/stationary'
import { useCollectionHandlers } from '../../../../screens/hooks/useCollectionHandlers'
import { RouteIcon } from '../../../RouteIcon'
import { EmojiPickerPopover } from '../EmojiPickerPopover'
import OutlineListItemActions, {
  OutlineListItemAction,
} from './OutlineListItemActions'
import { ModalName } from '../../../../common/modals/core/modalConfig'
import { useModal } from '../../../../common/modals/core/hooks/useModal'
import { ClickEvent } from '../../../../common/recordItemEventHandlers/types'

export interface ListItem {
  id: string
  title: string
  emoji?: string
  count?: number
}

export interface OutlineListItemProps extends ListItem {
  isActive?: boolean
}

const OutlineListItem: React.FC<OutlineListItemProps> = ({
  id,
  title,
  emoji,
  count,
  isActive,
}) => {
  const [hover, setHover] = useState(false)
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const { openModal } = useModal()

  const setIsActionMenuOpenFalse = useCallback(
    () => setIsActionMenuOpen(false),
    [],
  )

  const {
    htmlRef: textRef,
    focus: focusText,
    blur: blurText,
  } = useFocus<HTMLInputElement>()

  const {
    editing,
    setEditing,
    inputValue,
    onItemClick,
    removeItem,
    onChange,
    onBlur,
    onKeyDown,
    openEmojiPicker,
    closeEmojiPicker,
    isEmojiPickerOpen,
    onSelectEmoji,
  } = useCollectionHandlers(title, id, blurText, false)

  const activateHover = useCallback(() => setHover(true), [])
  const deactivateHover = useCallback(() => setHover(false), [])
  const stopPropagationClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      event.stopPropagation(),
    [],
  )

  const onEditClick = useCallback(
    (event: ClickEvent) => {
      event.preventDefault()
      event.stopPropagation()
      setEditing(true)
      focusAction({ id })
      setIsActionMenuOpenFalse()
    },
    [setEditing],
  )

  const onDeleteClick = useCallback(() => {
    setIsActionMenuOpen(false)

    openModal(ModalName.CONFIRM_DELETE_LIST_MODAL, {
      listId: id,
    })

    deactivateHover()
  }, [removeItem, deactivateHover])

  useEffect(() => {
    return subscribeToFocusEvent(({ payload }) => {
      if (payload.id === id) {
        if (editing && textRef.current) {
          focusText()
          return true
        } else {
          setEditing(true)
        }
      }
      return false
    })
  }, [textRef, id, editing, focusText, setEditing])

  const systemList = isRouteType(id) && isSystemListRoute(id)
  const isTitleEmpty = isEmpty(title)

  const theme = useTheme()

  const actions: OutlineListItemAction[] = useMemo(
    () => [
      {
        iconVariant: `glyphCaret`,
        onClick: onEditClick,
        title: `Rename`,
      },
      {
        iconVariant: `glyphStar`,
        onClick: () => {
          openEmojiPicker()
          setIsActionMenuOpenFalse()
        },
        title: `Change emoji`,
      },
      {
        color: theme.colors.red[700],
        iconVariant: `glyphTrash`,
        onClick: onDeleteClick,
        title: `Delete`,
      },
    ],
    [onEditClick, openEmojiPicker, onDeleteClick, setIsActionMenuOpenFalse],
  )

  const [dropRef, { isValidAndHovered }] =
    useRegisterDropTarget<OutlineRecordDropProps>(
      DropTargetType.OUTLINE_RECORD,
      [DragSourceType.RECORD],
      {
        id,
      },
    )

  const onEmojiPickerClick: React.MouseEventHandler = (e) => {
    e.stopPropagation() // Don't propagate event up to OutlineListItem
    if (isEmojiPickerOpen) closeEmojiPicker()
    else openEmojiPicker()
  }

  return (
    <>
      <S.OutlineListItem
        className={cx({
          isActionMenuOpen,
          isActive,
          isEditing: editing,
          isSystemList: systemList,
          isTitleEmpty,
          isValidAndHovered,
        })}
        key={`${id}-Item`}
        onMouseEnter={!('ontouchstart' in window) ? activateHover : undefined}
        onMouseLeave={!('ontouchstart' in window) ? deactivateHover : undefined}
        onClick={onItemClick}
        ref={dropRef}
      >
        {systemList ? (
          <S.Icon boxSize={20} routeType={id} />
        ) : (
          <>
            <S.EmojiPicker>
              <S.EmojiPickerButton
                emoji={emoji}
                onClick={onEmojiPickerClick}
                close={closeEmojiPicker}
                onSelect={onSelectEmoji}
                visible={isEmojiPickerOpen}
              />
            </S.EmojiPicker>
          </>
        )}
        <S.OutlineListItemText>
          {editing ? (
            <S.TextEditable
              className="text"
              data-placeholder="Untitled"
              value={inputValue}
              onClick={stopPropagationClick}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              ref={textRef}
            />
          ) : (
            titleOrDefault(inputValue)
          )}
        </S.OutlineListItemText>
        {!editing && (hover || isActionMenuOpen) ? (
          <S.Actions
            actions={actions}
            isMenuVisible={isActionMenuOpen}
            setIsMenuVisible={setIsActionMenuOpen}
          />
        ) : null}
        {!editing && !(hover || isActionMenuOpen) && count !== undefined ? (
          <S.Count showZero value={count} />
        ) : null}
      </S.OutlineListItem>
    </>
  )
}

export default OutlineListItem

export const OutlineListItemDimensions = {
  height: 36,
}
const S = {
  OutlineListItem: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    borderRadius: 8,
    height: OutlineListItemDimensions.height,
    transition: 'background 0.2s ease',

    '&.isTitleEmpty:not(.isEditing)': {
      color: theme.colors.text[500],
    },
    '&:hover': {
      background: theme.colors.alpha.tone.extraWeak,
    },
    '&.isActive': {
      fontWeight: 600,
      background: theme.colors.alpha.tone.weak,
    },
    '&.isValidAndHovered': {
      background: theme.colors.alpha.selection.medium,
    },
  })),
  Icon: styled(RouteIcon)({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginRight: 10,
    borderRadius: 8,
  }),
  EmojiPicker: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: `center`,
    justifyContent: `center`,
    width: 40,
  })),
  EmojiPickerButton: styled(EmojiPickerPopover)({}),
  TextEditable: styled.input(({ theme }) => ({
    position: 'relative',
    width: `100%`,
    outline: `none`,
    height: `24px`,
    cursor: 'text !important',

    '&[contenteditable=true]:empty:before': {
      content: `attr(data-placeholder)`,
      display: `block`,
      color: theme.colors.text[900],
    },
  })),
  OutlineListItemText: styled.div({
    flex: `1`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    overflowX: `hidden`,
  }),
  Actions: styled(OutlineListItemActions)({
    position: 'relative',
  }),
  Count: styled(Count)(({ theme }) => ({
    marginRight: 10,
    borderRadius: 4,
    color: theme.colors.text[500],
    background: theme.colors.alpha.tone.extraWeak,
  })),
}
