import { SortDirection } from '@eleventhlabs/capture-shared'

import * as Analytics from '../analytics/capture-analytics-actions'
import { ExecAction } from '../useExec'
import { CommandId } from './CommandId'

export const executeCommandFromCommandBar = (
  exec: ExecAction,
  commandId: CommandId,
  trigger: `click` | `keyboard`,
) => {
  const triggeredByKeyboard = trigger === `keyboard`
  const triggeredByUI = trigger === `click`
  switch (commandId) {
    case CommandId.OPEN_SCHEDULE_DIALOG: {
      return exec({
        type: `OPEN_SCHEDULE_DIALOG`,
        trigger: Analytics.DatePickerFlowStartedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.OPEN_MOVE_TO_DIALOG: {
      return exec({
        type: commandId,
        trigger: Analytics.MoveToPickerFlowStartedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.RECORDS_MARK_DONE: {
      return exec({
        type: 'RECORDS_MARK_DONE',
        trigger: Analytics.ToggleRecordsCompletedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.RECORDS_MARK_NOT_DONE: {
      return exec({
        type: 'RECORDS_MARK_NOT_DONE',
        trigger: Analytics.ToggleRecordsCompletedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.TOGGLE_SHOW_COMPLETED: {
      return exec({
        type: commandId,
        trigger: Analytics.ShowCompletedToggleTrigger.COMMAND_BAR,
      })
    }
    case CommandId.RECORD_ITEMS_SHOW_NOTES: {
      return exec({
        type: 'RECORD_ITEMS_SHOW_NOTES',
        triggerShowAnnotation:
          Analytics.ShowAnnotationsToggleTrigger.COMMAND_BAR,
        triggerCreatedAnnotation:
          Analytics.AnnotationCreatedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.RECORD_ITEMS_HIDE_NOTES: {
      return exec({
        type: 'RECORD_ITEMS_HIDE_NOTES',
        trigger: Analytics.ShowAnnotationsToggleTrigger.COMMAND_BAR,
      })
    }
    case CommandId.EXPAND_TO_FULLSCREEN: {
      return exec({
        type: commandId,
        trigger: `Command Bar`,
        triggeredByKeyboard,
        triggeredByUI,
      })
    }
    case CommandId.EXIT_FULLSCREEN: {
      return exec({ type: commandId })
    }
    case CommandId.RECORDS_COPY: {
      return exec({ type: 'COPY' })
    }
    case CommandId.RECORDS_REMOVE_DATE: {
      return exec({ type: 'RECORDS_REMOVE_TICKLER' })
    }
    case CommandId.RECORD_ADD_NOTE: {
      return exec({
        type: 'ADD_ANNOTATION',
        showAnnotationsTrigger:
          Analytics.ShowAnnotationsToggleTrigger.COMMAND_BAR,
        createAnnotationTrigger: Analytics.AnnotationCreatedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.RECORDS_REMOVE_NOTE: {
      return exec({
        type: 'RECORDS_DELETE_NOTES',
        trigger: Analytics.AnnotationDeletedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.FOCUS_FILE_TITLE_INPUT: {
      return exec({ type: commandId })
    }
    case CommandId.CREATE_COLLECTION: {
      return exec({
        type: commandId,
        trigger: Analytics.ListCreatedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.RECORDS_SELECT_ALL: {
      return exec({
        type: 'SELECT_ALL_RECORDS',
        trigger: Analytics.RecordSelectedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.RECORD_CONVERT_TO_URL: {
      return exec({ type: 'CONVERT_RECORD_TO_URL' })
    }
    case CommandId.RECORD_CONVERT_TO_TEXT: {
      return exec({ type: 'CONVERT_RECORD_TO_TEXT' })
    }
    case CommandId.COLLECTION_RENAME: {
      return exec({ type: 'RENAME_COLLECTION' })
    }
    case CommandId.COLLECTION_SET_EMOJI: {
      return exec({ type: 'SET_COLLECTION_EMOJI' })
    }
    case CommandId.OPEN_CALENDAR_DIALOG: {
      return exec({ type: `OPEN_CALENDAR_DIALOG` })
    }
    case CommandId.TOGGLE_SIDEBAR: {
      return exec({
        type: commandId,
        trigger: Analytics.ShowSidebarToggleTrigger.COMMAND_BAR,
      })
    }
    case CommandId.GO_TO_SCREEN: {
      return exec({
        type: `OPEN_GO_TO_DIALOG`,
        trigger: Analytics.QuickSwitcherFlowStartedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.GO_TO_INBOX: {
      return exec({
        type: commandId,
        trigger: Analytics.ScreenNavigationStartedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.GO_TO_TODAY: {
      return exec({
        type: commandId,
        trigger: Analytics.ScreenNavigationStartedTrigger.COMMAND_BAR,
      })
    }
    case CommandId.GO_TO_TRASH: {
      return exec({
        type: commandId,
        trigger: Analytics.ScreenNavigationStartedTrigger.USER_ACTIONS_CLICK,
      })
    }
    case CommandId.TOGGLE_KEYBOARD_SHORTCUTS_PANE: {
      return exec({ type: 'TOGGLE_KEYBOARD_SHORTCUTS_PANE' })
    }
    case CommandId.LOGOUT: {
      return exec({ type: commandId, logoutTrigger: 'Command Bar' })
    }
    case CommandId.VIEW_JWT: {
      return exec({ type: commandId })
    }
    case CommandId.RECORDS_SORT_BY_DATE: {
      return exec({ type: 'SORT_BY_DATE', direction: SortDirection.ASC })
    }
    case CommandId.RECORDS_SORT_BY_LOCATION: {
      return exec({ type: 'SORT_BY_LOCATION', direction: SortDirection.ASC })
    }
    case CommandId.THEME_SWITCH_TO_LIGHT: {
      return exec({
        type: 'SWITCH_TO_LIGHT_THEME',
        trigger: Analytics.ThemeNameToggleTrigger.COMMAND_BAR,
      })
    }
    case CommandId.THEME_SWITCH_TO_DARK: {
      return exec({
        type: 'SWITCH_TO_DARK_THEME',
        trigger: Analytics.ThemeNameToggleTrigger.COMMAND_BAR,
      })
    }
    case CommandId.THEME_SWITCH_TO_SYSTEM: {
      return exec({
        type: 'SWITCH_TO_SYSTEM_THEME',
        trigger: Analytics.ThemeNameToggleTrigger.COMMAND_BAR,
      })
    }
    case CommandId.CREATE_RECORD: {
      return exec({
        trigger: Analytics.RecordCreatedTrigger.COMMAND_BAR,
        type: 'CREATE_RECORD_INSERT_BELOW',
      })
    }
    case CommandId.RECORD_ITEMS_COLLAPSE: {
      return exec({
        trigger: Analytics.RecordCollapsedTrigger.COMMAND_BAR,
        type: 'RECORD_ITEMS_COLLAPSE',
      })
    }
    case CommandId.RECORD_ITEMS_EXPAND: {
      return exec({
        trigger: Analytics.RecordExpandedTrigger.COMMAND_BAR,
        type: 'RECORD_ITEMS_EXPAND',
      })
    }
    case CommandId.RECORDS_MOVE_TO_TRASH: {
      return exec({
        type: 'MOVE_RECORDS_TO_TRASH',
        trigger: 'Command Bar',
        triggeredByKeyboard: true,
        triggeredByUI: true,
      })
    }
    case CommandId.RECORDS_RESTORE: {
      return exec({
        type: 'RESTORE_RECORDS',
        trigger: Analytics.RecordsRestoredTrigger.COMMAND_BAR,
        triggeredByKeyboard: true,
        triggeredByUI: false,
      })
    }
    default:
      throw new Error(`Not recognized command: ${commandId}`)
  }
}
