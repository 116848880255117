import {
  DataRecord,
  RichText,
  TextRecord,
  isItemRecord,
  isTextRecord,
} from '@eleventhlabs/capture-shared'
import { useCallback, useMemo } from 'react'
import { useExecContext } from '../../../common/contexts/ExecContext'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { ModelStore } from '../../../common/data/modelStore'
import Inbox from '../../../common/inbox'

export const useOnboardingInbox = () => {
  const { store } = useModelStoreContext()
  const { exec } = useExecContext()

  const onboardingInboxRecords = useMemo(
    () => getInboxDemoRecords(store),
    [store],
  )

  const addTextToInbox = useCallback((text: string) => {
    return exec({ type: 'ONBOARDING_SUBMIT_QUICK_CAPTURE', text })
  }, [])

  return { onboardingInboxRecords, addTextToInbox }
}

export type OnboardingDemoRecord = {
  id: string
  value: string
}

function getInboxDemoRecords(store: ModelStore): OnboardingDemoRecord[] {
  const inboxRecord = Inbox.getRecord(store.records)
  const inboxChildrenRecords = Inbox.getChildrenRecords(
    inboxRecord,
    store.records,
  )
  return inboxChildrenRecords
    .filter(isTextRecord)
    .filter(isNotTemplateRecord)
    .map(toDemoRecord)
}

function isNotTemplateRecord(r: DataRecord) {
  return isItemRecord(r) && r.capturedFromSource !== undefined
}

const toDemoRecord = (r: TextRecord) => ({
  id: r.id,
  value: RichText.getRawText(r.richText.value),
})
