import { EditorsManager } from '../../../../EditorsManager'

export const noteFocused = (
  noteId: string,
  clearSelection: () => void,
  setFocusedRecordId: (recordId?: string | undefined) => void,
) => {
  setFocusedRecordId(noteId)
  EditorsManager.analytics.register(noteId)

  clearSelection()
}
