import { ReadonlyJSONObject, Replicache } from 'replicache'

import { RecordDict } from '@eleventhlabs/capture-shared'
import { getAllRecords } from './query'
import { useSubscribeToSharedStructureDict } from './useSubscribe'

export const useRecords = (
  rep: Replicache | null,
  def: RecordDict,
): RecordDict => {
  return useSubscribeToSharedStructureDict(
    rep,
    getAllRecords,
    def as ReadonlyJSONObject,
  ) as RecordDict
}
