import { DeleteableRecord } from '@eleventhlabs/capture-shared'

// emptyTrash
export const emptyTrash = (
  softDeletedRecords: DeleteableRecord[],
  deleteRecords: (records: string[]) => void,
) => {
  // TODO: We could delete more records if there is a delete cascade
  const length = softDeletedRecords.length
  deleteRecords(softDeletedRecords.map((r) => r.id))
  return length
}
