import { RecordType } from '@eleventhlabs/capture-shared'
import { RecordGroupType, RecordItemData } from '../../data/recordGroups'

export const SUPPORTED_DRAG_RECORD_TYPES: RecordType[] = [
  RecordType.Text,
  RecordType.URL,
  RecordType.File,
]

export enum DragSourceType {
  RECORD = 'record',
}

// The information DnD needs from our app to build the handlers
export type DragPayload = DragRecordItemPayload

interface DragRecordItemPayload {
  index: number
  record: RecordItemData
  showToggle: boolean
  groupType: RecordGroupType
}

// The props that the DnD framework returns us for us to use
export type DragProps = RecordItemDragProps

export interface RecordItemDragProps {
  isDragging: boolean
  isInDragGroup: boolean
}

// An object describing the current item being dragged
export type DragItemData = RecordItemDragData

export interface RecordItemDragData {
  index: number
  record: RecordItemData
  type: string
  showToggle: boolean
  groupType: RecordGroupType
}
