import React, { useCallback } from 'react'

import { useUIContext } from '../../common/contexts/UIContext'
import { RecordGroupType } from '../../common/data/recordGroups'
import { useScrollWindowAtEdgesOnDrag } from '../../common/hooks/listeners/useScrollWindowAtEdgesOnDrag'
import { ClickOutsideListener } from '../../components/helpers/ClickOutsideListener'
import { RecordGroup } from '../../components/RecordGroup/RecordGroup'
import { withDragLayer } from '../enhancers/withDragLayer'
import { withRecordGroupHotKeys } from '../enhancers/withRecordGroupHotKeys'
import { withRecordGroupScreen } from '../enhancers/withRecordGroupScreen'
import { CommonRecordGroupScreenProps } from '../types'
import ListHeader from './components/ListHeader'
import { withFloatingActions } from '../enhancers/withFloatingActions'
import useScreenLoadedAnalytics from '../../common/analytics/helpers/hooks/useScreenLoadedAnalytics'
import Analytics from '../../common/analytics/capture-analytics-actions'
import {
  screenInnerStyles,
  screenStyles,
  styled,
} from '../../common/stationary'
import { isMobile } from '../../common/utils/env'
import Header from '../../components/Header/Header'
import {
  EmptyStateText,
  RecordGroupEmptyState,
} from '../../components/RecordGroupEmptyState'
import { useDragToSelectRecords } from '../../common/dragToSelect/useDragToSelect'
import { FileDropOverlay } from '../../components/FileDropOverlay'

export interface ListProps {
  listId: string
  title?: string
  emoji?: string
}

export type ListScreenProps = ListProps & CommonRecordGroupScreenProps

const ListScreen = ({
  createFileRecordAtStartOfGroup,
  className,
  emoji,
  focusedId,
  groups,
  listId,
  miniBarHandlers,
  noteHandlers,
  onClickOutside,
  recordHandlers,
  routeType,
  selectedIds,
  shouldDisplayNotesForRecordId,
  createRecordAtStartOfGroup,
  title = ``,
  uploadProgress,
  onTouchMove,
  onTouchStart,
  isDragActive,
}: ListScreenProps) => {
  const { isMouseRecentlyActive } = useUIContext()

  const dragBoundaryRef = useScrollWindowAtEdgesOnDrag<HTMLDivElement>()
  useDragToSelectRecords(dragBoundaryRef)

  useScreenLoadedAnalytics(Analytics.ActiveScreen.USER_LIST, listId)

  return (
    <S.ListScreen
      ref={dragBoundaryRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
    >
      {isDragActive && <FileDropOverlay />}
      <Header />
      <ClickOutsideListener onClickOutside={onClickOutside}>
        <S.ListScreenInner className={className}>
          <ListHeader id={listId} title={title} emoji={emoji} />
          {groups.map((group) => (
            <RecordGroup
              createFileRecordAtStartOfGroup={createFileRecordAtStartOfGroup}
              createRecordAtStartOfGroup={createRecordAtStartOfGroup}
              emptyStateRenderer={() => (
                <RecordGroupEmptyState>
                  <EmptyStateText>
                    Nothing in <strong>{title}</strong>
                  </EmptyStateText>
                </RecordGroupEmptyState>
              )}
              focusedId={focusedId}
              isMouseActive={isMouseRecentlyActive}
              key={`${group.type}-${group.id}`}
              miniBarHandlers={miniBarHandlers}
              noteHandlers={noteHandlers}
              recordHandlers={recordHandlers}
              routeType={routeType}
              selectedIds={selectedIds}
              shouldDisplayNotesForRecordId={shouldDisplayNotesForRecordId}
              showCaptureButtons={
                !isMobile && group.type !== RecordGroupType.ListComplete
              }
              softHeader={group.type === RecordGroupType.ListComplete}
              uploadProgress={uploadProgress}
              {...group}
            />
          ))}
        </S.ListScreenInner>
      </ClickOutsideListener>
    </S.ListScreen>
  )
}

export default withFloatingActions(
  withDragLayer(withRecordGroupScreen(withRecordGroupHotKeys(ListScreen))),
)

const S = {
  ListScreen: styled.div({
    ...screenStyles,
  }),
  ListScreenInner: styled.div({
    ...screenInnerStyles,
  }),
}
