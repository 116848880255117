import React from 'react'

import { styled } from '../../../../../common/stationary'

interface URLFaviconProps {
  favicon?: string
  loading?: boolean
}

export const URLFavicon: React.FC<URLFaviconProps> = ({ favicon, loading }) => {
  return favicon ? (
    <S.URLFavicon>
      <S.Image src={favicon} />
    </S.URLFavicon>
  ) : loading ? (
    <S.Empty />
  ) : (
    <S.URLFavicon>
      <DefaultFavicon />
    </S.URLFavicon>
  )
}

const DefaultFavicon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="3" fill="#30343A" fillOpacity="0.04" />
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="2.5"
      stroke="black"
      strokeOpacity="0.04"
    />
    <path
      opacity="0.1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8C11.1046 8 12 7.10458 12 6C12 4.89542 11.1046 4 10 4C8.89543 4 8 4.89542 8 6C8 7.10458 8.89543 8 10 8ZM0 13L4.93934 8.06066C5.52513 7.47487 6.47487 7.47487 7.06066 8.06066L9 10C9.55228 10.5523 10.4477 10.5523 11 10C11.5523 9.44772 12.4477 9.44771 13 10L16 13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13Z"
      fill="#30343A"
    />
  </svg>
)

const S = {
  URLFavicon: styled.div({
    display: `flex`,
    flex: `0 0 16px`,
    height: 16,
    marginRight: 6,
    alignItems: `center`,
    justifyContent: `center`,
  }),
  Image: styled.img({
    display: `flex`,
    maxWidth: `100%`,
    maxHeight: `100%`,
  }),
  Empty: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 16px`,
    height: 16,
    marginRight: 6,
    background: theme.colors.surface.lowest,
  })),
}
