import { styled } from '../common/stationary/styled'

export const RecordGroupEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 100%;
  padding: 0 20px;
  border-radius: 12px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 8px,
    ${({ theme }) => theme.colors.alpha.tone.weak} 8px,
    ${({ theme }) => theme.colors.alpha.tone.weak} 16px
  );
  color: ${({ theme }) => theme.colors.text[600]};
  ${({ theme }) => theme.text.publicSans['16:24']};
`

export const EmptyStateText = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  strong {
    font-weight: 700;
  }
`
