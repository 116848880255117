import { has, some } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { UserDataOnboarding } from '../../../common/data/modelStore/useUser'
import { useReplicacheContext } from '../../../common/replicache/ReplicacheContext'
import {
  SlideType,
  getShouldSkipIfCompletedState,
  getShouldSkipSlide,
  getSlideByName,
} from '../components/slides'
import {
  getIsCheckpoint,
  getSlideType,
  getTrackItemIndex,
  track,
  trackType,
} from '../components/tracks'
import { useCompleteOnboardingSlides } from './useCompleteOnboardingSlides'
import { useOnboardingAnalytics } from './useOnboardingAnalytics'

export const useOnboardingSlideIndex = () => {
  const onboardingAnalytics = useOnboardingAnalytics(trackType)
  const { uncompleteSlides } = useCompleteOnboardingSlides()

  const { isReady } = useReplicacheContext()

  const {
    store: { user },
  } = useModelStoreContext()

  const userOnboardingData = user.data.onboarding

  const [index, setIndex] = useState<number>(0)

  const prevIsReady = usePrevious(isReady, false)
  useEffect(() => {
    const firstUncompletedSlideIndex =
      getFirstUncompletedSlideIndex(
        userOnboardingData,
        uncompleteSlides,
        !prevIsReady && isReady, // If this is replicache's first load, reset any uncompleted groups
      ) ?? 0

    onboardingAnalytics.loaded(getSlideType(track[firstUncompletedSlideIndex]))

    // If the first uncompleted slide is greater than where we're at now, advance to it
    if (firstUncompletedSlideIndex > index) setIndex(firstUncompletedSlideIndex)
  }, [userOnboardingData, uncompleteSlides, index, isReady])

  // Will return the next unseen slide index, or -1 if all slides have been seen
  const shouldShowSlide = useCallback(
    (slideName: SlideType) => {
      return shouldShowSlideInTrack(slideName, userOnboardingData)
    },
    [userOnboardingData],
  )

  return {
    index,
    setIndex,
    shouldShowSlide,
  }
}

export const usePrevious = <T>(value: T, initialValue: T) => {
  const ref = useRef<T>(initialValue)
  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

const shouldShowSlideInTrack = (
  slideName: SlideType,
  userOnboardingData: UserDataOnboarding,
) => {
  if (getShouldSkipSlide(slideName)) return true

  const slide = getSlideByName(slideName)

  const shouldSkipSlideIfCompletedState =
    getShouldSkipIfCompletedState(slideName)

  switch (shouldSkipSlideIfCompletedState) {
    case 'AnyTrack':
      return !hasSlideBeenSeenOnAnyTrack(slideName, userOnboardingData)
    case 'CurrentTrack':
      return !has(
        userOnboardingData.track[trackType].completedSlides,
        slideName,
      )
    case 'Never':
      return true
  }
}

const getFirstUncompletedSlideIndex = (
  userOnboardingData: UserDataOnboarding,
  unCompleteSlides: (slides: SlideType[]) => void,
  resetGroup: boolean,
): number | null => {
  let checkpointGroup: SlideType[] = []
  let prevCheckpointIndex = null

  for (let i = 0; i < track.length; i++) {
    const trackItem = track[i]

    checkpointGroup.push(getSlideType(trackItem))

    // If we get to a slide that we should show:
    // - if it's not a checkpoint, then reset the group, along with the previous checkpoint slide, return index of previuos checkpoint slide, if exists
    // - if it is a checkpoint, then return it
    if (shouldShowSlideInTrack(getSlideType(trackItem), userOnboardingData)) {
      if (!getIsCheckpoint(trackItem)) {
        if (resetGroup) {
          // Reset savepoints for all slides in the group, including the previous checkpoint, so the user
          // goes back to that slide and has to recomplete it
          let group = checkpointGroup
          if (prevCheckpointIndex !== null) {
            group = [getSlideType(track[prevCheckpointIndex]), ...group]
          }
          unCompleteSlides(group)
        }

        // Return the previous checkpoint index if it exists
        const trackItemIndex =
          prevCheckpointIndex !== null
            ? prevCheckpointIndex
            : getTrackItemIndex(trackType, checkpointGroup[0])

        return trackItemIndex
      } else {
        return i
      }
    } else if (getIsCheckpoint(trackItem)) {
      // If we shouldn't show the slide and it's a checkpoint, set it as the previous checkpoint
      // Reset the previous checkpoint group
      prevCheckpointIndex = i
      checkpointGroup = []
    }
  }

  return null
}

const hasSlideBeenSeenOnAnyTrack = (
  slideName: SlideType,
  userOnboardingData: UserDataOnboarding,
): boolean => {
  return some(userOnboardingData.track, (trackData, trackType) =>
    has(trackData.completedSlides, slideName),
  )
}
