/* eslint-disable @typescript-eslint/return-await */
import { navigate } from 'gatsby'

import { NavigateOptions } from '@gatsbyjs/reach-router'

import { Env } from './env'

export const safeNavigate = async (
  to: string,
  options?: NavigateOptions<Record<string, unknown>>,
): Promise<void> => {
  if (Env.isSSR) return Promise.resolve()
  else return navigate(to, options)
}
