import { ExecAction } from '../../useExec'
import Analytics from '../../analytics/capture-analytics-actions'
import { EditorsManager } from '../../EditorsManager'

export const onKeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  exec: ExecAction,
  noteId: string,
  recordId: string,
) => {
  if (e.key === `Escape`) {
    return onKeyDownEscape(e, exec, noteId, recordId)
  }
  if (e.key === `/`) {
    return onKeyDownSlash(e, exec)
  }
  if (e.key === `k`) {
    return onKeyDownK(e, exec, noteId, recordId)
  }
  return false
}

const onKeyDownEscape = (
  e: React.KeyboardEvent<HTMLDivElement>,
  exec: ExecAction,
  noteId: string,
  parentId: string,
) => {
  EditorsManager.effects.blur(noteId)
  exec({ type: 'NOTE_BLURRED', noteId, parentId })
  exec({ type: 'NOTE_ESCAPED', recordId: parentId })
  return true
}

const onKeyDownSlash = (
  e: React.KeyboardEvent<HTMLDivElement>,
  exec: ExecAction,
) => {
  if (e.metaKey) {
    e.preventDefault()
    exec({
      type: 'TOGGLE_SIDEBAR',
      trigger: Analytics.ShowSidebarToggleTrigger.KEYBOARD_COMMAND_BACKSLASH,
    })
    return true
  }
  return false
}

const onKeyDownK = (
  e: React.KeyboardEvent<HTMLDivElement>,
  exec: ExecAction,
  noteId: string,
  parentId: string,
) => {
  if (e.metaKey) {
    e.preventDefault()
    exec({ type: 'NOTE_BLURRED', noteId, parentId })
    exec({
      type: 'OPEN_COMMAND_BAR',
      trigger: Analytics.CommandBarFlowStartedTrigger.KEYBOARD_CMDK,
    })
    return true
  }
  return false
}
