import { NavigateFn } from '@gatsbyjs/reach-router'

import {
  PlaceTag,
  isInboxTag,
  isListTag,
  isRootTag,
} from '../../../../data/tags'
import { RouteType, getUrl } from '../../../../routes'

export const placeTagClick = (
  tag: PlaceTag,
  navigate: NavigateFn,
  navigateToList: (listId: string) => void,
) => {
  if (isInboxTag(tag)) navigate(getUrl({ type: RouteType.Inbox }))
  else if (isRootTag(tag)) return
  else if (isListTag(tag)) navigateToList(tag.parentId)
  else throw new Error(`Unexpected place type`)
}
