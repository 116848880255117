import hotkeys, { HotkeysEvent } from 'hotkeys-js'
import { useEffect, useRef } from 'react'
import { isMacLike } from '../../../common/utils/env'

export const OnboardingKeys = {
  GoForward: ['Enter'],
  ShowQuickCapture: isMacLike ? ['Alt', ' '] : ['Ctrl', 'Alt', ' '],
  SubmitQuickCapture: ['Meta', 'Enter'],
}

export type HotkeyConfig = {
  key: string
  handler: (event: KeyboardEvent, hotkeyEvent: HotkeysEvent) => void
  listenToKeyUp?: boolean
  listenToKeyDown?: boolean
}

/**
 * This function sets up hotkeys for the onboarding process. It takes in a configuration for the hotkeys and a scope.
 * The hotkeys configuration is an array of objects, each containing a key (the keyboard key to listen for), a handler
 * (the function to execute when the key is pressed), and optional settings for the scope, keyup, and keydown events.
 * The scope is a string that defines the context in which the hotkeys should work.
 *
 * @todo Generalize this function to be used across the entire application, not just for onboarding.
 *
 * @param {HotkeyConfig[]} hotkeysConfig - The configuration for the hotkeys.
 * @param {string} scope - The scope in which the hotkeys should work. This can be a screen, a component, or any contained part of our app.
 */

export const useOnboardingHotkeys = (
  hotkeysConfig: HotkeyConfig[],
  scope: string = 'onboarding',
) => {
  useEffect(() => {
    hotkeysConfig.forEach(
      ({ key, handler, listenToKeyDown, listenToKeyUp }) => {
        hotkeys(
          key,
          { scope, keyup: listenToKeyUp, keydown: listenToKeyDown },
          handler,
        )
      },
    )

    hotkeys.setScope(scope)

    return () => {
      hotkeys.deleteScope(scope)
    }
  }, [hotkeysConfig, scope])
}

/**
 * It is necessary to sanitize e.key to account for &nbsp
 * @todo Should either user hotkeys-js or switch to e.code to avoid this
 */
export const sanitizeKey = (key: string) => (key.trim() !== '' ? key : ' ')

/**
 * Gets the prettified string version of a key
 * @todo Automatically determine the prettified string based on platform
 */
export const prettifyKey = (key: string) => {
  switch (key) {
    case 'Alt':
      return isMacLike ? '⌥ Option' : 'Alt'
    case 'Meta':
      return isMacLike ? '⌘' : 'Ctrl'
    case 'Enter':
      return 'Enter ↵'
    case ' ':
      return 'Space'
    default:
      return key
  }
}

/**
 * Prints keys with a custom delimiter
 */
export const printKeys = (keys: string[]) => keys.join(' + ')

/**
 * Checks if keysA is a subset of keysB
 */
const isSubset = (keysA: string[], keysB: string[]) =>
  keysA.reduce((acc, keyA) => {
    let foundInB = false
    for (const keyB of keysB) {
      if (keyA === keyB) foundInB = true
    }

    return acc && foundInB
  }, true)
