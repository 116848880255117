import React from 'react'

import { SlideProps } from '.'
import diagramBrowserAppExampleSrc from '../../../../assets/onboarding/diagram-browser-app-example@2x.png'
import { Key, useKeys } from '../../../../common/hooks/useKeys'
import { styled } from '../../../../common/stationary'
import { isMobile } from '../../../../common/utils/env'
import {
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/SplitCardSlide'

export const InstallBrowserApp: React.FC<SlideProps> = ({ goForward }) => {
  useKeys([Key.Enter], () => goForward())

  return (
    <S.InstallBrowserApp>
      <TransitionSlideContainer>
        <SlideIntro>
          <SlideTitle>Capture on your computer</SlideTitle>
          <SlideDescription>
            Visit <strong>capture.so</strong> on your computer to access Capture
            from your favorite browser, set up the desktop app, & more.
          </SlideDescription>
          <SlidePrimaryButton onClick={() => goForward()}>
            <strong>Continue</strong>
          </SlidePrimaryButton>
        </SlideIntro>
        <SlideDiagrams>
          <img
            src={diagramBrowserAppExampleSrc}
            srcSet={`${diagramBrowserAppExampleSrc} 2x`}
          />
        </SlideDiagrams>
      </TransitionSlideContainer>
    </S.InstallBrowserApp>
  )
}

const S = {
  InstallBrowserApp: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',

    ...(isMobile && {
      padding: '0 8px',
    }),
  })),
}
