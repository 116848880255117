import React, { useCallback } from 'react'

import { Button, cx, styled } from '../../../common/stationary'
import { HandleResultPayload, ResultGroupItem } from '../QuickSwitcher.types'

export interface ResultItemProps {
  item: ResultGroupItem
  isActive: boolean
  className?: string
  onHover?: (id: string) => void
  handleResultClick: (payload: HandleResultPayload) => void
}

const ResultItem = ({
  item,
  isActive,
  className,
  onHover,
  handleResultClick,
}: ResultItemProps) => {
  const onClick = useCallback(() => {
    handleResultClick({ id: item.id, type: item.type })
  }, [item, handleResultClick])

  const onMouseEnter = useCallback(() => {
    onHover && onHover(item.id)
  }, [item, onHover])

  return (
    <S.Row
      className={cx(className, { isActive })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <S.MoveItemButton>
        {item.renderPre}
        {item.renderContent}
        {item.renderPost}
      </S.MoveItemButton>
    </S.Row>
  )
}

export default ResultItem

export const ResultItemDimensions = {
  height: 48,
}

const S = {
  Row: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    boxSizing: `border-box`,
    height: 40,
    margin: `0 8px`,
    cursor: 'pointer',

    '&.isActive': {
      background: theme.colors.alpha.tone.weak,
      borderRadius: 8,
    },
  })),
  MoveItemButton: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    width: `100%`,
    padding: `0 16px`,
    textAlign: `left`,
  }),
}
