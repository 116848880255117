import React, { useCallback, useEffect, useRef } from 'react'

import { EditorsManager } from '../../../common/EditorsManager'
import { RichText } from '@eleventhlabs/capture-shared'
import { RichTextSyncController } from './RichTextSyncController'

type SyncableComponentProps = {
  id: string
  richText: RichText
  onBlur?: (e: React.FocusEvent<HTMLDivElement, Element>) => void
  onChangeDebounced?: (value: RichText) => void
  onFocus?: (e: React.FocusEvent<HTMLDivElement, Element>) => void
}

type SyncedComponentProps = SyncableComponentProps

export const withSync = <T extends SyncableComponentProps>(
  Component: React.FC<T>,
): React.FC<T & SyncedComponentProps> => {
  const WrappedComponent = ({
    id,
    richText: upstreamRichText,
    onBlur: handleOnBlur,
    onChangeDebounced,
    onFocus: handleOnFocus,
    ...props
  }: SyncedComponentProps) => {
    const ctrlRef = useRef(new RichTextSyncController(id, upstreamRichText))
    useEffect(() => {
      ctrlRef.current = new RichTextSyncController(id, upstreamRichText)
    }, [id])

    useDidUpdate(() => {
      ctrlRef.current.onUpstreamChanged(upstreamRichText)
    }, [upstreamRichText])

    useEffect(() => {
      if (onChangeDebounced) ctrlRef.current.flushD2U = onChangeDebounced
    }, [onChangeDebounced])

    useEffect(() => {
      ctrlRef.current.flushU2D = (v) => {
        if (id === undefined) return
        if (!EditorsManager.isRegistered(id)) return
        EditorsManager.effects._set(id, v)
      }
    }, [id])

    // Focus & Blur
    const onFocus = useCallback(
      (e: React.FocusEvent<HTMLDivElement>) => {
        ctrlRef.current.onFocus()
        handleOnFocus && handleOnFocus(e)
      },
      [handleOnFocus],
    )
    const onBlur = useCallback(
      (e: React.FocusEvent<HTMLDivElement>) => {
        ctrlRef.current.onBlur()
        handleOnBlur && handleOnBlur(e)
      },
      [handleOnBlur],
    )

    const _onChangeDebounced = useCallback(
      (v) => {
        ctrlRef.current.onDownstreamChanged(v)
      },
      [onChangeDebounced],
    )

    return (
      <Component
        {...(props as T)}
        id={id}
        richText={upstreamRichText}
        onChangeDebounced={_onChangeDebounced}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }

  return WrappedComponent
}

/**
 *  useDidUpdate hook
 *
 *  Fires a callback on component update
 *  Can take in a list of conditions to fire callback when one of the
 *  conditions changes
 *
 * @param {Function} callback The callback to be called on update
 * @param {Array} conditions The list of variables which trigger update when they are changed
 * @returns {undefined}
 */
function useDidUpdate(callback: () => any, conditions?: any[]): void {
  const hasMountedRef = useRef(false)
  if (typeof conditions !== 'undefined' && !Array.isArray(conditions)) {
    conditions = [conditions]
  } else if (Array.isArray(conditions) && conditions.length === 0) {
    console.warn(
      'Using [] as the second argument makes useDidUpdate a noop. The second argument should either be `undefined` or an array of length greater than 0.',
    )
  }
  useEffect(() => {
    if (hasMountedRef.current) {
      callback()
    } else {
      hasMountedRef.current = true
    }
  }, conditions)

  useEffect(() => {
    return () => {
      hasMountedRef.current = false
    }
  }, [])
}
