/**
 * This file manages callbacks that respond to state changes of the application. It provides a way to register callbacks
 * that will be executed when the application state changes (i.e., when the app goes to the foreground or background).
 *
 * `registerAppStateChangeCallback` registers a callback function that will be executed
 * based on the application's state. The callback function can be set to execute immediately, run once, or execute
 * when the app is in a specific focus state.
 *
 * `functionsToExecute` array stores the callback functions that will be executed when the app state changes.
 *
 * `removeListenerCallback` removes the listener for the 'appStateChange' event.
 */

import { App, AppState } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'

let functionsToExecute: Array<{
  fn: () => void | Promise<void>
  executeWhen: AppFocusState
}> = []

let removeListenerCallback: PluginListenerHandle | null = null

export enum AppFocusState {
  FOREGROUND,
  BACKGROUND,
  BOTH,
}

export const registerAppStateChangeCallback = (
  fn: () => void | Promise<void>,
  {
    executeImmediately = false,
    runOnce = false,
    executeWhen = AppFocusState.FOREGROUND,
  }: {
    executeImmediately: boolean
    runOnce: boolean
    executeWhen: AppFocusState
  },
) => {
  const executeOnForeground = async () => {
    const { isActive } = await App.getState()

    if (
      (isActive && executeWhen !== AppFocusState.BACKGROUND) ||
      (!isActive && executeWhen !== AppFocusState.FOREGROUND)
    ) {
      if (isActive && executeImmediately) {
        await fn()
        if (runOnce) {
          unregisterAppStateChangeCallback(fn)
        }
      } else {
        functionsToExecute.push({ fn, executeWhen })
        if (!removeListenerCallback) {
          removeListenerCallback = await App.addListener(
            'appStateChange',
            handleAppStateChange,
          )
        }
      }
    }
  }

  executeOnForeground()
}

export const unregisterAppStateChangeCallback = (
  fn: () => void | Promise<void>,
) => {
  const index = functionsToExecute.findIndex((f) => f.fn === fn)
  if (index > -1) {
    functionsToExecute.splice(index, 1)
  }
  if (functionsToExecute.length === 0 && removeListenerCallback) {
    removeListenerCallback.remove()
    removeListenerCallback = null
  }
}

const handleAppStateChange = async (state: AppState) => {
  await Promise.all(
    functionsToExecute
      .filter(
        (f) =>
          (state.isActive && f.executeWhen !== AppFocusState.BACKGROUND) ||
          (!state.isActive && f.executeWhen !== AppFocusState.FOREGROUND),
      )
      .map((f) => f.fn()),
  )
}
