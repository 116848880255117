import { Env } from './env'

export const ApiEndpoints = {
  awsSignedDataURI: `${Env.hostURI}/rpc/signedFormResponseData`,
  getMagicLinkWithCodeChallengeURI: `${Env.hostURI}/rpc/getMagicLinkWithCodeChallenge`,
  getSessionWithCodeVerifierAndMagicLinkURI: `${Env.hostURI}/rpc/getSessionWithCodeVerifierAndMagicLink`,
  loginInformationUrl: `${Env.hostURI}/loginInformation`,
  metadataURI: `${Env.hostURI}/rpc/extractUrlMetadata`,
  rpcURI: `${Env.hostURI}/rpc`,
  wsAuthURI: `${Env.hostURI}/rpc/wsAuth`,
}
