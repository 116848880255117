import { DataRecord } from '@eleventhlabs/capture-shared'
import React from 'react'

import { getIconConfigForRecord } from '../common/records'
import {
  RouteType,
  getMetadataForRoute,
  isSystemListRoute,
} from '../common/routes'
import { Icon, IconVariantName, useTheme } from '../common/stationary'

export interface RouteIconProps {
  boxSize: number
  className?: string
  routeType: RouteType
  value?: DataRecord | { emoji: string }
}

export const RouteIcon: React.FC<RouteIconProps> = ({
  boxSize,
  className,
  routeType,
  value,
}) => {
  const theme = useTheme()

  let iconColor: string | undefined
  let iconValue: string | undefined
  let iconVariant: IconVariantName | undefined

  if (isSystemListRoute(routeType)) {
    const { iconConfig } = getMetadataForRoute({ routeType }, theme)
    iconColor = iconConfig?.color
    iconVariant = iconConfig?.variant
  } else if (value && `emoji` in value) {
    iconValue = value.emoji
    iconVariant = `emoji`
  } else {
    const iconConfig = getIconConfigForRecord({ record: value }, theme)
    iconColor = iconConfig?.color
    iconValue = iconConfig?.value
    iconVariant = iconConfig?.variant
  }

  return (
    <Icon
      boxSize={boxSize}
      className={className}
      style={{ color: iconColor }}
      value={iconValue}
      variant={iconVariant}
    />
  )
}
