import { ReferenceInsertPoint, TodayId } from '@eleventhlabs/capture-shared'
import { RefObject } from 'react'
import { RecordGroupType, RecordItemData } from '../../data/recordGroups'

export type DropPoint = ReferenceInsertPoint['at']

export enum DropTargetType {
  RECORD = 'record',
  OUTLINE_RECORD = 'outline_record',
  SHORTCUT = 'shortcut',
  RECORD_GROUP = 'record_group',
}

export const UNSUPPORTED_DROP_RECORD_IDS = [TodayId]

// The information DnD needs from our app to build the handlers
export type DropPayload =
  | DropRecordItemPayload
  | DropOutlineRecordPayload
  | DropShortcutPayload
  | DropRecordGroupPayload

export interface DropOutlineRecordPayload {
  id: string
}

export interface DropShortcutPayload {
  id: string
  destinationId: string
}

export interface DropRecordGroupPayload {
  groupId: string
  isRecordGroupEmpty: boolean
}

export interface DropRecordItemPayload {
  groupType: RecordGroupType
  index: number
  record: RecordItemData
  hoverRef: RefObject<HTMLDivElement>
}

// The props that the DnD framework returns us for us to use
export type DropProps =
  | RecordItemDropProps
  | OutlineRecordDropProps
  | ShortcutDropProps
  | RecordGroupDropProps

export interface BaseDropProps {
  hovered: boolean
  canDrop: boolean
  isValidAndHovered: boolean
}
export interface RecordItemDropProps extends BaseDropProps {
  isDragInProgress: boolean
}

export interface OutlineRecordDropProps extends BaseDropProps {}

export interface ShortcutDropProps extends BaseDropProps {
  isDragInProgress: boolean
}

export interface RecordGroupDropProps extends BaseDropProps {}
