import { isEmpty, trim } from 'lodash'
import sanitizeHtml from 'sanitize-html'
import { KeyboardEvent } from '../recordItemEventHandlers/types'
import { Env } from '../env'

export * from './cookie'
export * from './date'
export * from './dom'
export * from './timeout'
export * from './timezone'
export type { default as MutableRef } from './MutableRef'
export { default as onMouseDownAction } from './onMouseDownAction'
export {
  getIndexToSpliceInListToMoveItemsBelowAnchor,
  getIndexToSpliceInListToMoveItemsAboveAnchor,
  getIndexToSpliceInListToMoveItemsToEnd,
} from './splice'
export * from './text'
export { default as useForkRef } from './useForkRef'
export { default as useStableCallback } from './useStableCallback'

// Is the key a valid key that will show up to the user?
// Only 1 character, is a number, digit, symbol, unicode, etc
// And not cmd+c
export const isVisibleKey = (e: KeyboardEvent) => {
  return /^[\d\w\s\p{P}\p{S}]{1}$/u.test(e.key) && !(e.metaKey && e.key === 'c')
}

// emptyFn

export const emptyFn = (): void => undefined

// Link

export const isLink = (text: string): boolean =>
  /^https?:\/\//.test(text) && !text.includes(` `)

// HTML

export const isEmptyHTML = (html: string) => {
  if (html.length === 0) return true

  const sanitizedAndTrimmedHtml = trim(
    sanitizeHtml(html, {
      allowedTags: [],
      allowedAttributes: {},
    }),
  )

  return isEmpty(sanitizedAndTrimmedHtml)
}

// SSR

export const isSSROrWindowNotAvailable = () => {
  return Env.isSSR || typeof window === 'undefined'
}

export const isNotSSRAndWindowAvailable = () => {
  return !Env.isSSR && typeof window === 'undefined'
}
