import { every } from 'lodash'

import { Records, canBeConvertedToTextRecord } from '../../../../records'
import {
  DataRecord,
  UpdateNoteRecordPayload,
  UpdateRecordPayload,
  UpdateTextRecordPayload,
} from '@eleventhlabs/capture-shared'

export const convertToTextRecord = (
  records: Record<string, DataRecord>,
  recordIds: string[],
  updateRecordText: (
    recordId: string,
    payload: UpdateTextRecordPayload | UpdateNoteRecordPayload,
  ) => void,
): boolean => {
  const selectedRecords = recordIds.map((id) => records[id])
  const canBeConverted = every(selectedRecords, canBeConvertedToTextRecord)

  if (!canBeConverted) return false

  selectedRecords.map((record) =>
    Records.convertToTextRecord(record, updateRecordText),
  )

  return true
}
