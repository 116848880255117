import { randomBytes, createHash } from 'crypto'

function generateCodeVerifier(): string {
  return randomBytes(32).toString('hex')
}

function generateCodeChallenge(codeVerifier: string): string {
  return createHash('sha256')
    .update(codeVerifier)
    .digest('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export { generateCodeChallenge, generateCodeVerifier }
