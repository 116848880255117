import { ClickAwayListener } from '@mui/base'
import { SnackbarUnstyledProps, useSnackbar } from '@mui/base/SnackbarUnstyled'
import React from 'react'
import { isMobile } from '../../utils/env'
import { cx, styled } from '../styled'
import { Alert, AlertProps } from './Alert/Alert'
import { Icon } from './Icon'
import { Transition } from 'react-transition-group'

interface SnackbarPropsExtra {
  closeOnClickAway?: boolean
}

export type SnackbarProps = SnackbarUnstyledProps &
  AlertProps &
  SnackbarPropsExtra

export const Snackbar: React.FC<SnackbarProps> = ({
  children,
  icon,
  autoHideDuration,
  onClose,
  open,
  closeOnClickAway = true,
}) => {
  const { getRootProps, onClickAway } = useSnackbar({
    onClose: onClose,
    open: open,
    autoHideDuration: autoHideDuration,
  })

  if (!open) return null

  const snackbarContent = (
    <Transition appear={open} in timeout={0}>
      {(status) => (
        <S.SnackbarWrapper
          {...getRootProps()}
          className={cx({ isMobile, isVisible: status === 'entered' })}
        >
          <Alert icon={icon} onClose={onClose}>
            {children}
          </Alert>
        </S.SnackbarWrapper>
      )}
    </Transition>
  )

  if (closeOnClickAway) {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        {snackbarContent}
      </ClickAwayListener>
    )
  }

  return snackbarContent
}

const S = {
  SnackbarWrapper: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: `flex-start`,
    padding: `6px`,
    gap: `6px`,

    position: `fixed`,
    width: `fit-content`,
    right: `16px`,
    bottom: `16px`,
    zIndex: 1000,
    marginRight: !isMobile ? 64 : 0,
    opacity: 0,
    marginBottom: -4,

    transition: 'opacity 0.1s ease, margin 0.2s ease',

    '&.isMobile': {
      left: `50%`,
      transform: `translateX(-50%)`,
      bottom: 'calc(16px + env(safe-area-inset-bottom))',
    },
    '&.isVisible': {
      opacity: 1,
      marginBottom: 0,
    },

    background: theme.colors.surface.base,

    border: theme.colors.alpha.border.weak,

    boxShadow: theme.effects.boxShadow.elevation[3],
    borderRadius: 6,
  })),
  ActionWrapper: styled.div({
    display: 'flex',
    height: `100%`,
  }),
  CloseButtonWrapper: styled.div({
    width: 28,
    height: 28,
    padding: 8,
    order: 1,
  }),
  CloseButton: styled(Icon)({
    cursor: 'pointer',

    opacity: 0.8,

    flex: `none`,
    flexGrow: 0,
  }),
}
