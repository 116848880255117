import { isEmpty } from 'lodash'
import React from 'react'

import { withDnDContext } from '../../../common/dnd/enhancers/withDnDContext'
import {
  Button,
  Icon,
  IconVariantName,
  cx,
  styled,
  withTooltip,
} from '../../../common/stationary'
import OutlineCategoryList from './OutlineList'
import { ListItem } from './OutlineList/OutlineListItem'

export interface Category {
  id: string
  title: string
  items: ListItem[]
  actions: CategoryAction[]
  onHeadingClick: React.MouseEventHandler
}

export interface CategoryAction {
  iconVariant: IconVariantName
  title: string
  onClick: React.MouseEventHandler
}

export interface OutlineProps {
  activeCategoryId?: string
  activeListId?: string
  categories: Category[]
  className?: string
}

export const Outline: React.FC<OutlineProps> = ({
  activeCategoryId,
  activeListId,
  categories,
  className,
}) => (
  <S.Outline className={className}>
    {categories.map((category) => (
      <OutlineCategory
        key={category.id}
        isActive={activeCategoryId === category.id}
        activeListId={activeListId}
        category={category}
      />
    ))}
  </S.Outline>
)

interface OutlineCategoryProps {
  isActive?: boolean
  activeListId?: string
  category: Category
}

const OutlineCategory: React.FC<OutlineCategoryProps> = ({
  isActive,
  activeListId,
  category: { title, items, actions, onHeadingClick },
}) => {
  return (
    <>
      <CategoryHeading
        title={title}
        actions={actions}
        onHeadingClick={onHeadingClick}
      />
      {isActive ? (
        <S.OutlineListWrapper>
          <S.OutlineList activeItem={activeListId} items={items} />
        </S.OutlineListWrapper>
      ) : null}
    </>
  )
}

interface CategoryHeadingProps {
  title: string
  actions: CategoryAction[]
  onHeadingClick?: React.MouseEventHandler
}

const CategoryHeading: React.FC<CategoryHeadingProps> = withDnDContext(
  ({ title, actions, onHeadingClick = () => undefined, isDragInProgress }) => (
    <S.CategoryHeading className={cx({ isDragInProgress })}>
      <S.HeadingButton
        onMouseDown={(e) => e.preventDefault()}
        onClick={onHeadingClick}
        variant="plain"
      >
        {title}
      </S.HeadingButton>
      {!isEmpty(actions) ? (
        <S.Actions>
          {actions.map((action) => (
            <S.ActionButton
              key={action.iconVariant}
              onClick={action.onClick}
              variant="plain"
              tooltip={{
                title: action.title,
              }}
            >
              <Icon boxSize={14} variant={action.iconVariant} />
            </S.ActionButton>
          ))}
        </S.Actions>
      ) : null}
    </S.CategoryHeading>
  ),
)

const CategoryHeadingDimensions = {
  height: 36,
}

const S = {
  Outline: styled.div({
    display: `flex`,
    flexDirection: `column`,
    padding: 8,
  }),
  CategoryHeading: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `space-between`,
    height: CategoryHeadingDimensions.height,
    padding: `0 6px`,

    '&.isDragInProgress': {
      pointerEvents: `none`,
    },
  }),
  HeadingButton: styled(Button)(({ theme }) => ({
    height: 24,
    padding: `0 6px`,
    borderRadius: 6,
    ...theme.text.publicSans['11.5:16'],
    ...theme.text.publicSans.uppercase,
    fontWeight: 700,
    textTransform: `uppercase`,
    color: theme.colors.text[700],

    '&:hover': {
      color: theme.colors.text[900],
    },
  })),
  Actions: styled.div({
    display: `flex`,
    alignItems: `center`,
  }),
  ActionButton: styled(withTooltip(Button))(({ theme }) => ({
    height: 28,
    width: 28,
    borderRadius: 6,
    color: theme.colors.text[700],

    '&:hover': {
      color: theme.colors.text[900],
    },
  })),
  OutlineListWrapper: styled.div({
    flex: `1`,
    display: `flex`,
    flexDirection: `column`,
    minHeight: 0,
    width: 'calc(100% + 8px)',
  }),
  OutlineList: styled(OutlineCategoryList)({
    overflowY: `scroll !important` as any,
  }),
}
