import { useCallback, useEffect, useState } from 'react'
import React from 'react'
import { Env } from '../env'

export function useStytchLazyLoaded() {
  const [stytchReact, setStytchReact] = useState()
  const [stytchVanillaJs, setStytchVanillaJs] = useState()
  const [stytchUIClient, setStytchUIClient] = useState(undefined)
  const isStytchLoaded = !!stytchReact && !!stytchVanillaJs && stytchUIClient

  useEffect(() => {
    setStytchVanillaJs(require('@stytch/vanilla-js'))
    setStytchReact(require('@stytch/react'))
  }, [])

  useEffect(() => {
    if (stytchVanillaJs) {
      setStytchUIClient(
        // @ts-ignore
        new stytchVanillaJs.StytchUIClient(Env.stytchPublicToken),
      )
    }
  }, [stytchVanillaJs])

  const StytchProvider: React.FC = useCallback(
    ({ children }) => {
      if (isStytchLoaded) {
        return (
          // @ts-ignore
          <stytchReact.StytchProvider stytch={stytchUIClient}>
            {children}
            {/* @ts-ignore */}
          </stytchReact.StytchProvider>
        )
      } else return null
    },
    [stytchReact, stytchUIClient],
  )

  return { StytchProvider }
}
