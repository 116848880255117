import React from 'react'

import diagramGooglePlay from '../assets/onboarding/diagram-google-play@2x.png'
import diagramTestflight from '../assets/onboarding/diagram-testflight@2x.png'
import { styled } from '../common/stationary'
import { isAndroid } from '../common/utils/env'
import { Modal, ModalBackdrop, ModalProps } from './Modal'
import { Env } from '../common/env'

interface MobileDownloadModalProps extends Omit<ModalProps, 'children'> {}

export const MobileDownloadModal: React.FC<MobileDownloadModalProps> = ({
  slots,
  ...props
}) => (
  <S.MobileDownloadModal
    slots={{ backdrop: ModalBackdrop, ...slots }}
    {...props}
  >
    <S.Inner>
      <S.GraphicsContainer>
        <img
          src={isAndroid ? diagramGooglePlay : diagramTestflight}
          srcSet={`${isAndroid ? diagramGooglePlay : diagramTestflight} 2x`}
        />
      </S.GraphicsContainer>
      <S.TextContainer>
        <S.Title>
          {isAndroid
            ? 'Install Capture for Android'
            : 'Install Capture for iPhone/iPad'}
        </S.Title>
        <S.Paragraph>
          {isAndroid ? (
            <>
              Install the beta app from <strong>Google Play</strong>.
            </>
          ) : (
            <>
              Before you can install the beta app, you’ll need to set up{' '}
              <strong>Apple TestFlight</strong>.
            </>
          )}
        </S.Paragraph>
      </S.TextContainer>
      <S.ButtonsContainer>
        <S.PrimaryButton
          href={
            isAndroid ? Env.androidUpdateDeeplinkUrl : Env.publicTestflightUrl
          }
        >
          {isAndroid ? 'View in Google Play' : 'View instructions'}
        </S.PrimaryButton>
      </S.ButtonsContainer>
    </S.Inner>
  </S.MobileDownloadModal>
)

const S = {
  MobileDownloadModal: styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 'calc(8px + env(safe-area-inset-bottom))',
    paddingTop: 'calc(8px + env(safe-area-inset-top))',
  })),
  Inner: styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    maxWidth: 432,
    borderRadius: 12,
    boxShadow: theme.effects.boxShadow.elevation[3],
    background: theme.colors.surface.base,
    overflow: 'hidden',
  })),

  GraphicsContainer: styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),

  TextContainer: styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '0 32px 8px',
    textAlign: 'center',
  }),
  Title: styled.h1(({ theme }) => ({
    ...theme.text.publicSans['27.5:36'],
    fontWeight: 800,
  })),
  Paragraph: styled.p({
    strong: {
      fontWeight: 700,
    },
  }),

  ButtonsContainer: styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 24px 32px',
  }),
  PrimaryButton: styled.a(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
    width: '100%',
    maxWidth: 400,
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[3],
    fontWeight: 700,
    textAlign: 'center',
    background: theme.colors.text[900],
    color: theme.colors.alpha.invertedTone.solid,
  })),
}
