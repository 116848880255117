import React from 'react'

import { cx, styled } from '../../../common/stationary'

import { AuthStyles } from '../AuthStyles'

import { useDesktopAuth } from '../../../common/contexts/DesktopAuthContext'
import { useAuth } from '../../../providers'
import { isEmpty } from 'lodash'
import { safeNavigate } from '../../../common/navigate'
import { LoginRoutePath, RouteType, RoutesPath } from '../../../common/routes'

interface OpenDesktopAppProps {
  queryParams: URLSearchParams
}

const OpenDesktopApp: React.FC<OpenDesktopAppProps> = ({ queryParams }) => {
  const { redirectToDesktopApp, getMagicLinkWithCodeChallenge, errorMessage } =
    useDesktopAuth()
  const { email, setErrorState, revokeSessionAndDeleteCookies } = useAuth()
  const codeChallenge = queryParams.get('code_challenge')

  const logoutAndNavigateToLoginScreen = async () => {
    setErrorState(undefined)
    await revokeSessionAndDeleteCookies()
    safeNavigate(LoginRoutePath)
  }

  const handleGetMagicLinkAndRedirect = async () => {
    if (codeChallenge) {
      const magicLink = await getMagicLinkWithCodeChallenge(codeChallenge)
      if (magicLink) {
        safeNavigate(RoutesPath[RouteType.Inbox])
        redirectToDesktopApp(magicLink)
      }
    }
  }

  return (
    <S.LoginWithBrowser>
      <S.LoginWithEmail>Log in with {email}?</S.LoginWithEmail>
      <AuthStyles.AuthSubmit onClick={handleGetMagicLinkAndRedirect}>
        Open the desktop app
      </AuthStyles.AuthSubmit>
      <S.NotYou>
        Not you?
        <strong onClick={logoutAndNavigateToLoginScreen}>
          Sign in to another account.
        </strong>
      </S.NotYou>

      {!isEmpty(errorMessage) && (
        <S.AuthMessage
          className={cx({
            isError: !isEmpty(errorMessage),
          })}
        >
          {' '}
          <>
            {errorMessage} If the issue persists,{' '}
            <a href="mailto:membership@capture.so">contact support</a>
          </>
        </S.AuthMessage>
      )}
    </S.LoginWithBrowser>
  )
}

export default OpenDesktopApp

const S = {
  LoginWithBrowser: styled.div(() => ({
    marginTop: '40%',
  })),
  LoginWithEmail: styled.div(() => ({
    marginBottom: 30,
    fontWeight: 700,
  })),
  NotYou: styled.div(() => ({
    marginTop: 10,
    fontSize: 12,

    strong: {
      textDecoration: 'underline',
      fontWeight: 700,
      cursor: 'pointer',
      marginLeft: 8,
    },
  })),
  AuthMessage: styled(AuthStyles.AuthMessage)(({ theme }) => ({
    border: `1px solid ${theme.colors.red[300]}`,
    maxWidth: 368,
  })),
}
