import React, { useEffect, useMemo } from 'react'
import { DraggableChildrenFn, Droppable } from 'react-beautiful-dnd'

import { Frack, RootRecord, RootRecordId } from '@eleventhlabs/capture-shared'

import Analytics from '../../../../common/analytics/capture-analytics-actions'
import { useActiveScreen } from '../../../../common/analytics/capture-analytics-web'
import { useDnDContext } from '../../../../common/contexts/DnDContext'
import { useModelStoreContext } from '../../../../common/contexts/ModelStoreContext'
import { VirtualListAutosized } from '../../../../common/virtualized'
import { OutlineListItemDimensions } from './OutlineListItem'
import { OutlineListItemData } from './OutlineListItemData'
import { OutlineListItemRow } from './OutlineListItemRow'
import { useOutlineListItemRenderClone } from './useOutlineListItemRenderClone'
import { withScrollerRef } from './withScrollerRef'

export const DnDOutlineCategoryList: React.FC<{
  className?: string
  itemData: OutlineListItemData
}> = (props) => {
  useUpdateOnDragEnd()
  const renderClone = useOutlineListItemRenderClone(props.itemData.items)
  return (
    <DnDDroppableOutlineCategoryList
      className={props.className}
      itemData={props.itemData}
      renderClone={renderClone}
    />
  )
}

const useUpdateOnDragEnd = () => {
  const { onDragEndRef } = useDnDContext()
  const { store, commands } = useModelStoreContext()
  const { moveRecords } = commands
  const root = useMemo(
    () => store.records[RootRecordId] as RootRecord,
    [store.records],
  )
  const activeScreen = useActiveScreen()
  useEffect(() => {
    onDragEndRef.current = (result, provided) => {
      const sourceSectionId = Frack.toArray(root.children)[result.source.index]
      const destinationIndex = result.destination?.index
      if (destinationIndex === undefined) return
      if (result.source.index === result.destination?.index) return
      moveRecords([sourceSectionId], {
        id: RootRecordId,
        point: { at: `index`, index: destinationIndex },
      })
      Analytics.listReordered({
        activeScreen,
        listReorderedTrigger: Analytics.ListReorderedTrigger.DRAG_AND_DROP,
      })
    }
  }, [activeScreen, root.children, moveRecords, onDragEndRef])
}

const DnDDroppableOutlineCategoryList: React.FC<{
  className?: string
  itemData: OutlineListItemData
  renderClone: DraggableChildrenFn
}> = (props) => {
  return (
    <Droppable
      droppableId={`outline`}
      mode="virtual"
      renderClone={props.renderClone}
    >
      {(provided) => (
        <List
          itemKey={(index) => props.itemData.items[index].id}
          itemCount={props.itemData.items.length}
          itemSize={OutlineListItemDimensions.height}
          itemData={props.itemData}
          outerRef={provided.innerRef}
          Row={OutlineListItemRow}
          className={props.className}
        />
      )}
    </Droppable>
  )
}

const List = withScrollerRef(VirtualListAutosized)
