import React, { useState } from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'

import Analytics from '../../../common/analytics/capture-analytics-actions'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { styled } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'
import { InviteModal, InviteModalEmailFormStatus } from './InviteModal'

export function InviteCTA() {
  const { store } = useModelStoreContext()

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [inviteEmailValue, setInviteEmailValue] = useState('')
  const [inviteEmailFormStatus, setInviteEmailFormStatus] =
    useState<InviteModalEmailFormStatus>('READY')

  const inviteEmailValueStatus =
    inviteEmailValue.length === 0
      ? 'EMPTY'
      : !inviteEmailValue.includes('@') || !inviteEmailValue.includes('.')
      ? 'NOT_EMAIL'
      : 'VALID'

  const handleInviteModalClose = () => {
    setIsInviteModalOpen(false)
    setInviteEmailValue('')
    setInviteEmailFormStatus('READY')
  }

  const handleInviteEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmailValue(e.target.value)
    setInviteEmailFormStatus('READY')
  }

  const handleInviteEmailFormSubmit = () => {
    if (inviteEmailValueStatus === 'VALID') setInviteEmailFormStatus('SUCCESS')
    else setInviteEmailFormStatus('ERROR')
    Analytics.inviteViaEmailClicked({ inviteCode: store.user.inviteCode })
    Analytics.inviteSharingStarted({ inviteCode: store.user.inviteCode })
  }

  return (
    <S.InviteCTA>
      <S.Title>Early access plan</S.Title>
      <S.Description>
        Invite others to skip the waitlist and get early access to Capture
      </S.Description>
      <S.Buttons>
        <S.CTAButton
          onClick={() => {
            Analytics.inviteCtaClicked({
              inviteCode: store.user.inviteCode.toUpperCase(),
            })
            setIsInviteModalOpen(true)
          }}
        >
          <strong>Share invites</strong>
          <BiRightArrowAlt />
        </S.CTAButton>
      </S.Buttons>
      <InviteModal
        inviteCode={store.user.inviteCode}
        inviteEmailFormStatus={inviteEmailFormStatus}
        inviteEmailValue={inviteEmailValue}
        inviteEmailValueStatus={inviteEmailValueStatus}
        onClose={handleInviteModalClose}
        onInviteEmailChange={handleInviteEmailChange}
        onInviteEmailFormSubmit={handleInviteEmailFormSubmit}
        open={isInviteModalOpen}
      />
    </S.InviteCTA>
  )
}

const S = {
  InviteCTA: styled.div`
    padding: 20px 24px;
    ${({ theme }) => theme.text.publicSans['13.5:20']};
  `,
  Title: styled.div`
    margin-bottom: 4px;
    font-weight: 700;
  `,
  Description: styled.div`
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.text[700]};
  `,
  Buttons: styled.div`
    margin: 0 -4px;
  `,
  CTAButton: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    height: 28px;
    padding: 0 12px;
    border-radius: 6px;
    box-shadow: ${({ theme }) => theme.effects.boxShadow.elevation[2]};
    background: ${({ theme }) => theme.colors.surface.base};
    cursor: pointer;

    strong {
      font-weight: 700;
    }
    svg {
      font-size: 16px;
      transform: rotate(-45deg);
    }
    :hover {
      box-shadow: ${({ theme }) => theme.effects.boxShadow.elevation[3]};
    }
    ${isMobile &&
    `
      height: 36px;
      width: 100%;
    `};
  `,
}
