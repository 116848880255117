import { useMemo } from 'react'
import { CAPACITOR_PLATFORM, MobileAppPlatform } from './env'

export const useMobileAppPlatform = (): MobileAppPlatform | null => {
  return useMemo(() => {
    if (
      CAPACITOR_PLATFORM === MobileAppPlatform.IOS ||
      CAPACITOR_PLATFORM === MobileAppPlatform.ANDROID
    ) {
      return CAPACITOR_PLATFORM
    }
    return null
  }, [])
}
