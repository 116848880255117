import { map } from 'lodash'

import React, { useMemo, useRef } from 'react'

import { TodayId } from '@eleventhlabs/capture-shared'
import Analytics from '../../common/analytics/capture-analytics-actions'
import useScreenLoadedAnalytics from '../../common/analytics/helpers/hooks/useScreenLoadedAnalytics'
import { useUIContext } from '../../common/contexts/UIContext'
import { RecordGroupType } from '../../common/data/recordGroups'
import { OverdueRecordGroupId } from '../../common/data/recordGroups/selectors/today'
import { useScrollWindowAtEdgesOnDrag } from '../../common/hooks/listeners/useScrollWindowAtEdgesOnDrag'
import { ScrollOptions } from '../../common/hooks/scroll/useScroll'
import {
  screenInnerStyles,
  screenStyles,
  styled,
} from '../../common/stationary'
import { isMobile } from '../../common/utils/env'
import { HeaderDimension } from '../../components/Header'
import Header from '../../components/Header/Header'
import { RecordGroup } from '../../components/RecordGroup/RecordGroup'
import { ClickOutsideListener } from '../../components/helpers/ClickOutsideListener'
import { withDragLayer } from '../enhancers/withDragLayer'
import { withFloatingActions } from '../enhancers/withFloatingActions'
import { withRecordGroupHotKeys } from '../enhancers/withRecordGroupHotKeys'
import { withRecordGroupScreen } from '../enhancers/withRecordGroupScreen'
import { CommonRecordGroupScreenProps } from '../types'
import TodayHeaderFixedWrapper, {
  TodayHeaderWrapperDimensions,
} from './components/TodayHeaderFixedWrapper'
import { useVisibleItemAsActive } from './hooks/useVisibleItemAsActive'
import { TodayControls } from './hooks/useTodayControls'
import {
  EmptyStateText,
  RecordGroupEmptyState,
} from '../../components/RecordGroupEmptyState'
import { useDragToSelectRecords } from '../../common/dragToSelect/useDragToSelect'
import { CAPTURE_BUTTONS_HEIGHT } from '../../components/CaptureButtons/CaptureButtons'
import { FileDropOverlay } from '../../components/FileDropOverlay'

export interface TodayProps {
  controls: TodayControls
}

export type TodayScreenProps = TodayProps & CommonRecordGroupScreenProps

const TODAY_TITLE = 'Today'

const TodayScreen = ({
  createFileRecordAtStartOfGroup,
  className,
  controls,
  focusedId,
  groups,
  noteHandlers,
  miniBarHandlers,
  onClickOutside,
  recordHandlers,
  selectedIds,
  shouldDisplayNotesForRecordId,
  createRecordAtStartOfGroup,
  uploadProgress,
  reschedule,
  routeType,
  onTouchStart,
  onTouchMove,
  isDragActive,
}: TodayScreenProps) => {
  const { isMouseRecentlyActive } = useUIContext()

  const headerRef = useRef<HTMLDivElement | null>(null)

  const dragBoundaryRef = useScrollWindowAtEdgesOnDrag<HTMLDivElement>(
    headerRef.current?.clientHeight,
  )
  useDragToSelectRecords(dragBoundaryRef)
  useScreenLoadedAnalytics(Analytics.ScreenType.TODAY, TodayId)

  const groupIds = useMemo(() => map(groups, (group) => group.id), [groups])
  const { visibilityStatusUpdate } = useVisibleItemAsActive(groupIds)

  return (
    <S.TodayScreen
      ref={dragBoundaryRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
    >
      {isDragActive && <FileDropOverlay />}
      <Header />
      <ClickOutsideListener onClickOutside={onClickOutside}>
        <S.TodayScreenInner className={className}>
          <TodayHeaderFixedWrapper id={TodayId} ref={headerRef} {...controls} />
          {groups.map((group) => (
            <RecordGroup
              showCaptureButtons={false}
              createFileRecordAtStartOfGroup={createFileRecordAtStartOfGroup}
              emptyStateRenderer={() => (
                <RecordGroupEmptyState>
                  <EmptyStateText>
                    Nothing scheduled for <strong>{group.title ?? ''}</strong>
                  </EmptyStateText>
                </RecordGroupEmptyState>
              )}
              focusedId={focusedId}
              isMouseActive={isMouseRecentlyActive}
              key={`${group.type}-${group.id}`}
              miniBarHandlers={miniBarHandlers}
              noteHandlers={noteHandlers}
              recordHandlers={recordHandlers}
              reschedule={
                group.type === RecordGroupType.Overdue ? reschedule : undefined
              }
              routeType={routeType}
              scrollOptions={scrollOptions}
              selectedIds={selectedIds}
              shouldDisplayNotesForRecordId={shouldDisplayNotesForRecordId}
              createRecordAtStartOfGroup={createRecordAtStartOfGroup}
              uploadProgress={uploadProgress}
              visibilityStatusUpdate={
                group.type === RecordGroupType.Overdue
                  ? undefined
                  : visibilityStatusUpdate
              }
              {...group}
            />
          ))}
        </S.TodayScreenInner>
      </ClickOutsideListener>
    </S.TodayScreen>
  )
}

export default withFloatingActions(
  withDragLayer(withRecordGroupScreen(withRecordGroupHotKeys(TodayScreen))),
)

const TODAY_SCREEN_OFFSET_TOP =
  HeaderDimension.height +
  TodayHeaderWrapperDimensions.height +
  CAPTURE_BUTTONS_HEIGHT

const scrollOptions: ScrollOptions = {
  offsetTop: TODAY_SCREEN_OFFSET_TOP,
  position: `top`,
  navigateIfFullyVisible: true,
}

const S = {
  TodayScreen: styled.div({
    ...screenStyles,
  }),
  TodayScreenInner: styled.div({
    ...screenInnerStyles,
    paddingTop: TODAY_SCREEN_OFFSET_TOP - 32,
  }),
}
