import { v4 as uuidv4 } from 'uuid'
import { RecordGroupsCommands } from '../../../../../data/recordGroups'
import { RecordItemTargetType } from '../../../../../recordItem'
import { Factory } from './factory'
import {
  CreateFileRecordExtras,
  CreateRecordAtFirstPositionArgs,
  CreateRecordAtStartOfGroupArgs,
  CreateRecordInsertBelowArgs,
  CreateRecordLocation,
  CreateRecordPosition,
  CreateRecordPositionToArgs,
  CreateRecordResult,
} from './types'

interface CreateRecordArgs<P extends CreateRecordPosition> {
  recordGroupsCommands: RecordGroupsCommands
  location: CreateRecordLocation<P>
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >
  positionArgs?: CreateRecordPositionToArgs[P]
  createFileRecordExtras?: CreateFileRecordExtras
  isFileRecord?: boolean
}

export const createRecord = async <P extends CreateRecordPosition>(
  args: CreateRecordArgs<P>,
): Promise<CreateRecordResult> => {
  if (args.isFileRecord) {
    if (!args.createFileRecordExtras) return

    return Factory.createFileRecord(
      args.recordGroupsCommands,
      args.location.groupId,
      args.location.index,
      args.createFileRecordExtras,
    )
  }

  if (!args.location.position)
    throw Error('position required for create record')

  return await createRecordInPosition(
    args.location.position,
    args.recordGroupsCommands,
    args.setRecentlyCreatedRecords,
    args.positionArgs,
  )
}

const createRecordInPosition = async <P extends CreateRecordPosition>(
  position: P,
  recordGroupsCommands: RecordGroupsCommands,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
  positionArgs?: CreateRecordPositionToArgs[P],
): Promise<CreateRecordResult> => {
  switch (position) {
    case CreateRecordPosition.FIRST_POSITION:
      return await Factory.createRecordAtFirstPosition(
        uuidv4(),
        recordGroupsCommands,
        setRecentlyCreatedRecords,
        positionArgs as CreateRecordAtFirstPositionArgs,
      )
    case CreateRecordPosition.START_OF_GROUP:
      return await Factory.createRecordAtStartOfGroup(
        uuidv4(),
        recordGroupsCommands,
        setRecentlyCreatedRecords,
        positionArgs as CreateRecordAtStartOfGroupArgs,
      )
    case CreateRecordPosition.BELOW_CURRENT:
      const typedArgs = positionArgs as CreateRecordInsertBelowArgs

      return Factory.createRecordInsertBelow(
        typedArgs.selection,
        recordGroupsCommands,
        setRecentlyCreatedRecords,
      )
  }
}
