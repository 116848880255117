import React from 'react'

import { ResultItem } from '../MoveTo.types'
import { styled } from '../../../common/stationary'

const MoveSeparator = ({ title }: ResultItem) => {
  return (
    <S.MoveSeparatorHeading>
      <S.MoveSeparatorHeadingTitle>{title}</S.MoveSeparatorHeadingTitle>
    </S.MoveSeparatorHeading>
  )
}

export default MoveSeparator

const S = {
  MoveSeparatorHeading: styled.div({
    display: `flex`,
    alignItems: `center`,
    height: 32,
    padding: `0 20px`,
    outline: `none`,
    userSelect: `none`,
  }),
  MoveSeparatorHeadingTitle: styled.div(({ theme }) => ({
    ...theme.text.publicSans['11.5:16'],
    fontWeight: `bold`,
    textTransform: `uppercase`,
    lineHeight: `16px`,
    color: theme.colors.text[500],
    letterSpacing: `0.08em`,
  })),
}
