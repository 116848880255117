import {
  CreateRecordPayload,
  InboxRecordId,
  MoveToExistingPlaceIndex,
  RecordType,
  createRichTextObject,
} from '@eleventhlabs/capture-shared'
import * as Analytics from '../../../../analytics/capture-analytics-actions'

export const onboardingSubmitQuickCapture = (
  createRecord: (
    payload: CreateRecordPayload,
    index: MoveToExistingPlaceIndex,
  ) => string,
  text: string,
) => {
  const newRecordId = createRecord(
    {
      type: RecordType.Text,
      richText: createRichTextObject(text),
    },
    { id: InboxRecordId, point: { at: 'start' } },
  )
  Analytics.recordCreated({
    activeScreen: undefined,
    destinationType: Analytics.DestinationType.INBOX,
    recordCreatedTrigger: 'Onboarding Quick Capture',
    recordId: newRecordId,
    recordType: Analytics.RecordType.TEXT,
  })

  return newRecordId
}
