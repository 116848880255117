import { first } from 'lodash'
import { useCallback, useMemo } from 'react'

import {
  UpdateTextRecordPayload,
  UpdateURLRecordPayload,
} from '@eleventhlabs/capture-shared'

import { useActiveScreen } from '../../analytics/capture-analytics-web'
import { useExecContext } from '../../contexts/ExecContext'
import { useUIContext } from '../../contexts/UIContext'
import { RecordItemData } from '../../data/recordGroups'
import { EditorsManager } from '../../EditorsManager'
import { onKeyDown as handleKeyDown } from './onKeyDown'
import { RichTextEventHandlers } from './RichTextEventHandlers'

export const useRichTextEventHandlersForFullscreen = (
  record: RecordItemData,
): RichTextEventHandlers => {
  const activeScreen = useActiveScreen()
  const { exec } = useExecContext()
  const { shouldDisplayNotesForRecordId, setRecentlyCreatedRecords } =
    useUIContext()
  // Set up analytics for different actions

  const onArrowLeftOrUpAtBoundary = useCallback(
    (_recordId: string) => {
      const noteId = first(record.notesList.notes)?.id
      const notesOpen = shouldDisplayNotesForRecordId(record.id)

      if (!noteId || !notesOpen) return false

      EditorsManager.effects.blur(record.id)
      EditorsManager.effects.focus({ id: noteId })
      return true
    },
    [record, shouldDisplayNotesForRecordId],
  )

  const onChange = useCallback(
    (richText, recordId) => {
      return exec({ type: 'RECORD_CHANGE', recordId, richText })
    },
    [activeScreen, exec],
  )

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>, recordId: string) => {
      return handleKeyDown(e, exec, recordId, setRecentlyCreatedRecords)
    },
    [exec],
  )

  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>, recordId: string) => {
      return exec({ type: 'RECORD_PASTE_FROM_CLIPBOARD', event, recordId })
    },
    [exec],
  )

  return useMemo(
    () => ({
      onArrowLeftOrUpAtBoundary,
      onChange,
      onPaste,
      onKeyDown,
    }),
    [onArrowLeftOrUpAtBoundary, onChange, onPaste, onKeyDown],
  )
}
