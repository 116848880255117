import { flowRight } from 'lodash'

import { withDraggable } from '../../../../common/beautiful-dnd'
import { withVirtualListWrapper } from '../../../../common/virtualized'
import OutlineListItem from './OutlineListItem'
import {
  OutlineListItemRowProps,
  withPropsForSingleRow,
} from './withPropsForSingleRow'

export const OutlineListItemRow: React.FC<OutlineListItemRowProps> = flowRight(
  withVirtualListWrapper,
  withPropsForSingleRow,
  withDraggable,
)(OutlineListItem)
