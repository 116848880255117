import React from 'react'
import { Transition } from 'react-transition-group'

import { styled } from '../../../../../common/stationary'
import * as BaseSlide from './BaseSlide'
import { isMobile } from '../../../../../common/utils/env'

/*
 * SlideContainer
 */
const SlideContainer = styled(BaseSlide.SlideContainer)(({ theme }) => ({
  flexDirection: 'row',
  maxWidth: 432 * 2,
  margin: '0 48px 32px',
  marginBottom: 32, // Vertically center by accounting for height of ProgressBar / 2
  borderRadius: 10,
  boxShadow: theme.effects.boxShadow.elevation[2],
  background: theme.colors.surface.base,
  overflow: 'hidden',

  ...(isMobile && {
    flexDirection: 'column-reverse',
    maxWidth: 432,
    margin: '8px 8px 32px',
  }),
}))

export const TransitionSlideContainer: React.FC<
  JSX.IntrinsicElements['div']
> = (props) => (
  <Transition appear in timeout={0}>
    {(state) => (
      <SlideContainer
        {...props}
        style={{
          ...props.style,
          opacity: state === 'entered' ? 1 : 0,
          marginTop: state === 'entered' ? 0 : 16,
        }}
      />
    )}
  </Transition>
)

/*
 * SlideIntro
 */
export const SlideIntro = styled(BaseSlide.SlideIntro)(() => ({
  flexBasis: '50%',
  alignItems: 'flex-start',
  padding: '56px 48px 140px',

  ...(isMobile && {
    padding: 48,
  }),
}))

export const SlideTitle = styled(BaseSlide.SlideTitle)(({ theme }) => ({
  marginBottom: 16,
  ...theme.text.publicSans['33:40'],
}))

export const SlideDescription = styled(BaseSlide.SlideDescription)(
  ({ theme }) => ({
    marginBottom: 24,
    ...theme.text.publicSans['16:24'],
  }),
)

export const SlideButtonGroup = styled(BaseSlide.SlideButtonGroup)(() => ({
  position: 'absolute',
  bottom: 48,
}))

export const SlidePrimaryButton = BaseSlide.SlidePrimaryButton

export const SlideSecondaryButton = BaseSlide.SlideSecondaryButton

export const SlideWaitingHint = BaseSlide.SlideWaitingHint

export const SlideWaitingSpinner = BaseSlide.SlideWaitingSpinner

export const SlideKeyboardHint = BaseSlide.SlideKeyboardHint

export const SlideSecondaryAction = BaseSlide.SlideSecondaryAction

/*
 * SlideDiagrams
 */
export const SlideDiagrams = styled(BaseSlide.SlideDiagrams)(({ theme }) => ({
  flexBasis: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.colors.surface.lower,
}))
