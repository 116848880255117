import React, { useCallback } from 'react'

import { useHotkeysThreshold } from '../../common/hooks/useHotkeysThreshold'
import { Key, Priorities, useKeys } from '../../common/hooks/useKeys'
import { css } from '../../common/stationary'
import { Modal } from '../../common/dialogs'
import { Picker } from 'emoji-mart'

interface EmojiDialogProps {
  onSelectEmoji: (emoji: { id?: string }) => void
  emoji?: string
  isOpen?: boolean
  onDismiss: (e?: React.SyntheticEvent) => void
}

export const EmojiDialog: React.FC<EmojiDialogProps> = ({
  isOpen,
  onDismiss,
  onSelectEmoji,
  emoji,
}) => {
  const onSelect = useCallback(
    (emoji: { id?: string }) => {
      if (emoji.id) onSelectEmoji(emoji)
      onDismiss && onDismiss()
    },
    [onSelectEmoji, onDismiss],
  )

  const close = useCallback(() => onDismiss && onDismiss(), [onDismiss])

  useKeys(Key.Esc, close, Priorities.Dialog, true)
  useHotkeysThreshold(Priorities.Dialog)

  return (
    <Modal
      aria-label="EmojiDialog"
      // classNameOuter={css({
      //   padding: `0 ${EmojiDialogDimension.insetRight}px
      //     0 ${EmojiDialogDimension.insetLeft}px`,
      // })}
      open={isOpen ?? false}
      // style={{ maxWidth: EmojiDialogDimension.maxWidth }}
      onClose={close}
    >
      <div
        className={css({
          marginTop: 96,
          maxWidth: '100%',
          width: `fit-content`,
          height: `fit-content`,
          background: `white`,
          borderRadius: `10px`,
          // margin: `0 auto`,
        })}
      >
        <Picker
          native
          autoFocus
          title="Pick your emoji…"
          emoji={emoji}
          onSelect={onSelect}
        />
      </div>
    </Modal>
  )
}

export default EmojiDialog

export const EmojiDialogDimension = {
  height: 400,
  maxWidth: 400,
  insetLeft: 24,
  insetRight: 24,
}
