import React, { createContext, useCallback, useContext, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { RecordItemData } from '../data/recordGroups'
import { DropPoint } from '../dnd/drop/types'
import { DataRecord } from '@eleventhlabs/capture-shared'
import { useModelStoreContext } from './ModelStoreContext'
import { useSelectionContext } from './SelectionContext'

export interface ReactDnDContextValue {
  hoverIndex: number
  hoverRecord: DataRecord | undefined
  insertIndex: DropPoint
  draggedRecords: DataRecord[] | RecordItemData[]
  setHoverIndex: (hoverIndex: number) => void
  setInsertIndex: (insertIndex: DropPoint) => void
  setDnDIndices: (hoverIndex: number, newInsertIndex: DropPoint) => void
  setDraggedRecords: (record?: RecordItemData) => void
  setHoverRecord: (id: string) => void
  clearHoverRecord: () => void
  isDragInProgress: boolean
  setIsDragInProgress: (isDragging: boolean) => void
  isDragIntent: boolean
  setIsDragIntent: (isDragging: boolean) => void
}

/*
 * Context
 */

const defaultValue: ReactDnDContextValue = {
  hoverIndex: -1,
  hoverRecord: undefined,
  insertIndex: `before`,
  draggedRecords: [],
  setHoverIndex: () => {},
  setHoverRecord: () => {},
  clearHoverRecord: () => {},
  setInsertIndex: () => {},
  setDnDIndices: () => {},
  setDraggedRecords: (_record?: RecordItemData) => {},
  isDragInProgress: false,
  setIsDragInProgress: () => {},
  isDragIntent: false,
  setIsDragIntent: () => {},
}

export const ReactDnDContext = createContext<ReactDnDContextValue>(defaultValue)
ReactDnDContext.displayName = `ReactDnDContext`

/*
 * Provider
 */

export interface ReactDnDProviderProps {
  children: React.ReactNode
}

export const ReactDnDProvider = ({ children }: ReactDnDProviderProps) => {
  const [hoverIndex, setHoverIndex] = useState<number>(-1)
  const [insertIndex, setInsertIndex] = useState<DropPoint>(`before`)
  const [draggedRecords, setDraggedRecords] = useState<
    DataRecord[] | RecordItemData[]
  >([])
  const [isDragInProgress, setIsDragInProgress] = useState<boolean>(false)
  const [isDragIntent, setIsDragIntent] = useState<boolean>(false)
  const [hoverRecord, setHoverRecord] = useState<DataRecord | undefined>(
    undefined,
  )

  const { selection, selectedRecords } = useSelectionContext()
  const { store } = useModelStoreContext()

  const setDnDIndices = (hoverIndex: number, newInsertIndex: DropPoint) => {
    if (hoverIndex === hoverIndex && insertIndex === newInsertIndex) {
      return
    }

    setInsertIndex(newInsertIndex)
    setHoverIndex(hoverIndex)
  }

  const _setDraggedRecords = useCallback(
    (record?: RecordItemData) => {
      if (record) {
        selection.clear()
        setDraggedRecords([record])
        return
      }

      setDraggedRecords(selectedRecords)
    },
    [selectedRecords],
  )

  const _setHoverRecord = (id: string) => {
    const record = store.records[id]
    setHoverRecord(record)
  }

  const clearHoverRecord = () => {
    setHoverRecord(undefined)
  }

  const value = {
    hoverIndex,
    hoverRecord,
    insertIndex,
    draggedRecords,
    isDragInProgress,
    setHoverIndex,
    setInsertIndex,
    setDnDIndices,
    setDraggedRecords: _setDraggedRecords,
    setHoverRecord: _setHoverRecord,
    clearHoverRecord,
    setIsDragInProgress,
    isDragIntent,
    setIsDragIntent,
  }

  return (
    <ReactDnDContext.Provider value={value}>
      <DndProvider
        backend={TouchBackend}
        options={{ enableMouseEvents: true, enableKeyboardEvents: true }}
      >
        {children}
      </DndProvider>
    </ReactDnDContext.Provider>
  )
}

/*
 * Hooks
 */

export const useReactDnDContext = (): ReactDnDContextValue => {
  const context = useContext(ReactDnDContext)

  if (context === undefined) {
    throw new Error(`useDnDContext must be used within an DnDProvider`)
  }

  return context
}
