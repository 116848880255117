import { isEmpty, replace } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import PhoneInput, {
  Value as PhoneNumberValue,
  isValidPhoneNumber,
} from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { SlideProps } from '.'
import { ApiEndpoints } from '../../../../common/ApiEndpoints'
import { apiCall, isSuccessStatusCode } from '../../../../common/api/client'
import { useAuth } from '../../../../common/contexts/AuthContext'
import {
  Icon,
  cx,
  spinAnimationStyles,
  styled,
} from '../../../../common/stationary'
import { isMobile } from '../../../../common/utils/env'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import OTPCodeInput, { OTPCodeInputRef } from '../OTPCodeInput'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/ImmersiveSlide'

enum PhoneVerificationState {
  WAITING_FOR_VERIFICATION = 'WAITING_FOR_VERIFICATION',
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
}

const phoneInputId = 'phoneInput'

export const VerifyPhone: React.FC<SlideProps> = ({ goForward }) => {
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumberValue>()
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] =
    useState<boolean>(false)
  const [phoneVerificationState, setPhoneVerificationState] =
    useState<PhoneVerificationState>(PhoneVerificationState.INPUT_PHONE_NUMBER)
  const [loading, setLoading] = useState<boolean>(false)
  const [phoneNumberValidationId, setPhoneNumberValidationId] =
    useState<string>('')
  const [sendVerificationCodeError, setSendVerificationCodeError] =
    useState<string>('')
  const [verifyOTPFailed, setVerifyOTPFailed] = useState<boolean>(false)

  const { sendPhoneVerificationCode, verifyOTP } = useAuth()

  const { onboardingAnalytics } = useOnboardingContext()

  const otpCodeInputRef = useRef<OTPCodeInputRef>(null)

  // Format the phone number for display as +1 408-529-7742
  function formatDisplayPhoneNumber(phoneNumber: string): string {
    // Remove all non-digit characters
    const digits = replace(phoneNumber, /\D+/g, '')

    // Check if the phone number matches the expected length (1 + 10 digits)
    if (digits.length !== 11 || digits[0] !== '1') {
      return phoneNumber // Fallback to the original number
    }

    return `+${digits[0]} ${digits.substring(1, 4)}-${digits.substring(
      4,
      7,
    )}-${digits.substring(7)}`
  }

  const clearErrors = () => {
    setVerifyOTPFailed(false)
    setSendVerificationCodeError('')
  }

  const updateUserPhoneNumber = useCallback(async () => {
    return await apiCall(`${ApiEndpoints.rpcURI}/updatePhoneNumber`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phoneNumber }),
    })
      .then(async (res) => {
        if (!isSuccessStatusCode(res.status)) {
          onboardingAnalytics.action('Update phone number failed')

          return false
        }

        onboardingAnalytics.action('Phone number updated')

        return true
      })
      .catch((err) => {
        onboardingAnalytics.action(`Update phone number failed: ${err}`)

        setVerifyOTPFailed(true)
        return false
      })
  }, [phoneNumber])

  const sendVerificationCodeAndWaitForVerification = useCallback(async () => {
    // Refocus on the phone input
    const inputElement = document.getElementById(phoneInputId)
    inputElement?.focus()

    if (!phoneNumber || isInvalidPhoneNumber) return

    setLoading(true)

    try {
      const verificationMethodId = await sendPhoneVerificationCode(phoneNumber)

      if (verificationMethodId === undefined) return

      onboardingAnalytics.action('OTP Sent')

      setPhoneNumberValidationId(verificationMethodId.method_id)
      setPhoneVerificationState(PhoneVerificationState.WAITING_FOR_VERIFICATION)
    } catch (error: any) {
      if (error.error_type === 'invalid_phone_number_country_code') {
        onboardingAnalytics.action('Send OTP failed: Unsupported country')
        setSendVerificationCodeError('invalid_phone_number_country_code')
      } else {
        onboardingAnalytics.action(`Send OTP failed: ${error}`)
        setSendVerificationCodeError('Unknown error')
      }
    } finally {
      setLoading(false)
    }
  }, [sendPhoneVerificationCode, phoneNumber, isInvalidPhoneNumber])

  const onResendCodeButtonClick = () => {
    clearErrors()
    otpCodeInputRef.current?.clear()
    otpCodeInputRef.current?.focus()

    onboardingAnalytics.action('OTP resent')

    sendVerificationCodeAndWaitForVerification()
  }

  const onOTPCodeChange = useCallback(
    async (otp: string) => {
      clearErrors()

      if (otp.length == 6) {
        try {
          setLoading(true)
          const verifyOTPResponse = await verifyOTP(
            otp,
            phoneNumberValidationId,
            phoneNumber ?? '',
          )

          if (!verifyOTPResponse) {
            onboardingAnalytics.action('Verify OTP failed')

            setVerifyOTPFailed(true)
            return
          }

          onboardingAnalytics.action('Verify OTP success')

          // Update phone number
          const success = await updateUserPhoneNumber()
          if (success) {
            goForward()
          }
        } catch (error: any) {
          onboardingAnalytics.action(`Verify OTP failed: ${error}`)

          setVerifyOTPFailed(true)
        } finally {
          setLoading(false)
        }
      }
    },
    [verifyOTP, phoneNumberValidationId, phoneNumber, updateUserPhoneNumber],
  )

  const inputVerificationCodeComponent = () => {
    return (
      <S.InputVerificationCodeContainer>
        <OTPCodeInput
          autoFocus
          onChange={onOTPCodeChange}
          hasError={verifyOTPFailed}
          allowedCharacters="numeric"
          ref={otpCodeInputRef}
        />
        {loading ? (
          <S.SpinnerIcon
            boxSize={16}
            variant="glyphCircleThreeQuarters"
            style={{ marginTop: 36 }}
          />
        ) : (
          <S.ResendCodeContainer>
            {verifyOTPFailed && (
              <S.VerifyOTPError>Failed to verify phone number</S.VerifyOTPError>
            )}
            <S.ResendCodeButton
              onClick={onResendCodeButtonClick}
              className={cx({
                hasVerifyOTPError: verifyOTPFailed,
              })}
            >
              <strong>Resend Code</strong>
            </S.ResendCodeButton>
          </S.ResendCodeContainer>
        )}
      </S.InputVerificationCodeContainer>
    )
  }

  const onPhoneNumberInputChange = (phoneNumber: string) => {
    clearErrors()

    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      setIsInvalidPhoneNumber(true)
    } else {
      setIsInvalidPhoneNumber(false)
    }
    setPhoneNumber(phoneNumber)
  }

  const onChangePhoneNumberClicked = () => {
    onboardingAnalytics.action('Phone number changed')

    setPhoneVerificationState(PhoneVerificationState.INPUT_PHONE_NUMBER)
  }

  const isWaitingForVerification =
    phoneVerificationState === PhoneVerificationState.WAITING_FOR_VERIFICATION

  return (
    <S.IntroTextYourself>
      <S.Inner>
        <TransitionSlideContainer>
          <SlideIntro>
            <SlideTitle>Verify your number</SlideTitle>
            <SlideDescription>
              {isWaitingForVerification ? (
                <S.VerifyOTPDescription>
                  <S.VerifyOTPDescriptionText>
                    Enter the code sent to{' '}
                    <strong>
                      {formatDisplayPhoneNumber(phoneNumber ?? '')}
                    </strong>
                  </S.VerifyOTPDescriptionText>
                  <S.ChangePhoneNumberButton
                    onClick={onChangePhoneNumberClicked}
                  >
                    Change
                  </S.ChangePhoneNumberButton>
                </S.VerifyOTPDescription>
              ) : (
                `We'll send you a 6-digit verification code.`
              )}
            </SlideDescription>
            {!isWaitingForVerification && (
              <SlideButtonGroup>
                {!isEmpty(sendVerificationCodeError) && (
                  <S.SendVerificationCodeError>
                    Error: {sendVerificationCodeError}
                  </S.SendVerificationCodeError>
                )}
                <SlidePrimaryButton
                  onClick={() => sendVerificationCodeAndWaitForVerification()}
                  disabled={
                    isInvalidPhoneNumber || loading || isEmpty(phoneNumber)
                  }
                >
                  <strong>Send code</strong>
                  {loading && (
                    <S.SpinnerIcon
                      boxSize={16}
                      variant="glyphCircleThreeQuarters"
                    />
                  )}
                </SlidePrimaryButton>
              </SlideButtonGroup>
            )}
          </SlideIntro>
          {isWaitingForVerification ? (
            inputVerificationCodeComponent()
          ) : (
            <S.PhoneNumberInput
              autoFocus
              className={cx({
                isInvalidPhoneNumber,
              })}
              defaultCountry={'US'}
              flags={flags}
              id={phoneInputId}
              onChange={onPhoneNumberInputChange}
              placeholder={'Phone number'}
              required
              type="tel"
              value={phoneNumber}
            />
          )}
        </TransitionSlideContainer>
      </S.Inner>
    </S.IntroTextYourself>
  )
}

const S = {
  IntroTextYourself: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',

    ...(isMobile && {
      padding: '0 24px',
    }),
  })),
  Inner: styled.div(() => ({
    width: '100%',
    maxWidth: 768,
  })),
  Diagram: styled.div(() => ({
    position: 'relative',
    zIndex: 1,

    transition: 'height 0.8s ease, opacity 0.4s ease',
  })),
  TextYourselfWrapper: styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
  })),
  TextYourself: styled.img(() => ({})),
  PhoneNumberInput: styled(PhoneInput)(({ theme }) => ({
    '--PhoneInputCountrySelect-marginRight': '8px',

    '.PhoneInputInput': {
      flex: 1,
      boxSizing: 'border-box',
      height: 48,
      width: '100%',
      padding: '0 16px',
      border: `1px solid ${theme.colors.alpha.border.medium}`,
      borderRadius: 6,
      background: theme.colors.alpha.tone.extraWeak,

      '&:focus': {
        borderColor: theme.colors.alpha.border.strong,
      },

      '::placeholder': {
        color: theme.colors.text[500],
      },
    },

    '&.isInvalidPhoneNumber .PhoneInputInput': {
      borderWidth: 1,
      borderColor: theme.colors.red[700],
    },

    '.PhoneInputCountryIcon': {
      display: 'flex',
    },
  })),
  OTPInputBox: styled.input(({ theme }) => ({
    height: 48,
    flexShrink: 0,
    borderRadius: 8,
    border: `1px solid ${theme.colors.alpha.border.strong}`,
    background: theme.colors.alpha.tone.extraWeak,
  })),
  InputVerificationCodeContainer: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  })),
  ResendCodeContainer: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],

    strong: {
      fontWeight: 700,
    },
  })),
  ResendCodeButton: styled.button(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    // fontWeight: 700,
    marginTop: 36,
    color: theme.colors.text[900],
    cursor: 'pointer',

    '&.hasVerifyOTPError': {
      color: theme.colors.red[700],
    },
  })),
  InputVerificationFooterContainer: styled.div(({ theme }) => ({
    display: 'flex',
  })),
  SpinnerIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.gray[400],
    ...spinAnimationStyles({ animationDuration: '2s' }),
  })),
  VerifyOTPDescription: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,

    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[900],
    fontWeight: 400,
  })),
  VerifyOTPDescriptionText: styled.div(({ theme }) => ({
    whiteSpace: 'nowrap',
    strong: {
      fontWeight: 600,
    },
  })),
  ChangePhoneNumberButton: styled.button(({ theme }) => ({
    fontWeight: 700,
  })),
  VerifyOTPError: styled.span(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 400,
    marginRight: 8,
    color: theme.colors.red[700],
  })),
  SendVerificationCodeError: styled.div(({ theme }) => ({
    display: 'flex',
    padding: `10px 20px`,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,

    borderRadius: 8,
    border: `1px solid ${theme.colors.red[300]}`,
    background: theme.colors.red[100],
    textAlign: 'center',
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 400,
    color: theme.colors.red[700],
  })),
}
