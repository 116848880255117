import React from 'react'

import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { ListRecord } from '@eleventhlabs/capture-shared'
import { RouteType, getListId, useRouteFromLocation } from '../../common/routes'
import DetailsScreen from '../DetailsScreen/DetailsScreen'
import ListScreen from '../ListScreen/ListScreen'
import { WithModelStore, withModelStore } from '../enhancers/withData'
import { WithSelection, withSelection } from '../enhancers/withSelection'
import { withSideBar } from '../enhancers/withSideBar'
import { withSnackbar } from '../enhancers/withSnackbar'
import { WithUIContext, withUIContext } from '../enhancers/withUIContext'
import { ScreenProps } from '../types'

type WithListId = {
  listId?: string
}

type WithRecordId = {
  recordId?: string
}

type RecordScreenContainerProps = ScreenProps &
  RouteComponentProps &
  WithListId &
  WithRecordId

export type DetailsScreenContainerComponentProps = RecordScreenContainerProps &
  WithModelStore &
  WithUIContext &
  WithSelection

const RecordScreenContainer = (props: DetailsScreenContainerComponentProps) => {
  const { store } = props
  const { routeType, listId, recordId } = useRouteFromLocation()

  if (routeType === RouteType.List && listId) {
    return (
      <ListScreen
        {...props}
        routeType={RouteType.List}
        listId={getListId(listId)}
        title={(store.records[listId] as ListRecord)?.title}
        emoji={(store.records[listId] as ListRecord)?.emoji}
      />
    )
  }

  if (routeType === RouteType.Details && recordId) {
    return <DetailsScreen {...props} recordId={recordId} />
  }

  return null
}

export default withSnackbar(
  withSideBar(
    withUIContext(withModelStore(withSelection(RecordScreenContainer))),
  ),
)
