import React, { useCallback, useMemo } from 'react'

import Analytics from '../analytics/capture-analytics-actions'
import { useExecContext } from '../contexts/ExecContext'
import { BaseSelection } from '../libs/selection'
import { NoteHandlers, useNoteEventHandlers } from '../noteEventHandlers'
import { isMobile } from '../utils/env'
import {
  MiniBarEventHandlers,
  useMiniBarEventHandlers,
} from './miniBarEventHandlers'
import { onTagClick as handleTagClick } from './onTagClick'
import { onToggle as handleToggle } from './onToggle'
import { useRichTextEventHandlers } from './richTextEventHandlers'
import { ClickHTMLElementEvent, RecordItemHandlers } from './types'

export const useRecordItemEventHandlers = (
  selection: BaseSelection,
): [RecordItemHandlers, NoteHandlers, MiniBarEventHandlers] => {
  const { exec } = useExecContext()

  const expandOnFocus = useCallback(
    (ev, recordId: string) => {
      exec({
        type: 'RECORD_ITEMS_EXPAND',
        targetId: recordId,
        trigger: Analytics.RecordExpandedTrigger.UI_CLICK_RECORD_CONTENT,
      })
    },
    [exec],
  )

  const onTap = useCallback(
    (ev, id) => {
      exec({
        type: 'EXPAND_TO_FULLSCREEN',
        recordId: id,
        trigger: Analytics.RecordOpenedTrigger.MINI_ACTION_BAR,
        triggeredByKeyboard: false,
        triggeredByUI: true,
      })
    },
    [exec],
  )

  const onFocus = useCallback(
    (ev, id) => {
      if (isMobile) {
        return exec({
          type: 'EXPAND_TO_FULLSCREEN',
          recordId: id,
          trigger: Analytics.RecordOpenedTrigger.MINI_ACTION_BAR,
          triggeredByKeyboard: false,
          triggeredByUI: true,
        })
      } else return exec({ type: 'RECORD_FOCUSED', recordId: id })
    },
    [exec],
  )

  const onBlur = useCallback(
    (ev, id: string) => {
      return exec({ type: 'RECORD_BLURRED', recordId: id })
    },
    [exec],
  )

  const onClick = useCallback(
    (ev: MouseEvent, recordId: string) => {
      if (ev.shiftKey || ev.metaKey) return
      return exec({ type: 'RECORD_CLICKED', recordId })
    },
    [exec],
  )

  const onShowMoreClick = useCallback(
    (ev: MouseEvent, recordId: string) => {
      exec({
        type: 'RECORD_ITEMS_TOGGLE_IS_EXPANDED',
        targetId: recordId,
        trigger: Analytics.RecordExpandedTrigger.UI_CLICK_SHOW_MORE_BUTTON,
      })
    },
    [exec],
  )

  const onTagClick = useCallback(
    (ev, id, tag) => handleTagClick(ev, exec, id, tag),
    [exec],
  )

  const onMoveTo = useCallback(
    (ev, id) =>
      exec({
        type: 'OPEN_MOVE_TO_DIALOG',
        recordId: id,
        trigger: Analytics.MoveToPickerFlowStartedTrigger.RECORD_MINI_BAR,
      }),
    [exec],
  )

  const onMouseDown = useCallback(
    (event: ClickHTMLElementEvent, recordId) => {
      return exec({
        type: 'RECORD_MOUSE_DOWN',
        event,
        recordId,
        trigger: Analytics.RecordSelectedTrigger.CLICK_WITH_MODIFIER,
      })
    },
    [exec],
  )

  const onHandleClick = useCallback(
    (event, recordId) => {
      exec({ type: 'RECORD_HANDLE_CLICKED', event, recordId })
    },
    [exec],
  )

  const onToggle = useCallback(
    (_: any, id: string) => {
      if (isMobile) {
        _.preventDefault()
        _.stopPropagation()
      }
      handleToggle(_, exec, id)
    },
    [exec],
  )

  const renameFile = useCallback(
    (recordId: string, fileName: string) => {
      exec({ type: 'RECORD_RENAME_FILE', recordId, fileName })
    },
    [exec],
  )

  const _skipOccurrence = useCallback(
    (recordId: string) => {
      exec({ type: 'RECORD_SKIP_OCCURRENCE', recordId })
    },
    [exec],
  )

  const {
    onArrowLeftOrUpAtBoundary,
    onArrowRightOrDownAtBoundary,
    onBackspaceAtFirstPointWhenNotEmpty,
    onBackspaceWhenEmpty,
    onChange,
    onEnter,
    onEscape,
    onKeyDown,
    onPaste,
  } = useRichTextEventHandlers()

  const noteHandlers = useNoteEventHandlers()

  const miniBarEventHandlers = useMiniBarEventHandlers(selection)

  const onFileTitleClick = useCallback(
    (e: React.MouseEvent, recordId: string) => {
      if (isMobile) {
        e.preventDefault()
        e.stopPropagation()
      }

      if (e.metaKey || e.shiftKey) return
      exec({ type: 'FILE_RECORD_OPEN_SRC_IN_TAB', recordId })
    },
    [exec],
  )

  const onUrlTitleClick = useCallback(
    (e: React.MouseEvent, recordId: string) => {
      if (isMobile) {
        e.preventDefault()
        e.stopPropagation()
      }
      if (e.metaKey || e.shiftKey) return
      exec({ type: 'URL_RECORD_OPEN_SRC_IN_TAB', recordId })
    },
    [exec],
  )

  const recordHandlers = useMemo(
    () => ({
      expandOnFocus,
      onArrowLeftOrUpAtBoundary,
      onArrowRightOrDownAtBoundary,
      onBackspaceAtFirstPointWhenNotEmpty,
      onBackspaceWhenEmpty,
      onBlur,
      onChange,
      onClick,
      onEnter,
      onEscape,
      onFileTitleClick,
      onFocus,
      onHandleClick,
      onKeyDown,
      onMouseDown,
      onMoveTo,
      onPaste,
      onShowMoreClick,
      onTagClick,
      onToggle,
      onTap,
      onUrlTitleClick,
      renameFile,
      skipOccurrence: _skipOccurrence,
    }),
    [
      _skipOccurrence,
      expandOnFocus,
      onArrowLeftOrUpAtBoundary,
      onArrowRightOrDownAtBoundary,
      onBackspaceAtFirstPointWhenNotEmpty,
      onBackspaceWhenEmpty,
      onBlur,
      onChange,
      onClick,
      onFileTitleClick,
      onFocus,
      onHandleClick,
      onKeyDown,
      onMouseDown,
      onMoveTo,
      onPaste,
      onShowMoreClick,
      onTagClick,
      onToggle,
      onTap,
      onUrlTitleClick,
      renameFile,
    ],
  )

  return [recordHandlers, noteHandlers, miniBarEventHandlers]
}
