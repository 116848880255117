import { CSSInterpolation } from '@emotion/css'
import { css } from '@emotion/react'
import { Interpolation } from '@emotion/styled'

import { Theme } from '../theme'

type InterpolationWithTheme<Props = {}> = Interpolation<
  { theme: Theme } & Props
>

export const styledMixin = <Props = {}>(
  strings: TemplateStringsArray,
  ...interpolations: InterpolationWithTheme<Props>[]
): InterpolationWithTheme<Props> => {
  return (props) => {
    const cssInterpolations = interpolations.map((interpolation) => {
      if (typeof interpolation === 'function') {
        return interpolation(props)
      }
      return interpolation
    })

    return css(strings, ...(cssInterpolations as CSSInterpolation[]))
  }
}
