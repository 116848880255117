import { useCallback, useMemo } from 'react'

import {
  CreateNoteRecordPayload,
  CreateRecordPayload,
  MoveToExistingPlaceIndex,
  MoveToIndex,
  OnboardingTrack,
  RecordDict,
  RecordType,
  SortRecordsPayload,
  UpdateNoteRecordPayload,
  UpdateRecordPayload,
  UpdateTextRecordPayload,
  UpdateTicklerPayload,
  dateGroupFromIso,
  getRichTextLastUpdateVersionOrDefault,
  isNoteRecord,
  isTextRecord,
} from '@eleventhlabs/capture-shared'

import { API } from '../../api'
import { useGlobalLoggedInContext } from '../../contexts/GlobalLoggedInContext'
import { useStartOfTodayISO } from '../../hooks/useStartOfTodayISO'
import { CustomReplicache } from '../../replicache/types'
import { ModelStoreCommands } from './ModelStoreCommands'
import {
  addIdIfMissing,
  cloneToEnforceNoDAGs,
  enhanceCreateRecordPayload,
} from './utils'

export const useModelStoreCommands = (
  replicache: CustomReplicache | null,
  recordDict: RecordDict,
): ModelStoreCommands => {
  const { timezone, clientID } = useGlobalLoggedInContext()
  const tzOnClient = timezone !== null ? timezone : undefined

  const completeRecords = useCallback(
    (recordIds: string[]) => {
      replicache?.mutate.completeRecords({
        completedAt: Date.now(),
        executedAtOnClient: Date.now(),
        recordIds,
        tzOnClient,
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const createRecord = useCallback(
    (payload: CreateRecordPayload, index: MoveToExistingPlaceIndex) => {
      const enhancedPayload = enhanceCreateRecordPayload(payload)
      replicache?.mutate.createRecord({
        executedAtOnClient: Date.now(),
        index,
        payload: enhancedPayload,
        tzOnClient,
      })
      return enhancedPayload.id
    },
    [replicache?.mutate, tzOnClient],
  )

  const createNote = useCallback(
    (payload: CreateNoteRecordPayload) => {
      const enhancedPayload = enhanceCreateRecordPayload(payload)
      const enhancedPayloadWithType = {
        ...enhancedPayload,
        type: RecordType.Note as RecordType.Note,
      }
      replicache?.mutate.createRecord({
        executedAtOnClient: Date.now(),
        payload: enhancedPayloadWithType,
        tzOnClient,
      })
      return enhancedPayloadWithType.id
    },
    [replicache?.mutate, tzOnClient],
  )

  const deleteRecords = useCallback(
    (recordIds: string[]) => {
      replicache?.mutate.deleteRecords({
        executedAtOnClient: Date.now(),
        recordIds,
        tzOnClient,
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const softDeleteRecords = useCallback(
    (recordIds: string[]) => {
      replicache?.mutate.softDeleteRecords({
        recordIds,
        tzOnClient,
        softDeletedAt: Date.now(),
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const unSoftDeleteRecords = useCallback(
    (recordIds: string[]) => {
      replicache?.mutate.unSoftDeleteRecords({
        recordIds,
        tzOnClient,
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const deleteNotes = useCallback(
    (noteIds: string[]) => {
      replicache?.mutate.deleteRecords({
        executedAtOnClient: Date.now(),
        recordIds: noteIds,
        tzOnClient,
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const startOfTodayIso = useStartOfTodayISO().today
  const moveRecords = useCallback(
    (recordIds: string[], index: MoveToIndex) => {
      // If destination is a new list, ensure the payload has an id
      let newListId: string | undefined
      if (`payload` in index) {
        const payloadWithId = addIdIfMissing(index.payload)
        index.payload = payloadWithId
        newListId = payloadWithId.id
      }
      if (`isoDate` in index) {
        const dateGroup = dateGroupFromIso(startOfTodayIso, index.isoDate)
        replicache?.mutate.moveIntoDateGroup({
          dateGroup,
          executedAtOnClient: Date.now(),
          point: index.point,
          recordIds,
          tzOnClient,
        })
        return
      } else {
        replicache?.mutate.moveIntoParent({
          executedAtOnClient: Date.now(),
          index,
          recordIds,
          tzOnClient,
        })
      }
      return newListId
    },
    [replicache?.mutate, tzOnClient, startOfTodayIso],
  )

  const sortRecords = useCallback(
    (payload: SortRecordsPayload) => {
      replicache?.mutate.sortRecords({
        executedAtOnClient: Date.now(),
        payload,
        tzOnClient,
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const uncompleteRecords = useCallback(
    (recordIds: string[]) => {
      replicache?.mutate.uncompleteRecords({
        executedAtOnClient: Date.now(),
        recordIds,
        tzOnClient,
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const updateRecord = useCallback(
    (recordId: string, payload: UpdateRecordPayload) => {
      if (payload === undefined) return

      replicache?.mutate.updateProps({
        executedAtOnClient: Date.now(),
        tzOnClient,
        updates: [{ recordId, payload: cloneToEnforceNoDAGs(payload) }],
      })
    },
    [replicache?.mutate, tzOnClient, recordDict],
  )

  const updateRecordText = useCallback(
    (
      recordId: string,
      payload: UpdateTextRecordPayload | UpdateNoteRecordPayload,
    ) => {
      if (payload === undefined || !clientID) return
      if (payload.richText === undefined) return

      const record = recordDict[recordId]

      if (!isTextRecord(record) && !isNoteRecord(record)) return

      const oldRichTextVersion =
        record.richTextLastUpdateVersion ??
        getRichTextLastUpdateVersionOrDefault({ clientId: clientID })
      const oldRichTextVersionPart = Number(oldRichTextVersion.split(':')[1])

      const newRichTextVersionPart = oldRichTextVersionPart + 1
      const newRichTextVersion = getRichTextLastUpdateVersionOrDefault({
        clientId: clientID,
        richTextVersion: String(newRichTextVersionPart),
      })

      replicache?.mutate.updateRecordText({
        incomingRichTextLastUpdateVersion: newRichTextVersion,
        updates: [
          { recordId, richText: cloneToEnforceNoDAGs(payload.richText) },
        ],
      })
    },
    [replicache?.mutate, tzOnClient, recordDict],
  )

  const updateRecordDate = useCallback(
    (recordId: string, payload: UpdateTicklerPayload) => {
      replicache?.mutate.updateTicklers({
        executedAtOnClient: Date.now(),
        recordIds: [recordId],
        tickler: payload,
        tzOnClient,
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const updateNote = updateRecordText

  const setClientTimezone = useCallback(
    (clientID: string, timezone: string) => {
      if (clientID === null) return
      API.setClientTimezone(clientID, timezone)
    },
    [],
  )

  const skipOccurrence = useCallback(
    (recordId: string) => {
      replicache?.mutate.skipOccurrences({
        executedAtOnClient: Date.now(),
        recordIds: [recordId],
        tzOnClient,
      })
    },
    [replicache?.mutate, tzOnClient],
  )

  const userMarkOnboardingComplete = useCallback(
    (track: OnboardingTrack) => {
      replicache?.mutate.userMarkOnboardingComplete({
        track,
        executedAtOnClient: Date.now(),
      })
    },
    [replicache?.mutate],
  )

  const userSetOnboardingSavePoint = useCallback(
    (track: OnboardingTrack, slide: string) => {
      replicache?.mutate.userSetOnboardingSavePoint({
        executedAtOnClient: Date.now(),
        slide,
        track,
      })
    },
    [replicache?.mutate],
  )

  const userCompleteOnboardingSlides = useCallback(
    (track: OnboardingTrack, slides: string[]) => {
      replicache?.mutate.userCompleteOnboardingSlides({
        executedAtOnClient: Date.now(),
        slides,
        track,
      })
    },
    [replicache?.mutate],
  )

  const userUncompleteOnboardingSlides = useCallback(
    (track: OnboardingTrack, slides: string[]) => {
      replicache?.mutate.userUncompleteOnboardingSlides({
        slides,
        track,
      })
    },
    [replicache?.mutate],
  )

  const commands: ModelStoreCommands = useMemo(
    () => ({
      completeRecords,
      softDeleteRecords,
      unSoftDeleteRecords,
      createRecord,
      createNote,
      deleteRecords,
      deleteNotes,
      moveRecords,
      sortRecords,
      uncompleteRecords,
      updateRecord,
      updateRecordText,
      updateRecordDate,
      updateNote,
      setClientTimezone,
      skipOccurrence,
      userMarkOnboardingComplete,
      userSetOnboardingSavePoint,
      userCompleteOnboardingSlides,
      userUncompleteOnboardingSlides,
    }),
    [
      completeRecords,
      softDeleteRecords,
      unSoftDeleteRecords,
      createRecord,
      createNote,
      deleteRecords,
      deleteNotes,
      moveRecords,
      sortRecords,
      uncompleteRecords,
      updateRecord,
      updateRecordText,
      updateRecordDate,
      updateNote,
      setClientTimezone,
      skipOccurrence,
      userMarkOnboardingComplete,
      userSetOnboardingSavePoint,
    ],
  )
  return commands
}
