import { isEmpty } from 'lodash'
import React, { useState } from 'react'

import { EditorsManager } from '../../../common/EditorsManager'
import { useSelectionContext } from '../../../common/contexts/SelectionContext'
import { useUIContext } from '../../../common/contexts/UIContext'
import { useRouteFromLocation } from '../../../common/routes'
import { Button, styled } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'
import { useHeaderQuickActions } from '../hooks/useHeaderQuickActions'
import { useScreenActions } from '../hooks/useScreenActions'
import HeaderQuickActions from './HeaderQuickActions'
import MoreActionMenu from './MoreActionsMenu'

export interface HeaderRightProps {
  isFullscreen?: boolean
}

export const HeaderRight: React.FC<HeaderRightProps> = ({ isFullscreen }) => {
  const {
    showCompleted,
    setShowProfileActions,
    toggleShowProfileActions,
    showProfileActions,
  } = useUIContext()
  const { routeType, listId } = useRouteFromLocation()
  const { focusedRecordId } = useSelectionContext()

  const quickActions = useHeaderQuickActions(routeType, showCompleted)
  const screenActions = useScreenActions(routeType, listId)

  const isKeyboardOpen = !!focusedRecordId

  const closeMobileKeyboard = () => {
    focusedRecordId && EditorsManager.effects.blur(focusedRecordId)
  }

  const displayRightHeader = () => {
    let quickActionsComponent
    let moreActionsComponent
    let mobileFullscreenDoneButton

    if (isMobile && isFullscreen && isKeyboardOpen) {
      mobileFullscreenDoneButton = (
        <S.Done onClick={closeMobileKeyboard}>Done</S.Done>
      )
    } else if (!isFullscreen) {
      if (!isEmpty(quickActions)) {
        quickActionsComponent = <HeaderQuickActions actions={quickActions} />
      }

      if (!isEmpty(screenActions)) {
        moreActionsComponent = (
          <MoreActionMenu
            actions={screenActions}
            open={showProfileActions}
            setOpen={setShowProfileActions}
            toggleSetOpen={toggleShowProfileActions}
          />
        )
      }
    }

    return (
      <S.HeaderRight>
        {quickActionsComponent}
        {moreActionsComponent}
        {mobileFullscreenDoneButton}
      </S.HeaderRight>
    )
  }

  return displayRightHeader()
}

const S = {
  HeaderRight: styled.div({
    display: `flex`,
    alignItems: `center`,
    gap: 4,
    paddingRight: 8,
  }),
  Done: styled(Button)(({ theme }) => ({
    padding: `0px 5px 0px 5px`,
    borderRadius: 4,
    marginRight: 4,
  })),
}
