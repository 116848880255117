import { useCallback, useMemo, useState } from 'react'

import { useExecContext } from '../../common/contexts/ExecContext'
import { useModelStoreContext } from '../../common/contexts/ModelStoreContext'

export const useCollectionCommands = (listId: string | undefined) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)

  const { commands } = useModelStoreContext()
  const { exec } = useExecContext()
  const { updateRecord } = commands

  const removeItem = useCallback(
    (listId: string) => {
      exec({ type: 'REMOVE_LIST', listId })
    },
    [exec],
  )

  const openEmojiPicker = useCallback(() => {
    setIsEmojiPickerOpen(true)
  }, [])

  const closeEmojiPicker = useCallback(() => {
    setIsEmojiPickerOpen(false)
  }, [])

  const updateEmoji = useCallback(
    (listId: string, emoji: string) => {
      updateRecord(listId, { emoji })
    },
    [updateRecord],
  )

  const onSelectEmoji = useCallback(
    (emoji: { id?: string }) => {
      if (!listId) return
      if (emoji.id) updateEmoji(listId, emoji.id)
      closeEmojiPicker()
    },
    [listId, updateEmoji, closeEmojiPicker],
  )

  const handlers = useMemo(
    () => ({
      openEmojiPicker,
      closeEmojiPicker,
      isEmojiPickerOpen,
      updateEmoji,
      onSelectEmoji,
      removeItem,
    }),
    [
      openEmojiPicker,
      closeEmojiPicker,
      isEmojiPickerOpen,
      updateEmoji,
      onSelectEmoji,
      removeItem,
    ],
  )

  return handlers
}
