import {
  InboxRecordId,
  MoveToPlaceIndex,
  RichText,
} from '@eleventhlabs/capture-shared'
import { useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useExecContext } from '../contexts/ExecContext'
import { useModelStoreContext } from '../contexts/ModelStoreContext'
import { Env } from '../env'
import { useReplicacheContext } from '../replicache/ReplicacheContext'
import { createRecordPayloadFromRichText } from '../useExec/groups/default/actions/createRecord/factory/createRecordPayload'
import { isDesktop } from '../utils/env'
import Desktop from './desktop'
import { QUICK_CAPTURE_BROADCAST_CHANNEL_NAME } from './types'
import Analytics from '../../common/analytics/capture-analytics-actions'
import { getDestinationTypeFromMoveToPlaceIndex } from '../analytics/helpers'

export const useSubscribeToQuickCaptureMessageChannel = () => {
  const { exec } = useExecContext()
  const { userId } = useAuth()
  const { commands } = useModelStoreContext()
  const { isReady } = useReplicacheContext()

  const createRecordInInbox = async (richText: RichText) => {
    const payload = await createRecordPayloadFromRichText(richText)
    const moveToInboxIndex: MoveToPlaceIndex = {
      id: InboxRecordId,
      point: { at: `start` },
    }

    const recordId = commands.createRecord(payload, moveToInboxIndex)

    Analytics.recordCreated({
      destinationType: getDestinationTypeFromMoveToPlaceIndex(moveToInboxIndex),
      recordCreatedTrigger: Analytics.RecordCreatedTrigger.QUICK_CAPTURE,
      recordType: payload.type,
      recordId: recordId,
      activeScreen: null,
    })
  }

  useEffect(() => {
    const quickCaptureBroadcastChannel = new BroadcastChannel(
      QUICK_CAPTURE_BROADCAST_CHANNEL_NAME,
    )

    if (isReady && isDesktop && !Env.isSSR && userId) {
      Desktop.subscribeOnce(
        'subscribeToQuickCaptureMessageChannel',
        (reset) => {
          quickCaptureBroadcastChannel.onmessage = (
            event: MessageEvent<{ richText: RichText }>,
          ) => {
            createRecordInInbox(event.data.richText)
          }

          window.addEventListener('unload', () => {
            reset()
          })
        },
      )
    }

    return () => quickCaptureBroadcastChannel.close()
  }, [userId, exec, isReady])
}
