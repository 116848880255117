import { copyToClipboard, wrapMarkdownForCopy } from '../../../../clipboard'
import { BaseSelection } from '../../../../libs/selection'
import {
  getHtmlTextFromRecords,
  getMarkdownTextFromRecords,
  getRichTextEncodedTextFromRecords,
} from '../../../../records'
import { DataRecord } from '@eleventhlabs/capture-shared'

export const copy = (
  records: Record<string, DataRecord>,
  selection: BaseSelection,
  visibleItemIds: string[],
) => {
  const idsInOrder = [...selection.ids].sort(
    (a, b) => visibleItemIds.indexOf(a) - visibleItemIds.indexOf(b),
  )
  const selectedRecordsInOrder = idsInOrder.map((id) => records[id])
  const exportPayloadMarkdown = wrapMarkdownForCopy(
    getMarkdownTextFromRecords(selectedRecordsInOrder),
  )
  const exportPayloadHtml = getHtmlTextFromRecords(selectedRecordsInOrder)
  const exportPayloadRichText = getRichTextEncodedTextFromRecords(
    selectedRecordsInOrder,
  )
  copyToClipboard([
    exportPayloadMarkdown,
    exportPayloadHtml,
    exportPayloadRichText,
  ])
}
