import React from 'react'

import { Icon, styled } from '../../../common/stationary'

export interface ResultItemPostProps {
  isActive: boolean
}

const ResultItemPost = ({ isActive }: ResultItemPostProps) => {
  return isActive ? (
    <S.Content>
      <S.Icon boxSize={12} variant="glyphArrowRight8" />
    </S.Content>
  ) : null
}

export default ResultItemPost

const S = {
  Content: styled.div({
    paddingLeft: 12,
  }),
  Icon: styled(Icon)(({ theme }) => ({
    color: theme.colors.text[500],
  })),
}
