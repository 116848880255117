import { useEffect } from 'react'

import { useUIContext } from '../contexts/UIContext'

export const useIsMouseRecentlyActive = () => {
  useSetMouseActiveOnMouseMove()
  useSetMouseInactiveOnKeydown()
}

export const useSetMouseActiveOnMouseMove = () => {
  const { setIsMouseRecentlyActive } = useUIContext()
  useEffect(() => {
    const f = (): void => setIsMouseRecentlyActive(true)
    window.addEventListener(`mousemove`, f)
    return () => window.removeEventListener(`mousemove`, f)
  }, [setIsMouseRecentlyActive])
}

export const useSetMouseInactiveOnKeydown = () => {
  const { setIsMouseRecentlyActive } = useUIContext()
  useEffect(() => {
    const f = (event: KeyboardEvent) => {
      if (!event.metaKey && !event.shiftKey) setIsMouseRecentlyActive(false)
    }
    window.addEventListener(`keydown`, f)
    return () => window.removeEventListener(`keydown`, f)
  }, [setIsMouseRecentlyActive])
}
