import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import * as z from 'zod'

export const useLocalStorageDict = <T extends unknown>(
  keyPrefix: string,
  schema: z.Schema<T>,
  defaultValue: { [key: string]: T },
): [
  { [key: string]: T },
  React.Dispatch<React.SetStateAction<{ [key: string]: T }>>,
] => {
  // Load the value from localStorage
  const [value, setValue] = useState(() => ({
    ...defaultValue,
    ...localStorageGetDict(keyPrefix, schema),
  }))

  // When the value changes, update localStorage
  const prevValue = usePrevState(value)
  useEffect(() => {
    // Don't store defaultValues in localStorage.
    if (value === defaultValue) return
    for (const [id, state] of Object.entries(value)) {
      if (!isEqual(prevValue?.[id], state)) {
        localStorageDictSetById(keyPrefix, schema, id, state)
      }
    }
  }, [keyPrefix, schema, defaultValue, value, prevValue])
  return [value, setValue]
}

const localStorageGetDict = <T>(
  keyPrefix: string,
  schema: z.Schema<T>,
): { [key: string]: T } => {
  const dict: { [key: string]: T } = {}
  for (const key of keysWithPrefix(keyPrefix)) {
    const id = key.substring(keyPrefix.length + 1)
    try {
      const item = localStorage.getItem(key) as string
      const state = schema.parse(JSON.parse(item))
      dict[id] = state
    } catch (e) {
      console.error(`Malformed dict item in local storage: ${keyPrefix}:${id}`)
    }
  }
  return dict
}

const keyFromPrefixAndId = (keyPrefix: string, id: string) =>
  `${keyPrefix}:${id}`

const keysWithPrefix = (keyPrefix: string) =>
  Object.keys(localStorage).filter((k) => k.startsWith(keyPrefix))

const usePrevState = <T>(value: T) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const localStorageDictSetById = <T>(
  keyPrefix: string,
  schema: z.ZodType<T>,
  id: string,
  state: T,
) => {
  try {
    const key = keyFromPrefixAndId(keyPrefix, id)
    const parsedValue = schema.parse(state)
    const valueToStore = JSON.stringify(parsedValue)
    setItemAsync(key, valueToStore)
  } catch (e) {
    console.error(`Failed to persist in local storage: ${keyPrefix}:${id}.`)
  }
}

const setItemAsync = async (key: string, value: string) => {
  return Promise.resolve(localStorage.setItem(key, value))
}
