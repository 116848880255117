import React from 'react'

import { Icon, cx, styled } from '../../../common/stationary'
import { RECORDS } from '../../../common/utils/uiConstants'

export interface MoveToFooterProps {
  isSelected: boolean
  isMouseRecentlyActive: boolean
  handleNewCollectionClick: () => void
}

const MoveToFooter = ({
  isSelected,
  isMouseRecentlyActive,
  handleNewCollectionClick,
}: MoveToFooterProps) => {
  return (
    <S.MoveToFooter>
      <S.NewCollection
        className={cx({
          isSelected,
          isMouseRecentlyActive,
        })}
        onClick={handleNewCollectionClick}
      >
        <S.NewCollectionIcon>
          <S.PlusIcon boxSize={16} variant="glyphPlus" />
        </S.NewCollectionIcon>
        <S.NewCollectionText>
          <S.Title>Create new collection</S.Title>
          <S.Description>Move {RECORDS} to a new collection.</S.Description>
        </S.NewCollectionText>
        <S.NewCollectionShortcut>
          <S.Key>⌘</S.Key>
          <S.Key>Enter</S.Key>
        </S.NewCollectionShortcut>
      </S.NewCollection>
    </S.MoveToFooter>
  )
}

export default MoveToFooter

const S = {
  MoveToFooter: styled.div({
    display: `flex`,
    alignItems: `flex-start`,
    justifyContent: `flex-start`,
    flexDirection: `row`,
    height: 80,
    padding: 8,
  }),
  NewCollection: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `flex-start`,
    justifyContent: `flex-start`,
    flexDirection: `row`,
    flex: 1,
    height: `100%`,
    cursor: `pointer`,

    '&.isSelected': {
      background: theme.colors.alpha.tone.weak,
      borderRadius: 8,
    },

    '&.isMouseRecentlyActive:hover': {
      background: theme.colors.alpha.tone.weak,
      borderRadius: 8,
    },
  })),
  NewCollectionIcon: styled.div({
    display: `flex`,
    alignItems: `flex-start`,
    justifyContent: `flex-start`,
    flex: `0 0 44px`,
    padding: `12px 12px 0 16px`,
  }),
  PlusIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.text[900],
  })),
  NewCollectionText: styled.div({
    display: `flex`,
    flex: 1,
    alignItems: `flex-start`,
    justifyContent: `flex-start`,
    padding: `10px 0 0 0`,
    flexDirection: `column`,
  }),
  Title: styled.div(({ theme }) => ({
    display: `flex`,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 500,
    color: theme.colors.text[900],
  })),
  Description: styled.div(({ theme }) => ({
    display: `flex`,
    marginTop: 4,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 400,
    color: theme.colors.text[700],
  })),
  NewCollectionShortcut: styled.div({
    display: `flex`,
    alignItems: `flex-start`,
    justifyContent: `flex-end`,
    padding: `10px 12px 0 12px`,
  }),
  Key: styled.div(({ theme }) => ({
    display: `flex`,
    height: `20px`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    lineHeight: `20px`,
    fontSize: theme.text.publicSans['11.5:16'].fontSize,
    fontWeight: 500,
    padding: `0 6px`,
    color: theme.colors.text[700],
    background: theme.colors.alpha.tone.mediumWeak,
    border: `1px solid ${theme.colors.alpha.border.weak}`,
    boxSizing: `border-box`,
    borderRadius: 6,
    marginLeft: 4,
  })),
}
