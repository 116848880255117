import React, { useState } from 'react'

import { NoteRecord } from '@eleventhlabs/capture-shared'
import { useEditorAnalyticsHandlers } from '../../../common/analytics/helpers/hooks/useValidateEditorAnalytics'
import { ContentErrorBoundaryErrorProps } from '../../../common/errors/ContentErrorBoundary'
import { withErrorBoundary } from '../../../common/errors/enhancers/withErrorBoundary'
import { RichTextEditor } from '../../RichTextEditor'
import { NoteHandlers } from '../types'
import { isMobile } from '../../../common/utils/env'
import { useRouteFromLocation } from '../../../common/routes'

interface NoteContentProps extends NoteHandlers {
  note: NoteRecord
  className?: string
  style?: any
  autofocus?: boolean
  placeholder: string
  placeholderType?: string
  setPlaceholderType: (type?: string) => void
}

const NoteContent: React.FC<NoteContentProps> = ({
  note,
  onChange,
  onBlur,
  onPaste,
  onClick,
  onMouseDown,
  onFocus,
  onArrowRightOrDownAtBoundary,
  onArrowLeftOrUpAtBoundary,
  onBackspaceWhenEmpty,
  onKeyDown,
  autofocus,
  placeholder,
  placeholderType,
  setPlaceholderType,
}) => {
  const { validateEditorAnalytics, onListItemChange } =
    useEditorAnalyticsHandlers(note.id, note.type)

  const { recordId } = useRouteFromLocation()
  const isFullscreen = !!recordId

  return note.richText !== undefined ? (
    <RichTextEditor
      autofocus={autofocus}
      editable={isMobile && !isFullscreen ? false : true}
      id={note.id}
      onArrowLeftOrUpAtBoundary={onArrowLeftOrUpAtBoundary}
      onArrowRightOrDownAtBoundary={onArrowRightOrDownAtBoundary}
      onBackspaceWhenEmpty={onBackspaceWhenEmpty}
      onBlur={onBlur}
      onChangeDebounced={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onMouseDown={onMouseDown}
      onPaste={onPaste}
      placeholder={placeholder}
      placeholderType={placeholderType}
      setPlaceholderType={setPlaceholderType}
      validateAnalytics={validateEditorAnalytics}
      onListItemChange={onListItemChange}
      richText={note.richText.value}
    />
  ) : null
}

export default withErrorBoundary<
  NoteContentProps,
  ContentErrorBoundaryErrorProps
>(NoteContent)
