import { useMemo } from 'react'

import { RouteType } from '../../../common/routes'
import { MenuAction } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'
import { useShowCompletedAction } from '../actions/useShowCompletedAction'

export function useHeaderQuickActions(
  routeType: RouteType | undefined,
  showCompleted: boolean,
): MenuAction[] {
  const showCompletedAction = useShowCompletedAction(showCompleted)

  return useMemo(() => {
    const actions: MenuAction[] = []

    if (routeType !== RouteType.Details && !isMobile) {
      actions.push(showCompletedAction)
    }
    return actions
  }, [routeType, showCompleted, showCompletedAction])
}
