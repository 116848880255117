import React, { useCallback, useState } from 'react'

import { ReminderModal } from './ReminderModal'
import { useReminderInput } from './useReminderInput'
import { Icon, cx, styled } from '../../../../common/stationary'

export interface ReminderInputProps {
  value: number | undefined
  setReminder: (minutesBefore: number | null) => void
}

export const ReminderInput = React.forwardRef<
  HTMLDivElement,
  ReminderInputProps
>(({ value, setReminder }, ref) => {
  const [modalVisible, setModalVisible] = useState(false)

  const toggleModal = useCallback(() => setModalVisible((value) => !value), [])
  const closeModal = useCallback(() => setModalVisible(false), [])

  const clearTime = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      setReminder(null)
    },
    [setReminder],
  )

  const { inputValue, onKeyDown, onChange, onSubmit, isValidInput } =
    useReminderInput(value, setReminder, closeModal)

  const validInput = isValidInput(inputValue)

  return (
    <S.Container ref={ref}>
      <S.Tag
        className={cx({
          active: modalVisible,
        })}
        onMouseDown={toggleModal}
      >
        <S.TagIcon
          boxSize={16}
          variant="glyphBell"
          className={cx({
            isEmpty: value == undefined,
          })}
        />
        {value == undefined ? (
          <S.TagText
            className={cx({
              isEmpty: value == undefined,
            })}
          >
            No reminder
          </S.TagText>
        ) : null}
        {value != undefined ? (
          <S.ClearTime onMouseDown={clearTime}>
            <Icon boxSize={16} variant="glyphX" />
          </S.ClearTime>
        ) : null}
      </S.Tag>
      {modalVisible ? (
        <S.Modal
          value={inputValue}
          validInput={validInput}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onSubmit={onSubmit}
          close={closeModal}
        />
      ) : null}
    </S.Container>
  )
})

const S = {
  Container: styled.div({
    position: `relative`,
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    userSelect: `none`,
  }),
  Tag: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: `4px 6px`,
    marginRight: 4,
    height: 28,
    cursor: `pointer`,
    border: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    borderRadius: 6,
    userSelect: `none`,

    '&.active': {
      background: theme.colors.surface.lowest,
      border: `1px solid ${theme.colors.alpha.border.mediumStrong}`,
    },
  })),
  TagIcon: styled(Icon)(({ theme }) => ({
    display: `flex`,
    marginRight: 4,
    fontWeight: 500,
    color: theme.colors.text[900],

    '&.isEmpty': {
      color: theme.colors.text[700],
    },
  })),
  TagText: styled.div(({ theme }) => ({
    display: `flex`,
    fontWeight: 500,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    color: theme.colors.text[900],

    '&.isEmpty': {
      color: theme.colors.text[700],
    },
  })),
  ClearTime: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    paddingLeft: 4,
    marginLeft: 2,
    height: 12,
    borderLeft: `1px solid ${theme.colors.alpha.border.medium}`,
    color: theme.colors.text[900],
  })),
  Modal: styled(ReminderModal)({
    position: `absolute`,
    top: 34,
    left: -12,
  }),
}
