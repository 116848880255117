import { entries, flatten, keyBy, orderBy, uniq, zip } from 'lodash'
import { JSONValue, ReadTransaction, WriteTransaction } from 'replicache'

import {
  Frack,
  RCMutation,
  RecordWithChildren,
  RecordWithTickler,
  SortDirection,
  SortRecordsBy,
  SortRecordsByDate,
  SortRecordsByLocation,
  dateSortByValue,
  locSortValueForRecord,
  zonedIsoDateFromUtcAndTz,
} from '@eleventhlabs/capture-shared'

import { getOverdueRecordsForDate, getRecordsWithTicklerForDay } from '../query'
import {
  getRecordWithChildren,
  getRecords,
  getRecordsWithChildren,
  setRecordChildren,
  updateRecord,
} from './utils'

export const sortRecords = async (
  tx: WriteTransaction,
  { payload, executedAtOnClient, tzOnClient }: RCMutation.SortRecordsArgs,
) => {
  if (payload.type === SortRecordsBy.Location) {
    await sortRecordsByLocation(tx, payload, executedAtOnClient, tzOnClient)
  } else if (payload.type === SortRecordsBy.Date) {
    await sortRecordsByDate(tx, payload)
  }
}

const sortRecordsByLocation = async (
  tx: WriteTransaction,
  payload: SortRecordsByLocation,
  executedOnClientAt: number,
  tzOnClient: string | undefined,
) => {
  const dateGroups = payload.dates

  // Overdue
  const includeOverdue = dateGroups.includes(`overdue`)
  const todayIsoWhenExecutedOnClient = zonedIsoDateFromUtcAndTz(
    executedOnClientAt,
    tzOnClient,
  )
  let overdueRecords: RecordWithTickler[] = []
  const includeSoftDeleted = false
  if (includeOverdue) {
    overdueRecords = await getOverdueRecordsForDate(
      tx,
      todayIsoWhenExecutedOnClient,
      includeSoftDeleted,
    )
  }

  // Non overdue
  const isoDates = dateGroups.filter((d) => d !== `overdue`)
  const recordsForIsoDates = await Promise.all(
    isoDates.map(
      async (iso) =>
        await getRecordsWithTicklerForDay(tx, iso, includeSoftDeleted),
    ),
  )

  // All records
  const allRecords = [...overdueRecords, ...flatten(recordsForIsoDates)]

  // Parents
  const parentIds = uniq(allRecords)
    .filter((r) => r.parentId !== undefined)
    .map((r) => r.parentId as string)
  const parentList = await getRecordsWithChildren(tx, parentIds)
  const parents = keyBy(parentList, `id`)

  // Sort by parent
  const zipped = includeOverdue
    ? zip([`overdue`, ...isoDates], [overdueRecords, ...recordsForIsoDates])
    : zip(isoDates, recordsForIsoDates)
  for (const [dateGroup, recordsForGroup] of zipped) {
    if (dateGroup === undefined || recordsForGroup === undefined) continue
    await sortDateGroupByLoc(tx, dateGroup, recordsForGroup, payload, parents)
  }
}

const sortDateGroupByLoc = async (
  tx: WriteTransaction,
  dateGroup: string,
  recordsForGroup: RecordWithTickler[],
  payload: SortRecordsByLocation,
  parents: Record<string, RecordWithChildren>,
) => {
  if (dateGroup === undefined || recordsForGroup === undefined) return
  const inOrder = orderedByLocation(recordsForGroup, parents, payload)
  const fIndexes = Frack.fromArray(inOrder.map((r) => r.id))
  for (const [index, id] of entries(fIndexes)) {
    const update =
      dateGroup === `overdue` ? { overdueFIndex: index } : { dateFIndex: index }
    await updateRecord(tx, id, update as unknown as JSONValue)
  }
}

const orderedByLocation = (
  records: RecordWithTickler[],
  parents: Record<string, RecordWithChildren>,
  payload: SortRecordsByLocation,
) => {
  const toSortValue = (r: RecordWithTickler) =>
    locSortValueForRecord(r, parents[r.parentId as string])
  const orders: [boolean | `asc` | `desc`] = [
    payload.direction === SortDirection.ASC ? `asc` : `desc`,
  ]
  return orderBy(records, toSortValue, orders)
}

const sortRecordsByDate = async (
  tx: WriteTransaction,
  payload: SortRecordsByDate,
) => {
  const locationId = payload.locationId
  const location = await getRecordWithChildren(tx, locationId)
  const childIds = Frack.toArray(location.children)
  const sortedIds = await sortByDate(tx, childIds, payload)
  await setRecordChildren(tx, locationId, Frack.fromArray(sortedIds))
}

const sortByDate = async (
  tx: ReadTransaction,
  ids: string[],
  payload: SortRecordsByDate,
) => {
  const records = keyBy(await getRecords(tx, ids), `id`)
  const orders: [boolean | `asc` | `desc`] = [
    payload.direction === SortDirection.ASC ? `asc` : `desc`,
  ]
  return orderBy(ids, (id) => dateSortByValue(records[id]), orders)
}
