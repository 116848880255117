import { startOfToday } from 'date-fns'
import { useMemo } from 'react'

import { ModelStore } from '../../common/data/modelStore'
import { RecordGroupData } from '../../common/data/recordGroups'
import { createInboxRecordGroups } from '../../common/data/recordGroups/selectors/inbox/data'
import { createListRecordGroups } from '../../common/data/recordGroups/selectors/list'
import { createTrashRecordGroups } from '../../common/data/recordGroups/selectors/trash'
import { createTodayRecordGroupsForDate } from '../../common/data/recordGroups/selectors/today'
import { RouteType } from '../../common/routes'

export const useRecordGroupData = (
  data: ModelStore,
  routeType: RouteType,
  showCompleted: boolean,
  showNextFourteenDays: boolean,
  date?: string,
  listId?: string,
  futureDates: string[] = [],
): RecordGroupData[] => {
  const today = startOfToday()
  return useMemo(() => {
    let groups: RecordGroupData[] = []
    try {
      switch (routeType) {
        case RouteType.Today:
          groups = createTodayRecordGroupsForDate(
            data,
            today,
            showCompleted,
            showNextFourteenDays,
          )
          break
        case RouteType.List:
          groups = createListRecordGroups(data, listId, showCompleted)
          break
        case RouteType.Trash:
          groups = createTrashRecordGroups(
            data.records,
            data.softDeletedRecords,
          )
          break
        case RouteType.Inbox:
          groups = createInboxRecordGroups(data, showCompleted)
          break
      }
    } catch (e) {
      console.warn(e)
    }
    return groups
  }, [
    data,
    date,
    futureDates,
    listId,
    routeType,
    showCompleted,
    showNextFourteenDays,
    today,
  ])
}
