import { useCallback } from 'react'
import Analytics, {
  TargetRecordType,
} from '../../../common/analytics/capture-analytics-actions'
import { RecordItemTargetType } from '../../../common/recordItem'
import { blurActiveElement } from '../../../common/utils'

interface UseCaptureButtonsEventHandlersArgs {
  createRecord: (
    type:
      | RecordItemTargetType.Text
      | RecordItemTargetType.Bookmark
      | RecordItemTargetType.Page,
  ) => void
}

interface CaptureButtonsEventHandlers {
  onPrimaryClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  onPageClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onBookmarkClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  onFileClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const useCaptureButtonsEventHandlers = ({
  createRecord,
}: UseCaptureButtonsEventHandlersArgs): CaptureButtonsEventHandlers => {
  const onPrimaryClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      blurActiveElement()
      event.preventDefault()
      createRecord(RecordItemTargetType.Text)

      Analytics.captureButtonClicked({
        targetRecordType: TargetRecordType.TEXT,
      })
    },
    [createRecord],
  )

  const onPageClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      blurActiveElement()
      event.preventDefault()
      createRecord(RecordItemTargetType.Page)

      Analytics.captureButtonClicked({
        targetRecordType: TargetRecordType.PAGE,
      })
    },
    [createRecord],
  )

  const onBookmarkClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      blurActiveElement()
      event.preventDefault()
      createRecord(RecordItemTargetType.Bookmark)

      Analytics.captureButtonClicked({
        targetRecordType: TargetRecordType.URL,
      })
    },
    [createRecord],
  )

  const onFileClick = useCallback(() => {
    blurActiveElement()
    Analytics.captureButtonClicked({
      targetRecordType: TargetRecordType.FILE,
    })
  }, [])

  return {
    onPrimaryClick,
    onPageClick,
    onBookmarkClick,
    onFileClick,
  }
}
