import { DeleteableRecord, RecordDict } from '@eleventhlabs/capture-shared'
import { RecordGroupData, RecordGroupType } from '../../types'
import { createRecordGroupData } from '../common'
import { getRecordForViewType } from '../getRecordForViewType'
import { RecordViewType } from '../RecordViewType'

export const TrashRecordGroupId = `Trash`

export const createTrashRecordGroups = (
  records: RecordDict,
  softDeletedRecords: DeleteableRecord[],
): RecordGroupData[] => {
  const groups: RecordGroupData[] = []

  const softDeletedRecordItems = softDeletedRecords.map((r) =>
    getRecordForViewType(records, r.id, RecordViewType.DateGroup),
  )

  const trashRecordGroup = createRecordGroupData(
    softDeletedRecordItems,
    RecordGroupType.Trash,
    `Trash`,
  )

  groups.push(trashRecordGroup)

  return groups
}
