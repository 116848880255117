import { last } from 'lodash'

import { EditorsManager } from '../../../../EditorsManager'
import { DataRecord, isNoteableRecord } from '@eleventhlabs/capture-shared'

export const recordArrowLeftOrUpAtBoundary = (
  recordId: string,
  prevRecord: DataRecord | undefined,
  focusPrevRecord: (recordId: string) => void,
  shouldDisplayNotesForRecordId: (id: string) => boolean,
): boolean => {
  if (prevRecord) {
    EditorsManager.effects.blur(recordId)
    if (
      isNoteableRecord(prevRecord) &&
      shouldDisplayNotesForRecordId(prevRecord.id)
    )
      EditorsManager.effects.focus({
        id: last(prevRecord.notes) ?? prevRecord.id,
        position: `end`,
      })
    else focusPrevRecord(recordId)
    return true
  }
  return false
}
