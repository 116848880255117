import React from 'react'
import { followCursor } from 'tippy.js'

import { ConnectDragSource } from 'react-dnd'
import { emptyFn } from '../../../common/utils'
import {
  cx,
  extractRGB,
  styled,
  useTheme,
  withTooltip,
} from '../../../common/stationary'

import dragHandleGripSrc from '../../../assets/texture/texture-drag-handle-grip@2x.png'
import { useReactDnDContext } from '../../../common/contexts/ReactDnDContext'

interface RecordHandleProps {
  className?: string
  onHandleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  drag?: ConnectDragSource
  isSticky?: boolean
}

export const RecordHandle: React.FC<RecordHandleProps> = ({
  className,
  onHandleClick = emptyFn,
  drag,
  isSticky,
}) => {
  const theme = useTheme()
  const { setIsDragIntent } = useReactDnDContext()
  return (
    <S.RecordHandle
      className={cx(className, { isSticky })}
      onClick={onHandleClick}
      onMouseDown={() => setIsDragIntent(true)}
      onMouseUp={() => setIsDragIntent(false)}
      ref={drag}
    >
      <S.Inner
        tooltip={[
          {
            title: (
              <>
                Drag <span className="weak">to move</span>
              </>
            ),
          },
          {
            title: (
              <>
                Click <span className="weak">to select</span>
              </>
            ),
          },
        ]}
        tippyProps={{
          offset: [0, 8],
          followCursor: true,
          plugins: [followCursor],
        }}
      >
        <S.HandleGlyph
          style={{
            filter: theme.name === 'StationaryDark' ? 'invert(1)' : undefined,
          }}
        ></S.HandleGlyph>
      </S.Inner>
    </S.RecordHandle>
  )
}

const Dimensions = {
  RecordHandleWidth: 20,
  HandleGlyphWidth: 8,
  HandleGlyphMarginVertical: 8,
  HandleGlyphMarginHorizontal: 6, // (RecordHandleWidth - HandleGlyphWidth) / 2
}

const S = {
  RecordHandle: styled.div(({ theme }) => ({
    position: 'relative',
    width: Dimensions.RecordHandleWidth,
    height: `100%`,
    overflow: 'hidden',
    cursor: `grab`,
    opacity: 0.2,
    transition: 'opacity 0.1s ease',

    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: Dimensions.HandleGlyphMarginHorizontal,
      right: Dimensions.HandleGlyphMarginHorizontal,
      height: Dimensions.HandleGlyphMarginVertical,
      background: `linear-gradient(
        rgba(${extractRGB(theme.colors.surface.lower)} / 1),
        rgba(${extractRGB(theme.colors.surface.lower)} / 0))`,
    },
    ':after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: Dimensions.HandleGlyphMarginHorizontal,
      right: Dimensions.HandleGlyphMarginHorizontal,
      height: Dimensions.HandleGlyphMarginVertical,
      background: `linear-gradient(
        rgba(${extractRGB(theme.colors.surface.lower)} / 0),
        rgba(${extractRGB(theme.colors.surface.lower)} / 1))`,
    },

    ':hover': {
      opacity: 0.3,
    },
    ':active': {
      opacity: 0.45,
    },
    // Used primiarly with RecordItemPreview
    '&.isSticky': {
      position: `absolute`,
      top: 0,
      bottom: 0,
      left: -Dimensions.RecordHandleWidth,
      padding: 0,
      opacity: 0.45,
    },
  })),
  Inner: styled(withTooltip('div'))(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    position: 'relative',
    height: '100%',
    width: '100%',
    padding: `${Dimensions.HandleGlyphMarginVertical}px 0 0`,

    ':before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 24,
      left: Dimensions.HandleGlyphMarginHorizontal,
      right: Dimensions.HandleGlyphMarginHorizontal,
      height: 24,
      background: `linear-gradient(
        rgba(${extractRGB(theme.colors.surface.lower)} / 0),
        rgba(${extractRGB(theme.colors.surface.lower)} / 0.6))`,
    },
    ':after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 48,
      bottom: 0,
      left: Dimensions.HandleGlyphMarginHorizontal,
      right: Dimensions.HandleGlyphMarginHorizontal,
      background: `rgba(${extractRGB(theme.colors.surface.lower)} / 0.6)`,
    },
  })),
  HandleGlyph: styled.div(() => ({
    display: `flex`,
    height: `100%`,
    width: Dimensions.HandleGlyphWidth,
    backgroundImage: `url("${dragHandleGripSrc}")`,
    backgroundSize: `${Dimensions.HandleGlyphWidth}px`,
    backgroundRepeat: 'repeat-y',
  })),
}
