import { flatMap, map } from 'lodash'
import { useEffect, useMemo } from 'react'

import { useSelectionContext } from '../../contexts/SelectionContext'
import { RecordGroupData } from '../../data/recordGroups'
import { subscribeToTriggerEvent } from '../../events'
import { findNearestValidId } from '../../libs/selection'

export const useKeepDefaultIdValid = (recordGroups: RecordGroupData[]) => {
  const {
    selection: { setDefaultId },
  } = useSelectionContext()

  const recordIds = useMemo(
    () => flatMap(recordGroups, (group) => map(group.items, (item) => item.id)),
    [recordGroups],
  )

  useEffect(() => {
    return subscribeToTriggerEvent(({ payload }) => {
      if (payload.target === `update-default`) {
        const nearestId = findNearestValidId(recordIds, payload.invalidIds)
        setDefaultId(nearestId)
        return true
      }
      return false
    })
  }, [recordIds, setDefaultId])
}
