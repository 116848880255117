import React from 'react'
import { isMobile } from '../../../common/utils/env'

import checkIconSource from '../../../assets/onboarding/success-check-icon.png'
import { safeNavigate } from '../../../common/navigate'
import { RouteType, RoutesPath } from '../../../common/routes'
import { AuthStyles as S } from '../../LoginScreen/AuthStyles'
import AuthLogo from '../../LoginScreen/components/AuthLogo'

const ChangePasswordSuccess: React.FC = ({}) => {
  return (
    <S.AuthRelatedMessage>
      <S.AuthHeader>
        <AuthLogo
          href={!isMobile ? '/' : undefined}
          secondaryIconSrc={checkIconSource}
        />
        <S.AuthTitle>
          <strong>Password changed successfully!</strong>
        </S.AuthTitle>
      </S.AuthHeader>
      <S.AuthBody>
        <S.ActionHint>
          <strong>Feel free to close this window </strong> or click the button
          below to return to Capture.
        </S.ActionHint>
        <S.GoToLogin
          onClick={() => {
            safeNavigate(RoutesPath[RouteType.Inbox])
          }}
        >
          Back to Capture
        </S.GoToLogin>
      </S.AuthBody>
    </S.AuthRelatedMessage>
  )
}

export default ChangePasswordSuccess
