import { useCallback } from 'react'
import { useReactDnDContext } from '../../contexts/ReactDnDContext'

export enum DropIndicatorPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

type GetDropIndicatorPositionFunc = (index: number) => DropIndicatorPosition

export const useGetDropIndicatorPosition = (): GetDropIndicatorPositionFunc => {
  const dragState = useReactDnDContext()

  const getDropIndicatorPosition = useCallback(
    (index: number) => {
      if (
        dragState.hoverIndex === index &&
        dragState.insertIndex === `before`
      ) {
        return DropIndicatorPosition.TOP
      } else {
        return DropIndicatorPosition.BOTTOM
      }
    },
    [dragState.hoverIndex, dragState.insertIndex],
  )

  return getDropIndicatorPosition
}
