import { styled } from '../../../../../common/stationary'

const SlideModalOverlay = styled.div(({ theme }) => ({
  position: 'absolute',
  background: theme.colors.alpha.shade.strong,
  height: `100%`,
  width: `100%`,
  top: 0,
  bottom: 0,
  zIndex: 1000,
  visibility: 'hidden',
  opacity: 0,

  transition: `opacity 0.1s ease`,

  '&.isVisible': {
    visibility: 'visible',
    opacity: 1,
  },
}))

export default SlideModalOverlay
