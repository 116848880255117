import { decode } from 'html-entities'
import React from 'react'

import { cx, styled } from '../../../../../common/stationary'

interface URLTitleProps {
  title?: string
  url?: string
  loading?: boolean
  onClick?: (e: React.MouseEvent) => void
}

export const URLTitle: React.FC<URLTitleProps> = ({
  title,
  url,
  loading,
  onClick,
}) => {
  return !loading ? (
    <S.Text href={url} onClick={onClick} className={cx({ validUrl: !!url })}>
      {decode(title ?? url)}
    </S.Text>
  ) : (
    <S.Empty href={url} onClick={onClick} />
  )
}

const S = {
  Text: styled.a(({ theme }) => ({
    display: `block`,
    fontWeight: `bold`,
    ...theme.text.publicSans['33:40'],
    color: theme.colors.text[900],
    overflow: `hidden`,
    whiteSpace: `nowrap`,
    textOverflow: `ellipsis`,
    textDecoration: `underline`,
    textDecorationColor: theme.colors.alpha.border.mediumStrong,
    textDecorationThickness: `2px`,

    '&.validUrl': {
      cursor: `pointer`,
    },
  })),
  Empty: styled.a(({ theme }) => ({
    display: `flex`,
    flex: `0 0 160px`,
    height: 16,
    background: theme.colors.surface.lowest,
    borderRadius: 6,
  })),
}
