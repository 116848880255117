import React from 'react'

import { Breadcrumb } from './types'
import HeaderBreadcrumb from './breadcrumbs/HeaderBreadcrumb'
import HeaderBreadCrumbErrorBoundary from './breadcrumbs/HeaderBreadcrumbErrorBoundary'
import { styled } from '../../../common/stationary'

export interface HeaderBreadcrumbsProps {
  className?: string
  breadcrumbs: Breadcrumb[]
}

const HeaderBreadcrumbs: React.FC<HeaderBreadcrumbsProps> = ({
  className,
  breadcrumbs,
}: HeaderBreadcrumbsProps) => {
  return (
    <S.HeaderBreadcrumbs className={className}>
      {breadcrumbs.map((breadcrumb, index) => {
        const notLastElement = index < breadcrumbs.length - 1
        return (
          <HeaderBreadcrumb
            notLastElement={notLastElement}
            key={`breadcrumb-${index}`}
            breadcrumb={breadcrumb}
            error={{
              fallbackComponent: HeaderBreadCrumbErrorBoundary,
            }}
          />
        )
      })}
    </S.HeaderBreadcrumbs>
  )
}

export default HeaderBreadcrumbs

export const HeaderBreadcrumbsHeight = 48

const S = {
  HeaderBreadcrumbs: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    whiteSpace: `nowrap`,
    userSelect: `none`,
    height: `40px`,
    paddingLeft: 12,
  }),
}
