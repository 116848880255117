import { RefObject, useEffect, useLayoutEffect } from 'react'
import { RecordsManager } from '.'

export const useRegisterRecordRef = (
  id: string,
  ref: RefObject<HTMLDivElement>,
) => {
  useEffect(() => {
    RecordsManager.register(id, ref)

    return () => {
      RecordsManager.unregister(id)
    }
  }, [])
}
