import { useCallback } from 'react'
import {
  ActiveScreen,
  PermanentlyDeleteFlowStartedTriggerValueType,
  permanentlyDeleteFlowStarted,
} from '../../../analytics/capture-analytics-actions'
import { useSelectionContext } from '../../../contexts/SelectionContext'
import { ModalName } from '../modalConfig'
import { useModal } from './useModal'

export const useOpenConfirmDeleteRecordsModal = () => {
  const { selection } = useSelectionContext()
  const { openModal, closeModal } = useModal()

  return useCallback(
    ({
      recordId,
      trigger,
    }: {
      recordId?: string
      trigger: PermanentlyDeleteFlowStartedTriggerValueType
    }) => {
      let selectionIds = selection.ids

      // If no record passed and no selection, don't open the dialog
      if (recordId === undefined && selection.ids.length === 0) return
      // If recordId is not in the selection, set the selection to the recordId
      if (recordId !== undefined && !selection.ids.includes(recordId)) {
        selectionIds = [recordId]
        selection.set(selectionIds)
      }
      // At this point, guaranteed there are selected records selection values

      openModal(ModalName.CONFIRM_DELETE_RECORDS_MODAL)

      permanentlyDeleteFlowStarted({
        activeScreen: ActiveScreen.TRASH,
        permanentlyDeleteFlowStartedTrigger: trigger,
      })
    },
    [selection, closeModal, openModal],
  )
}
