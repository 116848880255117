import React from 'react'

import { styled } from '../../../../common/stationary'

export interface ResultItemContentGroupProps {
  content: string
}

const ResultItemContentGroup = ({ content }: ResultItemContentGroupProps) => {
  return <S.Content>{content}</S.Content>
}

export default ResultItemContentGroup

const S = {
  Content: styled.div(({ theme }) => ({
    display: `block`,
    flex: 1,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    fontWeight: `bold`,
    userSelect: `none`,
    paddingLeft: `22px`,
    paddingRight: `5px`,
    ...theme.text.publicSans['11.5:16'],
    lineHeight: `16px`,
    textTransform: `uppercase`,
    letterSpacing: `0.08em`,
    color: theme.colors.text[500],

    textOverflow: `ellipsis`,
    overflowX: `hidden`,
    whiteSpace: `nowrap`,
  })),
}
