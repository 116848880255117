import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'

import { RecordType, UpdateRecordPayload } from '@eleventhlabs/capture-shared'

import { useActiveScreen } from '../analytics/capture-analytics-web'
import { useExecContext } from '../contexts/ExecContext'
import { RecordItemData } from '../data/recordGroups'
import { BaseSelection } from '../libs/selection'
import { NoteHandlers } from '../noteEventHandlers'
import { useNoteEventHandlersForFullscreen } from '../noteEventHandlers/useNoteEventHandlersForFullscreen'
import { onTagClick as handleTagClick } from './onTagClick'
import {
  PropertiesListEventHandlers,
  usePropertiesListEventHandlersForFullscreen,
} from './propertiesListEventHandlers'
import { useRichTextEventHandlersForFullscreen } from './richTextEventHandlers/useRichTextEventHandlersForFullscreen'
import { ClickHTMLElementEvent, RecordItemHandlers } from './types'

export const useFullScreenRecordEventHandlers = (
  record: RecordItemData,
  selection: BaseSelection,
  updateRecord: (recordId: string, payload: UpdateRecordPayload) => void,
  skipOccurrence: (recordId: string) => void,
): [RecordItemHandlers, NoteHandlers, PropertiesListEventHandlers] => {
  const { exec } = useExecContext()
  const activeScreen = useActiveScreen()

  const onFocus = useCallback(
    (ev, id) => exec({ type: 'RECORD_FOCUSED', recordId: id }),
    [exec],
  )

  const onBlur = useCallback(
    (ev, id: string) => exec({ type: 'RECORD_BLURRED', recordId: id }),
    [exec],
  )

  const onClick = useCallback(
    (ev: MouseEvent, id: string) => {
      if (ev.shiftKey || ev.metaKey) return
      // if it's a link, open a new tab
      if (
        // TODO: we need to securize access to S3 buckets
        (record.type === RecordType.URL || record.type === RecordType.File) &&
        record.url
      )
        window.open(record.url, `_blank`)
    },
    [record, activeScreen],
  )

  const onMouseDown = useCallback((ev: ClickHTMLElementEvent, id) => {
    // do nothing
  }, [])

  const renameFile = useCallback(
    (id: string, fileName: string) => {
      if (record.type !== RecordType.File) return
      const fileMetadata = record?.fileMetadata
      if (isEmpty(fileMetadata)) return
      updateRecord(id, { fileMetadata: { ...fileMetadata, name: fileName } })
    },
    [record, updateRecord],
  )

  const _skipOccurrence = useCallback(
    (recordId: string) => {
      skipOccurrence(recordId)
    },
    [skipOccurrence],
  )

  const onTagClick = useCallback(
    (ev, id, tag) => handleTagClick(ev, exec, id, tag),
    [exec],
  )

  const richTextHandlers = useRichTextEventHandlersForFullscreen(record)

  const noteHandlers = useNoteEventHandlersForFullscreen(record, selection)

  const propertiesListEventHandlers =
    usePropertiesListEventHandlersForFullscreen()

  const recordHandlers = useMemo(
    () => ({
      onBlur,
      onClick,
      onFocus,
      onTagClick,
      onMouseDown,
      renameFile,
      skipOccurrence: _skipOccurrence,
      ...richTextHandlers,
    }),
    [
      onBlur,
      onClick,
      onFocus,
      onTagClick,
      onMouseDown,
      renameFile,
      _skipOccurrence,
      richTextHandlers,
    ],
  )

  return [recordHandlers, noteHandlers, propertiesListEventHandlers]
}
