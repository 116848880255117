import React, { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { parseISO } from 'date-fns'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { RouteType, getMetadataForRoute } from '../../../common/routes'
import {
  MenuAction,
  screenHeaderStyles,
  styled,
  useTheme,
} from '../../../common/stationary'
import { dateToShortTitle, todayPageDateToTitle } from '../../../common/utils'
import { isMobile } from '../../../common/utils/env'
import MoreActionMenu from '../../../components/Header/components/MoreActionsMenu'
import { RouteIcon } from '../../../components/RouteIcon'
import { useTodayControls } from '../hooks/useTodayControls'
import TodayTitle from './TodayTitle'
import CaptureButtons from '../../../components/CaptureButtons'
import { useUIContext } from '../../../common/contexts/UIContext'
import { RecordItemTargetType } from '../../../common/recordItem'
import { useExec } from '../../../common/useExec'

export interface TodayDay {
  date: string
  count: number
}

export interface TodayWeek {
  days: TodayDay[]
}

export interface TodayHeaderProps {
  id: string
  showNextFourteenDays: boolean
  toggleShowNextFourteenDays: () => void
}

const TodayHeader = React.forwardRef<HTMLDivElement, TodayHeaderProps>(
  ({ showNextFourteenDays, toggleShowNextFourteenDays }, ref) => {
    const theme = useTheme()
    const { store } = useModelStoreContext()
    const metadata = getMetadataForRoute({ routeType: RouteType.Today }, theme)

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const { activeDate } = useTodayControls(store)
    const titleDate = parseISO(activeDate)

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen)
    }

    const toggleMenuClosedWithValueChange = () => {
      setIsMenuOpen(false)
      toggleShowNextFourteenDays()
    }

    const action: MenuAction[] = [
      {
        type: `menuItem`,
        menuItemProps: {
          switchProps: {
            checked: showNextFourteenDays,
            onChange: toggleMenuClosedWithValueChange,
          },
          title: 'Show next 14 days',
        },
      },
    ]

    const exec = useExec()
    const { isMouseRecentlyActive } = useUIContext()

    const captureButtonsOnFileSelected = useCallback(
      (files: File[]) => {
        exec({
          type: 'CREATE_FILE_RECORD_AT_FIRST_POSITION',
          trigger: 'Capture Button Bar',
          files: files,
        })
      },
      [exec],
    )

    const captureButtonCreateRecord = useCallback(
      (
        type:
          | RecordItemTargetType.Text
          | RecordItemTargetType.Bookmark
          | RecordItemTargetType.Page,
      ) => {
        exec({
          type: 'CREATE_RECORD_AT_FIRST_POSITION',
          recordId: uuidv4(),
          trigger: 'Capture Button Bar',
          targetType: type,
          openRecordInFullScreen: type == RecordItemTargetType.Page,
        })
      },
      [exec],
    )

    return metadata ? (
      <S.TodayHeader ref={ref}>
        <S.Content>
          <S.Icon>
            <RouteIcon boxSize={32} routeType={RouteType.Today} />
          </S.Icon>
          <TodayTitle
            title={todayPageDateToTitle(titleDate) ?? ``}
            dayTitle={isMobile ? '' : dateToShortTitle(titleDate)}
          />
          <S.Filler />
          <MoreActionMenu
            actions={action}
            open={isMenuOpen}
            setOpen={setIsMenuOpen}
            toggleSetOpen={toggleMenu}
          />
        </S.Content>
        {!isMobile && (
          <S.CaptureButtons
            isSecondaryButtonTextHidden={false}
            isMouseActive={isMouseRecentlyActive}
            onFileSelected={captureButtonsOnFileSelected}
            createRecord={captureButtonCreateRecord}
          />
        )}
      </S.TodayHeader>
    ) : null
  },
)

export default TodayHeader

export const TodayHeaderHeight = 48

const S = {
  TodayHeader: styled.div({
    ...screenHeaderStyles,
  }),
  Content: styled.div({
    display: `flex`,
    flex: `0 0 64px`,
    width: `100%`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    margin: `24px 0 8px 0`,
  }),
  Icon: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginRight: 12,
    userSelect: `none`,
    outline: `none`,
    height: 28,
    width: 28,
  }),
  Filler: styled.div({
    flex: 1,
  }),
  Switch: styled.label(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    padding: '8px 12px',
    borderRadius: 8,
    ...theme.text.publicSans['13.5:20'],
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',

    '&:hover': {
      background: theme.colors.alpha.tone.weak,
    },
    '&:active': {
      background: theme.colors.alpha.tone.mediumWeak,
    },
  })),
  TodayButton: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    fontWeight: 500,
    whiteSpace: `nowrap`,
    color: theme.colors.gray[900],
    padding: `3px 9px`,
    boxSizing: `border-box`,
    borderRadius: 8,
    margin: `0 2px`,
    border: `1px solid ${theme.colors.alpha.border.weak}`,
    cursor: `pointer`,

    '&.disabled': {
      color: theme.colors.gray[700],
      cursor: `default`,
    },

    '&:hover:not(.disabled)': {
      background: theme.colors.alpha.tone.weak,
    },
  })),
  CaptureButtons: styled(CaptureButtons)`
    margin-bottom: 0;
  `,
}
