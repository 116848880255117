import { isEmpty, last } from 'lodash'

import { EditorsManager } from '../../../../EditorsManager'
import { DataRecord, isNoteableRecord } from '@eleventhlabs/capture-shared'

export const recordArrowRightOrDownAtBoundary = (
  recordId: string,
  recordIds: string[],
  record: DataRecord,
  focusNextRecord: (recordId: string) => void,
  shouldDisplayNotesForRecordId: (id: string) => boolean,
): boolean => {
  if (
    isNoteableRecord(record) &&
    !isEmpty(record.notes) &&
    shouldDisplayNotesForRecordId(record.id)
  ) {
    EditorsManager.effects.blur(recordId)
    EditorsManager.effects.focus({
      id: record.notes[0],
      position: `start`,
    })
    return true
  } else if (last(recordIds) !== recordId) {
    EditorsManager.effects.blur(recordId)
    focusNextRecord(recordId)
    return true
  }
  return false
}
