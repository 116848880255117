/* *
 * Copied from https://github.com/drac94/react-auth-code-input and modified to support one time code autofilling in Capacitor mobile
 */

import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react'
import { cx, styled } from '../../../common/stationary'

const allowedCharactersValues = ['alpha', 'numeric', 'alphanumeric'] as const

export type OTPCodeInputProps = {
  allowedCharacters?: (typeof allowedCharactersValues)[number]
  ariaLabel?: string
  autoFocus?: boolean
  containerClassName?: string
  disabled?: boolean
  inputClassName?: string
  isPassword?: boolean
  length?: number
  placeholder?: string
  hasError?: boolean
  onChange: (res: string) => void
}

type InputMode = 'text' | 'numeric'

type InputType = 'text' | 'tel' | 'password'

type InputProps = {
  type: InputType
  inputMode: InputMode
  pattern: string
  min?: string
  max?: string
}

export type OTPCodeInputRef = {
  focus: () => void
  clear: () => void
}

const propsMap: { [key: string]: InputProps } = {
  alpha: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}',
  },

  alphanumeric: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z0-9]{1}',
  },

  numeric: {
    type: 'tel',
    inputMode: 'numeric',
    pattern: '[0-9]{1}',
    min: '0',
    max: '9',
  },
}

const OTPCodeInput = forwardRef<OTPCodeInputRef, OTPCodeInputProps>(
  (
    {
      allowedCharacters = 'alphanumeric',
      ariaLabel,
      autoFocus = true,
      containerClassName,
      disabled,
      inputClassName,
      isPassword = false,
      length = 6,
      placeholder,
      onChange,
      hasError = false,
    },
    ref,
  ) => {
    if (isNaN(length) || length < 1) {
      throw new Error('Length should be a number and greater than 0')
    }

    if (!allowedCharactersValues.some((value) => value === allowedCharacters)) {
      throw new Error(
        'Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric',
      )
    }

    const inputsRef = useRef<Array<HTMLInputElement>>([])
    const inputProps = propsMap[allowedCharacters]

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0].focus()
        }
      },
      clear: () => {
        if (inputsRef.current) {
          for (let i = 0; i < inputsRef.current.length; i++) {
            inputsRef.current[i].value = ''
          }
          inputsRef.current[0].focus()
        }
        sendResult()
      },
    }))

    useEffect(() => {
      if (autoFocus) {
        inputsRef.current[0].focus()
      }
    }, [])

    const sendResult = () => {
      const res = inputsRef.current.map((input) => input.value).join('')
      onChange && onChange(res)
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, nextElementSibling },
      } = e

      // If the change is on the first input and it has more than one character
      if (e.target === inputsRef.current[0] && value.length > 1) {
        for (let i = 0; i < length && i < value.length; i++) {
          if (inputsRef.current[i]) {
            inputsRef.current[i].value = value.charAt(i)
          }
        }
        sendResult()
      } else {
        if (value.length > 1) {
          e.target.value = value.charAt(0)
          if (nextElementSibling !== null) {
            ;(nextElementSibling as HTMLInputElement).focus()
          }
        } else {
          if (value.match(inputProps.pattern)) {
            if (nextElementSibling !== null) {
              ;(nextElementSibling as HTMLInputElement).focus()
            }
          } else {
            e.target.value = ''
          }
        }
        sendResult()
      }
    }

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e
      const target = e.target as HTMLInputElement
      if (key === 'Backspace') {
        if (target.value === '') {
          if (target.previousElementSibling !== null) {
            const t = target.previousElementSibling as HTMLInputElement
            t.value = ''
            t.focus()
            e.preventDefault()
          }
        } else {
          target.value = ''
        }
        sendResult()
      }
    }

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.select()
    }

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = e.clipboardData.getData('Text')

      let currentInput = 0

      for (let i = 0; i < pastedValue.length; i++) {
        const pastedCharacter = pastedValue.charAt(i)
        const currentValue = inputsRef.current[currentInput].value
        if (pastedCharacter.match(inputProps.pattern)) {
          if (!currentValue) {
            inputsRef.current[currentInput].value = pastedCharacter
            if (inputsRef.current[currentInput].nextElementSibling !== null) {
              ;(
                inputsRef.current[currentInput]
                  .nextElementSibling as HTMLInputElement
              ).focus()
              currentInput++
            }
          }
        }
      }
      sendResult()

      e.preventDefault()
    }

    const inputs = []
    for (let i = 0; i < length; i++) {
      inputs.push(
        <S.Input
          key={i}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          {...inputProps}
          type={isPassword ? 'password' : inputProps.type}
          ref={(el: HTMLInputElement) => {
            inputsRef.current[i] = el
          }}
          maxLength={i === 0 ? undefined : 1}
          className={cx(inputClassName, {
            hasError,
          })}
          autoComplete={'off'}
          aria-label={
            ariaLabel
              ? `${ariaLabel}. Character ${i + 1}.`
              : `Character ${i + 1}.`
          }
          disabled={disabled}
          placeholder={placeholder}
        />,
      )
    }

    return (
      <S.InputCodeContainer className={containerClassName}>
        {inputs}
      </S.InputCodeContainer>
    )
  },
)

const S = {
  InputCodeContainer: styled.div(() => ({
    display: 'flex',
    gap: 8,
    marginTop: 4,
  })),
  Input: styled.input(({ theme }) => ({
    height: 48,
    width: 48,
    flexShrink: 0,
    borderRadius: 8,
    border: `1px solid ${theme.colors.alpha.border.medium}`,
    background: theme.colors.alpha.tone.extraWeak,
    textAlign: 'center',

    '&.hasError': {
      border: `1px solid ${theme.colors.red[700]}`,
      background: theme.colors.red[100],
    },

    '&:focus': {
      border: `1px solid ${theme.colors.alpha.border.strong}`,
    },
  })),
}

export default OTPCodeInput
