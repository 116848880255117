import { WriteTransaction } from 'replicache'

import { OnboardingTrack, RCMutation } from '@eleventhlabs/capture-shared'
import { assign, cloneDeep, set } from 'lodash'
import { IDBUser } from '../models/IDBUser'
import { replicacheUserRepository } from '../replicacheUserRepository'

export const userCompleteOnboardingSlides = async (
  tx: WriteTransaction,
  {
    executedAtOnClient,
    track,
    slides,
  }: RCMutation.UserCompleteOnboardingSlides,
) => {
  const user = await replicacheUserRepository.getUser(tx)
  const updatedUser = completeSlidesAndUpdateUser(
    user,
    track,
    slides,
    executedAtOnClient,
  )
  await replicacheUserRepository.putUser(tx, updatedUser)
}

function completeSlidesAndUpdateUser(
  user: IDBUser,
  trackType: OnboardingTrack,
  slidesToComplete: string[],
  completedAt: number,
): IDBUser {
  const userObj = cloneDeep(user)
  initializeTrackIfNotInitialized(userObj, trackType)

  const userDataOnboardingTrack = user.data?.onboarding?.track

  const currentCompletedSlides =
    userDataOnboardingTrack &&
    userDataOnboardingTrack[trackType]?.completedSlides

  const incomingCompletedSlides: Record<string, number> = {}
  slidesToComplete.map((s) => {
    incomingCompletedSlides[s] = completedAt
  })

  // Prefer incoming completed slides over old ones and update timestamp if exists
  const newCompletedSlides = assign(
    {},
    currentCompletedSlides,
    incomingCompletedSlides,
  )

  const path = ['data', 'onboarding', 'track', trackType, 'completedSlides']

  return set(userObj, path, newCompletedSlides)
}

function initializeTrackIfNotInitialized(u: IDBUser, track: OnboardingTrack) {
  const isTrackNotInitialized = u.data?.onboarding?.track === undefined
  if (isTrackNotInitialized) initializeTrack(u, track)
}

function initializeTrack(u: IDBUser, track: OnboardingTrack) {
  set(u, `data.onboarding.track.${track}`, {
    savePoints: {},
    completedSlides: {},
    isComplete: true,
  })
}
