import { useCallback } from 'react'
import { ReadTransaction, Replicache } from 'replicache'

import { RecordWithTickler } from '@eleventhlabs/capture-shared'
import { getRecordsWithTicklerISOInRange } from './query'
import { IsoRangeResult, useSubscribeInIsoRange } from './useSubscribe'

export const useWithTicklerIsoInRange = (
  rep: Replicache | null,
  startISO: string,
  endISO: string,
  includeSoftDeleted: boolean,
  def: { [isoDate: string]: RecordWithTickler[] },
  deps?: any[],
) => {
  const get = useCallback(
    async (tx: ReadTransaction) => {
      return (await getRecordsWithTicklerISOInRange(tx, {
        startISO,
        endISO,
        includeSoftDeleted,
      })) as unknown as IsoRangeResult
    },
    [startISO, endISO, includeSoftDeleted],
  )
  const withTicklerInRange =
    useSubscribeInIsoRange(rep, get, def as unknown as IsoRangeResult, deps) ??
    def
  return withTicklerInRange as unknown as {
    [isoDate: string]: RecordWithTickler[]
  }
}
