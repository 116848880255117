import { noop } from 'lodash'
import React, { useEffect, useMemo } from 'react'

import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { useExecContext } from '../../common/contexts/ExecContext'
import { RecordViewType } from '../../common/data/recordGroups'
import { getRecordForViewType } from '../../common/data/recordGroups/selectors/getRecordForViewType'
import useDialogs from '../../common/dialogs/useDialogs'
import { useActiveRecordIds } from '../../common/hooks/useActiveRecordIds'
import { RouteType } from '../../common/routes'
import { Layout } from '../../common/stationary'
import {
  DetailsFullScreenProps,
  DetailsScreenProps,
} from '../DetailsScreen/DetailsScreen'
import { useFullScreenEventHandlers } from '../hooks/useFullScreenEventHandlers'
import { ScreenProps } from '../types'
import { WithModelStore } from './withData'
import { WithSelection } from './withSelection'
import { WithUIContext } from './withUIContext'
import { SideBarDimensions } from '../../components/SideBar'

export type RecordDetailsScreenProps = ScreenProps &
  RouteComponentProps & {
    routeType: RouteType
    showNextFourteenDays?: boolean
    activeDate?: string
    maxDate?: Date
    setDate?: (date: string) => void
    futureDates?: string[]
  }

type WithRecordDetailsScreenProps = DetailsFullScreenProps &
  WithModelStore &
  WithUIContext &
  WithSelection

export function withRecordDetailsScreen(
  WrappedComponent: React.FC<DetailsScreenProps>,
): React.FC<WithRecordDetailsScreenProps> {
  const ComponentWithRecordGroupScreen = (
    props: WithRecordDetailsScreenProps,
  ) => {
    const {
      store,
      commands,
      focusedRecordId,
      showCompleted,
      isViewVisible,
      selection,
      selectedRecords,
      renderSidebar,
      isLeftHidden,
    } = props
    const recordId = props.recordId

    const record = useMemo(
      () =>
        getRecordForViewType(
          store.records,
          recordId,
          RecordViewType.SectionGroup,
        ),
      [store.records, recordId],
    )

    const { activeRecords } = useActiveRecordIds()

    const { exec } = useExecContext()
    const {
      openScheduleDialog,
      openQuickSwitcherDialog,
      openSendToDialog,
      openCalendarDialog,
      dialogComponents,
    } = useDialogs(
      store.records,
      focusedRecordId,
      activeRecords(),
      selectedRecords,
      isViewVisible,
      exec,
      noop,
      undefined,
    )

    const {
      shouldDisplayNotes,
      toggleNotesVisibilityForRecordIds,
      recordHandlers,
      noteHandlers,
      propertiesListEventHandlers,
      onClickBelowRecord,
    } = useFullScreenEventHandlers(
      record,
      selection,
      commands.updateRecord,
      commands.skipOccurrence,
    )

    // Clean selection on mount and unmount
    useEffect(() => {
      selection.clear()
      return function cleanup() {
        selection.clear()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Layout
        leftColumn={{
          children: renderSidebar && renderSidebar(),
          width: SideBarDimensions.width,
          isExpanded: !isLeftHidden,
        }}
      >
        <WrappedComponent
          {...(props as any)}
          focusedId={focusedRecordId}
          record={record}
          noteHandlers={noteHandlers}
          propertiesListEventHandlers={propertiesListEventHandlers}
          openScheduleDialog={openScheduleDialog}
          openQuickSwitcherDialog={openQuickSwitcherDialog}
          openSendToDialog={openSendToDialog}
          openCalendarDialog={openCalendarDialog}
          recordHandlers={recordHandlers}
          selectedIds={selection.ids}
          shouldDisplayNotes={shouldDisplayNotes}
          showCompleted={showCompleted}
          toggleNotesVisibilityForRecordIds={toggleNotesVisibilityForRecordIds}
          onClickBelowRecord={onClickBelowRecord}
        />
        {dialogComponents}
      </Layout>
    )
  }

  ComponentWithRecordGroupScreen.displayName = `ComponentWithRecordGroupScreen`

  return ComponentWithRecordGroupScreen
}
