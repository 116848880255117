import React, { useCallback, useMemo, useState } from 'react'

import { useNavigate } from '@gatsbyjs/reach-router'

import {
  Frack,
  InboxRecord,
  InboxRecordId,
  RootRecord,
  RootRecordId,
  isCompleteableRecord,
} from '@eleventhlabs/capture-shared'
import Analytics, {
  ScreenTypeValueType,
  ShowSidebarToggleTrigger,
} from '../../common/analytics/capture-analytics-actions'
import {
  getScreenNavigationStartedScreenTypeFromRouteType,
  useActiveScreen,
} from '../../common/analytics/capture-analytics-web'
import { useExecContext } from '../../common/contexts/ExecContext'
import { useModelStoreContext } from '../../common/contexts/ModelStoreContext'
import { useReactDnDContext } from '../../common/contexts/ReactDnDContext'
import { useUIContext } from '../../common/contexts/UIContext'
import { useNavigateToList } from '../../common/hooks/useNavigateToList'
import { RouteType, getUrl, useRouteFromLocation } from '../../common/routes'
import { toggleSidebar } from '../../common/useExec/groups/default/actions'
import { isAndroid, isMobile } from '../../common/utils/env'
import { SideBar } from '../../components/SideBar/SideBar'
import { useOutlineCategories } from '../../components/SideBar/hooks/useOutlineCategories'
import { useShortcuts } from '../../components/SideBar/hooks/useShortcuts'

const MOBILE_PIXEL_START_LIMIT = 32

interface RouteParameters {
  listId?: string
  recordId?: string
}

export interface WithSideBar {
  renderSidebar?: () => React.ReactNode
}

export function withSideBar<T extends WithSideBar>(
  WrappedComponent: React.FC<T & RouteParameters>,
): React.FC<T> {
  const ComponentWithSideBar = (props: T & RouteParameters) => {
    // Location
    const { routeType, listId } = useRouteFromLocation()
    const { exec } = useExecContext()

    const dragContext = useReactDnDContext()

    const onRouteClickGenerator = useOnRouteClickGenerator(routeType, listId)

    // Shortcuts
    const { store } = useModelStoreContext()
    const {
      isLeftHidden,
      toggleIsLeftHidden,
      todayFilters: { todayDate },
    } = useUIContext()

    const [startX, setStartX] = useState<null | number>(null)
    const [startY, setStartY] = useState<null | number>(null)
    const [timerId, setTimerId] = useState<number | null>(null)
    const thresholdTime = 300

    const onInboxClick = onRouteClickGenerator(RouteType.Inbox)
    const onTodayClick = onRouteClickGenerator(RouteType.Today)

    const inboxShortcutCount = Frack.toArray(
      (store.records[InboxRecordId] as InboxRecord).children,
    ).filter((id: string) => {
      const record = store.records[id]
      return isCompleteableRecord(record) && !record.isCompleted
    }).length

    const recordsWithOverdueDateCount = store.overdueRecords.length
    const recordsWithTodayDateCount =
      store.recordsWithDates[todayDate]?.filter((r) => !r.isCompleted).length ??
      0
    const todayShortcutCount =
      recordsWithOverdueDateCount + recordsWithTodayDateCount

    const count = useMemo(
      () => ({
        [RouteType.Inbox]: inboxShortcutCount,
        [RouteType.Today]: todayShortcutCount,
      }),
      [inboxShortcutCount, todayShortcutCount],
    )

    const { shortcuts, activeShortcutsIndex } = useShortcuts({
      activeRouteType: routeType,
      listId,
      onInboxClick,
      onTodayClick,
      count,
    })

    const root = useMemo(
      () => store.records[RootRecordId] as RootRecord,
      [store.records],
    )
    const { activeCategoryId, categories } = useOutlineCategories(
      root,
      store.records,
    )

    const toggleSideBar = useCallback(() => {
      exec({
        type: 'TOGGLE_SIDEBAR',
        trigger:
          Analytics.ShowSidebarToggleTrigger.SIDEBAR_TOGGLE_HEADER_BUTTON,
      })
    }, [exec])

    const handleTouchStart = (event: React.TouchEvent) => {
      if (isMobile && !isAndroid) {
        setStartX(event.touches[0].clientX)
        setStartY(event.touches[0].clientY)
        // Start the timer when touch starts
        setTimerId(
          window.setTimeout(() => {
            setStartX(null)
          }, thresholdTime),
        )
      }
    }

    const handleTouchMove = (event: React.TouchEvent) => {
      if (isMobile && !isAndroid) {
        if (!startX || !startY || dragContext.isDragInProgress) {
          return
        }

        const xDiff = startX - event.touches[0].clientX
        const yDiff = startY - event.touches[0].clientY

        if (xDiff > 8 && Math.abs(yDiff) < 17) {
          if (!isLeftHidden) toggleSidebar(toggleIsLeftHidden)
        } else if (xDiff < -8 && Math.abs(yDiff) < 17) {
          if (isLeftHidden && startX < MOBILE_PIXEL_START_LIMIT)
            toggleSidebar(toggleIsLeftHidden)
        }

        // Reset the swipe start position/timer
        setStartX(null)
        setStartY(null)
        if (timerId) {
          clearTimeout(timerId)
          setTimerId(null)
        }
      }
    }

    const renderSidebar = useCallback(
      () => (
        <SideBar
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          activeCategoryId={activeCategoryId}
          activeShortcutsIndex={activeShortcutsIndex}
          categories={categories}
          isHidden={isLeftHidden}
          listId={listId}
          onToggleButtonClick={toggleSideBar}
          shortcuts={shortcuts}
        />
      ),
      [
        listId,
        isLeftHidden,
        shortcuts,
        activeShortcutsIndex,
        categories,
        toggleSideBar,
      ],
    )

    return (
      <WrappedComponent
        renderSidebar={renderSidebar}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        {...props}
      />
    )
  }

  ComponentWithSideBar.displayName = `ComponentWithSideBar`

  return ComponentWithSideBar
}

const useOnRouteClickGenerator = (
  currentRoute: RouteType | undefined,
  currentListId: string | undefined,
) => {
  const { exec } = useExecContext()
  const toggleSideBar = useCallback(() => {
    exec({
      type: 'TOGGLE_SIDEBAR',
      trigger: ShowSidebarToggleTrigger.SIDEBAR_TOGGLE_HEADER_BUTTON,
    })
  }, [exec])

  const navigate = useNavigate()
  const navigateToList = useNavigateToList()
  const activeScreen = useActiveScreen()
  const navigateToRoute = useCallback(
    (route: RouteType, listId?: string) => {
      if (currentRoute === undefined) throw new Error(``)
      else if (currentRoute === route && currentListId === listId)
        window.scrollTo(0, 0)
      else {
        const screenType = getScreenNavigationStartedScreenTypeFromRouteType(
          route,
        ) as ScreenTypeValueType
        Analytics.screenNavigationStarted({
          activeScreen,
          screenNavigationStartedTrigger:
            Analytics.ScreenNavigationStartedTrigger.SIDEBAR_ITEM_CLICK,
          screenType,
        })
        if (listId) navigateToList(listId)
        else navigate(getUrl({ type: route }))
        if (isMobile) toggleSideBar()
      }
    },
    [activeScreen, currentListId, currentRoute, navigate, navigateToList],
  )
  const onRouteClickGenerator = useCallback(
    (route: RouteType, listId?: string) => () => navigateToRoute(route, listId),
    [navigateToRoute],
  )
  return onRouteClickGenerator
}
