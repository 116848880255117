import { RichText } from '@eleventhlabs/capture-shared'
import { emptyFn } from '../../../common/utils'
import { DebouncedEditor } from '../debounce/withDebounce'
import { isEqualWith } from 'lodash'
import { comparisonFunc } from '../../../common/utils/objectEquivalencyCompare'

/**
 * The RichText component must be provided with a rich text object.
 * However, the component creates it's own internal rich text object.
 * The provided object is just used as the initial value of the internal object.
 *
 * So, we have two rich text objects:
 * 1. the "upstream" rich text object (coming from the application state)
 * 2. the "downstream" rich text object (the internal state of the RichText component)
 *
 * When a user interacts with the component, only the internal object changes, not the provided object.
 * Because of this, we need a way to sync changes between the two objects.
 *
 * The RichTextSyncController implements the syncing rules, which are:
 * 1. Only push the downstream object upstream (and vice versa) if the object values are different.
 * 2. Push the downstream object upstream when it changes. However, debounce these pushes.
 * 3. When the upstream object changes, only push the object downstream if
 *    there are no pending downstream changes waiting for the debounce period to end.
 *    AND
 *    the downstream component isn't focused
 *      We don't push upstream objects downstream while the user is focused because,
 *      depending on the cursor position in the component and how the rich text object changes,
 *      this can break the RichText component
 * 4. When the component blurs, push the upstream object downstream.
 *    This syncs any upstream changes that occurred while the user was focused.
 *    There will only be upstream changes to sync if no changes were made to the downstream object while focused.
 */

export class RichTextSyncController {
  debouncedTryFlushD2U: any
  downstream: RichText
  downstreamPending = false
  flushD2U: (downstream: RichText) => void = emptyFn
  flushU2D: (upstream: RichText) => void = emptyFn
  id: string
  isFocused = false
  upstream: RichText

  constructor(id: string, upstream: RichText) {
    this.upstream = upstream
    this.downstream = upstream
    this.id = id
  }

  onUpstreamChanged = (upstream: RichText) => {
    // Update the upstream value
    this.upstream = upstream
    // Whenever there is a new upstream value,
    // try to flush it downstream if it is allowed.
    this.tryFlushU2D()
  }

  tryFlushU2D = () => {
    // Only flush if there are no pending downstream changes
    if (DebouncedEditor.isPending(this.id)) return
    // Only flush if the record isn't currently focused
    if (this.isFocused) return
    // Only flush if the upstream value is different than the downstream.
    if (isEqualWith(this.upstream, this.downstream, comparisonFunc)) return
    this.flushU2D(this.upstream)
  }

  onDownstreamChanged = (downstream: RichText) => {
    this.downstream = downstream
    this.tryFlushD2U()
  }

  tryFlushD2U = () => {
    // Only actually flush the downstream value to upstream if it has a new value
    if (!isEqualWith(this.upstream, this.downstream, comparisonFunc)) {
      this.flushD2U(this.downstream)
    }
  }

  onFocus = () => {
    this.isFocused = true
  }

  onBlur = () => {
    this.isFocused = false
    if (DebouncedEditor.isPending(this.id)) {
      DebouncedEditor.flushPending(this.id)
    } else {
      // On blur, we check if we should flush any upstream changes downstream
      // The set timeout is a slightly hacky bugfix that prevents onBlur updates
      // from occurring before the upstream changes are updated
      setTimeout(() => this.tryFlushU2D(), 0)
    }
  }
}
