import { useEffect } from 'react'
import { ConnectDragSource, useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDragSourceFactory } from './factory/hooks/useDragSourceFactory'
import { DragItemData, DragPayload, DragProps, DragSourceType } from './types'

export const useRegisterDragSource = <DragSourceProps extends DragProps>(
  dragSourceType: DragSourceType,
  payload: DragPayload,
): [ConnectDragSource, DragProps] => {
  const dragSourceHandlers =
    useDragSourceFactory<DragSourceProps>()[dragSourceType]

  const [dragProps, dragRef, preview] = useDrag<
    DragItemData,
    unknown,
    DragSourceProps
  >({
    type: dragSourceType,
    item: () => {
      return dragSourceHandlers.onDragStart(payload)
    },
    collect: () => {
      return dragSourceHandlers.getDragProps(payload) as DragSourceProps
    },
    canDrag: () => {
      return dragSourceHandlers.canDrag
        ? dragSourceHandlers.canDrag(payload)
        : true
    },
    end: () => {
      // Set dragging to be false finally
      dragSourceHandlers.onDragEnd()
    },
  })

  // Render an empty image for drag preview so we can do whatever we want
  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)

    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    preview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    })
  }, [])

  return [dragRef, dragProps]
}
