import { isUrlRecord, RecordDict } from '@eleventhlabs/capture-shared'

export const urlRecordOpenSrcInTab = (
  records: RecordDict,
  recordId: string,
) => {
  const record = records[recordId]
  if (isUrlRecord(record)) window.open(record.url, `_blank`)
  else throw new Error(`Unexpected record type`)
}
