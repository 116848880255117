import React, { ReactNode, createContext, useContext, useMemo } from 'react'

import {
  defaultRecordGroupCommands,
  useRecordGroupCommands,
} from '../../screens/hooks/useRecordGroupCommands'
import { useRecordGroupData } from '../../screens/hooks/useRecordGroupData'
import { RecordGroupData, RecordGroupsCommands } from '../data/recordGroups'
import { useUploadFileToAWS } from '../hooks/useUploadFileToAWS'
import { getVisibleRecordIdsFromGroups } from '../records'
import { RouteType, useRouteFromLocation } from '../routes'
import { FileMetadata } from '@eleventhlabs/capture-shared'
import { useModelStoreContext } from './ModelStoreContext'
import { useUIContext } from './UIContext'

export interface DerivedStateContextValue {
  recordGroups: RecordGroupData[]
  recordGroupCommands: RecordGroupsCommands
  visibleItemIds: string[]
  uploadProgress: Record<string, number>
  uploadFile: (recordId: string, file: File) => Promise<FileMetadata>
}

/*
 * Context
 */

const defaultValue: DerivedStateContextValue = {
  recordGroups: [],
  recordGroupCommands: defaultRecordGroupCommands,
  visibleItemIds: [],
  uploadProgress: {},
  uploadFile: async () => {
    return await new Promise((resolve) => resolve({}))
  },
}

export const DerivedStateContext =
  createContext<DerivedStateContextValue>(defaultValue)
DerivedStateContext.displayName = `DerivedStateContext`

/*
 * Provider
 */

export interface DerivedStateProviderProps {
  children: ReactNode
}

export const DerivedStateProvider = ({
  children,
}: DerivedStateProviderProps) => {
  const { store, commands } = useModelStoreContext()
  const {
    showCompleted,
    todayFilters: { showNextFourteenDays, todayDate },
  } = useUIContext()
  const { routeType, listId } = useRouteFromLocation()

  const recordGroups = useRecordGroupData(
    store,
    routeType,
    showCompleted,
    showNextFourteenDays,
    getActiveDate(routeType, todayDate),
    listId,
  )

  const recordGroupCommands = useRecordGroupCommands(
    routeType,
    store.records,
    commands,
    getActiveDate(routeType, todayDate),
    listId,
  )

  const visibleItemIds = useMemo(
    () => getVisibleRecordIdsFromGroups(recordGroups, showCompleted),
    [recordGroups, showCompleted],
  )

  const { uploadProgress, uploadFile } = useUploadFileToAWS()

  const contextValue = {
    recordGroups,
    recordGroupCommands,
    visibleItemIds,
    uploadProgress,
    uploadFile,
  }

  return (
    <DerivedStateContext.Provider value={contextValue}>
      {children}
    </DerivedStateContext.Provider>
  )
}

/*
 * Hooks
 */

export const useDerivedStateContext = (): DerivedStateContextValue => {
  const context = useContext(DerivedStateContext)

  if (context === undefined) {
    throw new Error(
      `useDerivedStateContext must be used within an DerivedStateContext.Provider`,
    )
  }

  return context
}

const getActiveDate = (routeType: RouteType, todayDate: string) => {
  switch (routeType) {
    case RouteType.Today:
      return todayDate
    default:
      return undefined
  }
}
