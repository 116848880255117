import { useCallback, useMemo } from 'react'

import Analytics from '../../analytics/capture-analytics-actions'
import { useExecContext } from '../../contexts/ExecContext'
import { EditorsManager } from '../../EditorsManager'
import { PropertiesListEventHandlers } from './types'

export const usePropertiesListEventHandlersForFullscreen =
  (): PropertiesListEventHandlers => {
    const { exec } = useExecContext()

    const completeRecords = useCallback(
      (recordId: string) => {
        exec({
          type: 'RECORDS_TOGGLE_IS_COMPLETED',
          trigger: Analytics.ToggleRecordsCompletedTrigger.RECORD_MINI_BAR,
          targetId: recordId,
        })
      },
      [exec],
    )

    const moveRecords = useCallback(
      (recordId: string) => {
        exec({
          type: 'OPEN_MOVE_TO_DIALOG',
          trigger: Analytics.MoveToPickerFlowStartedTrigger.RECORD_MINI_BAR,
          recordId,
        })
      },
      [exec],
    )

    const scheduleRecords = useCallback(
      (recordId: string) => {
        exec({
          type: 'OPEN_SCHEDULE_DIALOG',
          trigger: Analytics.DatePickerFlowStartedTrigger.RECORD_MINI_BAR,
          recordId,
        })
      },
      [exec],
    )

    const addNoteToRecord = useCallback(
      (recordId: string) => {
        exec({
          type: 'ADD_ANNOTATION',
          showAnnotationsTrigger:
            Analytics.ShowAnnotationsToggleTrigger.RECORDITEM_BUTTON,
          createAnnotationTrigger:
            Analytics.AnnotationCreatedTrigger.RECORD_MINI_BAR,
          recordId,
        })
      },
      [exec],
    )

    const hideNote = useCallback(
      (recordId: string) => {
        exec({
          type: 'TOGGLE_ANNOTATIONS',
          recordId,
          showAnnotationsTrigger:
            Analytics.ShowAnnotationsToggleTrigger.RECORDITEM_BUTTON,
          createAnnotationTrigger:
            Analytics.AnnotationCreatedTrigger.RECORD_ITEM_BUTTON,
        })
      },
      [exec],
    )

    const moveRecordsToTrash = useCallback(
      (recordId: string) => {
        exec({
          type: 'MOVE_RECORDS_TO_TRASH',
          trigger: `Keyboard Backspace`,
          triggeredByKeyboard: false,
          triggeredByUI: true,
          recordId,
        })
      },
      [exec],
    )

    const blurRecord = useCallback((recordId: string) => {
      EditorsManager.effects.blur(recordId)
    }, [])

    const handlers = useMemo(
      () => ({
        completeRecords,
        moveRecords,
        scheduleRecords,
        addNoteToRecord,
        hideNote,
        moveRecordsToTrash,
        blurRecord,
      }),
      [
        completeRecords,
        moveRecords,
        scheduleRecords,
        addNoteToRecord,
        hideNote,
        moveRecordsToTrash,
        blurRecord,
      ],
    )

    return handlers
  }
