import { first, flatMap, last } from 'lodash'
import {
  RecordGroupData,
  RecordGroups as RGHelpers,
} from '../../data/recordGroups'

import { Ordered } from './types'

export const createRecordGroupsOrdering = (
  recordGroups: RecordGroupData[],
): Ordered => {
  const recordIds = flatMap(recordGroups, (g) => g.items.map((i) => i.id))
  return {
    firstItem: first(recordIds),
    getNextItem: (id: string) => RGHelpers.getNextItem(recordGroups, id),
    getPrevItem: (id: string) => RGHelpers.getPrevItem(recordGroups, id),
    isEmpty: () => recordIds.length === 0,
    lastItem: last(recordIds),
  }
}

/*
 * Helper functions for dealing with Ordered instances
 */

export const getItemsSortedByOrder = (ordered: Ordered, items: string[]) => {
  let current = ordered.firstItem
  if (current === undefined) return []
  const sorted = []
  do {
    if (items.includes(current)) sorted.push(current)
    current = ordered.getNextItem(current)
  } while (current !== undefined && sorted.length !== items.length)
  return sorted
}

export const getOrderedRangeIncludingItems = (
  ordered: Ordered,
  items: string[],
): string[] => {
  const orderedItems = getItemsSortedByOrder(ordered, items)
  const range: string[] = []
  let curr = first(orderedItems)
  const lastItem = last(orderedItems) as string
  while (curr !== undefined && !range.includes(lastItem)) {
    range.push(curr)
    curr = ordered.getNextItem(curr)
  }
  return range
}

export const sortByOrderAndGetFirstItem = (
  ordered: Ordered,
  items: string[],
): string => {
  return first(getItemsSortedByOrder(ordered, items)) as string
}

export const sortByOrderAndGetLastItem = (
  ordered: Ordered,
  items: string[],
): string => {
  return last(getItemsSortedByOrder(ordered, items)) as string
}

export const getContiguousSliceContainingTarget = (
  target: string,
  items: string[],
  ordered: Ordered,
): string[] => {
  const slice: string[] = []

  // Before Target
  let curr = target
  do {
    const prevItem = ordered.getPrevItem(curr)
    if (prevItem === undefined || !items.includes(prevItem)) break
    slice.unshift(prevItem)
    curr = prevItem
  } while (true)

  // Target
  slice.push(target)

  // After Target
  do {
    const nextItem = ordered.getNextItem(curr)
    if (nextItem === undefined || !items.includes(nextItem)) break
    slice.push(nextItem)
    curr = nextItem
  } while (true)

  return slice
}
