import React, { useEffect, useMemo } from 'react'
import { FixedSizeList } from 'react-window'

import { useUIContext } from '../../../../common/contexts/UIContext'
import { useScrollAtBoundaryOnDrag } from '../../../../common/hooks/listeners/useScrollAtBoundaryOnDrag'
import { useScrollable } from '../../../../common/hooks/scroll/useScrollable'
import { useScrollCommands } from '../../../../common/hooks/scroll/useScrollCommands'
import { useForkRef } from '../../../../common/utils'
import { VirtualListAutosized } from '../../../../common/virtualized'

type ListProps = React.ComponentProps<typeof VirtualListAutosized>
interface ListPropsWithData extends ListProps {
  itemData: { items: Array<{ id: string }> }
}
export const withScrollerRef = <T extends ListPropsWithData>(
  WrappedComponent: React.FC<T>,
) => {
  const WithScrollerRef: React.FC<T> = (props: T) => {
    const items = props.itemData.items
    const ids = useMemo(() => items.map((item) => item.id), [items])
    const { scrollRef, scrollCommands } = useScrollCommands<FixedSizeList>(
      ids,
      `center`,
    )
    const { onOutlineScroll, outlineScrollOffset } = useUIContext()
    useScrollable(`outline`, scrollCommands)

    useEffect(() => {
      if (outlineScrollOffset)
        scrollCommands.scrollToPosition(outlineScrollOffset)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const dragBoundaryRef = useScrollAtBoundaryOnDrag<HTMLDivElement>()

    const forkedOuterRef = useForkRef(props.outerRef, dragBoundaryRef)

    return (
      <WrappedComponent
        ref={scrollRef}
        {...props}
        outerRef={forkedOuterRef}
        onScroll={onOutlineScroll}
      />
    )
  }
  WithScrollerRef.displayName = `WithScrollerRef`
  return WithScrollerRef
}
