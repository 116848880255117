import React from 'react'

import { Icon, IconVariantName, styled } from '../../../../common/stationary'

export interface ResultItemPreProps {
  variant: IconVariantName
}

const ResultItemPre = ({ variant }: ResultItemPreProps) => {
  return (
    <S.Content>
      <Icon boxSize={16} variant={variant} />
    </S.Content>
  )
}

export default ResultItemPre

const S = {
  Content: styled.div({
    display: `flex`,
    flex: `0 0 20px`,
    height: `20px`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    userSelect: `none`,
    paddingLeft: `20px`,
  }),
}
