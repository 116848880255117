import produce from 'immer'
import { cloneDeep, entries, groupBy, merge, omit } from 'lodash'
import emoji from 'node-emoji'
import { JSONValue, ReadTransaction, WriteTransaction } from 'replicache'
import { v4 } from 'uuid'

import {
  CreateListRecordPayload,
  DataRecord,
  FIndexes,
  Frack,
  InsertPoint,
  MoveToPlaceIndex,
  NoteableRecord,
  RecordType,
  RecordWithChildren,
  RecordWithDFIndex,
  RecordWithTickler,
  RecurrencePayload,
  RecurrenceProp,
  Reminder,
  ReminderPayload,
  RootRecordId,
  TickleableRecord,
  Tickler,
  create,
  dateFIndexesForRecords,
  isDeleteableRecord,
  isItemRecord,
  isListRecord,
  isNewListIndex,
  isNoteableRecord,
  isParentableRecord,
  isTickleableRecord,
  isUserCreateableRecord,
  isWithChildren,
  isWithTickler,
  must,
  recordKey,
  withUniqValuesInsertedAtPoint,
  zonedIsoDateFromUtcAndTz,
} from '@eleventhlabs/capture-shared'

import { getRecordsBeforeDay, getRecordsWithTicklerForDay } from '../query'

export const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] !== undefined &&
      obj[key] !== null &&
      typeof obj[key] === `object`
    ) {
      removeEmpty(obj[key])
    } else if (obj[key] === undefined) {
      delete obj[key]
    }
  })
  return obj
}

export const upsertRecord = async (
  tx: WriteTransaction,
  id: string,
  data: DataRecord,
) => {
  await tx.put(recordKey(id), removeEmpty(data))
}

export const updateRecord = async (
  tx: WriteTransaction,
  id: string,
  update: JSONValue,
) => {
  const record = await getRecord(tx, id)
  await upsertRecord(tx, id, merge({}, record, update))
}

export const setRecordChildren = async (
  tx: WriteTransaction,
  id: string,
  children: FIndexes<string>,
) => {
  const record = await getRecordWithChildren(tx, id)
  await upsertRecord(tx, id, { ...record, children })
}

export const deleteRecord = async (tx: WriteTransaction, id: string) => {
  await tx.del(recordKey(id))
}

//
// get
//

export const getRecord = async (tx: ReadTransaction, id: string) => {
  return (await tx.get(recordKey(id))) as DataRecord
}

export const getRecords = async (tx: ReadTransaction, ids: string[]) => {
  return await Promise.all(ids.map(async (id) => await getRecord(tx, id)))
}

export const getRecordWithChildren = async (
  tx: ReadTransaction,
  id: string,
) => {
  const record = await getRecord(tx, id)
  return must(record, isWithChildren)
}

export const getRecordsWithChildren = async (
  tx: ReadTransaction,
  ids: string[],
) => {
  return await Promise.all(
    ids.map(async (id) => await getRecordWithChildren(tx, id)),
  )
}

export const getListRecord = async (tx: WriteTransaction, id: string) => {
  const record = await getRecord(tx, id)
  return must(record, isListRecord)
}

export const getUserCreatableRecord = async (
  tx: ReadTransaction,
  id: string,
) => {
  const record = await getRecord(tx, id)
  return must(record, isUserCreateableRecord)
}

export const getTickleableRecord = async (tx: ReadTransaction, id: string) => {
  const record = await getRecord(tx, id)
  return must(record, isTickleableRecord)
}

export const getTickleableRecords = async (
  tx: ReadTransaction,
  ids: string[],
) => {
  return await Promise.all(
    ids.map(async (id) => await getTickleableRecord(tx, id)),
  )
}

export const getRecordWithTickler = async (tx: ReadTransaction, id: string) => {
  const record = await getRecord(tx, id)
  return must(record, isWithTickler)
}

export const getRecordsWithTicker = async (
  tx: ReadTransaction,
  ids: string[],
) => {
  return await Promise.all(
    ids.map(async (id) => await getRecordWithTickler(tx, id)),
  )
}

export const getDeleteableRecord = async (tx: ReadTransaction, id: string) => {
  const record = await getRecord(tx, id)
  return must(record, isDeleteableRecord)
}

export const getDeleteableRecords = async (
  tx: ReadTransaction,
  ids: string[],
) => {
  return await Promise.all(
    ids.map(async (id) => await getDeleteableRecord(tx, id)),
  )
}

export const getParentableRecord = async (tx: ReadTransaction, id: string) => {
  const record = await getRecord(tx, id)
  return must(record, isParentableRecord)
}

export const getNoteableRecord = async (tx: ReadTransaction, id: string) => {
  const record = await getRecord(tx, id)
  return must(record, isNoteableRecord)
}

export const getMoveableRecord = async (tx: ReadTransaction, id: string) => {
  const record = await getRecord(tx, id)
  return must(record, isItemRecord)
}

export const getGroupedByParent = async (
  tx: ReadTransaction,
  ids: string[],
) => {
  const records = await Promise.all(
    ids.map(async (id) => await getParentableRecord(tx, id)),
  )
  return groupBy(records, `parentId`)
}

//
// TICKLER
//

export type ApplyTicklerUpdate =
  | { isoDate: null; isoLocalTime?: null; recurrence?: null; reminder?: null }
  | {
      isoDate?: string
      isoLocalTime?: string | null
      recurrence?: RecurrencePayload | null
      reminder?: ReminderPayload
    }

export const updateTickler = async (
  tx: WriteTransaction,
  record: TickleableRecord,
  payload: ApplyTicklerUpdate,
) => {
  const next = getRecordWithTicklerUpdates(record, payload)
  await upsertRecord(tx, record.id, next)
}

export const getRecordWithTicklerUpdates = (
  record: TickleableRecord,
  payload: ApplyTicklerUpdate,
) => {
  // Clone deep to prevent DAGs, which replicache cannot deal with.
  return cloneDeep(produce(record, (r) => impureUpdateTicklerProps(r, payload)))
}

export const impureUpdateTicklerProps = (
  record: TickleableRecord,
  payload: ApplyTicklerUpdate,
) => {
  if (record.tickler === undefined && payload.isoDate === undefined)
    throw new Error(``)

  if (payload.isoDate === null) {
    delete record.tickler
    delete record.dateFIndex
    delete record.overdueFIndex
    return
  }
  if (payload.isoDate !== undefined) setIsoDate(record, payload.isoDate)
  if (record.tickler === undefined) throw new Error(``)
  updateIsoLocalTime(
    (record as RecordWithTickler).tickler,
    payload.isoLocalTime,
  )
  updateRecurrence((record as RecordWithTickler).tickler, payload.recurrence)
  updateReminder(record as RecordWithTickler, payload.reminder)
}

const setIsoDate = (record: TickleableRecord, isoDate: string) => {
  if (record.tickler === undefined) record.tickler = { isoDate }
  else record.tickler.isoDate = isoDate
}

const updateIsoLocalTime = (tickler: Tickler, isoLocalTime?: string | null) => {
  updateIfNullOrDefined(tickler, `isoLocalTime`, isoLocalTime)
}

const updateRecurrence = (
  tickler: Tickler,
  recurrence?: RecurrencePayload | null,
) => {
  if (recurrence === undefined) return
  else if (recurrence === null) delete tickler.recurrence
  else tickler.recurrence = recurrenceFromPayload(recurrence)
}

const recurrenceFromPayload = (payload: RecurrencePayload): RecurrenceProp => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    interval: payload.interval,
    frequency: payload.frequency,
    byDayOfWeek: payload.byDayOfWeek,
  } as RecurrenceProp
}

const updateReminder = (
  record: RecordWithTickler,
  payload?: ReminderPayload,
) => {
  if (payload === undefined) return
  else if (payload === null) removeReminder(record)
  else setReminder(record, payload)
}

const setReminder = (record: RecordWithTickler, payload: ReminderPayload) => {
  const reminderId = record.tickler.reminderId ?? v4()
  const { minutesBefore, lastHandledOccurrence } =
    record.reminders[reminderId] ?? {}
  const reminder = reminderFromTicklerAndPayload(record.tickler, {
    minutesBefore: payload?.minutesBefore ?? minutesBefore,
    lastHandledOccurrence,
  })
  record.tickler.reminderId = reminderId
  record.reminders[reminderId] = reminder
}

const removeReminder = (record: TickleableRecord) => {
  if (record.tickler === undefined) return
  const reminderId = record.tickler.reminderId
  if (reminderId === undefined) return
  delete record.tickler.reminderId
  if (record.reminders[reminderId] !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete record.reminders[reminderId]
  }
}

const updateIfNullOrDefined = <T, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K] | null | undefined,
) => {
  if (value === undefined) return
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  else if (value === null) delete obj[key]
  else obj[key] = value
}

const reminderFromTicklerAndPayload = (
  tickler: Tickler,
  payload: Partial<Pick<Reminder, 'minutesBefore' | 'lastHandledOccurrence'>>,
) => {
  return {
    startFrom: tickler.isoDate,
    isoLocalTime: tickler.isoLocalTime,
    frequency: tickler.recurrence?.frequency,
    interval: tickler.recurrence?.interval,
    byDayOfWeek: tickler.recurrence?.byDayOfWeek,
    lastHandledOccurrence: payload.lastHandledOccurrence,
    minutesBefore: payload.minutesBefore,
  }
}

//
// Move Into Date Group
//

export const updateFIndexesForMoveIntoDateGroup = async (
  tx: WriteTransaction,
  recordIds: string[],
  dateGroup: string,
  point: InsertPoint,
  executedAtOnClient: number,
  tzOnClient: string | undefined,
) => {
  if (dateGroup === `overdue`)
    await moveIntoOverdueDateGroup(
      tx,
      recordIds,
      point,
      executedAtOnClient,
      tzOnClient,
    )
  else await moveIntoNonOverdueDateGroup(tx, recordIds, dateGroup, point)
}

const moveIntoOverdueDateGroup = async (
  tx: WriteTransaction,
  recordIds: string[],
  point: InsertPoint,
  executedAtOnClient: number,
  tzOnClient: string | undefined,
) => {
  const todayIsoAtExecutionOnClient = zonedIsoDateFromUtcAndTz(
    executedAtOnClient,
    tzOnClient,
  )
  const includeSoftDeleted = false
  const overdueRecordsInOrder = await getRecordsBeforeDay(
    tx,
    todayIsoAtExecutionOnClient,
    includeSoftDeleted,
  )
  const overdueIdsInOrder = overdueRecordsInOrder.map((r) => r.id)
  const fIndexes = Frack.fromArray(overdueIdsInOrder)
  const nextFIndexes = withUniqValuesInsertedAtPoint(fIndexes, recordIds, point)
  for (const [overdueFIndex, id] of entries(nextFIndexes)) {
    const record = await getTickleableRecord(tx, id)
    await upsertRecord(tx, record.id, { ...record, overdueFIndex })
  }
}

const moveIntoNonOverdueDateGroup = async (
  tx: WriteTransaction,
  recordIds: string[],
  dateGroup: string,
  point: InsertPoint,
) => {
  const includeSoftDeleted = false
  const recordsWithDate = await getRecordsWithTicklerForDay(
    tx,
    dateGroup,
    includeSoftDeleted,
  )
  const fIndexes = dateFIndexesForRecords(
    recordsWithDate as RecordWithDFIndex[],
  )
  const nextFIndexes = withUniqValuesInsertedAtPoint(fIndexes, recordIds, point)
  for (const id of recordIds) {
    const dateFIndex = Frack.getKeyForValue(nextFIndexes, id)
    const record = await getTickleableRecord(tx, id)
    await upsertRecord(tx, record.id, {
      ...(omit(record, [`overdueFIndex`]) as RecordWithDFIndex),
      dateFIndex,
    })
  }
}

//
// Parent / Child
//

export const removeFromNotes = async (
  tx: WriteTransaction,
  record: NoteableRecord,
  noteIds: string[],
) => {
  await upsertRecord(tx, record.id, {
    ...record,
    notes: record.notes.filter((id) => !noteIds.includes(id)),
  })
}

export const removeFromChildren = async (
  tx: WriteTransaction,
  record: RecordWithChildren,
  ids: string[],
) => {
  await upsertRecord(tx, record.id, {
    ...record,
    children: Frack.withValuesRemoved(record.children, ids),
  })
}

export const addToChildren = async (
  tx: WriteTransaction,
  parentId: string,
  ids: string[],
  point: InsertPoint,
) => {
  const parent = await getRecordWithChildren(tx, parentId)
  const toPut = {
    ...parent,
    children: withUniqValuesInsertedAtPoint(parent.children, ids, point),
  }
  await upsertRecord(tx, parent.id, toPut)
}

export const removeFromChildrenPropOfParents = async (
  tx: WriteTransaction,
  recordIds: string[],
) => {
  const groupedByParent = await getGroupedByParent(tx, recordIds)
  for (const [parentId, records] of entries(groupedByParent)) {
    if (parentId === `undefined`) continue
    const parent = await getRecordWithChildren(tx, parentId)
    await removeFromChildren(
      tx,
      parent,
      records.map((r) => r.id),
    )
  }
}

export const createDestIfNeededAndNormalizeIdx = async (
  tx: WriteTransaction,
  index: MoveToPlaceIndex,
): Promise<{ id: string; point: InsertPoint }> => {
  const destinationRecord = await getOrCreateRecordFromIndex(tx, index)
  const id = destinationRecord.id
  const point: InsertPoint = isNewListIndex(index) ? { at: `end` } : index.point
  return { id, point }
}

export const getOrCreateRecordFromIndex = async (
  tx: WriteTransaction,
  index: MoveToPlaceIndex,
) => {
  if (isNewListIndex(index)) {
    const newListId = await createListInRoot(tx, index.payload)
    return await getListRecord(tx, newListId)
  } else {
    return await getRecord(tx, index.id)
  }
}

const createListInRoot = async (
  tx: WriteTransaction,
  payload: CreateListRecordPayload,
) => {
  const list = {
    ...create({
      ...payload,
      emoji: payload.emoji ?? emoji.random().key,
      type: RecordType.List,
    }),
    parentId: RootRecordId,
  }
  await upsertRecord(tx, list.id, list)
  await addToChildren(tx, RootRecordId, [list.id], { at: `end` })
  return list.id
}

//

export const setParentIdForRecords = async (
  tx: WriteTransaction,
  recordIds: string[],
  parentId: string,
) => {
  for (const recordId of recordIds) {
    const record = await getMoveableRecord(tx, recordId)
    await upsertRecord(tx, record.id, {
      ...record,
      parentId,
    })
  }
}
