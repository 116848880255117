import { Channel } from 'pusher-js'
import pusher from '../pusher'
import { WSEventName, WSEventPayload } from './websocketConfig'
import { getPrivateUserChannelName } from './utils'

let pusherUserIdChannel: Channel
const wsChannels: Record<string, Channel> = {}

export const ws = {
  subscribe,
  unsubscribe,
  bindEvent,
  sendEvent,
  sendClientEvent,
  getChannel,
}

/**
 * Subscribes to a channel with the given ID.
 *
 * @param {string} channelId - The ID of the channel to subscribe to.
 */
function subscribe(channelId: string) {
  pusherUserIdChannel = pusher.subscribe(channelId)

  wsChannels[channelId] = pusherUserIdChannel
}

/**
 * Unsubscribes from a websocket channel.
 *
 * @param {string} channelId - The ID of the channel to unsubscribe from.
 */
function unsubscribe(channelId: string) {
  const channel = wsChannels[channelId]
  if (!channel) return

  channel.unsubscribe()
}

export interface BindEventArgs<T extends keyof WSEventPayload> {
  eventName: T
  callback: (payload: WSEventPayload[T]) => void
}

export type BindEventArgsArray = {
  [K in keyof WSEventPayload]?: BindEventArgs<K>
}[]

/**
 * Binds a websocket event to a specific channel.
 *
 * Make sure to any new events in websocketConfig.ts
 *
 * @param {string} channelId - The ID of the channel to bind the event to.
 * @param {BindEventArgs<T>} args - The arguments for binding the event.
 */
function bindEvent<T extends keyof WSEventPayload>(
  channelId: string,
  args: BindEventArgs<T>,
) {
  const channel = wsChannels[channelId]
  if (!channel) return

  return channel.bind(args.eventName, args.callback)
}

export interface WebSocketEvent {
  name: WSEventName
  payload: WSEventPayload[WSEventName]
}

/**
 * Sends an event to the specified WebSocket channel.
 *
 * @param {string} channelId - The ID of the channel to send the event to.
 * @param {WebSocketEvent} event - The event to send.
 */
function sendEvent(channelId: string, event: WebSocketEvent) {
  const channel = wsChannels[channelId]
  if (!channel) return

  channel.trigger(event.name, event.payload)
}

/**
 * Sends a websocket event to all connected clients of a specific user.
 *
 * Use this method when you want to send an event to all authorized, connected clients.
 *
 * @param {string} userId - The ID of the user.
 * @param {WebSocketEvent} event - The event to send.
 */
function sendClientEvent(userId: string, event: WebSocketEvent) {
  ws.sendEvent(getPrivateUserChannelName(userId), event)
}

/**
 * Retrieves a registered channel with the given ID.
 *
 * @param {string} channelId - The ID of the channel to retrieve.
 * @return {any} The channel object associated with the given channelId, or undefined if not found.
 */
export function getChannel(channelId: string): Channel | null {
  return wsChannels[channelId]
}
