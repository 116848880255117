import { IconVariantName } from '@eleventhlabs/stationary'
import React from 'react'
import { styled } from '../../styled'
import { Icon } from '../Icon'

export interface AlertProps {
  children: any
  icon?: IconVariantName
  onClose?: () => void
}

export const Alert: React.FC<AlertProps> = ({ children, icon, onClose }) => {
  return (
    <S.AlertWrapper>
      {icon && (
        <S.IconWrapper>
          <Icon variant={icon} boxSize={20} />
        </S.IconWrapper>
      )}
      <S.AlertContent>{children}</S.AlertContent>
      <S.Divider />
      <S.CloseButtonWrapper>
        <S.CloseButton variant="glyphX" boxSize={12} onClick={onClose} />
      </S.CloseButtonWrapper>
    </S.AlertWrapper>
  )
}

const S = {
  AlertWrapper: styled.div(({ theme }) => ({
    height: `100%`,
    width: `100%`,
    display: `flex`,
    alignItems: `flex-start`,
  })),
  AlertContent: styled.div(() => ({
    padding: '4px 6px',
    marginRight: 6,
  })),
  Divider: styled.div(({ theme }) => ({
    width: 1,

    flex: `none`,
    order: 0,
    alignSelf: `stretch`,
    flexGrow: 0,
    backgroundColor: theme.colors.alpha.border.mediumWeak,
    marginRight: 6,
  })),
  IconWrapper: styled.div({
    display: 'flex',
    height: 28,
    width: 28,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  CloseButtonWrapper: styled.div({
    width: 28,
    height: 28,
    padding: 8,
    order: 1,
  }),
  CloseButton: styled(Icon)({
    cursor: 'pointer',

    opacity: 0.8,

    flex: `none`,
    flexGrow: 0,
  }),
}
