import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  FixedSizeList,
  FixedSizeListProps,
  ListChildComponentProps,
} from 'react-window'
import { css, customScrollbarStyles, cx, useTheme } from '../stationary'

type VirtualListProps = Omit<
  FixedSizeListProps,
  'height' | 'width' | 'children'
> & {
  Row: React.FC<ListChildComponentProps>
}

export const VirtualListAutosized = React.forwardRef<
  FixedSizeList,
  VirtualListProps
>(
  (
    {
      itemCount,
      itemData,
      itemSize,
      outerRef,
      Row,
      className,
      onScroll,
      initialScrollOffset,
    },
    ref,
  ) => {
    const theme = useTheme()

    return (
      // Need the defaults for dnd compatability
      <AutoSizer defaultHeight={1} defaultWidth={1}>
        {({ height, width }) => (
          <FixedSizeList
            height={height}
            itemCount={itemCount}
            itemData={itemData}
            itemSize={itemSize}
            outerRef={outerRef}
            className={cx(
              css(
                customScrollbarStyles({
                  thumbColor: theme.colors.alpha.tone.strong,
                  width: 8,
                }),
              ),
              `scroll`,
              className,
            )}
            ref={ref}
            width={width}
            onScroll={onScroll}
            initialScrollOffset={initialScrollOffset}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    )
  },
)
