import React from 'react'
import { Transition } from 'react-transition-group'

import styled from '@emotion/styled'
import { Icon, spinAnimationStyles } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'

interface Record {
  id: string
  value: string
}

export interface InteractiveInboxProps {
  name: string
}

export const RegistrationDemoInbox: React.FC<InteractiveInboxProps> = ({
  name,
}) => {
  return (
    <S.InteractiveInbox>
      <Transition appear in timeout={0}>
        {(state) => (
          <S.Inner
            style={{
              transform:
                state === 'entering' ? 'translateY(24px)' : 'translateY(0)',
            }}
          >
            <S.Inbox>
              <S.Header>
                <S.Icon boxSize={28} variant="glyphInboxSquircle" />
                <S.Title>Inbox</S.Title>
              </S.Header>
              <S.Records>
                <Transition appear in key={1234} timeout={0}>
                  {(state) => (
                    <S.Record
                      style={{
                        opacity: state === 'entering' ? 0 : 1,
                        transform:
                          state === 'entering'
                            ? 'translateY(-16px)'
                            : 'translateY(0)',
                      }}
                    >
                      <S.DummyTextEditor>
                        <S.TextBlock>{`${name}'s first Capture 😎`}</S.TextBlock>
                      </S.DummyTextEditor>
                    </S.Record>
                  )}
                </Transition>
                <Transition appear in timeout={100}>
                  {(state) => (
                    <S.Record
                      style={{
                        opacity: state === 'entering' ? 0 : 1,
                        transform:
                          state === 'entering'
                            ? 'translate(48px, -16px)'
                            : 'translate(0)',
                      }}
                    >
                      <S.DummyTextEditor>
                        <S.DummyH1Block style={{ width: 240 }} />
                        <S.DummyChecklistItemBlock style={{ width: 480 }} />
                        <S.DummyChecklistItemBlock style={{ width: 360 }} />
                        <S.DummyChecklistItemBlock style={{ width: 480 }} />
                      </S.DummyTextEditor>
                    </S.Record>
                  )}
                </Transition>
                <Transition appear in timeout={200}>
                  {(state) => (
                    <S.Record
                      style={{
                        opacity: state === 'entering' ? 0 : 1,
                        transform:
                          state === 'entering'
                            ? 'translate(48px, -16px)'
                            : 'translate(0)',
                      }}
                    >
                      <S.DummyWebPagePreview>
                        <S.DummyFrontMatter>
                          <S.DummyFrontMatterTitle style={{ width: 360 }} />
                          <S.DummyFrontMatterDescription
                            style={{ width: '100%' }}
                          />
                        </S.DummyFrontMatter>
                        <S.DummyThumbnail />
                      </S.DummyWebPagePreview>
                    </S.Record>
                  )}
                </Transition>
                <Transition appear in timeout={300}>
                  {(state) => (
                    <S.Record
                      style={{
                        opacity: state === 'entering' ? 0 : 1,
                        transform:
                          state === 'entering'
                            ? 'translate(48px, -16px)'
                            : 'translate(0)',
                      }}
                    >
                      <S.DummyTextEditor>
                        <S.DummyTextBlock style={{ width: 240 }} />
                      </S.DummyTextEditor>
                    </S.Record>
                  )}
                </Transition>
              </S.Records>
            </S.Inbox>
          </S.Inner>
        )}
      </Transition>
    </S.InteractiveInbox>
  )
}

const Dimensions = {
  OutsetHorizontal: !isMobile ? 48 : 24,
}

const BaseStyled = {
  DummyRow: styled.div(({ theme }) => ({
    position: 'relative',
    maxWidth: '100%',

    ':before': {
      content: '""',
      position: 'absolute',
      top: 2,
      bottom: 2,
      left: 0,
      right: 0,
      borderRadius: 4,
      background: `linear-gradient(to right,
        ${theme.colors.alpha.tone.mediumStrong},
        ${theme.colors.alpha.tone.mediumWeak})`,
    },
  })),
}

const S = {
  InteractiveInbox: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'center',
    position: 'relative',
    minHeight: 0,

    transition: 'opacity 0.8s ease',
  })),
  EmojiRewardOrigin: styled.div(() => ({
    position: 'absolute',
    top: 112,
    zIndex: 1,
    width: '100%',
    maxWidth: 768,
  })),
  Inner: styled.div(({ theme }) => ({
    height: '100%',
    width: '100%',
    maxWidth: 768 + Dimensions.OutsetHorizontal * 2,
    borderRadius: '12px',
    boxShadow: theme.effects.boxShadow.elevation[2],
    background: theme.colors.surface.lower,
    overflow: 'hidden',

    transition: 'transform 0.8s ease',
  })),
  Inbox: styled.div(() => ({
    padding: `0 24px 24px`,
  })),
  Header: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    padding: '48px 0 24px',
  })),
  Icon: styled(Icon)(({ theme }) => ({
    color: theme.colors.blue[700],
  })),
  Title: styled.div(({ theme }) => ({
    ...theme.text.publicSans['33:40'],
    fontWeight: 800,
  })),
  Records: styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  })),
  WaitingForTextPlaceholder: styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: isMobile ? 'flex-start' : 'center',
    gap: 11,
    height: 64,
    borderRadius: 8,
    paddingTop: 16,
    paddingBottom: 16,
    width: `100%`,
    border: `1px dashed ${theme.colors.alpha.border.medium}`,
  })),
  WaitingForTextPlaceholderLeft: styled.div(({ theme }) => ({
    position: 'absolute',
    left: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    fontWeight: 400,
    color: theme.colors.text[600],
  })),
  WaitingIcon: styled(Icon)({
    ...spinAnimationStyles({
      animationDuration: '2s',
    }),
  }),
  SkipStepButton: styled.div(({ theme }) => ({
    position: 'absolute',
    right: 16,
    display: 'flex',
    height: 32,
    padding: `0px 16px`,
    justifyContent: `center`,
    alignItems: 'center',
    gap: 8,
    flexShrink: 0,
    borderRadius: 6,
    border: `1px solid ${theme.colors.gray[200]}`,
    background: theme.colors.gray[100],
    color: theme.colors.text[900],
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 700,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 1s, visibility 1s',

    '&.isButtonShowing': {
      opacity: 1,
      visibility: `visible`,
    },
  })),
  Record: styled.div(({ theme }) => ({
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[1],
    background: theme.colors.surface.base,

    transition: 'opacity 0.4s ease, transform 0.6s ease',
  })),

  DummyTextEditor: styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 20,
  })),
  TextBlock: styled.div(({ theme }) => ({
    minHeight: 24,
    ...theme.text.publicSans['16:24'],
  })),
  DummyTextBlock: styled(BaseStyled.DummyRow)(() => ({
    height: 24,
  })),
  DummyH1Block: styled(BaseStyled.DummyRow)(() => ({
    height: 36,
  })),
  DummyChecklistItemBlock: styled(BaseStyled.DummyRow)(({ theme }) => ({
    height: 24,

    ':before': {
      left: 32,
    },
    ':after': {
      content: '""',
      position: 'absolute',
      top: 2,
      bottom: 2,
      left: 0,
      width: 20,
      border: `2px solid ${theme.colors.alpha.tone.mediumStrong}`,
      borderRadius: 4,
    },
  })),

  DummyWebPagePreview: styled.div(() => ({
    display: 'flex',
    height: 88,
  })),
  DummyFrontMatter: styled.div(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    padding: 20,
  })),
  DummyFrontMatterTitle: styled(BaseStyled.DummyRow)(() => ({
    height: 24,
  })),
  DummyFrontMatterDescription: styled(BaseStyled.DummyRow)(({ theme }) => ({
    height: 24,

    ':before': {
      top: 6,
      bottom: 2,
      left: 24,
    },
    ':after': {
      content: '""',
      position: 'absolute',
      top: 6,
      bottom: 2,
      left: 0,
      width: 16,
      borderRadius: 4,
      background: theme.colors.alpha.tone.mediumStrong,
    },
  })),
  DummyThumbnail: styled(BaseStyled.DummyRow)(() => ({
    flexBasis: 88 * 2,

    ':before': {
      top: 2,
      bottom: 2,
      left: 2,
      right: 2,
      borderRadius: 6,
    },
  })),
}
