import { useCallback } from 'react'

import { EditorsManager } from '../../../EditorsManager/EditorsManager'
import Analytics from '../../capture-analytics-actions'
import { useActiveScreen } from '../../capture-analytics-web/useActiveScreen'
import { RecordType } from '@eleventhlabs/capture-shared'

interface EditorAnalyticsHandlers {
  validateEditorAnalytics: () => void
  onListItemChange: () => void
}
export const useEditorAnalyticsHandlers = (
  recordId: string,
  recordType: RecordType,
): EditorAnalyticsHandlers => {
  const activeScreen = useActiveScreen()

  const validateEditorAnalytics = useCallback(() => {
    return EditorsManager.analytics.validate(recordId)
  }, [recordId])

  const onListItemChange = useCallback(() => {
    if (!EditorsManager.analytics.isRegistered(recordId)) {
      EditorsManager.analytics.register(recordId, { shouldFlush: true })

      const editorStats = EditorsManager.analytics.flush(recordId)
      if (editorStats) {
        if (recordType === RecordType.Note) {
          Analytics.annotationContentUpdated({
            ...editorStats,
            activeScreen,
            recordId,
          })
        } else {
          Analytics.recordContentUpdated({
            ...editorStats,
            activeScreen,
            recordId,
          })
        }
      }
    }
  }, [recordId, recordType])

  return {
    validateEditorAnalytics,
    onListItemChange,
  }
}
