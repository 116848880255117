import {
  ModalUnstyled,
  ModalUnstyledBackdropSlotProps,
  ModalUnstyledProps,
} from '@mui/base'
import React, { useEffect } from 'react'

import { useUIContext } from '../common/contexts/UIContext'
import { styled } from '../common/stationary/styled'

export type ModalBackdropProps = ModalUnstyledBackdropSlotProps

export const ModalBackdrop = styled.div(({ theme }) => ({
  position: `fixed`,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  background: theme.colors.alpha.shade.extraStrong,
}))

export const ModalRoot = styled.div({
  zIndex: 999,
})

/**
 * Prevent mouse events from bubbling up to parents
 */
const preventMouseEventPropagation: React.MouseEventHandler = (e) =>
  e.stopPropagation()

export type ModalProps = ModalUnstyledProps

export const Modal: React.FC<ModalProps> = ({ slots, slotProps, ...props }) => {
  const { setIsAModalOpen } = useUIContext()
  useEffect(() => {
    setIsAModalOpen(props.open)
    return () => {
      setIsAModalOpen(false)
    }
  }, [props.open])

  return (
    <ModalUnstyled
      slots={{ root: ModalRoot, ...slots }}
      slotProps={{
        root: { onClick: preventMouseEventPropagation },
        ...slotProps,
      }}
      {...props}
    />
  )
}
