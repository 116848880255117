import React, { useLayoutEffect } from 'react'

import { Redirect, Router, useLocation } from '@gatsbyjs/reach-router'

import { safeNavigate } from '../common/navigate'
import { useIsOnboardingCompleted } from '../common/onboarding'
import { useReplicacheContext } from '../common/replicache/ReplicacheContext'
import { RouteType, RoutesPath, useIsUnknownRoute } from '../common/routes'
import { isMobile } from '../common/utils/env'
import InboxScreenContainer from '../screens/InboxScreen/InboxScreenContainer'
import LoadingScreen from '../screens/LoadingScreen'
import { OnboardingScreenContainer } from '../screens/OnboardingScreen/OnboardingScreen'
import RecordScreenContainer from '../screens/RecordScreen/RecordScreenContainer'
import ResetPasswordScreen from '../screens/ResetPasswordScreen/ResetPasswordScreen'
import TodayScreenContainer from '../screens/TodayScreen/TodayScreenContainer'
import TrashScreenContainer from '../screens/TrashScreen/TrashScreenContainer'
import { ScreenProps } from '../screens/types'
import { track } from '../screens/OnboardingScreen/components/tracks'
import { isEmpty } from 'lodash'

export const ProtectedRouter = (props: ScreenProps) => {
  useOnboardingRerouteLayoutEffect()

  const { isReady } = useReplicacheContext()

  const isUnknownRoute = useIsUnknownRoute()

  if (!isReady) return <LoadingScreen />

  if (isUnknownRoute)
    return (
      <Redirect
        default
        noThrow
        from={window.location.pathname}
        to={RoutesPath[RouteType.Inbox]}
      />
    )

  return (
    <Router>
      <OnboardingScreenContainer path="onboarding" {...props} />
      <InboxScreenContainer path={RoutesPath[RouteType.Inbox]} {...props} />
      <TodayScreenContainer path={RoutesPath[RouteType.Today]} {...props} />
      <RecordScreenContainer path={RoutesPath[RouteType.Details]} {...props} />
      <TrashScreenContainer path={RoutesPath[RouteType.Trash]} {...props} />
      <ResetPasswordScreen
        path={RoutesPath[RouteType.ResetPassword]}
        {...props}
      />
    </Router>
  )
}

const useOnboardingRerouteLayoutEffect = () => {
  const { isReady } = useReplicacheContext()
  const isOnboardingComplete = useIsOnboardingCompleted()
  const { pathname } = useLocation()
  const shouldRerouteToOnboarding =
    !pathname.includes('onboarding') && !isOnboardingComplete && !isEmpty(track)

  /**
   * If onboarding is not complete go to /onboarding
   */
  useLayoutEffect(() => {
    if (isReady && shouldRerouteToOnboarding) safeNavigate('/onboarding')
  }, [isReady, shouldRerouteToOnboarding])

  /**
   * If onboarding is complete, redirect from /onboarding to /inbox
   */
  useLayoutEffect(() => {
    if (isReady && isOnboardingComplete && pathname.includes('onboarding'))
      safeNavigate(RoutesPath[RouteType.Inbox])
  }, [isReady, isOnboardingComplete, pathname])
}
