import React, { useCallback, useMemo } from 'react'

export interface BaseSelection {
  addIds: (ids: string[], shouldHideSelectionActionBar?: boolean) => void
  clear: (defaultId?: string) => void
  empty: () => void
  defaultId: string | undefined
  ids: string[]
  removeIds: (ids: string[], shouldHideSelectionActionBar?: boolean) => void
  setDefaultId: (id: string | undefined) => void
  togglePresence: (id: string) => void
  set: (ids: string[], shouldHideSelectionActionBar?: boolean) => void
  shouldShowSelectionActionBar: boolean
}

export const useBaseSelection = (): BaseSelection => {
  const [ids, setIds] = React.useState<string[]>([])
  const [defaultId, setDefaultId] = React.useState<string | undefined>()
  const [shouldHideSelectionActionBar, setShouldHideSelectionActionBar] =
    React.useState(false)

  const set = useCallback(
    (ids: string[], shouldHideSelectionActionBar?: boolean): void => {
      setShouldHideSelectionActionBar(shouldHideSelectionActionBar ?? false)
      setIds(ids)
    },
    [],
  )

  const addIds = useCallback(
    (ids: string[], shouldHideSelectionActionBar?: boolean): void => {
      setShouldHideSelectionActionBar(shouldHideSelectionActionBar ?? false)
      setIds((prevIds) => [
        ...ids,
        ...prevIds.filter((id) => !ids.includes(id)),
      ])
    },
    [],
  )

  const clear = useCallback((defaultId?: string): void => {
    setShouldHideSelectionActionBar(false)
    setIds([])
    setDefaultId(defaultId)
  }, [])

  const empty = useCallback((): void => {
    setShouldHideSelectionActionBar(false)
    setIds([])
  }, [])

  const removeIds = useCallback((ids: string[]): void => {
    setShouldHideSelectionActionBar(false)
    setIds((prevIds) => prevIds.filter((id) => !ids.includes(id)))
  }, [])

  const togglePresence = useCallback(
    (id: string): void => {
      setShouldHideSelectionActionBar(false)
      setIds((prevIds) => {
        if (prevIds.includes(id)) return prevIds.filter((_id) => id !== _id)
        else return [id, ...prevIds]
      })
    },
    [setIds],
  )

  const shouldShowSelectionActionBar =
    !!ids.length && !shouldHideSelectionActionBar

  return useMemo(
    () => ({
      ids,
      defaultId,
      addIds,
      clear,
      empty,
      removeIds,
      set,
      setDefaultId,
      togglePresence,
      shouldShowSelectionActionBar,
    }),
    [
      ids,
      defaultId,
      addIds,
      clear,
      empty,
      removeIds,
      set,
      setDefaultId,
      togglePresence,
      shouldShowSelectionActionBar,
    ],
  )
}
