import React from 'react'

import {
  ModelStoreContextValue,
  useModelStoreContext,
} from '../../common/contexts/ModelStoreContext'

export interface WithModelStore extends ModelStoreContextValue {}

export function withModelStore<T>(
  WrappedComponent: React.FC<T & WithModelStore>,
): React.FC<T> {
  const ComponentWithData = (props: T) => {
    const modelStoreContextValue = useModelStoreContext()
    return <WrappedComponent {...props} {...modelStoreContextValue} />
  }

  ComponentWithData.displayName = `ComponentWithData`

  return ComponentWithData
}
