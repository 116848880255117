import React from 'react'

import { Breadcrumb } from '../types'
import { withErrorBoundary } from '../../../../common/errors/enhancers/withErrorBoundary'
import { styled } from '../../../../common/stationary'

export interface HeaderBreadcrumbProps {
  notLastElement: boolean
  breadcrumb: Breadcrumb
}

const HeaderBreadcrumb: React.FC<HeaderBreadcrumbProps> = ({
  notLastElement,
  breadcrumb,
}: HeaderBreadcrumbProps) => {
  return (
    <React.Fragment key={breadcrumb.id}>
      {breadcrumb.iconRenderer && breadcrumb.iconRenderer()}
      {breadcrumb.titleRenderer && breadcrumb.titleRenderer()}
      {notLastElement ? <S.TextDivider>/</S.TextDivider> : null}
    </React.Fragment>
  )
}

export default withErrorBoundary<HeaderBreadcrumbProps, never>(HeaderBreadcrumb)

const S = {
  TextDivider: styled.div(({ theme }) => ({
    margin: `0 10px`,
    color: theme.colors.alpha.border.medium,
  })),
}
