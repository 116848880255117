import React from 'react'

import { titleOrDefault } from '../../../common/records'
import { styled } from '../../../common/stationary'

export interface TodayTitleProps {
  title: string
  className?: string
  dayTitle?: string
}

const TodayTitle: React.FC<TodayTitleProps> = ({
  title,
  className,
  dayTitle,
}: TodayTitleProps) => {
  return (
    <S.TodayTitle className={className}>
      <S.TodayTitleText>{titleOrDefault(title)}</S.TodayTitleText>
      <S.DayTitle>{dayTitle}</S.DayTitle>
    </S.TodayTitle>
  )
}

export default TodayTitle

export const TodayTitleHeight = 48

const S = {
  TodayTitle: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    overflowX: `hidden`,
    fontWeight: `bold`,
    ...theme.text.publicSans['33:40'],
    marginLeft: 4,
  })),
  TodayTitleText: styled.div({
    width: `100%`,
  }),
  DayTitle: styled.div(({ theme }) => ({
    ...theme.text.publicSans['16:24'],
    lineHeight: `24px`,
    fontWeight: 500,
    whiteSpace: `nowrap`,
    color: theme.colors.text[900],
    paddingTop: 13,
    marginLeft: 16,
  })),
}
