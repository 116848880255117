import { CSSObject } from '../styled'
import { Theme } from '../theme'

export { stackBackgroundStyles } from '@eleventhlabs/stationary'

export const gradientBackgroundStyles = ({
  theme,
}: {
  theme: Theme
}): CSSObject => ({
  background: `radial-gradient(circle at 50% 50%,
    ${theme.colors.blue[100]} 0%,
    ${theme.colors.indigo[100]} 20%,
    ${theme.colors.orange[100]} 40%,
    ${theme.colors.blue[100]} 60%,
    ${theme.colors.indigo[100]} 80%,
    ${theme.colors.orange[100]} 100%)`,
  backgroundSize: '400% 400%',
})
