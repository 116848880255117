import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { SlideProps } from '.'
import diagramDesktopAppMacSrc from '../../../../assets/onboarding/diagram-desktop-app-menu-bar-icon-focused@2x.png'
import diagramDesktopAppWindowsSrc from '../../../../assets/onboarding/diagram-desktop-app-system-tray-focused-windows@2x.png'
import { useQuickCaptureInteraction } from '../../../../common/contexts/onboarding/QuickCaptureInteractionContext'
import { Key, useKeys } from '../../../../common/hooks/useKeys'
import { cx, styled } from '../../../../common/stationary'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import { SlideWaitingHint } from './variants/ImmersiveSlide'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideSecondaryAction,
  SlideTitle,
  SlideWaitingSpinner,
  TransitionSlideContainer,
} from './variants/SplitCardSlide'
import { useReward } from 'react-rewards'
import { isMacLike } from '../../../../common/utils/env'

export const DesktopMenuBarShortcut: React.FC<SlideProps> = ({ goForward }) => {
  const { onboardingAnalytics } = useOnboardingContext()

  const { didClickTrayIcon, setDidClickTrayIcon } = useQuickCaptureInteraction()

  useLayoutEffect(() => {
    setDidClickTrayIcon(false)
  }, [setDidClickTrayIcon])

  const { reward: emojiReward } = useReward('emojiRewardId', 'emoji', {
    emoji: ['✅'],
    zIndex: 1,
  })

  useEffect(() => {
    if (didClickTrayIcon) emojiReward()
  }, [didClickTrayIcon])

  useKeys(Key.Enter, () => didClickTrayIcon && goForward())

  const slideTitle = isMacLike
    ? 'The menu bar shortcut'
    : 'The system tray shortcut'

  const systemTrayCopy = isMacLike ? 'menu bar icon' : 'system tray icon'

  return (
    <S.InstallDesktopApp>
      <TransitionSlideContainer>
        <SlideIntro>
          <SlideTitle>{slideTitle}</SlideTitle>
          <SlideDescription>
            Another way to access quick capture from anywhere on your computer.
          </SlideDescription>
          <SlideWaitingHint
            className={cx({ isSuccess: didClickTrayIcon })}
            id="emojiRewardId"
          >
            <span>
              Click the <strong>{systemTrayCopy}</strong>
            </span>
            {!didClickTrayIcon ? (
              <SlideWaitingSpinner
                boxSize={16}
                variant="glyphCircleThreeQuarters"
              />
            ) : (
              <span>✅</span>
            )}
          </SlideWaitingHint>
          <SlideButtonGroup
            style={{
              display: didClickTrayIcon ? 'none' : undefined,
            }}
          >
            <Transition appear in timeout={4000}>
              {(state) => (
                <SlideSecondaryAction
                  style={{
                    opacity: state === 'entering' ? 0 : 1,
                    pointerEvents: state === 'entering' ? 'none' : 'auto',
                    transition: 'opacity 0.8s ease',
                  }}
                >
                  <a
                    className={cx('isItalic')}
                    onClick={() => {
                      goForward()
                      onboardingAnalytics.action(
                        'Menu Bar Shortcut Skip For Now',
                      )
                    }}
                  >
                    Skip for now
                  </a>
                </SlideSecondaryAction>
              )}
            </Transition>
          </SlideButtonGroup>
          <SlideButtonGroup
            style={{
              display: didClickTrayIcon ? undefined : 'none',
            }}
          >
            <SlidePrimaryButton onClick={() => goForward()}>
              <strong>Continue</strong>
            </SlidePrimaryButton>
          </SlideButtonGroup>
        </SlideIntro>
        <SlideDiagrams>
          <img
            src={
              isMacLike ? diagramDesktopAppMacSrc : diagramDesktopAppWindowsSrc
            }
            srcSet={`${
              isMacLike ? diagramDesktopAppMacSrc : diagramDesktopAppWindowsSrc
            } 2x`}
          />
        </SlideDiagrams>
      </TransitionSlideContainer>
    </S.InstallDesktopApp>
  )
}

const S = {
  InstallDesktopApp: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',
  })),
  PrettyKeys: styled.span(({ theme }) => ({
    display: 'inline-block',
    margin: '0 1px',
    padding: '2px 4px',
    border: `1px solid ${theme.colors.alpha.border.weak}`,
    borderRadius: 4,
    ...theme.text.publicSans['11.5:16'],
    fontWeight: 700,
    color: theme.colors.text[700],
    background: theme.colors.alpha.tone.extraWeak,
    transform: 'translateY(-2px)',
  })),
}
