import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router'
import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import ChromeLogo from '../../assets/onboarding/chrome-icon.png'
import GoogleSignInLogo from '../../assets/onboarding/google-sign-in-logo.png'
import Analytics from '../../common/analytics/capture-analytics-actions'
import { safeNavigate } from '../../common/navigate'

import { SHOULD_SKIP_LANDING_COOKIE_NAME } from '../../common/contexts/AuthContext'
import { RouteType, RoutesPath } from '../../common/routes'
import { ThemeProvider, cx, getThemes, styled } from '../../common/stationary'
import { deleteCookie } from '../../common/utils'
import { isDesktop, isMobile } from '../../common/utils/env'
import { useAuth } from '../../providers'
import { AuthStyles, AuthStyles as S } from './AuthStyles'

import AuthLogo from './components/AuthLogo'
import LoginFooter from './components/LoginFooter'
import LoginForm from './components/LoginForm'
import PasswordEmailSentMessage from './components/PasswordEmailMessage'
import PkceErrorMessage from './components/PkceErrorMessage'
import WaitingForAuth from './components/WaitingForAuth'
import LoginWithBrowser from './components/LoginWithBrowser'
import OpenDesktopApp from './components/OpenDesktopApp'
import { Env } from '../../common/env'

const { StationaryLight } = getThemes()

const LoginScreen: FC<RouteComponentProps> = () => {
  const location = useLocation()
  const {
    isLoggedIn,
    sendMagicLink,
    getIsUserWithPassword,
    authenticateViaPassword,
    sendResetPasswordEmail,
    sendSetPasswordEmail,
    setIsChromeExtensionAuthFlow,
    startGoogleOAuthAuthentication,
    isUserWithPassword,
    errorState,
    setErrorState,
  } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isMagicLinkEmailSuccess, setIsMagicLinkEmailSuccess] = useState(false)
  const [isEmailError, setIsEmailError] = useState(false)
  const [isPasswordLogin, setIsPasswordLogin] = useState(false)
  const [isPasswordError, setIsPasswordError] = useState(false)
  const queryParams = new URLSearchParams(location?.search)
  const [error] = useState(queryParams.get('error'))

  const [username, setUsername] = useState(``)
  const [password, setPassword] = useState(``)

  const [isResettingPassword, setIsResettingPassword] = useState(false)

  const isSentFromChromeExtension = queryParams.get('cx')
  useEffect(() => {
    setIsChromeExtensionAuthFlow(!!isSentFromChromeExtension)
    Analytics.signInScreenLoaded({
      sentFromChromeExtension: !!isSentFromChromeExtension,
    })
  }, [])

  const isDesktopAuth = queryParams.get('isDesktopAuth')

  const errorMessage = errorState ? (
    <>
      {errorState === 'expired' && (
        <>
          This link is no longer valid. Please try again or{' '}
          <a href="mailto:membership@capture.so">contact support</a>
        </>
      )}
      {errorState === 'oauth_failure' && (
        <>
          No account associated with this Google email. If this is an error,
          please <a href="mailto:membership@capture.so">contact support</a>
        </>
      )}
      {errorState === 'unknown' && (
        <>
          Unknown error logging in. Please try again or{' '}
          <a href="mailto:membership@capture.so">contact support</a>
        </>
      )}
    </>
  ) : isEmailError ? (
    <>
      We couldn’t verify the email address you provided.{' '}
      <strong>Try a different email</strong>
    </>
  ) : isPasswordError ? (
    <>
      Your password was incorrect. <strong>Please try again</strong>
    </>
  ) : error ? (
    <>
      {error === 'expired_link' && (
        <>
          This link is no longer valid. Please try again or{' '}
          <a href="mailto:membership@capture.so">contact support</a>
        </>
      )}
      {error !== 'expired_link' && (
        <>
          Unknown error logging in. Please try again or{' '}
          <a href="mailto:membership@capture.so">contact support</a>
        </>
      )}
    </>
  ) : null

  useLayoutEffect(() => {
    if (isLoggedIn && !isDesktopAuth) {
      safeNavigate(RoutesPath[RouteType.Inbox]).finally(null)
    }
  }, [isLoggedIn])

  const handleSendMagicLinkSuccess = (email: string) => {
    setIsLoading(false)
    setIsEmailError(false)
    setIsMagicLinkEmailSuccess(true)
    Analytics.signInScreenSubmitEmailSucceeded({ emailAddressSubmitted: email })
  }

  const handleSendEmailFailure = (email: string) => {
    setIsLoading(false)
    setIsEmailError(true)
    setIsMagicLinkEmailSuccess(false)
    Analytics.signInScreenSubmitEmailFailed({ emailAddressSubmitted: email })
  }

  const resetFromPasswordLoginFlow = () => {
    setIsPasswordLogin(false)
    setIsResettingPassword(false)
  }

  const handleMagicLinkLoginStart = () => {
    sendMagicLink(username)
      .then((responseStatus) => {
        handleSendMagicLinkSuccess(username)
      })
      .catch(() => {
        handleSendEmailFailure(username)
      })
      .finally(() => {
        resetFromPasswordLoginFlow()
      })
  }

  const handleCreatePasswordFlowStart = () => {
    sendSetPasswordEmail(username)
      .then(() => {
        setIsResettingPassword(true)
        setIsMagicLinkEmailSuccess(false)
      })
      .catch(() => {
        setIsEmailError(true)
        handleSendEmailFailure(username)
        resetFromPasswordLoginFlow()
      })
  }

  const determineAndContinueLoginPath = async () => {
    if (!isPasswordLogin) {
      setIsLoading(true)

      const { offerPasswordLogin } = await getIsUserWithPassword(username)

      if (offerPasswordLogin) {
        setIsPasswordLogin(true)
        setIsMagicLinkEmailSuccess(false)
        setIsEmailError(false)
        setIsLoading(false)
        setIsPasswordError(false)
        return
      }
    }
    if (isPasswordLogin) {
      setIsLoading(true)
      try {
        await authenticateViaPassword(username, password)
      } catch (error) {
        setIsPasswordError(true)
      }
      setIsLoading(false)
    } else {
      handleMagicLinkLoginStart()
    }
  }

  const resetLoginState = () => {
    setIsMagicLinkEmailSuccess(false)
    setErrorState(undefined)
    resetFromPasswordLoginFlow()
  }

  const handleSendResetPasswordEmail = async (event: React.MouseEvent) => {
    event.preventDefault()
    try {
      await sendResetPasswordEmail(username)
      setIsResettingPassword(true)
    } catch (error) {
      setIsEmailError(true)
    }
  }

  const startGoogleOAuth = () => {
    Analytics.signInGoogleOauthStarted()
    startGoogleOAuthAuthentication()
  }

  if (errorState === 'pkce-mismatch')
    return <PkceErrorMessage setErrorState={setErrorState} />

  if (isResettingPassword)
    return (
      <PasswordEmailSentMessage
        resetLoginState={resetLoginState}
        handleMagicLinkLoginStart={handleMagicLinkLoginStart}
      />
    )

  return (
    <ThemeProvider theme={StationaryLight}>
      <S.AuthScreen>
        <Helmet>
          <title>Capture - Log in</title>
        </Helmet>
        <S.Main>
          <S.AuthCardWrapper>
            {isDesktopAuth && isLoggedIn ? (
              <OpenDesktopApp queryParams={queryParams} />
            ) : isDesktop ? (
              <LoginWithBrowser />
            ) : (
              <S.AuthCard>
                {isMagicLinkEmailSuccess ? (
                  <WaitingForAuth emailAddress={username} />
                ) : (
                  <>
                    <S.AuthHeader>
                      <AuthLogo
                        href={!isMobile ? '/' : undefined}
                        secondaryIconSrc={
                          isSentFromChromeExtension ? ChromeLogo : undefined
                        }
                        mode="default"
                      />
                      <S.AuthTitle>
                        <strong>Log in to Capture</strong>
                      </S.AuthTitle>
                      {isSentFromChromeExtension && (
                        <S.ClipperMessage>
                          Web Clipper for Chrome
                        </S.ClipperMessage>
                      )}
                    </S.AuthHeader>
                    <S.AuthBody>
                      <LoginForm
                        isLoading={isLoading}
                        onSubmit={(event) => {
                          event.preventDefault()
                          determineAndContinueLoginPath()
                        }}
                        isPasswordLogin={isPasswordLogin}
                        password={password}
                        setPassword={setPassword}
                        username={username}
                        setUsername={setUsername}
                        resetLoginState={resetLoginState}
                      />
                      {errorMessage && (
                        <S.AuthMessage
                          className={cx({
                            isError: errorMessage !== null,
                          })}
                        >
                          {errorMessage}
                        </S.AuthMessage>
                      )}
                      {isPasswordLogin && (
                        <LoginScreenStyles.ResetPassword>
                          <a onClick={handleSendResetPasswordEmail}>
                            Forgot password?
                          </a>
                        </LoginScreenStyles.ResetPassword>
                      )}
                    </S.AuthBody>
                    <LoginScreenStyles.RuleForOr>
                      <LoginScreenStyles.hr />
                      <LoginScreenStyles.or>OR</LoginScreenStyles.or>
                      <LoginScreenStyles.hr />
                    </LoginScreenStyles.RuleForOr>
                    <>
                      <LoginScreenStyles.GoogleButton
                        type="button"
                        value="Google OAuth"
                        onClick={startGoogleOAuth}
                      >
                        <LoginScreenStyles.GoogleIcon
                          style={{
                            backgroundImage: `url(${GoogleSignInLogo})`,
                          }}
                        />
                        Continue with Google
                      </LoginScreenStyles.GoogleButton>
                    </>
                  </>
                )}
              </S.AuthCard>
            )}
            {isMagicLinkEmailSuccess ? (
              <S.AlternativeLoginMethodMessage
                onClick={() => {
                  if (isUserWithPassword) {
                    resetLoginState()
                    setIsPasswordLogin(true)
                  } else handleCreatePasswordFlowStart()
                }}
              >
                Log in with password
              </S.AlternativeLoginMethodMessage>
            ) : isPasswordLogin ? (
              <S.AlternativeLoginMethodMessage
                onClick={() => {
                  handleMagicLinkLoginStart()
                }}
              >
                Log in without password
              </S.AlternativeLoginMethodMessage>
            ) : (
              <LoginScreenStyles.RegisterForAccount>
                Don't have an account?
                <a
                  /**
                   * Ensure cookie that "skips landing" is deleted
                   */
                  onClick={() => {
                    isDesktop
                      ? //@ts-ignore
                        window.todesktop.contents.openUrlInBrowser(
                          Env.captureWebUrl,
                        )
                      : deleteCookie(SHOULD_SKIP_LANDING_COOKIE_NAME)
                  }}
                  href="/#waitlist"
                >
                  Join the waitlist →
                </a>
              </LoginScreenStyles.RegisterForAccount>
            )}
          </S.AuthCardWrapper>
        </S.Main>
        {!isMobile && <LoginFooter />}
      </S.AuthScreen>
    </ThemeProvider>
  )
}

export default LoginScreen

const LoginScreenStyles = {
  ResetPassword: styled.div(({ theme }) => ({
    marginTop: 24,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[700],
    cursor: 'pointer',

    a: {
      fontWeight: 600,

      ':hover': {
        textDecoration: 'underline',
      },
    },
  })),

  RegisterForAccount: styled.div(({ theme }) => ({
    paddingTop: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    height: 24,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[700],

    a: {
      fontWeight: 600,

      ':hover': {
        textDecoration: 'underline',
      },
    },
  })),

  GoogleButton: styled(S.AuthSubmit)(({ theme }) => ({
    color: theme.colors.text[900],
    backgroundColor: 'white',
    fontWeight: 600,
    boxShadow: 'none',
    border: `1px solid ${theme.colors.alpha.border.medium}`,
    marginTop: 0,

    ':hover': {
      border: `1px solid ${theme.colors.alpha.border.mediumStrong}`,
    },
  })),

  GoogleIcon: styled.div(({ theme }) => ({
    height: 20,
    width: 20,
    zIndex: 1,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  })),

  hr: styled.div(({ theme }) => ({
    borderTop: `1px solid ${theme.colors.alpha.border.medium}`,
    flex: '1 1 auto',
    width: '35%',
    marginTop: 12,
  })),

  or: styled.div(({ theme }) => ({
    color: theme.colors.text[800],
    fontWeight: 600,
    fontSize: 12,
    marginLeft: 12,
    marginRight: 12,
  })),

  RuleForOr: styled.div(({ theme }) => ({
    display: 'flex',
    marginTop: 16,
    marginBottom: 16,
  })),

  LoginWithBrowser: styled(AuthStyles.AuthSubmit)(({}) => ({
    marginTop: '20%',
  })),
}
