import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'

import { RecordItemData } from '../../../common/data/recordGroups'
import { Tag, TagType } from '../../../common/data/tags'
import {
  PropertiesListEventHandlers,
  usePropertiesListActions,
} from '../../../common/recordItemEventHandlers/propertiesListEventHandlers'
import { RecordItemTagClick } from '../../../common/recordItemEventHandlers/types'
import {
  Button,
  Icon,
  MenuDivider,
  MenuItem,
  styled,
  withMenu,
} from '../../../common/stationary'
import { emptyFn } from '../../../common/utils'
import TagStripItem from '../../../components/RecordItem/components/TagStripItem'

export interface DetailsHeaderProps {
  record: RecordItemData
  className?: string
  tags: Tag[]
  onTagClick?: RecordItemTagClick
  skipOccurrence?: (recordId: string) => void
  isCompleted?: boolean
  notesOpen?: boolean
  propertiesListEventHandlers: PropertiesListEventHandlers
}

const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  record,
  tags,
  onTagClick = emptyFn,
  skipOccurrence = emptyFn,
  isCompleted,
  notesOpen,
  propertiesListEventHandlers,
}: DetailsHeaderProps) => {
  const [showMoreActions, setShowMoreActions] = useState(false)

  const sortedTags = sortStripTags(tags)

  const toggleDropDown = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()
      setShowMoreActions((value) => !value)
    },
    [setShowMoreActions],
  )

  const closeDropDown = useCallback(
    () => setShowMoreActions(false),
    [setShowMoreActions],
  )

  const actions = usePropertiesListActions(
    record,
    propertiesListEventHandlers,
    closeDropDown,
    notesOpen,
  )

  return (
    <S.DetailsHeader>
      {sortedTags.map((tag, index) => (
        <React.Fragment key={tag.text}>
          {tag.type === TagType.PLACE ? <S.Filler /> : null}
          {tag.type === TagType.NOTES && index > 0 ? <S.Separator /> : null}
          <S.TagItem
            onTagClick={(event) => onTagClick(event, record.id, tag)}
            skipOccurrence={() => skipOccurrence(record.id)}
            tag={tag}
            isCompleted={isCompleted}
            notesOpen={notesOpen}
          />
        </React.Fragment>
      ))}
      {!isEmpty(sortedTags) ? <S.Separator /> : null}
      <S.AddProperty
        onClick={toggleDropDown}
        popperProps={{
          offset: [-12, 4],
          placement: 'bottom-start',
        }}
        menuProps={{
          children: actions.map(({ type, menuItemProps }, index) =>
            type === 'menuItem' ? (
              <MenuItem key={index} {...menuItemProps} />
            ) : type === 'divider' ? (
              <MenuDivider key={index} />
            ) : undefined,
          ),
          open: showMoreActions,
          onClose: closeDropDown,
        }}
      >
        <S.Icon boxSize={16} variant="glyphThreeDotsHorizontal" />
        Actions
      </S.AddProperty>
      <S.Filler />
    </S.DetailsHeader>
  )
}

export default DetailsHeader

export const DetailsHeaderHeight = 48

const S = {
  DetailsHeader: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    userSelect: `none`,
    outline: `none`,
    width: `100%`,
    height: `40px`,
    marginTop: `calc(20px + env(safe-area-inset-top))`,
    marginBottom: `4px`,
    background: theme.colors.alpha.tone.extraWeak,
    borderRadius: 8,
    padding: `0 20px`,
  })),
  Filler: styled.div({
    display: `flex`,
    flex: 1,
  }),
  Separator: styled.div(({ theme }) => ({
    display: `flex`,
    height: 12,
    width: 1,
    marginLeft: 8,
    marginRight: 8,
    background: theme.colors.alpha.tone.strong,
  })),
  TagItem: styled(TagStripItem)({
    marginLeft: 2,
  }),
  AddProperty: styled(withMenu(Button))(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    color: theme.colors.text[500],
    ...theme.text.publicSans['13.5:20'],
    height: 24,
    // width: 24,
    fontWeight: 600,
    lineHeight: `16px`,
    cursor: `pointer`,

    '&:hover': {
      color: theme.colors.text[900],
    },
  })),
  Icon: styled(Icon)({
    display: `flex`,
    marginRight: 8,
  }),
}

function sortStripTags(tags: Tag[]) {
  return [
    ...tags.filter((tag) => tag.type === TagType.DATE),
    ...tags.filter((tag) => tag.type === TagType.NOTES),
    ...tags.filter((tag) => tag.type === TagType.PLACE),
  ]
}
