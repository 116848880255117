import { DependencyList, useCallback, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventHandler = (...args: any[]) => any

export default (
  handler: EventHandler | undefined,
  deps?: DependencyList,
): EventHandler => {
  const eventHandlerRef = useRef<EventHandler | undefined>(handler)
  eventHandlerRef.current = handler
  const eventHandler: EventHandler = useCallback((...args) => {
    if (eventHandlerRef.current === undefined) return
    return eventHandlerRef.current(...args)
  }, deps ?? [])
  return eventHandler
}
