/* eslint-disable @typescript-eslint/dot-notation */

/* eslint-disable @typescript-eslint/promise-function-async */
import { getAWSSignedData } from './getAWSSignedData'
import { getFileMetadata } from './getFileMetadata'
import { getUrlMetadata } from './getUrlMetadata'
import { setClientTimezone } from './setClientTimezone'
import { uploadFileToAWS } from './uploadFileToAWS'

export const API = {
  getAWSSignedData,
  getFileMetadata,
  getUrlMetadata,
  setClientTimezone,
  uploadFileToAWS,
}
