// @ts-expect-error library does not provide any types
import deselectCurrent from 'toggle-selection'

import { RICH_TEXT_EDITOR_CLIPBOARD_MIME_TYPE } from '@eleventhlabs/capture-shared'
import { emptyFn } from './utils'

export const wrapRawTextForCopy = (
  text: string,
): { type: `raw`; text: string; mimeType: `text/plain` } => {
  return {
    type: `raw`,
    text,
    mimeType: `text/plain`,
  }
}

export const wrapMarkdownForCopy = (
  text: string,
): { type: `markdown`; text: string; mimeType: `text/plain` } => {
  return {
    type: `markdown`,
    text,
    mimeType: `text/plain`,
  }
}

export const wrapHtmlForCopy = (
  text: string,
): { type: `html`; text: string; mimeType: `text/html` } => {
  return {
    type: `html`,
    text,
    mimeType: `text/html`,
  }
}

const clipboardToIE11Formatting = {
  'text/plain': `Text`,
  'text/html': `Url`,
  [RICH_TEXT_EDITOR_CLIPBOARD_MIME_TYPE]: `Text`,
  default: `Text`,
}

interface CopyData {
  mimeType:
    | `text/plain`
    | `text/html`
    | `default`
    | typeof RICH_TEXT_EDITOR_CLIPBOARD_MIME_TYPE
  text: string
}

export const copyToClipboard = async (items: CopyData[]): Promise<void> => {
  let selection: Selection | null = null
  let range: Range | null = null
  let mark
  let reselectPrevious = emptyFn
  try {
    reselectPrevious = deselectCurrent()

    range = document.createRange()
    selection = document.getSelection()

    mark = document.createElement(`span`)
    mark.textContent = `placeholder`
    // reset user styles for span element
    mark.style.all = `unset`
    // prevents scrolling to the end of the page
    mark.style.position = `fixed`
    mark.style.top = `0`
    mark.style.clip = `rect(0, 0, 0, 0)`
    // used to preserve spaces and line breaks
    mark.style.whiteSpace = `pre`
    // do not inherit user-select (it may be `none`)
    mark.style.webkitUserSelect = `text`
    ;(mark.style as any).MozUserSelect = `text`
    ;(mark.style as any).msUserSelect = `text`
    mark.style.userSelect = `text`

    mark.addEventListener(`copy`, function (e: ClipboardEvent) {
      e.stopPropagation()
      e.preventDefault()
      clearClipboard(e)
      items.map((item) => copyItem(e, item))
    })

    document.body.appendChild(mark)

    range.selectNodeContents(mark)
    selection?.addRange(range)

    const successful = document.execCommand(`copy`)
    if (!successful) {
      throw new Error(`copy command was unsuccessful`)
    }
  } catch (err) {
    console.error(`unable to copy using execCommand: `, err)
  } finally {
    if (selection) {
      if (range && typeof selection.removeRange == `function`) {
        selection.removeRange(range)
      } else {
        selection.removeAllRanges()
      }
    }

    if (mark) {
      document.body.removeChild(mark)
    }
    reselectPrevious()
  }
}

const copyItem = (e: ClipboardEvent, item: CopyData) => {
  if (typeof e.clipboardData === `undefined`) {
    // IE 11
    const format =
      clipboardToIE11Formatting[item.mimeType] ||
      clipboardToIE11Formatting.default
    ;(window as any).clipboardData.setData(format, item.text)
  } else {
    // all other browsers
    e.clipboardData?.setData(item.mimeType, item.text)
  }
}

const clearClipboard = (e: ClipboardEvent) => {
  if (typeof e.clipboardData === `undefined`) {
    // IE 11
    ;(window as any).clipboardData.clearData()
  } else {
    // all other browsers
    e.clipboardData?.clearData()
  }
}
