import React, { ReactNode, createContext, useContext, useMemo } from 'react'

import { CustomReplicache } from './types'
import { useReplicache } from './useReplicache'

type Ready = {
  rep: CustomReplicache
  isReady: true
}
type NotReady = {
  rep: CustomReplicache | null
  isReady: false
}

export type ReplicacheContextValue = Ready | NotReady

/*
 * Context
 */

export const ReplicacheContext = createContext<
  ReplicacheContextValue | undefined
>(undefined)
ReplicacheContext.displayName = `ReplicacheContext`

/*
 * Provider
 */

export interface ReplicacheProviderProps {
  children: ReactNode
}

export const ReplicacheProvider = ({ children }: ReplicacheProviderProps) => {
  const { rep, isReady } = useReplicache()
  const contextValue = useMemo(() => ({ rep, isReady }), [rep, isReady]) as
    | Ready
    | NotReady
  return (
    <ReplicacheContext.Provider value={contextValue}>
      {children}
    </ReplicacheContext.Provider>
  )
}

/*
 * Hooks
 */

export const useReplicacheContext = (): ReplicacheContextValue => {
  const context = useContext(ReplicacheContext)

  if (context === undefined) {
    throw new Error(
      `useReplicacheContext must be used within an ReplicacheContext.Provider`,
    )
  }

  return context
}
