/**
 * Configures env values used in the application.
 * Usage of process.env here is an affordance provided by Gatsby, this is NOT a Node.js file:
 * @see https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables/#accessing-environment-variables-in-the-browser
 */
import packageJson from '../../package.json'

export enum EnvName {
  Development = `development`,
  Production = `production`,
}

export const Env = {
  amplitudeApiEndpoint: process.env.GATSBY_AMPLITUDE_ENDPOINT,
  androidUpdateDeeplinkUrl:
    process.env.GATSBY_ANDROID_UPDATE_DEEPLINK_URL ?? ``,
  appEnv: process.env.GATSBY_APP_ENV,
  avoHideWebDebugger: process.env.GATSBY_AVO_HIDE_WEB_DEBUGGER === `TRUE`,
  avoNoop: process.env.GATSBY_AVO_NOOP === `TRUE`,
  avoSuppressLogs: process.env.GATSBY_AVO_SUPPRESS_LOGS === `TRUE`,
  captureApiUrl: process.env.GATSBY_CAPTURE_API_URL ?? '',
  captureContactPhoneNumber:
    process.env.GATSBY_CAPTURE_CONTACT_PHONE_NUMBER ?? '',
  captureWebUrl: process.env.GATSBY_CAPTURE_WEB_URL ?? ``,
  COMMAND_BAR_TEAM_ID: process.env.GATSBY_COMMAND_BAR_TEAM_ID ?? '',
  customAppDeeplinkProtocol:
    process.env.GATSBY_CUSTOM_APP_DEEPLINK_PROTOCOL ?? ``,
  DESKTOP_MAIN_CAPTURE_WINDOW_ID:
    process.env.GATSBY_DESKTOP_MAIN_CAPTURE_WINDOW_ID ?? '',
  DESKTOP_QUICK_CAPTURE_WINDOW_ID:
    process.env.GATSBY_DESKTOP_QUICK_CAPTURE_WINDOW_ID ?? '',
  DESKTOP_TRAY_ID: process.env.GATSBY_DESKTOP_TRAY_ID ?? '',
  DOWNLOAD_ANDROID_INSTRUCTIONS:
    process.env.GATSBY_DOWNLOAD_ANDROID_INSTRUCTIONS ?? '',
  DOWNLOAD_IOS_INSTRUCTIONS: process.env.GATSBY_DOWNLOAD_IOS_INSTRUCTIONS ?? '',
  hostURI: process.env.GATSBY_HOST_URI ?? '',
  iosUpdateDeeplinkUrl: process.env.GATSBY_IOS_UPDATE_DEEPLINK_URL ?? ``,
  isSSR: typeof window === `undefined`, // Investigate "safer" isSSR check, possible to get value from env?
  // URL to proxy logs (helps us get around ad-blockers)
  logUrl: process.env.GATSBY_LOG_PROXY_URL ?? ``,
  name: process.env.NODE_ENV as EnvName,
  oneSignalAppId: process.env.GATSBY_ONESIGNAL_APP_ID ?? ``,
  pathPrefix: process.env.GATSBY_PATH_PREFIX,
  publicTestflightUrl: process.env.GATSBY_CAPTURE_PUBLIC_TESTFLIGHT_LINK ?? ``,
  pusherAppId: process.env.GATSBY_PUSHER_APP_ID ?? ``,
  pusherCluster: process.env.GATSBY_PUSHER_CLUSTER ?? ``,
  pusherKey: process.env.GATSBY_PUSHER_KEY ?? ``,
  pusherSecret: process.env.GATSBY_PUSHER_SECRET ?? ``,
  replicacheLicenseKey: process.env.GATSBY_REPLICACHE_LICENSE_KEY ?? ``,
  // URL to proxy cdn fetches to (helps us get around ad-blockers)
  segmentProxyCDNUrl: process.env.GATSBY_SEGMENT_PROXY_CDN_URL ?? ``,
  sendToURI: process.env.GATSBY_SEND_TO_HOST_URI,
  stytchPublicToken: process.env.GATSBY_STYTCH_PUBLIC_TOKEN ?? ``,
  TEXT_YOURSELF_INSTRUCTIONS:
    process.env.GATSBY_TEXT_YOURSELF_INSTRUCTIONS ?? '',
}

export const CURRENT_APP_VERSION = packageJson.version
