import React from 'react'

import {
  Button,
  Icon,
  IconVariantName,
  MenuItem,
  cx,
  styled,
  withMenu,
} from '../../../../common/stationary'

export interface OutlineListItemAction {
  color?: string
  iconVariant: IconVariantName
  onClick: React.MouseEventHandler
  title: string
}

export interface OutlineListItemActionsProps {
  actions: OutlineListItemAction[]
  className?: string
  isMenuVisible?: boolean
  setIsMenuVisible?: (open: boolean) => void
}

const OutlineListItemActions: React.FC<OutlineListItemActionsProps> = ({
  actions,
  className,
  isMenuVisible,
  setIsMenuVisible = () => undefined,
}) => {
  const onClick: React.MouseEventHandler = (e) => {
    e.stopPropagation() // Don't propagate event up to OutlineListItem
    setIsMenuVisible(!isMenuVisible)
  }

  return (
    <S.Actions className={className}>
      <S.MenuButton
        className={cx({ isMenuVisible })}
        menuProps={{
          children: actions.map(
            ({ color, iconVariant, onClick, title }, index) => (
              <MenuItem
                key={index}
                iconProps={{ variant: iconVariant }}
                onClick={onClick}
                style={{ color }}
                title={title}
              />
            ),
          ),
          open: isMenuVisible,
          onClose: () => setIsMenuVisible(false),
        }}
        onClick={onClick}
        popperProps={{
          offset: [4, 4],
          placement: 'bottom-end',
        }}
      >
        <Icon boxSize={16} variant="glyphThreeDotsHorizontal" />
      </S.MenuButton>
    </S.Actions>
  )
}

export default OutlineListItemActions

const S = {
  Actions: styled.div({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    marginRight: 6,
    pointerEvents: 'none',
  }),
  MenuIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.gray[700],
  })),
  MenuButton: styled(withMenu(Button))(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 28,
    width: 28,
    borderRadius: 6,
    color: theme.colors.text[600],
    pointerEvents: 'initial',
    cursor: 'pointer',
    transition: 'background 0.2s ease',

    '&:hover': {
      color: theme.colors.text[900],
      background: theme.colors.alpha.tone.weak,
    },
    '&:active, &.isMenuVisible': {
      color: theme.colors.text[900],
      background: theme.colors.alpha.tone.mediumWeak,
    },
  })),
}
