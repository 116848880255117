import React, { useCallback, useMemo } from 'react'

import { useMobileAppPlatform } from '../utils/env/useMobileAppPlatform'
import { GenericErrorBoundaryProps } from './types'
import { MobileAppPlatform } from '../utils/env'
import { useMailToContactTemplateLink } from './utils'
import { DataRecord } from '@eleventhlabs/capture-shared'
import { RecordItemData } from '../data/recordGroups'
import { Env } from '../env'
import { isNoteRecord } from '@eleventhlabs/capture-shared'
import { Icon, cx, styled } from '../stationary'
import { triggerAppRefresh } from '../utils/refresh'

export interface ContentErrorBoundaryErrorProps {
  record?: DataRecord | RecordItemData
  isFullPage?: boolean
  hasTags?: boolean
}
export interface ContentErrorBoundaryProps
  extends GenericErrorBoundaryProps<ContentErrorBoundaryErrorProps> {}

const ContentErrorBoundary: React.FC<ContentErrorBoundaryProps> = ({
  error,
  errorProps,
}) => {
  const mobileAppPlatform = useMobileAppPlatform()

  const hasTags = errorProps?.hasTags ?? false
  const record = errorProps?.record ?? undefined
  const isFullPage = errorProps?.isFullPage ?? false

  const isNote = useMemo(() => {
    if (!record) return false

    return !('notesList' in record) && isNoteRecord(record)
  }, [record])

  const isCompleted = useMemo(() => {
    if (!record) return false

    return `isCompleted` in record && record.isCompleted
  }, [record])

  const mailToLink = useMailToContactTemplateLink({
    error,
    record,
    isFullPage,
  })

  const { refreshCopyText, refreshButtonText } = useMemo(() => {
    if (isFullPage) {
      return {
        refreshCopyText: 'Refresh your browser to view this page.',
        refreshButtonText: 'Refresh',
      }
    }

    if (!mobileAppPlatform) {
      return {
        refreshCopyText: 'Refresh your browser to view this record.',
        refreshButtonText: 'Refresh',
      }
    }
    return {
      refreshCopyText: 'Update your app to view this record.',
      refreshButtonText: 'Update',
    }
  }, [])

  const onRefreshButtonClick = useCallback(() => {
    triggerAppRefresh(mobileAppPlatform)
  }, [])

  return (
    <S.ErrorBoundary
      className={cx({
        hasTags,
        isCompleted,
        isNote,
      })}
    >
      <S.ErrorBoundaryInner
        className={cx({
          hasTags,
        })}
      >
        <S.ErrorTextWrapper style={{ width: `100%` }}>
          <S.ErrorText>
            <S.ErrorTextFirstWrapper>
              <S.WarningIcon>
                <Icon boxSize={16} variant="glyphWarning" />
              </S.WarningIcon>
              <S.FirstLineTextWrapper>
                <S.BoldText>
                  Capture is out of date
                  <S.Divider>&#183;</S.Divider>
                </S.BoldText>
                <span style={{ fontStyle: 'italic' }}>{refreshCopyText}</span>
              </S.FirstLineTextWrapper>
            </S.ErrorTextFirstWrapper>
            <S.CTAButton type="button" onClick={onRefreshButtonClick}>
              <Icon boxSize={16} variant="glyphCcwCircle" />
              {refreshButtonText}
            </S.CTAButton>
          </S.ErrorText>
          <S.ErrorTextMore>
            <div style={{ marginTop: 10 }}>
              <Icon boxSize={12} variant="glyphArrowCurvedE12" />
            </div>
            <S.StillHavingIssues>
              Still having issues?
              <S.GetInTouch
                onClick={() => window.open(mailToLink)}
                type="button"
              >
                <Icon boxSize={16} variant="glyphLetter" />
                Get in touch
              </S.GetInTouch>
              with our Membership team ✨
            </S.StillHavingIssues>
          </S.ErrorTextMore>
        </S.ErrorTextWrapper>
      </S.ErrorBoundaryInner>
    </S.ErrorBoundary>
  )
}

export default ContentErrorBoundary

const S = {
  ErrorBoundary: styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: `24px`,
    width: `100%`,
    userSelect: 'text',
    whiteSpace: `pre-wrap`,
    wordBreak: `break-word`,

    '&.hasTags': {
      paddingBottom: 28,
    },

    '&.isCompleted': {
      opacity: `.5`,
    },

    '&.isNote': {
      paddingTop: 16,
    },
  })),
  WarningIcon: styled.div({
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 4,
  }),
  FirstLineTextWrapper: styled.div({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
  }),
  ErrorText: styled.div({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: `100%`,

    '@media only screen and (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }),
  ErrorTextFirstWrapper: styled.div({
    display: 'flex',
    flexDirection: 'row',
    flex: `1 1 auto`,
    flexWrap: 'nowrap',
    gap: 4,
  }),
  Divider: styled.div({
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    },
  }),
  ErrorTextMore: styled.div({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: 4,
    flex: '1 1 auto',

    '@media only screen and (max-width: 600px)': {
      marginTop: `40px`,
    },
  }),
  StillHavingIssues: styled.div({
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 4,
  }),
  ErrorBoundaryInner: styled.div(({ theme }) => ({
    width: `100%`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.colors.alpha.border.weak,
    borderRadius: 6,
    backgroundColor: theme.colors.orange[100],
    color: theme.colors.orange[700],
    marginBottom: 28,
    margin: 8,
    '&.hasTags': {
      marginBottom: 24,
    },
    padding: `10px 16px`,
  })),
  ErrorTextWrapper: styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: `100%`,
  }),
  GetInTouch: styled.button(({ theme }) => ({
    all: 'unset',
    display: 'flex',
    flex: `1 1 auto`,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: 6,
    boxShadow: `0px 2px 0px ${theme.colors.orange[200]}, 0px 0px 0px 1px ${theme.colors.orange[200]}`,
    borderRadius: 6,
    color: 'inherit',
    padding: `2px 8px`,
    fontWeight: 'bold',
    width: 116,
    maxWidth: 116,
    height: 28,
  })),
  BoldText: styled.span({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    gap: 4,
  }),
  CTAButton: styled.button(({ theme }) => ({
    all: 'unset',
    display: 'flex',
    gap: 6,
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.orange[700],
    fontWeight: 'bold',
    boxShadow: `0px 2px 0px ${theme.colors.orange[200]}, 0px 0px 0px 1px ${theme.colors.orange[200]}`,
    cursor: 'pointer',
    borderRadius: 6,
    textAlign: 'center',
    width: `93px`,
    minWidth: 93,
    height: `32px`,
    padding: `2px 4px`,
    marginBottom: -35,
    zIndex: 2,

    '@media only screen and (max-width: 600px)': {
      marginLeft: 20,
      marginTop: 4,
    },
  })),
}
