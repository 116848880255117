import { Env } from '../../env'
import { MobileAppPlatform } from '../env'

/**
 * Perform the necessary actions to get the user to refresh the app depending on if they are on mobile or web
 * @param mobileAppPlatform Comes from useMobileAppPlatform()
 */
export const triggerAppRefresh = (
  mobileAppPlatform: MobileAppPlatform | null,
) => {
  if (!mobileAppPlatform) window.location.reload()

  if (mobileAppPlatform === MobileAppPlatform.IOS) {
    // If we can't deeplink directly to the app for some reason, just open the
    // fallback install URL, which is Testflight for now.
    const opened = window.open(Env.iosUpdateDeeplinkUrl, `_blank`)
    if (!opened) {
      window.open(Env.publicTestflightUrl, `_blank`)
    }
  }
  if (mobileAppPlatform === MobileAppPlatform.ANDROID) {
    window.open(Env.androidUpdateDeeplinkUrl, `_blank`)
  }
}
