import { decode } from 'html-entities'
import React from 'react'

import { Icon, styled } from '../../../../../common/stationary'

interface URLTitleProps {
  loading?: boolean
  onClick?: (e: React.MouseEvent) => void
  title?: string
  url?: string
}

const preventDefault = (e: React.MouseEvent) => e.preventDefault()

export const URLTitle: React.FC<URLTitleProps> = ({
  loading,
  onClick,
  title,
  url,
}) => {
  return !loading ? (
    <S.Title onClick={onClick}>
      <S.Text href={url} onClick={preventDefault}>
        {decode(title ?? url)}
      </S.Text>
      <S.Icon boxSize={16} variant="glyphLinkSingle" />
    </S.Title>
  ) : (
    <S.Empty href={url} />
  )
}

const S = {
  Title: styled.div({
    alignItems: `center`,
    cursor: `pointer`,
    display: `flex`,
    flexDirection: `row`,
    height: 24,
    justifyContent: `flex-start`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
  }),
  Text: styled.a(({ theme }) => ({
    display: `block`,
    fontWeight: 600,
    ...theme.text.publicSans['16:24'],
    color: theme.colors.text[900],
    overflow: `hidden`,
    whiteSpace: `nowrap`,
    textOverflow: `ellipsis`,
    textDecoration: `underline`,
    textDecorationColor: theme.colors.text[500],
    textDecorationThickness: `1px`,

    '&:hover': {
      textDecorationColor: theme.colors.text[700],
    },
  })),
  Icon: styled(Icon)(({ theme }) => ({
    marginLeft: 8,
    color: theme.colors.text[700],
  })),
  Empty: styled.a(({ theme }) => ({
    display: `flex`,
    flex: `0 0 160px`,
    height: 16,
    background: theme.colors.alpha.tone.strong,
    borderRadius: 6,
  })),
}
