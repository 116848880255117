import React, { useMemo } from 'react'

import {
  MiniBarAction,
  MiniBarActionType,
} from '../../../common/recordItemEventHandlers'
import { Button, Icon, styled, withTooltip } from '../../../common/stationary'

export interface MiniBarProps {
  className?: string
  actions: MiniBarAction[]
}

export const MiniBar: React.FC<MiniBarProps> = ({ actions, className }) => {
  const standAloneActions = useMemo(
    () =>
      actions.filter((action) => action.type === MiniBarActionType.StandAlone),
    [actions],
  )
  const miniBarActions = useMemo(
    () => actions.filter((action) => action.type === MiniBarActionType.MiniBar),
    [actions],
  )

  return (
    <S.MiniBar className={className}>
      {standAloneActions.length > 0 && (
        <S.ActionGroup>
          {standAloneActions.map((action) => (
            <S.ActionItem
              key={action.title}
              tooltip={
                action.tooltip && {
                  title: action.tooltip.title,
                  keycaps: action.tooltip.keycaps,
                }
              }
              onClick={action.onMouseDown}
              variant="plain"
            >
              <Icon boxSize={16} variant={action.variant} />
            </S.ActionItem>
          ))}
        </S.ActionGroup>
      )}
      <S.ActionGroup>
        {miniBarActions.map((action) => (
          <S.ActionItem
            key={action.title}
            tooltip={
              action.tooltip && {
                title: action.tooltip.title,
                keycaps: action.tooltip.keycaps,
              }
            }
            onClick={action.onMouseDown}
            variant="plain"
          >
            <Icon boxSize={16} variant={action.variant} />
          </S.ActionItem>
        ))}
      </S.ActionGroup>
    </S.MiniBar>
  )
}

const S = {
  MiniBar: styled.div({
    position: `relative`,
    display: `flex`,
  }),
  ActionGroup: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    gap: 2,
    zIndex: 7,
    marginLeft: 4,
    padding: '1px 2px',
    background: theme.colors.surface.base,
    boxShadow: theme.effects.boxShadow.elevation[1],
    borderRadius: 6,
  })),
  ActionItem: styled(withTooltip(Button))({
    position: 'relative',
    height: 28,
    width: 28,
    borderRadius: 5,

    ':before': {
      content: '""',
      position: 'absolute',
      inset: -1,
    },
  }),
}
