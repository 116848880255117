import { useEffect } from 'react'

import { ScrollTarget, subscribeToScrollEvent } from '../../events'
import { ScrollCommands } from './types'

export const useScrollable = (
  target: ScrollTarget,
  commands: ScrollCommands,
) => {
  useEffect(() => {
    return subscribeToScrollEvent(({ payload }) => {
      if (payload.target === target) {
        const { to } = payload
        if (to.id != undefined) {
          commands.scrollToId(to.id)
          return true
        }
        if (to.index != undefined) {
          commands.scrollToIndex(to.index)
          return true
        }
        if (to.position != undefined) {
          commands.scrollToPosition(to.position)
          return true
        }
      }
      return false
    })
  }, [commands, target])
}
