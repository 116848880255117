import React from 'react'

import { styled } from '../../../common/stationary'

export interface NoResultsProps {
  query: string
}

const NoResults = ({ query }: NoResultsProps) => {
  return (
    <S.NoResults>
      No results found for <S.Query>{query}</S.Query>.
    </S.NoResults>
  )
}

export default NoResults

const S = {
  NoResults: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    padding: `0 20px 0 20px`,
    height: 40,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    fontWeight: `normal`,
    color: theme.colors.text[900],
  })),
  Query: styled.span(({ theme }) => ({
    padding: `0 6px`,
    margin: `0 3px`,
    background: theme.colors.alpha.tone.mediumWeak,
    borderRadius: 4,
  })),
}
