import { v4 as uuidv4 } from 'uuid'
import { isMacLike } from '../utils/env'

export interface ToDesktopRefObject {
  id: string
  namespace: string
  type: string
}

export const DESKTOP_TRAY_SHOW_QUICK_CAPTURE_EVENT = 'tray-show-quick-capture'

export const QUICK_CAPTURE_BROADCAST_CHANNEL_NAME = 'quickCaptureMessages'

export const QUICK_CAPTURE_SHORTCUT = isMacLike ? 'Alt+Space' : 'Ctrl+Alt+Space'

export const QUICK_CAPTURE_EDITOR_ID = `quick-capture-${uuidv4()}`
