import Pusher from 'pusher-js'
import { ApiEndpoints } from './ApiEndpoints'
import { apiCall, buildAPIRequestHeaders } from './api/client'
import { Env } from './env'

const pusher = new Pusher(Env.pusherKey, {
  cluster: Env.pusherCluster,
  channelAuthorization: {
    endpoint: ApiEndpoints.wsAuthURI,
    transport: 'ajax',
    // Custom handler to authenticate private channels. Pusher seems to cache the request headers
    // in the Pusher object when it's instantiated, but on first load, we have to wait for stytch cookies to
    // get populated in the browser. This custom handler lets us build the request headers on every subscription request
    // so we use the updated cookies on every request
    customHandler: async ({ channelName, socketId }, callback) => {
      let error: Error | null = null
      const authData = await apiCall(ApiEndpoints.wsAuthURI, {
        method: `POST`,
        headers: buildAPIRequestHeaders({
          'Content-Type': `application/json`,
        }),
        body: JSON.stringify({
          channel_name: channelName,
          socket_id: socketId,
        }),
      })
        .then(async (response) => {
          return await response.json()
        })
        .catch((e) => {
          if (e instanceof Error) {
            error = e
          }
        })

      callback(error, authData)
    },
  },
})

export default pusher
