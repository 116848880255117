import { useCallback } from 'react'

import { useLocalStorageBoolean } from '../LocalStorageContext'
import { isMobile } from '../../utils/env'

export const useIsLeftOrRightHidden = () => {
  const [isLeftHidden, setIsLeftHidden] = useLocalStorageBoolean(
    `isLeftHidden`,
    isMobile,
  )

  const [isRightHidden, setIsRightHidden] = useLocalStorageBoolean(
    `isRightHidden`,
    true,
  )

  const toggleIsLeftHidden = useCallback(() => {
    const nextValue = !isLeftHidden
    setIsLeftHidden(nextValue)
    return nextValue
  }, [isLeftHidden, setIsLeftHidden])

  const toggleIsRightHidden = useCallback(() => {
    const nextValue = !isRightHidden
    setIsRightHidden(nextValue)
    return isRightHidden
  }, [isRightHidden, setIsRightHidden])

  return {
    isLeftHidden,
    setIsLeftHidden,
    toggleIsLeftHidden,
    isRightHidden,
    setIsRightHidden,
    toggleIsRightHidden,
  }
}
