import React from 'react'

import { FloatingButtonType } from '../FloatingActions'
import {
  Button,
  ButtonProps,
  Icon,
  IconVariantName,
  WithTooltipProps,
  css,
  cx,
  styled,
  withTooltip,
} from '../../../common/stationary'

interface PrimaryButtonProps extends WithTooltipProps<ButtonProps> {
  isSelected: boolean
  isActive: boolean
  onPointerEnter: () => void
  setSelectedButton: (buttonType: FloatingButtonType) => void
  activeIconVariant: IconVariantName
  inactiveIconVariant: IconVariantName
}

export const PrimaryButton = React.forwardRef<
  HTMLButtonElement,
  PrimaryButtonProps
>(
  (
    {
      isSelected,
      isActive,
      onPointerEnter,
      setSelectedButton,
      activeIconVariant,
      inactiveIconVariant,
      ...props
    },
    ref,
  ) => (
    <S.PrimaryButton
      ref={ref}
      className={cx({
        isSelected,
      })}
      onPointerEnter={onPointerEnter}
      // https://stackoverflow.com/a/70976017
      onPointerDown={(e: any) => {
        setSelectedButton(FloatingButtonType.PRIMARY)
        e.target.releasePointerCapture(e.pointerId)
      }}
      {...props}
    >
      <Icon
        boxSize={16}
        variant={isActive ? activeIconVariant : inactiveIconVariant}
        className={css({
          color: 'white',
        })}
      />
    </S.PrimaryButton>
  ),
)

const S = {
  PrimaryButton: styled(withTooltip(Button))(({ theme }) => ({
    all: 'unset',
    height: '64px',
    width: '64px',
    borderRadius: '999px',
    boxShadow: theme.effects.boxShadow.elevation[3],
    background: `linear-gradient(#0000, rgb(0 0 0/40%)) top/100% 800%`,
    backgroundColor: theme.colors.blue[700],
    cursor: `pointer`,
    display: `flex`,
    alignItems: 'center',
    justifyContent: 'center',
    WebkitTouchCallout: `none`,
    WebkitUserSelect: `none`,
    WebkitTextSizeAdjust: `none`,

    '&.isSelected': {
      backgroundPosition: `bottom`,
    },
  })),
}
