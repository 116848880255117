import React from 'react'

import { KeyboardShortcutGroup as Group } from '../shortcutsGroups'
import { KeyboardShortcutsItem } from './KeyboardShortcutsItem'
import { styled } from '../../../common/stationary'

export interface KeyboardShortcutsGroupProps {
  group: Group
}

export const KeyboardShortcutsGroup: React.FC<KeyboardShortcutsGroupProps> = ({
  group,
}) => {
  return (
    <S.Group>
      {group.title && <S.Header>{group.title}</S.Header>}
      {group.shortcuts.map((item) => (
        <KeyboardShortcutsItem key={item.description} item={item} />
      ))}
    </S.Group>
  )
}

const S = {
  Group: styled.div({
    display: `flex`,
    width: `100%`,
    flexDirection: `column`,
  }),
  Header: styled.div(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    flex: `0 0 40px`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    ...theme.text.publicSans['11.5:16'],
    fontWeight: 700,
    textTransform: `uppercase`,
    letterSpacing: `1px`,
    paddingTop: `16px`,
    color: theme.colors.text[700],
  })),
}
