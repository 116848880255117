import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'

import { NoteListData } from '../../../../common/data/notes'
import { NoteHandlers } from '../../../../common/noteEventHandlers'
import { Note, withNoteEventHandlers } from '../../../Note'
import {
  cx,
  notesContentClassName,
  styled,
} from '../../../../common/stationary'

const NoteItem = withNoteEventHandlers(Note)

interface NoteListProps extends NoteListData {
  parentId: string
  handlers: NoteHandlers
  isMouseActive?: boolean
  isSelected?: boolean
  className?: string
}

export const NoteList = React.forwardRef<HTMLDivElement, NoteListProps>(
  (
    {
      parentId,
      handlers,
      notes,
      title,
      zeroStateMessage,
      isMouseActive,
      isSelected,
      className,
    },
    ref,
  ) => {
    const noNotesToDisplay = isEmpty(notes)

    const onNoteListMouseDown = useCallback(
      (e) => {
        handlers.onMouseDown && handlers.onMouseDown(e, ``, parentId)
      },
      [parentId, handlers],
    )

    return !isEmpty(notes) || zeroStateMessage ? (
      <S.NoteList
        className={cx(notesContentClassName, { isSelected }, className)}
        onMouseDown={onNoteListMouseDown}
      >
        {title ? <S.ListTitle>{title}</S.ListTitle> : null}
        {noNotesToDisplay && zeroStateMessage ? (
          <S.ZeroStateMessage>{zeroStateMessage}</S.ZeroStateMessage>
        ) : (
          <S.Notes ref={ref}>
            {notes.map((note) => (
              <NoteItem
                key={note.id}
                isSelected={isSelected}
                isMouseActive={isMouseActive}
                note={note}
                {...handlers}
              />
            ))}
          </S.Notes>
        )}
      </S.NoteList>
    ) : null
  },
)

const S = {
  NoteList: styled.div(({ theme }) => ({
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    borderTop: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    background: theme.colors.surface.base,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,

    '&.isSelected': {},
  })),
  ListTitle: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    flex: `0 0 24px`,
    width: `100%`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    fontWeight: `bold`,
    ...theme.text.publicSans['11.5:16'],
    lineHeight: `24px`,
    letterSpacing: `0.08em`,
    textTransform: `uppercase`,
    userSelect: `none`,
    color: theme.colors.text[700],
    marginBottom: -16,
    paddingTop: 15,
    paddingLeft: 20,
  })),
  ZeroStateMessage: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    flex: `0 0 20px`,
    width: `100%`,
    paddingLeft: 16,
    fontWeight: 400,
    userSelect: `none`,
    ...theme.text.publicSans['11.5:16'],
    lineHeight: `16px`,
    color: theme.colors.text[500],
  })),
  Notes: styled.div({
    display: `flex`,
    flexDirection: `column`,
    flex: 1,
    width: `100%`,
    alignItems: `center`,
    justifyContent: `flex-start`,
  }),
}
