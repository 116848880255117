import { createFileName } from '../files'
import { FileMetadata } from '@eleventhlabs/capture-shared'
import { getAWSSignedData } from './getAWSSignedData'
import { getFileMetadata } from './getFileMetadata'

export const uploadFileToAWS = async (
  userId: string,
  file: File,
  updateProgress: (progress: number) => void,
): Promise<FileMetadata> => {
  const awsSignedData = await getAWSSignedData()

  return await new Promise((resolve) => {
    const fileName = createFileName(file, {
      withUser: userId,
      withTimestamp: true,
    })

    const fd = new FormData()

    fd.append(`key`, fileName)
    fd.append(`acl`, awsSignedData.bucketAcl)
    fd.append(`Content-Type`, file.type)
    fd.append(`policy`, awsSignedData.encodedPolicy)
    fd.append(`x-amz-algorithm`, `AWS4-HMAC-SHA256`)
    fd.append(`x-amz-credential`, awsSignedData.amzCred)
    fd.append(`x-amz-date`, awsSignedData.expDate)
    fd.append(`X-Amz-Signature`, awsSignedData.sign)
    fd.append(`x-amz-security-token`, awsSignedData[`x-amz-security-token`])

    fd.append(`file`, file)

    const xhr = new XMLHttpRequest()
    const bucketWriteUrl = `https://${awsSignedData.bucket}.s3.amazonaws.com/`

    xhr.upload.addEventListener(
      `progress`,
      ({ loaded, total }) => {
        const progress = Math.round((loaded / total) * 100)
        updateProgress(progress)
      },
      false,
    )

    xhr.addEventListener(
      `load`,
      () => {
        resolve(getFileMetadata(file, fileName, bucketWriteUrl))
      },
      false,
    )

    xhr.addEventListener(`error`, () => resolve({}), false)

    xhr.addEventListener(`timeout`, () => resolve({}), false)

    xhr.open(`POST`, bucketWriteUrl, true)
    xhr.send(fd)
  })
}
