import React, { ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { AuthContext, useAuthContextValue } from './common/contexts/AuthContext'
import {
  DesktopAuthContext,
  useDesktopAuthContextValue,
} from './common/contexts/DesktopAuthContext'
import {
  LocalStorageContext,
  useLocalStorageContextValue,
} from './common/contexts/LocalStorageContext'
import {
  ThemeNameContext,
  useThemeName,
  useThemeNameValue,
} from './common/contexts/ThemeNameContext'
import { QuickCaptureInteractionProvider } from './common/contexts/onboarding/QuickCaptureInteractionContext'
import { useStytchLazyLoaded } from './common/hooks/useStytchLazyLoaded'
import { customScrollbarStyles } from './common/stationary/mixinStyles'
import {
  CSSObject,
  ThemeProvider as EmotionThemeProvider,
  Global,
  styles,
} from './common/stationary/styled'
import {
  getThemes,
  globalResetStyles,
  globalStyles,
} from './common/stationary/theme'
import { localStorageProxy } from './common/storage'

/**
 * Providers
 */
export const Providers = (props: { children: ReactNode }) => {
  const { StytchProvider } = useStytchLazyLoaded()
  return (
    <StytchProvider>
      <LocalStorageProvider>
        <AuthProvider>
          <DesktopAuthProvider>
            <ThemeNameProvider>
              <ThemeProvider>
                <QuickCaptureInteractionProvider>
                  {props.children}
                </QuickCaptureInteractionProvider>
              </ThemeProvider>
            </ThemeNameProvider>
          </DesktopAuthProvider>
        </AuthProvider>
      </LocalStorageProvider>
    </StytchProvider>
  )
}

/**
 * LocalStorageProvider
 */
const LocalStorageProvider = (props: { children: ReactNode }) => {
  const localStorageContextValue = useLocalStorageContextValue()

  useEffect(() => {
    localStorageProxy.registerStorageEventListener()
    return localStorageProxy.unRegisterStorageEventListener
  }, [])

  return (
    <LocalStorageContext.Provider value={localStorageContextValue}>
      {props.children}
    </LocalStorageContext.Provider>
  )
}

export { useAuth } from './common/contexts/AuthContext'
export { useLocalStorage } from './common/contexts/LocalStorageContext'

/**
 * AuthProvider
 */
const AuthProvider = (props: { children: ReactNode }) => {
  const authContextValue = useAuthContextValue()

  return (
    <AuthContext.Provider value={authContextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}

/**
 * DesktopAuthProvider
 */
const DesktopAuthProvider = (props: { children: ReactNode }) => {
  const desktopAuthContextValue = useDesktopAuthContextValue()

  return (
    <DesktopAuthContext.Provider value={desktopAuthContextValue}>
      {props.children}
    </DesktopAuthContext.Provider>
  )
}

/**
 * ThemeNameProvider
 */
const ThemeNameProvider = (props: { children: ReactNode }) => {
  const themeNameValue = useThemeNameValue()

  return (
    <ThemeNameContext.Provider value={themeNameValue}>
      {props.children}
    </ThemeNameContext.Provider>
  )
}

/**
 * @link https://emotion.sh/docs/theming#:~:text=Note%3A,have%20performance%20problems.
 */
const themes = getThemes()

/**
 * ThemeProvider
 */
const ThemeProvider = (props: { children: ReactNode }) => {
  const { themeName } = useThemeName()
  const theme = themes[themeName]

  return (
    <EmotionThemeProvider theme={theme}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Global
        styles={styles(
          globalResetStyles as CSSObject,
          globalStyles({ theme }),
          {
            body: {
              ...customScrollbarStyles({
                thumbColor: 'none',
                width: 0,
              }),
            },
          },
        )}
      />
      {props.children}
    </EmotionThemeProvider>
  )
}
