import React, { useEffect, useRef, useState } from 'react'

import { useCallback } from 'react'
import { useUIContext } from '../../common/contexts/UIContext'
import { useVirtualKeyboardState } from '../../common/hooks/mobile/listeners/useVirtualKeyboardState'
import { useLongPress } from '../../common/hooks/useLongPress'
import { css } from '../../common/stationary'
import { PrimaryButton } from './components/PrimaryButton'
import { SecondaryButton } from './components/SecondaryButton'
import { useFloatingActionsEventHandlers } from './hooks/useFloatingActionsEventHandlers'
import { isMobile } from '../../common/utils/env'

export enum FloatingButtonType {
  PRIMARY = 'primary',
  PAGE = 'page',
}

const LONG_PRESS_DELAY = 200

interface PointerPosition {
  x: number
  y: number
}

export const FloatingActions = () => {
  const { isLeftHidden } = useUIContext()

  const [pressedButton, setPressedButton] = useState<FloatingButtonType | null>(
    null,
  )
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const floatingActionWrapperRef = useRef<HTMLDivElement>(null)
  const [pointerPosition, setPointerPosition] = useState<PointerPosition>({
    x: 0,
    y: 0,
  })

  const { onPrimaryClick, onPageClick } = useFloatingActionsEventHandlers()

  const { isVirtualKeyboardVisible } = useVirtualKeyboardState()

  const isPointerOverFloatingActions = useCallback(() => {
    const boundingClientRect =
      floatingActionWrapperRef.current?.getBoundingClientRect()

    if (!boundingClientRect) return false

    const pointerX = pointerPosition.x
    const pointerY = pointerPosition.y

    return (
      pointerX > (boundingClientRect?.left ?? 0) &&
      pointerX < (boundingClientRect?.right ?? 0) &&
      pointerY > (boundingClientRect?.top ?? 0) &&
      pointerY < (boundingClientRect?.bottom ?? 0)
    )
  }, [pointerPosition])

  const onLongPressRelease = useCallback(() => {
    // Cancel the interaction if the pointer / touch isn't over the buttons (drag to cancel)
    if (!isPointerOverFloatingActions()) return

    switch (pressedButton) {
      case FloatingButtonType.PRIMARY:
        onPrimaryClick()
        break
      case FloatingButtonType.PAGE:
        onPageClick()
      default:
        break
    }
  }, [onPrimaryClick, onPageClick, pressedButton, isPointerOverFloatingActions])

  const setCurrentPointerPosition = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      setPointerPosition({ x: e.clientX, y: e.clientY })
    },
    [],
  )

  const {
    isLongPressing,
    handlers: longPressHandlers,
    setIsLongPressing,
  } = useLongPress({
    delay: LONG_PRESS_DELAY,
    onRelease: onLongPressRelease,
  })

  useEffect(() => {
    if (!isLongPressing && isMounted) {
      setPressedButton(null)
    }
  }, [isLongPressing])

  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  useEffect(() => {
    const listener = (e: any) => setCurrentPointerPosition(e)
    const onPointerUp = (_e: any) => {
      onLongPressRelease()
      setIsLongPressing(false)
      setPressedButton(null)
    }

    document.addEventListener<'pointermove'>('pointermove', listener)
    document.addEventListener<'pointerup'>('pointerup', onPointerUp)
    return () => {
      document.removeEventListener('pointermove', listener)
      document.removeEventListener('pointerup', onPointerUp)
    }
  }, [
    onLongPressRelease,
    setIsLongPressing,
    setCurrentPointerPosition,
    setPressedButton,
  ])

  if (!isMobile || !isLeftHidden || isVirtualKeyboardVisible) return null

  return (
    <div
      ref={floatingActionWrapperRef}
      className={css({
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: '32px',
        right: '16px',
        gap: 8,
        zIndex: 500,
      })}
      onPointerDown={(e) => {
        // Set the pointer position immediately when clicking
        setCurrentPointerPosition(e)
      }}
      {...longPressHandlers}
    >
      <PrimaryButton
        isSelected={
          isLongPressing && pressedButton === FloatingButtonType.PRIMARY
        }
        isActive={isLongPressing}
        onPointerEnter={() => setPressedButton(FloatingButtonType.PRIMARY)}
        setSelectedButton={setPressedButton}
        tooltip={{ title: 'Create record' }}
        tippyProps={{
          visible:
            isLongPressing && pressedButton === FloatingButtonType.PRIMARY,
          placement: 'left',
        }}
        activeIconVariant="glyphPenOnPaper"
        inactiveIconVariant="glyphPlus"
      />
      {isLongPressing && (
        <SecondaryButton
          isSelected={
            isLongPressing && pressedButton === FloatingButtonType.PAGE
          }
          onPointerEnter={() =>
            isLongPressing && setPressedButton(FloatingButtonType.PAGE)
          }
          iconVariant="glyphTextMulti"
          tooltip={{ title: 'Create record as page' }}
          tippyProps={{
            visible:
              isLongPressing && pressedButton === FloatingButtonType.PAGE,
            placement: 'left',
          }}
        />
      )}
    </div>
  )
}
