import { isEmpty } from 'lodash'
import React from 'react'

import DialogInput from '../../DialogInput'
import { Icon, styled } from '../../../common/stationary'
import { GO_TO_PLACEHOLDER } from '../../../common/utils/uiConstants'

export interface MoveToHeaderProps {
  inputRef?: React.RefObject<HTMLInputElement>
  selectedRecordIds: string[]
  inputValue: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
  handleSearchInputKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void
}

export const MoveToHeader = ({
  inputRef,
  selectedRecordIds,
  inputValue,
  onChange,
  handleSearchInputKeyDown,
  onSubmit,
}: MoveToHeaderProps) => {
  return (
    <S.Header>
      <S.InfosContainer>
        <S.InfosLeft>
          <S.InfoTitle>
            <S.Icon boxSize={16} variant="glyphSquircleArrowRight" />
            <S.Title>Move</S.Title>
          </S.InfoTitle>
        </S.InfosLeft>
        {!isEmpty(selectedRecordIds) ? (
          <S.InfosRight>
            <S.InfoSelected>
              <S.InfoTagStatic>
                {selectedRecordIds.length} selected
              </S.InfoTagStatic>
            </S.InfoSelected>
          </S.InfosRight>
        ) : null}
      </S.InfosContainer>
      <S.Input
        inputRef={inputRef}
        onKeyDown={handleSearchInputKeyDown}
        placeholder={GO_TO_PLACEHOLDER}
        inputValue={inputValue}
        onSubmit={onSubmit}
        onChange={onChange}
      />
    </S.Header>
  )
}

export default MoveToHeader

const S = {
  Header: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `column`,
    padding: 16,
    userSelect: `none`,
    borderBottom: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
  })),
  InfosContainer: styled.div({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
  }),
  InfosLeft: styled.div({
    flex: 1,
    display: `flex`,
  }),
  InfosRight: styled.ul({
    display: `flex`,
    height: `100%`,
  }),
  InfoTitle: styled.li(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    boxShadow: `0 0 0 1px ${theme.colors.alpha.border.weak}`,
    borderRadius: 6,
    outline: `none`,
    userSelect: `none`,
    padding: `2px 6px 2px 4px`,
  })),
  Icon: styled(Icon)(({ theme }) => ({
    marginRight: 4,
    color: theme.colors.text[500],
  })),
  Title: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    fontWeight: `bold`,
    lineHeight: `20px`,
    color: theme.colors.text[700],
  })),
  InfoSelected: styled.li({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-end`,
    outline: `none`,
    userSelect: `none`,
  }),
  InfoTagStatic: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: `0 4px`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    color: theme.colors.text[700],
  })),
  Input: styled(DialogInput)({
    padding: `8px 0 0 0`,
  }),
}
