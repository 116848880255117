import { RouteType, isRouteType } from '../../../routes'
import Analytics from '../../capture-analytics-actions'

export const getScreenNavigationStartedScreenTypeFromRouteType = (
  route: RouteType | string,
) => {
  const destinationType = {
    [RouteType.Inbox]: Analytics.ScreenType.INBOX,
    [RouteType.Today]: Analytics.ScreenType.TODAY,
    [RouteType.Trash]: Analytics.ScreenType.TRASH,
    [RouteType.List]: Analytics.ScreenType.USER_LIST,
    [RouteType.ResetPassword]: Analytics.ScreenType.RESET_PASSWORD,
    [RouteType.Details]: Analytics.ScreenType.USER_LIST, // TODO: change to the right avo screen type
  }
  return isRouteType(route) ? destinationType[route] : undefined
}
