import React from 'react'
import { styled } from '../../../common/stationary'

const LoginFooter: React.FC = () => {
  return (
    <S.Footer>
      <S.LinksAndCopyright>
        <S.Links>
          <S.Link href="mailto:membership@capture.so">Contact Us</S.Link>
          <S.Link href="/privacy">Privacy</S.Link>
        </S.Links>
        <S.Copyright>&copy; 2023 Eleventh, Inc.</S.Copyright>
      </S.LinksAndCopyright>
    </S.Footer>
  )
}

export default LoginFooter

const S = {
  Footer: styled.div(() => ({
    flexBasis: 64,
  })),

  LinksAndCopyright: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    height: 32,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[500],
  })),

  Links: styled.div(() => ({
    display: 'flex',
    gap: 16,
    fontWeight: 600,
  })),

  Link: styled.a(({ theme }) => ({
    ':hover': {
      textDecoration: 'underline',
    },
  })),

  Copyright: styled.div(() => ({})),
}
