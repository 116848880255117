import { IconVariantName } from '../../stationary'

export enum MiniBarActionType {
  StandAlone = `StandAlone`,
  MiniBar = `MiniBar`,
}

export interface MiniBarAction {
  type: MiniBarActionType
  title: string
  group?: number
  variant?: IconVariantName
  tooltip?: {
    title: React.ReactNode
    keycaps?: React.ReactNode[]
  }
  shortcut?: string[]
  standalone?: boolean
  onMouseDown?: () => void
}

export interface MiniBarEventHandlers {
  completeRecords: (recordId: string) => void
  moveRecords: (recordId: string) => void
  scheduleRecords: (recordId: string) => void
  addNoteToRecord: (recordId: string) => void
  moveRecordsToTrash: (recordId: string) => void
  collapseRecord: (recordId: string) => void
  expandRecord: (recordId: string) => void
  expandToFullscreen: (recordId: string) => void
  restoreRecords: (recordId: string) => void
  blurRecord: (recordId: string) => void
  hideNote: (recordId: string) => void
}
