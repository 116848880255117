import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

import { DialogType } from '../dialogs/types'
import {
  RecordItemExpandStateDict,
  SetRecordItemExpandStateDict,
  useRecordItemExpandState,
} from '../expandRecord'
import { systemThemeName, useSavedThemeName } from '../hooks/useSavedTheme'
import { RecordItemTargetType } from '../recordItem'
import { emptyFn } from '../utils'
import {
  defaultFullscreenControls,
  FullscreenControlsData,
  useFullscreenControls,
} from './helpers/useFullscreenControls'
import { useIsLeftOrRightHidden } from './helpers/useIsLeftHidden'
import {
  defaultNotesVisibility,
  NotesVisibilityData,
  useNotesVisibility,
} from './helpers/useNotesVisibility'
import {
  defaultOutlineControls,
  OutlineControls,
  useOutlineControls,
} from './helpers/useOutlineControls'
import {
  defaultShowProfileActions,
  ShowProfileActionsData,
  useProfileActions,
} from './helpers/useProfileActions'
import {
  defaultShowCompleted,
  ShowCompletedData,
  useShowCompleted,
} from './helpers/useShowCompleted'
import {
  defaultTodayFilters,
  TodayFiltersData,
  useTodayFilters,
} from './helpers/useTodayFilters'
import { useModalContext } from '../modals/ModalContext'

type ViewAction = DialogType

export interface UIContextValue
  extends ShowCompletedData,
    NotesVisibilityData,
    ShowProfileActionsData,
    FullscreenControlsData,
    OutlineControls {
  isLeftHidden: boolean
  toggleIsLeftHidden: () => boolean
  isRightHidden: boolean
  toggleIsRightHidden: () => boolean
  setIsRightHidden: (value: boolean) => void
  isMouseRecentlyActive: boolean
  setIsMouseRecentlyActive: (value: boolean) => void
  activeAction: ViewAction | undefined
  hideView: () => void
  isAnyViewVisible: boolean
  isViewVisible: (action: ViewAction) => boolean
  showView: (action: ViewAction) => void
  todayFilters: TodayFiltersData
  savedThemeName: string | null
  setSavedThemeName: (value: string | null) => void
  setIsAModalOpen: (open: boolean) => void
  recentlyCreatedRecords: Record<string, RecordItemTargetType>
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >
  recordItemExpandState: RecordItemExpandStateDict
  setRecordItemExpandState: SetRecordItemExpandStateDict
  isDarkMode: boolean
  isLightMode: boolean
}

/*
 * Context
 */

const defaultValue = {
  ...defaultShowCompleted,
  ...defaultFullscreenControls,
  ...defaultNotesVisibility,
  ...defaultOutlineControls,
  ...defaultShowProfileActions,
  activeAction: undefined,
  hideView: emptyFn,
  isAnyViewVisible: false,
  isLeftHidden: false,
  isMouseRecentlyActive: false,
  isRightHidden: false,
  toggleIsRightHidden: () => false,
  setIsRightHidden: (value: boolean) => {},
  isViewVisible: () => false,
  recentlyCreatedRecords: {},
  recordItemExpandState: {},
  savedThemeName: systemThemeName,
  setDocType: emptyFn,
  setIsAModalOpen: emptyFn,
  setIsMouseRecentlyActive: emptyFn,
  setRecentlyCreatedRecords: emptyFn,
  setRecordItemExpandState: emptyFn,
  setSavedThemeName: emptyFn,
  showView: emptyFn,
  toggleIsLeftHidden: () => false,
  isDarkMode: false,
  isLightMode: false,
  todayFilters: defaultTodayFilters,
}

export const UIContext = createContext<UIContextValue>(defaultValue)
UIContext.displayName = `UIContext`

/*
 * Provider
 */

export interface UIProviderProps {
  children: ReactNode
}

export const UIProvider = ({ children }: UIProviderProps) => {
  const { savedThemeName, setSavedThemeName, isDarkMode, isLightMode } =
    useSavedThemeName()
  const {
    isLeftHidden,
    toggleIsLeftHidden,
    isRightHidden,
    toggleIsRightHidden,
    setIsRightHidden,
  } = useIsLeftOrRightHidden()
  const showCompletedData = useShowCompleted()
  const todayFilters = useTodayFilters()
  const notesVisibilityData = useNotesVisibility()
  const profileActions = useProfileActions()
  const fullscreenControls = useFullscreenControls()
  const outlineStateData = useOutlineControls()
  const { isAnyModalOpen: isAnyGlobalModalOpen } = useModalContext()

  const [isMouseRecentlyActive, setIsMouseRecentlyActive] = useState(true)
  const [activeAction, setActiveAction] = React.useState<
    ViewAction | undefined
  >(undefined)

  const [recordItemExpandState, setRecordItemExpandState] =
    useRecordItemExpandState()

  const [recentlyCreatedRecords, setRecentlyCreatedRecords] = useState<
    Record<string, RecordItemTargetType>
  >({})

  const [isAModalOpen, setIsAModalOpen] = useState(false)

  const hideView = useCallback(() => {
    setActiveAction(undefined)
  }, [setActiveAction])
  const showView = useCallback(
    (action: ViewAction) => {
      setActiveAction(action)
    },
    [setActiveAction],
  )
  const isViewVisible = useCallback(
    (action: ViewAction) => activeAction === action,
    [activeAction],
  )

  // TODO: Convert all modals to be global and only check isAnyGlobalModalOpen here.
  const isAnyViewVisible =
    activeAction !== undefined || isAModalOpen || isAnyGlobalModalOpen

  const value = {
    ...showCompletedData,
    activeAction,
    hideView,
    isAnyViewVisible,
    isLeftHidden,
    isMouseRecentlyActive,
    isViewVisible,
    recentlyCreatedRecords,
    recordItemExpandState,
    savedThemeName,
    setIsAModalOpen,
    setIsMouseRecentlyActive,
    setRecentlyCreatedRecords,
    setRecordItemExpandState,
    setSavedThemeName,
    showView,
    toggleIsLeftHidden,
    isRightHidden,
    toggleIsRightHidden,
    setIsRightHidden,
    todayFilters,
    isDarkMode,
    isLightMode,
    ...notesVisibilityData,
    ...profileActions,
    ...fullscreenControls,
    ...outlineStateData,
  }

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

/*
 * Hooks
 */

export const useUIContext = (): UIContextValue => {
  const context = useContext(UIContext)

  if (context === undefined) {
    throw new Error(`useUIContext must be used within an UIProvider`)
  }

  return context
}
