import React, { useContext, useMemo, useState } from 'react'
import { ModalComponentProps, ModalName } from './core/modalConfig'
import { emptyFn } from '../utils'

export interface CurrentModal<T extends keyof ModalComponentProps> {
  name: T
  props: ModalComponentProps[T]
}

export interface ModalContextValue {
  currentModal: CurrentModal<keyof ModalComponentProps> | null
  isAnyModalOpen: boolean
  setCurrentModal: (_: CurrentModal<keyof ModalComponentProps> | null) => void
}

/*
 * Context
 */

const defaultValue = {
  currentModal: null,
  isAnyModalOpen: false,
  setCurrentModal: emptyFn,
}

export const ModalContext = React.createContext<ModalContextValue>(defaultValue)
ModalContext.displayName = `ModalContext`

export const ModalProvider: React.FC = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<CurrentModal<
    keyof ModalComponentProps
  > | null>(null)

  const contextValue = useMemo(() => {
    return {
      currentModal,
      setCurrentModal,
      isAnyModalOpen: currentModal !== null,
    }
  }, [currentModal])

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext)

  if (context === undefined) {
    throw new Error(`useModalContext must be used within an ModalProvider`)
  }

  return context
}
