import React from 'react'

import { Key, Priorities, useKeys } from '../../common/hooks/useKeys'
import { emptyFn } from '../../common/utils'
import { HandleResultAction, InputHandlers, MoveItemData } from './MoveTo.types'
import MoveToBody from './components/MoveToBody'
import MoveToFooter from './components/MoveToFooter'
import MoveToHeader from './components/MoveToHeader'
import { styled } from '../../common/stationary'

export interface MoveToProps extends InputHandlers {
  inputRef?: React.RefObject<HTMLInputElement>
  selectedRecordIds: string[]
  inputValue: string
  items: MoveItemData[]
  isSelectedAddNewCollection: boolean
  isMouseRecentlyActive: boolean
  handleResultClick: HandleResultAction
  handleNewCollectionClick: () => void
  onItemHover: (id: string) => void
}

export const MoveTo = ({
  inputRef,
  selectedRecordIds,
  inputValue,
  items,
  isSelectedAddNewCollection,
  isMouseRecentlyActive,
  handleResultClick,
  handleNewCollectionClick,
  onItemHover,
  onDownArrow,
  onUpArrow,
  onEnter,
  onSchedule,
  onComplete,
  onMove,
  onEsc,
  onChange,
  handleSearchInputKeyDown,
  onSubmit,
}: MoveToProps) => {
  useKeys([Key.Down, Key.J], onDownArrow, Priorities.Dialog, true)
  useKeys([Key.Up, Key.K], onUpArrow, Priorities.Dialog, true)
  useKeys(Key.Enter, onEnter, Priorities.Dialog, true)
  useKeys(Key.S, onSchedule, Priorities.Dialog, true)
  useKeys(Key.C, onComplete, Priorities.Dialog, true)
  useKeys(Key.M, onMove, Priorities.Dialog, false)
  useKeys(Key.Esc, onEsc, Priorities.Dialog, true)
  useKeys(Key.Tab, emptyFn, Priorities.Dialog, true)
  useKeys(Key.ShiftTab, emptyFn, Priorities.Dialog, true)

  return (
    <S.MoveTo>
      <MoveToHeader
        inputRef={inputRef}
        selectedRecordIds={selectedRecordIds}
        inputValue={inputValue}
        onChange={onChange}
        handleSearchInputKeyDown={handleSearchInputKeyDown}
        onSubmit={onSubmit}
      />
      <div>
        <MoveToBody
          query={inputValue}
          items={items}
          handleResultClick={handleResultClick}
          onItemHover={onItemHover}
        />
      </div>
      <MoveToFooter
        isSelected={isSelectedAddNewCollection}
        isMouseRecentlyActive={isMouseRecentlyActive}
        handleNewCollectionClick={handleNewCollectionClick}
      />
    </S.MoveTo>
  )
}

export default MoveTo

const S = {
  MoveTo: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `column`,
    borderRadius: 12,
    boxShadow: theme.effects.boxShadow.elevation[3],
    background: theme.colors.surface.base,
  })),
}
