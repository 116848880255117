import { isEmpty } from 'lodash'
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { BaseSelection, useBaseSelection } from '../libs/selection/base'
import { allCompleted as allRecordsCompleted } from '../records'
import { DataRecord, isCompleteableRecord } from '@eleventhlabs/capture-shared'
import { useModelStoreContext } from './ModelStoreContext'

export type SelectionContextValue = {
  selection: BaseSelection
  selectedRecords: DataRecord[]
  focusedRecordId: string | undefined
  setFocusedRecordId: (recordId?: string) => void
  allCompleted?: boolean
  isSelectionEmpty?: boolean
}

/*
 * Context
 */

export const SelectionContext = createContext<
  SelectionContextValue | undefined
>(undefined)
SelectionContext.displayName = `SelectionContext`

/*
 * Provider
 */

export interface SelectionProviderProps {
  children: ReactNode
}

export const SelectionProvider = ({ children }: SelectionProviderProps) => {
  // Get doc & commands
  const { store } = useModelStoreContext()

  // Track focused Record
  const [focusedRecordId, setFocusedRecordId] = useState<string | undefined>(
    undefined,
  )

  // Selection
  const selection = useBaseSelection()

  const selectedRecords = useMemo(
    () =>
      selection.ids
        .filter((id) => store.records[id] !== undefined)
        .map((id) => store.records[id]),
    [selection, store.records],
  )

  const setDefaultSelectionId = selection.setDefaultId
  const _setFocusedRecordId = useCallback(
    (recordId?: string) => {
      setFocusedRecordId(recordId)
      if (recordId) setDefaultSelectionId(recordId)
    },
    [setDefaultSelectionId],
  )

  const focusedRecord = focusedRecordId
    ? store.records[focusedRecordId]
    : undefined

  const isSelectionEmpty = isEmpty(selection.ids) && !focusedRecordId
  const allCompleted = focusedRecord
    ? isCompleteableRecord(focusedRecord) && focusedRecord.isCompleted
    : allRecordsCompleted(selectedRecords)

  const contextValue = useMemo(
    () => ({
      selection,
      selectedRecords,
      focusedRecordId,
      setFocusedRecordId: _setFocusedRecordId,
      isSelectionEmpty,
      allCompleted,
    }),
    [
      selection,
      selectedRecords,
      focusedRecordId,
      _setFocusedRecordId,
      isSelectionEmpty,
      allCompleted,
    ],
  )

  return (
    <SelectionContext.Provider value={contextValue}>
      {children}
    </SelectionContext.Provider>
  )
}

/*
 * Hooks
 */

export const useSelectionContext = (): SelectionContextValue => {
  const context = useContext(SelectionContext)

  if (context === undefined) {
    throw new Error(
      `useSelectionContext must be used within an SelectionContext.Provider`,
    )
  }

  return context
}
