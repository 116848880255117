import React from 'react'

import ReactDnDDragLayer from '../../common/dnd/components/ReactDnDDragLayer'

export function withDragLayer<T extends {}>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const ComponentWithDragLayer = (props: T) => {
    return (
      <>
        <ReactDnDDragLayer />
        <WrappedComponent {...props} />
      </>
    )
  }

  ComponentWithDragLayer.displayName = `ComponentWithDragLayer`

  return ComponentWithDragLayer
}
