import React from 'react'

import { WidgetProps } from '.'
import { styled } from '../../../../common/stationary'

export interface ProgressBarProps extends WidgetProps {
  progress: number
}

/**
 * @param props.progress Number between 0 and 1 representing what percentage of
 * the ProgressBar should be filled
 */
export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  style,
}) => (
  <S.ProgressBar style={style}>
    <S.FillBackground>
      <S.Fill style={{ width: `${progress * 100}%` }} />
    </S.FillBackground>
  </S.ProgressBar>
)

const S = {
  ProgressBar: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    padding: 'env(safe-area-inset-top) 16px 0',
  })),
  FillBackground: styled.div(({ theme }) => ({
    position: 'relative',
    height: 2,
    width: '100%',
    borderRadius: 999,
    background: theme.colors.alpha.tone.medium,
    overflow: 'hidden',
  })),
  Fill: styled.div(({ theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderRadius: 999,
    background: theme.colors.alpha.tone.solid,

    transition: 'width 0.8s ease',
  })),
}
