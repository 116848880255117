import React, { useState } from 'react'
import { Transition } from 'react-transition-group'

import { SlideProps } from '.'
import { Icon, cx, styled } from '../../../../common/stationary'
import { isIOS, isMobile } from '../../../../common/utils/env'
import {
  SlideButtonGroup,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/ImmersiveSlide'

import pinToIphone from '../../../../assets/onboarding/pin-to-iphone@2x.png'
import pinToWhatsappIOS from '../../../../assets/onboarding/pin-to-whatsapp-ios@2x.png'
import pinToAndroid from '../../../../assets/onboarding/pin-to-android@2x.png'
import pinToWhatsappAndroid from '../../../../assets/onboarding/pin-to-whatsapp-android@2x.png'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import { OnboardingMessagingApp } from '../../hooks/useOnboardingScreen'
import { Env } from '../../../../common/env'

type InstructionsList = (string | JSX.Element)[]

export const PinToMessages: React.FC<SlideProps> = ({ goForward }) => {
  const [hasPinned, setHasPinned] = useState<boolean>(false)
  const { onboardingAnalytics, selectedMessagingApp, didSkipAddingContact } =
    useOnboardingContext()

  const iosInstructionsList: InstructionsList = [
    <>
      <strong>Go to iMessage</strong>, then tap <strong>Edit</strong>
    </>,
    <>
      Tap <strong>Edit Pins</strong>, then tap Pin next to the Capture
      {didSkipAddingContact
        ? ` number: ${Env.captureContactPhoneNumber}`
        : ' contact'}
    </>,
    <>
      Tap <strong>Done</strong>
    </>,
  ]

  const androidInstructionsList: InstructionsList = [
    <>
      <strong>Go to Messages</strong> (your text messaging app)
    </>,
    `Press and hold your conversation with Capture${
      didSkipAddingContact ? ` (${Env.captureContactPhoneNumber})` : ''
    }`,
    <>
      Tap <strong>Pin</strong>
    </>,
  ]

  const whatsAppInstructionsList: InstructionsList = [
    <>
      <strong>Go to WhatsApp</strong>, then tap and hold the chat you want to
      pin
    </>,
    <>
      Tap <strong>Pin chat</strong>
    </>,
  ]

  const didUserSelectWhatsapp =
    selectedMessagingApp == OnboardingMessagingApp.WHATSAPP

  let pinToDiagramSrc = ''

  let instructionsList: InstructionsList = []

  if (didUserSelectWhatsapp) {
    instructionsList = whatsAppInstructionsList
    pinToDiagramSrc = isIOS ? pinToWhatsappIOS : pinToWhatsappAndroid
  } else if (isIOS) {
    instructionsList = iosInstructionsList
    pinToDiagramSrc = pinToIphone
  } else {
    instructionsList = androidInstructionsList
    pinToDiagramSrc = pinToAndroid
  }

  const getMessagesAppName = () => {
    if (didUserSelectWhatsapp) return 'WhatsApp'
    else if (isIOS) {
      return 'iMessage'
    } else {
      return 'Messages'
    }
  }

  const onCheckPinnedWrapperClick = () => {
    onboardingAnalytics.action('Pin to messages confirmed')

    setHasPinned((hasPinned) => !hasPinned)
  }

  return (
    <S.PinToMessages>
      <S.Inner>
        <TransitionSlideContainer>
          <SlideIntro>
            <SlideTitle>Pin Capture for easy access 📌️</SlideTitle>
            <SlideButtonGroup>
              <S.CheckPinnedWrapper onClick={onCheckPinnedWrapperClick}>
                <S.Checkbox>
                  {hasPinned && <Icon boxSize={18} variant="glyphCheck" />}
                </S.Checkbox>
                <strong>I've pinned Capture to {getMessagesAppName()}</strong>
              </S.CheckPinnedWrapper>
              <S.ContinueButton
                onClick={() => goForward()}
                className={cx({ isDisabled: !hasPinned })}
              >
                <strong>Continue</strong>
              </S.ContinueButton>
            </SlideButtonGroup>
          </SlideIntro>
          <S.InstructionsList>
            {instructionsList.map((step, index) => {
              return (
                <S.InstructionsListItem key={index}>
                  <S.InstructionText>{step}</S.InstructionText>
                </S.InstructionsListItem>
              )
            })}
          </S.InstructionsList>
          <S.SlideDiagrams>
            <Transition appear in timeout={0}>
              {(state) => (
                <S.Diagram
                  style={{
                    opacity: state === 'entering' ? 0 : 1,
                    pointerEvents: state === 'entering' ? 'none' : 'initial',
                  }}
                >
                  <S.PinToMessagesWrapper>
                    <S.PinToMessagesImg
                      src={pinToDiagramSrc}
                      srcSet={`${pinToDiagramSrc} 2x`}
                    />
                  </S.PinToMessagesWrapper>
                </S.Diagram>
              )}
            </Transition>
          </S.SlideDiagrams>
        </TransitionSlideContainer>
      </S.Inner>
    </S.PinToMessages>
  )
}

const S = {
  PinToMessages: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',
    overflow: 'hidden',

    ...(isMobile && {
      padding: '0 24px',
    }),
  })),
  Inner: styled.div(() => ({
    width: '100%',
    maxWidth: isMobile ? 320 : 768,
  })),
  Diagram: styled.div(() => ({
    position: 'relative',
    zIndex: 1,

    transition: 'height 0.8s ease, opacity 0.4s ease',
  })),
  PinToMessagesWrapper: styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  })),
  PinToMessagesImg: styled.img(() => ({})),
  SlideDiagrams: styled(SlideDiagrams)(() => ({
    // This is a bit of hack, to allow visible overflow
    // so this can work at any height
    height: 400,
  })),
  InstructionsList: styled.ol(() => ({
    listStyleType: 'decimal',
    listStylePosition: 'outside',
    width: 340,
    marginLeft: -20,

    li: {
      marginLeft: 48,
      marginBottom: 16,
    },
  })),
  InstructionsListItem: styled.li(() => ({
    marginLeft: 24,
    marginBottom: 8,
  })),
  InstructionText: styled.div(({ theme }) => ({
    ...theme.text.publicSans['16:24'],
    color: theme.colors.text[900],
    marginBottom: 8,
    width: '100%',

    display: 'inline-block',
    verticalAlign: 'top',

    strong: {
      fontWeight: 700,
    },
  })),
  CheckPinnedWrapper: styled.div(({ theme }) => ({
    display: 'flex',
    padding: '10px 20px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    border: '1px solid black',
    borderRadius: 8,
    background: theme.colors.surface.base,
    ...theme.text.publicSans['13.5:20'],

    strong: {
      fontWeight: 700,
    },
  })),
  Checkbox: styled.div(({ theme }) => ({
    width: '16px',
    height: '16px',
    flexShrink: 0,
    borderRadius: 4,
    border: `2px solid ${theme.colors.text[900]}`,
    background: theme.colors.surface.base,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })),
  ContinueButton: styled(SlidePrimaryButton)(({ theme }) => ({
    '&.isDisabled': {
      opacity: 0.6,
    },
  })),
}
