import { useCallback, useState } from 'react'

import { API } from '../api'
import { useAuth } from '../contexts/AuthContext'
import { FileMetadata } from '@eleventhlabs/capture-shared'

export const useUploadFileToAWS = () => {
  const { user } = useAuth()

  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {},
  )

  const updateProgress = useCallback((recordId: string, progress: number) => {
    setUploadProgress((value) => ({
      ...value,
      [recordId]: progress,
    }))
  }, [])

  const uploadFile = useCallback(
    async (recordId: string, file: File): Promise<FileMetadata> => {
      const updateProgressForRecord = (progress: number) =>
        updateProgress(recordId, progress)
      return await API.uploadFileToAWS(
        (user?.trusted_metadata.captureId as string) ?? ``,
        file,
        updateProgressForRecord,
      )
    },
    [user, updateProgress],
  )

  return { uploadFile, uploadProgress }
}
