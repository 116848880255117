import { find, findIndex, flatMap, isEmpty, keys, take, uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Frack, RootRecord, RootRecordId } from '@eleventhlabs/capture-shared'

import { useExecContext } from '../../../contexts/ExecContext'
import { useLocalStorage } from '../../../contexts/LocalStorageContext'
import { useModelStoreContext } from '../../../contexts/ModelStoreContext'
import { useUIContext } from '../../../contexts/UIContext'
import { scrollAction } from '../../../events'
import { LocalStorageItemSetter } from '../../../libs'
import { emptyFn } from '../../../utils'
import { CommandId } from '../../CommandId'

import { SystemListsRouteType } from '../../../../common/routes'

import { executeCommandFromCommandBar } from '../../executeCommandForCommandBar'
import {
  CommandBarDomain,
  CommandBarHandlers,
  HandleResultPayload,
  ItemType,
  ResultGroup,
  ResultGroupItem,
  useCommandBarDomains,
} from '../CommandBar.types'
import { useCommandBarData } from './useCommandBarData'

interface UseCommandBarData {
  query?: string
  groupData: ResultGroup
  handlers: CommandBarHandlers
}

export const useCommandBar = (
  close: () => void = emptyFn,
): UseCommandBarData => {
  const didMountRef = useRef(false)
  const [query, setQuery] = useState<string | undefined>()
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(0)
  const [recentPicksStr, setRecentPicksStr] = useLocalStorage(
    `recent-command-bar-picks`,
  )
  const { exec } = useExecContext()

  const { store } = useModelStoreContext()
  const root = store.records[RootRecordId] as RootRecord
  const recentPicks = useMemo(() => {
    const itemIds = [
      ...Frack.toArray(root.children),
      ...keys(SystemListsRouteType),
    ]
    return decodeRecentPicks(itemIds, recentPicksStr)
  }, [root.children, recentPicksStr])

  const commandBarDomains = useCommandBarDomains()
  const groupData = useCommandBarData(
    commandBarDomains,
    query,
    selectedIndex,
    recentPicks,
  )

  useEffect(() => {
    // avoid scrolling on first render
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }
    // we keep the selected element always visible
    if (selectedIndex != undefined)
      scrollAction({ target: `command-bar`, to: { index: selectedIndex } })
  }, [selectedIndex])

  const handleSubmitResult = useCallback(
    (payload: HandleResultPayload) => {
      const commandId = payload.id ?? getItemId(groupData.items, selectedIndex)
      if (!commandId) return
      const command = getCommand(commandBarDomains, commandId)
      executeCommandFromCommandBar(
        exec,
        commandId as CommandId,
        payload.trigger,
      )
      if (!command?.shouldStayOpen) close()
      savePickIntoRecent(recentPicks, commandId, setRecentPicksStr)
    },
    [close, exec, groupData, selectedIndex, recentPicks, setRecentPicksStr],
  )

  const handleResultClick = (p?: { id?: string }) =>
    handleSubmitResult({ ...p, trigger: `click` })

  const onBlur = useCallback(() => setSelectedIndex(0), [])

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((event.target as any)?.value)
    // when the query changes, it will always select the first result
    setSelectedIndex(0)
  }, [])

  const itemsCount = groupData.items.length
  const increaseIndex = useCallback(
    (pointer?: number) => {
      const newIndex = (pointer ?? selectedIndex ?? -1) + 1
      if (newIndex >= itemsCount) return
      if (groupData.items[newIndex].type === ItemType.Group)
        increaseIndex(newIndex)
      else setSelectedIndex(newIndex)
    },
    [groupData.items, selectedIndex, itemsCount],
  )
  const decreaseIndex = useCallback(
    (pointer?: number) => {
      const newIndex = (pointer ?? selectedIndex ?? 0) - 1
      if (newIndex < 0) return
      if (groupData.items[newIndex].type === ItemType.Group)
        decreaseIndex(newIndex)
      else setSelectedIndex(newIndex)
    },
    [groupData.items, selectedIndex],
  )

  const handleSearchInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case `Tab`:
          event.preventDefault()
          break
        case `ArrowDown`:
          event.preventDefault()
          increaseIndex()
          break
        case `ArrowUp`:
          event.preventDefault()
          decreaseIndex()
          break
        case `Enter`: {
          event.preventDefault()
          if (selectedIndex === undefined) break
          handleSubmitResult({ trigger: `keyboard` })
          break
        }
        case `Escape`:
          event.preventDefault()
          if (isEmpty(query)) close()
          else setQuery(``)
          break
        default:
      }
    },
    [
      query,
      selectedIndex,
      decreaseIndex,
      increaseIndex,
      handleResultClick,
      close,
    ],
  )

  const { isMouseRecentlyActive } = useUIContext()
  const onHover = useCallback(
    (id: string) => {
      if (!isMouseRecentlyActive) return
      if (
        find(groupData.items, (item) => item.id === id)?.type !==
        ItemType.Action
      )
        return
      const index = findIndex(groupData.items, (item) => item.id === id)
      setSelectedIndex(index)
    },
    [groupData, isMouseRecentlyActive],
  )

  const onDownArrow = useCallback(() => increaseIndex(), [increaseIndex])
  const onUpArrow = useCallback(() => decreaseIndex(), [decreaseIndex])
  const onEnter = useCallback(
    () => handleSubmitResult({ trigger: `keyboard` }),
    [handleResultClick],
  )
  const onEsc = useCallback(() => close(), [close])

  const result = useMemo(
    () => ({
      handlers: {
        onDownArrow,
        onUpArrow,
        onEnter,
        onEsc,
        onChange,
        onBlur,
        onHover,
        handleSearchInputKeyDown,
        handleResultClick,
      },
      groupData,
      query,
    }),
    [
      onDownArrow,
      onUpArrow,
      onEnter,
      onEsc,
      onChange,
      onBlur,
      onHover,
      handleSearchInputKeyDown,
      handleResultClick,
      groupData,
      query,
    ],
  )

  return result
}

const MAX_RECENT_PICKS = 100

const getItemId = (items: ResultGroupItem[], index?: number) => {
  return index != undefined ? items[index].id : undefined
}

const getCommand = (domains: CommandBarDomain[], id?: string) => {
  if (!id) return
  return find(
    flatMap(domains, (domain) => domain.commands),
    (command) => command.id === id,
  )
}

const savePickIntoRecent = (
  recentPicks: string[],
  newPick: string,
  setRecentPicksStr: LocalStorageItemSetter,
) => {
  const cleanedRecentPicks = take(
    uniq([newPick, ...recentPicks]),
    MAX_RECENT_PICKS,
  )
  setRecentPicksStr(JSON.stringify(cleanedRecentPicks))
}

const decodeRecentPicks = (
  itemIds: string[],
  recentPicksStr: string | null,
): string[] => {
  if (!recentPicksStr) return []

  const recentPicks = JSON.parse(recentPicksStr) as string[]
  return recentPicks.filter((id) => itemIds.includes(id))
}
