import { globalShortcut } from '@todesktop/client-core'

// Convenience type for https://www.electronjs.org/docs/api/accelerator
type Accelerator = string

export default class GlobalShortcut {
  /**
   * Whether this application has registered shortcut.
   */
  static isRegistered(accelerator: Accelerator): Promise<boolean> {
    return globalShortcut.isRegistered(accelerator)
  }

  /**
   * Registers a global shortcut.
   */
  static register(
    accelerator: Accelerator,
    handler: () => void,
  ): Promise<void> {
    return globalShortcut.register(accelerator, handler)
  }

  /**
   * Unregisters a global shortcut.
   */
  static unregister(accelerator: Accelerator): Promise<void> {
    return globalShortcut.unregister(accelerator)
  }

  /**
   * Unregisters all global shortcuts.
   */
  static unregisterAll(): Promise<void> {
    return globalShortcut.unregisterAll()
  }
}
