import React, { Suspense } from 'react'
import { useModalContext } from '../ModalContext'
import { modalsConfig } from './modalConfig'

export const ModalRenderer = () => {
  const { currentModal } = useModalContext()

  const Modal = currentModal ? modalsConfig[currentModal.name] : null

  return currentModal && Modal ? (
    <Suspense fallback={<div />}>
      <Modal {...currentModal.props} />
    </Suspense>
  ) : null
}
