import React from 'react'
import { Transition } from 'react-transition-group'

import { extractRGB, styled } from '../../../../../common/stationary'
import { isIOS, isMobile } from '../../../../../common/utils/env'
import * as BaseSlide from './BaseSlide'

/*
 * SlideContainer
 */
const SlideContainer = styled(BaseSlide.SlideContainer)(() => ({
  flexDirection: 'column',

  transition: 'opacity 0.4s ease, margin 0.8s ease',
}))

export const TransitionSlideContainer: React.FC<
  JSX.IntrinsicElements['div']
> = (props) => (
  <Transition appear in timeout={0}>
    {(state) => (
      <SlideContainer
        {...props}
        style={{
          ...props.style,
          opacity: state === 'entered' ? 1 : 0,
          marginTop: state === 'entered' ? 0 : 8,
        }}
      />
    )}
  </Transition>
)

/*
 * SlideIntro
 */
export const SlideIntro = styled(BaseSlide.SlideIntro)(() => ({
  alignItems: 'stretch',
  padding: '56px 0',

  ...(isMobile && {
    padding: '16px 0',
    textAlign: 'center',
  }),
}))

export const SlideTitle = styled(BaseSlide.SlideTitle)(({ theme }) => ({
  marginTop: isIOS ? 12 : 0,
  marginBottom: 16,
  ...theme.text.publicSans['39.5:44'],

  ...(isMobile && {
    ...theme.text.publicSans['33:40'],
  }),
}))

export const SlideDescription = styled(BaseSlide.SlideDescription)(
  ({ theme }) => ({
    marginBottom: 24,
    ...theme.text.publicSans['19:28'],

    ...(isMobile && {
      ...theme.text.publicSans['16:24'],
    }),
  }),
)

export const SlideButtonGroup = styled(BaseSlide.SlideButtonGroup)(
  ({ theme }) => ({
    ...(isMobile && {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 8,
      position: 'fixed',
      zIndex: 2,
      bottom: 0,
      left: 0,
      right: 0,
      padding: '80px 24px calc(24px + env(safe-area-inset-top))',
      background: `linear-gradient(to bottom,
        rgba(${extractRGB(theme.colors.surface.base)} / 0),
        rgba(${extractRGB(theme.colors.surface.base)} / 1),
        rgba(${extractRGB(theme.colors.surface.base)} / 1))`,
    }),
  }),
)

export const SlidePrimaryButton = styled(BaseSlide.SlidePrimaryButton)(
  ({ theme }) => ({
    ...(isMobile && {
      height: 48,
      borderRadius: 8,
    }),
    ...(isIOS && {
      marginBottom: -42,
    }),

    '&:disabled': {
      opacity: 0.4,
    },
  }),
)

export const SlideSecondaryButton = styled(BaseSlide.SlideSecondaryButton)(
  ({ theme }) => ({
    ...(isMobile && {
      height: 48,
      borderRadius: 8,
      border: `1px solid ${theme.colors.gray[200]}`,
    }),
    ...(isIOS && {
      '&:last-child': {
        marginBottom: -42,
      },
    }),
  }),
)

export const SlideWaitingHint = BaseSlide.SlideWaitingHint

export const SlideWaitingSpinner = BaseSlide.SlideWaitingSpinner

export const SlideKeyboardHint = styled(BaseSlide.SlideKeyboardHint)(() => ({
  ...(isMobile && {
    display: 'none',
  }),
}))

export const SlideSecondaryAction = BaseSlide.SlideSecondaryAction

/*
 * SlideDiagrams
 */
export const SlideDiagrams = BaseSlide.SlideDiagrams
