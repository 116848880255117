import { entries, isArray, isArrayLike, isEqual, isObject } from 'lodash'
import { useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import type {
  ReadTransaction,
  ReadonlyJSONObject,
  ReadonlyJSONValue,
  Replicache,
} from 'replicache'

// We wrap all the callbacks in a `unstable_batchedUpdates` call to ensure that
// we do not render things more than once overv all of the changed subscriptions.

let hasPendingCallback = false
let callbacks: Array<() => void> = []

function doCallback() {
  const cbs = callbacks
  callbacks = []
  hasPendingCallback = false
  unstable_batchedUpdates(() => {
    for (const callback of cbs) {
      callback()
    }
  })
}

export function useSubscribeIfNotNull<R extends ReadonlyJSONValue>(
  rep: Replicache | null,
  query: (tx: ReadTransaction) => Promise<R | undefined>,
  def: R,
  deps: any[] = [],
): R {
  const [snapshot, setSnapshot] = useState<R>(def)
  useEffect(() => {
    if (rep === null) return undefined
    return rep.subscribe(query, {
      onData: (data: R | undefined) => {
        callbacks.push(() => setSnapshot(data ?? def))
        if (!hasPendingCallback) {
          Promise.resolve().then(doCallback)
          hasPendingCallback = true
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rep, def, ...deps])
  return snapshot
}

export function useSubscribeToSharedStructureDict(
  rep: Replicache | null,
  query: (tx: ReadTransaction) => Promise<ReadonlyJSONObject>,
  def: ReadonlyJSONObject,
  deps: any[] = [],
): ReadonlyJSONObject {
  const [snapshot, setSnapshot] = useState(def)
  useEffect(() => {
    if (rep === null) return undefined
    return rep.subscribe(query, {
      onData: (data) => {
        if (!isObject(data) || isArrayLike(data)) return
        if (Object.keys(data).length === 0) return
        callbacks.push(() => {
          setSnapshot((prev) => preserveIdentitiesWhenEqual(prev, data))
        })
        if (!hasPendingCallback) {
          Promise.resolve().then(doCallback)
          hasPendingCallback = true
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rep, ...deps])
  return snapshot
}

const preserveIdentitiesWhenEqual = (
  prev: ReadonlyJSONObject,
  next: ReadonlyJSONObject,
): ReadonlyJSONObject => {
  const result: { [key: string]: any } = {}
  for (const [key, value] of entries(next)) {
    const keyIsNew = prev[key] === undefined
    const valueChanged = prev[key] !== undefined && !isEqual(prev[key], value)
    if (keyIsNew || valueChanged) result[key] = value
    else result[key] = prev[key]
  }
  return result
}

export type JSONObjWithId = ReadonlyJSONObject & { id: string }

export function useSubscribeArrWithStableIdentities(
  rep: Replicache | null,
  query: (tx: ReadTransaction) => Promise<JSONObjWithId[]>,
  def: JSONObjWithId[],
  deps: any[] = [],
): JSONObjWithId[] {
  const [snapshot, setSnapshot] = useState(def)
  useEffect(() => {
    if (rep === null) return undefined
    return rep.subscribe(query, {
      onData: (data) => {
        if (!isArray(data)) return
        callbacks.push(() => {
          setSnapshot((prev) => arrPreserveIdentitiesWhenEqual(prev, data))
        })
        if (!hasPendingCallback) {
          Promise.resolve().then(doCallback)
          hasPendingCallback = true
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rep, ...deps])
  return snapshot
}

const arrPreserveIdentitiesWhenEqual = (
  prev: JSONObjWithId[],
  next: JSONObjWithId[],
): JSONObjWithId[] => {
  // Hash
  const prevHash: { [key: string]: JSONObjWithId } = {}
  for (const item of prev) {
    prevHash[item.id] = item
  }
  // Add items one at a time
  const result: JSONObjWithId[] = []
  for (const item of next) {
    if (item.id in prevHash && isEqual(prevHash[item.id], item))
      result.push(prevHash[item.id])
    else result.push(item)
  }
  return result
}

export type IsoRangeResult = { [key: string]: JSONObjWithId[] }
export function useSubscribeInIsoRange(
  rep: Replicache | null,
  query: (tx: ReadTransaction) => Promise<{ [key: string]: JSONObjWithId[] }>,
  def: IsoRangeResult,
  deps: any[] = [],
): IsoRangeResult {
  const [snapshot, setSnapshot] = useState(def)
  useEffect(() => {
    if (rep === null) return undefined
    return rep.subscribe(query, {
      onData: (data) => {
        if (data === undefined) return
        callbacks.push(() => {
          setSnapshot((prev) => isoRangePreserveIdentitiesWhenEqual(prev, data))
        })
        if (!hasPendingCallback) {
          Promise.resolve().then(doCallback)
          hasPendingCallback = true
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rep, ...deps])
  return snapshot
}

const isoRangePreserveIdentitiesWhenEqual = (
  prev: IsoRangeResult,
  next: IsoRangeResult,
): IsoRangeResult => {
  const result: IsoRangeResult = {}
  for (const [iso, records] of entries(next)) {
    result[iso] = arrPreserveIdentitiesWhenEqual(prev[iso] ?? [], records)
  }
  return result
}
