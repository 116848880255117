import 'emoji-mart/css/emoji-mart.css'

import React, { FC, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import Analytics, {
  ScreenNavigationStartedTrigger,
} from '../common/analytics/capture-analytics-actions'
import { useBootCommandBarApp } from '../common/commandBarApp/useBootCommandBarApp'
import { SHOULD_SKIP_LANDING_COOKIE_NAME } from '../common/contexts/AuthContext'
import { useExecContext } from '../common/contexts/ExecContext'
import { useShareIntent } from '../common/contexts/IntentContext'
import { useModelStoreContext } from '../common/contexts/ModelStoreContext'
import { useOneSignal } from '../common/contexts/OneSignalContext'
import { useSubscribeToQuickCaptureMessageChannel } from '../common/desktop/useSubscribeToQuickCaptureMessageChannel'
import ContentErrorBoundary from '../common/errors/ContentErrorBoundary'
import { useBodyWithoutScrollBarOnNoChrome } from '../common/hooks/useBodyWithoutScrollBarOnNoChrome'
import { useIsMouseRecentlyActive } from '../common/hooks/useIsMouseRecentlyActive'
import { useRegisterAllHotKeys } from '../common/hooks/useRegisterAllHotkeys'
import { useSyncClientTimezone } from '../common/hooks/useSyncClientTimezone'
import { ModalRenderer } from '../common/modals/core/modalRenderer'
import { setCookie } from '../common/utils'
import { useBlurOtherClientsWhenOnline } from '../common/utils/network/useBlurOtherClientsWhenOnline'
import { useSubscribeToWebsocket } from '../common/websocket/hooks/useSubscribeToWebsocket'
import { useAuth } from '../providers'
import { ProtectedRouter } from '../router/ProtectedRouter'
import { withHideMouseWhenInactive } from './enhancers/withHideMouseWhenInactive'
import { withProtectedProviders } from './enhancers/withProtectedProviders'
import { withMobileDownloadModal } from './enhancers/withMobileDownloadModal'

const LoggedIn: FC = () => {
  const { commands } = useModelStoreContext()
  const { exec } = useExecContext()

  // User
  const { userId, extendSession } = useAuth()

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const result = await extendSession()
      if (result === 'failure') {
        exec({ type: 'LOGOUT', logoutTrigger: 'Extend Session Failed' })
      }
    }, FIVE_MINUTES_IN_MILLISECONDS)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  // isMouseRecentlyActive
  useIsMouseRecentlyActive()

  // if we are on no-chrome, remove body scroll bar
  useBodyWithoutScrollBarOnNoChrome()

  // Set user timezone
  useSyncClientTimezone(commands.setClientTimezone)

  // Shortcuts
  useRegisterAllHotKeys()

  // Analytics
  useEffect(() => {
    Analytics.loggedinLoaded({ userId_: userId ?? '' })
  }, [userId])

  // Cookie for avoiding landing page
  useEffect(() => {
    const name = SHOULD_SKIP_LANDING_COOKIE_NAME
    setCookie(name, 'true')
  }, [])

  // Associate OneSignal device id with Capture user id
  // Notification prompting happens during onboarding
  useOneSignal((oneSignal) => {
    oneSignal.setUserId(userId)
  })

  // Share Intent from iOS
  useShareIntent((item, { enqueue }) => {
    // TODO: Correct Analytics event
    exec({
      type: 'GO_TO_INBOX',
      trigger: ScreenNavigationStartedTrigger.COMMAND_BAR,
    })
    if (item.title) enqueue(item)
  })

  // Subscribe the user to all websocket channels
  useSubscribeToWebsocket(userId)
  useBlurOtherClientsWhenOnline()

  useSubscribeToQuickCaptureMessageChannel()

  useBootCommandBarApp()

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        return (
          <ContentErrorBoundary
            error={error}
            errorProps={{ isFullPage: true }}
          />
        )
      }}
    >
      <ProtectedRouter />
      <ModalRenderer />
    </ErrorBoundary>
  )
}

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000

export default withProtectedProviders(
  withMobileDownloadModal(withHideMouseWhenInactive(LoggedIn)),
)
