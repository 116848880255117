interface RecordEditingStartedPayload {
  recordId: string
}

// 1. Add new Websocket event here
export enum WSEventName {
  POKE = 'poke',
  RECORD_EDITING_STARTED = 'client-record_editing_started',
  ONBOARDING_QR_SCANNED = 'onboarding_qr_scanned',
  // ...
}

// 2. Add payload here
export type WSEventPayload = {
  [WSEventName.POKE]: void
  [WSEventName.RECORD_EDITING_STARTED]: RecordEditingStartedPayload
  [WSEventName.ONBOARDING_QR_SCANNED]: void
  // ...
}
