import React from 'react'
import { Helmet } from 'react-helmet'
import warningIconSrc from '../../../assets/onboarding/warning-icon.png'
import { isMobile } from '../../../common/utils/env'
import { AuthStyles } from '../../LoginScreen/AuthStyles'
import AuthLogo from '../../LoginScreen/components/AuthLogo'
import LoginFooter from '../../LoginScreen/components/LoginFooter'
import { safeNavigate } from '../../../common/navigate'
import { styled } from '../../../common/stationary'

/**
 * RegistrationError
 */

const RegistrationError: React.FC = ({}) => (
  <AuthStyles.AuthScreen>
    <Helmet>
      <title>Capture - Registration</title>
    </Helmet>
    <AuthStyles.Main>
      <AuthStyles.AuthRelatedMessage>
        <S.AuthHeader>
          <S.AuthLogo
            href={!isMobile ? '/' : undefined}
            secondaryIconSrc={warningIconSrc}
          />
          <AuthStyles.AuthTitle>
            <strong>This link is invalid</strong>
          </AuthStyles.AuthTitle>
        </S.AuthHeader>
        <AuthStyles.AuthBody>
          <S.ActionHint>
            It may be expired or already used. If you believe this message to be
            an error, please contact us for assistance.
          </S.ActionHint>
          <S.GoToLogin onClick={() => safeNavigate('/login')}>
            Go to login page
          </S.GoToLogin>
          <AuthStyles.ContactUs>
            Having issues?
            <a href="mailto:membership@capture.so">Contact us</a>
          </AuthStyles.ContactUs>
        </AuthStyles.AuthBody>
      </AuthStyles.AuthRelatedMessage>
    </AuthStyles.Main>
    {!isMobile && <LoginFooter />}
  </AuthStyles.AuthScreen>
)

export default RegistrationError

const S = {
  GoToLogin: styled(AuthStyles.GoToLogin)(({}) => ({
    marginTop: 24,
    marginBottom: 32,
    width: 'unset',
    padding: '0px 24px 0px 24px',
  })),

  AuthLogo: styled(AuthLogo)(({}) => ({
    marginBottom: 24,
  })),

  AuthHeader: styled(AuthStyles.AuthHeader)(({}) => ({
    paddingBottom: 8,
  })),

  ActionHint: styled(AuthStyles.ActionHint)(({}) => ({
    width: 304,
  })),
}
