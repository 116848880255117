import { ConnectDropTarget, useDrop } from 'react-dnd'
import { DragItemData, DragSourceType } from '../drag/types'
import { useDropTargetFactory } from './factory/hooks/useDropTargetFactory'
import { DropPayload, DropProps, DropTargetType } from './types'

export const useRegisterDropTarget = <DropTargetProps extends DropProps>(
  dropTargetType: DropTargetType,
  acceptedDragSourceTypes: DragSourceType[],
  payload: DropPayload,
): [ConnectDropTarget, DropTargetProps] => {
  const dropTargetHandlers =
    useDropTargetFactory<DropTargetProps>()[dropTargetType]

  const [dropProps, dropRef] = useDrop<DragItemData, unknown, DropTargetProps>({
    accept: acceptedDragSourceTypes,
    hover(_item, monitor) {
      const extras = {
        canDrop: monitor.canDrop(),
      }

      dropTargetHandlers.onHover(payload, extras)
    },
    collect: (monitor) => {
      const extras = {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }

      return dropTargetHandlers.getDropProps(payload, extras) as DropTargetProps
    },
    canDrop: (_item, _monitor) => {
      return dropTargetHandlers.canDrop
        ? dropTargetHandlers.canDrop(payload)
        : true
    },
    drop: (_item, monitor) => {
      const extras = {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }

      return dropTargetHandlers.onDrop(payload, extras)
    },
  })

  return [dropRef, dropProps]
}
