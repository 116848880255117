import { RecordType } from '@eleventhlabs/capture-shared'
import { RecordItemExpandStateDict } from './useRecordItemExpandState'

export const getIsExpanded = (
  dict: RecordItemExpandStateDict,
  r: { id: string; type: RecordType },
) => {
  const isExpanded = dict[r.id]?.isExpanded
  return isExpanded !== undefined ? isExpanded : defaultForType(r.type)
}

const defaultForType = (type: RecordType): boolean => {
  return true
}
