import { CaptureMetadata } from '@eleventhlabs/capture-shared'
import { cloneDeep, isEmpty, isObject, transform } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { getTimezone } from '../../utils'
import { isDesktop, isMobileApp } from '../../utils/env'

export type WithId<T extends { id?: string }> = T & { id: string }

export const addIdIfMissing = <T extends { id?: string }>(
  payload: T,
): WithId<T> => {
  if (payload.id !== undefined && typeof payload.id === `string`)
    return payload as T & { id: string }
  else return { ...payload, id: uuidv4() }
}

type CapturedFromSource = 'Web' | 'Mobile' | 'Desktop'
export const getCapturedFromSource = (): CapturedFromSource | undefined => {
  if (isMobileApp) return 'Mobile'
  if (isDesktop) return 'Desktop'
  if (!isMobileApp && !isDesktop) return 'Web'
  console.error('Could not determine CapturedFromSource')
  return undefined
}

const getMetadata = (): CaptureMetadata => {
  return {
    capturedAt: Date.now(),
    capturedFromSource: getCapturedFromSource(),
    capturedFromTimezone: getTimezone(),
  }
}

const addMetadata = <T>(payload: T): T & { metadata: CaptureMetadata } => ({
  ...payload,
  metadata: getMetadata(),
})

// Replicache does not allow Directed Acyclic Graphs (DAGs) as arguments.
// While lexical values will never be general graphs, they can be DAGs.
export const cloneToEnforceNoDAGs = cloneDeep

// Replicache does not allow for undefined values.
function removeUndefined<T extends object>(obj: T): T {
  return transform(obj, (result, value, key) => {
    if (isObject(value)) {
      const cleanedValue = removeUndefined(value)
      if (!isEmpty(cleanedValue)) {
        result[key] = cleanedValue
      }
    } else if (value !== undefined) {
      result[key] = value
    }
  })
}

export const enhanceCreateRecordPayload = <T extends {}>(payload: T) => {
  return removeUndefined(
    cloneToEnforceNoDAGs(addMetadata(addIdIfMissing(payload))),
  )
}
