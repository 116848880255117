import React, { useEffect, useRef, useState } from 'react'

import { Popper, PopperProps } from './Popper'
import { Menu, MenuProps } from './Menu'

export type WithMenuProps<T = {}> = T & {
  menuProps: Omit<MenuProps, 'anchorEl'>
  popperProps?: Omit<PopperProps, 'anchorEl' | 'open'> & {
    offset?: [number, number]
  }
}

export function withMenu<T extends React.ElementType>(Component: T) {
  const ComponentWithMenu: React.FC<
    WithMenuProps<React.ComponentPropsWithoutRef<T>>
  > = ({
    menuProps: {
      onClose = () => undefined,
      slots,
      slotProps,
      open: menuOpen,
      ...menuPropsRest
    },
    onMouseDown = () => undefined,
    onMouseUp = () => undefined,
    popperProps: { offset, popperOptions, ...popperPropsRest } = {},
    ...propsRest
  }) => {
    const anchorEl = useRef<HTMLButtonElement | null>(null)
    const [isOpen, setIsOpen] = useState(menuOpen)

    useEffect(() => {
      if (menuOpen !== undefined) {
        // If component is controlled, sync isOpen with menuOpen
        setIsOpen(menuOpen)
      }
    }, [menuOpen])

    const onButtonMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      onMouseDown(e)
    }

    const onButtonMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (menuOpen === undefined) {
        // If component is uncontrolled, toggle isOpen
        setIsOpen(!isOpen)
      }
      onMouseUp(e)
    }

    const onMenuClose = () => {
      if (menuOpen === undefined) {
        // If component is uncontrolled, set isOpen to false
        setIsOpen(false)
      }
      onClose()
    }

    const ComponentType = Component as React.ElementType

    return (
      <>
        <ComponentType
          onMouseDown={onButtonMouseDown}
          onMouseUp={onButtonMouseUp}
          ref={anchorEl}
          {...propsRest}
        />
        <Menu
          anchorEl={anchorEl.current}
          onClose={onMenuClose}
          open={isOpen}
          slots={{ root: Popper, ...slots }}
          slotProps={{
            root: {
              open: isOpen,
              popperOptions: {
                modifiers: [
                  {
                    name: 'offset',
                    options: { offset },
                  },
                ],
                ...popperOptions,
              },
              ...popperPropsRest,
            },
            ...slotProps,
          }}
          {...menuPropsRest}
        />
      </>
    )
  }

  return ComponentWithMenu
}
