import React, { useCallback, useRef } from 'react'

import {
  Button,
  ButtonProps,
  ButtonVariantName,
  Icon,
  IconVariantName,
  WithTooltipProps,
  cx,
  styled,
  withTooltip,
} from '../../common/stationary'
import { RecordItemTargetType } from '../../common/recordItem'

export interface CreateRecordButtonConfig {
  iconVariant: IconVariantName
  isTextHidden: boolean
  onClick?: ButtonProps['onClick']
  onFileSelected?: (files: File[]) => void
  targetType: RecordItemTargetType
  text: string
  tooltipText: string
  buttonVariant: ButtonVariantName
}

export interface CreateRecordButtonProps
  extends WithTooltipProps<ButtonProps>,
    CreateRecordButtonConfig {
  isFilePickerButton?: boolean
  isMouseActive: boolean
  isOverflow: boolean
}

export const CreateRecordButton = React.forwardRef<
  HTMLButtonElement,
  CreateRecordButtonProps
>(
  (
    {
      iconVariant,
      isFilePickerButton,
      isMouseActive,
      isOverflow,
      isTextHidden,
      onClick = () => undefined,
      onFileSelected = () => undefined,
      targetType,
      text,
      tooltipText,
      buttonVariant,
      ...props
    },
    ref,
  ) => {
    //
    const inputRef = useRef<HTMLInputElement>(null)

    const stopPropagationAndPreventDefault = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation()
        event.preventDefault()
      },
      [],
    )

    const fileInputOnChange = useCallback(() => {
      const files = (inputRef.current as any)?.files ?? []
      onFileSelected && onFileSelected(files)
    }, [onFileSelected])

    // Hack to make the entire file button clickable
    // (without opening the file input twice)
    const _onClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isFilePickerButton) {
          e.preventDefault()
          e.stopPropagation()
          setTimeout(() => {
            inputRef?.current?.click()
          }, 0)
        }

        onClick(e)
      },
      [isFilePickerButton, onClick],
    )

    // Open the file picker if it's a file button
    const buttonContent = isFilePickerButton ? (
      <S.Inner>
        <input
          type="file"
          name="file"
          id="file"
          onChange={fileInputOnChange}
          onClick={(e) => e.stopPropagation()}
          style={{ display: `none` }}
          ref={inputRef}
        />
        <S.Label htmlFor="file">
          <S.Icon
            boxSize={16}
            variant={iconVariant}
            className="createRecordButtonIcon"
          />
          <div className={'createRecordButtonText'}>{text}</div>
        </S.Label>
      </S.Inner>
    ) : (
      <S.Inner>
        <S.Icon
          boxSize={16}
          variant={iconVariant}
          className="createRecordButtonIcon"
        />
        <div className={'createRecordButtonText'}>{text}</div>
      </S.Inner>
    )

    return (
      <S.CreateRecordButton
        ref={ref}
        onMouseDown={stopPropagationAndPreventDefault}
        onClick={_onClick}
        // Need this generic classname so we can use it to calculate the width of all buttons in list
        slotProps={{
          root: {
            className: cx(`createRecordButton`, {
              isMouseActive,
              isOverflow,
              isTextHidden,
            }),
          },
        }}
        variant={buttonVariant}
        {...props}
      >
        {buttonContent}
      </S.CreateRecordButton>
    )
  },
)

const S = {
  CreateRecordButton: styled(withTooltip(Button))(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    maxWidth: 109,
    width: `auto`,
    fontWeight: 500,
    fontSize: 13,
    padding: `12px`,
    borderRadius: 6,
    height: 32,
    cursor: `pointer`,

    '&:disabled': { opacity: 1 },

    '&:first-of-type': {
      marginRight: 8,
      fontWeight: 700,
    },

    '&.isOverflow': {
      '& .createRecordButtonText': {
        display: `none`,
      },

      '& .createRecordButtonIcon': {
        marginRight: 0,
      },
    },

    '&.isTextHidden': {
      '& .createRecordButtonText': {
        display: `none`,
      },

      '& .createRecordButtonIcon': {
        marginRight: 0,
      },
    },
  })),
  Inner: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    width: `auto`,
    cursor: `pointer`,
  }),
  Icon: styled(Icon)({
    marginRight: 8,
  }),
  Label: styled.label({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    width: `auto`,
    cursor: `pointer`,
  }),
}
