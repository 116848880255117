import React, { useEffect, useState } from 'react'

import { SlideProps } from '.'
import diagramChromeExtensionSrc from '../../../../assets/onboarding/diagram-chrome-extension@2x.png'
import { Key, useKeys } from '../../../../common/hooks/useKeys'
import { cx, styled } from '../../../../common/stationary'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideSecondaryAction,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/SplitCardSlide'
import { useReward } from 'react-rewards'

type PlatformState = 'Chrome' | 'Safari'
type InstallState = 'Start' | 'WaitingForDownload' | 'Finish'

export const InstallChromeExtension: React.FC<SlideProps> = ({ goForward }) => {
  const [platformState, setPlatformState] = useState<PlatformState>('Chrome') // @todo Automatically detect platform
  const [installState, setInstallState] = useState<InstallState>('Start')

  const { onboardingAnalytics } = useOnboardingContext()

  const { reward: emojiReward } = useReward('emojiRewardId', 'emoji', {
    emoji: ['✅'],
    zIndex: 1,
  })

  useEffect(() => {
    if (installState === 'Finish') emojiReward()
  }, [installState])

  useEffect(() => {
    if (installState === 'WaitingForDownload') {
      setTimeout(() => {
        setInstallState('Finish')
      }, 1000)
    }
  }, [platformState, installState, setInstallState])

  useKeys([Key.Enter], () => installState === 'Finish' && goForward())

  const getActivePlatformTitle = () =>
    platformState === 'Chrome' ? 'Chrome' : 'Safari'

  const getInactivePlatformTitle = () =>
    platformState === 'Chrome' ? 'Safari' : 'Chrome'

  const togglePlatformState = () => {
    setPlatformState(platformState === 'Chrome' ? 'Safari' : 'Chrome')
    handleDownloadButtonClick()
  }

  const getActivePlatformDownloadUrl = () => {
    if (platformState === 'Chrome')
      return 'https://chrome.google.com/webstore/detail/capture-web-clipper/ndapdpcgnfoamdnjdicpeaimjmkpnman'
    if (platformState === 'Safari') return '' // @todo Fill in this URL
  }

  const handleDownloadButtonClick = () => {
    onboardingAnalytics.action('Browser Extension Install Button Clicked')
    setInstallState('WaitingForDownload')
    /**
     * IMPORTANT: window.open must be fired from a synchronous onClick to not get blocked in Safari
     */
    window.open(getActivePlatformDownloadUrl(), '_blank')
  }

  return (
    <S.InstallChromeExtension>
      <TransitionSlideContainer>
        <SlideIntro>
          <SlideTitle>Chrome extension</SlideTitle>
          <SlideDescription>
            <strong>Save content from the web</strong> with one click and
            right-click to capture highlighted text.
          </SlideDescription>
          <SlidePrimaryButton
            className={cx({ isSuccess: installState === 'Finish' })}
            disabled={installState !== 'Start'}
            id="emojiRewardId"
            onClick={handleDownloadButtonClick}
          >
            <strong>Download for {getActivePlatformTitle()}</strong>
            {installState === 'Finish' && <span>✅</span>}
          </SlidePrimaryButton>
          {/* @todo Finish altnernate platform feature and uncomment */}
          {/* <SlideSecondaryAction>
                  or{' '}
                  <a onClick={() => togglePlatformState()}>
                    download for {getInactivePlatformTitle()}
                  </a>
                </SlideSecondaryAction> */}
          <SlideButtonGroup
            style={{
              display: installState !== 'Finish' ? 'none' : undefined,
            }}
          >
            <SlideSecondaryAction>
              <a className="isItalic" onClick={() => goForward()}>
                Skip for now
              </a>
            </SlideSecondaryAction>
          </SlideButtonGroup>
          <SlideButtonGroup
            style={{
              display: installState !== 'Finish' ? 'none' : undefined,
            }}
          >
            <SlidePrimaryButton onClick={() => goForward()}>
              <strong>Continue</strong>
            </SlidePrimaryButton>
          </SlideButtonGroup>
        </SlideIntro>
        <SlideDiagrams>
          <img
            src={diagramChromeExtensionSrc}
            srcSet={`${diagramChromeExtensionSrc} 2x`}
          />
        </SlideDiagrams>
      </TransitionSlideContainer>
    </S.InstallChromeExtension>
  )
}

const S = {
  InstallChromeExtension: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',
  })),
}
