import { WriteTransaction } from 'replicache'

import { MoveIntoParentArgs } from '@eleventhlabs/capture-shared/lib/replicache/mutations'

import {
  addToChildren,
  createDestIfNeededAndNormalizeIdx,
  getRecord,
  removeFromChildrenPropOfParents,
  setParentIdForRecords,
} from './utils'
import { isParentableRecord } from '@eleventhlabs/capture-shared'

export const moveIntoParent = async (
  tx: WriteTransaction,
  { recordIds, index }: MoveIntoParentArgs,
) => {
  const normalizedIndex = await createDestIfNeededAndNormalizeIdx(tx, index)
  const nonSoftDeletedRecordIds = []
  for (const recordId of recordIds) {
    const record = await getRecord(tx, recordId)
    if (isParentableRecord(record) && !record.isSoftDeleted) {
      nonSoftDeletedRecordIds.push(record.id)
    }
  }
  await removeFromChildrenPropOfParents(tx, recordIds)
  await setParentIdForRecords(tx, recordIds, normalizedIndex.id)
  await addToChildren(
    tx,
    normalizedIndex.id,
    nonSoftDeletedRecordIds,
    normalizedIndex.point,
  )
}
