import { CSSObject } from '../styled'

export const screenStyles: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: `column`,
  position: `relative`,
  width: `100%`,
  paddingTop: 'calc(56px + env(safe-area-inset-top))', // HeaderDimension.height
}

export const screenInnerStyles: CSSObject = {
  position: `relative`,
  width: `100%`,
  maxWidth: 832,
  marginBottom: 256, // Use margin instead of padding so that ClickOutsideListener works properly
}

export const screenHeaderStyles: CSSObject = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-start`,
  justifyContent: `flex-start`,
  userSelect: `none`,
  outline: `none`,
  width: `100%`,
  padding: `0 20px`,
}
