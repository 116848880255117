import React, { useCallback } from 'react'

import { URLDescription } from './components/URLDescription'
import { URLDomain } from './components/URLDomain'
import { URLFavicon } from './components/URLFavicon'
import { URLThumbnail } from './components/URLThumbnail'
import { URLTitle } from './components/URLTitle'
import { Icon, cx, styled } from '../../../../common/stationary'

interface FullScreenURLRecordItemContentProps {
  id: string
  url?: string
  title?: string
  thumbnail?: string
  favicon?: string
  domain?: string
  description?: string
  className?: string
  style?: any
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const FullScreenURLRecordItemContent: React.FC<
  FullScreenURLRecordItemContentProps
> = ({ url, title, thumbnail, favicon, domain, description, className }) => {
  const loading = !url
  const validURL = !!url

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.metaKey || e.shiftKey) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      } else {
        e.preventDefault()
        if (url) window.open(url, `_blank`)
      }
    },
    [url],
  )

  return (
    <S.FullScreenURLRecordItemContent
      className={cx(className, {
        loading,
      })}
      title={url}
    >
      <S.Title className={cx({ validURL })}>
        <URLTitle title={title} url={url} loading={loading} onClick={onClick} />
      </S.Title>
      <S.Details>
        <URLFavicon favicon={favicon} loading={loading} />
        <URLDomain domain={domain} loading={loading} />
        <URLDescription description={description} loading={loading} />
      </S.Details>
      <S.Thumbnail>
        <URLThumbnail
          url={url}
          thumbnail={thumbnail}
          loading={loading}
          onClick={onClick}
        />
      </S.Thumbnail>
      <S.OpenURL href={url} onClick={onClick}>
        <S.OpenUrlText>Open link</S.OpenUrlText>
        <S.Icon boxSize={16} variant="glyphArrowE" />
      </S.OpenURL>
    </S.FullScreenURLRecordItemContent>
  )
}

const S = {
  FullScreenURLRecordItemContent: styled.div({
    display: `flex`,
    width: `100%`,
    maxWidth: `100%`,
    flexDirection: `column`,
    userSelect: `none`,
  }),
  Title: styled.div({
    display: `flex`,
    flexDirection: `row`,
    flex: 1,
    alignItems: `center`,
    justifyContent: `flex-start`,
    paddingLeft: 24,
  }),
  Details: styled.div({
    display: `flex`,
    flexDirection: `row`,
    flex: 1,
    alignItems: `center`,
    justifyContent: `flex-start`,
    paddingLeft: 24,
  }),
  Thumbnail: styled.div({
    display: `flex`,
    flex: 1,
    flexDirection: `row`,
    margin: `32px 0`,
  }),
  OpenURL: styled.a({
    display: `flex`,
    flex: 1,
    flexDirection: `row`,
    paddingLeft: 24,
    alignItems: `center`,
    justifyContent: `flex-start`,
    textDecoration: `none`,
    cursor: `pointer`,
  }),
  OpenUrlText: styled.div(({ theme }) => ({
    display: `flex`,
    fontWeight: 500,
    ...theme.text.publicSans['16:24'],
    color: theme.colors.text[500],
  })),
  Icon: styled(Icon)(({ theme }) => ({
    display: `flex`,
    color: theme.colors.text[500],
    marginLeft: 5,
  })),
}
