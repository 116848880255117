import React, { useCallback, useEffect, useRef } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { RecordType } from '@eleventhlabs/capture-shared'

import { useUIContext } from '../../../common/contexts/UIContext'
import { getIsExpanded } from '../../../common/expandRecord'
import {
  RecordItemDimension,
  RecordItemHandleOnMouse,
  RecordItemOnFocus,
} from '../types'

export const useExpandAndCollapse = (
  record: { id: string; type: RecordType },
  hasTags: boolean,
  onShowMoreClick: RecordItemHandleOnMouse | undefined,
  onFocus: RecordItemOnFocus | undefined,
  expandOnFocus: RecordItemOnFocus | undefined,
  isFocused: boolean,
  isImageRecord: boolean,
) => {
  const { recordItemExpandState, setRecordItemExpandState } = useUIContext()

  const containerRef = useRef<HTMLDivElement>(null)
  const { height, ref: contentRef } = useResizeDetector()

  const isExpanded = getIsExpanded(recordItemExpandState, record) || isFocused
  const isShortRecord = !!(height && height <= RecordItemDimension.maxHeight)
  const isLongRecord = !!(height && height > RecordItemDimension.maxHeight)

  const isExpandableRecord = isLongRecord || isImageRecord

  let isCollapsed = isExpandableRecord && !isExpanded && !isFocused

  const maxHeight = isCollapsed
    ? hasTags
      ? RecordItemDimension.maxHeight + RecordItemDimension.propertiesStrip
      : RecordItemDimension.maxHeight
    : height

  const isMaxHeightInTransition =
    recordItemExpandState[record.id]?.isMaxHeightInTransition === true
  const isFullyExpanded =
    isExpandableRecord && Boolean(isExpanded) && !isMaxHeightInTransition

  const setIsMaxHeightInTransition = useCallback(
    (value: boolean) => {
      setRecordItemExpandState((prev) => {
        return {
          ...prev,
          [record.id]: {
            isExpanded: getIsExpanded(prev, {
              id: record.id,
              type: record.type,
            }),
            isMaxHeightInTransition: value,
          },
        }
      })
    },
    [record.id, record.type, setRecordItemExpandState],
  )

  useEffect(() => {
    if (!containerRef.current) return
    const el = containerRef.current
    const endExpandTransition = () => setIsMaxHeightInTransition(false)
    el.addEventListener(`transitionend`, endExpandTransition, true)
    return () => el?.removeEventListener(`transitionend`, endExpandTransition)
  }, [isExpanded, setIsMaxHeightInTransition])

  const _onShowMoreClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!!onShowMoreClick) {
        setIsMaxHeightInTransition(true)
        onShowMoreClick(event)
      }
    },
    [onShowMoreClick, setIsMaxHeightInTransition],
  )

  const _onFocus = useCallback(
    (event: React.FocusEvent<HTMLDivElement, Element>) => {
      if (!!onFocus && !!expandOnFocus) {
        setIsMaxHeightInTransition(true)
        onFocus(event)

        // Only explicitly expand the record if it's a long record that has been collapsed
        if (isCollapsed) {
          expandOnFocus(event)
        }
      }
    },
    [record.id, isCollapsed, expandOnFocus, setIsMaxHeightInTransition],
  )

  return {
    containerRef,
    contentRef,
    height,
    maxHeight,
    isShortRecord,
    isLongRecord,
    isCollapsed,
    isExpanded,
    isFullyExpanded,
    onShowMoreClick: _onShowMoreClick,
    isMaxHeightInTransition,
    onFocus: _onFocus,
  }
}
