import React from 'react'

import { styled } from '../../../../../common/stationary'

interface FileDescriptionProps {
  description?: string
  loading?: boolean
}

export const FileDescription: React.FC<FileDescriptionProps> = ({
  description,
  loading,
}) => {
  return loading ? <S.Empty /> : <S.Description>{description}</S.Description>
}

const S = {
  Description: styled.div(({ theme }) => ({
    display: `block`,
    flex: 1,
    marginRight: 8,
    whiteSpace: `nowrap`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    alignItems: `flex-start`,
    justifyContent: `flex-start`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: '24px',
    color: theme.colors.text[500],
  })),
  Empty: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 96px`,
    height: 16,
    marginRight: 8,
    background: theme.colors.alpha.tone.weak,
    borderRadius: 6,
  })),
}
