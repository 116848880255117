import { CSSObject } from '../styled'
import { RGBAStyle, RGBStyle, Theme, extractRGB, isRGBAStyle } from '../theme'
import { stackBackgroundStyles } from './backgroundStyles'

export const collapsedStripClassName = 'collapsedStrip'
export const propertiesStripClassName = 'propertiesStrip'
export const notesContentClassName = 'notesContent'

export const thumbnailContentClassName = 'thumbnailContent'

const recordItemDescendentSelectors = `.${collapsedStripClassName}, .${propertiesStripClassName}, .${notesContentClassName},`
const collapsedStripGradientSelector = `.${collapsedStripClassName}::before`
const thumbnailContentOverlaySelector = `.${thumbnailContentClassName}::before`

const getRecordItemBackgroundStyles = ({
  backgroundColors,
}: {
  backgroundColors: Array<RGBStyle | RGBAStyle>
}): CSSObject => ({
  ...stackBackgroundStyles({ backgroundColors }),

  [recordItemDescendentSelectors]: {
    ...stackBackgroundStyles({ backgroundColors }),
  },
  [collapsedStripGradientSelector]: {
    ...stackBackgroundStyles({
      backgroundColors: backgroundColors.map((backgroundColor) =>
        isRGBAStyle(backgroundColor)
          ? [`rgba(${extractRGB(backgroundColor)} / 0)`, backgroundColor]
          : [
              `rgba(${extractRGB(backgroundColor)} / 0)`,
              `rgba(${extractRGB(backgroundColor)} / 1)`,
            ],
      ),
    }),
  },
})

/**
 * recordItemStyles
 */

export const recordItemStyles = ({ theme }: { theme: Theme }): CSSObject => ({
  display: 'flex',
  position: 'relative',
  minHeight: 64,
  borderRadius: 8,
  boxShadow: theme.effects.boxShadow.elevation[1],

  ...getRecordItemBackgroundStyles({
    backgroundColors: [theme.colors.surface.base],
  }),
})

export const recordItemStyles_inSelection = ({
  theme,
}: {
  theme: Theme
}): CSSObject => ({
  outline: `1px solid ${theme.colors.alpha.selection.strong}`,

  ...getRecordItemBackgroundStyles({
    backgroundColors: [
      theme.colors.surface.base,
      theme.colors.alpha.selection.medium,
    ],
  }),
  [thumbnailContentOverlaySelector]: {
    ...stackBackgroundStyles({
      backgroundColors: [theme.colors.alpha.selection.medium],
    }),
  },
})

export const recordItemStyles_inSelection_hover = ({
  theme,
}: {
  theme: Theme
}): CSSObject => ({
  boxShadow: theme.effects.boxShadow.elevation[2],
  outline: `1px solid ${theme.colors.alpha.selection.strong}`,

  ...getRecordItemBackgroundStyles({
    backgroundColors: [
      theme.colors.surface.base,
      theme.colors.alpha.selection.medium,
      theme.colors.alpha.tone.extraWeak,
    ],
  }),
  [thumbnailContentOverlaySelector]: {
    ...stackBackgroundStyles({
      backgroundColors: [
        theme.colors.alpha.selection.medium,
        theme.colors.alpha.tone.extraWeak,
      ],
    }),
  },
})

export const recordItemStyles_hover = ({
  theme,
}: {
  theme: Theme
}): CSSObject => ({
  boxShadow: theme.effects.boxShadow.elevation[2],
  outline: `1px solid ${theme.colors.alpha.tone.weak}`,

  ...getRecordItemBackgroundStyles({
    backgroundColors: [
      theme.colors.surface.base,
      theme.colors.alpha.tone.extraWeak,
    ],
  }),
  [thumbnailContentOverlaySelector]: {
    ...stackBackgroundStyles({
      backgroundColors: [theme.colors.alpha.tone.extraWeak],
    }),
  },
})

export const recordItemStyles_focus = ({
  theme,
}: {
  theme: Theme
}): CSSObject => ({
  boxShadow: theme.effects.boxShadow.elevation[2],
  outline: `1px solid ${theme.colors.alpha.tone.mediumWeak}`,

  ...getRecordItemBackgroundStyles({
    backgroundColors: [theme.colors.surface.base],
  }),
})

/**
 * recordItemInnerStyles
 */

export const recordItemInnerStyles: CSSObject = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  overflow: 'hidden',
  borderRadius: 8,
}

/**
 * recordContentStyles
 */

export const recordContentStyles: CSSObject = {
  display: 'flex',
  position: 'relative',
  width: '100%',
}

/**
 * recordContentInnerStyles
 */

export const recordContentInnerStyles: CSSObject = {
  display: 'flex',
  position: 'absolute',
  left: 0,
  right: 0,
}

/**
 * thumbnailContentStyles
 */

export const thumbnailContentStyles: CSSObject = {
  position: 'relative',
}

export const thumbnailContentOverlayStyles: CSSObject = {
  content: '""',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}

/**
 * collapsedStripStyles
 */

export const collapsedStripStyles = ({
  theme,
}: {
  theme: Theme
}): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 40,
  paddingLeft: 20,
  ...theme.text.publicSans['13.5:20'],
})

export const collapsedStripGradientStyles: CSSObject = {
  content: '""',
  position: 'absolute',
  bottom: 40,
  left: 0,
  right: 0,
  height: 40,
  pointerEvents: 'none',
}

/**
 * propertiesStripStyles
 */

export const propertiesStripStyles = ({
  theme,
}: {
  theme: Theme
}): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 5,
  height: 56,

  '&.isCollapsed': {
    borderTop: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
  },

  '&.isImageRecord': {
    borderTop: 'none',
  },
})
