import { InboxRecord, InboxRecordId } from '@eleventhlabs/capture-shared'
import { ModelStore } from '../../../modelStore'
import { RecordGroupData, RecordGroupType } from '../../types'
import { createRecordGroupData } from '../common'
import { getInboxRecords } from '../inbox'
import { COMPLETED } from '../../../../utils/uiConstants'

export enum InboxRecordGroupId {
  Inbox = `inbox`,
  Completed = `completed`,
}

export const createInboxRecordGroups = (
  data: ModelStore,
  showCompleted: boolean,
): RecordGroupData[] => {
  const inboxRecord = data.records[InboxRecordId] as InboxRecord
  const listData = getInboxRecords(data.records, inboxRecord)

  const groups = [
    createRecordGroupData(
      listData.records,
      RecordGroupType.ListMain,
      InboxRecordGroupId.Inbox,
    ),
  ]

  if (showCompleted) {
    groups.push(
      createRecordGroupData(
        listData.completedRecords,
        RecordGroupType.ListComplete,
        InboxRecordGroupId.Completed,
        COMPLETED,
      ),
    )
  }

  return groups
}
