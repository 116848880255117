import {
  NoteRecord,
  NoteableRecord,
  RecordDict,
} from '@eleventhlabs/capture-shared'

export const getNotesForRecord = (
  records: RecordDict,
  recordId: string,
): NoteRecord[] => {
  const record = records[recordId] as NoteableRecord
  return record.notes.map((id) => records[id] as NoteRecord)
}
