import { EditorsManager } from '../../../../EditorsManager'
import { BaseSelection } from '../../../../libs/selection'
import { DataRecord, isNoteableRecord } from '@eleventhlabs/capture-shared'

export const removeNote = (
  activeRecords: DataRecord[],
  noteId: string | undefined,
  recordId: string | undefined,
  selection: BaseSelection,
  deleteNotes: (ids: string[]) => void,
): string | null => {
  if (noteId) {
    EditorsManager.effects.blur(noteId)
    deleteNotes([noteId])
    selection.set(recordId ? [recordId] : [])
    return noteId
  }

  if (activeRecords.length === 1 && isNoteableRecord(activeRecords[0])) {
    const firstNoteId = activeRecords[0]?.notes[0]
    if (firstNoteId) {
      EditorsManager.effects.blur(firstNoteId)
      deleteNotes([firstNoteId])
      selection.set(recordId ? [recordId] : [])
      return firstNoteId
    }
  }

  return null
}
