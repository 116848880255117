import {
  getIndexForPositionInFirstLine,
  getIndexForPositionInLastLine,
} from '../'

type HTMLElementWithFocusAndSetSelection =
  | HTMLInputElement
  | HTMLTextAreaElement

export const focusHtmlElementAtEnd = (
  element: HTMLElementWithFocusAndSetSelection,
): void => {
  const text = element.textContent
  if (text === null) return
  focusElementWithCollapsedSelectionAtIndex(element, text.length)
}

export const focusHtmlElementAtPositionInFirstLine = (
  element: HTMLElementWithFocusAndSetSelection,
  position = 0,
): void => {
  const text = element.textContent
  if (text === null) return
  const indexOfPositionInFirstLine = getIndexForPositionInFirstLine(
    text,
    position,
  )
  focusElementWithCollapsedSelectionAtIndex(element, indexOfPositionInFirstLine)
}

export const focusHtmlElementAtPositionInLastLine = (
  element: HTMLElementWithFocusAndSetSelection,
  position = 0,
): void => {
  const text = element.textContent
  if (text === null) return
  const indexOfPositionInLastLine = getIndexForPositionInLastLine(
    text,
    position,
  )
  focusElementWithCollapsedSelectionAtIndex(element, indexOfPositionInLastLine)
}

export const focusElementWithCollapsedSelectionAtIndex = (
  element: HTMLElementWithFocusAndSetSelection,
  index: number,
): void => {
  element.focus()
  element.setSelectionRange(index, index)
}

export const blurActiveElement = (): void => {
  if (document?.activeElement instanceof HTMLElement)
    document.activeElement.blur()
  window.getSelection()?.removeAllRanges()
}

export const placeCaretAtEnd = (el: HTMLElement): void => {
  el.focus()
  if (
    typeof window.getSelection !== `undefined` &&
    typeof document.createRange !== `undefined`
  ) {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel?.removeAllRanges()
    sel?.addRange(range)
  }
}

export const focusEl = (
  el: HTMLElement,
  position: `start` | `end` = `start`,
) => {
  el.focus()

  if (position === `start`) return

  const sel = window.getSelection ? window.getSelection() : null
  const range = document.createRange ? document.createRange() : null

  if (sel && range) {
    range.selectNodeContents(el)
    range.collapse(false)

    sel.removeAllRanges()
    sel.addRange(range)
  }
}
