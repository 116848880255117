import React, { useCallback } from 'react'

import { Tag } from '../../../common/data/tags'
import { emptyFn } from '../../../common/utils'
import { RecordItemTagOnMouse } from '../types'
import { TagItemContentWithoutTooltip } from './TagItemContent'
import { TagStripItemContent } from './TagStripItemContent'
import {
  WithDnDContext,
  withDnDContext,
} from '../../../common/dnd/enhancers/withDnDContext'
import { cx, styled } from '../../../common/stationary'

interface TagStripItemProps {
  tag: Tag
  className?: string
  onTagClick?: RecordItemTagOnMouse
  skipOccurrence: () => void
  isCompleted?: boolean
  notesOpen?: boolean
}

type TagStripItemPropsEnhanced = TagStripItemProps & WithDnDContext

const TagStripItem: React.FC<TagStripItemPropsEnhanced> = ({
  tag,
  className,
  onTagClick = emptyFn,
  skipOccurrence,
  isCompleted = false,
  notesOpen = false,
  isDragInProgress,
}) => {
  const onClick = useCallback(
    (e) => {
      onTagClick(e, tag)
    },
    [onTagClick, tag],
  )

  return (
    <S.TagStripItem
      className={cx(className, {
        isDragInProgress,
      })}
      onMouseDown={emptyFn}
    >
      <TagStripItemContent
        tag={tag}
        isCompleted={isCompleted}
        notesOpen={notesOpen}
        onClick={onClick}
        skipOccurrence={skipOccurrence}
      />
    </S.TagStripItem>
  )
}

export default withDnDContext(TagStripItem)

const S = {
  TagStripItem: styled(TagItemContentWithoutTooltip)({
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    cursor: `pointer`,
    height: 24,
    pointerEvents: `auto`,

    '&.isDragInProgress': {
      pointerEvents: `none`,
    },
  }),
}
