import React from 'react'

import { cx, styled } from '../../../common/stationary'
import { Modal } from '../../../components/Modal'

interface ModalContentConfig {
  headerContent: string | JSX.Element
  bodyContent: string | JSX.Element
  leftButton: {
    content: string | JSX.Element
    onClick?: () => void | Promise<void>
    isDanger?: boolean
    goForwardOnClick?: boolean
  }
  rightButton: {
    content: string | JSX.Element
    onClick?: () => void | Promise<void>
    isDanger?: boolean
    goForwardOnClick?: boolean
  }
}

export interface ConfirmationModalProps {
  goForward: (skipToIndex?: number) => void
  closeModal: () => void
  config: ModalContentConfig
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  goForward,
  closeModal,
  config,
}) => {
  const goForwardWithTimeout = () => {
    setTimeout(() => {
      goForward()
    }, 500)
  }

  const closeModalAndMaybeGoForward = (button: 'left' | 'right') => {
    closeModal()

    const shouldGoForwardForLeftButton =
      button === 'left' && config.leftButton.goForwardOnClick

    const shouldGoForwardForRightButton =
      button === 'right' && config.rightButton.goForwardOnClick

    if (shouldGoForwardForLeftButton || shouldGoForwardForRightButton) {
      goForwardWithTimeout()
    }
  }

  const onLeftButtonClick = async () => {
    config.leftButton.onClick && (await config.leftButton.onClick())
    closeModalAndMaybeGoForward('left')
  }

  const onRightButtonClick = async () => {
    config.rightButton.onClick && (await config.rightButton.onClick())
    closeModalAndMaybeGoForward('right')
  }

  return (
    <S.Modal open onClose={closeModal}>
      <S.ModalInner>
        <S.ModalTitle>{config.headerContent}</S.ModalTitle>
        <S.ModalParagraph>{config.bodyContent}</S.ModalParagraph>
        <S.FooterButtonsContainer>
          <S.LeftButton
            className={cx({ isDanger: config.leftButton.isDanger })}
            onClick={onLeftButtonClick}
          >
            {config.leftButton.content}
          </S.LeftButton>
          <S.RightButton
            className={cx({ isDanger: config.rightButton.isDanger })}
            onClick={onRightButtonClick}
          >
            {config.rightButton.content}
          </S.RightButton>
        </S.FooterButtonsContainer>
      </S.ModalInner>
    </S.Modal>
  )
}

export default ConfirmationModal

const S = {
  Modal: styled(Modal)(({ theme }) => ({
    position: 'relative',
    width: 272,
    height: 208,
    flexShrink: 0,
    borderRadius: 10,
    background: theme.colors.surface.base,
    boxShadow: theme.effects.boxShadow.elevation[4],
    marginTop: `82%`,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 1000000000,
  })),
  ModalInner: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: 384,
    borderRadius: 10,
    background: theme.colors.surface.base,
  })),
  ModalTitle: styled.h1(({ theme }) => ({
    ...theme.text.publicSans['19:28'],
    fontWeight: 800,
    color: theme.colors.text[900],
    textAlign: 'center',
    marginTop: 20,
    marginLeft: 16,
    marginRight: 16,
    lineHeight: `24px`,
  })),
  ModalParagraph: styled.p(({ theme }) => ({
    margin: `8px 16px 20px`,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[900],
    textAlign: 'center',
    fontWeight: 400,

    strong: {
      fontWeight: 'bold',
    },
  })),
  ModalDivider: styled.p(({ theme }) => ({
    height: 1,
    margin: '24px 0 32px',
    background: theme.colors.alpha.border.mediumWeak,
  })),
  FooterButtonsContainer: styled.div(({ theme }) => ({
    display: 'flex',
    height: `100%`,
    borderTop: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
  })),
  LeftButton: styled.div(({ theme }) => ({
    flex: 1,
    borderRight: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.text[900],
    fontWeight: 600,

    '&.isDanger': {
      color: theme.colors.red[700],
    },
  })),
  RightButton: styled.div(({ theme }) => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.text[900],
    fontWeight: 600,

    '&.isDanger': {
      color: theme.colors.red[700],
    },
  })),
}
