import {
  CURRENT_RICH_TEXT_VERSION,
  CreateTextRecordPayload,
  CreateURLRecordPayload,
  RecordType,
  RichText,
  RichTextObjectWithVersion,
  createRichTextObject,
} from '@eleventhlabs/capture-shared'
import { isMetadataEmpty } from '../../../../../../records'
import { getUrlMetadata } from '../../../../../../urls'

export const createRecordPayloadFromRichText = async (
  richText: RichText,
): Promise<CreateTextRecordPayload | CreateURLRecordPayload> => {
  if (RichText.isSingleLink(richText))
    return await createUrlRecordPayload(RichText.getSingleLink(richText))
  else return createTextRecordPayload({ richText })
}

const createTextRecordPayload = ({
  text,
  richText,
}: {
  text?: string
  richText?: RichText
}) => {
  let payloadRichText: RichTextObjectWithVersion

  if (richText) {
    payloadRichText = {
      version: CURRENT_RICH_TEXT_VERSION,
      value: richText,
    }
  } else if (text) {
    payloadRichText = createRichTextObject(text)
  } else {
    payloadRichText = {
      version: CURRENT_RICH_TEXT_VERSION,
      value: RichText.create(),
    }
  }

  const payload: CreateTextRecordPayload = {
    richText: payloadRichText,
    type: RecordType.Text,
  }
  return payload
}
const createUrlRecordPayload = async (
  url: string,
): Promise<CreateURLRecordPayload | CreateTextRecordPayload> => {
  const urlMetadata = await getUrlMetadata(url)
  const success = !isMetadataEmpty(urlMetadata)

  if (success) {
    return {
      type: RecordType.URL,
      url,
      urlMetadata,
    }
  } else {
    return {
      type: RecordType.Text,
      richText: {
        value: RichText.create(url),
        version: CURRENT_RICH_TEXT_VERSION,
      },
    }
  }
}
