import { some } from 'lodash'

import {
  CreateRecordPayload,
  MoveToExistingPlaceIndex,
  RecordDict,
  isCompleteableRecord,
} from '@eleventhlabs/capture-shared'
import { ModelStoreCommands } from '../../../modelStore'
import { RecordGroupType, RecordGroupsCommands } from '../../types'

export const createListRecordGroupsCommands = (
  listId: string,
  records: RecordDict,
  commands: ModelStoreCommands,
): RecordGroupsCommands => {
  const isCompleted = (id: string) => {
    const record = records[id]
    return isCompleteableRecord(record) && record.isCompleted
  }
  return {
    createAtFirstInsertPosition: (payload: CreateRecordPayload) => {
      const moveToIndex: MoveToExistingPlaceIndex = {
        id: listId,
        point: { at: `start` },
      }

      const recordId = commands.createRecord(payload, moveToIndex)

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    createAtIndexInGroup: (
      payload: CreateRecordPayload,
      groupId: string,
      index: number,
    ) => {
      if (groupId === RecordGroupType.ListComplete) {
        console.error(`Cannot create completed records`)
        return
      }

      const moveToIndex: MoveToExistingPlaceIndex = {
        id: listId,
        point: { at: `index`, index },
      }

      const recordId = commands.createRecord(payload, moveToIndex)

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    addItemAtBottomOfGroup: (payload: CreateRecordPayload, groupId: string) => {
      if (groupId === RecordGroupType.ListComplete) {
        console.error(`Cannot create completed records`)
        return
      }

      const moveToIndex: MoveToExistingPlaceIndex = {
        id: listId,
        point: { at: `end` },
      }

      const recordId = commands.createRecord(payload, moveToIndex)

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    createBelowAnchor: (payload: CreateRecordPayload, anchorId: string) => {
      if (isCompleted(anchorId)) {
        console.error(`Cannot create completed records`)
        return
      }

      const moveToIndex: MoveToExistingPlaceIndex = {
        id: listId,
        point: { at: `after`, anchorId },
      }

      const recordId = commands.createRecord(payload, moveToIndex)

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    moveBelowAnchor: (ids: string[], anchorId: string) => {
      if (some(ids.map((id) => isCompleted(id)))) {
        console.error(`Cannot move completed records`)
        return
      }
      if (isCompleted(anchorId)) {
        console.error(`Cannot complete records via reorder`)
        return
      }
      commands.moveRecords(ids, {
        id: listId,
        point: { at: `after`, anchorId },
      })
    },
    moveAboveAnchor: (ids: string[], anchorId: string) => {
      if (some(ids.map((id) => isCompleted(id)))) {
        console.error(`Cannot move completed records`)
        return
      }
      if (isCompleted(anchorId)) {
        console.error(`Cannot complete records via reorder`)
        return
      }
      commands.moveRecords(ids, {
        id: listId,
        point: { at: `before`, anchorId },
      })
    },
    moveToStartOfGroup: (ids: string[], groupId: string) => {
      if (some(ids.map((id) => isCompleted(id)))) {
        console.error(`Cannot move completed records`)
        return
      }
      if (groupId === RecordGroupType.ListComplete) {
        console.error(`Cannot complete records via reorder`)
        return
      }
      commands.moveRecords(ids, {
        id: listId,
        point: { at: `start` },
      })
    },
    moveToEndOfGroup: (ids: string[], groupId: string) => {
      if (some(ids.map((id) => isCompleted(id)))) {
        console.error(`Cannot move completed records`)
        return
      }
      if (groupId === RecordGroupType.ListComplete) {
        console.error(`Cannot complete records via reorder`)
        return
      }
      commands.moveRecords(ids, {
        id: listId,
        point: { at: `end` },
      })
    },
  }
}
