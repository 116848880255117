import { startOfTomorrow } from 'date-fns'
import React, { useCallback, useMemo } from 'react'

import CalendarDialog from '../../components/CalendarDialog'
import { useDialogContext } from '../contexts/DialogContext'
import { DialogType } from './types'

const useCalendarDialog = (
  isViewVisible: (action: DialogType) => boolean,
  onSelectDate: (value: string) => void,
  maxDate?: Date,
) => {
  const { closeDialog } = useDialogContext()

  const _onSelectDate = useCallback(
    (value: string) => {
      onSelectDate(value)
      closeDialog()
    },
    [onSelectDate, closeDialog],
  )

  const isOpen = isViewVisible(DialogType.Calendar)

  const today = startOfTomorrow()
  const calendarComponent = useMemo(
    () =>
      isOpen ? (
        <CalendarDialog
          isOpen={isOpen}
          onDismiss={closeDialog}
          initialDate={today}
          maxDate={maxDate}
          onSelectDate={_onSelectDate}
        />
      ) : null,
    [today, maxDate, isOpen, closeDialog, _onSelectDate],
  )

  return {
    calendarComponent,
  }
}

export default useCalendarDialog
