import React, { FC } from 'react'

import { ExecProvider } from '../../common/contexts/ExecContext'
import { DerivedStateProvider } from '../../common/contexts/DerivedStateContext'
import { DialogProvider } from '../../common/contexts/DialogContext'
import { DnDProvider } from '../../common/contexts/DnDContext'
import { GlobalLoggedInProvider } from '../../common/contexts/GlobalLoggedInContext'
import { IntentProvider } from '../../common/contexts/IntentContext'
import { ModelStoreProvider } from '../../common/contexts/ModelStoreContext'
import { OneSignalProvider } from '../../common/contexts/OneSignalContext'
import { SelectionProvider } from '../../common/contexts/SelectionContext'
import { UIProvider } from '../../common/contexts/UIContext'
import { ReplicacheProvider } from '../../common/replicache/ReplicacheContext'
import { ReactDnDProvider } from '../../common/contexts/ReactDnDContext'
import { LoggerProvider } from '../../common/contexts/LoggerContext'
import { SnackbarProvider } from '../../common/contexts/SnackbarContext'
import { ModalProvider } from '../../common/modals/ModalContext'
import { DragToSelectProvider } from '../../common/dragToSelect/DragToSelectContext'

const ProtectedProviders: FC = (props) => {
  return (
    <GlobalLoggedInProvider>
      <LoggerProvider>
        <OneSignalProvider>
          <ReplicacheProvider>
            <SnackbarProvider>
              <ModelStoreProvider>
                <IntentProvider>
                  <ModalProvider>
                    <UIProvider>
                      <SelectionProvider>
                        <DerivedStateProvider>
                          <DialogProvider>
                            <DnDProvider>
                              <ReactDnDProvider>
                                <DragToSelectProvider>
                                  <ExecProvider>{props.children}</ExecProvider>
                                </DragToSelectProvider>
                              </ReactDnDProvider>
                            </DnDProvider>
                          </DialogProvider>
                        </DerivedStateProvider>
                      </SelectionProvider>
                    </UIProvider>
                  </ModalProvider>
                </IntentProvider>
              </ModelStoreProvider>
            </SnackbarProvider>
          </ReplicacheProvider>
        </OneSignalProvider>
      </LoggerProvider>
    </GlobalLoggedInProvider>
  )
}

export function withProtectedProviders<T extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const ComponentWithProtectedProviders = (props: T) => {
    return (
      <ProtectedProviders>
        <WrappedComponent {...props} />
      </ProtectedProviders>
    )
  }
  ComponentWithProtectedProviders.displayName = `ComponentWithProtectedProviders`
  return ComponentWithProtectedProviders
}
