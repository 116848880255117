import React from 'react'
import { EditorsManager } from '../../common/EditorsManager'
import { Editor } from '@eleventhlabs/capture-shared'

export const withRegistration = <T,>(WrappedComponent: React.FC<T>) => {
  const ComponentWithRegistration: React.FC<T & { id: string }> = (props) => {
    const id = props.id
    const onEditorCreated = React.useCallback(
      (editor: Editor) => {
        EditorsManager.register(id, editor)
      },
      [id],
    )
    const onEditorDestroyed = React.useCallback(
      (_: Editor) => {
        EditorsManager.unregister(id)
      },
      [id],
    )
    return (
      <WrappedComponent {...{ ...props, onEditorCreated, onEditorDestroyed }} />
    )
  }
  return ComponentWithRegistration
}
