import {
  TooltipRootVariantName,
  tooltipStyles,
  TooltipUnstyled,
  TooltipUnstyledProps,
} from '@eleventhlabs/stationary'

import { excludeItems, styled } from '../styled'

export type TooptipVariantName = TooltipRootVariantName

export interface TooltipStyledProps {
  variant?: TooptipVariantName
}

export type TooltipProps = TooltipUnstyledProps & TooltipStyledProps

export const Tooltip = styled(TooltipUnstyled, {
  shouldForwardProp: excludeItems([`variant`]),
})<TooltipStyledProps>(({ theme, variant = `shade` }) => ({
  margin: 4,
  padding: `4px 8px`,
  borderRadius: 6,

  ...(variant && tooltipStyles.rootVariants[variant]({ theme })),
}))

export const TooltipRow = styled.div(({ theme }) => ({
  ...theme.text.publicSans[`13.5:20`],
  fontWeight: 600,
  textAlign: `center`,

  'span.weak': {
    opacity: 0.6,
  },
}))
