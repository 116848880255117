import { isEmpty } from 'lodash'

import {
  isFileRecord,
  isUrlRecord,
  isNoteableRecord,
  isParentableRecord,
  RecordDict,
} from '@eleventhlabs/capture-shared'
import { NavigateFn } from '@gatsbyjs/reach-router'

import { EditorsManager } from '../../../../EditorsManager'
import { BaseSelection } from '../../../../libs/selection'
import { RouteType } from '../../../../routes'
import { deleteRecords as deleteRecordsCommand } from './deleteRecord'
import { exitFullscreen } from './exitFullscreen'
import { softDeleteRecords as softDeleteRecordsCommand } from './softDeleteRecords'

export const moveRecordsToTrash = (
  records: RecordDict,
  recordId: string | undefined,
  fullscreenRecordId: string | undefined,
  routeType: RouteType,
  lastRoute: string | undefined,
  setLastRoute: (value: string | undefined) => void,
  navigate: NavigateFn,
  setShouldRestoreSelection: (value: boolean) => void,
  activeRecords: (forcedRecordId?: string | undefined) => string[],
  visibleItemIds: string[],
  selection: BaseSelection,
  deleteRecords: (recordIds: string[]) => void,
  softDeleteRecords: (recordIds: string[]) => void,
): {
  softDeletedIds: string[]
  hardDeletedIds: string[]
} => {
  // Must explicitly call DELETE_RECORDS to delete.
  if (routeType === RouteType.Trash)
    return { softDeletedIds: [], hardDeletedIds: [] }

  if (routeType === RouteType.Details && fullscreenRecordId) {
    const record = records[fullscreenRecordId]
    if (isParentableRecord(record)) {
      const parentId = record.parentId
      exitFullscreen(
        parentId,
        lastRoute,
        setLastRoute,
        navigate,
        setShouldRestoreSelection,
      )
    }
  }

  const theActiveRecords = activeRecords(recordId)

  const emptyRecords = theActiveRecords.filter((recordId) => {
    const record = records[recordId]
    let hasNotes = false
    if (isFileRecord(record)) return false
    if (isUrlRecord(record)) return false
    if (isNoteableRecord(record)) {
      if (!isEmpty(record.notes)) hasNotes = true
    }
    return (
      (!hasNotes && EditorsManager.get.isEmptyParagraph(recordId)) ||
      EditorsManager.get.isEmptyHeading(recordId)
    )
  })

  const unEmptyRecords = theActiveRecords.filter((recordId) => {
    const record = records[recordId]
    let hasNotes = false
    if (isFileRecord(record)) return true
    if (isUrlRecord(record)) return true
    if (isNoteableRecord(record)) {
      if (!isEmpty(record.notes)) hasNotes = true
    }
    return !(
      (!hasNotes && EditorsManager.get.isEmptyParagraph(recordId)) ||
      EditorsManager.get.isEmptyHeading(recordId)
    )
  })

  const result =
    !isEmpty(emptyRecords) &&
    deleteRecordsCommand(emptyRecords, visibleItemIds, selection, deleteRecords)

  const softDeleteResult =
    !isEmpty(unEmptyRecords) &&
    softDeleteRecordsCommand(
      visibleItemIds,
      selection,
      softDeleteRecords,
      unEmptyRecords,
    )

  return {
    softDeletedIds: softDeleteResult ? unEmptyRecords : [],
    hardDeletedIds: result ? emptyRecords : [],
  }
}
