import { PasswordStrengthCheckResponse } from '@stytch/vanilla-js'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import 'react-phone-number-input/style.css'
import HidePasswordIcon from '../../assets/icon/icon-hide-password.svg'
import ShowPasswordIcon from '../../assets/icon/icon-show-password.svg'
import { safeNavigate } from '../../common/navigate'
import { Icon } from '../../common/stationary/components'
import { spinAnimationStyles } from '../../common/stationary/mixinStyles'
import { cx, styled } from '../../common/stationary/styled'
import { isMobile } from '../../common/utils/env'
import { useAuth } from '../../providers'
import { AuthStyles } from '../LoginScreen/AuthStyles'
import PasswordStrengthAndHintDisplay from '../ResetPasswordScreen/components/PasswordStrengthAndHint'
import { RegistrationScreenState } from './RegistrationScreen'
import GoogleLogo from '../../assets/google.svg'

export interface RegistrationFormProps {
  className?: string
  errorMsg?: string
  screenState?: RegistrationScreenState
  onSubmit?: (email: string, fullName: string, password: string) => void
  setName: (name: string) => void
  tokenEmail: string
  resetErrors: () => void
  inviteCode: string | null
  registrationToken: string | null
}

const emailInputId = 'email'

/**
 * RegistrationForm
 */

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  className,
  errorMsg,
  screenState,
  onSubmit = () => undefined,
  setName,
  tokenEmail,
  resetErrors,
  inviteCode,
  registrationToken,
}) => {
  const [validationError, setValidationError] = useState<Array<string>>([])

  const [password, setPassword] = useState('')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState(tokenEmail)
  const [isEmailError, setIsEmailError] = useState(false)
  const [isChangingEmail, setIsChangingEmail] = useState(false)
  const emailInputRef = useRef<HTMLInputElement | null>(null)

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  function isValidEmail(email: string): boolean {
    return emailRegex.test(email)
  }

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    if (!isValidEmail(email)) {
      setIsEmailError(true)
      return
    }
    onSubmit(email, fullName, password)
  }

  useEffect(() => {
    if (screenState === RegistrationScreenState.SUBMIT_SUCCESS)
      safeNavigate('/login') // @todo Present confirmation screen
  }, [screenState])

  const { startGoogleOAuthRegistration, strengthCheck } = useAuth()

  const [passwordStrengthCheckResponse, setPasswordStrengthCheckResponse] =
    useState<PasswordStrengthCheckResponse>()
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const isPasswordValid = passwordStrengthCheckResponse?.valid_password

  useEffect(() => {
    const errors = []
    if ((isChangingEmail && isEmpty(email)) || isEmailError)
      errors.push('email')
    if (!isEmpty(password) && !isPasswordValid) errors.push('password')
    if (isEmpty(fullName)) errors.push('fullName')
    setValidationError(errors)
  }, [email, fullName, password, isPasswordValid, isEmailError])

  const checkPasswordStrength = async (passwordToCheck: string) => {
    const response = await strengthCheck(passwordToCheck)
    setPasswordStrengthCheckResponse(response)
  }

  const getShowHideIcon = () => {
    return showPassword ? (
      <S.HidePasswordIcon onClick={handleTogglePassword} />
    ) : (
      <S.ShowPasswordIcon onClick={handleTogglePassword} />
    )
  }

  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEmail = e.target.value
    setIsEmailError(false)
    setEmail(updatedEmail)
  }

  useEffect(() => {
    if (isChangingEmail) {
      emailInputRef?.current?.focus()
    }
  }, [isChangingEmail])

  return (
    <S.RegistrationForm className={cx(className)} onSubmit={handleSubmit}>
      <S.FormInner>
        <S.FormHeader>
          <S.FormTitle>Create your account</S.FormTitle>
        </S.FormHeader>
        <S.Fieldset>
          <S.FieldLabel>Sign up with...</S.FieldLabel>
          <S.SignupWithGoogle
            onClick={() =>
              startGoogleOAuthRegistration({
                registration_token: registrationToken ?? undefined,
                invite_code: inviteCode ?? undefined,
              })
            }
          >
            <S.StyledGoogleLogo />
            <div>Google</div>
          </S.SignupWithGoogle>
        </S.Fieldset>
        <S.DividerOr>
          <S.Divider></S.Divider>
          <S.OR>OR</S.OR>
        </S.DividerOr>
        <S.Fieldset
          disabled={screenState === RegistrationScreenState.SUBMIT_LOADING}
        >
          <S.FormField
            className={cx({ isVisible: isChangingEmail || !!inviteCode })}
            key="email"
          >
            <S.FieldLabel htmlFor="email">Email</S.FieldLabel>
            <S.TextInputContainer>
              <S.Input
                className={cx({
                  hasError: isEmailError,
                })}
                type="email"
                id={emailInputId}
                onChange={(e) => {
                  resetErrors()
                  updateEmail(e)
                }}
                placeholder="Email"
                value={email}
                ref={emailInputRef}
              />
            </S.TextInputContainer>
          </S.FormField>
          <S.FormField className={cx({ isVisible: true })} key="fullName">
            <S.FieldLabel htmlFor="fullName">
              What should we call you?
            </S.FieldLabel>
            <S.TextInputContainer>
              <S.Input
                autoFocus={!isChangingEmail && !inviteCode}
                type="text"
                id="text"
                onChange={(e) => {
                  resetErrors()
                  setFullName(e.target.value)
                  setName(e.target.value)
                }}
                placeholder="First and last name"
                value={fullName}
              />
            </S.TextInputContainer>
          </S.FormField>

          <S.FormField className={cx({ isVisible: true })} key="password">
            <S.FieldLabel htmlFor="password">
              Password <S.OptionalLabel>Optional</S.OptionalLabel>
            </S.FieldLabel>
            <S.TextInputContainer>
              <AuthStyles.InputWithIcon>
                <S.Input
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => {
                    resetErrors()
                    checkPasswordStrength(event.target.value)
                    setPassword(event.target.value)
                  }}
                  value={password}
                  placeholder={'Password'}
                />
                <AuthStyles.InlineIconPositioning>
                  {getShowHideIcon()}
                </AuthStyles.InlineIconPositioning>
              </AuthStyles.InputWithIcon>
              <S.Margin>
                <PasswordStrengthAndHintDisplay
                  passwordStrengthCheckResponse={passwordStrengthCheckResponse}
                />
              </S.Margin>
            </S.TextInputContainer>
          </S.FormField>
          <S.ErrorMsg
            className={cx({
              isVisible:
                screenState === RegistrationScreenState.SUBMIT_ERROR ||
                !!errorMsg,
            })}
          >
            {errorMsg}
          </S.ErrorMsg>

          <S.SignUpButton type="submit" disabled={validationError.length > 0}>
            Continue{' '}
            {screenState === RegistrationScreenState.SUBMIT_LOADING && (
              <S.LoadingIcon boxSize={16} variant="glyphCircleThreeQuarters" />
            )}
          </S.SignUpButton>
          {!isChangingEmail && !inviteCode && (
            <S.ChangeEmailContainer>
              🔐 Signing up with<strong>{email}</strong>
              <S.ChangeEmailLink onClick={() => setIsChangingEmail(true)}>
                Change
              </S.ChangeEmailLink>
            </S.ChangeEmailContainer>
          )}
        </S.Fieldset>
      </S.FormInner>
    </S.RegistrationForm>
  )
}

/**
 * Styled
 */

const S = {
  RegistrationForm: styled.form(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: 400,
    overflow: 'visible',
  })),
  FormInner: styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  })),

  FormHeader: styled.div(({ theme }) => ({
    padding: isMobile ? 32 : '32px 0',
  })),
  FormTitle: styled.h1(({ theme }) => ({
    marginBottom: 8,
    ...(isMobile
      ? theme.text.publicSans['27.5:36']
      : theme.text.publicSans['33:40']),
    fontWeight: 900,
    display: isMobile ? 'flex' : 'inherit',
    justifyContent: isMobile ? 'center' : 'inherit',
  })),
  FormSubtitle: styled.div(({ theme }) => ({
    color: theme.colors.text[800],
  })),

  Fieldset: styled.fieldset(() => ({
    ':disabled': {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    width: isMobile ? '100vw' : '',
    maxWidth: 368,
  })),
  FormField: styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,

    ':not(.isVisible)': {
      display: 'none',
    },
  })),
  FieldLabel: styled.label(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    margin: isMobile ? '0 24px 4px' : '0 0px 4px',
    fontWeight: 700,
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.colors.text[700],
    maxWidth: 368,
  })),
  OptionalLabel: styled.label(({ theme }) => ({
    fontWeight: 700,
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.colors.text[500],
  })),
  FieldDescription: styled.div(({ theme }) => ({
    margin: isMobile ? '0 24px 16px' : '0 0px 16px',
    ...theme.text.publicSans['13.5:20'],

    strong: {
      fontWeight: 700,
    },
    'span.italic': {
      fontStyle: 'italic',
    },
  })),
  TextInputContainer: styled.div(() => ({
    margin: isMobile ? '0 24px' : '0px',
    width: isMobile ? 'unset' : 368,
  })),

  FieldCallout: styled.div(({ theme }) => ({
    boxSizing: 'border-box',
    margin: '16px 16px 0',
    padding: 16,
    border: `1px solid ${theme.colors.yellow[200]}`,
    borderRadius: 6,
    ...theme.text.publicSans['13.5:20'],
    background: theme.colors.yellow[100],

    ':not(.isVisible)': {
      display: 'none',
    },

    strong: {
      fontWeight: 700,
    },
    'span.italic': {
      fontStyle: 'italic',
    },
  })),

  ErrorMsg: styled.div(({ theme }) => ({
    boxSizing: 'border-box',
    margin: isMobile ? '8px 24px 8px 24px' : '8px 0px 8px 0px',
    padding: 16,
    border: `1px solid ${theme.colors.red[200]}`,
    borderRadius: 6,
    ...theme.text.publicSans['13.5:20'],
    overflowWrap: 'break-word',
    color: theme.colors.red[900],
    background: theme.colors.red[100],
    maxWidth: 368,

    ':not(.isVisible)': {
      display: 'none',
    },

    strong: {
      fontWeight: 700,
    },
    'span.italic': {
      fontStyle: 'italic',
    },
  })),

  SignUpButton: styled.button(({ theme }) => ({
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: 40,
    margin: isMobile ? '0 24px' : '0',
    width: isMobile ? `calc(100% - 48px)` : '',
    maxWidth: 368,
    justifyContent: isMobile ? 'center' : '',
    padding: '0 16px',
    borderRadius: 6,
    boxShadow: theme.effects.boxShadow.elevation[1],
    fontWeight: 700,
    background: theme.colors.text[900],
    color: theme.colors.alpha.invertedTone.solid,
    cursor: 'pointer',

    ':disabled': {
      opacity: 0.6,
    },
  })),
  LoadingIcon: styled(Icon)(() => ({
    ...spinAnimationStyles({ animationDuration: '2s' }),
  })),
  ShowPasswordIcon: styled(ShowPasswordIcon)(() => ({
    height: 16,
    cursor: 'pointer',
  })),
  HidePasswordIcon: styled(HidePasswordIcon)(() => ({
    height: 16,
    cursor: 'pointer',
  })),
  Input: styled(AuthStyles.AuthPassword)(({ theme }) => ({
    marginBottom: 0,
    '&.hasError': {
      borderWidth: 1,
      borderColor: theme.colors.red[900],
    },
  })),
  Margin: styled.div(() => ({
    marginTop: 8,
    marginBottom: -8,
  })),
  ChangeEmailContainer: styled.div(() => ({
    marginTop: 24,
    justifyContent: 'center',
    display: 'flex',
    gap: 8,
    flex: 0,
    strong: {
      marginLeft: -4,
      fontWeight: 600,
      maxWidth: isMobile ? 130 : 150,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })),
  ChangeEmailLink: styled.div(() => ({
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  })),
  SignupWithGoogle: styled.div(({ theme }) => ({
    alignItems: 'center',
    background: theme.colors.surface.base,
    border: `1px solid ${theme.colors.alpha.border.medium}`,
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'inline-flex',
    flexShrink: '0',
    fontWeight: 600,
    gap: '8px',
    height: '40px',
    justifyContent: 'center',
    marginBottom: '32px',
    marginTop: '4px',
    padding: '0px 20px',
    ...theme.text.publicSans['16:24'],
  })),
  StyledGoogleLogo: styled(GoogleLogo)(() => ({
    width: '20px',
    height: '20px',
  })),
  Divider: styled.div(({ theme }) => ({
    border: `1px solid ${theme.colors.alpha.border.medium}`,
    height: '1px',
    width: '100%',
  })),
  OR: styled.div(({ theme }) => ({
    backgroundColor: 'white',
    fontColor: theme.colors.text[700],
    fontWeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-9px',
    padding: '0 8px',
    position: 'relative',
    width: 'fit-content',
    ...theme.text.publicSans['11.5:16'],
  })),
  DividerOr: styled.div(() => ({
    marginBottom: '32px',
  })),
}
