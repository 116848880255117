import { isNoteableRecord, RecordDict } from '@eleventhlabs/capture-shared'
import { first, isEmpty } from 'lodash'

import { EditorsManager } from '../../../../EditorsManager'

export const recordBackspaceWhenEmpty = (
  records: RecordDict,
  recordId: string,
  recordIds: string[],
  deleteRecordsById: (recordIds: string[]) => void,
  softDeleteRecords: (recordIds: string[]) => void,
  setFocusedRecordId: (recordId: string | undefined) => void,
): { softDeleted: boolean; hardDeleted: boolean } => {
  const record = records[recordId]
  if (isNoteableRecord(record) && !isEmpty(record.notes)) {
    softDeleteRecords([recordId])
    return { hardDeleted: false, softDeleted: true }
  } else {
    deleteRecordsById([recordId])
    setFocusedRecordId(undefined)
    const isFirstRecord = first(recordIds) === recordId
    if (!isFirstRecord) {
      const prevRecordId = recordIds[recordIds.indexOf(recordId) - 1]
      EditorsManager.effects.focus({ id: prevRecordId, position: `end` })
    }
    return { hardDeleted: true, softDeleted: false }
  }
}
