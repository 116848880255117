import React, { useMemo } from 'react'
import { FixedSizeList } from 'react-window'

import { useScrollCommands } from '../../../hooks/scroll/useScrollCommands'
import { useScrollable } from '../../../hooks/scroll/useScrollable'
import {
  VariableVirtualListAutosized,
  VirtualListAutosized,
} from '../../../virtualized'
import { ResultGroup } from '../CommandBar.types'

type ListProps = React.ComponentProps<typeof VirtualListAutosized>

interface ListPropsWithData extends ListProps {
  itemData: ResultGroup
}

type VariableListProps = React.ComponentProps<
  typeof VariableVirtualListAutosized
>

interface VariableListPropsWithData extends VariableListProps {
  itemData: ResultGroup
}

interface WithData {
  itemData: ResultGroup
}

export function withScrollerRef<T extends ListPropsWithData>(
  WrappedComponent: React.FC<T>,
): React.FC<T>
export function withScrollerRef<T extends VariableListPropsWithData>(
  WrappedComponent: React.FC<T>,
): React.FC<T>
export function withScrollerRef<T extends WithData>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const WithScrollerRef: React.FC<T> = (props: T) => {
    const items = props.itemData.items
    const ids = useMemo(() => items.map((item) => item.id), [items])
    const { scrollRef, scrollCommands } = useScrollCommands<FixedSizeList>(ids)

    useScrollable(`command-bar`, scrollCommands)

    return <WrappedComponent ref={scrollRef} {...props} />
  }

  WithScrollerRef.displayName = `WithScollerRef`
  return WithScrollerRef
}
