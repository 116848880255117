import { FileMetadata } from '@eleventhlabs/capture-shared'

export const getFileMetadata = async (
  file: File,
  fileName: string,
  bucket?: string,
) => {
  const metadata: FileMetadata = {
    name: file?.name,
    size: file?.size,
    mimeType: file?.type,
    lastModified: file?.lastModified,
  }

  if (bucket) {
    metadata.url = `${bucket}${fileName}`
  }

  return metadata
}
