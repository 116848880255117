import { useCallback, useMemo } from 'react'

import Analytics from '../../analytics/capture-analytics-actions'
import { useExecContext } from '../../contexts/ExecContext'
import { EditorsManager } from '../../EditorsManager'
import { BaseSelection } from '../../libs/selection'
import { MiniBarEventHandlers } from './types'

export const useMiniBarEventHandlers = (
  selection: BaseSelection,
): MiniBarEventHandlers => {
  const { exec } = useExecContext()

  const completeRecords = useCallback(
    (recordId: string) => {
      exec({
        type: 'RECORDS_TOGGLE_IS_COMPLETED',
        trigger: Analytics.ToggleRecordsCompletedTrigger.RECORD_MINI_BAR,
        targetId: recordId,
      })
    },
    [exec],
  )

  const moveRecords = useCallback(
    (recordId: string) => {
      exec({
        type: 'OPEN_MOVE_TO_DIALOG',
        trigger: Analytics.MoveToPickerFlowStartedTrigger.RECORD_MINI_BAR,
        recordId: recordId,
        shouldHideSelectionActionBar: !selection.shouldShowSelectionActionBar,
      })
    },
    [selection.ids, selection.shouldShowSelectionActionBar, exec],
  )

  const scheduleRecords = useCallback(
    (recordId: string) => {
      exec({
        type: 'OPEN_SCHEDULE_DIALOG',
        trigger: Analytics.DatePickerFlowStartedTrigger.RECORD_MINI_BAR,
        recordId: recordId,
        shouldHideSelectionActionBar: !selection.shouldShowSelectionActionBar,
      })
    },
    [selection.ids, selection.shouldShowSelectionActionBar, exec],
  )

  const addNoteToRecord = useCallback(
    (recordId: string) => {
      exec({
        type: 'ADD_ANNOTATION',
        showAnnotationsTrigger:
          Analytics.ShowAnnotationsToggleTrigger.RECORDITEM_BUTTON,
        createAnnotationTrigger:
          Analytics.AnnotationCreatedTrigger.RECORD_MINI_BAR,
        recordId: recordId,
      })
    },
    [selection.ids, exec],
  )

  const hideNote = useCallback(
    (recordId: string) => {
      exec({
        type: 'TOGGLE_ANNOTATIONS',
        recordId,
        showAnnotationsTrigger:
          Analytics.ShowAnnotationsToggleTrigger.RECORDITEM_BUTTON,
        createAnnotationTrigger:
          Analytics.AnnotationCreatedTrigger.RECORD_ITEM_BUTTON,
      })
    },
    [exec],
  )

  const moveRecordsToTrash = useCallback(
    (recordId: string) => {
      exec({
        type: 'MOVE_RECORDS_TO_TRASH',
        trigger: `Record Mini Bar`,
        triggeredByKeyboard: false,
        triggeredByUI: true,
        recordId: recordId,
      })
    },
    [selection.ids, exec],
  )

  const restoreRecords = useCallback(
    (recordId: string) => {
      exec({
        type: 'RESTORE_RECORDS',
        trigger: Analytics.RecordsRestoredTrigger.RECORD_MINI_BAR,
        triggeredByUI: true,
        triggeredByKeyboard: false,
        recordId: recordId,
      })
    },
    [selection.ids, exec],
  )

  const collapseRecord = useCallback(
    (recordId: string) => {
      exec({
        type: 'RECORD_ITEMS_COLLAPSE',
        targetId: recordId,
        trigger:
          Analytics.RecordCollapsedTrigger.UI_CLICK_MINI_ACTION_BAR_BUTTON,
      })
    },
    [exec],
  )

  const expandRecord = useCallback(
    (recordId: string) => {
      exec({
        type: 'RECORD_ITEMS_EXPAND',
        targetId: recordId,
        trigger:
          Analytics.RecordExpandedTrigger.UI_CLICK_MINI_ACTION_BAR_BUTTON,
      })
    },
    [exec],
  )

  const expandToFullscreen = useCallback(
    (recordId: string) => {
      exec({
        type: 'EXPAND_TO_FULLSCREEN',
        recordId,
        trigger: Analytics.RecordOpenedTrigger.MINI_ACTION_BAR,
        triggeredByKeyboard: false,
        triggeredByUI: true,
      })
    },
    [exec],
  )

  const blurRecord = useCallback((recordId: string) => {
    EditorsManager.effects.blur(recordId)
  }, [])

  const handlers = useMemo(
    () => ({
      completeRecords,
      moveRecords,
      scheduleRecords,
      addNoteToRecord,
      moveRecordsToTrash,
      restoreRecords,
      collapseRecord,
      expandRecord,
      expandToFullscreen,
      blurRecord,
      hideNote,
    }),
    [
      completeRecords,
      moveRecords,
      scheduleRecords,
      addNoteToRecord,
      moveRecordsToTrash,
      restoreRecords,
      collapseRecord,
      expandRecord,
      expandToFullscreen,
      blurRecord,
      hideNote,
    ],
  )

  return handlers
}
