import React, { useCallback, useState } from 'react'

import {
  Icon,
  cx,
  styled,
  thumbnailContentClassName,
  thumbnailContentOverlayStyles,
  thumbnailContentStyles,
} from '../../../../../common/stationary'

interface FileThumbnailProps {
  thumbnail?: string
  loading?: boolean
  isMovie?: boolean
  progress: number
  notesOpen: boolean
  isExpanded?: boolean
}

const LOADING_BAR_WIDTH = 48

export const FileThumbnail: React.FC<FileThumbnailProps> = ({
  thumbnail,
  loading,
  isMovie,
  progress,
  notesOpen,
  isExpanded,
}) => {
  const [failed, setFailed] = useState(false)

  const onError = useCallback(() => setFailed(true), [])

  return thumbnail && !failed ? (
    <S.Thumbnail
      className={cx(`thumbnail`, thumbnailContentClassName, {
        notesOpen,
        isExpanded,
      })}
    >
      <S.Image
        src={thumbnail}
        className={cx({ isExpanded })}
        onError={onError}
      />
      {isMovie ? <S.PlayIcon /> : null}
    </S.Thumbnail>
  ) : (
    <S.Empty
      className={cx({
        placeholder: !loading,
      })}
    >
      {loading ? (
        <S.Loading>
          <S.LoadingBar
            style={{ width: (LOADING_BAR_WIDTH / 100) * progress }}
          />
        </S.Loading>
      ) : isMovie ? (
        <S.PlayIcon />
      ) : (
        <S.FileIcon boxSize={16} variant="glyphFileDogEar" />
      )}
    </S.Empty>
  )
}

const PlayIcon = (props: { className?: string }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <circle opacity="0.7" cx="18" cy="18" r="18" fill="black" />
    <path d="M14 25V11L24 18L14 25Z" fill="white" />
  </svg>
)

const S = {
  Thumbnail: styled.div({
    ...thumbnailContentStyles,
    display: `flex`,
    flex: 1,
    height: `100%`,
    borderTopRightRadius: `8px`, // hack: to perfectly match with parent 8 (9px)
    borderBottomRightRadius: `8px`, // hack: to perfectly match with parent 8 (9px)
    alignItems: `center`,
    justifyContent: `center`,
    position: `relative`,
    overflow: `hidden`,

    '&::before': { ...thumbnailContentOverlayStyles },

    '&.notesOpen': {
      borderBottomRightRadius: 0,
    },

    '&.isExpanded': {
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 8,
    },
  }),
  Loading: styled.div(({ theme }) => ({
    boxSizing: `border-box`,
    border: `1px solid ${theme.colors.gray[700]}`,
    width: `${LOADING_BAR_WIDTH}px`,
    height: `4px`,
    pointerEvents: `none`,
    borderRadius: 999,
  })),
  LoadingBar: styled.div(({ theme }) => ({
    background: theme.colors.gray[700],
    width: 0,
    height: `2px`,
    pointerEvents: `none`,
    animationDuration: `300ms`,
    animationTimingFunction: `ease-in-out`,
    borderRadius: 999,
  })),
  Image: styled.img(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    height: `100%`,
    objectFit: 'scale-down',
    background: 'black',
  })),
  PlayIcon: styled(PlayIcon)({
    position: `absolute`,
    top: `calc(50% - 18px)`,
    left: `calc(50% - 18px)`,

    '&.empty': {
      opacity: 0.2,
    },
  }),
  FileIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.text[700],
    pointerEvents: `none`,
  })),
  Empty: styled.div(({ theme }) => ({
    display: `flex`,
    flex: 1,
    height: `100%`,
    background: theme.colors.alpha.tone.weak,
    borderLeft: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    boxSizing: `border-box`,
    alignItems: `center`,
    justifyContent: `center`,
    position: `relative`,
  })),
}
