import React, { useCallback, useRef } from 'react'

import { useHotkeysThreshold } from '../../common/hooks/useHotkeysThreshold'
import { Priorities } from '../../common/hooks/useKeys'
import QuickSwitcher from '../QuickSwitcher/QuickSwitcher'
import { useQuickSwitcher } from '../QuickSwitcher/hooks/useQuickSwitcher'
import { styled } from '../../common/stationary'
import { Modal } from '../../common/dialogs'

interface QuickSwitcherDialogProps {
  isOpen?: boolean
  onDismiss: (e?: React.SyntheticEvent) => void
}

export const QuickSwitcherDialog: React.FC<QuickSwitcherDialogProps> = ({
  isOpen,
  onDismiss,
}) => {
  const QuickSwitcherInputRef = useRef<HTMLInputElement>(null)

  const close = useCallback(() => onDismiss && onDismiss(), [onDismiss])

  const { query, groupData, handlers } = useQuickSwitcher(close)

  useHotkeysThreshold(Priorities.Dialog)

  return (
    <S.QuickSwitcherDialog
      aria-label="QuickSwitcherDialog"
      open={isOpen ?? false}
      // initialFocusRef={QuickSwitcherInputRef} // we use @reach Dialog underneath and if we let it autofocus it will focus on the input and expand the datepicker
      onClose={close}
    >
      <S.Inner>
        <QuickSwitcher
          query={query}
          inputRef={QuickSwitcherInputRef}
          groupData={groupData}
          handlers={handlers}
        />
      </S.Inner>
    </S.QuickSwitcherDialog>
  )
}

export default QuickSwitcherDialog

const S = {
  QuickSwitcherDialog: styled(Modal)({}),
  Inner: styled.div({
    width: 384,
    maxWidth: '100%',
    marginTop: 96,
  }),
}
