import Analytics, {
  MoveTypeValueType,
} from '../../../../analytics/capture-analytics-actions'
import { ModelStoreCommands } from '../../../../data/modelStore'
import { RecordGroupsCommands } from '../../../../data/recordGroups'
import { Ordered } from '../../../../libs/selection'
import { getItemsSortedByOrder } from '../../../../libs/selection/ordered'
import { DataRecord, ReferenceInsertPoint } from '@eleventhlabs/capture-shared'

export interface MoveRecordsResult {
  success: boolean
  moveType: MoveTypeValueType
}

export interface MoveRecordsPayload {
  recordIds: string[]
  parentRecordId: string
  insertPoint?: ReferenceInsertPoint
  groupId?: string
}

//
// This function currently supports moving records through drag and drop.
// TODO: make it generic so we can support moving records through various means
//
// - Supports moving to a ReferenceInsertPoint (can add support for other insert points as needed)
// - Moving to the start of a group (can add support for moving to end of group as needed)
// - Moving into parent
//
export const moveRecords = (
  recordGroupsCommands: RecordGroupsCommands,
  recordGroupsOrdering: Ordered,
  commands: ModelStoreCommands,
  payload: MoveRecordsPayload,
  draggedRecordIds?: string[],
  hoverRecord?: DataRecord,
): MoveRecordsResult => {
  // If the record we're hovering over is in the drag group, we're not actually moving anywhere
  if (draggedRecordIds && draggedRecordIds.includes(hoverRecord?.id ?? '')) {
    return {
      success: false,
      // Just returning something here to avoid dealing with typescript
      moveType: Analytics.MoveType.REORDER,
    }
  }

  const orderedRecordIds = getItemsSortedByOrder(
    recordGroupsOrdering,
    payload.recordIds,
  )

  const insertPoint = payload.insertPoint
  if (insertPoint) {
    if (insertPoint.at == `before`) {
      recordGroupsCommands.moveAboveAnchor(
        orderedRecordIds,
        insertPoint.anchorId,
      )
    } else {
      recordGroupsCommands.moveBelowAnchor(
        orderedRecordIds,
        insertPoint.anchorId,
      )
    }

    return {
      success: true,
      moveType: Analytics.MoveType.REORDER,
    }
  }

  const groupId = payload.groupId
  if (groupId) {
    recordGroupsCommands.moveToStartOfGroup(orderedRecordIds, groupId)
    return {
      success: true,
      moveType: Analytics.MoveType.REORDER,
    }
  }

  const parentRecordId = payload.parentRecordId

  // Reparent the record by moving it to the start of a parent
  // For dates, this could also move the record to the first group in the date
  commands.moveRecords(orderedRecordIds, {
    id: parentRecordId,
    point: { at: `start` },
  })

  return {
    success: true,
    moveType: Analytics.MoveType.REPARENT,
  }
}
