import React, { useState } from 'react'

import { cx, styled } from '../../../common/stationary'

import { AuthStyles } from '../AuthStyles'
import {
  generateCodeChallenge,
  generateCodeVerifier,
} from '../../../common/utils/auth/pkce'
import { useDesktopAuth } from '../../../common/contexts/DesktopAuthContext'
import AuthLogo from './AuthLogo'
import { isMobile } from '../../../common/utils/env'
import { isEmpty } from 'lodash'
import { Env } from '../../../common/env'

interface LoginWithBrowserProps {}

// WARNING: This component is only meant to be rendered in the toDesktop app
const LoginWithBrowser: React.FC<LoginWithBrowserProps> = ({}) => {
  const { setStoredCodeVerifier, errorMessage } = useDesktopAuth()

  const [shouldGoToBrowser, setShouldGoToBrowser] = useState(false)

  // This component should only be loading in desktop
  // @ts-ignore
  const openInBrowser = window?.todesktop?.contents?.openUrlInBrowser

  const redirectToBrowser = () => {
    const codeVerifier = generateCodeVerifier()
    const codeChallenge = generateCodeChallenge(codeVerifier)

    setStoredCodeVerifier(codeVerifier)

    if (openInBrowser) {
      // LOCAL DEV: Change to localhost:8000
      openInBrowser(
        `${Env.captureWebUrl}/login?code_challenge=${codeChallenge}&isDesktopAuth=true`,
      )
    }

    setShouldGoToBrowser(true)
  }

  return (
    <>
      <AuthStyles.AuthHeader>
        <AuthLogo href={!isMobile ? '/' : undefined} mode="default" />
        <AuthStyles.AuthTitle>
          <strong>Log in to Capture</strong>
        </AuthStyles.AuthTitle>
      </AuthStyles.AuthHeader>
      <S.LoginWithBrowser>
        {shouldGoToBrowser ? (
          <>
            <>Go to browser to finish logging in</>
            <S.Problems>
              Something went wrong?{' '}
              <strong onClick={() => setShouldGoToBrowser(false)}>
                Go back and try again.
              </strong>
            </S.Problems>
          </>
        ) : (
          <AuthStyles.AuthSubmit onClick={redirectToBrowser}>
            Log in with browser
          </AuthStyles.AuthSubmit>
        )}
        {!isEmpty(errorMessage) && (
          <S.AuthMessage
            className={cx({
              isError: !isEmpty(errorMessage),
            })}
          >
            {' '}
            <>
              {errorMessage} If the issue persists,{' '}
              <a href="mailto:membership@capture.so">contact support</a>
            </>
          </S.AuthMessage>
        )}
      </S.LoginWithBrowser>
    </>
  )
}

export default LoginWithBrowser

const S = {
  LoginWithBrowser: styled.div(() => ({
    marginTop: 40,
  })),
  Problems: styled.div(() => ({
    marginTop: 10,
    fontSize: 12,

    strong: {
      textDecoration: 'underline',
      fontWeight: 700,
      cursor: 'pointer',
      marginLeft: 8,
    },
  })),
  AuthMessage: styled(AuthStyles.AuthMessage)(({ theme }) => ({
    border: `1px solid ${theme.colors.red[300]}`,
    maxWidth: 368,
  })),
}
