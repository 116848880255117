import {
  addDays,
  format,
  formatISO,
  getWeekOfMonth,
  isBefore,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThisYear,
  isThursday,
  isToday,
  isTomorrow,
  isTuesday,
  isValid,
  isWednesday,
  isWithinInterval,
  isYesterday,
  parseISO,
  startOfToday,
  startOfYesterday,
} from 'date-fns'
import { capitalize, flow, isNumber } from 'lodash'
import Sugar from 'sugar'

import {
  DayOfWeek,
  DayOfWeekInMonth,
  DayOfWeekInYear,
  MonthOfYear,
  RecurrenceFrequency,
  RecurrenceProp,
} from '@eleventhlabs/capture-shared'

export const tokenFormatISO = `yyyy-MM-dd`

export const isInNextFourteenDaysExclusive = (date: Date): boolean => {
  const today = startOfToday()
  const thirteenDaysLater = addDays(today, 13)
  return isWithinInterval(date, { start: today, end: thirteenDaysLater })
}

export const isInNextSevenDaysExclusive = (date: Date): boolean => {
  const today = startOfToday()
  const sixDaysLater = addDays(today, 6)
  return isWithinInterval(date, { start: today, end: sixDaysLater })
}

const todayHeaderFormat = (date: Date): string =>
  `Today ・ ${format(date, `EEE MMM d`)}`
const tomorrowHeaderFormat = (date: Date): string =>
  `Tomorrow ・ ${format(date, `EEE MMM d`)}`
const thisWeekHeaderFormat = (date: Date): string =>
  format(date, `EEE ・ MMM d`)
const thisYearHeaderFormat = (date: Date): string => format(date, `MMM d`)
const notThisYearHeaderFormat = (date: Date): string =>
  format(date, `MMM d, yyyy`)

export const dateToDateHeaderText = (date: Date): string => {
  if (isToday(date)) return todayHeaderFormat(date)
  else if (isTomorrow(date)) return tomorrowHeaderFormat(date)
  else if (isInNextSevenDaysExclusive(date)) return thisWeekHeaderFormat(date)
  else if (isThisYear(date)) return thisYearHeaderFormat(date)
  else return notThisYearHeaderFormat(date)
}

const todayTagFormat = (date: Date): string => `Today`
const tomorrowTagFormat = (date: Date): string => `Tomorrow`
const thisWeekTagFormat = (date: Date): string => format(date, `EEEE`)
const thisYearTagFormat = (date: Date): string => format(date, `MMM d`)
const notThisYearTagFormat = (date: Date): string => format(date, `MMM d, yyyy`)

export const dateToDateTagText = (date: Date): string => {
  if (isToday(date)) return todayTagFormat(date)
  else if (isTomorrow(date)) return tomorrowTagFormat(date)
  else if (isInNextSevenDaysExclusive(date)) return thisWeekTagFormat(date)
  else if (isThisYear(date)) return thisYearTagFormat(date)
  else return notThisYearTagFormat(date)
}

export const todayPageDateToTitle = (date: Date): string => {
  if (isToday(date)) return todayTagFormat(date)
  else if (isTomorrow(date)) return tomorrowTagFormat(date)
  else if (isThisYear(date)) return thisWeekTagFormat(date)
  else return notThisYearTagFormat(date)
}

export const dateToInputText = (date: Date): string => {
  if (isToday(date)) return todayTagFormat(date)
  else if (isTomorrow(date)) return tomorrowTagFormat(date)
  else return notThisYearTagFormat(date)
}

export const dateToTitle = dateToDateTagText
export const dateToShortTitle = (date: Date): string =>
  format(date, `EEE MMM d`)

export const dateStringToDateTagText = flow(parseISO, dateToDateTagText)
export const dateStringToDateHeaderText = flow(parseISO, dateToDateHeaderText)
export const dateStringToDateGroupTitle = flow(parseISO, dateToTitle)
export const dateStringToDateGroupSubTitle = flow(parseISO, dateToShortTitle)
export const dateStringToInputText = flow(parseISO, dateToInputText)

export const dateToUTC = (date: Date): Date => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export const startOfTodayISO = (): string =>
  formatISO(startOfToday(), { representation: `date` })

export const startOfYesterdayISO = (): string =>
  formatISO(startOfYesterday(), { representation: `date` })

export const isBeforeToday = (str: string): boolean =>
  isBefore(parseISO(str), startOfToday())

interface ParsedInputDate {
  isoDate?: Date
  recurrence?: RecurrenceProp
  isoLocalTime?: string
  reminder?: {
    minutesBefore: number
  }
}

function createDailyRecurrence(): RecurrenceProp {
  return {
    frequency: RecurrenceFrequency.Daily,
    interval: 1,
    byDayOfWeek: [],
  }
}

function createWeeklyRecurrence(dayOfWeek: DayOfWeek[]): RecurrenceProp {
  return {
    frequency: RecurrenceFrequency.Weekly,
    interval: 1,
    byDayOfWeek: dayOfWeek,
  }
}

function createMonthlyRecurrence(
  dayOfWeek: DayOfWeek,
  weekOfMonth: number,
): RecurrenceProp {
  return {
    frequency: RecurrenceFrequency.Monthly,
    interval: 1,
    byDayOfWeek: [{ dayOfWeek, weekOfMonth }],
  }
}

function createYearlyRecurrence(
  dayOfMonth: number,
  monthOfYear: MonthOfYear,
): RecurrenceProp {
  return {
    frequency: RecurrenceFrequency.Yearly,
    interval: 1,
    byDayOfWeek: [{ dayOfMonth, monthOfYear }],
  }
}

// remove extra white spaces before checking regex
function normalizeStringForRegex(str: string) {
  return str.trim().toLowerCase().replace(/\s\s/g, ` `)
}

// time
const timeRegex =
  /(at ){0,1}([0-9]|0[0-9]|1[0-2])(:[0-5][0-9]){0,2} *(am|pm){1}/i

// reminders
const reminderRegex =
  /([0-9]+h){0,1}[\sa-zA-Z]*([0-9]+m){0,1}[\sa-zA-Z]*([0-9]+s){0,1}/i

// recurrence
const dailyRegex = /daily|every day/i
const weeklyDefaultRegex = /weekly|every week/i
const weeklyOnRegex =
  /(every |weekly on |every week on ){1}(sunday|monday|tuesday|wednesday|thursday|friday|saturday){1}|(sunday|monday|tuesday|wednesday|thursday|friday|saturday){1}s/i
const monthlyDefaultRegex = /monthly|every month/i
const monthlyOnRegex =
  /monthly on (1st|2nd|3rd|4th) (sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
const annuallyDefaultRegex = /yearly|every year/i
const annuallyOnRegex =
  /annually on (january|february|march|april|may|june|july|august|september|october|november|december) (3[0-1]|2[0-9]|1[0-9]|[0-9]){1}(st|nd|rd|th){0,1}/i

const nlpInputToRecurrenceProp = (
  value: string,
  currentDateStr?: string | null,
): { recurrence: RecurrenceProp | undefined; rest: string } => {
  const currentDate = currentDateStr ? parseISO(currentDateStr) : undefined
  const normalizedValue = normalizeStringForRegex(value)
  let matches
  // daily
  matches = normalizedValue.match(dailyRegex)
  if (matches)
    return {
      recurrence: createDailyRecurrence(),
      rest: normalizedValue.split(matches[0]).join(` `),
    }
  // weekly
  matches = normalizedValue.match(weeklyOnRegex)
  if (matches) {
    let day
    if (isWeekDay(matches[2])) day = matches[2]
    else if (isWeekDay(matches[3])) day = matches[3]
    else return { recurrence: undefined, rest: normalizedValue }
    return {
      recurrence: createWeeklyRecurrence([getDayOfWeekFromString(day)]),
      rest: normalizedValue.split(matches[0]).join(` `),
    }
  }
  matches = normalizedValue.match(weeklyDefaultRegex)
  if (matches)
    return {
      recurrence: createWeeklyRecurrence([
        getDayOfWeekFromDate(currentDate ?? startOfToday()),
      ]),
      rest: normalizedValue.split(matches[0]).join(` `),
    }
  // monthly
  matches = normalizedValue.match(monthlyOnRegex)
  if (matches) {
    let dayOfWeek
    let weekInMonth
    if (isNumber(getWeekInMonthFromText(matches[1])))
      weekInMonth = getWeekInMonthFromText(matches[1])
    if (isWeekDay(matches[2])) dayOfWeek = matches[2]
    if (!dayOfWeek || !isNumber(weekInMonth))
      return { recurrence: undefined, rest: normalizedValue }
    return {
      recurrence: createMonthlyRecurrence(
        getDayOfWeekFromString(dayOfWeek),
        weekInMonth as any,
      ),
      rest: normalizedValue.split(matches[0]).join(` `),
    }
  }
  matches = normalizedValue.match(monthlyDefaultRegex)
  if (matches)
    return {
      recurrence: createMonthlyRecurrence(
        currentDateStr
          ? getDayOfWeekFromString(currentDateStr)
          : getDayOfWeekFromDate(startOfToday()),
        currentDate
          ? getWeekOfMonthFromDate(currentDate)
          : getWeekOfMonthFromDate(startOfToday()),
      ),
      rest: normalizedValue.split(matches[0]).join(` `),
    }
  // annually
  matches = normalizedValue.match(annuallyOnRegex)
  if (matches) {
    let dayInMonth
    const monthInYear = getMonnthOfYearFromString(matches[1])
    if (isNumber(parseInt(matches[2]))) dayInMonth = parseInt(matches[2])
    if (!isNumber(dayInMonth) || !monthInYear)
      return { recurrence: undefined, rest: normalizedValue }
    return {
      recurrence: createYearlyRecurrence(dayInMonth, monthInYear),
      rest: normalizedValue.split(matches[0]).join(` `),
    }
  }
  matches = normalizedValue.match(annuallyDefaultRegex)
  if (matches) {
    const dayInMonth = currentDate
      ? getDayNumber(currentDate)
      : getDayNumber(startOfToday())
    const monthInYear = currentDate
      ? getMonthOfYearFromDate(currentDate)
      : getMonthOfYearFromDate(startOfToday())
    return {
      recurrence: createYearlyRecurrence(parseInt(dayInMonth), monthInYear),
      rest: normalizedValue.split(matches[0]).join(` `),
    }
  }
  // no recurrence
  return {
    recurrence: undefined,
    rest: normalizedValue,
  }
}

export const nlpInputToTimeProp = (value: string) => {
  const normalizedValue = normalizeStringForRegex(value)
  // compute time
  const time = normalizedValue.match(timeRegex)
  if (time) {
    return {
      isoLocalTime: nlpInputToISOTime(time[0]),
      rest: normalizedValue.split(time[0]).join(` `),
    }
  }
  return {
    isoLocalTime: undefined,
    rest: value,
  }
}

export const nlpInputToReminder = (value: string): number | undefined => {
  const normalizedValue = normalizeStringForRegex(value)
  // compute time
  const reminder = normalizedValue.match(reminderRegex)
  if (reminder) {
    const hours = reminder[1] ? parseInt(reminder[1].slice(0, -1)) : 0
    const minutes = reminder[2] ? parseInt(reminder[2].slice(0, -1)) : 0
    const seconds = reminder[3] ? parseInt(reminder[3].slice(0, -1)) : 0
    let totalMinutes = 0
    if (isNumber(hours)) totalMinutes += hours * 60
    if (isNumber(minutes)) totalMinutes += minutes
    if (isNumber(seconds)) totalMinutes += 0 // TODO: ignored because we treat with minutes as an atomic unit
    return totalMinutes
  }
  return undefined
}

export const nlpInputToDateProp = (value: string): ParsedInputDate => {
  // compute time
  const { isoLocalTime, rest: valueWithoutTime } = nlpInputToTimeProp(value)
  // reminder if time exists
  const reminder = isoLocalTime ? { minutesBefore: 0 } : undefined
  // compute recurrence
  const { recurrence, rest: valueWithoutRecurrenceAndTime } =
    nlpInputToRecurrenceProp(valueWithoutTime)
  // run input through sugar
  const nlpDate = Sugar.Date.create(valueWithoutRecurrenceAndTime, {
    future: true,
  })
  const defaultDate = recurrence ? startOfToday() : undefined
  const isoDate = isValid(nlpDate) ? nlpDate : defaultDate
  return {
    isoDate,
    recurrence,
    isoLocalTime,
    reminder,
  }
}

export const nlpInputToDate: typeof Sugar.Date.create = (d, opts) => {
  return Sugar.Date.create(d, { future: true, ...opts })
}

export const getMonthTitle = (date: Date): string => {
  return format(date, `MMMM yyyy`)
}

export const getDayOfWeek = (date: Date): string => {
  return format(date, `EEE`)
}

export const getDayNumber = (date: Date): string => {
  return format(date, `d`)
}

export const getDayTitle = (date: Date): string => {
  if (isToday(date)) return `Today`
  else if (isTomorrow(date)) return `Tomorrow`
  else if (isYesterday(date)) return `Yesterday`
  else if (isThisYear(date)) return format(date, `EEE MMM d`)
  else return format(date, `MMM d, yyyy`)
}

export const getDaySubTitle = (date: Date): string | undefined => {
  if (isToday(date)) return format(date, `EEE MMM d`)
  else if (isTomorrow(date)) return format(date, `EEE MMM d`)
  else if (isYesterday(date)) return format(date, `EEE MMM d`)
  else return format(date, `EEE MMM d`)
}

export function inputToISOTime(value: string) {
  const normalizedValue = value.toLowerCase().replace(/\s/g, ``)
  const isPM = normalizedValue.includes(`pm`)
  const time = isPM
    ? normalizedValue.split(`pm`)[0]
    : normalizedValue.split(`am`)[0]
  const segments = time.split(`:`)
  const hours = parseInt(segments[0])
  const minutes = segments[1] ?? `00`
  const seconds = segments[2] ?? `00`
  return `${hoursNumberToISO(
    isPM ? hours12to24(hours, `pm`) : hours12to24(hours, `am`),
  )}:${minutes}:${seconds}`
}

export function nlpInputToISOTime(value = ``) {
  const normalizedValue = value.toLowerCase().replace(`at `, ``)
  return inputToISOTime(normalizedValue)
}

export function hours12to24(hours: number, mode: `am` | `pm` = `am`) {
  if (mode === `am`) {
    return hours === 12 ? 0 : hours
  }
  if (mode === `pm`) {
    return hours === 24 ? 0 : hours === 12 ? hours : hours + 12
  }
  return hours
}

export function hours24to12(hours: number) {
  if (hours > 12) return hours - 12
  if (hours === 0 || hours === 24) return 12
  return hours
}

export function hoursNumberToISO(hours: number): string {
  return `${hours}`.padStart(2, `0`)
}

export function convertISOtoDisplay(value?: string) {
  if (!value) return ``
  const hours = parseInt(value.split(`:`)[0])
  const minutes = value.split(`:`)[1]
  const isPM = hours >= 12 && hours !== 24
  return `${hours24to12(hours)}:${minutes} ${isPM ? `PM` : `AM`}`
}

export function convertReminderMinutesToDisplay(value?: number) {
  if (!value) return ``
  if (value === 0) return `0m`
  const hours = Math.floor(value / 60)
  const minutes = value % 60
  const hoursStr = hours > 0 ? `${hours}h` : ``
  const minutesStr = minutes > 0 ? ` ${minutes}m` : ``
  return `${hoursStr}${minutesStr}`
}

export function getDayOfWeekFromDate(date: Date) {
  if (isSunday(date)) return DayOfWeek.SU
  if (isMonday(date)) return DayOfWeek.MO
  if (isTuesday(date)) return DayOfWeek.TU
  if (isWednesday(date)) return DayOfWeek.WE
  if (isThursday(date)) return DayOfWeek.TH
  if (isFriday(date)) return DayOfWeek.FR
  if (isSaturday(date)) return DayOfWeek.SA
  return DayOfWeek.SU
}

export function getDayOfWeekFromString(date: string): DayOfWeek {
  if (date.toLowerCase() === `sunday`) return DayOfWeek.SU
  if (date.toLowerCase() === `monday`) return DayOfWeek.MO
  if (date.toLowerCase() === `tuesday`) return DayOfWeek.TU
  if (date.toLowerCase() === `wednesday`) return DayOfWeek.WE
  if (date.toLowerCase() === `thursday`) return DayOfWeek.TH
  if (date.toLowerCase() === `friday`) return DayOfWeek.FR
  if (date.toLowerCase() === `saturday`) return DayOfWeek.SA
  return getDayOfWeekFromDate(startOfToday())
}

export function getMonnthOfYearFromString(month: string): MonthOfYear {
  if (month.toLowerCase() === `january`) return MonthOfYear.January
  if (month.toLowerCase() === `february`) return MonthOfYear.February
  if (month.toLowerCase() === `march`) return MonthOfYear.March
  if (month.toLowerCase() === `april`) return MonthOfYear.April
  if (month.toLowerCase() === `may`) return MonthOfYear.May
  if (month.toLowerCase() === `june`) return MonthOfYear.June
  if (month.toLowerCase() === `july`) return MonthOfYear.July
  if (month.toLowerCase() === `august`) return MonthOfYear.August
  if (month.toLowerCase() === `september`) return MonthOfYear.September
  if (month.toLowerCase() === `october`) return MonthOfYear.October
  if (month.toLowerCase() === `november`) return MonthOfYear.November
  if (month.toLowerCase() === `december`) return MonthOfYear.December
  return getMonthOfYearFromDate(startOfToday())
}

export function getWeekOfMonthFromDate(date: Date): number {
  const week = getWeekOfMonth(date)
  return week > 3 ? 4 : week
}

export function getWeekOfMonthText(number: number): string {
  if (number === 1) return `first`
  if (number === 2) return `second`
  if (number === 3) return `third`
  if (number === 4) return `fourth`
  return `first`
}

export function getDayOfMonthFromDate(date: Date): number {
  const day = parseInt(format(date, `d`))
  return isNumber(day) ? day : 1
}

export function getDayOfMonthText(number: number): string {
  if (number === 1) return `1st`
  if (number === 2) return `2nd`
  if (number === 3) return `3rd`
  return `${number}th`
}

export function getWeekInMonthFromText(text: string): number | undefined {
  switch (text) {
    case `1st`:
      return 1
    case `2nd`:
      return 2
    case `3rd`:
      return 3
    case `4th`:
      return 4
    default:
      return undefined
  }
}

export function getMonthOfYearFromDate(date: Date): MonthOfYear {
  const month = format(date, `MMMM`)
  return month as MonthOfYear
}

export function getByDayOfWeekFromDate(
  frequency: RecurrenceFrequency,
  date: Date,
): Array<DayOfWeek | DayOfWeekInMonth | DayOfWeekInYear> {
  switch (frequency) {
    case RecurrenceFrequency.Daily:
      return []
    case RecurrenceFrequency.Weekly:
      return [getDayOfWeekFromDate(date)]
    case RecurrenceFrequency.Monthly:
      return [
        {
          dayOfWeek: getDayOfWeekFromDate(date),
          weekOfMonth: getWeekOfMonthFromDate(date),
        },
      ]
    case RecurrenceFrequency.Yearly:
      return [
        {
          dayOfMonth: getDayOfMonthFromDate(date),
          monthOfYear: getMonthOfYearFromDate(date),
        },
      ]
    default:
      return []
  }
}

export function doesRecurrenceMatchDate(
  date: Date,
  recurrence: RecurrenceProp | undefined,
): boolean {
  if (!recurrence) return true
  switch (recurrence.frequency) {
    case RecurrenceFrequency.Daily:
      return true
    case RecurrenceFrequency.Weekly:
      return getDayOfWeekFromDate(date) === recurrence.byDayOfWeek[0]
    case RecurrenceFrequency.Monthly:
      return (
        getDayOfWeekFromDate(date) === recurrence.byDayOfWeek[0]?.dayOfWeek &&
        getWeekOfMonthFromDate(date) === recurrence.byDayOfWeek[0]?.weekOfMonth
      )
    case RecurrenceFrequency.Yearly:
      return (
        getDayOfMonthFromDate(date) === recurrence.byDayOfWeek[0]?.dayOfMonth &&
        getMonthOfYearFromDate(date) === recurrence.byDayOfWeek[0]?.monthOfYear
      )
    default:
      return true
  }
}

export function isWeekDay(value: string): boolean {
  const weekDays = [
    `sunday`,
    `monday`,
    `tuesday`,
    `wednesday`,
    `thursday`,
    `friday`,
    `saturday`,
  ]
  return value ? weekDays.includes(value.toLowerCase()) : false
}

export function getDayOfWeekName(day: DayOfWeek): string {
  if (day === DayOfWeek.SU) return `sunday`
  if (day === DayOfWeek.MO) return `monday`
  if (day === DayOfWeek.TU) return `tuesday`
  if (day === DayOfWeek.WE) return `wednesday`
  if (day === DayOfWeek.TH) return `thursday`
  if (day === DayOfWeek.FR) return `friday`
  if (day === DayOfWeek.SA) return `saturday`
  throw new Error(`Not a recognized day: ${day}`)
}

export function getFrequencyTitle(
  frequency: RecurrenceFrequency,
  byDayOfWeek: Array<DayOfWeek | DayOfWeekInMonth | DayOfWeekInYear>,
): string {
  switch (frequency) {
    case RecurrenceFrequency.Daily:
      return `Daily`
    case RecurrenceFrequency.Weekly: {
      const dayOfWeek = (byDayOfWeek as DayOfWeek[])[0]
      return dayOfWeek
        ? `Weekly on ${capitalize(getDayOfWeekName(dayOfWeek))}`
        : ``
    }
    case RecurrenceFrequency.Monthly: {
      const date = (byDayOfWeek as DayOfWeekInMonth[])[0]
      return `Monthly every ${getWeekOfMonthText(
        date?.weekOfMonth,
      )} ${capitalize(getDayOfWeekName(date?.dayOfWeek))}`
    }
    case RecurrenceFrequency.Yearly: {
      const date = (byDayOfWeek as DayOfWeekInYear[])[0]
      return `Every ${getDayOfMonthText(date?.dayOfMonth)} of ${
        date?.monthOfYear
      }`
    }
    default:
      return `Does not repeat`
  }
}

export function getFrequencyDescription(
  frequency: RecurrenceFrequency,
): string {
  switch (frequency) {
    case RecurrenceFrequency.Daily:
      return `every day`
    case RecurrenceFrequency.Weekly:
      return `every week`
    case RecurrenceFrequency.Monthly:
      return `every month`
    case RecurrenceFrequency.Yearly:
      return `every year`
    default:
      return ``
  }
}

export function getDateModelDescription(isoDate?: string | null): string {
  return isoDate ? dateStringToInputText(isoDate) : ``
}
