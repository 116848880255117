// Record constants
export const RECORD = 'capture'
export const RECORDS = 'captures'

// Annotation constants
export const ADD_NOTE = `Annotate`
export const HIDE_NOTE = `Hide annotation`
export const SHOW_NOTE = `Show annotation`
export const REMOVE_NOTE = `Remove annotation`
export const NOTE = `Annotation`

// Archive constants
export const COMPLETE = `Archive`
export const UNCOMPLETE = `Unarchive`
export const SHOW_COMPLETED = `Show archived`
export const HIDE_COMPLETED = `Hide archived`
export const COMPLETED = `Archived`

// Fullscreen constants
export const FULLSCREEN = `Note view`
export const OPEN_FULLSCREEN = `Open note view`
export const CLOSE_FULLSREEN = `Close note view`

// Bookmark constants
export const BOOKMARK = `Web page`

// Go to constatnts
export const GO_TO = 'Go to...'
export const GO_TO_PLACEHOLDER = 'Type a destination name...'

// Password Constants
export const PASSWORD_SET = 'Set'
export const PASSWORD_RESET = 'Reset'
export const PASSWORD_SETTING = 'Setting'
export const PASSWORD_RESETTING = 'Resetting'
