import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import { NoteList } from '../../../../components/RecordItem/components/NoteList/NoteList'
import RecordContent from '../../../../components/RecordItem/components/RecordContent'
import { RecordHandle } from '../../../../components/RecordItem/components/RecordHandle'
import { RecordToggle } from '../../../../components/RecordItem/components/RecordToggle'
import { TagStrip } from '../../../../components/RecordItem/components/TagStrip'
import { RecordItemDimension } from '../../../../components/RecordItem/types'
import { useUIContext } from '../../../contexts/UIContext'
import { RecordItemData } from '../../../data/recordGroups'
import { emptyFn } from '../../../utils'
import { useShouldAutofocus } from '../../../../components/RecordItem/hooks/useShouldAutofocus'
import { usePlaceholderText } from '../../../../components/RecordItem/hooks/usePlaceholderText'
import ContentErrorBoundary from '../../../errors/ContentErrorBoundary'
import {
  Icon,
  collapsedStripGradientStyles,
  collapsedStripClassName,
  collapsedStripStyles,
  cx,
  propertiesStripClassName,
  recordContentInnerStyles,
  recordContentStyles,
  recordItemInnerStyles,
  recordItemStyles,
  recordItemStyles_inSelection,
  styled,
} from '../../../stationary'

export interface RecordPreviewProps {
  className?: string
  record: RecordItemData
  dragCount?: number
  showToggle?: boolean
}

const PREVIEW_ITEM_DEFAULT_WIDTH = 600
export let previewRecordItemWidth = PREVIEW_ITEM_DEFAULT_WIDTH

export const RecordItemPreview: React.FC<RecordPreviewProps> = ({
  className,
  record,
  dragCount,
  showToggle = false,
}) => {
  const { shouldDisplayNotesForRecordId } = useUIContext()

  const hasNotes = !isEmpty(record.notesList?.notes)
  const notesOpen = hasNotes && shouldDisplayNotesForRecordId(record.id)
  const hasTags = hasNotes || !isEmpty(record.tags)

  const contentRef = useRef<HTMLDivElement>(null)
  const height = contentRef.current?.clientHeight

  const isLongRecord = !!(height && height > RecordItemDimension.maxHeight)

  // Always collapse long records in record preview
  const isCollapsed = isLongRecord

  const maxHeight = isCollapsed
    ? hasTags
      ? RecordItemDimension.maxHeight + RecordItemDimension.propertiesStrip
      : RecordItemDimension.maxHeight
    : height

  useEffect(() => {
    // Make sure the preview item is the same width as the record item.
    // This could be brittle because we're using a CSS selector but I don't expect
    // the css selector name to change much
    previewRecordItemWidth =
      document.querySelector('.recordItem')?.clientWidth ??
      PREVIEW_ITEM_DEFAULT_WIDTH
  }, [])

  const { shouldAutofocus } = useShouldAutofocus(record.id)
  const [placeholderType, setPlaceholderType] = useState<string | undefined>()
  const { placeholder } = usePlaceholderText(record.id, placeholderType)

  return (
    <>
      {dragCount && dragCount > 1 && (
        <S.DraggedRecordCount>{dragCount}</S.DraggedRecordCount>
      )}
      <S.RecordItemPreview
        className={cx(className, {
          isLongRecord,
        })}
        style={{ width: previewRecordItemWidth }}
      >
        <RecordHandle isSticky />
        <RecordToggle completed={record.isCompleted} showAction={showToggle} />
        <S.RecordItemInner
          className={cx({
            isCollapsed,
            notesOpen,
            hasTags,
          })}
        >
          <S.RecordContent
            className={cx({
              isCollapsed,
            })}
            style={{
              maxHeight,
              height,
            }}
          >
            <S.RecordContent
              className={cx({
                isCollapsed,
              })}
              style={{
                maxHeight,
                height,
              }}
            >
              <S.RecordContentInner ref={contentRef}>
                <RecordContent
                  notesOpen={notesOpen}
                  record={{ ...record, id: `dnd-preview-${record.id}` }}
                  uploadProgress={100}
                  autofocus={shouldAutofocus}
                  placeholder={placeholder}
                  placeholderType={placeholderType}
                  setPlaceholderType={setPlaceholderType}
                  error={{
                    fallbackComponent: ContentErrorBoundary,
                    fallbackComponentProps: {
                      hasTags,
                      record,
                    },
                  }}
                />
              </S.RecordContentInner>
              {isCollapsed ? (
                <S.CollapsedStrip
                  className={cx(collapsedStripClassName, {
                    hasTags,
                  })}
                >
                  <Icon boxSize={16} variant="glyphExpandNS" />
                  Show More...
                </S.CollapsedStrip>
              ) : null}
              <S.TagStrip
                tags={record.tags}
                onTagClick={emptyFn}
                skipOccurrence={emptyFn}
                isCompleted={record.isCompleted}
                notesOpen={notesOpen}
                className={cx(propertiesStripClassName, {
                  isCollapsed,
                  isLongRecord,
                  notesOpen,
                })}
              />
            </S.RecordContent>
          </S.RecordContent>
          {notesOpen ? (
            <NoteList
              {...record.notesList}
              parentId={record.id}
              handlers={{}}
            />
          ) : null}
        </S.RecordItemInner>
      </S.RecordItemPreview>
    </>
  )
}

const S = {
  DraggedRecordCount: styled.div(({ theme }) => ({
    position: `absolute`,
    top: -10,
    left: -10,
    height: 28,
    width: 28,
    borderRadius: 50,
    backgroundColor: theme.colors.alpha.selection.solid,
    padding: 10,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    opacity: 1,
    color: 'white',
    ...theme.text.publicSans['16:24'],
    fontWeight: 500,
    zIndex: 1,
  })),
  RecordItemPreview: styled.div(({ theme }) => ({
    ...recordItemStyles({ theme }),
    ...recordItemStyles_inSelection({ theme }),

    width: previewRecordItemWidth,
    opacity: 0.9,

    // '& .thumbnail:before': {
    //   content: `""`,
    //   display: `block`,
    //   position: `absolute`,
    //   top: 0,
    //   bottom: 0,
    //   left: 0,
    //   right: 0,
    //   background: theme.colors.alpha.selection.weak,
    // },
  })),
  RecordItemInner: styled.div({ ...recordItemInnerStyles }),
  RecordContent: styled.div({ ...recordContentStyles }),
  RecordContentInner: styled.div({ ...recordContentInnerStyles }),
  CollapsedStrip: styled.div(({ theme }) => ({
    ...collapsedStripStyles({ theme }),

    '&::before': { ...collapsedStripGradientStyles },

    '&.hasTags': {
      bottom: RecordItemDimension.propertiesStrip,
    },
  })),
  TagStrip: styled(TagStrip)(({ theme }) => ({
    '&:not(.isLongRecord)': {
      background: 'none !important',
    },
  })),
}
