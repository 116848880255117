import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { CURRENT_APP_VERSION } from '../env'
import { useReplicacheContext } from '../replicache/ReplicacheContext'
import { useLatestAppVersionFromReplicache } from '../replicache/useReplicache'
import { emptyFn } from '../utils'
import { useMobileAppPlatform } from '../utils/env/useMobileAppPlatform'
import { triggerAppRefresh } from '../utils/refresh'
import { isVersionGreaterThan } from '../versions'

export interface SnackbarContextValue {
  isOpen: boolean
  closeSnackbar: () => void
  onActionButtonClick: () => void
  actionButtonText: string
  softDeleteConfirmationCount: number
  setSoftDeleteConfirmationCount: (count: number) => void
  onSoftDeleteConfirmationClose: () => void
}

/*
 * Context
 */

const defaultValue: SnackbarContextValue = {
  isOpen: false,
  closeSnackbar: emptyFn,
  onActionButtonClick: emptyFn,
  actionButtonText: '',
  softDeleteConfirmationCount: 0,
  setSoftDeleteConfirmationCount: () => undefined,
  onSoftDeleteConfirmationClose: () => undefined,
}

export const SnackbarContext = createContext<SnackbarContextValue>(defaultValue)
SnackbarContext.displayName = `SnackbarContext`

/*
 * Provider
 */

export interface SnackbarProviderProps {
  children: React.ReactNode
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const { rep } = useReplicacheContext()
  // Not using modelstore here specifically because we don't want to trigger a rerender
  // every time anything in the modelstore updates (mainly records)
  const latestAppVersion = useLatestAppVersionFromReplicache(rep)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [softDeleteConfirmationCount, setSoftDeleteConfirmationCount] =
    useState(0)

  const onSoftDeleteConfirmationClose = () => setSoftDeleteConfirmationCount(0)

  const mobileAppPlatform = useMobileAppPlatform()

  const onActionButtonClick = useCallback(() => {
    triggerAppRefresh(mobileAppPlatform)
  }, [])

  const actionButtonText = useMemo(() => {
    if (!mobileAppPlatform) return 'reload'
    return 'update'
  }, [])

  useEffect(() => {
    const isClientOld = isVersionGreaterThan(
      latestAppVersion,
      CURRENT_APP_VERSION,
    )
    setSnackbarOpen(isClientOld)
  }, [latestAppVersion])

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false)
  }, [])

  const contextValue = useMemo(() => {
    return {
      isOpen: snackbarOpen,
      closeSnackbar: handleSnackbarClose,
      actionButtonText,
      onActionButtonClick,
      softDeleteConfirmationCount,
      setSoftDeleteConfirmationCount,
      onSoftDeleteConfirmationClose,
    }
  }, [
    snackbarOpen,
    handleSnackbarClose,
    actionButtonText,
    onActionButtonClick,
    softDeleteConfirmationCount,
    setSoftDeleteConfirmationCount,
    onSoftDeleteConfirmationClose,
  ])

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
    </SnackbarContext.Provider>
  )
}

/*
 * Hooks
 */

export const useSnackbarContext = (): SnackbarContextValue => {
  const context = useContext(SnackbarContext)

  if (context === undefined) {
    throw new Error(
      `useSnackbarContext must be used within an SnackbarProvider`,
    )
  }

  return context
}
