import React, { useCallback } from 'react'
import { Subtract } from 'utility-types'

import { RecordItemData } from '../../common/data/recordGroups'
import { RecordItemHandlers as GenericRecordItemHandlers } from '../../common/recordItemEventHandlers'
import { RecordItemHandlers } from '../RecordItem/types'

export const withRecordItemEventHandlers = <
  T extends RecordItemHandlers & { record: RecordItemData },
>(
  WrappedComponent: React.FC<T>,
) => {
  const ComponentWithEventHandlers: React.FC<
    Subtract<T, RecordItemHandlers> &
      GenericRecordItemHandlers & { record: RecordItemData }
  > = (props) => {
    const {
      expandOnFocus,
      onArrowLeftOrUpAtBoundary,
      onArrowRightOrDownAtBoundary,
      onBackspaceAtFirstPointWhenNotEmpty,
      onBackspaceWhenEmpty,
      onBlur,
      onChange,
      onClick,
      onEnter,
      onEscape,
      onFileTitleClick,
      onFocus,
      onHandleClick,
      onKeyDown,
      onMouseDown,
      onMoveTo,
      onPaste,
      onShowMoreClick,
      onTagClick,
      onToggle,
      onTap,
      onUrlTitleClick,
      renameFile,
      skipOccurrence,
      ...rest
    } = props

    const recordId = props.record.id

    const _onBlur = useCallback(
      (e) => {
        return onBlur && onBlur(e, recordId)
      },
      [onBlur, recordId],
    )

    const _onFocus = useCallback(
      (e) => {
        return onFocus && onFocus(e, recordId)
      },
      [onFocus, recordId],
    )

    const _expandOnFocus = useCallback(
      (e) => {
        return expandOnFocus && expandOnFocus(e, recordId)
      },
      [expandOnFocus, recordId],
    )

    const _onHandleClick = useCallback(
      (e) => {
        return onHandleClick && onHandleClick(e, recordId)
      },
      [onHandleClick, recordId],
    )

    const _onShowMoreClick = useCallback(
      (e) => {
        return onShowMoreClick && onShowMoreClick(e, recordId)
      },
      [onShowMoreClick, recordId],
    )

    const _onMouseDown = useCallback(
      (e) => {
        return onMouseDown && onMouseDown(e, recordId)
      },
      [onMouseDown, recordId],
    )

    const _onChange = useCallback(
      (value) => {
        return onChange && onChange(value, recordId)
      },
      [onChange, recordId],
    )

    const _onPaste = useCallback(
      (e) => {
        return onPaste && onPaste(e, recordId)
      },
      [onPaste, recordId],
    )

    const _onArrowRightOrDownAtBoundary = useCallback(() => {
      return (
        onArrowRightOrDownAtBoundary && onArrowRightOrDownAtBoundary(recordId)
      )
    }, [onArrowRightOrDownAtBoundary, recordId])

    const _onArrowLeftOrUpAtBoundary = useCallback(
      (e) => {
        return onArrowLeftOrUpAtBoundary && onArrowLeftOrUpAtBoundary(recordId)
      },
      [onArrowLeftOrUpAtBoundary, recordId],
    )

    const _onBackspaceWhenEmpty = useCallback(() => {
      return onBackspaceWhenEmpty && onBackspaceWhenEmpty(recordId)
    }, [onBackspaceWhenEmpty, recordId])

    const _onBackspaceAtFirstPointWhenNotEmpty = useCallback(
      (e) => {
        return (
          onBackspaceAtFirstPointWhenNotEmpty &&
          onBackspaceAtFirstPointWhenNotEmpty(recordId)
        )
      },
      [onBackspaceAtFirstPointWhenNotEmpty, recordId],
    )

    const _onKeyDown = useCallback(
      (e) => {
        return onKeyDown && onKeyDown(e, recordId)
      },
      [onKeyDown, recordId],
    )

    const _onTagClick = useCallback(
      (e, tag) => {
        return onTagClick && onTagClick(e, recordId, tag)
      },
      [onTagClick, recordId],
    )

    const _onToggle = useCallback(
      (e) => {
        return onToggle && onToggle(e, recordId)
      },
      [onToggle, recordId],
    )

    const _onTap = useCallback(
      (e) => {
        return onTap && onTap(e, recordId)
      },
      [onTap, recordId],
    )

    const _onMoveTo = useCallback(
      (e) => {
        return onMoveTo && onMoveTo(e, recordId)
      },
      [onMoveTo, recordId],
    )

    const _onClick = useCallback(
      (e) => {
        return onClick && onClick(e, recordId)
      },
      [onClick, recordId],
    )

    const _renameFile = useCallback(
      (fileName: string) => {
        return renameFile && renameFile(recordId, fileName)
      },
      [renameFile, recordId],
    )

    const _skipOccurrence = useCallback(() => {
      return skipOccurrence && skipOccurrence(recordId)
    }, [skipOccurrence, recordId])

    const _onUrlTitleClick = useCallback(
      (e) => {
        return onUrlTitleClick && onUrlTitleClick(e, recordId)
      },
      [onUrlTitleClick, recordId],
    )

    const _onFileTitleClick = useCallback(
      (e) => {
        return onFileTitleClick && onFileTitleClick(e, recordId)
      },
      [onFileTitleClick, recordId],
    )

    const _onEnter = useCallback(
      (e) => {
        if (onEnter === undefined) return false
        return onEnter(e, recordId)
      },
      [onEnter, recordId],
    )

    const _onEscape = useCallback(
      (e) => {
        if (onEscape === undefined) return false
        return onEscape(e, recordId)
      },
      [onEscape, recordId],
    )

    return (
      <WrappedComponent
        expandOnFocus={_expandOnFocus}
        onArrowLeftOrUpAtBoundary={_onArrowLeftOrUpAtBoundary}
        onArrowRightOrDownAtBoundary={_onArrowRightOrDownAtBoundary}
        onBackspaceWhenEmpty={_onBackspaceWhenEmpty}
        onBackspaceAtFirstPointWhenNotEmpty={
          _onBackspaceAtFirstPointWhenNotEmpty
        }
        onBlur={_onBlur}
        onChange={_onChange}
        onClick={_onClick}
        onEnter={_onEnter}
        onEscape={_onEscape}
        onFileTitleClick={_onFileTitleClick}
        onFocus={_onFocus}
        onHandleClick={_onHandleClick}
        onKeyDown={_onKeyDown}
        onMouseDown={_onMouseDown}
        onMoveTo={_onMoveTo}
        onPaste={_onPaste}
        onShowMoreClick={_onShowMoreClick}
        onTagClick={_onTagClick}
        onToggle={_onToggle}
        onTap={_onTap}
        onUrlTitleClick={_onUrlTitleClick}
        renameFile={_renameFile}
        skipOccurrence={_skipOccurrence}
        {...(rest as T)}
      />
    )
  }
  return ComponentWithEventHandlers
}
