import { JSONObject, ReadTransaction, WriteTransaction } from 'replicache'

import { userKey } from '@eleventhlabs/capture-shared'

import { IDBUser, IDBUserSchema } from './models/IDBUser'

const getUser = async (tx: ReadTransaction): Promise<IDBUser> => {
  const user = await tx.get(userKey)
  return IDBUserSchema.parse(user)
}

const safeGetUser = async (tx: ReadTransaction) => {
  const user = await tx.get(userKey)
  const result = IDBUserSchema.safeParse(user)
  if (result.success) {
    return result.data
  }
  return undefined
}

const putUser = async (
  tx: WriteTransaction,
  value: JSONObject,
): Promise<void> => {
  const userToPut = IDBUserSchema.parse(value)
  await tx.put(userKey, userToPut)
}

export const replicacheUserRepository = {
  getUser,
  safeGetUser,
  putUser,
}
