import React, { useState } from 'react'
import { Transition } from 'react-transition-group'
import { SlideProps } from '.'
import diagramDesktopAppMacSrc from '../../../../assets/onboarding/diagram-desktop-app-keep-in-dock@2x.png'
import diagramDesktopAppWindowsSrc from '../../../../assets/onboarding/diagram-desktop-app-keep-in-taskbar-windows@2x.png'
import { Key, useKeys } from '../../../../common/hooks/useKeys'
import { Icon, styled } from '../../../../common/stationary'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import { isMac, isMacLike } from '../../../../common/utils/env'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/SplitCardSlide'

export const DesktopKeepInDock: React.FC<SlideProps> = ({ goForward }) => {
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false)

  const { onboardingAnalytics } = useOnboardingContext()

  useKeys(Key.Enter, () => hasConfirmed && goForward())

  const onCheckConfirmClick = () => {
    onboardingAnalytics.action('Keep in dock confirmed')

    setHasConfirmed(!hasConfirmed)
  }

  const slideDescription = isMacLike ? (
    <>
      Just <strong>right-click the app icon</strong> in Dock, then select
      Options {`>`} Keep in Dock.
    </>
  ) : (
    <>
      Just <strong>right-click the app button</strong> in your taskbar, then
      select Pin to taskbar.
    </>
  )

  const taskbarDockCopy = isMacLike ? 'Dock' : 'taskbar'

  return (
    <S.InstallDesktopApp>
      <TransitionSlideContainer>
        <SlideIntro>
          <SlideTitle>Keep Capture in your {taskbarDockCopy}</SlideTitle>
          <SlideDescription>{slideDescription}</SlideDescription>
          <S.CheckPinnedWrapper onClick={onCheckConfirmClick}>
            <S.Checkbox>
              {hasConfirmed && <Icon boxSize={18} variant="glyphCheck" />}
            </S.Checkbox>
            <strong>I've finished this step</strong>
          </S.CheckPinnedWrapper>
          <SlideButtonGroup>
            <SlidePrimaryButton
              onClick={() => goForward()}
              disabled={!hasConfirmed}
            >
              <strong>Continue</strong>
            </SlidePrimaryButton>
          </SlideButtonGroup>
        </SlideIntro>
        <SlideDiagrams>
          <img
            src={
              isMacLike ? diagramDesktopAppMacSrc : diagramDesktopAppWindowsSrc
            }
            srcSet={`${
              isMacLike ? diagramDesktopAppMacSrc : diagramDesktopAppWindowsSrc
            } 2x`}
          />
        </SlideDiagrams>
      </TransitionSlideContainer>
    </S.InstallDesktopApp>
  )
}

const S = {
  InstallDesktopApp: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',
  })),
  CheckPinnedWrapper: styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    background: theme.colors.surface.base,
    ...theme.text.publicSans['13.5:20'],

    strong: {
      fontWeight: 700,
    },
  })),
  Checkbox: styled.div(({ theme }) => ({
    width: '16px',
    height: '16px',
    flexShrink: 0,
    borderRadius: 4,
    border: `2px solid ${theme.colors.text[900]}`,
    background: theme.colors.surface.base,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })),
}
