import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'

import { useUIContext } from '../../../common/contexts/UIContext'
import { useRouteFromLocation } from '../../../common/routes'
import {
  Button,
  Icon,
  IconVariantName,
  styled,
  withTooltip,
} from '../../../common/stationary'
import { useHeaderShortcuts } from '../hooks/useHeaderShortcuts'
import { HeaderTitle } from './HeaderTitle'
import { isMobile } from '../../../common/utils/env'
import { CLOSE_FULLSREEN } from '../../../common/utils/uiConstants'
import { useKeyCaps } from '../../../common/keyMappableActions/useKeyCaps'

export interface HeaderShortcut {
  variant?: IconVariantName
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const HeaderLeft: React.FC = ({}) => {
  const { isLeftHidden } = useUIContext()
  const { routeType, listId, recordId } = useRouteFromLocation()
  const isFullscreen = !!recordId

  const shortcuts = useHeaderShortcuts(isLeftHidden, isFullscreen, isMobile)

  const onMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.nativeEvent.stopImmediatePropagation()
  }, [])

  const keyCaps = useKeyCaps()

  return (
    <S.HeaderLeft>
      {shortcuts.map((shortcut) => (
        <S.ButtonWithIcon
          key={shortcut.variant}
          onMouseDown={onMouseDown}
          onClick={shortcut.onClick}
          tooltip={
            shortcut.variant === 'glyphHamburgerAlt'
              ? {
                  title: 'Show sidebar',
                  keycaps: keyCaps['KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE'],
                }
              : shortcut.variant === 'glyphX'
              ? {
                  title: CLOSE_FULLSREEN,
                }
              : undefined
          }
          variant="plain"
        >
          <Icon boxSize={18} variant={shortcut.variant} />
        </S.ButtonWithIcon>
      ))}
      {!isEmpty(shortcuts) ? <S.Separator /> : null}
      <S.TitleContainer>
        <HeaderTitle
          listId={listId}
          recordId={recordId}
          routeType={routeType}
        />
      </S.TitleContainer>
    </S.HeaderLeft>
  )
}

const S = {
  HeaderLeft: styled.div({
    display: `flex`,
    paddingLeft: 8,
    alignItems: `center`,
    flex: 1,
  }),
  ButtonWithIcon: styled(withTooltip(Button))(({ theme }) => ({
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: 40,
    height: 40,
    borderRadius: 8,
    color: theme.colors.text[800],

    '&:hover': {
      color: theme.colors.text[900],
    },
  })),
  Separator: styled.div(({ theme }) => ({
    display: `flex`,
    alignSelf: `center`,
    width: `1px`,
    height: `12px`,
    marginLeft: 8,
    background: theme.colors.alpha.border.mediumWeak,
  })),
  TitleContainer: styled.div({
    marginLeft: 8,
  }),
}
