import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

export const withDraggable = <T,>(WrappedComponent: React.FC<T>) => {
  const WithDraggable: React.FC<T & { id: string; index: number }> = (
    props,
  ) => (
    <Draggable draggableId={props.id} index={props.index} key={props.id}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <WrappedComponent
            provided={provided}
            isDragging={snapshot.isDragging}
            {...props}
          />
        </div>
      )}
    </Draggable>
  )
  return WithDraggable
}
