import {
  DataRecord,
  RecordType,
  UpdateRecordPayload,
} from '@eleventhlabs/capture-shared'

export const recordRenameFile = (
  records: Record<string, DataRecord>,
  recordId: string,
  fileName: string,
  updateRecord: (recordId: string, payload: UpdateRecordPayload) => void,
) => {
  const record = records[recordId]
  if (!(record.type === RecordType.File)) return
  const fileMetadata = record?.fileMetadata
  updateRecord(recordId, { fileMetadata: { ...fileMetadata, name: fileName } })
}
