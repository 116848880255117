import { zipObject } from 'lodash'
import { useCallback, useMemo } from 'react'

import { RecordItemData } from '../data/recordGroups'
import { BaseSelection } from '../libs/selection'
import { useNoteRichTextEventHandlersForFullscreen } from './richTextEventHandlers/useNoteRichTextEventHandlersForFullscreen'
import { ClickHTMLElementEvent, FocusEvent, NoteHandlers } from './types'
import { useExecContext } from '../contexts/ExecContext'

export const useNoteEventHandlersForFullscreen = (
  record: RecordItemData,
  selection: BaseSelection,
): NoteHandlers => {
  const { exec } = useExecContext()

  const notes = useMemo(() => {
    const notes = record.notesList.notes
    const noteIds = notes.map((n) => n.id)
    return zipObject(noteIds, notes)
  }, [record])

  const onFocus = useCallback(
    (ev: FocusEvent, noteId: string) => exec({ type: 'NOTE_FOCUSED', noteId }),
    [exec],
  )

  const onBlur = useCallback(
    (ev: FocusEvent, noteId: string) => exec({ type: 'NOTE_BLURRED', noteId }),
    [exec],
  )

  const onMouseDown = useCallback(
    (ev: ClickHTMLElementEvent, noteId: string, parentId?: string) => {
      // do nothing
    },
    [],
  )

  const {
    onArrowLeftOrUpAtBoundary,
    onArrowRightOrDownAtBoundary,
    onBackspaceWhenEmpty,
    onChange,
    onPaste,
    onKeyDown,
  } = useNoteRichTextEventHandlersForFullscreen(record, notes, selection)

  const handlers = useMemo(
    () => ({
      onArrowLeftOrUpAtBoundary,
      onArrowRightOrDownAtBoundary,
      onBackspaceWhenEmpty,
      onBlur,
      onChange,
      onPaste,
      onFocus,
      onKeyDown,
      onMouseDown,
    }),
    [
      onArrowLeftOrUpAtBoundary,
      onArrowRightOrDownAtBoundary,
      onBackspaceWhenEmpty,
      onBlur,
      onChange,
      onPaste,
      onFocus,
      onKeyDown,
      onMouseDown,
    ],
  )

  return handlers
}
