import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

import { RecordType } from '@eleventhlabs/capture-shared'
import { useEditorAnalyticsHandlers } from '../../../common/analytics/helpers/hooks/useValidateEditorAnalytics'
import { RecordItemData } from '../../../common/data/recordGroups'
import { ContentErrorBoundaryErrorProps } from '../../../common/errors/ContentErrorBoundary'
import { withErrorBoundary } from '../../../common/errors/enhancers/withErrorBoundary'
import { RichTextEditor } from '../../RichTextEditor'
import { RecordItemHandlers } from '../types'
import { FileRecordItemContent } from './FileRecordItemContent/FileRecordItemContent'
import { URLRecordItemContent } from './URLRecordItemContent'
import { cx, styled } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'
import { emptyFn } from '../../../common/utils'
import { scrollAction } from '../../../common/events'

interface RecordContentProps extends RecordItemHandlers {
  record: RecordItemData
  uploadProgress: number
  notesOpen: boolean
  className?: string
  style?: any
  autofocus?: boolean
  placeholder?: string
  placeholderType?: string
  setPlaceholderType?: (type?: string) => void
  isExpanded?: boolean
  isCompleted?: boolean
  maxHeight?: number
}

const RecordContent: React.FC<RecordContentProps> = ({
  notesOpen,
  onArrowLeftOrUpAtBoundary,
  onArrowRightOrDownAtBoundary,
  onBackspaceAtFirstPointWhenNotEmpty,
  onBackspaceWhenEmpty,
  onBlur,
  onChange,
  onClick,
  onEnter,
  onEscape,
  onFileTitleClick,
  onFocus,
  onKeyDown,
  onBackspace,
  onMouseDown,
  onPaste,
  onUrlTitleClick,
  record,
  renameFile,
  uploadProgress,
  autofocus,
  placeholder,
  placeholderType,
  setPlaceholderType = emptyFn,
  isExpanded,
  isCompleted,
  maxHeight,
}) => {
  const hasTags = !isEmpty(record.tags)
  const { validateEditorAnalytics, onListItemChange } =
    useEditorAnalyticsHandlers(record.id, record.type)

  useEffect(() => {
    if (autofocus)
      scrollAction({
        target: `record`,
        to: { id: record.id },
        options: { extraOffsetTop: 8 },
      })
  }, [autofocus])

  switch (record.type) {
    case RecordType.Text: {
      if (!record.richText) return null
      return (
        <S.Editor
          autofocus={autofocus}
          editable={!isMobile}
          editorClassName={cx({ isCompleted: record.isCompleted }, { hasTags })}
          id={record.id}
          onBlur={onBlur}
          onChangeDebounced={onChange}
          onArrowLeftOrUpAtBoundary={onArrowLeftOrUpAtBoundary}
          onArrowRightOrDownAtBoundary={onArrowRightOrDownAtBoundary}
          onBackspace={onBackspace}
          onBackspaceAtFirstPointWhenNotEmpty={
            onBackspaceAtFirstPointWhenNotEmpty
          }
          onBackspaceWhenEmpty={onBackspaceWhenEmpty}
          onClick={onClick}
          onEnter={onEnter}
          onEscape={onEscape}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onMouseDown={onMouseDown}
          onPaste={onPaste}
          placeholder={placeholder}
          setPlaceholderType={setPlaceholderType}
          validateAnalytics={validateEditorAnalytics}
          onListItemChange={onListItemChange}
          placeholderType={placeholderType}
          richText={record.richText.value}
        />
      )
    }
    case RecordType.URL:
      return (
        <S.URLRecord
          className={cx(
            { isCompleted: record.isCompleted },
            {
              hasTags,
            },
          )}
          hasTags={hasTags}
          id={record.id}
          notesOpen={notesOpen}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onTitleClick={onUrlTitleClick}
          url={record.url}
          {...record?.urlMetadata}
        />
      )
    case RecordType.File:
      return (
        <FileRecordItemContent
          className={cx(
            { isCompleted: record.isCompleted },
            {
              hasTags,
            },
          )}
          hasTags={hasTags}
          id={record.id}
          metadata={record.fileMetadata}
          notesOpen={notesOpen}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onTitleClick={onFileTitleClick}
          renameFile={renameFile}
          thumbnailUrl={record.thumbnailUrl}
          uploadProgress={uploadProgress}
          url={record.url}
          isExpanded={!!isExpanded}
          isCompleted={isCompleted}
          initialHeight={maxHeight}
        />
      )
    default:
      return null
  }
}

export default withErrorBoundary<
  RecordContentProps,
  ContentErrorBoundaryErrorProps
>(RecordContent)

const S = {
  Editor: styled(RichTextEditor)({
    '&.isCompleted': {
      opacity: `.5`,
    },

    '&.startsWithHeadingOrIsMultiline': {
      paddingBottom: 8,
    },

    '&.hasTags': {
      paddingBottom: 28,

      '&.startsWithHeadingOrIsMultiline': {
        paddingBottom: 36,
      },
    },
  }),
  URLRecord: styled(URLRecordItemContent)({
    '&.isCompleted': {
      opacity: `.5`,
    },
    '&.hasTags': {
      paddingBottom: 0,
    },
  }),
  FileRecord: styled(FileRecordItemContent)({
    '&.isCompleted': {
      opacity: `.5`,
    },
    '&.hasTags': {
      paddingBottom: 0,
    },
  }),
}
