import React from 'react'

import { withTooltip } from '../../../common/stationary'

interface TagItemContentProps {
  className?: string
  onClick?: (e: any) => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  children: React.ReactNode
}

export const TagItemContentWithoutTooltip = React.forwardRef<
  HTMLDivElement,
  TagItemContentProps
>(({ className, onClick, onMouseDown, children }, ref) => (
  <div
    className={className}
    onClick={onClick}
    onMouseDown={onMouseDown}
    ref={ref}
  >
    {children}
  </div>
))

export const TagItemContentWithTooltip = withTooltip(
  TagItemContentWithoutTooltip,
)
