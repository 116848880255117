import { InboxRecordId, TodayId } from '@eleventhlabs/capture-shared'
import { findIndex, includes } from 'lodash'
import React from 'react'

import { RoutesTitle, RouteType } from '../../../common/routes'
import { Shortcut } from '../components/ShortcutGroup'

interface UseShortcutProps {
  activeRouteType?: RouteType
  count: Record<string, number>
  listId?: string
  onInboxClick: () => void
  onTodayClick: () => void
}

interface UseShortcuts {
  shortcuts: Shortcut[]
  activeShortcutsIndex: number
}

export const useShortcuts = (props: UseShortcutProps): UseShortcuts => {
  const shortcuts: Shortcut[] = React.useMemo(
    () => [
      {
        id: InboxRecordId,
        routeType: RouteType.Inbox,
        title: RoutesTitle[RouteType.Inbox] ?? ``,
        onClick: props.onInboxClick,
        count: props.count[RouteType.Inbox],
        placement: `top`,
      },
      {
        id: TodayId,
        routeType: RouteType.Today,
        title: RoutesTitle[RouteType.Today] ?? ``,
        onClick: props.onTodayClick,
        count: props.count[RouteType.Today],
        placement: `top`,
      },
    ],
    [props.onInboxClick, props.onTodayClick, props.count],
  )

  const activeShortcutsIndex = findIndex(shortcuts, (shortcut) =>
    includes(shortcut.routeType, props.activeRouteType),
  )

  return {
    shortcuts,
    activeShortcutsIndex,
  }
}
