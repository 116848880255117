import { useCallback, useMemo } from 'react'

import { DataRecord } from '@eleventhlabs/capture-shared'

import { useActiveScreen } from '../../common/analytics/capture-analytics-web'
import { useExecContext } from '../../common/contexts/ExecContext'
import { EditorsManager } from '../../common/EditorsManager'

export interface CommonFullScreenHotKeys {
  onDownArrow: (e: KeyboardEvent) => void
  onEnter: (e: KeyboardEvent) => void
  onI: (e: KeyboardEvent) => void
  onJ: (e: KeyboardEvent) => void
}

export const useCommonHotkeysForFullscreen = (
  record: DataRecord,
): CommonFullScreenHotKeys => {
  const { exec } = useExecContext()

  const activeScreen = useActiveScreen()
  const recordId = record.id

  const focusFirstLine = useCallback(() => {
    EditorsManager.effects.focus({ id: recordId, position: `endOfFirstLine` })
  }, [recordId, activeScreen])

  const onEnter = useCallback(
    (e) => {
      e.preventDefault()
      focusFirstLine()
    },
    [focusFirstLine, exec],
  )

  const onDownArrow = useCallback(
    (e) => {
      e.preventDefault()
      focusFirstLine()
    },
    [focusFirstLine],
  )

  const onJ = useCallback(
    (e) => {
      e.preventDefault()
      focusFirstLine()
    },
    [focusFirstLine],
  )

  return useMemo(
    () => ({
      onDownArrow,
      onEnter,
      onI: onEnter,
      onJ,
    }),
    [onDownArrow, onEnter, onJ],
  )
}
