import { RouteType } from '../../../../routes'
import {
  InboxRecordId,
  SortDirection,
  SortRecordsBy,
  SortRecordsPayload,
} from '@eleventhlabs/capture-shared'

export const sortByDate = (
  routeType: RouteType,
  listId: string | undefined,
  direction: SortDirection,
  sortRecords: (payload: SortRecordsPayload) => void,
) => {
  let id: string
  if (routeType === RouteType.Inbox) {
    id = InboxRecordId
  } else {
    if (listId === undefined) throw new Error(``)
    id = listId
  }
  sortRecords({
    type: SortRecordsBy.Date,
    direction,
    locationId: id,
  })
}
