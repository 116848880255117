import { uniq } from 'lodash'
import { JSONValue, WriteTransaction } from 'replicache'

import {
  InsertPoint,
  RCMutation,
  create,
  dateGroupFromIso,
  isCompleteableRecord,
  isNoteRecord,
  isParentableRecord,
  isTickleableRecord,
  zonedIsoDateFromUtcAndTz,
} from '@eleventhlabs/capture-shared'

import { moveIntoParent } from './moveIntoParent'
import {
  createDestIfNeededAndNormalizeIdx,
  getNoteableRecord,
  getTickleableRecord,
  getUserCreatableRecord,
  updateFIndexesForMoveIntoDateGroup,
  updateRecord,
  updateTickler,
  upsertRecord,
} from './utils'

export const createRecord = async (
  tx: WriteTransaction,
  {
    payload,
    index,
    executedAtOnClient,
    tzOnClient,
  }: RCMutation.CreateRecordArgs,
): Promise<JSONValue> => {
  // Create the record
  const record = create(payload)
  await upsertRecord(tx, record.id, record)

  // If creating record with parent, move into parent and update parentId
  if (isParentableRecord(record) && index !== undefined) {
    const normalizedIndex = await createDestIfNeededAndNormalizeIdx(tx, index)
    await updateRecord(tx, record.id, { parentId: normalizedIndex.id })
    // TODO: create a moveIntoParent that doesn't take executedAtOnClient
    await moveIntoParent(tx, {
      recordIds: [record.id],
      index: normalizedIndex,
      executedAtOnClient,
    })
  }

  // If creating note, add to notes prop of notee
  if (isNoteRecord(record)) {
    const noteableRecord = await getNoteableRecord(tx, record.parentId)
    await updateRecord(tx, noteableRecord.id, {
      notes: uniq([record.id, ...noteableRecord.notes]),
    })
  }

  if (`tickler` in payload && isTickleableRecord(record)) {
    await updateTicklerIfInPayload(
      tx,
      record.id,
      payload,
      executedAtOnClient,
      tzOnClient,
    )
  }

  if (`isCompleted` in payload && isCompleteableRecord(record)) {
    await updateRecord(tx, record.id, {
      isCompleted: payload.isCompleted,
      completedAt: payload.completedAt ?? Date.now(),
    } as JSONValue)
  }
  // @ts-ignore
  return (await getUserCreatableRecord(tx, record.id)) as JSONValue
}

const updateTicklerIfInPayload = async (
  tx: WriteTransaction,
  recordId: string,
  payload: RCMutation.CreateRecordArgs['payload'],
  executedAtOnClient: number,
  tzOnClient?: string,
) => {
  const record = await getTickleableRecord(tx, recordId)
  if (`tickler` in payload && payload.tickler !== undefined) {
    await updateTickler(tx, record, payload.tickler)
    const point: InsertPoint = payload?.pointInDateGroup ?? {
      at: `start`,
    }
    const todayIsoWhenExcecutedOnClient = zonedIsoDateFromUtcAndTz(
      executedAtOnClient,
      tzOnClient,
    )
    const dateGroup = dateGroupFromIso(
      todayIsoWhenExcecutedOnClient,
      payload.tickler.isoDate,
    )
    await updateFIndexesForMoveIntoDateGroup(
      tx,
      [record.id],
      dateGroup,
      point,
      executedAtOnClient,
      tzOnClient,
    )
  }
}
