import { useEffect, useState } from 'react'

import { BaseSelection } from '../libs/selection'
import { RecordDict, isCompleteableRecord } from '@eleventhlabs/capture-shared'

export const useRemoveCompletedFromSelectionOnToggle = (
  selection: BaseSelection,
  records: RecordDict,
  showCompleted: boolean,
) => {
  const [prevShowCompleted, setPrevShowCompleted] = useState(showCompleted)
  useEffect(() => {
    setPrevShowCompleted(showCompleted)
  }, [showCompleted])
  useEffect(() => {
    if (prevShowCompleted !== showCompleted && !showCompleted) {
      const withoutCompleted = selection.ids.filter((id) => {
        const record = records[id]
        return isCompleteableRecord(record) && !record.isCompleted
      })
      selection.set(withoutCompleted)
    }
  }, [prevShowCompleted, showCompleted, selection, records])
}
