import React, { useMemo } from 'react'

import { ListItem } from './OutlineListItem'
import { DnDOutlineCategoryList } from './DnDOutlineCategoryList'

interface OutlineCategoryListProps {
  className?: string
  items: ListItem[]
  activeItem: string | undefined
}

export const OutlineCategoryList: React.FC<OutlineCategoryListProps> = ({
  className,
  items,
  activeItem,
}) => {
  const itemData = useMemo(
    () => ({
      items,
      activeItem,
    }),
    [items, activeItem],
  )
  return <DnDOutlineCategoryList className={className} itemData={itemData} />
}

export default OutlineCategoryList
