import { useState } from 'react'

import keyboardManager from '../keyboardManager'
import { useHotkeys } from './useHotKeys'
import { Key } from './useKeys'

/**
 * Use this to register hotkeys = declare that we will be using this hotkeys, DO THIS JUST ONCE
 */

export const useRegisterHotKeys = (keys: string): void => {
  // https://www.notion.so/eleventh/Keyboard-shortcuts-bf276c77d8d343119c7282712872efa3
  // We use useState here because MacOS does not emit keyup events when cmd/meta is pressed.
  // This breaks useHotkeys, which relies on keyup events.
  // To solve it, we immediately re-register the event handler when it is used.
  const [wasPressedFlipFlop, setWasPressedFlipFlop] = useState(false)
  useHotkeys(
    keys,
    (event) => {
      keyboardManager.onKeyDown(keys, event)
      setWasPressedFlipFlop((val) => !val)
    },
    {
      filter: (event: KeyboardEvent): boolean => {
        const isTargetContentEditable: boolean =
          (event as any)?.target?.getAttribute &&
          (event as any)?.target?.getAttribute(`contenteditable`)
        const isSrcElementContentEditable: boolean =
          (event as any)?.srcElement?.getAttribute &&
          (event as any)?.srcElement?.getAttribute(`contenteditable`)
        return !(isTargetContentEditable || isSrcElementContentEditable)
      },
      filterPreventDefault: false,
    },
    [wasPressedFlipFlop],
  )
}

export const useRegisterAllHotKeys = () => {
  useRegisterHotKeys(Key.Backspace)
  useRegisterHotKeys(Key.C)
  useRegisterHotKeys(Key.CommandA)
  useRegisterHotKeys(Key.CommandC)
  useRegisterHotKeys(Key.CommandD)
  useRegisterHotKeys(Key.CommandE)
  useRegisterHotKeys(Key.CommandDown)
  useRegisterHotKeys(Key.CommandEnter)
  useRegisterHotKeys(Key.CommandGreaterThan)
  useRegisterHotKeys(Key.CommandK)
  useRegisterHotKeys(Key.CommandShiftDown)
  useRegisterHotKeys(Key.CommandShiftP)
  useRegisterHotKeys(Key.CommandShiftUp)
  useRegisterHotKeys(Key.CommandSlash)
  useRegisterHotKeys(Key.CommandUp)
  useRegisterHotKeys(Key.D)
  useRegisterHotKeys(Key.Delete)
  useRegisterHotKeys(Key.Down)
  useRegisterHotKeys(Key.E)
  useRegisterHotKeys(Key.Enter)
  useRegisterHotKeys(Key.Esc)
  useRegisterHotKeys(Key.F)
  useRegisterHotKeys(Key.G)
  useRegisterHotKeys(Key.GreaterThan)
  useRegisterHotKeys(Key.H)
  useRegisterHotKeys(Key.I)
  useRegisterHotKeys(Key.J)
  useRegisterHotKeys(Key.K)
  useRegisterHotKeys(Key.L)
  useRegisterHotKeys(Key.Left)
  useRegisterHotKeys(Key.LowerThan)
  useRegisterHotKeys(Key.M)
  useRegisterHotKeys(Key.N)
  useRegisterHotKeys(Key.O)
  useRegisterHotKeys(Key.OptionDown)
  useRegisterHotKeys(Key.OptionJ)
  useRegisterHotKeys(Key.OptionK)
  useRegisterHotKeys(Key.OptionUp)
  useRegisterHotKeys(Key.Q)
  useRegisterHotKeys(Key.QMark)
  useRegisterHotKeys(Key.Right)
  useRegisterHotKeys(Key.S)
  useRegisterHotKeys(Key.ShiftDown)
  useRegisterHotKeys(Key.ShiftJ)
  useRegisterHotKeys(Key.ShiftK)
  useRegisterHotKeys(Key.ShiftTab)
  useRegisterHotKeys(Key.ShiftUp)
  useRegisterHotKeys(Key.T)
  useRegisterHotKeys(Key.Tab)
  useRegisterHotKeys(Key.Up)
  useRegisterHotKeys(Key.X)
  useRegisterHotKeys(Key.V)
  useRegisterHotKeys(Key.U)
  useRegisterHotKeys(Key.B)
  useRegisterHotKeys(Key.Semicolon)
  useRegisterHotKeys(Key.CtrlM)
  useRegisterHotKeys(Key.CommandBackslash)
  useRegisterHotKeys(Key.CommandShiftE)
}
