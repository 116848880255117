import { values } from 'lodash'
import { WriteTransaction } from 'replicache'

import {
  DataRecord,
  DeleteableRecord,
  isDeleteableRecord,
  isNoteableRecord,
  isNoteRecord,
  isParentableRecord,
  isWithChildren,
  RCMutation,
} from '@eleventhlabs/capture-shared'

import { getSoftDeletedRecords } from '../query'
import {
  deleteRecord,
  getNoteableRecord,
  getRecord,
  getRecordWithChildren,
  removeFromChildren,
  removeFromNotes,
} from './utils'

export const deleteRecords = async (
  tx: WriteTransaction,
  { recordIds }: RCMutation.DeleteRecordsArgs,
): Promise<void> => {
  for (const id of recordIds) {
    await hardDeleteRecord(tx, id)
  }
}

// Strategy: Delete records "Depth first"
// First, delete any child-ish records lower down in record tree.
//   This includes deleting soft-deleted records.
// Then, remove references to the record higher up in the record tree.
// Finally, delete the record.

// Current assumptions:
// Never have to delete a note with an already-soft-deleted parent record.
// Never have to delete a record with an already-soft-deleted parent record.

const hardDeleteRecord = async (tx: WriteTransaction, recordId: string) => {
  const r = await getRecord(tx, recordId)
  if (r === undefined) {
    console.error('Tried to delete undefined record')
    return
  }
  if (!isDeleteableRecord(r)) {
    throw new Error('Tried to delete undeleteable record')
  }

  // Possible that we already deleted this record when deleting other records
  if (r === undefined) return

  // If there are notes, delete those first
  if (isNoteableRecord(r)) {
    // Delete notes
    for (const noteId of r.notes) {
      await hardDeleteRecord(tx, noteId)
    }
    // We don't expect any "soft deleted" notes
  }

  // If there are children, delete those first
  if (isWithChildren(r)) {
    for (const childId of values(r.children)) {
      await hardDeleteRecord(tx, childId)
    }
    // May have soft-deleted children
    const softDeletedChildren = await getSoftDeletedRecordsWithParentId(
      tx,
      r.id,
    )
    for (const child of softDeletedChildren) {
      await hardDeleteRecord(tx, child.id)
    }
  }

  // Soft deleted records should already have references removed
  if (!r.isSoftDeleted) await removeReferences(tx, r)

  // Delete the record itself
  await deleteRecord(tx, recordId)
}

const getSoftDeletedRecordsWithParentId = async (
  tx: WriteTransaction,
  parentId: string,
): Promise<DeleteableRecord[]> => {
  const softDeletedRecords = await getSoftDeletedRecords(tx)
  return softDeletedRecords.filter((r) => r.parentId === parentId)
}

// Works for non-deleted and soft-deleted records
const removeReferences = async (tx: WriteTransaction, record: DataRecord) => {
  if (isNoteRecord(record)) {
    const noteableRecord = await getNoteableRecord(tx, record.parentId)
    await removeFromNotes(tx, noteableRecord, [record.id])
  }
  if (isParentableRecord(record) && record.parentId !== undefined) {
    const parent = await getRecordWithChildren(tx, record.parentId)
    await removeFromChildren(tx, parent, [record.id])
  }
}
