import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  ListChildComponentProps,
  VariableSizeList,
  VariableSizeListProps,
} from 'react-window'

import { css, customScrollbarStyles, cx, useTheme } from '../stationary'

type VariableVirtualListAutosizedProps = Omit<
  VariableSizeListProps,
  'height' | 'width' | 'children'
> & {
  Row: React.FC<ListChildComponentProps>
}

export const VariableVirtualListAutosized = React.forwardRef<
  VariableSizeList,
  VariableVirtualListAutosizedProps
>(
  (
    {
      itemCount,
      itemData,
      itemSize,
      estimatedItemSize,
      outerRef,
      Row,
      className,
    },
    ref,
  ) => {
    const theme = useTheme()

    return (
      // Need the defaults for dnd compatability
      <AutoSizer defaultHeight={1} defaultWidth={1}>
        {({ height, width }) => (
          <VariableSizeList
            height={height}
            itemCount={itemCount}
            itemData={itemData}
            itemSize={itemSize}
            estimatedItemSize={estimatedItemSize}
            outerRef={outerRef}
            className={cx(
              css(
                customScrollbarStyles({
                  thumbColor: theme.colors.alpha.tone.strong,
                  width: 8,
                }),
              ),
              `scroll`,
              className,
            )}
            ref={ref}
            width={width}
          >
            {Row}
          </VariableSizeList>
        )}
      </AutoSizer>
    )
  },
)
