import {
  InboxDestination,
  VIRTUAL_DESTINATION,
} from '../../../data/useDestinations/types'
import { InboxRecordId } from '@eleventhlabs/capture-shared'
import Analytics, {
  MoveToDestinationTypeValueType,
} from '../../capture-analytics-actions'

// Maps a given record ID or destination ID to the Analytics Destination Type
export const getRecordsMovedDestinationType = (
  recordIdOrDestinationId: string,
): MoveToDestinationTypeValueType => {
  if (isForInbox(recordIdOrDestinationId))
    return Analytics.MoveToDestinationType.INBOX

  if (isForNewUserList(recordIdOrDestinationId))
    return Analytics.MoveToDestinationType.NEW_USER_LIST

  if (isForUserList(recordIdOrDestinationId))
    return Analytics.MoveToDestinationType.USER_LIST

  throw new Error(``)
}

const isForInbox = (recordIdOrDestinationId: string): boolean => {
  return (
    recordIdOrDestinationId === InboxDestination ||
    recordIdOrDestinationId === InboxRecordId
  )
}

const isForNewUserList = (recordIdOrDestinationId: string): boolean => {
  return recordIdOrDestinationId === VIRTUAL_DESTINATION
}

const isForUserList = (recordIdOrDestinationId: string): boolean => {
  return (
    !isForInbox(recordIdOrDestinationId) &&
    !isForNewUserList(recordIdOrDestinationId)
  )
}
