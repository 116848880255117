import {
  SwitchUnstyled,
  SwitchUnstyledProps,
  switchUnstyledClasses,
} from '@mui/base'
import React from 'react'

import { styled } from '../styled'

const SwitchDimensions = {
  height: 16,
  width: 24,
  inset: 2,
}

const SwitchRoot = styled.span(({ theme }) => ({
  fontSize: 0,
  position: 'relative',
  width: SwitchDimensions.width,
  height: SwitchDimensions.height,
  cursor: 'pointer',

  [`&.${switchClasses.disabled}`]: {
    opacity: 0.6,
    cursor: 'not-allowed',
  },
  [`&.${switchClasses.checked}`]: {
    [`.${switchClasses.track}`]: {
      background: theme.colors.blue[700],
    },
    [`.${switchClasses.thumb}`]: {
      left:
        SwitchDimensions.width -
        (SwitchDimensions.height - SwitchDimensions.inset),
      backgroundColor: `#fff`,
    },
  },
}))

const SwitchTrack = styled.span(({ theme }) => ({
  background: theme.colors.alpha.tone.mediumStrong,
  borderRadius: 999,
  display: 'block',
  height: '100%',
  width: '100%',
  position: 'absolute',
}))

const SwitchThumb = styled.span({
  display: 'block',
  width: SwitchDimensions.height - SwitchDimensions.inset * 2,
  height: SwitchDimensions.height - SwitchDimensions.inset * 2,
  top: 2,
  left: 2,
  borderRadius: 999,
  backgroundColor: '#fff',
  position: 'relative',
  transition: 'left 0.2s ease',
})

const SwitchInput = styled.input({
  cursor: 'inherit',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  opacity: 0,
  zIndex: 1,
  margin: 0,
})

export type SwitchProps = SwitchUnstyledProps

export const Switch: React.FC<SwitchProps> = ({ slots, ...props }) => {
  return (
    <SwitchUnstyled
      slots={{
        root: SwitchRoot,
        track: SwitchTrack,
        thumb: SwitchThumb,
        input: SwitchInput,
        ...slots,
      }}
      {...props}
    />
  )
}

export const switchClasses = switchUnstyledClasses
