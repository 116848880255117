import { focusAction } from '../../../../events'
import { immediateExecute } from '../../../../utils'
import { createInputId } from '../../../../utils/input'

export const renameCollection = (listId?: string) => {
  if (!listId) return
  immediateExecute(() => {
    focusAction({ id: createInputId(listId) })
  })
}
