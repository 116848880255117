import React from 'react'

import {
  PlaceTag as PlaceTagData,
  isInboxTag,
  isListTag,
} from '../../../../common/data/tags'
import { Icon, styled, withTooltip } from '../../../../common/stationary'
import { RouteIcon } from '../../../RouteIcon'
import { RouteType } from '../../../../common/routes'

interface PlaceTagProps {
  tag: PlaceTagData
  emoji?: string
  text: string
  onClick: (e: any) => void
  className?: string
}

const PlaceTag = React.forwardRef<HTMLDivElement, PlaceTagProps>(
  ({ tag, emoji, text, onClick, className }, ref) => {
    return (
      <S.Container className={className} onClick={onClick} ref={ref}>
        {isListTag(tag) ? (
          <S.Emoji>
            <RouteIcon
              boxSize={16}
              routeType={RouteType.List}
              value={emoji ? { emoji } : undefined}
            />
          </S.Emoji>
        ) : null}
        {isInboxTag(tag) ? (
          <S.Inbox>
            <Icon boxSize={16} variant="glyphInboxSquircle" />
          </S.Inbox>
        ) : null}
        {text}
      </S.Container>
    )
  },
)

export const PlaceTagWithTooltip = withTooltip(PlaceTag)

const S = {
  Container: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    textAlign: `center`,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 500,
    lineHeight: `26px`,
    whiteSpace: `nowrap`,
    userSelect: `none`,
    padding: `0 6px`,
    color: theme.colors.text[700],
    border: `1px solid ${theme.colors.alpha.border.weak}`,
    boxShadow: theme.effects.boxShadow.elevation[0],
    borderRadius: 6,
    background: theme.colors.surface.base,
    opacity: 0.95,

    '&:hover': {
      color: theme.colors.text[900],
      opacity: 1,
    },
  })),
  Emoji: styled.div({
    display: `flex`,
    marginRight: 8,
  }),
  Inbox: styled.div(({ theme }) => ({
    display: `flex`,
    color: theme.colors.blue[700],
    marginRight: 8,
  })),
}
