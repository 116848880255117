import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { DebouncedFunc } from './DebounceFunc.type'

// Time that has to elapse since the last time the debounce function was invoked (we will save .5s after the user has stopped typing)
const ON_CHANGE_DEBOUNCE_TIMEOUT = 500
// maximum time that the function can be delayed before it's invoked (we want to make sure we save at least every 10s)
const ON_CHANGE_DEBOUNCE_MAX_WAIT = 5000

type HasOnChangeProps<T> = {
  id: string
  onChange?: (value: T) => void
}

export type EditorWithDebouncedOnChange<T> = {
  onChangeDebounced?: (value: T) => void
}

export const withDebounce = <T, S extends HasOnChangeProps<T>>(
  Component: React.FC<S>,
): React.FC<S & EditorWithDebouncedOnChange<T>> => {
  const WrappedComponent: React.FC<EditorWithDebouncedOnChange<T> & S> = ({
    id,
    onChange: handleOnChange,
    onChangeDebounced: handleOnChangeDebounced,
    ...props
  }: EditorWithDebouncedOnChange<T> & HasOnChangeProps<T>) => {
    const onChangedDebounced = useCallback(
      debounce(
        (v: T) => {
          handleOnChangeDebounced && handleOnChangeDebounced(v)
          DebouncedEditor.setPending(id, false)
        },
        ON_CHANGE_DEBOUNCE_TIMEOUT,
        {
          maxWait: ON_CHANGE_DEBOUNCE_MAX_WAIT,
        },
      ),
      [id, handleOnChangeDebounced],
    )
    useEffect(() => {
      DebouncedEditor.register(id, onChangedDebounced)
      return () => {
        DebouncedEditor.unregister(id)
      }
    }, [id, onChangedDebounced])
    useEffect(() => {
      return () => {
        onChangedDebounced.flush()
      }
    }, [onChangedDebounced])
    const onChange = useCallback(
      (v: T) => {
        DebouncedEditor.setPending(id, true)
        onChangedDebounced(v)
        handleOnChange && handleOnChange(v)
      },
      [onChangedDebounced, handleOnChange],
    )
    return <Component {...(props as S)} id={id} onChange={onChange} />
  }
  return WrappedComponent
}

const debouncedOnChange: { [id: string]: DebouncedFunc<(v: any) => void> } = {}
const pendingDict: { [id: string]: boolean } = {}
export const DebouncedEditor = {
  register(id: string, onChange: DebouncedFunc<(v: any) => void>) {
    debouncedOnChange[id] = onChange
  },
  cancelPending(id: string) {
    debouncedOnChange[id]?.cancel()
  },
  flushPending(id: string) {
    debouncedOnChange[id]?.flush()
  },
  unregister(id: string) {
    delete debouncedOnChange[id]
  },
  isPending(id: string) {
    return pendingDict[id] ?? false
  },
  setPending(id: string, value: boolean) {
    pendingDict[id] = value
  },
}
