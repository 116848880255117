import {
  CURRENT_RICH_TEXT_VERSION,
  CreateNoteRecordPayload,
  NoteRecord,
  RecordType,
} from '@eleventhlabs/capture-shared'
import { map } from 'lodash'

import {
  AnnotationCreatedTrigger,
  AnnotationCreatedTriggerValueType,
  ShowAnnotationsToggleTriggerValueType,
} from '../../analytics/capture-analytics-actions'
import { RecordItemTargetType } from '../../recordItem'
import { NoteListCommands, NoteListData } from './types'

export const getNextNote = (
  list: NoteListData,
  noteId: string,
): string | undefined => {
  const noteIds = map(list.notes, (n) => n.id)
  const index = noteIds.indexOf(noteId)
  return noteIds[index + 1]
}

export const getPrevNote = (
  list: NoteListData,
  noteId: string,
): string | undefined => {
  const noteIds = map(list.notes, (n) => n.id)
  const index = noteIds.indexOf(noteId)
  return noteIds[index - 1]
}

export const addNoteAtBottomOfList = (
  createNote: (payload: CreateNoteRecordPayload) => string,
  setNotesVisibilityForRecordIds: (
    ids: string[],
    visibility: boolean,
    trigger: ShowAnnotationsToggleTriggerValueType,
  ) => void,
  recordId: string,
  triggerShowAnnotation: ShowAnnotationsToggleTriggerValueType,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
): string => {
  setNotesVisibilityForRecordIds([recordId], true, triggerShowAnnotation)
  const newId = createNote({ type: RecordType.Note, parentId: recordId })
  // set recentlyCreatedRecords
  setRecentlyCreatedRecords((recentlyCreatedRecords) => ({
    ...recentlyCreatedRecords,
    [newId]: RecordItemTargetType.Text,
  }))
  return newId
}

export const addNotesToRecord = (
  recordId: string,
  notes: NoteRecord[],
  createNote: (payload: CreateNoteRecordPayload) => string,
) => {
  if (!recordId) return
  notes.forEach((note) => {
    createNote({
      type: RecordType.Note,
      parentId: recordId,
      richText: {
        value: note?.richText.value,
        version: CURRENT_RICH_TEXT_VERSION,
      },
    })
  })
}
