import React from 'react'

import {
  ClickOutsideEventType,
  useClickOutside,
} from '../../common/hooks/useClickOutside'
import { emptyFn, useForkRef } from '../../common/utils'

interface MustAcceptRef {
  ref: React.RefObject<HTMLElement>
}

interface ClickOutsideListenerProps {
  children: any & MustAcceptRef
  mouseEvent?: ClickOutsideEventType
  onClickOutside?: (event: MouseEvent) => void
}

export const ClickOutsideListener = ({
  children,
  mouseEvent = ClickOutsideEventType.OnMouseDown,
  onClickOutside = emptyFn,
}: ClickOutsideListenerProps) => {
  const { ref } = useClickOutside({ mouseEvent, onClickOutside })
  const handleRef = useForkRef(children.ref, ref)

  const childrenProps = { ref: handleRef }

  return (
    <React.Fragment>
      {React.cloneElement(children, childrenProps)}
    </React.Fragment>
  )
}
