import { RefObject, useEffect, useRef } from 'react'
import { HeaderDimension } from '../../components/Header'
import { EditorsManager } from '../EditorsManager'
import { RecordsManager, recordsToRefs } from '../RecordsManager'
import { useReactDnDContext } from '../contexts/ReactDnDContext'
import { useSelectionContext } from '../contexts/SelectionContext'
import { useTheme } from '../stationary'
import { useDragToSelectContext } from './DragToSelectContext'
import { DragToSelectManager } from './DragToSelectManager'

// Threshold below which we will skip setting the selection (in px)
const SKIP_SET_SELECTION_THRESHOLD = 1

export const useDragToSelectRecords = (
  dragContainerRef: RefObject<HTMLDivElement>,
) => {
  const { selection, focusedRecordId } = useSelectionContext()
  const { isDragIntent: isDnDStarted } = useReactDnDContext()
  const { setIsDragToSelectInProgress } = useDragToSelectContext()
  const theme = useTheme()

  const getRecordBoundingRectsWithScroll = () => {
    const recordRefDOM: Record<string, DOMRect> = {}
    for (const id in recordsToRefs) {
      const recordRef = RecordsManager.getById(id)
      if (recordRef && recordRef.current) {
        const recordRect = recordRef.current.getBoundingClientRect()
        const adjustedRecordRect = new DOMRect(
          recordRect.left + window.scrollX,
          recordRect.top + window.scrollY,
          recordRect.width,
          recordRect.height,
        )
        recordRefDOM[id] = adjustedRecordRect
      }
    }

    return recordRefDOM
  }

  // These are all refs because we need to keep track of these values changing within
  // the mouse handlers that get registered first in the useEffect closure, without
  // relying adding these values into the dep array
  const selectionRef = useRef(selection)
  let recordWidthsRef = useRef(getRecordBoundingRectsWithScroll())
  const focusedRecordIdRef = useRef(focusedRecordId)

  const container = dragContainerRef.current

  const dragToSelectManager = new DragToSelectManager(container)

  const intersects = (rect1: DOMRect, rect2: DOMRect) => {
    return !(
      rect2.left > rect1.right ||
      rect2.right < rect1.left ||
      rect2.top > rect1.bottom ||
      rect2.bottom < rect1.top
    )
  }

  const checkRecordsForIntersections = (skipSetSelection = false) => {
    const selectionBoxRect =
      dragToSelectManager.selectionBox.getBoundingClientRect()
    if (!selectionBoxRect) return

    const adjustedSelectionBoxRect = new DOMRect(
      selectionBoxRect.left + window.scrollX,
      selectionBoxRect.top + window.scrollY,
      selectionBoxRect.width,
      selectionBoxRect.height,
    )

    const newIds = new Set<string>([])
    for (const id in recordsToRefs) {
      const recordRect = recordWidthsRef.current[id]

      if (intersects(adjustedSelectionBoxRect, recordRect)) {
        newIds.add(id)
      } else {
        newIds.delete(id)
      }
    }

    // If this flag is passed in, do not set the selection
    if (skipSetSelection) return

    if (newIds.size === 0) {
      selectionRef.current.clear()
    } else {
      selectionRef.current.set(Array.from(newIds))
    }
  }

  // -------------- EVENT HANDLERS -------------- //

  const onMouseDown = (e: MouseEvent) => {
    if (!container) return

    // Calculate the currently rendered records' bounding rects when dragging starts
    recordWidthsRef.current = getRecordBoundingRectsWithScroll()

    // Make sure any previous selections are stopped
    dragToSelectManager.stopSelection()

    dragToSelectManager.startSelection(e.pageX, e.pageY)

    setIsDragToSelectInProgress(true)
  }

  let rafId: number | null = null

  const onMouseMove = (e: MouseEvent) => {
    if (
      !container ||
      !dragToSelectManager.selectionBox.exists() ||
      !dragToSelectManager.isSelecting()
    )
      return

    // If there is a record currently focused, stop dragToSelect
    if (focusedRecordIdRef.current) {
      dragToSelectManager.stopSelection()
      setIsDragToSelectInProgress(false)
      return
    }

    // Get selectionBox start and end from dragToSelectManager
    const sBoxStart = dragToSelectManager.selectionBox.getStart()
    const sBoxEnd = dragToSelectManager.selectionBox.getEnd()

    // Calculate if we should skipSetSelection
    const skipSetSelection =
      Math.abs(sBoxStart.x - sBoxEnd.x) <= SKIP_SET_SELECTION_THRESHOLD &&
      Math.abs(sBoxStart.y - sBoxEnd.y) <= SKIP_SET_SELECTION_THRESHOLD

    e.preventDefault()

    if (focusedRecordIdRef.current) {
      EditorsManager.effects.blur(focusedRecordIdRef.current)
    }

    const buffer = 50 // pixels from the edge of the window
    const scrollSpeed = 10 // pixels to scroll per frame

    let scrollDirection = 0 // 1 for down, -1 for up
    if (e.clientY < buffer + HeaderDimension.height) {
      scrollDirection = -1
    } else if (e.clientY > window.innerHeight - buffer) {
      scrollDirection = 1
    }

    if (scrollDirection !== 0) {
      window.scrollBy(0, scrollSpeed * scrollDirection)

      dragToSelectManager.selectionBox.setStyle({
        top: `${
          parseInt(dragToSelectManager.selectionBox.getStyle()?.top ?? '0') -
          scrollSpeed * scrollDirection
        }px`,
      })
    }

    if (rafId) {
      window.cancelAnimationFrame(rafId)
    }

    rafId = window.requestAnimationFrame(() => {
      dragToSelectManager.selectionBox.updatePositionStyle(
        e.clientX,
        e.clientY,
        theme,
      )
    })

    checkRecordsForIntersections(skipSetSelection)
  }

  const onMouseUp = () => {
    dragToSelectManager.stopSelection()

    setIsDragToSelectInProgress(false)
  }

  // -------------- EFFECTS -------------- //

  useEffect(() => {
    focusedRecordIdRef.current = focusedRecordId
  }, [focusedRecordId])

  useEffect(() => {
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        recordWidthsRef.current = getRecordBoundingRectsWithScroll()
      })
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (container && !isDnDStarted) {
      container.addEventListener('mousedown', onMouseDown)
      container.addEventListener('mousemove', onMouseMove)
      container.addEventListener('mouseup', onMouseUp)

      return () => {
        container.removeEventListener('mousedown', onMouseDown)
        container.removeEventListener('mousemove', onMouseMove)
        container.removeEventListener('mouseup', onMouseUp)
      }
    }
  }, [container, isDnDStarted])
}
