import React from 'react'
import { ListChildComponentProps } from 'react-window'

export const withVirtualListWrapper = <T,>(
  WrappedComponent: React.FC<Omit<T, 'style'>>,
) => {
  const WithVirtualListWrapper: React.FC<T & ListChildComponentProps> = (
    props,
  ) => {
    const { style, ...rest } = props
    return (
      <div style={style}>
        <WrappedComponent {...rest} />
      </div>
    )
  }
  WithVirtualListWrapper.displayName = `WithVirtualListWrapper`
  return WithVirtualListWrapper
}
