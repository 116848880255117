import React from 'react'

import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { RouteType } from '../../common/routes'
import InboxScreen from '../InboxScreen/InboxScreen'
import { WithModelStore, withModelStore } from '../enhancers/withData'
import { WithSelection, withSelection } from '../enhancers/withSelection'
import { withSideBar } from '../enhancers/withSideBar'
import { withSnackbar } from '../enhancers/withSnackbar'
import { WithUIContext, withUIContext } from '../enhancers/withUIContext'
import { ScreenProps } from '../types'

export type InboxScreenContainerProps = ScreenProps & RouteComponentProps

type InboxScreenContainerComponentProps = InboxScreenContainerProps &
  WithModelStore &
  WithUIContext &
  WithSelection

const InboxScreenContainer = (props: InboxScreenContainerComponentProps) => {
  return <InboxScreen {...props} routeType={RouteType.Inbox} />
}

export default withSnackbar(
  withSideBar(
    withUIContext(withModelStore(withSelection(InboxScreenContainer))),
  ),
)
