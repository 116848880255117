import { isEmpty } from 'lodash'
import React from 'react'
import { cx, styled } from '../../../../common/stationary'

export interface ResultItemContentActionProps {
  content: string
  isActive: boolean
}

const ResultItemContentAction = ({
  content,
  isActive,
}: ResultItemContentActionProps) => {
  return (
    <S.Content
      className={cx({
        empty: isEmpty(content),
        isActive,
      })}
    >
      {isEmpty(content) ? `No command description` : content}
    </S.Content>
  )
}

export default ResultItemContentAction

const S = {
  Content: styled.div(({ theme }) => ({
    display: `block`,
    flex: 1,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    fontWeight: 600,
    userSelect: `none`,
    paddingLeft: `18px`,
    paddingRight: `5px`,
    color: theme.colors.text[700],

    textOverflow: `ellipsis`,
    overflowX: `hidden`,
    whiteSpace: `nowrap`,

    '&.empty': {
      color: theme.colors.text[700],
    },

    '&.isActive': {
      color: theme.colors.text[900],
    },
  })),
}
