import React from 'react'

import { URLDescription } from './components/URLDescription'
import { URLDomain } from './components/URLDomain'
import { URLFavicon } from './components/URLFavicon'
import { URLThumbnail } from './components/URLThumbnail'
import { URLTitle } from './components/URLTitle'
import { cx, styled } from '../../../../common/stationary'

interface URLRecordItemContentProps {
  className?: string
  description?: string
  domain?: string
  favicon?: string
  hasTags: boolean
  id: string
  notesOpen: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
  onTitleClick?: (e: React.MouseEvent) => void
  style?: any
  thumbnail?: string
  title?: string
  url?: string
}

export const URLRecordItemContent: React.FC<URLRecordItemContentProps> = ({
  className,
  description,
  domain,
  favicon,
  hasTags,
  notesOpen,
  onClick,
  onMouseDown,
  onTitleClick,
  thumbnail,
  title,
  url,
}) => {
  const loading = !url

  return (
    <S.Container
      className={cx(className, {
        validURL: !!url,
        loading,
      })}
      title={url}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      <S.Content className={cx({ hasTags })}>
        <S.ContentRow>
          <URLTitle
            loading={loading}
            onClick={onTitleClick}
            title={title}
            url={url}
          />
        </S.ContentRow>
        <S.ContentRow>
          <URLFavicon favicon={favicon} loading={loading} />
          <URLDomain domain={domain} loading={loading} />
          <URLDescription description={description} loading={loading} />
        </S.ContentRow>
      </S.Content>
      <S.RightSide className={cx({ hasTags })}>
        <URLThumbnail
          thumbnail={thumbnail}
          loading={loading}
          notesOpen={notesOpen}
        />
      </S.RightSide>
    </S.Container>
  )
}

const S = {
  Container: styled.div({
    display: `flex`,
    width: `100%`,
    maxWidth: `100%`,
    flexDirection: `row`,
    userSelect: `none`,
  }),
  Content: styled.div({
    display: `flex`,
    flex: 1,
    height: `100%`,
    minWidth: 0,
    flexDirection: `column`,
    padding: 24,

    '&.hasTags': {
      padding: `24px 24px 48px 24px`,
    },
  }),
  ContentRow: styled.div({
    display: `flex`,
    flex: 1,
    width: `100%`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
  }),
  RightSide: styled.div({
    display: `flex`,
    flex: `0 0 192px`,
    height: `96px`,
    flexDirection: `row`,

    '&.hasTags': {
      height: `120px`,
    },
  }),
}
