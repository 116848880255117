import { format, isValid } from 'date-fns'
import { filter, isUndefined } from 'lodash'
import { useCallback, useMemo } from 'react'
import Sugar from 'sugar'

import { createLocalDestination } from '../../../common/data/useDestinations'
import { Destinations } from '../../../common/data/useDestinations/types'
import { alphabeticallyAscending } from '../../../common/data/utils'
import { DestinationGroups } from '../../../common/hooks/useSendToDestinations'
import { tokenFormatISO } from '../../../common/utils'

export const useDestinationSearch = (
  destinations: Destinations,
  groups: DestinationGroups,
  query: string,
) => {
  let queryDate: Date | undefined = Sugar.Date.create(query)
  queryDate = isValid(queryDate) ? queryDate : undefined

  const filterByQuery = useCallback(
    (resultId: string) => {
      const queryLCase = query.toLowerCase().trim()
      const result = destinations[resultId]
      if (!result) return false
      return (
        queryLCase === `` || result.title.toLowerCase().includes(queryLCase)
      )
    },
    [query, destinations],
  )

  const recentIds = useMemo(
    () => groups.recents.resultsList.filter(filterByQuery),
    [groups.recents.resultsList, filterByQuery],
  )
  const destinationIds = useMemo(
    () => [
      ...groups.results.resultsList
        .filter(filterByQuery)
        .sort((a, b) =>
          alphabeticallyAscending(destinations[a].title, destinations[b].title),
        ),
    ],
    [destinations, groups.results.resultsList, filterByQuery],
  )

  const results = useMemo(
    () => ({
      ...destinations,
    }),
    [destinations],
  )

  const itemIds = filter(
    [
      ...recentIds.map((recentId) =>
        destinationIds.find((destinationId) => destinationId === recentId),
      ),
      ...destinationIds.filter((id) => !recentIds.includes(id)),
    ],
    (id) => !isUndefined(id),
  ) as string[]

  return { itemIds, results }
}

export default useDestinationSearch
