import React, { useState } from 'react'
import { Transition } from 'react-transition-group'

import { SlideProps } from '.'
import { cx, styled } from '../../../../common/stationary'
import { isAndroid, isIOS, isMobile } from '../../../../common/utils/env'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideSecondaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/ImmersiveSlide'

import { Browser } from '@capacitor/browser'
import iOSContactCardSrc from '../../../../assets/onboarding/diagram-capture-contact-ios@2x.png'
import androidContactCardSrc from '../../../../assets/onboarding/diagram-capture-contact-android@2x.png'
import SiriIcon from '../../../../assets/onboarding/siri-icon.png'
import GoogleAssistantIcon from '../../../../assets/onboarding/google-assistant-icon.png'
import { Env } from '../../../../common/env'
import { withSearchParams } from '../../../../common/urls'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import SlideModalOverlay from './components/SlideModalOverlay'
import ConfirmationModal from '../ConfirmationModal'

export enum AddToContactsPlatform {
  IOS = 'iOS',
  Android = 'Android',
  None = 'None',
}

export const AddToContacts: React.FC<SlideProps> = ({ goForward }) => {
  const [isConfirmSkipModalOpen, setIsConfirmSkipModalOpen] =
    useState<boolean>(false)

  const contactCardSrc = isIOS ? iOSContactCardSrc : androidContactCardSrc
  const { onboardingAnalytics, setDidSkipAddingContact } =
    useOnboardingContext()

  const onPrimaryButtonClick = () => {
    Browser.open({
      url: withSearchParams(`${Env.captureWebUrl}/onboardingDownloadContact`, {
        platform: isIOS
          ? AddToContactsPlatform.IOS
          : isAndroid
          ? AddToContactsPlatform.Android
          : AddToContactsPlatform.None,
      }),
    })

    Browser.addListener('browserFinished', () => {
      setTimeout(() => {
        Browser.removeAllListeners().then(() => {
          goForward()
        })
      }, 500)
    })
  }

  const onSkipButtonClicked = () => {
    onboardingAnalytics.action('Add to contacts skipped')

    setIsConfirmSkipModalOpen(true)
  }

  const voiceAssistantIcon = isIOS ? SiriIcon : GoogleAssistantIcon

  const getContactsAppAndVoiceAssistantString = () => {
    return isIOS ? 'iMessage and Siri' : 'Messages and Google Assistant'
  }

  return (
    <S.IntroTextYourself>
      <S.Inner>
        <TransitionSlideContainer>
          <SlideIntro>
            <SlideTitle>Add to contacts</SlideTitle>
            <S.SlideDescription>
              {`The quickest way to find Capture in ${getContactsAppAndVoiceAssistantString()}.`}
            </S.SlideDescription>
            <SlideButtonGroup>
              <SlideSecondaryButton onClick={onSkipButtonClicked}>
                <strong>Skip for now</strong>
              </SlideSecondaryButton>
              <SlidePrimaryButton onClick={onPrimaryButtonClick}>
                <strong>View instructions</strong>
              </SlidePrimaryButton>
            </SlideButtonGroup>
          </SlideIntro>
          <SlideDiagrams>
            <Transition appear in timeout={0}>
              {(state) => (
                <S.Diagram
                  style={{
                    opacity: state === 'entering' ? 0 : 1,
                    pointerEvents: state === 'entering' ? 'none' : 'initial',
                  }}
                >
                  <S.ContactWrapper>
                    <S.VoiceAssistantPrompt>
                      <S.VoiceAssistantIcon src={voiceAssistantIcon} />
                      e.g. “<strong>
                        Hey {isIOS ? 'Siri' : 'Google'},
                      </strong>{' '}
                      text Capture...”
                    </S.VoiceAssistantPrompt>
                    <S.Contact
                      src={contactCardSrc}
                      srcSet={`${contactCardSrc} 2x`}
                    />
                  </S.ContactWrapper>
                </S.Diagram>
              )}
            </Transition>
          </SlideDiagrams>
        </TransitionSlideContainer>
      </S.Inner>
      <SlideModalOverlay
        className={cx({
          isVisible: isConfirmSkipModalOpen,
        })}
      />
      {isConfirmSkipModalOpen && (
        <ConfirmationModal
          goForward={goForward}
          closeModal={() => setIsConfirmSkipModalOpen(false)}
          config={{
            headerContent: 'Skip adding Capture to contacts?',
            bodyContent: (
              <>
                Adding Capture to contacts will allow you to{' '}
                <strong>send texts to Capture</strong> using{' '}
                {getContactsAppAndVoiceAssistantString()}.
              </>
            ),
            leftButton: {
              content: 'Go back',
            },
            rightButton: {
              content: 'Skip',
              onClick: () => setDidSkipAddingContact(true),
              goForwardOnClick: true,
              isDanger: true,
            },
          }}
        />
      )}
    </S.IntroTextYourself>
  )
}

const S = {
  IntroTextYourself: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',

    ...(isMobile && {
      padding: '0 24px',
    }),
  })),
  Inner: styled.div(() => ({
    width: '100%',
    maxWidth: 768,
  })),
  Diagram: styled.div(() => ({
    position: 'relative',
    zIndex: 1,

    transition: 'height 0.8s ease, opacity 0.4s ease',
  })),
  ContactWrapper: styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  })),
  Contact: styled.img(({ theme }) => ({
    boxShadow: theme.effects.boxShadow.elevation[4],
    borderRadius: 8,
  })),
  SlideDescription: styled(SlideDescription)(() => ({
    paddingLeft: isAndroid ? 30 : 10,
    paddingRight: isAndroid ? 30 : 10,
  })),
  VoiceAssistantIcon: styled.img(() => ({
    width: 24,
    height: 24,
    margin: 8,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  })),
  VoiceAssistantPrompt: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 999,
    border: `1px solid rgba(255, 255, 255, 0.10)`,
    background: `rgba(255, 255, 255, 0.80)`,
    boxShadow: theme.effects.boxShadow.elevation[4],
    backdropFilter: `blur(16px)`,
    width: 272,
    height: 40,
    flexShrink: 0,
    marginBottom: -8,

    ...theme.text.publicSans['13.5:20'],
    textAlign: 'right',
    color: 'black',
    pointerEvents: 'none',

    strong: {
      fontWeight: 700,
    },
  })),
}
