import React from 'react'

import { getListId } from '../../common/routes'
import { cx, styled } from '../../common/stationary'
import { InviteCTA } from './components/InviteCTA'
import { Category, Outline } from './components/Outline'
import { Shortcut, ShortcutGroup } from './components/ShortcutGroup'
import { SideBarHeader } from './components/SideBarHeader'

interface SideBarProps {
  activeCategoryId: string
  activeShortcutsIndex: number
  categories: Category[]
  isHidden: boolean
  listId?: string
  onToggleButtonClick: React.MouseEventHandler
  shortcuts: Shortcut[]
  onTouchStart?: (event: React.TouchEvent) => void
  onTouchMove?: (event: React.TouchEvent) => void
}

export const SideBar: React.FC<SideBarProps> = ({
  activeCategoryId,
  activeShortcutsIndex,
  categories,
  isHidden,
  listId,
  onToggleButtonClick,
  shortcuts,
  onTouchStart,
  onTouchMove,
}) => {
  const activeListId = listId ? getListId(listId) : undefined

  return (
    <S.SideBar
      className={cx({ isHidden })}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
    >
      <SideBarHeader onToggleButtonClick={onToggleButtonClick} />
      <S.SideBarSection>
        <S.ShortcutGroupTop
          activeIndex={activeShortcutsIndex}
          shortcuts={shortcuts}
          placement="top"
        />
      </S.SideBarSection>
      <S.SideBarSpacer />
      <S.SideBarSection className="isGrowable">
        <S.Outline
          activeCategoryId={activeCategoryId}
          activeListId={activeListId}
          categories={categories}
        />
      </S.SideBarSection>
      <S.SideBarSpacer />
      <S.SideBarSection>
        <S.ShortcutGroupBottom
          activeIndex={activeShortcutsIndex}
          shortcuts={shortcuts}
          placement="bottom"
        />
      </S.SideBarSection>
      <S.SideBarDivider />
      <S.SideBarSection>
        <InviteCTA />
      </S.SideBarSection>
    </S.SideBar>
  )
}

export const SideBarDimensions = {
  width: 288,
}

const S = {
  SideBar: styled.aside(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: SideBarDimensions.width,
    background: theme.colors.surface.lowest,

    '&.isHidden': {
      opacity: 0,
    },
  })),
  SideBarSection: styled.section({
    position: 'relative',
    zIndex: 999,

    '&.isGrowable': {
      flex: 1,
      minHeight: 0,
    },
  }),
  SideBarSpacer: styled.div(() => ({
    height: 0,
    margin: '0 20px',
  })),
  SideBarDivider: styled.div(({ theme }) => ({
    height: 1,
    margin: '0 20px',
    background: theme.colors.alpha.tone.mediumWeak,
  })),
  ShortcutGroupTop: styled(ShortcutGroup)({}),
  ShortcutGroupBottom: styled(ShortcutGroup)({}),
  Outline: styled(Outline)({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
}
