import React from 'react'
import { useOnboardingScreen } from './hooks/useOnboardingScreen'

type UseOnboardingScreen = ReturnType<typeof useOnboardingScreen>

export const OnboardingScreenContext = React.createContext<
  UseOnboardingScreen | undefined
>(undefined)

export const useOnboardingContext = () => {
  const context = React.useContext(OnboardingScreenContext)
  if (!context) {
    throw new Error(
      `useOnboardingContext must be used within a OnboardingScreenProvider`,
    )
  }
  return context
}
