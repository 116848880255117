import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { isEmpty } from 'lodash'
import React, { FC, useEffect, useRef } from 'react'
import * as z from 'zod'
import LoadingScreen from '../../screens/LoadingScreen'
import * as Analytics from '../analytics/capture-analytics-actions'
import {
  SessionTokens,
  StytchTokenType,
  useAuth,
} from '../contexts/AuthContext'
import { Env } from '../env'
import { safeNavigate } from '../navigate'
import { LoginRoutePath, RouteType, RoutesPath } from '../routes'
import { authenticateApiCall } from './authenticateApiCall'
import { getBooleanQueryParam, getQueryParams } from './utils'

export const AuthenticateRoute: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const params = getQueryParams()

  const isEmailVerification = getBooleanQueryParam('isEmailVerification')
  const emailVerificationAuth = useEmailVerificationAuth()

  const isRegisterWithGoogle = getBooleanQueryParam('isRegisterWithGoogle')
  const registerWithGoogleAuth = useRegisterWithGoogleAuth()

  const token = params['token']
  const tokenType = params['stytch_token_type']

  const isMagicLinkAuth = token && tokenType === StytchTokenType.MAGIC_LINK
  const magicLinkAuth = useMagicLinkAuth()

  const isOAuthAuth = token && tokenType === StytchTokenType.OAUTH
  const oauthAuth = useOAuthAuth()

  const desktopCodeChallenge = params['desktop_code_challenge']

  const isAuthenticating = useRef(false)

  useEffect(() => {
    Analytics.authenticateScreenLoaded()
  }, [])

  useEffect(() => {
    if (isAuthenticating.current === true) return
    isAuthenticating.current = true

    if (isEmailVerification) {
      emailVerificationAuth()
      return
    }

    if (isRegisterWithGoogle) {
      registerWithGoogleAuth()
      return
    }

    if (isMagicLinkAuth) {
      magicLinkAuth()
      return
    }

    if (isOAuthAuth) {
      oauthAuth()
      return
    }
  }, [
    emailVerificationAuth,
    isEmailVerification,
    isMagicLinkAuth,
    isOAuthAuth,
    isRegisterWithGoogle,
    magicLinkAuth,
    oauthAuth,
  ])
  return <LoadingScreen />
}

// --------------------
// EMAIL VERIFICATION
// --------------------

function useEmailVerificationAuth() {
  // When password is created before email verification,
  //   email must happen in same session as password creation.
  //   getSessionTokens gets the tokens created during password creation
  //   to send to the backend.
  const { authenticateViaSessionTokens, getSessionTokens } = useAuth()
  const createPasswordSessionTokens = getSessionTokens()
  const emailVerificationAuth = async () => {
    try {
      const sessionTokens =
        await exchangeEmailVerificationTokenForSessionTokens(
          createPasswordSessionTokens,
        )
      await authenticateViaSessionTokens(sessionTokens)
      safeNavigate(RoutesPath[RouteType.Inbox])
    } catch (error) {
      trackEmailVerificationError(error)
    }
  }
  return emailVerificationAuth
}

async function exchangeEmailVerificationTokenForSessionTokens(
  createPasswordSessionTokens: SessionTokens | null,
) {
  const result = await authenticateApiCall({
    sessionTokens: createPasswordSessionTokens ?? undefined,
  })
  if (result.isErr()) throw result.error

  const parseResult = emailVerificationAuthResponseSchema.parse(result)
  if (!parseResult.isVerified) {
    throw Error('Email still unverified after /authetnicate request')
  } else return parseResult
}

const emailVerificationAuthResponseSchema = z.union([
  z.object({ isVerified: z.literal(false) }),
  z.object({
    isVerified: z.literal(true),
    session_token: z.string(),
    session_jwt: z.string(),
  }),
])

function trackEmailVerificationError(error: unknown) {
  const err =
    error instanceof Error
      ? error
      : { name: 'Unknown error', message: String(error) }
  Analytics.authenticateEmailVerificationFailed({
    errorName: err.name,
    errorMessage: err.message,
  })
}

// --------------------
// REGISTER WITH GOOGLE
// --------------------

function useRegisterWithGoogleAuth() {
  const { authenticateViaSessionTokens } = useAuth()
  async function registerWithGoogleAuth() {
    try {
      const sessionTokens = await exchangeGoogleOAuthTokenForSessionTokens()
      await authenticateViaSessionTokens(sessionTokens)
      safeNavigate(RoutesPath[RouteType.Inbox])
    } catch (error) {
      trackRegisterWithGoogleError(error)
    }
  }
  return registerWithGoogleAuth
}

async function exchangeGoogleOAuthTokenForSessionTokens() {
  const code_verifier = getStytchPKCEStateFromLocalStorage().code_verifier
  const result = await authenticateApiCall({ code_verifier })
  if (result.isErr()) throw result.error
  return registerWithGoogleAuthResponseSchema.parse(result.value)
}

const registerWithGoogleAuthResponseSchema = z.object({
  session_token: z.string(),
  session_jwt: z.string(),
})

function trackRegisterWithGoogleError(error: unknown) {
  const err =
    error instanceof Error
      ? error
      : { name: 'Unknown error', message: String(error) }
  Analytics.authenticateRegisterWithGoogleFailed({
    errorName: err.name,
    errorMessage: err.message,
  })
}

// -----------------------------
// PKCE FOR REGISTER WITH GOOGLE
// -----------------------------
// Google OAuth is initiated on frontend by Stytch SDK.
// Code verifier for PKCE flow is generated by Stytch,
//   and stored in localStorage.
// Need the code_verifier to consume the token on the backend.
// In the future, Stytch SDK will expose a method to get the token:
// https://stytch.slack.com/archives/C015UDB4X33/p1700517101819509?thread_ts=1700516675.762789&cid=C015UDB4X33

function getStytchPKCEStateFromLocalStorage() {
  const STYTCH_PKCE_VERIFIED_KEY = `stytch_sdk_state_${Env.stytchPublicToken}::PKCE_VERIFIER:oauth`
  const localStorageValue = localStorage.getItem(STYTCH_PKCE_VERIFIED_KEY)
  return stytchPkceVerifierStateSchema.parse(
    JSON.parse(localStorageValue ?? ''),
  )
}

const stytchPkceVerifierStateSchema = z.object({
  code_challenge: z.string(),
  code_verifier: z.string(),
})

// -- End PKCE for register with Google

function useMagicLinkAuth() {
  const { authenticateViaMagicLink } = useAuth()
  const params = getQueryParams()
  const token = params['token']
  const desktopCodeChallenge = params['desktop_code_challenge']
  const fn = async () => {
    if (typeof token !== 'string') return
    authenticateViaMagicLink(token)
      .then(() => {
        const redirectPath = !isEmpty(desktopCodeChallenge)
          ? `${LoginRoutePath}?isDesktopAuth=true&code_challenge=${desktopCodeChallenge}`
          : RoutesPath[RouteType.Inbox]
        safeNavigate(redirectPath)
      })
      .catch(console.error)
  }
  return fn
}

function useOAuthAuth() {
  const { authenticateViaOAuth } = useAuth()
  const params = getQueryParams()
  const token = params['token']
  const desktopCodeChallenge = params['desktop_code_challenge']
  const fn = async () => {
    if (typeof token !== 'string') return
    authenticateViaOAuth(token)
      .then(() => {
        const redirectPath = !isEmpty(desktopCodeChallenge)
          ? `${LoginRoutePath}?isDesktopAuth=true&code_challenge=${desktopCodeChallenge}`
          : RoutesPath[RouteType.Inbox]
        safeNavigate(redirectPath)
      })
      .catch(console.error)
  }
  return fn
}
