import { ApiEndpoints } from '../ApiEndpoints'
import { apiCall } from './client'

export const setClientTimezone = async (clientID: string, timezone: string) => {
  try {
    await apiCall(`${ApiEndpoints.rpcURI}/setClientTimezone`, {
      method: `POST`,
      mode: `cors`,
      headers: {
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({ clientID, timezone }),
    })
  } catch (e) {
    console.error(e)
  }
}
