import React from 'react'

import { useIsOnboardingCompleted } from '../../common/onboarding'
import { useReplicacheContext } from '../../common/replicache/ReplicacheContext'
import { isMobileWeb } from '../../common/utils/env'
import { MobileDownloadModal } from '../../components/MobileDownloadModal'

export function withMobileDownloadModal<T extends {}>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const ComponentWithMobileDownloadModal = (props: T) => {
    const { isReady } = useReplicacheContext()
    const { isOpen } = useIsMobileDownloadModalOpen()
    return (
      <>
        <WrappedComponent {...props} />
        {isReady ? <MobileDownloadModal open={isOpen} /> : null}
      </>
    )
  }

  ComponentWithMobileDownloadModal.displayName = `ComponentWithMobileDownloadModal`

  return ComponentWithMobileDownloadModal
}

const useIsMobileDownloadModalOpen = () => {
  // Don't trust value until replicacheContext isReady is true
  const isOnboardingCompleted = useIsOnboardingCompleted()

  // The value if the user doesn't explictly close the modal
  const isOpen = isMobileWeb && !isOnboardingCompleted

  return {
    isOpen,
  }
}
