import {
  InboxRecordId,
  Reminder,
  RootRecordId,
  Tickler,
} from '@eleventhlabs/capture-shared'

export enum TagType {
  DATE = `DATE`,
  PLACE = `PLACE`,
  NOTES = `NOTES`,
}

interface BaseTag {
  type: TagType
  text: string
}

export interface DateTag extends BaseTag {
  type: TagType.DATE
  date: Tickler
  reminder?: Reminder
}

export interface PlaceTag extends BaseTag {
  type: TagType.PLACE
  parentId: string
  emoji?: string
  isParentList?: boolean
}

export const isInboxTag = (t: PlaceTag) => t.parentId === InboxRecordId
export const isRootTag = (t: PlaceTag) => t.parentId === RootRecordId
export const isListTag = (t: PlaceTag) => t.isParentList === true

export interface NotesTag extends BaseTag {
  type: TagType.NOTES
}

export type Tag = DateTag | PlaceTag | NotesTag

export type WithTags = { tags: Tag[] }
