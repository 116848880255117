import * as React from 'react'

function useForkRef<T>(
  refA:
    | React.MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  refB:
    | React.MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
) {
  return React.useMemo(() => {
    if (refA == null && refB == null) {
      return null
    }
    return (refValue: T) => {
      setRef(refA, refValue)
      setRef(refB, refValue)
    }
  }, [refA, refB])
}

function setRef<T>(
  ref:
    | React.MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T | null,
): void {
  if (typeof ref === `function`) {
    ref(value)
  } else if (ref) {
    ref.current = value
  }
}

export default useForkRef
