import styled from '@emotion/styled'
import React, { Fragment } from 'react'
import { getShortcutKeys } from '../../common/hooks/useKeys'
import { ShortcutItem } from './ShortcutItem'

export interface ShortcutsListProps {
  shortcuts: string[]
}

export const ShortcutsList: React.FC<ShortcutsListProps> = ({ shortcuts }) => {
  return (
    <>
      {shortcuts.map((keys, shortcutIndex) => {
        return (
          <Fragment key={shortcutIndex}>
            {getShortcutKeys(keys).map((key, keyIndex) => (
              <S.Key key={`${key}+${keyIndex}`} keyString={key} />
            ))}
            {shortcutIndex !== shortcuts.length - 1 && (
              <S.Delimiter key={shortcutIndex}>or</S.Delimiter>
            )}
          </Fragment>
        )
      })}
    </>
  )
}

const S = {
  Key: styled(ShortcutItem)({
    '&:last-of-type': {
      marginRight: 20,
    },
  }),
  Delimiter: styled.div(({ theme }) => ({
    fontWeight: 500,
    padding: `2px 6px`,
    color: theme.colors.text[700],
    boxSizing: `border-box`,
    borderRadius: 4,
    marginRight: `-4px`,
  })),
}
