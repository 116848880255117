import { QRCodeSVG } from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { SlideProps } from '.'
import diagramAndroidAppSrc from '../../../../assets/onboarding/diagram-android-app@2x.png'
import diagramIOSAppSrc from '../../../../assets/onboarding/diagram-ios-app@2x.png'
import appleIconSrc from '../../../../assets/onboarding/apple-icon-small.png'
import androidIconSrc from '../../../../assets/onboarding/android-icon-small.png'
import InstallArrowWE from '../../../../assets/onboarding/install-arrow-w-e.svg'
import diagramTestflight from '../../../../assets/onboarding/diagram-testflight@2x.png'
import { Key, useKeys } from '../../../../common/hooks/useKeys'
import { Icon, cx, styled } from '../../../../common/stationary'
import { WSEventName, ws } from '../../../../common/websocket'
import { useAuth } from '../../../../providers'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideSecondaryAction,
  SlideTitle,
  SlideWaitingHint,
  SlideWaitingSpinner,
  TransitionSlideContainer,
} from './variants/SplitCardSlide'
import { useReward } from 'react-rewards'

type PlatformState = 'iOS' | 'Android'
type InstallState =
  | 'WaitingForScan'
  | 'FinishInstall'
  | 'WaitingForTestingSetup' // Show additional instructions for installing the app on the platform
  | 'FinishedTestingSetup'

export const InstallMobileApp: React.FC<SlideProps> = ({ goForward }) => {
  const [platformState, setPlatformState] = useState<PlatformState>('iOS')
  const [installState, setInstallState] =
    useState<InstallState>('WaitingForScan')

  const { onboardingAnalytics } = useOnboardingContext()

  const onMobileTestingSetupConfirmClick = () => {
    setInstallState((installState) => {
      if (installState === 'WaitingForTestingSetup') {
        onboardingAnalytics.action('Set up Testflight confirmed')

        return 'FinishedTestingSetup'
      } else {
        return 'WaitingForTestingSetup'
      }
    })
  }

  const { userId } = useAuth()

  const { reward: emojiReward } = useReward('emojiRewardId', 'emoji', {
    emoji: ['✅'],
    zIndex: 1,
  })

  useEffect(() => {
    if (installState === 'FinishInstall') emojiReward()
  }, [installState])

  useKeys(Key.Enter, () => {
    if (installState === 'FinishInstall' && platformState === 'iOS') {
      setInstallState('WaitingForTestingSetup')
    } else if (
      (installState === 'FinishInstall' && platformState === 'Android') ||
      installState === 'FinishedTestingSetup'
    ) {
      goForward()
    }
  })

  useEffect(() => {
    if (userId) {
      const channel = ws.bindEvent(userId, {
        eventName: WSEventName.ONBOARDING_QR_SCANNED,
        callback: () => {
          setInstallState('FinishInstall')
        },
      })

      return () => {
        channel?.unbind(WSEventName.ONBOARDING_QR_SCANNED)
      }
    }
  }, [userId])

  const getActivePlatformTitle = () =>
    platformState === 'iOS' ? 'iOS' : 'Android'

  const getInactivePlatformTitle = () =>
    platformState === 'iOS' ? 'Android' : 'iOS'

  const togglePlatformState = () => {
    setPlatformState(platformState === 'iOS' ? 'Android' : 'iOS')
    setInstallState('WaitingForScan')
  }

  const { iOSQRCodeLink, androidQRCodeLink } = useOnboardingContext()
  const getActivePlatformDownloadUrl = () => {
    if (platformState === 'iOS') return iOSQRCodeLink
    if (platformState === 'Android') return androidQRCodeLink
  }

  const getActivePlatformDiagramSrc = () => {
    if (platformState === 'iOS') return diagramIOSAppSrc
    if (platformState === 'Android') return diagramAndroidAppSrc
  }

  const getInActivePlatformIconSrc = () => {
    if (platformState === 'iOS') return androidIconSrc
    if (platformState === 'Android') return appleIconSrc
  }

  const onInstallContinueClick = () => {
    if (platformState === 'iOS') {
      setInstallState('WaitingForTestingSetup')
    } else {
      goForward()
    }
  }

  const onAdditionalMobileInstallSetupStep =
    installState === 'WaitingForTestingSetup' ||
    installState === 'FinishedTestingSetup'

  const installMobileAppSlideContent = (
    <>
      <SlideIntro>
        <SlideTitle>Mobile app</SlideTitle>
        <SlideDescription>
          Capture on the go with our <strong>“text yourself”</strong> feature,
          voice assistant commands, and push notification reminders.
        </SlideDescription>
        <SlideWaitingHint
          className={cx({ isSuccess: installState === 'FinishInstall' })}
          id="emojiRewardId"
        >
          <span>
            Scan QR to <strong>download for {getActivePlatformTitle()}</strong>
          </span>
          {installState !== 'FinishInstall' ? (
            <SlideWaitingSpinner
              boxSize={16}
              variant="glyphCircleThreeQuarters"
            />
          ) : (
            <span>✅</span>
          )}
        </SlideWaitingHint>
        <SlideSecondaryAction
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          or
          <S.InactivePlatformCTA onClick={() => togglePlatformState()}>
            download for
            <S.InactivePlatformName
              style={{
                color: platformState === 'iOS' ? '#A4C639' : 'black',
              }}
            >
              &nbsp;
              {getInactivePlatformTitle()}
            </S.InactivePlatformName>
            <S.InactivePlatformIcon
              src={getInActivePlatformIconSrc()}
              srcSet={`${getInActivePlatformIconSrc()} 2x`}
            />
          </S.InactivePlatformCTA>
        </SlideSecondaryAction>
        <SlideButtonGroup
          style={{
            display: installState === 'FinishInstall' ? 'none' : undefined,
          }}
        >
          <Transition appear in timeout={4000}>
            {(state) => (
              <SlideSecondaryAction
                style={{
                  opacity: state === 'entering' ? 0 : 1,
                  pointerEvents: state === 'entering' ? 'none' : 'auto',
                  transition: 'opacity 0.8s ease',
                }}
              >
                <a
                  className={cx('isItalic')}
                  onClick={() => {
                    goForward()
                    onboardingAnalytics.action('Mobile Skip For Now')
                  }}
                >
                  I don't have access to my phone
                </a>
              </SlideSecondaryAction>
            )}
          </Transition>
        </SlideButtonGroup>
        <SlideButtonGroup
          style={{
            display: installState !== 'FinishInstall' ? 'none' : undefined,
          }}
        >
          <SlidePrimaryButton onClick={onInstallContinueClick}>
            <strong>Continue</strong>
          </SlidePrimaryButton>
        </SlideButtonGroup>
      </SlideIntro>
      <SlideDiagrams>
        <img
          src={getActivePlatformDiagramSrc()}
          srcSet={`${getActivePlatformDiagramSrc()} 2x`}
        />
        <S.QRScanInstructions>Scan here</S.QRScanInstructions>
        <S.QRCode value={getActivePlatformDownloadUrl() ?? ''} />
        <S.InstallArrowWE />
      </SlideDiagrams>
    </>
  )

  const testingSetupSlideContent = (
    <>
      <SlideIntro>
        <SlideTitle>Set up Testflight</SlideTitle>
        <SlideDescription style={{ marginBottom: 16 }}>
          If you haven’t already, you’ll need to set up Apple’s TestFlight
          software before you can install Capture for iPhone & iPad.
        </SlideDescription>
        <SlideDescription style={{ marginBottom: 18 }}>
          The QR code from the previous step should have opened the setup
          instructions — if you need to see the QR again,{' '}
          <S.GoBackToQrCodeText
            onClick={() => setInstallState('WaitingForScan')}
          >
            click here.
          </S.GoBackToQrCodeText>
        </SlideDescription>
        <S.CheckPinnedWrapper onClick={onMobileTestingSetupConfirmClick}>
          <S.Checkbox>
            {installState === 'FinishedTestingSetup' && (
              <Icon boxSize={18} variant="glyphCheck" />
            )}
          </S.Checkbox>
          <strong>I've set up Testflight</strong>
        </S.CheckPinnedWrapper>
        <SlideButtonGroup>
          <SlidePrimaryButton
            onClick={() => goForward()}
            disabled={installState !== 'FinishedTestingSetup'}
          >
            <strong>Continue</strong>
          </SlidePrimaryButton>
        </SlideButtonGroup>
      </SlideIntro>
      <SlideDiagrams>
        <img src={diagramTestflight} srcSet={`${diagramTestflight} 2x`} />
      </SlideDiagrams>
    </>
  )

  return (
    <S.InstallMobileApp>
      <TransitionSlideContainer>
        {onAdditionalMobileInstallSetupStep
          ? testingSetupSlideContent
          : installMobileAppSlideContent}
      </TransitionSlideContainer>
    </S.InstallMobileApp>
  )
}

const S = {
  InstallMobileApp: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',
  })),
  QRScanInstructions: styled.div(({ theme }) => ({
    position: 'absolute',
    bottom: 56 + 128 + 12,
    left: 56,
    height: 16,
    width: 128,
    ...theme.text.publicSans['11.5:16'],
    ...theme.text.publicSans.uppercase,
    textTransform: 'uppercase',
    fontWeight: 800,
    textAlign: 'center',

    ':before': {
      content: '""',
      position: 'absolute',
      top: 8,
      left: 0,
      height: 1,
      width: 20,
      background: theme.colors.alpha.border.mediumWeak,
    },
    ':after': {
      content: '""',
      position: 'absolute',
      top: 8,
      right: 0,
      height: 1,
      width: 20,
      background: theme.colors.alpha.border.mediumWeak,
    },
  })),
  QRCode: styled(QRCodeSVG)(() => ({
    position: 'absolute',
    bottom: 56,
    left: 56,
    height: 128,
    width: 128,
  })),
  InactivePlatformCTA: styled.a(() => ({
    display: 'flex',
    marginLeft: 3,
    alignItems: 'center',
    justifyContent: 'center',
  })),
  InactivePlatformName: styled.span(() => ({
    fontWeight: 'bold',
  })),
  InactivePlatformIcon: styled.img(() => ({
    height: 18,
    width: 18,
  })),
  InstallArrowWE: styled(InstallArrowWE)(() => ({
    position: 'absolute',
    bottom: 104,
    left: -40,
  })),
  CheckPinnedWrapper: styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    background: theme.colors.surface.base,
    ...theme.text.publicSans['13.5:20'],
    marginBottom: -48,

    strong: {
      fontWeight: 700,
    },
  })),
  Checkbox: styled.div(({ theme }) => ({
    width: '16px',
    height: '16px',
    flexShrink: 0,
    borderRadius: 4,
    border: `2px solid ${theme.colors.text[900]}`,
    background: theme.colors.surface.base,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })),
  GoBackToQrCodeText: styled.span(() => ({
    cursor: 'pointer',
    fontWeight: 700,
  })),
}
