import React from 'react'

import { useUIContext } from '../../common/contexts/UIContext'
import {
  WithDnDContext,
  withDnDContext,
} from '../../common/dnd/enhancers/withDnDContext'
import { useRouteFromLocation } from '../../common/routes'
import { cx, styled } from '../../common/stationary'
import { SideBarDimensions } from '../SideBar'
import { HeaderLeft } from './components/HeaderLeft'
import { HeaderRight } from './components/HeaderRight'

type HeaderProps = WithDnDContext

export const Header: React.FC<HeaderProps> = ({ isDragInProgress }) => {
  const { isLeftHidden, isRightHidden } = useUIContext()

  const { recordId } = useRouteFromLocation()
  const isFullscreen = !!recordId

  return (
    <S.Header
      className={cx({
        isDragInProgress,
        isFullscreen,
        isLeftHidden,
        isRightHidden,
      })}
    >
      <S.HeaderInner className={cx({ isFullscreen })}>
        <HeaderLeft />
        <HeaderRight isFullscreen={isFullscreen} />
      </S.HeaderInner>
    </S.Header>
  )
}

export default withDnDContext(Header)

export const HeaderDimension = {
  height: 56,
}

const S = {
  Header: styled.div(({ theme }) => ({
    display: 'flex',
    position: `fixed`,
    top: 0,
    right: SideBarDimensions.width,
    left: SideBarDimensions.width,
    paddingTop: `env(safe-area-inset-top)`,
    zIndex: 10,
    background: theme.colors.surface.lower,
    transitionProperty: `left,right`,
    transitionDuration: `0.2s`,
    transitionTimingFunction: `ease`,
    overflow: 'hidden',

    '&.isLeftHidden': {
      left: 0,
    },
    '&.isRightHidden': {
      right: 0,
    },
    '&.isDragInProgress': {
      pointerEvents: `none`,
    },
  })),
  HeaderInner: styled.div(({ theme }) => ({
    flex: 1,
    display: `flex`,
    justifyContent: `center`,
    position: `relative`,
    height: HeaderDimension.height,

    '&.isFullscreen': {
      margin: '0 4px',
      borderRadius: '8px 8px 0 0',
      boxShadow: theme.effects.boxShadow.elevation[3],
      background: theme.colors.surface.base,
    },
  })),
}
