import { Capacitor } from '@capacitor/core'
import { Env } from '../../env'

const USER_AGENT = typeof navigator !== `undefined` ? navigator.userAgent : ''

const iOSUserAgentRegex = /iphone|ipad/i
const androidUserAgentRegex = /android/i

export const CAPACITOR_PLATFORM = Capacitor.getPlatform()

export const isIOSNative = CAPACITOR_PLATFORM === 'ios'
export const isIOSUserAgent = iOSUserAgentRegex.test(USER_AGENT)
export const isIOSMobileWeb = isIOSUserAgent && !isIOSNative
export const isIOS = isIOSNative || isIOSMobileWeb

export const isAndroidNative = CAPACITOR_PLATFORM === 'android'
export const isAndroidUserAgent = androidUserAgentRegex.test(USER_AGENT)
export const isAndroidMobileWeb = isAndroidUserAgent && !isAndroidNative
export const isAndroid = isAndroidNative || isAndroidMobileWeb

export const isMobileApp = isIOSNative || isAndroidNative
export const isMobileWeb = isIOSMobileWeb || isAndroidMobileWeb
export const isMobileIOSWeb = isIOSMobileWeb
export const isMobile = isMobileApp || isMobileWeb

export const isMac =
  typeof navigator !== `undefined`
    ? navigator.platform.indexOf('Mac') !== -1
    : false

export const isWindows =
  typeof navigator !== `undefined`
    ? navigator.platform.indexOf('Win') !== -1
    : false

export const isMacLike =
  typeof navigator !== `undefined`
    ? isMac || /(iPhone|iPod|iPad)/i.test(navigator?.platform || ``)
    : false

//@ts-ignore
export const isDesktop = !Env.isSSR && window && window.todesktop

// Env

export const envIsBeta = Env.appEnv === `BETA`
export const envIsProd = Env.appEnv === `PRODUCTION`
export const envIsDev = Env.appEnv === `DEVELOPMENT`
export const envIsStaging = Env.appEnv === `STAGING`

export enum MobileAppPlatform {
  IOS = 'ios',
  ANDROID = 'android',
}
