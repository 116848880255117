import { useEffect, useMemo } from 'react'

import { useUIContext } from '../../../common/contexts/UIContext'
import { ModelStore } from '../../../common/data/modelStore'
import { useStartOfTodayISO } from '../../../common/hooks/useStartOfTodayISO'

export interface TodayControls {
  activeDate: string
  showNextFourteenDays: boolean
  toggleShowNextFourteenDays: () => void
}

export const useTodayControls = (store: ModelStore): TodayControls => {
  const {
    todayFilters: {
      todayDate: activeDate,
      setTodayDate: setActiveDate,
      showNextFourteenDays,
      toggleShowNextFourteenDays,
    },
  } = useUIContext()

  const { today } = useStartOfTodayISO()

  useEffect(() => {
    setActiveDate(today)
    return () => setActiveDate(today)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => ({
      activeDate,
      showNextFourteenDays,
      toggleShowNextFourteenDays,
    }),
    [, activeDate, showNextFourteenDays, toggleShowNextFourteenDays],
  )
}
