import { first } from 'lodash'
import { getShortcutKeys, shortcutKeyToSymbol } from '../hooks/useKeys'
import { KeyMappedActionIdType, KeyMappedActions } from './KeyMappedAction'

export const keysFromActionType = (
  id: KeyMappedActionIdType,
  keyMappedActions: KeyMappedActions,
) => {
  return keyMappedActions[id].keys
}

export const getKeyCapsForActions = (
  keyMappedActions: KeyMappedActions,
): Record<KeyMappedActionIdType, string[]> => {
  // Currently, only returns the first keycap for each action. Can be modified to return all once we support rendering them.
  return (Object.keys(keyMappedActions) as KeyMappedActionIdType[]).reduce(
    (acc, id) => {
      acc[id] =
        first(keyMappedActions[id].keys.map((key) => getShortcutKeys(key))) ??
        []
      return acc
    },
    {} as Record<KeyMappedActionIdType, string[]>,
  )
}
