import React, { createContext, ReactNode, useContext, useMemo } from 'react'

import { ExecAction, useExec } from '../useExec'
import { emptyFn } from '../utils'

export interface ExecContextValue {
  exec: ExecAction
}

/*
 * Context
 */

const defaultValue = {
  exec: emptyFn,
}

export const ExecContext = createContext<ExecContextValue>(defaultValue as any)
ExecContext.displayName = `ExecContext`

/*
 * Provider
 */

export interface ExecProviderProps {
  children: ReactNode
}

export const ExecProvider = ({ children }: ExecProviderProps) => {
  const exec = useExec()
  const value = useMemo(() => ({ exec }), [exec])

  return <ExecContext.Provider value={value}>{children}</ExecContext.Provider>
}

/*
 * Hooks
 */

export const useExecContext = (): ExecContextValue => {
  const context = useContext(ExecContext)

  if (context === undefined) {
    throw new Error(`useExecContext must be used within an ExecProvider`)
  }

  return context
}
