import React from 'react'

import { Key, Priorities, useKeys } from '../../common/hooks/useKeys'
import { emptyFn } from '../../common/utils'
import ScheduleBody from './components/ScheduleBody'
import ScheduleHeader from './components/ScheduleHeader'
import { ScheduleHandlersData } from './hooks/useScheduleHandlers'
import { styled } from '../../common/stationary'

export interface ScheduleProps extends ScheduleHandlersData {
  inputRef?: React.RefObject<HTMLInputElement>
  selectedRecordIds: string[]
  activeRecordDueDate?: string
  onPreviewClick: () => void
}

export const Schedule = ({
  inputRef,
  selectedRecordIds,
  inputValue,
  validInput,
  quickDates,
  selectedIndex,
  dateModel,
  previewModel,
  activeRecordDueDate,
  hadInitialRecurrenceOrTime,
  setDate,
  setTime,
  setReminder,
  setRecurrence,
  onSubmit,
  onShortcutMouseEnter,
  onShortcutMouseLeave,
  onDownArrow,
  onUpArrow,
  onEnter,
  onSchedule,
  onEsc,
  onChange,
  handleSearchInputKeyDown,
  onPreviewClick,
}: ScheduleProps) => {
  useKeys([Key.Down, Key.J], onDownArrow, Priorities.Dialog, true)
  useKeys([Key.Up, Key.K], onUpArrow, Priorities.Dialog, true)
  useKeys(Key.Enter, onEnter, Priorities.Dialog, true)
  useKeys(Key.S, onSchedule, Priorities.Dialog, true)
  useKeys(Key.C, emptyFn, Priorities.Dialog, true)
  useKeys(Key.M, emptyFn, Priorities.Dialog, false)
  useKeys(Key.Esc, onEsc, Priorities.Dialog, true)
  useKeys(Key.Tab, emptyFn, Priorities.Dialog, true)
  useKeys(Key.ShiftTab, emptyFn, Priorities.Dialog, true)

  return (
    <S.Schedule>
      <ScheduleHeader
        inputRef={inputRef}
        selectedRecordIds={selectedRecordIds}
        inputValue={inputValue}
        validInput={validInput}
        onChange={onChange}
        onSubmit={onSubmit}
        handleSearchInputKeyDown={handleSearchInputKeyDown}
      />
      <div>
        <ScheduleBody
          quickDates={quickDates}
          value={activeRecordDueDate}
          selectedIndex={selectedIndex}
          hadInitialRecurrenceOrTime={hadInitialRecurrenceOrTime}
          onShortcutMouseEnter={onShortcutMouseEnter}
          onShortcutMouseLeave={onShortcutMouseLeave}
          dateModel={dateModel}
          previewModel={previewModel}
          setDate={setDate}
          setTime={setTime}
          setReminder={setReminder}
          setRecurrence={setRecurrence}
          onPreviewClick={onPreviewClick}
        />
      </div>
    </S.Schedule>
  )
}

export default Schedule

const S = {
  Schedule: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `column`,
    borderRadius: 12,
    boxShadow: theme.effects.boxShadow.elevation[3],
    background: theme.colors.surface.base,
    width: 512,
    maxWidth: '100%',
  })),
  Body: {},
}
