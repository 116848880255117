import { keyframes } from '@emotion/react'
import React, { useCallback, useState } from 'react'

import { Icon, cx, styled } from '../../../../../common/stationary'

interface URLThumbnailProps {
  thumbnail?: string
  loading?: boolean
  notesOpen: boolean
}

export const URLThumbnail: React.FC<URLThumbnailProps> = ({
  thumbnail,
  loading,
  notesOpen,
}) => {
  const [failed, setFailed] = useState(false)

  const onError = useCallback(() => setFailed(true), [])

  return thumbnail && !failed ? (
    <S.Thumbnail className={cx(`thumbnail`, { notesOpen })}>
      <S.Image src={thumbnail} onError={onError} />
    </S.Thumbnail>
  ) : (
    <S.Empty>
      {loading ? (
        <S.SpinnerIcon boxSize={16} variant="glyphCircleThreeQuarters" />
      ) : (
        <S.LinkIcon boxSize={16} variant="glyphLinkSingle" />
      )}
    </S.Empty>
  )
}

const kf = {
  spin: keyframes({
    from: { transform: `rotate(0deg)` },
    to: { transform: `rotate(360deg)` },
  }),
}

const S = {
  Thumbnail: styled.div({
    display: `flex`,
    position: `relative`,
    flex: 1,
    height: `100%`,
    borderTopRightRadius: `8px`, // hack: to perfectly match with parent 8 (9px)
    borderBottomRightRadius: `8px`, // hack: to perfectly match with parent 8 (9px)
    boxSizing: `border-box`,
    alignItems: `center`,
    justifyContent: `center`,
    overflow: `hidden`,

    '&.notesOpen': {
      borderBottomRightRadius: 0,
    },
  }),
  SpinnerIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.gray[700],
    pointerEvents: `none`,
    animationName: kf.spin,
    animationDuration: `800ms`,
    animationIterationCount: `infinite`,
    animationTimingFunction: `ease-in-out`,
  })),
  LinkIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.text[700],
    pointerEvents: `none`,
  })),
  Image: styled.img(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    height: `100%`,
    background: theme.colors.alpha.tone.weak,
    objectFit: `cover`,
  })),
  Empty: styled.div(({ theme }) => ({
    display: `flex`,
    flex: 1,
    height: `100%`,
    background: theme.colors.alpha.tone.weak,
    borderLeft: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    boxSizing: `border-box`,
    alignItems: `center`,
    justifyContent: `center`,
  })),
}
