import React from 'react'

import { Icon, cx, styled } from '../../../common/stationary'

interface RecordMoveToProps {
  onAction?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  showAction?: boolean
  completed?: boolean
}

export const RecordMoveTo: React.FC<RecordMoveToProps> = ({
  onAction,
  showAction,
  completed,
}) => {
  return showAction ? (
    <S.RecordMoveTo>
      <S.RecordMoveToInner className={cx({ completed })} onClick={onAction}>
        <Icon boxSize={16} variant="glyphArrowE" />
      </S.RecordMoveToInner>
    </S.RecordMoveTo>
  ) : null
}

const S = {
  RecordMoveTo: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 48px`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    paddingTop: 24 + 1,
    borderRight: `1px solid ${theme.colors.alpha.border.extraWeak}`,
  })),
  RecordMoveToInner: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    height: 22,
    width: 22,
    border: `1px solid ${theme.colors.alpha.border.weak}`,
    borderRadius: 999,
    color: theme.colors.text[900],
    cursor: `pointer`,

    '&.completed': {
      border: `1px solid ${theme.colors.alpha.border.extraWeak}`,
    },
    '&:hover': {
      border: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    },
  })),
}
