import { ContentEditableEvent } from 'react-contenteditable'

import { Tag } from '../../common/data/tags'
import { RecordItemRichTextHandlers } from './RecordItemRichTextHandlers'

export type TextMode = `plain` | `link`

export type HandlerWithRecordId<T> = (e: T, recordId: string) => void
export type RecordItemOnChange = (event: ContentEditableEvent) => void
export type RecordItemOnFocus = (
  event: React.FocusEvent<HTMLDivElement>,
) => void
export type RecordItemOnMouse = (
  event: React.MouseEvent<Element, MouseEvent>,
) => void
export type RecordItemHandleOnMouse = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => void
export type RecordItemToggleOnMouse = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => void
export type RecordItemMoveToOnMouse = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => void
export type RecordItemTagOnMouse = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  tag: Tag,
) => void
export type RecordItemOnTap = (
  event: React.MouseEvent<HTMLDivElement>,
  id: string,
) => void

export interface RecordItemHandlers extends RecordItemRichTextHandlers {
  expandOnFocus?: RecordItemOnFocus
  onBlur?: RecordItemOnFocus
  onClick?: RecordItemOnMouse
  onFileTitleClick?: RecordItemOnMouse
  onFocus?: RecordItemOnFocus
  onHandleClick?: RecordItemHandleOnMouse
  onMouseDown?: RecordItemOnMouse
  onMoveTo?: RecordItemMoveToOnMouse
  onShowMoreClick?: RecordItemOnMouse
  onTagClick?: RecordItemTagOnMouse
  onToggle?: RecordItemToggleOnMouse
  onTap?: RecordItemOnTap
  onUrlTitleClick?: RecordItemOnMouse
  renameFile?: (fileName: string) => void
  skipOccurrence?: () => void
}

export const RecordItemDimension = {
  insetLeft: 20,
  insetRight: 20,
  insetTop: 8,
  maxHeight: 256,
  propertiesStrip: 56,
  showMore: 40,
}
