import React, { useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLogger } from '../../contexts/LoggerContext'

interface WithErrorBoundaryProps<T> {
  error: { fallbackComponent: any; fallbackComponentProps?: T }
}

export const withErrorBoundary = <
  WrappedComponentProps,
  ErrorBoundaryComponentProps,
>(
  WrappedComponent: React.FC<
    WrappedComponentProps & WithErrorBoundaryProps<ErrorBoundaryComponentProps>
  >,
) => {
  const ComponentWithErrorBoundary: React.FC<
    WrappedComponentProps & WithErrorBoundaryProps<ErrorBoundaryComponentProps>
  > = (props) => {
    const { error } = props
    const ErrorBoundaryComponent = error.fallbackComponent
    const errorBoundaryComponentProps = error.fallbackComponentProps

    const logger = useLogger()

    const onError = useCallback(
      (
        error: Error,
        info: {
          componentStack: string
        },
      ) => {
        logger.error('ErrorBoundary Error', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          content: info,
        })
      },
      [],
    )

    return (
      <ErrorBoundary
        fallbackRender={({ error }) => {
          return (
            <ErrorBoundaryComponent
              error={error}
              errorProps={errorBoundaryComponentProps}
            />
          )
        }}
        onError={onError}
      >
        <WrappedComponent {...props} />
      </ErrorBoundary>
    )
  }

  return ComponentWithErrorBoundary
}
