import { LexicalEditor } from '@eleventhlabs/capture-shared/lib/editor/frameworks/lexical'
import React, { useCallback } from 'react'
import { useLogger } from '../../common/contexts/LoggerContext'
import { EditorsManager } from '../../common/EditorsManager'

export const withLogging = <T,>(WrappedComponent: React.FC<T>) => {
  const ComponentWithRegistration: React.FC<T & { id: string }> = (props) => {
    const logger = useLogger()

    const recordId = props.id

    const onEditorError = useCallback(
      (_editor: LexicalEditor, error: Error) => {
        logger.error('RichTextEditor Error', {
          recordId,
          message: error.message,
          name: error.name,
          stack: error.stack,
          content: EditorsManager.get.richText(recordId, { sanitize: true }),
        })
      },
      [],
    )

    return <WrappedComponent {...{ ...props, onEditorError }} />
  }
  return ComponentWithRegistration
}
