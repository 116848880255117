import { apiCall } from '../../../common/api/client'
import { useAuth } from '../../../common/contexts/AuthContext'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { Env } from '../../../common/env'
import { useReplicacheContext } from '../../../common/replicache/ReplicacheContext'
import { withSearchParams } from '../../../common/urls'
import { TrackItem, TrackType, track, trackType } from '../components/tracks'
import { useOnboardingSlideIndex } from './useOnboardingSlideIndex'
import { useOnboardingAnalytics } from './useOnboardingAnalytics'
import { OnboardingDemoRecord, useOnboardingInbox } from './useOnboardingInbox'
import { useCallback, useEffect, useState } from 'react'
import { SlideType } from '../components/slides'

export enum OnboardingMessagingApp {
  DEFAULT_MESSAGING_APP = 'messages',
  WHATSAPP = 'whatsapp',
  NONE = 'none',
}

export type UseOnboardingScreen = {
  addTextToInbox: (value: string) => void
  isReady: boolean
  markOnboardingComplete: () => void
  onboardingAnalytics: ReturnType<typeof useOnboardingAnalytics>
  onboardingInboxRecords: OnboardingDemoRecord[]
  track: TrackItem[]
  trackType: TrackType
  iOSQRCodeLink: string
  androidQRCodeLink: string
  selectedMessagingApp: OnboardingMessagingApp
  setSelectedMessagingApp: (app: OnboardingMessagingApp) => void
  currentIndex: number
  setCurrentIndex: (index: number) => void
  shouldShowSlide: (slideName: SlideType) => boolean
  didSkipAddingContact: boolean
  setDidSkipAddingContact: (value: boolean) => void
}

export function useOnboardingScreen(): UseOnboardingScreen {
  const { isReady } = useReplicacheContext()

  const [didSkipAddingContact, setDidSkipAddingContact] =
    useState<boolean>(false)

  // Interactive Inbox
  const { onboardingInboxRecords, addTextToInbox } = useOnboardingInbox()

  // Mark onboarding complete
  const markOnboardingComplete = useMarkOnboardingComplete()

  // Analytics
  const onboardingAnalytics = useOnboardingAnalytics(trackType)

  const { iOSQRCodeLink, androidQRCodeLink } = useMobileDownloadQRCodeLinks()

  const { selectedMessagingApp, setSelectedMessagingApp } =
    useSelectedMessagingApp()

  const {
    index: currentIndex,
    setIndex: setCurrentIndex,
    shouldShowSlide,
  } = useOnboardingSlideIndex()

  return {
    addTextToInbox,
    isReady,
    markOnboardingComplete,
    onboardingAnalytics,
    onboardingInboxRecords,
    track,
    trackType,
    iOSQRCodeLink,
    androidQRCodeLink,
    selectedMessagingApp,
    setSelectedMessagingApp,
    currentIndex,
    setCurrentIndex,
    shouldShowSlide,
    didSkipAddingContact,
    setDidSkipAddingContact,
  }
}

const useSelectedMessagingApp = () => {
  const [selectedMessagingApp, setSelectedMessagingApp] =
    useState<OnboardingMessagingApp>(OnboardingMessagingApp.NONE)

  return {
    selectedMessagingApp,
    setSelectedMessagingApp,
  }
}

function useMarkOnboardingComplete() {
  const { commands } = useModelStoreContext()
  const markComplete = commands.userMarkOnboardingComplete
  const withTrackCurried = useCallback(() => {
    return markComplete(trackType)
  }, [markComplete])
  return withTrackCurried
}

function useMobileDownloadQRCodeLinks() {
  const { isReady } = useReplicacheContext()
  const userId = useAuth().userId as string

  const GET_QRCODE_LINKS_URL = withSearchParams(
    `${Env.captureApiUrl}/onboarding/qrCodeLinks`,
    {
      userId,
    },
  )

  const [iOSQRCodeLink, setIOSQRCodeLink] = useState('')
  const [androidQRCodeLink, setAndroidAppQRCodeLink] = useState('')

  useEffect(() => {
    if (!isReady) return
    apiCall(GET_QRCODE_LINKS_URL, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (res) => {
      const { iOSQRCodeLink, androidQRCodeLink } = await res.json()
      setIOSQRCodeLink(iOSQRCodeLink)
      setAndroidAppQRCodeLink(androidQRCodeLink)
    })
  }, [GET_QRCODE_LINKS_URL, isReady])

  return { iOSQRCodeLink, androidQRCodeLink }
}
