import { filter, first, includes, indexOf, last, reverse, slice } from 'lodash'

export const findNearestValidId = (
  ids: string[],
  invalidIds: string[],
): string | undefined => {
  const lastIdIndex = indexOf(ids, last(invalidIds))

  if (lastIdIndex === -1) return

  // we will try to find nearest going down
  const nextId = first(
    filter(slice(ids, lastIdIndex), (id) => !includes(invalidIds, id)),
  )
  if (nextId) return nextId

  // we will try to find nearest going up
  const previousId = first(
    reverse(
      filter(slice(ids, 0, lastIdIndex), (id) => !includes(invalidIds, id)),
    ),
  )
  if (previousId) return previousId

  // there is no nearest
  return undefined
}
