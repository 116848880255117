import React from 'react'

import { VirtualListAutosized } from '../../../common/virtualized'
import { HandleResultPayload, ResultGroupItem } from '../QuickSwitcher.types'
import { withScrollerRef } from '../enhancers/withScrollerRef'
import { ResultItemDimensions } from './ResultItem'
import { ResultItemRow } from './ResultItemRow'

const ResultList: React.FC<{
  items: ResultGroupItem[]
  className?: string
  onHover?: (id: string) => void
  handleResultClick: (payload: HandleResultPayload) => void
}> = (props) => {
  return (
    <List
      itemCount={props.items.length}
      itemSize={ResultItemDimensions.height}
      itemData={props}
      className={props.className}
      Row={ResultItemRow}
    />
  )
}

const List = withScrollerRef(VirtualListAutosized)

export default ResultList
