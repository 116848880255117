import { format, parseISO } from 'date-fns'
import React from 'react'

import { Tag, TagType } from '../../../common/data/tags'
import {
  getFloatingDate,
  getNextSkipOccurrence,
} from '@eleventhlabs/capture-shared'
import {
  convertISOtoDisplay,
  convertReminderMinutesToDisplay,
  getFrequencyTitle,
} from '../../../common/utils'
import { DateTagWithTooltip } from './Tags/DateTag'
import { NotesTagWithTooltip } from './Tags/NotesTag'
import { PlaceTagWithTooltip } from './Tags/PlaceTag'
import { RecurrenceIconWithTooltip } from './Tags/RecurrenceIcon'
import { ReminderIconWithTooltip } from './Tags/ReminderIcon'
import { SkipIconWithTooltip } from './Tags/SkipIcon'
import { useStartOfTodayISO } from '../../../common/hooks/useStartOfTodayISO'
import { cx, styled, useTheme } from '../../../common/stationary'
import { HIDE_NOTE, SHOW_NOTE } from '../../../common/utils/uiConstants'

interface TagStripItemContentProps {
  tag: Tag
  isCompleted?: boolean
  notesOpen?: boolean
  onClick: (e: any) => void
  skipOccurrence: () => void
}

export const TagStripItemContent: React.FC<TagStripItemContentProps> = ({
  tag,
  isCompleted = false,
  notesOpen = false,
  onClick,
  skipOccurrence,
}) => {
  const theme = useTheme()

  const todayIso = useStartOfTodayISO().today

  let tagText
  if (tag.type === TagType.DATE) {
    let isoDate
    if (tag.date && tag.date.recurrence) {
      const next = getNextSkipOccurrence(
        {
          ...getFloatingDate(tag.date.isoDate),
          recurrence: tag.date.recurrence,
        },
        todayIso,
      )
      isoDate = next.isoDate
    }

    tagText = (
      <S.TagStripItemContentText
        className={cx({
          completed: isCompleted,
        })}
      >
        <S.DateTag
          text={tag.text}
          date={tag.date.isoDate}
          isoLocalTime={tag.date.isoLocalTime}
          onClick={onClick}
          tooltip={{
            title: `Reschedule`,
          }}
          tippyProps={{
            offset: [0, 5],
            placement: `bottom`,
          }}
        />
        {tag.date.reminderId ? (
          <S.ReminderTag
            onClick={onClick}
            tooltip={{
              title:
                tag.reminder?.minutesBefore === 0
                  ? `At ${convertISOtoDisplay(tag.date.isoLocalTime)}`
                  : `${convertReminderMinutesToDisplay(
                      tag.reminder?.minutesBefore,
                    )} before`,
            }}
            tippyProps={{
              offset: [0, 5],
              placement: `bottom`,
            }}
          />
        ) : null}
        {tag.date.recurrence ? (
          <S.RecurrenceTag
            onClick={onClick}
            tooltip={{
              title: getFrequencyTitle(
                tag.date.recurrence.frequency,
                tag.date.recurrence.byDayOfWeek,
              ),
            }}
            tippyProps={{
              offset: [0, 5],
              placement: `bottom`,
            }}
          />
        ) : null}
        {tag.date.recurrence && isoDate ? (
          <S.SkipTag
            onClick={skipOccurrence}
            tooltip={{
              title: `Skip until ${format(parseISO(isoDate), `EEE MMM d`)}`,
            }}
            tippyProps={{
              offset: [0, 5],
              placement: `bottom`,
            }}
          />
        ) : null}
      </S.TagStripItemContentText>
    )
  } else if (tag.type === TagType.NOTES) {
    tagText = (
      <S.NotesTag
        isCompleted={isCompleted}
        notesOpen={notesOpen}
        text={tag.text}
        onClick={onClick}
        tooltip={{
          title: notesOpen ? HIDE_NOTE : SHOW_NOTE,
        }}
        tippyProps={{
          offset: [0, 2],
          placement: `bottom`,
        }}
      />
    )
  } else if (tag.type === TagType.PLACE) {
    tagText = (
      <S.PlaceTag
        tag={tag}
        emoji={tag.emoji}
        text={tag.text}
        onClick={onClick}
        tooltip={{
          title: `Go to ${tag.text}`,
        }}
        tippyProps={{
          offset: [0, 2],
          placement: `bottom`,
        }}
      />
    )
  }

  return tagText ?? null
}

const S = {
  TagStripItemContentText: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    textAlign: `center`,
    marginRight: 2,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 500,
    lineHeight: `16px`,
    whiteSpace: `nowrap`,
    userSelect: `none`,
    color: theme.colors.text[700],
    cursor: `pointer`,
    height: 24,
  })),
  DateTag: styled(DateTagWithTooltip)({
    display: `flex`,
    flexWrap: `nowrap`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    fontWeight: 500,
  }),
  ReminderTag: styled(ReminderIconWithTooltip)({
    display: `flex`,
    flexWrap: `nowrap`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    fontWeight: 400,
  }),
  RecurrenceTag: styled(RecurrenceIconWithTooltip)({
    display: `flex`,
    flexWrap: `nowrap`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    fontWeight: 400,
  }),
  SkipTag: styled(SkipIconWithTooltip)({
    display: `flex`,
    flexWrap: `nowrap`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    fontWeight: 400,
  }),
  NotesTag: styled(NotesTagWithTooltip)({}),
  PlaceTag: styled(PlaceTagWithTooltip)({
    display: `flex`,
    flexWrap: `nowrap`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    fontWeight: 400,
  }),
}
