import React from 'react'

import { cx, spinAnimationStyles, styled } from '../../../common/stationary'

import BrandFavicon from '../../../assets/brand/brand-favicon.svg'

type AuthLogoMode = 'default' | 'isWaiting'

interface AuthLogoProps {
  className?: string
  href?: string
  mode?: AuthLogoMode
  secondaryIconSrc?: string
}

const AuthLogo: React.FC<AuthLogoProps> = ({
  className,
  href,
  mode = 'default',
  secondaryIconSrc,
}) => (
  <S.AuthLogo className={cx(className, mode)}>
    <S.LogoAnchor href={href}>
      <S.Logo className={cx(mode)} />
    </S.LogoAnchor>
    {secondaryIconSrc && (
      <S.SecondaryIcon
        style={{ backgroundImage: `url(${secondaryIconSrc})` }}
      />
    )}
    <S.LogoSpinner className={cx(mode)} />
    <S.SpinnerOverlay className={cx(mode)} />
  </S.AuthLogo>
)

export default AuthLogo

const S = {
  AuthLogo: styled.div(() => ({
    position: 'relative',
    marginBottom: 16,
  })),
  LogoAnchor: styled.a(() => ({
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 64,
    width: 64,
    fontSize: 0,
  })),
  Logo: styled(BrandFavicon)(() => ({
    height: 64,

    '&.isWaiting': {
      transform: 'scale(0.75)',
    },
  })),
  SecondaryIcon: styled.div(() => ({
    height: 32,
    width: 32,
    position: 'absolute',
    zIndex: 1,
    bottom: -4,
    right: -4,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  })),
  LogoSpinner: styled.div(({ theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 999,

    '&.isWaiting': {
      background: theme.colors.blue[500],
      opacity: 0.2,
    },
  })),
  SpinnerOverlay: styled.div(({ theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 999,
    overflow: 'hidden',
    ...spinAnimationStyles({ animationDuration: '2s' }),

    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: '50%',
      left: 0,
      right: '50%',
      background: theme.colors.blue[500],
    },

    ':after': {
      content: '""',
      position: 'absolute',
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
      borderRadius: 999,
      background: theme.colors.surface.base,
    },
  })),
}
