import React, { useMemo } from 'react'

import { DataRecord, RecordDict } from '@eleventhlabs/capture-shared'

import useCommandBarDialog from '../commandBar/dialog/useCommandBarDialog'
import { useDialogContext } from '../contexts/DialogContext'
import { ExecAction } from '../useExec'
import { DialogType } from './types'
import useCalendarDialog from './useCalendarDialog'
import useMoveToDialog from './useMoveToDialog'
import useQuickSwitcherDialog from './useQuickSwitcherDialog'
import useScheduleDialog from './useScheduleDialog'

// PLEASE SEE: modalConfig.ts before you try to add anything to this file.
/*
 * @deprecated prefer modalConfig.ts
 */
const useDialogs = (
  records: RecordDict,
  focusedRecordId: string | undefined,
  selectedRecordIds: string[],
  selectedRecords: DataRecord[],
  isViewVisible: (action: DialogType) => boolean,
  exec: ExecAction,
  onSelectDate: (value: string) => void,
  maxDate?: Date,
) => {
  const {
    openScheduleDialog,
    openSendToDialog,
    openQuickSwitcherDialog,
    openCalendarDialog,
  } = useDialogContext()

  const { scheduleComponent } = useScheduleDialog(
    records,
    focusedRecordId,
    selectedRecordIds,
    selectedRecords,
    isViewVisible,
    exec,
  )

  const { moveToComponent } = useMoveToDialog(selectedRecordIds, isViewVisible)

  const { quickSwitcherComponent } = useQuickSwitcherDialog(isViewVisible)

  const { calendarComponent } = useCalendarDialog(
    isViewVisible,
    onSelectDate,
    maxDate,
  )

  const { commandBarComponent } = useCommandBarDialog(
    isViewVisible,
    selectedRecordIds,
  )
  const dialogComponents = useMemo(
    () => (
      <>
        {scheduleComponent}
        {moveToComponent}
        {quickSwitcherComponent}
        {calendarComponent}
        {commandBarComponent}
      </>
    ),
    [
      scheduleComponent,
      moveToComponent,
      quickSwitcherComponent,
      calendarComponent,
      commandBarComponent,
    ],
  )

  return {
    openScheduleDialog,
    openSendToDialog,
    openQuickSwitcherDialog,
    openCalendarDialog,
    dialogComponents,
  }
}

export default useDialogs
