import { NavigateFn } from '@gatsbyjs/reach-router'

import { getParentRoute } from '../../../../routes'
import { isMobile } from '../../../../utils/env'

export const exitFullscreen = (
  parentId: string | undefined,
  lastRoute: string | undefined,
  setLastRoute: (route: string | undefined) => void,
  navigate: NavigateFn,
  setShouldRestoreSelection: (value: boolean) => void,
) => {
  if (lastRoute) {
    navigate(lastRoute)
  } else {
    navigate(getParentRoute(parentId))
  }

  setLastRoute(undefined)
  setShouldRestoreSelection(!isMobile)
}
