import {
  Icon,
  spinAnimationStyles,
  styled,
} from '../../../../../common/stationary'

/*
 * SlideContainer
 */
export const SlideContainer = styled.div(() => ({
  display: 'flex',
}))

/*
 * SlideIntro
 */
export const SlideIntro = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}))

export const SlideTitle = styled.h1(() => ({
  fontWeight: 800,
}))

export const SlideDescription = styled.div(() => ({
  strong: {
    fontWeight: 700,
  },
}))

export const SlideButtonGroup = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}))

export const SlidePrimaryButton = styled.button(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  height: 40,
  padding: '0 20px',
  borderRadius: 6,
  boxShadow: theme.effects.boxShadow.elevation[2],
  color: theme.colors.alpha.invertedTone.solid,
  background: theme.colors.alpha.tone.solid,
  cursor: 'pointer',

  transition: 'color 0.2s ease, box-shadow 0.2s ease, opacity 0.2s ease',

  strong: {
    fontWeight: 700,
  },

  ':disabled': {
    opacity: 0.4,
    cursor: 'default',

    '&.isSuccess': {
      boxShadow: 'none',
      color: theme.colors.alpha.tone.solid,
      background: theme.colors.green[200],
      opacity: 1,

      transition: 'box-shadow none',
    },
  },

  ':hover': {
    boxShadow: theme.effects.boxShadow.elevation[4],
  },
}))

export const SlideSecondaryButton = styled.button(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  height: 40,
  padding: '0 20px',
  borderRadius: 6,
  color: theme.colors.alpha.tone.solid,
  background: theme.colors.surface.base,
  cursor: 'pointer',

  transition: 'color 0.2s ease, opacity 0.2s ease',

  strong: {
    fontWeight: 700,
  },

  ':disabled': {
    opacity: 0.4,
    cursor: 'default',

    '&.isSuccess': {
      color: theme.colors.alpha.tone.solid,
      background: theme.colors.green[200],
      opacity: 1,

      transition: 'box-shadow none',
    },
  },

  ':hover': {
    background: theme.colors.alpha.tone.medium,
  },
}))

export const SlideWaitingHint = styled.div(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  height: 40,
  padding: '0 20px',
  borderRadius: 6,
  background: theme.colors.alpha.tone.mediumWeak,
  cursor: 'default',

  strong: {
    fontWeight: 700,
  },

  '&.isSuccess': {
    boxShadow: 'none',
    color: theme.colors.alpha.tone.solid,
    background: theme.colors.green[200],
    opacity: 1,

    transition: 'box-shadow none',
  },
}))

export const SlideWaitingSpinner = styled(Icon)(({ theme }) => ({
  color: theme.colors.gray[400],
  ...spinAnimationStyles({ animationDuration: '2s' }),
}))

export const SlideKeyboardHint = styled.div(({ theme }) => ({
  padding: '0 20px',
  ...theme.text.publicSans['13.5:20'],
  color: theme.colors.text[700],
  cursor: 'default',

  transition: 'opacity 0.4s ease',

  strong: {
    fontWeight: 700,
  },

  '&.isDisabled': {
    opacity: 0.6,
  },
}))

export const SlideSecondaryAction = styled.div(({ theme }) => ({
  marginTop: 16,
  ...theme.text.publicSans['13.5:20'],
  color: theme.colors.text[600],

  a: {
    fontWeight: 600,
    cursor: 'pointer',

    ':hover': {
      textDecoration: 'underline',
    },

    '&.isItalic': {
      fontWeight: 400,
      fontStyle: 'italic',
    },
  },
}))

/*
 * SlideDiagrams
 */
export const SlideDiagrams = styled.div(() => ({
  position: 'relative',
}))
