import { EditorsManager } from '../../../../EditorsManager'
import Analytics, {
  ActiveScreenValueType,
} from '../../../../analytics/capture-analytics-actions'
import { RecordItemTargetType } from '../../../../recordItem'

export const recordBlurred = (
  recordId: string,
  setFocusedRecordId: (recordId: string | undefined) => void,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
  setDefaultId: (recordId: string | undefined) => void,
  activeScreen: ActiveScreenValueType | undefined,
) => {
  const editorStats = EditorsManager.analytics.flush(recordId)

  if (editorStats) {
    Analytics.recordContentUpdated({ ...editorStats, activeScreen, recordId })
  }

  setRecentlyCreatedRecords(
    (recentlyCreatedRecords: Record<string, RecordItemTargetType>) => {
      delete recentlyCreatedRecords[recordId]
      return recentlyCreatedRecords
    },
  )
  setFocusedRecordId(undefined)
  setDefaultId(recordId)
  return
}
