import { useMemo } from 'react'
import * as Analytics from '../../../common/analytics/capture-analytics-actions'
import { useAuth } from '../../../providers'

export type OnboardingPlatform = 'Web' | 'Mobile' | 'Desktop' | 'MobileWeb'

export const useOnboardingAnalytics = (platform: OnboardingPlatform) => {
  const { userId } = useAuth()
  const onboardingAnalytics = useMemo(
    () => getOnboardingAnalytics(platform),
    [platform],
  )
  return useMemo(
    () => ({
      completed() {
        if (!userId) {
          console.error(
            'Cannot track onboarding completed event when userId is null',
          )
          return
        }
        onboardingAnalytics.completed(userId)
      },
      loaded: onboardingAnalytics.loaded,
      action: onboardingAnalytics.action,
    }),
    [userId, onboardingAnalytics],
  )
}

export type OnboardingAnalytics = {
  completed: (userId: string) => void
  loaded: (savePoint: string | null) => void
  action: (actionName: string) => void
}

const desktopOnboardingAnalytics = {
  completed: (userId: string) => {
    Analytics.desktopOnboardingCompleted({
      userId_: userId,
      desktopOnboardingCompletedVersion: '1',
    })
  },
  loaded: (savePoint: string | null) => {
    Analytics.desktopOnboardingLoaded({
      onboardingSavePoint: savePoint ?? undefined,
    })
  },
  action: (actionName: string) => {
    Analytics.desktopOnboardingAction({ onboardingAction: actionName })
  },
}

const mobileOnboardingAnalytics = {
  completed: (userId: string) => {
    Analytics.mobileOnboardingCompleted({
      userId_: userId,
      mobileOnboardingVersionCompleted: '1',
    })
  },
  loaded: (savePoint: string | null) => {
    Analytics.mobileOnboardingLoaded({
      onboardingSavePoint: savePoint ?? undefined,
    })
  },
  action: (actionName: string) => {
    Analytics.mobileOnboardingAction({ onboardingAction: actionName })
  },
}

const mobileWebOnboardingAnalytics = {
  completed: (userId: string) => {
    console.error('Mobile Web Onboarding Completed not implemented')
  },
  loaded: (savePoint: string | null) => {
    console.error('Mobile Web Onboarding Loaded not implemented')
  },
  action: (actionName: string) => {
    console.error('Mobile Web Onboarding Action not implemented')
  },
}

const webOnboardingAnalytics = {
  completed: (userId: string) => {
    Analytics.webOnboardingCompleted({
      userId_: userId,
      webOnboardingVersionCompleted: '1',
    })
  },
  loaded: (savePoint: string | null) => {
    Analytics.webOnboardingLoaded({
      onboardingSavePoint: savePoint ?? undefined,
    })
  },
  action: (actionName: string) => {
    Analytics.webOnboardingAction({ onboardingAction: actionName })
  },
}

export function getOnboardingAnalytics(
  type: OnboardingPlatform,
): OnboardingAnalytics {
  switch (type) {
    case 'Desktop':
      return desktopOnboardingAnalytics
    case 'Mobile':
      return mobileOnboardingAnalytics
    case 'MobileWeb':
      return mobileWebOnboardingAnalytics
    case 'Web':
      return webOnboardingAnalytics
    default:
      throw new Error('Invalid type provided.')
  }
}
