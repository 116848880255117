import {
  DataRecord,
  InboxDisplayText,
  InboxRecordId,
  ListRecord,
  RecordDict,
  RecordType,
  RichText,
  TodayDisplayText,
  TrashDisplayText,
} from '@eleventhlabs/capture-shared'
import { Theme } from '@eleventhlabs/stationary'
import { useLocation } from '@gatsbyjs/reach-router'
import { entries, find, last, pick, replace, take } from 'lodash'
import { validate as validateUUID } from 'uuid'
import { useModelStoreContext } from './contexts/ModelStoreContext'
import { getIconConfigForRecord } from './records'
import { IconConfig, getIconConfigPresets } from './stationary/components'

export const LoginRoutePath = '/login'

/**
 * @todo Use IDs from capture-shared (requires TypeScript 5+)
 */
export enum SystemListId {
  Inbox = 'InboxRecordId',
  Today = 'Today',
  Trash = 'Trash',
  ResetPassword = 'ResetPassword',
}

export enum RouteType {
  Inbox = `Inbox`,
  Today = `Today`,
  Trash = `Trash`,
  List = `List`,
  Details = `Details`,
  ResetPassword = `ResetPassword`,
}

export const SystemListsRouteType: Record<SystemListId, RouteType> = {
  [SystemListId.Inbox]: RouteType.Inbox,
  [SystemListId.Trash]: RouteType.Trash,
  [SystemListId.Today]: RouteType.Today,
  [SystemListId.ResetPassword]: RouteType.ResetPassword,
}

export const RoutesId: Record<RouteType, SystemListId | undefined> = {
  [RouteType.Inbox]: SystemListId.Inbox,
  [RouteType.Trash]: SystemListId.Trash,
  [RouteType.Today]: SystemListId.Today,
  [RouteType.List]: undefined,
  [RouteType.Details]: undefined,
  [RouteType.ResetPassword]: SystemListId.ResetPassword,
}

export const RoutesPath: Record<RouteType, string> = {
  [RouteType.Inbox]: `/inbox`,
  [RouteType.Today]: `/today`,
  [RouteType.Trash]: '/trash',
  [RouteType.List]: `/:listId`,
  [RouteType.Details]: `/:recordId`,
  [RouteType.ResetPassword]: `/reset-password`,
}

export const RoutesTitle: Record<RouteType, string | undefined> = {
  [RouteType.Inbox]: InboxDisplayText,
  [RouteType.Today]: TodayDisplayText,
  [RouteType.Trash]: TrashDisplayText,
  [RouteType.List]: undefined,
  [RouteType.Details]: undefined,
  [RouteType.ResetPassword]: undefined,
}

const getIconConfigForRoute = (
  { routeType, record }: { routeType: RouteType; record?: DataRecord },
  theme?: Theme,
): IconConfig => {
  switch (routeType) {
    case RouteType.Inbox: {
      return getIconConfigPresets(`Inbox`, theme)
    }
    case RouteType.Trash: {
      return getIconConfigPresets(`Trash`, theme)
    }
    case RouteType.Today: {
      return getIconConfigPresets(`Today`, theme)
    }
    case RouteType.List: {
      return record
        ? getIconConfigForRecord({ record }, theme)
        : getIconConfigPresets(`List`, theme)
    }
    case RouteType.Details: {
      return record
        ? getIconConfigForRecord({ record }, theme)
        : getIconConfigPresets(`Details`, theme)
    }
    case RouteType.ResetPassword:
    default: {
      return {}
    }
  }
}

export interface RouteMetadata {
  type: RouteType
  id?: SystemListId
  path: string
  title?: string
  iconConfig?: IconConfig
}

export const getMetadataForRoute = (
  { routeType, record }: { routeType: RouteType; record?: DataRecord },
  theme?: Theme,
): RouteMetadata => ({
  type: routeType,
  id: RoutesId[routeType],
  path: RoutesPath[routeType],
  title: RoutesTitle[routeType],
  iconConfig: getIconConfigForRoute({ routeType, record }, theme),
})

export interface RoutePayload {
  type: RouteType
  listId?: string
}

export const getUrl = ({ type, listId }: RoutePayload) => {
  const path = RoutesPath[type]
  if (type === RouteType.List && listId) return getListUrl(listId)
  return path ?? `/`
}

const getRecordIdFromUrl = (url: string) => {
  const id = last(url.split(`/`)) ?? ``
  return validateUUID(id) ? id : undefined
}

export const getListUrl = (listId: SystemListId | string) => {
  const path = RoutesPath[RouteType.List]
  return isSystemListId(listId)
    ? RoutesPath[SystemListsRouteType[listId]]
    : replace(path, `:listId`, listId)
}

export const getDetailsUrl = (recordId: string) => {
  const path = RoutesPath[RouteType.Details]
  return replace(path, `:recordId`, recordId)
}

export const getListId = (listUrlId: string): string => {
  return getSystemListIdFromPath(listUrlId) ?? listUrlId
}

export const getBreadcrumbRoute = (
  records: RecordDict,
  routeType?: RouteType,
  listId?: string,
  recordId?: string,
) => {
  const routePath = routeType ? RoutesPath[routeType] : undefined

  switch (routeType) {
    case RouteType.Today:
    case RouteType.Inbox:
      return routePath
    case RouteType.List: {
      if (!listId) return routePath
      return getListUrl(listId)
    }
    case RouteType.Details: {
      if (!recordId) return routePath
      const record = records[recordId]
      switch (record?.type) {
        case RecordType.Text:
        case RecordType.File:
        case RecordType.URL:
          return getDetailsUrl(recordId)
        case RecordType.List:
          return getListUrl(recordId)
        case RecordType.Inbox:
          return RoutesPath['Inbox']
        default:
          return routePath
      }
    }
    default:
      return routePath
  }
}

export const getTitleFromRoute = (
  records: RecordDict,
  routeType?: RouteType,
  listId?: string,
  recordId?: string,
): string | undefined => {
  const routeTitle = routeType ? RoutesTitle[routeType] : undefined

  switch (routeType) {
    case RouteType.List: {
      if (!listId) return routeTitle
      return isSystemListId(listId)
        ? RoutesTitle[SystemListsRouteType[listId]]
        : (records[listId] as ListRecord | undefined)?.title
    }
    case RouteType.Details: {
      if (!recordId) return routeTitle
      const record = records[recordId]
      switch (record?.type) {
        case RecordType.Text:
          return take(
            RichText.getRawTextFromFirstLine(record.richText.value) ??
              `Untitled`,
            100,
          ) as any
        case RecordType.File:
          return record.fileMetadata?.name ?? `Untitled`
        case RecordType.URL:
          return record.urlMetadata?.title ?? `Untitled`
        case RecordType.List:
          return record.title
        case RecordType.Inbox:
          return RoutesTitle['Inbox']
        default:
          return routeTitle
      }
    }
    case RouteType.Today:
    case RouteType.Inbox:
    default:
      return routeTitle
  }
}

export const getParentRoute = (parentId: string | undefined) => {
  switch (parentId) {
    case InboxRecordId:
      return RoutesPath[RouteType.Inbox]
    case undefined:
      return RoutesPath[RouteType.Inbox]
    default:
      return getListUrl(parentId)
  }
}

export const isRouteType = (type: RouteType | string): type is RouteType => {
  return Boolean(RoutesId[type as RouteType])
}

export const isSystemListId = (
  id: SystemListId | string,
): id is SystemListId => {
  return Object.values(SystemListId).includes(id as SystemListId)
}

export const isSystemListRoute = (
  routeType: RouteType | string,
): routeType is RouteType => {
  return Boolean(RoutesId[routeType as RouteType])
}

export const getSystemListIdFromPath = (path: string) => {
  const routeType = find(
    SystemListsRouteType,
    (routeType) => RoutesPath[routeType] === path,
  )
  return routeType ? RoutesId[routeType] : undefined
}

const isListRoute = (records: Record<string, DataRecord>, url: string) => {
  const id = getRecordIdFromUrl(url)
  if (!id) return false
  return records[id]?.type === RecordType.List
}

const isDetailsRoute = (records: Record<string, DataRecord>, url: string) => {
  const id = getRecordIdFromUrl(url)
  if (!id) return false
  return (
    records[id]?.type === RecordType.Text ||
    records[id]?.type === RecordType.File ||
    records[id]?.type === RecordType.URL
  )
}

const StaticRoutePaths = pick(RoutesPath, [
  RouteType.Inbox,
  RouteType.Today,
  RouteType.Trash,
  RouteType.ResetPassword,
])

type StaticRoutePathEntry = [keyof typeof StaticRoutePaths, string]
type StaticRoutePathEntries = Array<StaticRoutePathEntry>

function getStaticRouteTypeFromPath(
  path: string,
): keyof typeof StaticRoutePaths | undefined {
  for (const route of entries(StaticRoutePaths) as StaticRoutePathEntries) {
    const [routeType, routePath] = route
    if (routePath === path) return routeType
  }
  return undefined
}

const getRouteTypeFromPath = (
  records: Record<string, DataRecord>,
  path: string,
): RouteType | undefined => {
  if (isListRoute(records, path)) return RouteType.List
  if (isDetailsRoute(records, path)) return RouteType.Details
  return getStaticRouteTypeFromPath(path) ?? RouteType.Inbox
}

export const useRouteFromLocation = () => {
  const { store } = useModelStoreContext()
  const location = useLocation()
  const routeType = getRouteTypeFromPath(store.records, location.pathname)
  if (routeType === undefined)
    throw new Error('Unexpected undefined route type')
  const listId =
    routeType === RouteType.List
      ? getRecordIdFromUrl(location.pathname)
      : undefined
  const recordId =
    routeType === RouteType.Details
      ? getRecordIdFromUrl(location.pathname)
      : undefined
  return { routeType, listId, recordId }
}

export const useIsUnknownRoute = () => {
  const location = useLocation()
  const routeFromLocation = useRouteFromLocation()

  // If the location is inbox, we expect useRouteFromLocation to return inbox
  // otherwise Inbox is the default returned so route is unknown
  const isRouteWithoutRouteType =
    location.pathname !== RoutesPath[RouteType.Inbox] &&
    routeFromLocation?.routeType === RouteType.Inbox

  const isKnownRouteWithoutRouteType = location.pathname === '/onboarding'

  return isRouteWithoutRouteType && !isKnownRouteWithoutRouteType
}

export const getPlaceId = (
  routeType: RouteType | undefined,
  todayDate: string,
  listId?: string,
): string | undefined => {
  switch (routeType) {
    case RouteType.Today:
      return todayDate
    case RouteType.List:
      return listId ? getListId(listId) : undefined
  }

  return undefined
}
