import { useCallback, useEffect, useRef, useState } from 'react'

import { useGlobalLoggedInContext } from '../contexts/GlobalLoggedInContext'

export const useSyncClientTimezone = (
  setClientTz: (clientID: string, tz: string) => void,
) => {
  const { timezone, clientID } = useGlobalLoggedInContext()
  const onChange = useCallback(() => {
    if (
      clientID !== null &&
      clientID !== `` &&
      timezone !== null &&
      timezone !== ``
    )
      setClientTz(clientID, timezone)
  }, [clientID, timezone, setClientTz])
  useOnValueChange(timezone, onChange)
  useOnValueChange(clientID, onChange)
}

const useOnValueChange = <T>(
  value: T,
  onChange: (prev?: T, curr?: T) => any,
) => {
  const [curr, setCurr] = useState(value)
  const [prev, setPrev] = useState(value)
  const isFirst = useRef(true)
  useEffect(() => {
    setCurr(value)
  }, [value])
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false
    } else {
      setPrev(curr)
    }
  }, [curr])
  useEffect(() => {
    if (curr !== prev) {
      onChange()
    }
  }, [curr, prev, onChange])
}
