import { useCallback, useEffect, useState } from 'react'

import { convertISOtoDisplay, inputToISOTime } from '../../../../common/utils'

const regex =
  /^( )*([0-9]|0[0-9]|1[0-2])(:[0-5][0-9]){0,2}( )*(am|pm){1}( )*$/gim

export const useTimeInput = (
  value: string,
  setTime: (time: string | null) => void,
  close: () => void,
) => {
  const [inputValue, setInputValue] = useState(``)

  useEffect(() => {
    setInputValue(convertISOtoDisplay(value))
  }, [value])

  const isValidInput = useCallback((value: string | undefined): boolean => {
    if (!value) return false
    return !!value.match(regex)
  }, [])

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === `Enter` && isValidInput(inputValue)) {
        const time = inputToISOTime(inputValue)
        setTime(time)
        close()
        return true
      }
      return false
    },
    [inputValue, setTime, isValidInput, close],
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value
      setInputValue(value ?? ``)
    },
    [setInputValue],
  )

  const onSubmit = useCallback(() => {
    if (!isValidInput(inputValue)) return
    const time = inputToISOTime(inputValue)
    setTime(time)
    close()
  }, [inputValue, setTime, isValidInput, close])

  return {
    inputValue,
    onKeyDown,
    onChange,
    onSubmit,
    isValidInput,
  }
}
