import { AvoEnv } from './Avo'

interface AvoLogger {
  logDebug: (env: AvoEnv, message: string) => boolean
  logWarn: (env: AvoEnv, message: string) => boolean
  logError: (env: AvoEnv, message: string) => boolean
}

export const silentLogger: AvoLogger = {
  logDebug: (env, msg) => true,
  logWarn: (env, msg) => true,
  logError: (env, msg) => true,
}
