import { noop } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { RecordCreatedTriggerValueType } from '../../common/analytics/capture-analytics-actions'
import { useDerivedStateContext } from '../../common/contexts/DerivedStateContext'
import { useExecContext } from '../../common/contexts/ExecContext'
import useDialogs from '../../common/dialogs/useDialogs'
import { useActiveRecordIds } from '../../common/hooks/useActiveRecordIds'
import { RecordItemTargetType } from '../../common/recordItem'
import { RouteType } from '../../common/routes'
import { useCommonSelectionBehavior } from '../../common/selection'
import { useRestorePreviousSelection } from '../../common/selection/useRestorePreviousSelection'
import { InboxProps } from '../InboxScreen/InboxScreen'
import { ListProps } from '../ListScreen/ListScreen'
import { TodayProps } from '../TodayScreen/TodayScreen'
import { TrashProps } from '../TrashScreen/TrashScreen'
import { useScreenEventHandlers } from '../hooks/useScreenEventHandlers'
import { CommonRecordGroupScreenProps, ScreenProps } from '../types'
import { WithModelStore } from './withData'
import { WithSelection } from './withSelection'
import { WithUIContext } from './withUIContext'

import { Layout } from '../../common/stationary'
import { isMobile } from '../../common/utils/env'
import { SideBarDimensions } from '../../components/SideBar'

import { useDropzone } from 'react-dropzone'
import { FloatingHelpCenterButton } from '../../components/FloatingHelpCenterButton'
import { KeyboardShortcutsPane } from '../../components/KeyboardShortcutsPane'
import { useOneSignalContext } from '../../common/contexts/OneSignalContext'

type ValidScreenType = TodayProps | ListProps

export type RecordGroupScreenProps = ScreenProps &
  RouteComponentProps & {
    routeType: RouteType
    showNextFourteenDays?: boolean
    activeDate?: string
    maxDate?: Date
    setDate?: (date: string) => void
    futureDates?: string[]
  }

type WithRecordGroupScreenProps = RecordGroupScreenProps &
  WithModelStore &
  WithUIContext &
  WithSelection

export function withRecordGroupScreen(
  WrappedComponent: React.FC<InboxProps & CommonRecordGroupScreenProps>,
): React.FC<InboxProps & WithRecordGroupScreenProps>
export function withRecordGroupScreen(
  WrappedComponent: React.FC<TodayProps & CommonRecordGroupScreenProps>,
): React.FC<TodayProps & WithRecordGroupScreenProps>
export function withRecordGroupScreen(
  WrappedComponent: React.FC<ListProps & CommonRecordGroupScreenProps>,
): React.FC<ListProps & WithRecordGroupScreenProps>
export function withRecordGroupScreen(
  WrappedComponent: React.FC<TrashProps & CommonRecordGroupScreenProps>,
): React.FC<TrashProps & WithRecordGroupScreenProps>

export function withRecordGroupScreen<T extends ValidScreenType>(
  WrappedComponent: React.FC<T & CommonRecordGroupScreenProps>,
): React.FC<T & WithRecordGroupScreenProps> {
  const ComponentWithRecordGroupScreen = (
    props: T & WithRecordGroupScreenProps,
  ) => {
    const {
      store,
      focusedRecordId,
      showCompleted,
      isViewVisible,
      isAnyViewVisible,
      selection,
      selectedRecords,
      setFocusedRecordId,
      maxDate,
      setDate = noop,
      renderSidebar,
      isLeftHidden,
      isRightHidden,
    } = props
    const { recordGroups, visibleItemIds, uploadProgress } =
      useDerivedStateContext()

    const { activeRecords } = useActiveRecordIds(visibleItemIds)

    useCommonSelectionBehavior(
      selection,
      focusedRecordId,
      recordGroups,
      store.records,
      showCompleted,
    )

    const { exec } = useExecContext()
    const {
      openScheduleDialog,
      openQuickSwitcherDialog,
      openSendToDialog,
      openCalendarDialog,
      dialogComponents,
    } = useDialogs(
      store.records,
      focusedRecordId,
      activeRecords(),
      selectedRecords,
      isViewVisible,
      exec,
      setDate,
      maxDate,
    )

    const {
      addNoteAtBottomOfList,
      miniBarHandlers,
      noteHandlers,
      onFileDrop,
      recordHandlers,
      reschedule,
      shouldDisplayNotesForRecordId,
      toggleNotesVisibilityForRecordIds,
    } = useScreenEventHandlers(selection)

    const clearSelection = selection.clear

    const onClickOutside = useCallback(() => {
      if (isAnyViewVisible) return
      setFocusedRecordId(undefined)
      clearSelection()
    }, [isAnyViewVisible, clearSelection, setFocusedRecordId])

    // Clean selection on mount and unmount
    useEffect(() => {
      selection.clear()
      return function cleanup() {
        selection.clear()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useRestorePreviousSelection(selection)

    const createRecordAtStartOfGroup = useCallback(
      (
        groupId: string,
        recordItemTargetType:
          | RecordItemTargetType.Text
          | RecordItemTargetType.Bookmark
          | RecordItemTargetType.Page,
        trigger: RecordCreatedTriggerValueType,
        triggeredByKeyboard: boolean,
        triggeredByUI: boolean,
      ) => {
        exec({
          type: 'CREATE_RECORD_AT_START_OF_GROUP',
          groupId,
          openRecordInFullScreen:
            recordItemTargetType === RecordItemTargetType.Page,
          recordId: uuidv4(),
          targetType: recordItemTargetType,
          trigger,
          triggeredByKeyboard,
          triggeredByUI,
        })
      },
      [exec],
    )
    const createFileRecordAtStartOfGroup = useCallback(
      (
        files: File[],
        groupId: string,
        trigger: RecordCreatedTriggerValueType,
      ) => {
        exec({
          type: 'CREATE_FILE_RECORD_AT_START_OF_GROUP',
          files,
          groupId,
          index: 0,
          trigger,
        })
      },
      [exec],
    )

    const { getRootProps: getDropZoneRootProps, isDragActive } = useDropzone({
      onDrop: onFileDrop,
    })

    // const oneSignal = useOneSignalContext()

    // // When the user re-downloads the app after having already completed
    // // onboarding, give them the option enable push notifications. This should
    // // happe for any screen with a RecordGroup (Inbox, Today, List, Trash)
    // useEffect(() => {
    //   oneSignal.promptForPermission('pushNotifications', () => {})
    // }, [])

    return (
      <section>
        <div {...getDropZoneRootProps()} style={{ outline: `none` }}>
          <Layout
            leftColumn={{
              children: renderSidebar && renderSidebar(),
              width: SideBarDimensions.width,
              isExpanded: !isLeftHidden,
              expandMode: isMobile ? 'shift' : 'resize',
            }}
            rightColumn={{
              children: <KeyboardShortcutsPane />,
              width: SideBarDimensions.width,
              isExpanded: !isRightHidden,
              expandMode: isMobile ? 'shift' : 'resize',
            }}
          >
            <WrappedComponent
              {...(props as any)}
              createFileRecordAtStartOfGroup={createFileRecordAtStartOfGroup}
              addNoteAtBottomOfList={addNoteAtBottomOfList}
              createRecordAtStartOfGroup={createRecordAtStartOfGroup}
              focusedId={focusedRecordId}
              groups={recordGroups}
              noteHandlers={noteHandlers}
              miniBarHandlers={miniBarHandlers}
              onClickOutside={onClickOutside}
              openScheduleDialog={openScheduleDialog}
              openQuickSwitcherDialog={openQuickSwitcherDialog}
              openSendToDialog={openSendToDialog}
              openCalendarDialog={openCalendarDialog}
              reschedule={reschedule}
              recordHandlers={recordHandlers}
              selectedIds={selection.ids}
              shouldDisplayNotesForRecordId={shouldDisplayNotesForRecordId}
              showCompleted={showCompleted}
              toggleNotesVisibilityForRecordIds={
                toggleNotesVisibilityForRecordIds
              }
              uploadProgress={uploadProgress}
              isDragActive={isDragActive}
            />
            {dialogComponents}
            <FloatingHelpCenterButton />
          </Layout>
        </div>
      </section>
    )
  }

  ComponentWithRecordGroupScreen.displayName = `ComponentWithRecordGroupScreen`

  return ComponentWithRecordGroupScreen
}
