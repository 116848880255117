import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { CSSProperties, useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'

import { isEmpty } from 'lodash'
import { safeNavigate } from '../../common/navigate'
import { RouteType, RoutesPath } from '../../common/routes'
import {
  CSSObject,
  Global,
  ThemeProvider,
  getThemes,
  globalResetStyles,
  globalStyles,
  gradientAnimationStyles,
  gradientBackgroundStyles,
  styled,
  styles,
} from '../../common/stationary'
import { isMobile } from '../../common/utils/env'
import {
  OnboardingScreenContext,
  useOnboardingContext,
} from './OnboardingScreenContext'
import {
  TrackItem,
  getShouldSkipTrackItem,
  getSlide,
  getSlideType,
} from './components/tracks'
import { WidgetType, Widgets } from './components/widgets'
import { InteractiveInboxProps } from './components/widgets/InteractiveInbox'
import { ProgressBarProps } from './components/widgets/ProgressBar'
import { useCompleteOnboardingSlides } from './hooks/useCompleteOnboardingSlides'
import { useOnboardingScreen } from './hooks/useOnboardingScreen'
import { ExecProvider } from '../../common/contexts/ExecContext'

const ONE_SECOND_MS = 1000
export const SubmitRecordDelay = ONE_SECOND_MS

const { StationaryLight } = getThemes()

export const OnboardingScreenContainer: React.FC<RouteComponentProps> = (
  props,
) => {
  const onboardingScreen = useOnboardingScreen()
  const { track } = onboardingScreen

  // If the track is empty, just go to the inbox
  if (isEmpty(track)) {
    safeNavigate(RoutesPath[RouteType.Inbox])
    return null
  }

  return (
    <OnboardingScreenContext.Provider value={onboardingScreen}>
      <ThemeProvider theme={StationaryLight}>
        <Global
          styles={styles(
            globalResetStyles as CSSObject,
            globalStyles({ theme: StationaryLight }),
          )}
        />
        <OnboardingScreen track={track} {...props} />
      </ThemeProvider>
    </OnboardingScreenContext.Provider>
  )
}

/**
 * Renders Slides & Widgets — these are configured via Tracks i.e. TrackItems[]
 */

type OnboardingScreenProps = RouteComponentProps & {
  track: TrackItem[]
}

export const OnboardingScreen: React.FC<OnboardingScreenProps> = ({
  track,
}) => {
  const { addTextToInbox, onboardingAnalytics, onboardingInboxRecords } =
    useOnboardingContext()

  const { trackItem, goBackward, goForward, progress } = useTrackItem(track)

  // When the track item changes, fire analytics.
  useEffect(() => {
    const slide = getSlide(trackItem)
    const onLoadAction = slide.analytics.onLoadAction
    onboardingAnalytics.action(onLoadAction)
  }, [onboardingAnalytics, trackItem])

  const {
    activeWidgets,
    widgetStyles,
    widgetProps,
    updateWidgetStyle,
    resetWidgetStyle,
    updateWidgetProps,
    resetWidgetProps,
  } = useWidgets(trackItem)

  const submitRecord = (value: string) => {
    /**
     * Delay to time setRecords with the animations and emoji confetti
     */
    setTimeout(() => addTextToInbox(value), SubmitRecordDelay)
  }

  const ProgressBar = Widgets.ProgressBar.component
  const InteractiveInbox = Widgets.InteractiveInbox.component
  const ActiveSlide = getSlide(trackItem).component

  return (
    <Transition appear in timeout={0}>
      {(state) => (
        <S.OnboardingScreen
          style={{
            opacity: state === 'entered' ? 1 : 0,
            overflow: isMobile ? 'hidden' : 'unset',
          }}
        >
          <ProgressBar
            progress={progress}
            style={{
              display: !activeWidgets['ProgressBar'] ? 'none' : undefined,
              ...widgetStyles['ProgressBar'],
            }}
          />
          <ActiveSlide
            goBackward={goBackward}
            goForward={goForward}
            submitRecord={submitRecord}
            updateWidgetStyle={updateWidgetStyle}
            resetWidgetStyle={resetWidgetStyle}
            updateWidgetProps={updateWidgetProps}
            resetWidgetProps={resetWidgetProps}
          />
          <InteractiveInbox
            isActive={activeWidgets['InteractiveInbox']}
            key={JSON.stringify(widgetStyles['InteractiveInbox'])}
            records={onboardingInboxRecords}
            style={{
              display: !activeWidgets['InteractiveInbox'] ? 'none' : undefined,
              ...widgetStyles['InteractiveInbox'],
            }}
            {...widgetProps['InteractiveInbox']}
          />
        </S.OnboardingScreen>
      )}
    </Transition>
  )
}

const S = {
  OnboardingScreen: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: 720,
    ...gradientBackgroundStyles({ theme }),
    ...gradientAnimationStyles({ animationDuration: '30s' }),

    transition: 'opacity 0.8s ease',

    ...(isMobile && {
      minHeight: 0,
    }),
  })),
}

/*
 * useWidgets
 */
export type WidgetPropsType = Partial<ProgressBarProps | InteractiveInboxProps>

const useWidgets = (trackItem: TrackItem) => {
  const [_, widgetTypes] = trackItem

  const activeWidgets = {
    ...getWidgetDict(false),
    ...widgetTypes.reduce(
      (acc, widgetType) => ({ ...acc, [widgetType]: true }),
      {},
    ),
  }

  const [widgetStyles, setWidgetStyles] = useState(
    getWidgetDict({} as CSSProperties),
  )

  const [widgetProps, setWidgetProps] = useState(
    getWidgetDict({} as WidgetPropsType),
  )

  const updateWidgetStyle = (widgetType: WidgetType, style?: CSSProperties) => {
    if (style) return setWidgetStyles({ ...widgetStyles, [widgetType]: style })
  }

  const resetWidgetStyle = (widgetType: WidgetType) => {
    return setWidgetStyles({ ...widgetStyles, [widgetType]: {} })
  }

  const updateWidgetProps = (
    widgetType: WidgetType,
    props: WidgetPropsType,
  ) => {
    return setWidgetProps({ ...widgetProps, [widgetType]: props })
  }

  const resetWidgetProps = (widgetType: WidgetType) => {
    return setWidgetProps({ ...widgetProps, [widgetType]: {} })
  }

  return {
    activeWidgets,
    widgetStyles,
    updateWidgetStyle,
    resetWidgetStyle,
    widgetProps,
    updateWidgetProps,
    resetWidgetProps,
  }
}

const getWidgetDict = <V extends unknown>(defaultValue: V) =>
  Object.keys(Widgets).reduce(
    (acc, [key]) => ({ ...acc, [key]: defaultValue }),
    {} as Record<WidgetType, V>,
  )

/**
 * useTrackItem
 */
const useTrackItem = (track: TrackItem[]) => {
  const {
    onboardingAnalytics,
    markOnboardingComplete,
    currentIndex,
    setCurrentIndex,
    shouldShowSlide,
  } = useOnboardingContext()

  const { completeSlides } = useCompleteOnboardingSlides()

  const trackItem = track[currentIndex]

  const slide = getSlide(trackItem)

  const goBackward = (skipToIndex?: number) => {
    const targetIndex = skipToIndex ?? currentIndex - 1

    if (targetIndex >= currentIndex || targetIndex < 0)
      console.error({ targetIndex, currentIndex, track })
    else setCurrentIndex(targetIndex)
  }

  const goForward = (skipToIndex?: number): void => {
    const nextIndex = skipToIndex ?? currentIndex + 1

    const nextTrackItem = track[nextIndex] ? track[nextIndex] : null

    /**
     * Check if shouldSkip, skip to the next index if so
     */
    if (
      nextTrackItem &&
      (getShouldSkipTrackItem(nextTrackItem) ||
        !shouldShowSlide(getSlideType(nextTrackItem)))
    )
      goForward(nextIndex + 1)

    if (nextIndex === -1 || nextIndex >= track.length) {
      onboardingAnalytics.completed()

      completeSlides([getSlideType(trackItem)])

      markOnboardingComplete()
    } else {
      onboardingAnalytics.action(slide.analytics.onCompleteAction)

      completeSlides([getSlideType(trackItem)])

      setCurrentIndex(nextIndex)
    }
  }

  const progress = (currentIndex + 1) / track.length

  return {
    trackItem,
    goForward,
    goBackward,
    progress,
  }
}
