import { isEmpty } from 'lodash'

import { triggerAction } from '../../../../events'
import { BaseSelection, findNearestValidId } from '../../../../libs/selection'

export const deleteRecords = (
  recordIdsToDelete: string[],
  visibleItemIds: string[],
  selection: BaseSelection,
  deleteRecords: (ids: string[]) => void,
): string[] => {
  if (isEmpty(recordIdsToDelete)) return []
  const newDefaultId = findNearestValidId(visibleItemIds, recordIdsToDelete)
  triggerAction({ target: `update-default`, invalidIds: recordIdsToDelete })
  deleteRecords(recordIdsToDelete)
  return recordIdsToDelete
}
