import { some } from 'lodash'

import {
  AnnotationCreatedTriggerValueType,
  ShowAnnotationsToggleTriggerValueType,
} from '../../../../analytics/capture-analytics-actions'
import { RecordItemTargetType } from '../../../../recordItem'
import { DataRecord, isNoteableRecord } from '@eleventhlabs/capture-shared'
import { closeAnnotations } from './closeAnnotations'
import { openAnnotations } from './openAnnotations'

export const toggleAnnotations = (
  records: Record<string, DataRecord>,
  recordIds: string[],
  deleteNotes: (noteIds: string[]) => void,
  shouldDisplayNotesForRecordId: (id: string) => boolean,
  addNoteAtBottomOfList:
    | ((
        parentId: string,
        triggerCreatedAnnotation: AnnotationCreatedTriggerValueType,
        triggerShowAnnotation: ShowAnnotationsToggleTriggerValueType,
      ) => void)
    | undefined,
  setNotesVisibilityForRecordIds: (ids: string[], visibility: boolean) => void,
  showAnnotationsTrigger: ShowAnnotationsToggleTriggerValueType,
  createAnnotationTrigger: AnnotationCreatedTriggerValueType,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
) => {
  if (recordIds.length === 0) {
    return {
      notesOpened: 0,
      notesClosed: 0,
      notesCreated: 0,
    }
  }

  const toggleToVisible = some(recordIds, (id) => {
    const r = records[id]
    return (
      (isNoteableRecord(r) && r.notes.length === 0) ||
      !shouldDisplayNotesForRecordId(id)
    )
  })

  let notesOpened = 0
  let notesCreated = 0
  let notesClosed = 0
  let newNoteId: string | undefined
  if (toggleToVisible) {
    ;({ notesOpened, notesCreated, newNoteId } = openAnnotations(
      records,
      recordIds,
      shouldDisplayNotesForRecordId,
      addNoteAtBottomOfList,
      setNotesVisibilityForRecordIds,
      showAnnotationsTrigger,
      createAnnotationTrigger,
      setRecentlyCreatedRecords,
    ))
  } else {
    ;({ notesClosed } = closeAnnotations(
      records,
      recordIds,
      setNotesVisibilityForRecordIds,
    ))
  }

  return {
    notesOpened,
    notesCreated,
    notesClosed,
    newNoteId,
  }
}
