import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { useEffect, useState } from 'react'
import LoadingScreen from '../../screens/LoadingScreen'
import * as Analytics from '../analytics/capture-analytics-actions'
import { useAuth } from '../contexts/AuthContext'
import { useDesktopAuth } from '../contexts/DesktopAuthContext'
import { safeNavigate } from '../navigate'
import { LoginRoutePath, RouteType, RoutesPath } from '../routes'
import { getQueryParams } from './utils'

export function DesktopAuthenticateRoute(props: RouteComponentProps) {
  const { getSessionWithCodeVerifierAndMagicLink } = useDesktopAuth()
  const { authenticateViaSessionTokens } = useAuth()
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const params = getQueryParams()
  const token = params['eml_token']

  useEffect(() => {
    Analytics.authenticateScreenLoaded()
  }, [])

  useEffect(() => {
    if (isAuthenticating) return
    if (!token) return
    setIsAuthenticating(true)
    async function getTokensAndAuthenticate() {
      const sessionTokens = await getSessionWithCodeVerifierAndMagicLink(token)
      if (!sessionTokens) {
        safeNavigate(LoginRoutePath)
        return
      }
      await authenticateViaSessionTokens(sessionTokens)
      setIsAuthenticating(false)
      safeNavigate(RoutesPath[RouteType.Inbox])
    }
    getTokensAndAuthenticate()
  }, [token, getSessionWithCodeVerifierAndMagicLink])

  return <LoadingScreen />
}
