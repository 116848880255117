import { filter, isUndefined } from 'lodash'
import React, { useCallback, useMemo } from 'react'

import {
  Frack,
  ListRecord,
  RootRecord,
  RootRecordId,
} from '@eleventhlabs/capture-shared'

import { ModelStore } from '../../../common/data/modelStore'
import { alphabeticallyAscending } from '../../../common/data/utils'
import {
  RouteType,
  RoutesId,
  RoutesTitle,
  isSystemListRoute,
} from '../../../common/routes'

import { ItemType, ResultGroup } from '../QuickSwitcher.types'
import ResultItemContent from '../components/ResultItemContent'
import ResultItemPost from '../components/ResultItemPost'
import ResultItemPre from '../components/ResultItemPre'
import { TodayId } from '@eleventhlabs/capture-shared'

export interface GroupItem {
  id: string
  type: ItemType
  title: string
  emoji?: string
}

export const useQuickSwitcherData = (
  store: ModelStore,
  query: string | undefined,
  selectedIndex: number | undefined,
  recentPicks: string[],
): ResultGroup => {
  const root = store.records[RootRecordId] as RootRecord
  const groupItems: GroupItem[] = useMemo(() => {
    const filteredItems = filterItems(
      [
        ...getSystemItems(),
        ...listsToItems(
          Frack.toArray(root.children).map(
            (id) => store.records[id] as ListRecord,
          ),
        ),
      ],
      query ?? ``,
    )

    const sortedItems = filteredItems.sort((a, b) =>
      alphabeticallyAscending(a.title, b.title),
    )

    return filter(
      [
        ...recentPicks.map((id) => sortedItems.find((item) => item.id === id)),
        ...sortedItems.filter((item) => !recentPicks.includes(item.id)),
      ],
      (item) => !isUndefined(item),
    ) as GroupItem[]
  }, [root.children, store.records, query, recentPicks])

  const isSelected = useCallback(
    (index?: number) => index === selectedIndex,
    [selectedIndex],
  )

  const items = useMemo(
    () =>
      groupItems.map((item, index) => {
        return {
          id: item.id,
          type: item.type,
          renderPre: <ResultItemPre id={item.id} emoji={item.emoji} />,
          renderContent: (
            <ResultItemContent
              content={item.title}
              isActive={isSelected(index)}
            />
          ),
          renderPost: <ResultItemPost isActive={isSelected(index)} />,
          isSelected,
        }
      }),
    [groupItems, isSelected],
  )

  const result = useMemo(
    () => ({
      items,
    }),
    [items],
  )

  return result
}

const filterItems = (items: GroupItem[], query: string): GroupItem[] => {
  return items.filter((item) => {
    const queryLCase = query.toLowerCase()
    const title = item.title.toLowerCase()

    return queryLCase === `` || title.includes(queryLCase)
  })
}

const listsToItems = (lists: ListRecord[]): GroupItem[] => {
  return lists.map((list) => ({
    id: list.id,
    title: list.title,
    emoji: list.emoji,
    type: ItemType.Collection,
  }))
}

const getSystemItems = (): GroupItem[] => {
  return [RouteType.Inbox, RouteType.Today].map((routeType) =>
    createSystemListRouteAsItem(routeType),
  )
}

const createSystemListRouteAsItem = (
  routeType: RouteType | string,
): GroupItem => {
  return {
    id: isSystemListRoute(routeType) ? RoutesId[routeType] ?? `` : ``,
    title: isSystemListRoute(routeType) ? RoutesTitle[routeType] ?? `` : ``,
    type: ItemType.SystemListRoute,
  }
}
