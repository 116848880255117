import React from 'react'

import { Icon, styled } from '../common/stationary'

export interface CalendarDayIconProps {
  boxSize: number
  className?: string
  day?: number
}

export const CalendarDayIcon: React.FC<CalendarDayIconProps> = ({
  boxSize,
  className,
  day,
}) => {
  return (
    <S.CalendarDayIcon>
      <Icon boxSize={boxSize} className={className} variant="glyphCalEmpty" />
      <S.DayNumber>{day}</S.DayNumber>
    </S.CalendarDayIcon>
  )
}

const S = {
  CalendarDayIcon: styled.div({
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    position: `relative`,
  }),
  /**
   * @todo Scale day number size/position based on boxSize
   */
  DayNumber: styled.div({
    position: `absolute`,
    top: 2,
    left: 0,
    fontSize: 8.5,
    fontWeight: `bold`,
    display: `flex`,
    width: `100%`,
    height: `100%`,
    alignItems: `center`,
    justifyContent: `center`,
  }),
}
