import { muiMenuItemStyles, muiMenuStyles } from '@eleventhlabs/stationary'
import {
  MenuItemUnstyled,
  MenuItemUnstyledProps,
  MenuUnstyled,
  MenuUnstyledListboxSlotProps,
  MenuUnstyledProps,
} from '@mui/base'
import React from 'react'

import { styled } from '../styled'
import { Icon, IconProps } from './Icon'
import { Keycap, KeycapGroup } from './Keycap'
import { Switch, SwitchProps } from './Switch'

export type BasicMenuItemProps = MenuItemUnstyledProps

export const BasicMenuItem = styled(MenuItemUnstyled)(({ theme }) => ({
  ...muiMenuItemStyles.root({ theme }),
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
  gap: 8,
  height: 32,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: 6,
  ...theme.text.publicSans[`13.5:20`],
}))

export interface MenuItemProps extends Omit<BasicMenuItemProps, 'title'> {
  iconProps?: Omit<IconProps, 'boxSize'> & { boxSize?: number }
  title?: React.ReactNode
  keycaps?: React.ReactNode[]
  switchProps?: SwitchProps
}

export const MenuItem: React.FC<MenuItemProps> = ({
  iconProps,
  title,
  keycaps,
  switchProps,
  ...props
}) => (
  <BasicMenuItem {...props}>
    {iconProps && (
      <MenuItemIcon boxSize={iconProps?.boxSize ?? 14} {...iconProps} />
    )}
    {title && <MenuItemTitle>{title}</MenuItemTitle>}
    {keycaps && (
      <KeycapGroup>
        {keycaps.map((keycap, index) => (
          <Keycap key={index}>{keycap}</Keycap>
        ))}
      </KeycapGroup>
    )}
    {switchProps && <MenuItemSwitch {...switchProps} />}
  </BasicMenuItem>
)

const MenuItemIcon = styled(Icon)({})

const MenuItemTitle = styled.div({
  flex: 1,
  display: `flex`,
  alignItems: `center`,
  gap: 6,
  paddingRight: 8,
})

const MenuItemSwitch = styled(Switch)({})

export const MenuDivider = styled.li(({ theme }) => ({
  height: 1,
  marginTop: 4,
  marginBottom: 4,
  marginLeft: 8,
  marginRight: 8,
  background: theme.colors.alpha.border.weak,
}))

const MenuListbox = styled.ul(({ theme }) => ({
  ...muiMenuStyles.listbox({ theme }),
  padding: 4,
  borderRadius: 8,
}))

export type MenuProps = MenuUnstyledProps

export const Menu: React.FC<MenuProps> = ({ slots, ...props }) => (
  <MenuUnstyled slots={{ listbox: MenuListbox, ...slots }} {...props} />
)

type MenuActionType = `menuItem` | `divider`

export interface MenuAction {
  type: MenuActionType
  menuItemProps?: MenuItemProps
}
