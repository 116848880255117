import { every } from 'lodash'
import { useCallback } from 'react'

import { useModelStoreContext } from '../contexts/ModelStoreContext'
import { useUIContext } from '../contexts/UIContext'
import { RecordItemExpandStateDict } from './useRecordItemExpandState'
import { getIsExpanded } from './utils'

const animate = false

export const useCollapseRecordItems = () => {
  const { setRecordItemExpandState } = useUIContext()
  return useCallback(
    (ids: string[], isMaxHeightInTransition: boolean = animate) => {
      for (const id of ids) {
        setRecordItemExpandState((prev) => ({
          ...prev,
          [id]: { isExpanded: false, isMaxHeightInTransition },
        }))
      }
    },
    [setRecordItemExpandState],
  )
}

export const useExpandRecordItems = () => {
  const { setRecordItemExpandState } = useUIContext()
  return useCallback(
    (ids: string[], isMaxHeightInTransition: boolean = animate) => {
      for (const id of ids) {
        setRecordItemExpandState((prev) => ({
          ...prev,
          [id]: { isExpanded: true, isMaxHeightInTransition },
        }))
      }
    },
    [setRecordItemExpandState],
  )
}

export const useToggleRecordItemsIsExpanded = () => {
  const { records } = useModelStoreContext().store
  const { setRecordItemExpandState } = useUIContext()
  return useCallback(
    (
      ids: string[],
      isMaxHeightInTransition: boolean = animate,
    ): {
      recordsExpanded: string[]
      recordsCollapsed: string[]
    } => {
      let recordsExpanded: string[] = []
      let recordsCollapsed: string[] = []
      setRecordItemExpandState((prev) => {
        const isExpandedValues = ids.map((id) =>
          getIsExpanded(prev, records[id]),
        )
        const toggleTo = every(isExpandedValues) ? false : true

        const toUpdate: RecordItemExpandStateDict = {}
        for (const id of ids) {
          toUpdate[id] = { isExpanded: toggleTo, isMaxHeightInTransition }
          if (toggleTo) {
            recordsExpanded.push(id)
          } else {
            recordsCollapsed.push(id)
          }
        }

        return {
          ...prev,
          ...toUpdate,
        }
      })

      return {
        recordsExpanded,
        recordsCollapsed,
      }
    },
    [records, setRecordItemExpandState],
  )
}
