import { CSSObject, keyframes } from '../styled'

export const spinAnimationStyles = ({
  animationDuration,
  animationTimingFunction = `linear`,
}: {
  animationDuration: string
  animationTimingFunction?: string
}): CSSObject => ({
  animationName: keyframes({
    from: { transform: `rotate(0deg)` },
    to: { transform: `rotate(360deg)` },
  }),
  animationDuration,
  animationTimingFunction,
  animationIterationCount: `infinite`,
})

export const gradientAnimationStyles = ({
  animationDuration,
  animationTimingFunction = `ease`,
}: {
  animationDuration: string
  animationTimingFunction?: string
}): CSSObject => ({
  animationName: keyframes({
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  }),
  animationDuration,
  animationTimingFunction,
  animationIterationCount: `infinite`,
})

export const bounceAnimationStyles = ({
  animationDuration,
  animationTimingFunction = `linear`,
}: {
  animationDuration: string
  animationTimingFunction?: string
}): CSSObject => ({
  animationName: keyframes({
    '66%': { transform: 'translateY(0%)' },
    '77%': { transform: 'translateY(-18%)' },
    '88%': { transform: 'translateY(0%)' },
    '94%': { transform: 'translateY(-9%)' },
    '97%': { transform: 'translateY(0%)' },
    '99%': { transform: 'translateY(-5%)' },
    '100%': { transform: 'translateY(0)' },
  }),
  animationDuration,
  animationTimingFunction,
  animationIterationCount: `infinite`,
})
