import {
  CURRENT_RICH_TEXT_VERSION,
  RichText,
} from '@eleventhlabs/capture-shared'
import { UpdateNoteRecordPayload } from '@eleventhlabs/capture-shared'

export const noteChange = (
  value: RichText,
  noteId: string,
  updateNote: (id: string, payload: UpdateNoteRecordPayload) => void,
) => {
  updateNote(noteId, {
    richText: { value, version: CURRENT_RICH_TEXT_VERSION },
  })
}
