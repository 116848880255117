import React, { useCallback } from 'react'

import {
  Button,
  Icon,
  MenuAction,
  MenuDivider,
  MenuItem,
  Switch,
  styled,
  withMenu,
} from '../../../common/stationary'

export interface MoreActionMenuProps {
  actions: MenuAction[]
  open: boolean
  setOpen: (open: boolean) => void
  toggleSetOpen: () => void
}

export const MoreActionMenu: React.FC<MoreActionMenuProps> = ({
  actions,
  open,
  setOpen,
  toggleSetOpen,
}) => {
  const toggle = useCallback(
    (event: React.MouseEvent) => {
      event.nativeEvent.stopImmediatePropagation()
      toggleSetOpen()
    },
    [toggleSetOpen],
  )

  const close = useCallback(() => setOpen(false), [setOpen])

  return (
    <S.MenuPopoverButton
      onClick={toggle}
      menuProps={{
        children: actions.map(({ type, menuItemProps }, index) => {
          switch (type) {
            case 'menuItem':
              if (menuItemProps?.switchProps) {
                return (
                  <S.Switch key={index}>
                    {menuItemProps?.title}
                    <Switch {...menuItemProps.switchProps} />
                  </S.Switch>
                )
              }
              return <S.MenuItem key={index} {...menuItemProps} />
            case 'divider':
              return <MenuDivider />
            default:
              return null
          }
        }),
        open: open,
        onClose: close,
      }}
      popperProps={{
        offset: [4, 4],
        placement: `bottom-end`,
      }}
      variant="plain"
    >
      <Icon boxSize={20} variant="glyphDotsHorizontal" />
    </S.MenuPopoverButton>
  )
}

export default MoreActionMenu

const S = {
  Switch: styled.label(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    padding: '8px 12px',
    borderRadius: 8,
    ...theme.text.publicSans['13.5:20'],
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',

    '&:hover': {
      background: theme.colors.alpha.tone.weak,
    },
    '&:active': {
      background: theme.colors.alpha.tone.mediumWeak,
    },
  })),
  MenuPopoverButton: styled(withMenu(Button))(({ theme }) => ({
    display: `flex`,
    width: 40,
    height: 40,
    borderRadius: 8,
    color: theme.colors.text[600],

    '&:hover': {
      color: theme.colors.text[900],
    },
  })),
  MenuItem: styled(MenuItem)(({ theme }) => ({
    '&:last-of-type:not(:only-of-type)': {
      color: theme.colors.red[700],
    },
  })),
}
