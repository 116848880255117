import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'

import { convertISOtoDisplay } from '../../../../common/utils'
import { TimeModal } from './TimeModal'
import { useTimeInput } from './useTimeInput'
import { Icon, cx, styled } from '../../../../common/stationary'

export interface TimeInputProps {
  value: string | undefined
  setTime: (time: string | null) => void
}

export const TimeInput = ({ value = ``, setTime }: TimeInputProps) => {
  const [modalVisible, setModalVisible] = useState(false)

  const toggleModal = useCallback(() => setModalVisible((value) => !value), [])
  const closeModal = useCallback(() => setModalVisible(false), [])

  const clearTime = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      setTime(null)
    },
    [setTime],
  )

  const { inputValue, onKeyDown, onChange, onSubmit, isValidInput } =
    useTimeInput(value, setTime, closeModal)

  const validInput = isValidInput(inputValue)

  return (
    <S.Container>
      <S.Tag
        className={cx({
          active: modalVisible,
        })}
        onMouseDown={toggleModal}
      >
        <S.TagIcon
          boxSize={16}
          variant="glyphCircleClock"
          className={cx({
            isEmpty: isEmpty(value),
          })}
        />
        <S.TagText
          className={cx({
            isEmpty: isEmpty(value),
          })}
        >
          {!isEmpty(value) ? convertISOtoDisplay(value) : `No time`}
        </S.TagText>
        {!isEmpty(value) ? (
          <S.ClearTime onMouseDown={clearTime}>
            <Icon boxSize={16} variant="glyphX" />
          </S.ClearTime>
        ) : null}
      </S.Tag>
      {modalVisible ? (
        <S.TimeModal
          value={inputValue}
          validInput={validInput}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onSubmit={onSubmit}
          close={closeModal}
        />
      ) : null}
    </S.Container>
  )
}

const S = {
  Container: styled.div({
    position: `relative`,
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    userSelect: `none`,
  }),
  Tag: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: `4px 6px`,
    marginRight: 4,
    cursor: `pointer`,
    border: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    borderRadius: 6,
    userSelect: `none`,

    '&.active': {
      background: theme.colors.surface.lowest,
      border: `1px solid ${theme.colors.alpha.border.mediumStrong}`,
    },
  })),
  TagIcon: styled(Icon)(({ theme }) => ({
    display: `flex`,
    marginRight: 4,
    fontWeight: 500,
    color: theme.colors.text[900],

    '&.isEmpty': {
      color: theme.colors.text[700],
    },
  })),
  TagText: styled.div(({ theme }) => ({
    display: `flex`,
    fontWeight: 500,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    color: theme.colors.text[900],

    '&.isEmpty': {
      color: theme.colors.text[700],
    },
  })),
  ClearTime: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    paddingLeft: 4,
    marginLeft: 6,
    height: 12,
    borderLeft: `1px solid ${theme.colors.alpha.border.medium}`,
    color: theme.colors.text[900],
  })),
  TimeModal: styled(TimeModal)({
    position: `absolute`,
    top: 34,
    left: -12,
  }),
}
