import React, { useMemo } from 'react'

import MoveToDialog from '../../components/MoveToDialog'
import { useDialogContext } from '../contexts/DialogContext'
import { useModelStoreContext } from '../contexts/ModelStoreContext'
import { useUIContext } from '../contexts/UIContext'
import useSendToDestination from '../hooks/useSendToDestinations'
import { RootRecord, RootRecordId } from '@eleventhlabs/capture-shared'
import { DialogType } from './types'

const useMoveToDialog = (
  selectedRecordIds: string[],
  isViewVisible: (action: DialogType) => boolean,
) => {
  const { store, commands } = useModelStoreContext()
  const { isMouseRecentlyActive } = useUIContext()
  const root = store.records[RootRecordId] as RootRecord
  const sendToDestinationData = useSendToDestination(
    store.records,
    root,
    commands,
  )

  const { closeDialog } = useDialogContext()

  const isOpen = isViewVisible(DialogType.MoveTo)

  const moveToComponent = useMemo(
    () =>
      isOpen && sendToDestinationData ? (
        <MoveToDialog
          isOpen={isOpen}
          isMouseRecentlyActive={isMouseRecentlyActive}
          onDismiss={closeDialog}
          selectedRecordIds={selectedRecordIds}
          sendToDestinationData={sendToDestinationData}
        />
      ) : null,
    [
      selectedRecordIds,
      isOpen,
      isMouseRecentlyActive,
      closeDialog,
      sendToDestinationData,
    ],
  )

  return {
    moveToComponent,
  }
}

export default useMoveToDialog
