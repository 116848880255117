import { useEffect } from 'react'
import { useExecContext } from '../../contexts/ExecContext'
import { ExecAction } from '../../useExec'
import { getPrivateUserChannelName } from '../utils'
import { ws } from '../websocket'
import { WSEventName } from '../websocketConfig'
import { EditorsManager } from '../../EditorsManager'

/**
 * Subscribes to a private user websocket channel so the client can receive authenticated messages.
 *
 * @param {string} userId - The ID of the user.
 * @return {void} This function does not return anything.
 */
export const useSubscribeToClientWebsocketChannel = (userId: string | null) => {
  const { exec } = useExecContext()

  useEffect(() => {
    if (userId) {
      ws.subscribe(getPrivateUserChannelName(userId))

      subscribeToDefaultEvents(exec, userId)

      return () => ws.unsubscribe(getPrivateUserChannelName(userId))
    }
  }, [userId, exec])
}

const subscribeToDefaultEvents = (exec: ExecAction, userId: string) => {
  // Blur the record when another client starts editing it
  ws.bindEvent(getPrivateUserChannelName(userId), {
    eventName: WSEventName.RECORD_EDITING_STARTED,
    callback: (payload) => {
      EditorsManager.effects.blur(payload.recordId)
    },
  })
}
