import React from 'react'
import { ListChildComponentProps } from 'react-window'

import { ResultListItemData } from '../CommandBar.types'
import { ResultItemProps } from '../components/ResultItem'

export interface ResultListItemRowProps
  extends Omit<ListChildComponentProps, 'style'> {
  data: ResultListItemData
}

export const withPropsForSingleRow = <T,>(
  WrappedComponent: React.FC<ResultItemProps & { index: number }>,
) => {
  const ResultListItemRow: React.FC<T & ResultListItemRowProps> = (props) => {
    const { items, onHover, handleResultClick } = props.data
    const item = items[props.index]

    return (
      <WrappedComponent
        index={props.index}
        isActive={item.isSelected ? item.isSelected(props.index) : false}
        item={item}
        onHover={onHover}
        handleResultClick={handleResultClick}
      />
    )
  }

  return ResultListItemRow
}
