import { find, findIndex, isEmpty, keys, take, uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Frack, RootRecord, RootRecordId } from '@eleventhlabs/capture-shared'

import Analytics, {
  ScreenTypeValueType,
} from '../../../common/analytics/capture-analytics-actions'
import {
  getScreenNavigationStartedScreenTypeFromRouteType,
  useActiveScreen,
} from '../../../common/analytics/capture-analytics-web'
import { useLocalStorage } from '../../../common/contexts/LocalStorageContext'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { useUIContext } from '../../../common/contexts/UIContext'
import { scrollAction } from '../../../common/events'
import { useNavigateToList } from '../../../common/hooks/useNavigateToList'
import { LocalStorageItemSetter } from '../../../common/libs'
import { SystemListsRouteType } from '../../../common/routes'
import { emptyFn } from '../../../common/utils'
import {
  HandleResultPayload,
  ItemType,
  QuickSwitcherHandlers,
  ResultGroup,
  ResultGroupItem,
} from '../QuickSwitcher.types'
import { useQuickSwitcherData } from './useQuickSwitcherData'

interface UseQuickSwitcherData {
  query?: string
  groupData: ResultGroup
  handlers: QuickSwitcherHandlers
}

export const useQuickSwitcher = (
  close: () => void = emptyFn,
): UseQuickSwitcherData => {
  const didMountRef = useRef(false)
  const [query, setQuery] = useState<string | undefined>()
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(0)
  const [recentPicksStr, setRecentPicksStr] = useLocalStorage(
    `recent-quick-switch-picks`,
  )

  const navigateToList = useNavigateToList()
  const { store } = useModelStoreContext()
  const root = useMemo(
    () => store.records[RootRecordId] as RootRecord,
    [store.records],
  )
  const recentPicks = useMemo(() => {
    const itemIds = [
      ...Frack.toArray(root.children),
      ...keys(SystemListsRouteType),
    ]
    return decodeRecentPicks(itemIds, recentPicksStr)
  }, [root, recentPicksStr])
  const groupData = useQuickSwitcherData(
    store,
    query,
    selectedIndex,
    recentPicks,
  )

  useEffect(() => {
    // avoid scrolling on first render
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }
    // we keep the selected element always visible
    if (selectedIndex != undefined)
      scrollAction({ target: `quick-switch`, to: { index: selectedIndex } })
  }, [selectedIndex])

  const activeScreen = useActiveScreen()

  const handleCollectionClick = useCallback(
    (id?: string) => {
      const itemId = id ?? getItemId(groupData.items, selectedIndex)
      if (itemId) {
        navigateToList(itemId)
        Analytics.screenNavigationStarted({
          activeScreen,
          screenNavigationStartedTrigger:
            Analytics.ScreenNavigationStartedTrigger.QUICK_SWITCHER,
          screenType: getScreenNavigationStartedScreenTypeFromRouteType(
            itemId,
          ) as ScreenTypeValueType,
        })
        close()
      }
    },
    [activeScreen, selectedIndex, groupData.items, close, navigateToList],
  )

  const handleResultClick = useCallback(
    (payload?: HandleResultPayload) => {
      const itemId = payload?.id ?? getItemId(groupData.items, selectedIndex)
      if (itemId && isCollection(groupData.items, itemId)) {
        savePickIntoRecent(recentPicks, itemId, setRecentPicksStr)
        handleCollectionClick(itemId)
      }
    },
    [
      groupData.items,
      selectedIndex,
      recentPicks,
      handleCollectionClick,
      setRecentPicksStr,
    ],
  )

  const onBlur = useCallback(() => setSelectedIndex(0), [])

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((event.target as any)?.value)
    // when the query changes, it will always select the first result
    setSelectedIndex(0)
  }, [])

  const itemsCount = groupData.items.length
  const increaseIndex = useCallback(() => {
    const newIndex = (selectedIndex ?? -1) + 1
    setSelectedIndex(newIndex >= itemsCount ? itemsCount - 1 : newIndex)
  }, [selectedIndex, itemsCount])
  const decreaseIndex = useCallback(() => {
    const newIndex = (selectedIndex ?? 0) - 1
    setSelectedIndex(newIndex < 0 ? 0 : newIndex)
  }, [selectedIndex])

  const handleSearchInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case `Tab`:
          event.preventDefault()
          break
        case `ArrowDown`: {
          if (event.shiftKey) return
          event.preventDefault()
          increaseIndex()
          break
        }
        case `ArrowUp`: {
          if (event.shiftKey) return
          event.preventDefault()
          decreaseIndex()
          break
        }
        case `Enter`: {
          event.preventDefault()
          if (selectedIndex === undefined) break
          handleResultClick()
          break
        }
        case `Escape`:
          event.preventDefault()
          if (isEmpty(query)) close()
          else setQuery(``)
          break
        default:
      }
    },
    [
      query,
      selectedIndex,
      decreaseIndex,
      increaseIndex,
      handleResultClick,
      close,
    ],
  )

  const { isMouseRecentlyActive } = useUIContext()
  const onHover = useCallback(
    (id: string) => {
      if (!isMouseRecentlyActive) return
      const index = findIndex(groupData.items, (item) => item.id === id)
      setSelectedIndex(index)
    },
    [groupData.items, isMouseRecentlyActive],
  )

  const onDownArrow = useCallback(() => increaseIndex(), [increaseIndex])
  const onUpArrow = useCallback(() => decreaseIndex(), [decreaseIndex])
  const onEnter = useCallback(() => handleResultClick(), [handleResultClick])
  const onEsc = useCallback(() => close(), [close])

  const result = useMemo(
    () => ({
      handlers: {
        onDownArrow,
        onUpArrow,
        onEnter,
        onEsc,
        onChange,
        onBlur,
        onHover,
        handleSearchInputKeyDown,
        handleResultClick,
      },
      groupData,
      query,
    }),
    [
      onDownArrow,
      onUpArrow,
      onEnter,
      onEsc,
      onChange,
      onBlur,
      onHover,
      handleSearchInputKeyDown,
      handleResultClick,
      groupData,
      query,
    ],
  )

  return result
}

const isCollection = (items: ResultGroupItem[], id?: string): boolean => {
  const item = find(items, (item) => item.id === id)
  return (
    id != undefined &&
    (item?.type === ItemType.Collection ||
      item?.type === ItemType.SystemListRoute)
  )
}

const getItemId = (items: ResultGroupItem[], index?: number) => {
  return index != undefined && items[index] ? items[index].id : undefined
}

const MAX_RECENT_PICKS = 100

const savePickIntoRecent = (
  recentPicks: string[],
  newPick: string,
  setRecentPicksStr: LocalStorageItemSetter,
) => {
  const cleanedRecentPicks = take(
    uniq([newPick, ...recentPicks]),
    MAX_RECENT_PICKS,
  )
  setRecentPicksStr(JSON.stringify(cleanedRecentPicks))
}

const decodeRecentPicks = (
  itemIds: string[],
  recentPicksStr: string | null,
): string[] => {
  if (!recentPicksStr) return []

  const recentPicks = JSON.parse(recentPicksStr) as string[]
  return recentPicks.filter((id) => itemIds.includes(id))
}
