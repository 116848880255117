import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { useLayoutEffect, useState } from 'react'
import BrandLogoMark from '../../assets/brand/brand-favicon.svg'
import BrandLogotype from '../../assets/brand/brand-logotype.svg'
import * as Analytics from '../../common/analytics/capture-analytics-actions'
import { safeNavigate } from '../../common/navigate'
import { RouteType, RoutesPath } from '../../common/routes'
import {
  gradientAnimationStyles,
  gradientBackgroundStyles,
} from '../../common/stationary/mixinStyles'
import { cx, styled } from '../../common/stationary/styled'
import { isMobile } from '../../common/utils/env'
import { useAuth } from '../../providers'
import LoadingScreen from '../LoadingScreen'
import WaitingForAuth from '../LoginScreen/components/WaitingForAuth'
import { RegistrationForm, RegistrationFormProps } from './RegistrationForm'
import RegistrationError from './components/RegistrationError'
import { RegistrationDemoInbox } from './components/RegistrationInbox'
import { RequestState, useRegistrationScreen } from './useRegistrationScreen'
import { RegistrationWelcomeModal } from './components/RegistrationWelcomeModal'

export enum RegistrationScreenState {
  TOKEN_ERROR,
  EMAIL_LOADING,
  EMAIL_ERROR,
  FORM_READY,
  SUBMIT_LOADING,
  SUBMIT_SUCCESS,
  SUBMIT_ERROR,
  LOADING,
}

/**
 * RegistrationScreen
 */

export const RegistrationScreen: React.FC<RouteComponentProps> = () => {
  const {
    isRegistrationTokenValid: isTokenOk,
    isRegistrationTokenValidRequestState: isTokenOkState,
    email: tokenEmail,
    submitRegistration: submitReg,
    submitRegistrationRequestState: submitRegState,
    submitRegistrationErr: submitRegErrMsg,
    resetSubmitErrors,
    inviteCode,
    registrationToken,
  } = useRegistrationScreen()

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(
    inviteCode !== null,
  )

  const [name, setName] = useState('')
  const [registrationSubmittedEmail, setRegistrationSubmittedEmail] =
    useState(tokenEmail)

  const { isLoggedIn } = useAuth()
  useLayoutEffect(() => {
    if (isLoggedIn) {
      safeNavigate(RoutesPath[RouteType.Inbox])
    }
  }, [isLoggedIn])

  // Derive screenState using data from useRegistrationScreen
  let screenState

  if (
    isTokenOkState === RequestState.NONE ||
    isTokenOkState === RequestState.LOADING
  )
    return <LoadingScreen />

  if (!isTokenOk && !inviteCode)
    screenState = RegistrationScreenState.TOKEN_ERROR
  else if (inviteCode && submitRegState === RequestState.NONE)
    screenState = RegistrationScreenState.FORM_READY
  else if (submitRegState === RequestState.NONE)
    screenState = RegistrationScreenState.FORM_READY
  else if (submitRegState === RequestState.LOADING)
    screenState = RegistrationScreenState.SUBMIT_LOADING
  else if (submitRegState === RequestState.SUCCESS)
    screenState = RegistrationScreenState.SUBMIT_SUCCESS
  else if (submitRegState === RequestState.ERROR)
    screenState = RegistrationScreenState.SUBMIT_ERROR

  // On TOKEN_ERROR, render RegistrationError
  if (screenState === RegistrationScreenState.TOKEN_ERROR)
    return <RegistrationError />

  // On unknown errors, render RegistrationError
  if (screenState === undefined) return <RegistrationError />

  const resetErrors = () => {
    resetSubmitErrors()
  }

  const handleFormSubmit: RegistrationFormProps['onSubmit'] = (
    email,
    fullName,
    password,
  ) => {
    Analytics.submittedRegistration()
    setRegistrationSubmittedEmail(email)
    submitReg({
      email,
      firstName: fullName,
      password: password !== '' ? password : undefined,
    })
  }

  const isSuccessScreen = screenState === RegistrationScreenState.SUBMIT_SUCCESS

  return (
    <S.RegistrationScreen>
      <S.ColumnContainer>
        <S.ColumnOne
          className={cx({
            isSuccessScreen,
          })}
        >
          <S.HeaderInner>
            <S.LogotypeAnchor href="/">
              {isMobile ? (
                <S.MobileLogotype className={cx({ isSuccessScreen })} />
              ) : (
                <S.Logotype className={cx({ isSuccessScreen })} />
              )}
            </S.LogotypeAnchor>
          </S.HeaderInner>
          <S.Main>
            {isSuccessScreen ? (
              <S.WaitingForAuth
                emailAddress={registrationSubmittedEmail ?? ''}
              />
            ) : (
              <S.RegistrationForm
                errorMsg={submitRegErrMsg ?? undefined}
                inviteCode={inviteCode}
                onSubmit={handleFormSubmit}
                registrationToken={registrationToken}
                resetErrors={resetErrors}
                screenState={screenState}
                setName={setName}
                tokenEmail={tokenEmail || ''}
              />
            )}
          </S.Main>
        </S.ColumnOne>
        <S.ColumnTwo
          className={cx({
            isSuccessScreen,
          })}
        >
          <S.InboxContainer>
            <RegistrationDemoInbox name={name} />
          </S.InboxContainer>
          <S.Footer>
            <S.LinksAndCopyright>
              <S.Links>
                <S.Link href="mailto:membership@capture.so">Contact Us</S.Link>
                <S.Link href="/privacy">Privacy</S.Link>
              </S.Links>
              <S.Copyright>{COPYRIGHT_TEXT}</S.Copyright>
            </S.LinksAndCopyright>
          </S.Footer>
        </S.ColumnTwo>
      </S.ColumnContainer>
      <RegistrationWelcomeModal
        onClose={() => setIsWelcomeModalOpen(false)}
        open={isWelcomeModalOpen}
      />
    </S.RegistrationScreen>
  )
}

const COPYRIGHT_TEXT = '© 2023 Eleventh, Inc.'

/**
 * Styled
 */

const S = {
  RegistrationScreen: styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
  })),

  Banner: styled.div(({ theme }) => ({
    position: 'relative',
    height: 160,

    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: -80,
      left: 0,
      right: 0,
      boxShadow: `0 -1px 0 inset ${theme.colors.alpha.border.extraWeak}`,
      ...gradientBackgroundStyles({ theme }),
      ...gradientAnimationStyles({ animationDuration: '30s' }),
    },
  })),
  Header: styled.header(() => ({
    position: 'relative',
  })),
  HeaderInner: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: isMobile ? 'center' : 'space-between',
    gap: 8,
    height: 30,
    margin: isMobile ? '60px 0px 32px 0px' : `-90px 0px 60px 0px`,
    width: 368,
  })),
  LogotypeAnchor: styled.a(() => ({
    display: 'flex',
  })),
  Logotype: styled(BrandLogotype)(() => ({
    height: 28,
    '&.isSuccessScreen': {
      display: 'none',
    },
  })),
  MobileLogotype: styled(BrandLogoMark)(() => ({
    height: 64,
    marginTop: -32,
    '&.isSuccessScreen': {
      display: 'none',
    },
  })),
  UserEmailAddress: styled.div(({ theme }) => ({
    padding: '10px 16px',
    borderRadius: 8,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 700,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    background: theme.colors.alpha.tone.extraWeak,
    color: theme.colors.text[800],
  })),

  Main: styled.main(() => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 80,
  })),
  RegistrationForm: styled(RegistrationForm)(() => ({
    marginBottom: 32,
  })),
  ContactUs: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    height: 24,
    width: '100%',
    maxWidth: 416,
    padding: '0 32px',
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[700],

    a: {
      fontWeight: 600,

      ':hover': {
        textDecoration: 'underline',
        color: theme.colors.text[900],
      },
    },
  })),
  WaitingForAuth: styled(WaitingForAuth)(() => ({
    maxWidth: 384,
    paddingTop: 48,
  })),

  Footer: styled.footer(() => ({
    position: 'absolute',
    bottom: 24,
    right: 32,
  })),
  LinksAndCopyright: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    height: 32,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[500],
  })),
  Links: styled.div(() => ({
    display: 'flex',
    gap: 16,
    fontWeight: 600,
  })),
  Link: styled.a(({ theme }) => ({
    ':hover': {
      textDecoration: 'underline',
      color: theme.colors.text[700],
    },
  })),
  Copyright: styled.div(() => ({})),

  RegistrationError: styled.div(() => ({})),
  ColumnContainer: styled.div(() => ({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
  })),
  ColumnOne: styled.div(({ theme }) => ({
    height: '100vh',
    width: '50vw',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '@media only screen and (max-width: 768px)': {
      width: '100vw',
    },

    '&.isSuccessScreen': {
      width: '100vw',
    },
  })),
  ColumnTwo: styled.div(({ theme }) => ({
    height: '100vh',
    width: '50vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...gradientBackgroundStyles({ theme }),
    ...gradientAnimationStyles({ animationDuration: '30s' }),

    '@media only screen and (max-width: 768px)': {
      display: 'none',
    },

    '&.isSuccessScreen': {
      display: 'none',
    },
  })),
  InboxContainer: styled.div(({}) => ({
    maxWidth: 668,
    width: '70%',
  })),
}
