import { cx } from '@emotion/css'
import { PasswordStrengthCheckResponse } from '@stytch/vanilla-js'
import React from 'react'
import { styled } from '../../../common/stationary'
import { ERROR_THRESHOLD, TOTAL_POSSIBLE_SCORE } from './ResetPasswordForm'

interface PasswordStengthAndHintDisplayProps {
  passwordStrengthCheckResponse?: PasswordStrengthCheckResponse
}

const STRONG_PASSWORD_MESSAGE = 'Great job! This is a strong password'

const PasswordStrengthAndHintDisplay: React.FC<
  PasswordStengthAndHintDisplayProps
> = ({ passwordStrengthCheckResponse }) => {
  const breached = passwordStrengthCheckResponse?.breached_password
  const score = breached
    ? ERROR_THRESHOLD
    : passwordStrengthCheckResponse?.score
  const isStrong = score === TOTAL_POSSIBLE_SCORE && !breached
  const isValid = passwordStrengthCheckResponse?.valid_password

  const buildScoreDisplay = () => {
    let sections = []
    for (let i = 0; i < TOTAL_POSSIBLE_SCORE; i++) {
      const isErrorSection = score && i < score && !isValid
      const isSuccessSection = score && i < score && isValid
      // Generally speaking, we should not use the index
      // as the key, however, since the number and
      // order in this case is stable, this should be a non issue
      if (isErrorSection) {
        sections.push(
          <S.StrengthIndicatorSection
            key={i}
            className={cx({ isErrorSection })}
          />,
        )
      } else if (isSuccessSection) {
        sections.push(
          <S.StrengthIndicatorSection
            key={i}
            className={cx({ isSuccessSection })}
          />,
        )
      } else {
        sections.push(<S.StrengthIndicatorSection key={i} />)
      }
    }
    return <>{sections}</>
  }

  return (
    <>
      <S.StrengthContainer>{buildScoreDisplay()}</S.StrengthContainer>
      <S.StrengthHintContainer className={cx({ isStrong })}>
        {isStrong
          ? STRONG_PASSWORD_MESSAGE
          : passwordStrengthCheckResponse?.feedback.warning}
      </S.StrengthHintContainer>
    </>
  )
}

export default PasswordStrengthAndHintDisplay

const S = {
  StrengthContainer: styled.div(() => ({
    flex: '1 1 auto',
    display: 'flex',
    gap: 2,
    height: 6,
    width: '100%',
    marginBottom: 8,
  })),
  StrengthIndicatorSection: styled.div(({ theme }) => ({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    height: 4,
    width: '25%',
    backgroundColor: theme.colors.alpha.tone.medium,

    '&.isErrorSection': {
      backgroundColor: theme.colors.red[900],
    },

    '&.isSuccessSection': {
      backgroundColor: theme.colors.green[900],
    },
  })),
  StrengthHintContainer: styled.div(({ theme }) => ({
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 300,
    color: theme.colors.red[900],
    minHeight: 28,
    lineHeight: 1,

    '&.isStrong': {
      color: theme.colors.green[900],
    },
  })),
}
