import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { SendIntent } from 'send-intent'

export interface IntentItem {
  title?: string
  type?: string
  url?: string
}

export interface IntentContextValue {
  items: IntentItem[]
  enqueue: (item: IntentItem) => void
  dequeue: () => IntentItem | undefined
}

/*
 * Context
 */

const defaultIntentContextValue: IntentContextValue = {
  items: [],
  enqueue: () => undefined,
  dequeue: () => undefined,
}

export const IntentContext = createContext(defaultIntentContextValue)
IntentContext.displayName = 'IntentContext'

/*
 * Provider
 */

export interface IntentProviderProps {
  children: ReactNode
}

export const IntentProvider = ({ children }: IntentProviderProps) => {
  const context = useIntentContextValue()

  return (
    <IntentContext.Provider value={context}>{children}</IntentContext.Provider>
  )
}

/*
 * Hooks
 */

export const useIntentContextValue = (): IntentContextValue => {
  const [items, setItems] = useState(defaultIntentContextValue.items)

  const enqueue = (item: IntentItem) => setItems([...items, item])

  const dequeue = () => {
    const item = items.shift()
    setItems(item ? [...items] : items)
    return item
  }

  return { items, enqueue, dequeue }
}

export const useIntentContext = (): IntentContextValue =>
  useContext(IntentContext)

export type OnIntentReceived = (
  item: IntentItem,
  context: IntentContextValue,
) => void

export const useShareIntent = (onIntentReceived: OnIntentReceived) => {
  const context = useIntentContext()

  useEffect(() => {
    if (isEnabledForUserAgent()) {
      window.addEventListener('sendIntentReceived', () => {
        SendIntent.checkSendIntentReceived().then((item: IntentItem) => {
          onIntentReceived(item, context)
        })
      })
    }
  }, [])
}

// export const useShortcutIntent = (onIntentReceived: OnIntentReceived) => {}

export type OnIntentEffect = (item: IntentItem) => void

export const useIntentEffect = (onIntentEffect: OnIntentEffect) => {
  const { items, dequeue } = useIntentContext()

  useEffect(() => {
    const item = dequeue()
    if (item) onIntentEffect(item)
  }, [items])
}

/*
 * Helpers
 */

const isEnabledForUserAgent = () => window.navigator.userAgent === 'capacitor'
