import React from 'react'

import {
  SelectionContextValue,
  useSelectionContext,
} from '../../common/contexts/SelectionContext'

export interface WithSelection extends SelectionContextValue {}

export function withSelection<T>(
  WrappedComponent: React.FC<T & WithSelection>,
): React.FC<T> {
  const ComponentWithSelection = (props: T) => {
    const contextValue = useSelectionContext()

    return <WrappedComponent {...props} {...contextValue} />
  }

  ComponentWithSelection.displayName = `ComponentWithSelection`

  return ComponentWithSelection
}
