type Version = {
  major: number
  minor: number
  patch: number
  prerelease?: {
    identifier: string
    number: number
  }
}

function parseVersion(version: string): Version {
  const versionParts = version.split(/-(.+)/)
  const versionPart = versionParts[0]
  const prereleasePart = versionParts[1]

  const [major, minor, patch] = versionPart
    .split('.')
    .map((part) => parseInt(part, 10))

  let prerelease
  if (prereleasePart) {
    const [identifier, numberStr] = prereleasePart.split('-')
    const number = parseInt(numberStr, 10)
    prerelease = { identifier, number }
  }

  return { major, minor, patch, prerelease }
}

function compareVersion(a: Version, b: Version): number {
  if (a.major !== b.major) {
    return a.major - b.major
  }
  if (a.minor !== b.minor) {
    return a.minor - b.minor
  }
  if (a.patch !== b.patch) {
    return a.patch - b.patch
  }

  if (!a.prerelease && !b.prerelease) {
    return 0
  }
  if (!a.prerelease) {
    return 1
  }
  if (!b.prerelease) {
    return -1
  }

  if (a.prerelease.identifier !== b.prerelease.identifier) {
    return a.prerelease.identifier.localeCompare(b.prerelease.identifier)
  }

  return a.prerelease.number - b.prerelease.number
}

/**
 * Is version A newer than version B?
 * @param a
 * @param b
 * @returns boolean
 */
export const isVersionGreaterThan = (
  a: string | undefined,
  b: string | undefined,
): boolean => {
  if (!a && !b) {
    return false
  }
  if (!a) {
    return false
  }
  if (!b) {
    return true
  }

  const versionA = parseVersion(a)
  const versionB = parseVersion(b)

  return compareVersion(versionA, versionB) > 0
}
