import { useEffect } from 'react'

import { useUIContext } from '../contexts/UIContext'
import { BaseSelection } from '../libs/selection'

export const useRestorePreviousSelection = (selection: BaseSelection) => {
  const {
    lastDefaultId,
    setLastDefaultId,
    lastSelection,
    setLastSelection,
    shouldRestoreSelection,
    setShouldRestoreSelection,
  } = useUIContext()

  useEffect(() => {
    if (shouldRestoreSelection) {
      selection.setDefaultId(lastDefaultId)
      selection.set(lastSelection)
    }
    setLastDefaultId(undefined)
    setLastSelection([])
    setShouldRestoreSelection(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
