import React, { useState } from 'react'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { withDnDContext } from '../../../common/dnd/enhancers/withDnDContext'
import { useKeyCaps } from '../../../common/keyMappableActions/useKeyCaps'
import {
  Button,
  Icon,
  MenuDivider,
  MenuItem,
  cx,
  styled,
  withMenu,
  withTooltip,
} from '../../../common/stationary'
import { useUserMenu } from '../hooks/useUserMenu'

interface SideBarHeaderProps {
  onToggleButtonClick: React.MouseEventHandler
}

export const SideBarHeader: React.FC<SideBarHeaderProps> = withDnDContext(
  ({ isDragInProgress, onToggleButtonClick }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const userMenu = useUserMenu()
    const { store } = useModelStoreContext()

    const actionWithCloseMenu = (action: (() => any) | undefined) => {
      setIsMenuOpen(false)
      action && action()
    }

    const keyCaps = useKeyCaps()

    return (
      <S.SideBarHeader className={cx({ isDragInProgress })}>
        <S.SideBarHeaderInner>
          <S.ToggleButton
            onClick={onToggleButtonClick}
            tooltip={{
              title: 'Hide sidebar',
              keycaps: keyCaps['KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE'],
            }}
            variant="plain"
          >
            <Icon boxSize={18} variant="glyphHamburgerAlt" />
          </S.ToggleButton>
          <S.ControlledMenuButton
            onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
            menuProps={{
              open: isMenuOpen,
              onClose: () => setIsMenuOpen(false),
              children: (
                <>
                  {userMenu.map((menu, index) => {
                    if (!menu.shouldShow) return null

                    if (menu.type === 'divider') {
                      return <MenuDivider key={index} />
                    }

                    return (
                      <MenuItem
                        onClick={() => actionWithCloseMenu(menu.onClick)}
                        title={menu.title}
                        keycaps={menu.keycaps}
                        key={index}
                      />
                    )
                  })}
                  <MenuDivider />
                  <S.UsernameItem>
                    <S.Username>{store.user.username}</S.Username>
                  </S.UsernameItem>
                </>
              ),
            }}
            popperProps={{
              offset: [4, 4],
              placement: 'bottom-end',
            }}
            variant="plain"
          >
            <Icon boxSize={18} variant="glyphThreeDotsHorizontal" />
          </S.ControlledMenuButton>
        </S.SideBarHeaderInner>
      </S.SideBarHeader>
    )
  },
)

export const SideBarHeaderDimensions = {
  height: 56,
}

const S = {
  SideBarHeader: styled.div(({ theme }) => ({
    display: 'flex',
    paddingTop: 'env(safe-area-inset-top)',

    '&.isDragInProgress': {
      pointerEvents: 'none',
    },
  })),
  SideBarHeaderInner: styled.div(({ theme }) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: SideBarHeaderDimensions.height,
    padding: 10,
    color: theme.colors.text[800],
  })),
  ToggleButton: styled(withTooltip(Button))({
    height: '36px',
    width: '36px',
    borderRadius: '8px',
  }),
  ControlledMenuButton: styled(withMenu(Button))({
    height: '36px',
    width: '36px',
    borderRadius: '8px',
  }),
  UsernameItem: styled.li(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: 32,
    padding: '0 16px 0 8px',
    maxWidth: 256,
    ...theme.text.publicSans['11.5:16'],
    fontWeight: 600,
    color: theme.colors.text[700],
  })),
  Username: styled.div(() => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  })),
}
