import { CURRENT_KEYBOARD_SHORTCUT_VERSION_KEY } from '@eleventhlabs/capture-shared'
import { useCallback, useEffect, useState } from 'react'
import { ReadTransaction, Replicache } from 'replicache'
import { ApiEndpoints } from '../ApiEndpoints'
import { useExecuteOnForeground } from '../capacitor/appStateChange/useExecuteOnForeground'
import { useAuth } from '../contexts/AuthContext'
import { useGlobalLoggedInContext } from '../contexts/GlobalLoggedInContext'
import { CURRENT_APP_VERSION, Env } from '../env'
import { useStableCallback } from '../utils'
import { getSessionJwt } from '../utils/auth/stytch'
import { MobileAppPlatform } from '../utils/env'
import { useMobileAppPlatform } from '../utils/env/useMobileAppPlatform'
import { useOnlineStatus } from '../utils/network/useOnlineStatus'
import { WSEventName, ws } from '../websocket'
import mutators from './mutators'
import { SCHEMA_VERSION } from './schema'
import { CustomReplicache } from './types'
import { useSubscribeIfNotNull } from './useSubscribe'

type Ready = { rep: CustomReplicache; isReady: true }
type NotReady = { rep: CustomReplicache | null; isReady: false }
export const useReplicache = (): Ready | NotReady => {
  const [rep, setRep] = useState<CustomReplicache | null>(null)
  useStoreClientID(rep)

  const { user } = useAuth()
  const sessionJwt = getSessionJwt()
  const userId = user?.trusted_metadata?.captureId as string

  useEffect(() => {
    if (!userId) return

    //  LOG LEVEL
    let logLevel: 'error' | 'debug' | 'info' = 'error'
    const params = new URLSearchParams(window.location.search)
    const replicacheLogLevel = params.get('replicacheLogLevel')
    if (replicacheLogLevel === 'debug') logLevel = 'debug'
    if (replicacheLogLevel === 'info') logLevel = 'info'

    const r = new Replicache({
      auth: sessionJwt ? `Bearer ${sessionJwt}` : undefined,
      logLevel,
      indexes: {
        tickler: {
          prefix: `record/`,
          jsonPointer: `/tickler/isoDate`,
        },
      },
      mutators,
      name: userId,
      pullURL: `${ApiEndpoints.rpcURI}/replicache-pull?cv=${CURRENT_APP_VERSION}`,
      pushURL: `${ApiEndpoints.rpcURI}/replicache-push?cv=${CURRENT_APP_VERSION}`,
      schemaVersion: SCHEMA_VERSION,
      licenseKey: Env.replicacheLicenseKey,
    })
    setRep(r)
    return () => {
      r?.close()
    }
  }, [userId])

  useRCPullOnPokeForUserId(rep)
  useRCPullWhenOnlineAndForegrounded(rep)

  // Check if replicache has been initialized
  const isClientLoaded = rep !== null

  // Check if our schema version matches what is in replicache
  // If it's not, we need to wait until the next replicache pull to render stuff
  const schemaVersion = useSchemaVersion(rep) ?? undefined
  const doesSchemaMatch = schemaVersion === SCHEMA_VERSION

  // Wait until client is loaded and schema matches to show data
  const isReady = isClientLoaded && doesSchemaMatch

  // Just type check the response
  if (rep !== null && isReady) return { rep, isReady }
  else if (!isReady) return { rep, isReady: false }
  else throw new Error(``)
}

const useRCPullWhenOnlineAndForegrounded = (rep: Replicache | null) => {
  const { isOnline } = useOnlineStatus()

  const initiatePull = useCallback(() => {
    rep?.pull()
  }, [rep])

  useExecuteOnForeground(initiatePull, {
    executeImmediately: false,
    runOnce: false,
  })

  useEffect(() => {
    if (isOnline) {
      initiatePull()
    }
  }, [isOnline, initiatePull])
}

const useRCPullOnPokeForUserId = (rep: Replicache | null) => {
  const { userId } = useAuth()
  const pull = useStableCallback(() => {
    rep?.pull()
  })
  useEffect(() => {
    if (!userId) return

    ws.bindEvent(userId, { eventName: WSEventName.POKE, callback: pull })

    return () => ws.unsubscribe(userId)
  }, [userId, pull])
}

const useSchemaVersion = (rep: Replicache | null): string | undefined => {
  return useSubscribeIfNotNull(rep, getSchemaVersion, null) ?? undefined
}

export const useLatestAppVersionFromReplicache = (
  rep: Replicache | null,
): string | undefined => {
  const mobilePlatform = useMobileAppPlatform()
  if (mobilePlatform == MobileAppPlatform.IOS) {
    return useSubscribeIfNotNull(rep, getLatestIOSVersion, null) ?? undefined
  } else if (mobilePlatform === MobileAppPlatform.ANDROID) {
    return (
      useSubscribeIfNotNull(rep, getLatestAndroidVersion, null) ?? undefined
    )
  }

  return useSubscribeIfNotNull(rep, getLatestAppVersion, null) ?? undefined
}

export const useCurrentKeyboardShortcutVersionFromReplicache = (
  rep: Replicache | null,
): number | undefined => {
  return (
    useSubscribeIfNotNull(rep, getCurrentKeyboardShortcutVersion, null) ??
    undefined
  )
}

const getLatestAppVersion = async (tx: ReadTransaction) =>
  ((await tx.get(`latestAppVersion`)) ?? null) as string | null

const getCurrentKeyboardShortcutVersion = async (tx: ReadTransaction) => {
  return ((await tx.get(CURRENT_KEYBOARD_SHORTCUT_VERSION_KEY)) ?? null) as
    | number
    | null
}

const getLatestIOSVersion = async (tx: ReadTransaction) =>
  ((await tx.get(`latestiOSVersion`)) ?? null) as string | null

const getLatestAndroidVersion = async (tx: ReadTransaction) =>
  ((await tx.get(`latestAndroidVersion`)) ?? null) as string | null

const getSchemaVersion = async (tx: ReadTransaction) =>
  ((await tx.get(`schemaVersion`)) ?? null) as string | null

const useStoreClientID = (rep: CustomReplicache | null) => {
  const { setClientID } = useGlobalLoggedInContext()
  useEffect(() => {
    const fn = async () => {
      const clientID = await rep?.clientID
      if (clientID === undefined) return
      setClientID(clientID)
    }
    fn()
    return () => setClientID(null)
  }, [rep?.clientID, setClientID])
}
