import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  LocalStorageItem,
  LocalStorageItemSetter,
  LocalStorageKey,
  LocalStorageProxy,
  booleanToLocalStorage,
  listToLocalStorage,
  localStorageToBoolean,
  localStorageToList,
  localStorageToNumber,
  numberToLocalStorage,
} from '../libs'
import { localStorageProxy } from '../storage'

export interface LocalStorageContextValue {
  localStorageProxy: LocalStorageProxy
}

/*
 * Context & Provider
 */

const defaultValue: LocalStorageContextValue = {
  localStorageProxy,
}

export const LocalStorageContext = createContext(defaultValue)
LocalStorageContext.displayName = `LocalStorageContext`

export const LocalStorageProvider = LocalStorageContext.Provider

/*
 * Hooks
 */

export const useLocalStorageContextValue = (): LocalStorageContextValue =>
  useContext(LocalStorageContext)

export const useLocalStorage = (
  key: LocalStorageKey,
): [LocalStorageItem, LocalStorageItemSetter] => {
  const localStorageContextValue = useContext(LocalStorageContext)
  const { localStorageProxy } = localStorageContextValue

  const initialItem = localStorageProxy.getItem(key)
  const [item, setItem] = useState(initialItem)

  useEffect(() => {
    localStorageProxy.addListener(key, (item) => setItem(item))
    return () => localStorageProxy.removeListener(key)
  }, [key, localStorageProxy])

  const setItemWithProxy = useCallback(
    (item: LocalStorageItem): LocalStorageItem =>
      localStorageProxy.setItem(key, item),
    [key, localStorageProxy],
  )

  return [item, setItemWithProxy]
}

export const useLocalStorageBoolean = (
  key: string,
  defaultValue: boolean,
): [boolean, (value: boolean) => void] => {
  const hasLoadedFromLS = useRef(false)
  const [value, setValue] = useLocalStorage(key)

  // Set default value on mount if needed
  if (!hasLoadedFromLS.current) {
    if (value === null) setValue(booleanToLocalStorage(defaultValue))
    hasLoadedFromLS.current = true
  }

  // Prepare value & setValue
  const _value = value === null ? defaultValue : localStorageToBoolean(value)
  const _setValue = useCallback(
    (value: boolean) => {
      setValue(booleanToLocalStorage(value))
    },
    [setValue],
  )

  return [_value, _setValue]
}

export const useLocalStorageNumber = (
  key: string,
  defaultValue: number,
): [number, (value: number) => void] => {
  const hasLoadedFromLS = useRef(false)
  const [value, setValue] = useLocalStorage(key)

  // Set default value on mount if needed
  if (!hasLoadedFromLS.current) {
    if (value === null) setValue(numberToLocalStorage(defaultValue))
    hasLoadedFromLS.current = true
  }

  // Prepare value & setValue
  const _value = value === null ? defaultValue : localStorageToNumber(value)
  const _setValue = useCallback(
    (value: number) => {
      setValue(numberToLocalStorage(value))
    },
    [setValue],
  )

  return [_value, _setValue]
}

export const useLocalStorageList = (
  key: string,
  defaultValue: string[],
): [string[], (value: string[]) => void] => {
  const hasLoadedFromLS = useRef(false)
  const [value, setValue] = useLocalStorage(key)

  // Set default value on mount if needed
  if (!hasLoadedFromLS.current) {
    if (value === null) setValue(listToLocalStorage(defaultValue))
    hasLoadedFromLS.current = true
  }

  // Prepare value & setValue
  const _value = value === null ? defaultValue : localStorageToList(value)
  const _setValue = useCallback(
    (value: string[]) => {
      setValue(listToLocalStorage(value))
    },
    [setValue],
  )

  return [_value, _setValue]
}
