import { completeRecords } from './completeRecords'
import { createRecord } from './createRecord'
import { deleteRecords } from './deleteRecords'
import { moveIntoDateGroup } from './moveIntoDateGroup'
import { moveIntoParent } from './moveIntoParent'
import { skipOccurrences } from './skipOccurrences'
import { softDeleteRecords } from './softDeleteRecords'
import { sortRecords } from './sortRecords'
import { unSoftDeleteRecords } from './unSoftDeleteRecords'
import { uncompleteRecords } from './uncompleteRecords'
import { updateProps } from './updateProps'
import { updateRecordText } from './updateRecordText'
import { updateTicklers } from './updateTicklers'
import { userMarkOnboardingComplete } from './userMarkOnboardingComplete'
import { userSetOnboardingSavePoint } from './userSetOnboardingSavePoint'
import { userCompleteOnboardingSlides } from './userCompleteOnboardingSlides'
import { userUncompleteOnboardingSlides } from './userUncompleteOnboardingSlides'

const mutators = {
  completeRecords,
  createRecord,
  deleteRecords,
  moveIntoDateGroup,
  moveIntoParent,
  skipOccurrences,
  softDeleteRecords,
  sortRecords,
  uncompleteRecords,
  unSoftDeleteRecords,
  updateProps,
  updateRecordText,
  updateTicklers,
  userMarkOnboardingComplete,
  userSetOnboardingSavePoint,
  userCompleteOnboardingSlides,
  userUncompleteOnboardingSlides,
}

export default mutators
