import { isEmpty } from 'lodash'
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { DialogType } from '../dialogs/types'
import { emptyFn } from '../utils'
import { useSelectionContext } from './SelectionContext'
import { useUIContext } from './UIContext'

export interface DialogContextValue {
  openScheduleDialog: (
    recordId?: string | undefined,
    shouldHideSelectionActionBar?: boolean,
  ) => void
  openSendToDialog: (
    recordId?: string | undefined,
    shouldHideSelectionActionBar?: boolean,
  ) => void
  openQuickSwitcherDialog: () => void
  openCalendarDialog: () => void
  openCommandBarDialog: () => void
  closeDialog: () => void
}

/*
 * @deprecated Context
 */
const defaultValue = {
  openScheduleDialog: emptyFn,
  openSendToDialog: emptyFn,
  openQuickSwitcherDialog: emptyFn,
  openCalendarDialog: emptyFn,
  openCommandBarDialog: emptyFn,
  closeDialog: emptyFn,
}

export const DialogContext = createContext<DialogContextValue>(defaultValue)
DialogContext.displayName = `DialogContext`

/*
 * @deprecated Provider
 */
export interface DialogProviderProps {
  children: ReactNode
}

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const value = useDialogActions()

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  )
}

/*
 * Hooks
 */

export const useDialogContext = (): DialogContextValue => {
  const context = useContext(DialogContext)

  if (context === undefined) {
    throw new Error(`useDialogContext must be used within an DialogProvider`)
  }

  return context
}

/**
 * @deprecated Returns an object containing functions to open various dialogs and to close the current dialog.
 *
 * @return {DialogContextValue} An object containing the following functions:
 * - openScheduleDialog: opens the Schedule dialog
 * - openSendToDialog: opens the Move To dialog
 * - openQuickSwitcherDialog: opens the Quick Switcher dialog
 * - openCalendarDialog: opens the Calendar dialog
 * - openCommandBarDialog: opens the Command Bar dialog
 * - closeDialog: closes the current dialog
 */
export const useDialogActions = (): DialogContextValue => {
  const { showView, hideView } = useUIContext()
  const { selection, focusedRecordId } = useSelectionContext()
  const [clearSelectionOnClose, setClearSelectionOnClose] = useState(false)

  const selectedRecordIds = selection.ids
  const clearSelection = selection.clear
  const selectItem = useCallback(
    (id: string, shouldHideSelectionActionBar?: boolean) =>
      selection.set([id], shouldHideSelectionActionBar),
    [selection],
  )

  const openDialog = useCallback(
    (
      mode: DialogType,
      recordId?: string,
      shouldHideSelectionActionBar?: boolean,
    ) => {
      if (
        recordId &&
        !selectedRecordIds.includes(recordId) &&
        !selection.shouldShowSelectionActionBar
      )
        setClearSelectionOnClose(true)
      if (recordId) selectItem(recordId, shouldHideSelectionActionBar)
      else {
        if (focusedRecordId === undefined && isEmpty(selectedRecordIds)) return
        if (focusedRecordId !== undefined && isEmpty(selectedRecordIds))
          selectItem(focusedRecordId, shouldHideSelectionActionBar)
      }
      showView(mode)
    },
    [
      focusedRecordId,
      selectedRecordIds,
      selection.shouldShowSelectionActionBar,
      selectItem,
      showView,
    ],
  )
  const closeDialog = useCallback(() => {
    hideView()
    if (clearSelectionOnClose) {
      clearSelection()
      setClearSelectionOnClose(false)
    }
  }, [clearSelectionOnClose, hideView, clearSelection])

  const openScheduleDialog = useCallback(
    (recordId?: string, shouldHideSelectionActionBar?: boolean) => {
      openDialog(DialogType.Schedule, recordId, shouldHideSelectionActionBar)
    },
    [openDialog],
  )
  const openSendToDialog = useCallback(
    (recordId?: string, shouldHideSelectionActionBar?: boolean) => {
      openDialog(DialogType.MoveTo, recordId, shouldHideSelectionActionBar)
    },
    [openDialog],
  )
  const openQuickSwitcherDialog = useCallback(
    () => showView(DialogType.QuickSwitcher),
    [showView],
  )
  const openCalendarDialog = useCallback(
    () => showView(DialogType.Calendar),
    [showView],
  )

  const openCommandBarDialog = useCallback(
    () => showView(DialogType.CommandBar),
    [showView],
  )

  return useMemo(
    () => ({
      openScheduleDialog,
      openSendToDialog,
      openQuickSwitcherDialog,
      openCalendarDialog,
      openCommandBarDialog,
      closeDialog,
    }),
    [
      openScheduleDialog,
      openSendToDialog,
      openQuickSwitcherDialog,
      openCalendarDialog,
      openCommandBarDialog,
      closeDialog,
    ],
  )
}
