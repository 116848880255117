import {
  BaseSelection,
  SelectionInteractions,
} from '../../../../libs/selection'
import { setSelectionForShiftClickToTarget } from '../../../../selection'
import { blurActiveElement } from '../../../../utils'

export const noteMouseDown = (
  event: React.MouseEvent<Element, MouseEvent>,
  recordId: string | undefined,
  recordIds: string[],
  selection: BaseSelection,
  selectionInteractions: SelectionInteractions,
  focusedRecordId: string | undefined,
) => {
  if (!recordId) return false

  const isClickedRecordAlsoFocusedRecord = recordId === focusedRecordId
  if (isClickedRecordAlsoFocusedRecord) return

  if (event.shiftKey) {
    event.stopPropagation()
    return
  }

  if (event.metaKey) {
    selection.togglePresence(recordId)
    event.preventDefault()
    event.stopPropagation()
    blurActiveElement()
    return
  }
}
