import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { isUrl, sanitizeUrl } from '@eleventhlabs/capture-shared'
import { styled, tooltipStyles } from '../../common/stationary'

const VERTICAL_GAP = 8
const DELAY = 400

interface FloatingLinkPreviewProps {
  triggerElement: HTMLElement | null
}

export const FloatingLinkPreviewComponent = ({
  triggerElement,
}: FloatingLinkPreviewProps) => {
  const floatingElementRef = useRef<HTMLDivElement | null>(null)
  const [linkUrl, setLinkUrl] = useState('')
  const [currentLinkElement, setCurrentLinkElement] =
    useState<HTMLElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  const resetLinkViewer = React.useCallback(() => {
    setCurrentLinkElement(null)
    setIsVisible(false)
  }, [])

  const showFloatingLinkPreviewForAnchor = (
    targetElement: HTMLAnchorElement,
  ) => {
    setIsVisible(true)
    setLinkUrl(targetElement.href)

    const floatingElem = floatingElementRef.current

    const anchorElementRect = targetElement.getBoundingClientRect()

    if (floatingElem === null) return resetLinkViewer()

    floatingElem.style.opacity = '1'

    // For bottom
    const floatingElemTop =
      anchorElementRect.top + anchorElementRect.height + VERTICAL_GAP

    floatingElem.style.top = `${floatingElemTop}px` // Position below target element
    floatingElem.style.position = 'fixed'
    floatingElem.style.left = `${anchorElementRect.left}px` // Align horizontally with target
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout
    const handleMouseEnter = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement

      if (targetElement && targetElement.tagName === 'A') {
        // Need to use a timeout to delay links for now, but should
        timeout = setTimeout(
          () =>
            showFloatingLinkPreviewForAnchor(
              targetElement as HTMLAnchorElement,
            ),
          DELAY,
        )
      } else if (
        targetElement.parentElement &&
        targetElement.parentElement.tagName === 'A'
      ) {
        setCurrentLinkElement(targetElement)
        timeout = setTimeout(
          () =>
            showFloatingLinkPreviewForAnchor(
              targetElement.parentElement as HTMLAnchorElement,
            ),
          DELAY,
        )
      }
    }

    const handleMouseLeave = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement
      if (
        (targetElement && targetElement.tagName === 'A') ||
        (targetElement.parentElement &&
          targetElement.parentElement.tagName === 'A')
      ) {
        resetLinkViewer()
        clearTimeout(timeout)
      }
    }

    triggerElement?.addEventListener('mouseover', handleMouseEnter)
    triggerElement?.addEventListener('mouseout', handleMouseLeave)

    // Clean up event listeners when component unmounts
    return () => {
      triggerElement?.removeEventListener('mouseover', handleMouseEnter)
      triggerElement?.removeEventListener('mouseout', handleMouseLeave)
      clearTimeout(timeout)
    }
  }, [triggerElement])

  useEffect(() => {
    if (!currentLinkElement) return

    const maybeUpdateLinkElement = () => {
      const pointerX = window.pageXOffset
      const pointerY = window.pageYOffset

      // Use the elementFromPoint method to get the element at the pointer position
      const element = document.elementFromPoint(pointerX, pointerY)
      if (element !== currentLinkElement) {
        resetLinkViewer()
      }
    }

    window.addEventListener('scroll', maybeUpdateLinkElement)

    // Clean up event listeners when component unmounts
    return () => {
      window.removeEventListener('scroll', maybeUpdateLinkElement)
    }
  }, [currentLinkElement, triggerElement])

  useEffect(() => {
    if (!isVisible) {
      resetLinkViewer()
    }
  }, [isVisible])

  if (!isVisible) return null

  return (
    <S.FloatingLinkPreview ref={floatingElementRef}>
      <a
        href={sanitizeUrl(linkUrl)}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'block',
          wordBreak: 'break-word',
        }}
      >
        {linkUrl}
      </a>
    </S.FloatingLinkPreview>
  )
}

const S = {
  FloatingLinkPreview: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: 'flex-start',
    height: 28,
    padding: 8,
    borderRadius: 6,
    fontWeight: 600,
    zIndex: 500,
    maxWidth: 500,
    ...theme.text.publicSans[`13.5:20`],

    ...tooltipStyles.rootVariants['shade']({ theme }),

    '>a': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })),
}

export default function FloatingLinkPreview({
  triggerElement = document.body,
}: {
  triggerElement?: HTMLElement | null
  className?: string
}): JSX.Element | null {
  return createPortal(
    <FloatingLinkPreviewComponent triggerElement={triggerElement} />,
    document.body,
  )
}
