import { isEmpty } from 'lodash'
import React from 'react'

import { Key, Priorities, useKeys } from '../../common/hooks/useKeys'
import { QuickSwitcherHandlers, ResultGroup } from './QuickSwitcher.types'
import NoResults from './components/NoResults'
import { QuickSwitcherHeader } from './components/QuickSwitcherHeader'
import ResultList from './components/ResultList'
import { styled } from '../../common/stationary'

export interface QuickSwitcherProps {
  query?: string
  groupData: ResultGroup
  inputRef?: React.RefObject<HTMLInputElement>
  handlers: QuickSwitcherHandlers
}

const ListItemSize = 48
const ListMaxHeight = 5 * 48

export const QuickSwitcher = ({
  query,
  groupData,
  inputRef,
  handlers,
}: QuickSwitcherProps) => {
  const {
    onDownArrow,
    onUpArrow,
    onEnter,
    onChange,
    onEsc,
    onHover,
    handleResultClick,
    handleSearchInputKeyDown,
  } = handlers

  useKeys([Key.Down, Key.J], onDownArrow, Priorities.Dialog, true)
  useKeys([Key.Up, Key.K], onUpArrow, Priorities.Dialog, true)
  useKeys(Key.Enter, onEnter, Priorities.Dialog, true)
  useKeys(Key.Esc, onEsc, Priorities.Dialog, true)

  const listHeight = groupData.items.length * ListItemSize

  return (
    <S.QuickSwitcher>
      <QuickSwitcherHeader
        inputRef={inputRef}
        inputValue={query}
        onChange={onChange}
        onKeyDown={handleSearchInputKeyDown}
      />
      <S.Body>
        {groupData.title ? <S.Title>{groupData.title}</S.Title> : null}
        {!isEmpty(groupData.items) ? (
          <S.List
            style={{
              height: listHeight > ListMaxHeight ? ListMaxHeight : listHeight,
              maxHeight: ListMaxHeight,
            }}
          >
            <S.ResultList
              items={groupData.items}
              onHover={onHover}
              handleResultClick={handleResultClick}
            />
          </S.List>
        ) : (
          <NoResults query={query} />
        )}
      </S.Body>
    </S.QuickSwitcher>
  )
}

export default QuickSwitcher

const S = {
  QuickSwitcher: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `column`,
    borderRadius: 12,
    boxShadow: theme.effects.boxShadow.elevation[3],
    background: theme.colors.surface.base,
  })),
  Body: styled.div({
    display: `flex`,
    flexDirection: `column`,
    flex: 1,
    paddingTop: 8,
  }),
  Title: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flex: `0 0 32px`,
    width: `100%`,
    flexDirection: `row`,
    ...theme.text.publicSans['11.5:16'],
    lineHeight: `16px`,
    fontWeight: `bold`,
    userSelect: `none`,
    textTransform: `uppercase`,
    letterSpacing: `0.08em`,
    color: theme.colors.text[500],
    paddingLeft: 20,
  })),
  List: styled.div({
    display: `flex`,
    flexDirection: `column`,
  }),
  ResultList: styled(ResultList)({
    overflowX: `hidden !important` as any,
  }),
}
