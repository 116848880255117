import { useEffect } from 'react'
import { useDragDropManager } from 'react-dnd'
import { useReactDnDContext } from '../../contexts/ReactDnDContext'
import { useBoundaryScroll } from '../scroll/useBoundaryScroll'
import { useHover } from '../useHover'

export const useScrollAtBoundaryOnDrag = <T extends HTMLElement>() => {
  const [hoverRef, isHovered] = useHover<T>()

  const { isDragInProgress } = useReactDnDContext()
  const { updatePosition } = useBoundaryScroll(hoverRef)

  const monitor = useDragDropManager().getMonitor()

  useEffect(() => {
    if (!isHovered) {
      // Stop the scroll listener if we're not hovering over the boundary anymore
      updatePosition({ isScrollAllowed: false })
      return
    }

    let unsubscribe
    if (isDragInProgress && isHovered) {
      unsubscribe = monitor.subscribeToOffsetChange(() => {
        const currentPointerPositionY = monitor.getClientOffset()?.y as number
        updatePosition({
          isScrollAllowed: true,
          pointerPositionY: currentPointerPositionY,
        })
      })
    }

    return unsubscribe
  }, [monitor, updatePosition, isDragInProgress, isHovered])

  return hoverRef
}
