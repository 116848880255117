import React from 'react'

import { styled } from '../../../../../common/stationary'

interface URLDomainProps {
  domain?: string
  loading?: boolean
}

export const URLDomain: React.FC<URLDomainProps> = ({ domain, loading }) => {
  return domain ? (
    <S.URLDomain>{domain}</S.URLDomain>
  ) : loading ? (
    <S.Empty />
  ) : null
}

const S = {
  URLDomain: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0`,
    height: 24,
    marginRight: 4,
    flexWrap: `wrap`,
    fontWeight: 500,
    alignItems: `center`,
    justifyContent: `flex-start`,
    whiteSpace: `nowrap`,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[900],
  })),
  Empty: styled.div(({ theme }) => ({
    display: `flex`,
    flex: 1,
    height: 16,
    background: theme.colors.surface.lowest,
    borderRadius: 6,
  })),
}
