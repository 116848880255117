import {
  AnnotationCreatedTriggerValueType,
  ShowAnnotationsToggleTriggerValueType,
} from '../../../../analytics/capture-analytics-actions'
import { RecordItemTargetType } from '../../../../recordItem'
import { DataRecord, NoteableRecord } from '@eleventhlabs/capture-shared'

export const addAnnotation = (
  records: Record<string, DataRecord>,
  recordIds: string[],
  addNoteAtBottomOfList:
    | ((
        parentId: string,
        triggerCreatedAnnotation: AnnotationCreatedTriggerValueType,
        triggerShowAnnotation: ShowAnnotationsToggleTriggerValueType,
      ) => string)
    | undefined,
  setNotesVisibilityForRecordIds: (
    ids: string[],
    visibility: boolean,
    trigger: ShowAnnotationsToggleTriggerValueType,
  ) => void,
  triggerCreatedAnnotation: AnnotationCreatedTriggerValueType,
  triggerShowAnnotation: ShowAnnotationsToggleTriggerValueType,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
) => {
  if (recordIds.length === 1) {
    const id = recordIds[0]
    const record = records[id] as NoteableRecord
    const notes = record.notes ?? []
    if (notes[0]) {
      setNotesVisibilityForRecordIds(recordIds, true, triggerShowAnnotation)
      // set recentlyCreatedRecords
      setRecentlyCreatedRecords((recentlyCreatedRecords) => ({
        ...recentlyCreatedRecords,
        [notes[0]]: RecordItemTargetType.Text,
      }))

      return {
        noteOpened: true,
        noteCreated: false,
      }
    }
    const newId =
      addNoteAtBottomOfList &&
      addNoteAtBottomOfList(id, triggerCreatedAnnotation, triggerShowAnnotation)

    return {
      noteOpened: false,
      noteCreated: true,
      newNoteId: newId,
    }
  }

  return {
    noteOpened: false,
    noteCreated: false,
  }
}
