import React, { useCallback, useMemo } from 'react'

import * as Analytics from '../../common/analytics/capture-analytics-actions'
import { useActiveScreen } from '../../common/analytics/capture-analytics-web'
import { useExecContext } from '../../common/contexts/ExecContext'
import { useModelStoreContext } from '../../common/contexts/ModelStoreContext'
import { useSelectionContext } from '../../common/contexts/SelectionContext'
import {
  KeyMappedActionIdType,
  useKeyMappedActionHotkeys,
} from '../../common/keyMappableActions'
import { useMappedKeys } from '../../common/keyMappableActions/useKeyMappedActionHotkeys'
import { useRecordGroupsSelectionInteractions } from '../../common/selection/useRecordGroupsInteractions'
import { useSelectionHotKeyCommands } from '../../common/selection/useSelectionHotkeyCommands'
import { useCommonScreenHotKeys } from '../hooks/useCommonHotkeys'
import { useScreenHotKeys } from '../hooks/useScreenHotKeys'
import { CommonRecordGroupScreenProps } from '../types'
import { useDerivedStateContext } from '../../common/contexts/DerivedStateContext'

export function withRecordGroupHotKeys<T extends CommonRecordGroupScreenProps>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const ComponentWithRecordGroupHotKeys = (props: T) => {
    const { routeType } = props

    const { store } = useModelStoreContext()
    const { selection } = useSelectionContext()
    const { recordGroups, recordGroupCommands } = useDerivedStateContext()

    const selectionInteractions = useRecordGroupsSelectionInteractions(
      selection,
      recordGroups,
      recordGroupCommands,
    )

    let idsInOrder: string[] = []
    for (const g of recordGroups) {
      idsInOrder = [...idsInOrder, ...g.items.map((i) => i.id)]
    }

    const selectionHotKeyCommands = useSelectionHotKeyCommands(
      selection,
      selectionInteractions,
      idsInOrder,
    )

    const handlers = useCommonScreenHotKeys(
      store.records,
      selectionHotKeyCommands,
      selection,
      routeType,
    )

    // register hotkeys for keyMappableActions

    useKeyMappedActionHotkeys(recordGroupScreenActionsToMap)

    /**
     * The delete action is custom because the Trash screen needs special behavior on the delete action.
     * Specifically, the Trash screen needs access to state that is not available in useExec.
     *   The dialog state for confirming record hard-deletions.
     * So, we need to make sure we specifically let the Trash screen setup it's own handler for this case.
     */

    const activeScreen = useActiveScreen()
    const { exec } = useExecContext()
    const onRecordDelete = useCallback(() => {
      if (selection.ids.length === 0) return
      if (activeScreen !== 'Trash') {
        exec({
          type: 'MOVE_RECORDS_TO_TRASH',
          trigger: Analytics.RecordsDeletedTrigger.KEYBOARD_BACKSPACE,
          triggeredByKeyboard: true,
          triggeredByUI: false,
        })
      }
    }, [activeScreen, exec, selection])

    useMappedKeys({ KEY_MAPPED_RECORD_DELETE: onRecordDelete })

    // register hot keys

    const hotKeys = useMemo(
      () => ({
        ...handlers,
      }),
      [handlers],
    )

    useScreenHotKeys(hotKeys)

    // wrapped component

    return <WrappedComponent {...props} />
  }

  return ComponentWithRecordGroupHotKeys
}

const recordGroupScreenActionsToMap: KeyMappedActionIdType[] = [
  'KEY_MAPPED_CREATE_RECORD_INSERT_BELOW',
  'KEY_MAPPED_CREATE_RECORD_AT_TOP',
  'KEY_MAPPED_FULLSCREEN_OPEN',
  'KEY_MAPPED_OPEN_GO_TO_DIALOG',
  'KEY_MAPPED_OPEN_COMMAND_BAR_DIALOG',
  'KEY_MAPPED_OPEN_KEYBOARD_SHORTCUT_DIALOG',
  'KEY_MAPPED_OPEN_MOVE_TO_DIALOG',
  'KEY_MAPPED_OPEN_SCHEDULE_TO_DIALOG',
  'KEY_MAPPED_RECORD_COPY',
  'KEY_MAPPED_RECORD_TOGGLE_EXPANDED',
  'KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE',
  'KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN',
  'KEY_MAPPED_SELECT_ALL_RECORDS',
  'KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE',
  'KEY_MAPPED_TOGGLE_MARKED_DONE_VISIBLE',
]
