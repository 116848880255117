import { formatISO } from 'date-fns'
import { isUndefined } from 'lodash'
import { WriteTransaction } from 'replicache'

import {
  RCMutation,
  getNextSkipOccurrence,
  isTickleableRecord,
} from '@eleventhlabs/capture-shared'

import {
  getRecord,
  getTickleableRecord,
  updateFIndexesForMoveIntoDateGroup,
  updateRecord,
  updateTickler,
} from './utils'

export const completeRecords = async (
  tx: WriteTransaction,
  {
    recordIds,
    completedAt,
    executedAtOnClient,
    tzOnClient,
  }: RCMutation.CompleteRecordsArgs,
) => {
  for (const recordId of recordIds) {
    await completeRecord(
      tx,
      recordId,
      completedAt,
      executedAtOnClient,
      tzOnClient,
    )
  }
}

const completeRecord = async (
  tx: WriteTransaction,
  recordId: string,
  completedAt: number,
  executedAtOnClient: number,
  tzOnClient: string | undefined,
) => {
  const record = await getRecord(tx, recordId)
  if (record) {
    const isRecurring =
      isTickleableRecord(record) && !isUndefined(record.tickler?.recurrence)
    if (isRecurring)
      await completeRecurringRecord(
        tx,
        recordId,
        completedAt,
        executedAtOnClient,
        tzOnClient,
      )
    else await completeNonRecurringRecord(tx, recordId, completedAt)
  }
}

const completeNonRecurringRecord = async (
  tx: WriteTransaction,
  recordId: string,
  completedAt: number,
) => {
  await updateRecord(tx, recordId, {
    isCompleted: true,
    completedAt,
  })
}

const completeRecurringRecord = async (
  tx: WriteTransaction,
  recordId: string,
  completedAt: number,
  executedAtOnClient: number,
  tzOnClient: string | undefined,
) => {
  const record = await getTickleableRecord(tx, recordId)
  if (record.tickler?.recurrence === undefined) return

  const thresholdDate = formatISO(completedAt, {
    representation: `date`,
  })
  const { isoDate: nextIsoDate } = getNextSkipOccurrence(
    record.tickler,
    thresholdDate,
  )
  if (nextIsoDate === undefined) throw new Error(``)

  // TODO: Consider using `updateTicklers` directly
  await updateFIndexesForMoveIntoDateGroup(
    tx,
    [record.id],
    nextIsoDate,
    {
      at: `start`,
    },
    executedAtOnClient,
    tzOnClient,
  )
  await updateTickler(tx, record, { isoDate: nextIsoDate })

  // TODO:
  // Create CompletedOccurrence Record
}
