import React from 'react'

import { ShortcutsList } from '../../../../components/ShortcutsList'
import { styled } from '../../../stationary'

export interface ResultItemPostProps {
  shortcut?: string[]
}

const ResultItemPost = ({ shortcut }: ResultItemPostProps) => {
  return (
    <S.Content>
      <ShortcutsList shortcuts={shortcut ?? []} />
    </S.Content>
  )
}

export default ResultItemPost

const S = {
  Content: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `flex-end`,
    alignItems: `center`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    fontWeight: 400,
    userSelect: `none`,
    paddingRight: `20px`,
    color: theme.colors.text[500],
  })),
  Delimiter: styled.div(({ theme }) => ({
    fontWeight: 500,
    padding: `2px 6px`,
    color: theme.colors.text[700],
    boxSizing: `border-box`,
    borderRadius: 4,
    marginRight: `-4px`,
  })),
}
