import { navigate } from 'gatsby'
import { createContext, useContext, useState } from 'react'
import { ApiEndpoints } from '../ApiEndpoints'
import { apiCall } from '../api/client'
import { Env } from '../env'
import { useLocalStorage } from './LocalStorageContext'

const CODE_VERIFIER_KEY = 'desktopCodeVerifier'

export interface DesktopAuthContextValue {
  getMagicLinkWithCodeChallenge: (codeChallenge: string) => Promise<string>
  getSessionWithCodeVerifierAndMagicLink: (
    magicLink: string,
  ) => Promise<{ session_token: string; session_jwt: string } | undefined>
  redirectToDesktopApp: (magicLink: string) => void
  storedCodeVerifier: string | null
  setStoredCodeVerifier: (code_verifier: string) => void
  errorMessage: string
  setErrorMessage: (message: string) => void
}

/*
 * Context & Provider
 */

const defaultDesktopAuthContextValue: DesktopAuthContextValue = {
  getMagicLinkWithCodeChallenge: async (codeChallenge: string) => '',
  getSessionWithCodeVerifierAndMagicLink: async (magicLink: string) =>
    undefined,
  redirectToDesktopApp: (magicLink: string) => {},
  storedCodeVerifier: null,
  setStoredCodeVerifier: (code_verifier: string) => {},
  errorMessage: '',
  setErrorMessage: (message: string) => {},
}

export const DesktopAuthContext = createContext(defaultDesktopAuthContextValue)
DesktopAuthContext.displayName = `DesktopAuthContext`

export const DesktopAuthProvider = DesktopAuthContext.Provider

export const useDesktopAuthContextValue = (): DesktopAuthContextValue => {
  const [storedCodeVerifier, setStoredCodeVerifier] =
    useLocalStorage(CODE_VERIFIER_KEY)
  const [errorMessage, setErrorMessage] = useState('')

  // called by web
  const getMagicLinkWithCodeChallenge = async (
    codeChallenge: string | undefined,
  ) => {
    const response = await apiCall(
      ApiEndpoints.getMagicLinkWithCodeChallengeURI,
      {
        method: `POST`,
        headers: {
          'Content-Type': `application/json; charset=utf-8`,
        },
        body: JSON.stringify({
          code_challenge: codeChallenge,
        }),
      },
    )

    if (response.status > 300) {
      setErrorMessage(
        'An error has occurred, please return to the Desktop App and restart this flow.',
      )
      return
    }

    const { magic_link_token } = await response.json()

    return magic_link_token
  }

  // called by web
  const redirectToDesktopApp = (magicLink: string) => {
    const desktopRedirectLocation = `${Env.customAppDeeplinkProtocol}/desktop-authenticate?eml_token=${magicLink}`
    navigate(desktopRedirectLocation)
  }

  // called by desktop app
  const getSessionWithCodeVerifierAndMagicLink = async (magicLink: string) => {
    const response = await apiCall(
      ApiEndpoints.getSessionWithCodeVerifierAndMagicLinkURI,
      {
        method: `POST`,
        headers: {
          'Content-Type': `application/json; charset=utf-8`,
        },
        body: JSON.stringify({
          code_verifier: storedCodeVerifier,
          magic_link_token: magicLink,
        }),
      },
    )

    if (response.status > 300) {
      setErrorMessage('An error has occurred, please try again.')
      return
    }

    const { session_token, session_jwt } = await response.json()

    return { session_token, session_jwt }
  }

  return {
    getMagicLinkWithCodeChallenge,
    getSessionWithCodeVerifierAndMagicLink,
    redirectToDesktopApp,
    storedCodeVerifier,
    setStoredCodeVerifier,
    errorMessage,
    setErrorMessage,
  }
}

export const useDesktopAuth = (): DesktopAuthContextValue =>
  useContext(DesktopAuthContext)
