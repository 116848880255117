import React from 'react'

import { ShortcutsList } from '../../ShortcutsList'
import { KeyboardShortcut } from '../shortcutsGroups'

import { styled } from '../../../common/stationary'

export interface KeyboardShortcutsItemProps {
  item: KeyboardShortcut
}

export const KeyboardShortcutsItem: React.FC<KeyboardShortcutsItemProps> = ({
  item,
}) => {
  return (
    <S.Item>
      <S.Description>{item.description}</S.Description>
      <ShortcutsList shortcuts={item.keys} />
    </S.Item>
  )
}

const S = {
  Item: styled.div(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    flex: `0 0 40px`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    borderBottom: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
  })),
  Description: styled.div(({ theme }) => ({
    display: `flex`,
    height: `100%`,
    flex: `1`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 400,
    color: theme.colors.text[900],
  })),
}
