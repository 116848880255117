import { ListOnScrollProps } from 'react-window'

import { useScrollMemory } from '../../hooks/scroll/useScrollMemory'
import { emptyFn } from '../../utils'

export interface OutlineControls {
  onOutlineScroll: (props: ListOnScrollProps) => void
  outlineScrollOffset?: number
}

export const defaultOutlineControls = {
  onOutlineScroll: emptyFn,
}

export const useOutlineControls = () => {
  const { onScroll: onOutlineScroll, scrollOffset: outlineScrollOffset } =
    useScrollMemory()

  return {
    onOutlineScroll,
    outlineScrollOffset,
  }
}
