import { XYCoord } from 'dnd-core'
import { useDragDropManager } from 'react-dnd'

import { RecordsMovedTrigger } from '../../../../analytics/capture-analytics-actions'
import { useExecContext } from '../../../../contexts/ExecContext'
import { useReactDnDContext } from '../../../../contexts/ReactDnDContext'
import { RecordGroupType } from '../../../../data/recordGroups'
import {
  RecordItemDragData,
  SUPPORTED_DRAG_RECORD_TYPES,
} from '../../../drag/types'
import {
  DropOutlineRecordPayload,
  DropPayload,
  DropPoint,
  DropProps,
  DropRecordGroupPayload,
  DropRecordItemPayload,
  DropShortcutPayload,
  DropTargetType,
  UNSUPPORTED_DROP_RECORD_IDS,
} from '../../types'

export interface DropPropExtras {
  isOver?: boolean
  canDrop?: boolean
}

export interface DropTargetFactoryHandlers<T extends DropProps> {
  // Called on hover
  onHover: (payload: DropPayload, extras?: DropPropExtras) => void
  // Props returned to the caller about drag state / anything else needed to render & do app-level logic
  getDropProps: (payload: DropPayload, extras?: DropPropExtras) => T
  // Called when the drag ends
  onDrop: (payload: DropPayload, extras?: DropPropExtras) => void
  // Whether or not the current drop target is active
  canDrop?: (payload: DropPayload) => boolean
}

export const useDropTargetFactory = <
  DropTargetProps extends DropProps,
>(): Record<DropTargetType, DropTargetFactoryHandlers<DropTargetProps>> => {
  // Use react-dnd drag-drop monitor
  const dragDropMonitor = useDragDropManager().getMonitor()
  const dragContext = useReactDnDContext()
  const { exec } = useExecContext()

  return {
    [DropTargetType.RECORD]: {
      onHover: (payload) => {
        const recordDropPayload = payload as DropRecordItemPayload

        const hoverIndex = recordDropPayload.index ?? 0

        // Determine rectangle on screen
        const hoverBoundingRect =
          recordDropPayload.hoverRef.current!.getBoundingClientRect()

        // Get vertical middle
        const midY = (hoverBoundingRect.top + hoverBoundingRect.bottom) / 2
        // Determine mouse position
        const pointerOffset = dragDropMonitor.getClientOffset() as XYCoord

        let newInsertIndex: DropPoint =
          pointerOffset.y < midY ? `before` : `after`

        dragContext.setDnDIndices &&
          dragContext.setDnDIndices(hoverIndex, newInsertIndex)
        dragContext.setHoverRecord(recordDropPayload.record.id)
      },
      getDropProps: (_payload, extras): DropTargetProps => {
        return {
          hovered: extras?.isOver,
          canDrop: extras?.canDrop,
          isValidAndHovered: extras?.isOver && extras.canDrop,
          isDragInProgress: dragContext.isDragInProgress,
        } as DropTargetProps
      },
      onDrop: (payload) => {
        const recordDropPayload = payload as DropRecordItemPayload

        // Bail if we're not dragging over a supported record item
        if (
          !dragContext.hoverRecord?.type ||
          !SUPPORTED_DRAG_RECORD_TYPES.includes(
            dragContext.hoverRecord?.type ?? '',
          )
        ) {
          return
        }

        exec({
          type: 'MOVE_RECORDS',
          parentRecordId: recordDropPayload.record.parentId ?? '',
          recordIds: dragContext.draggedRecords.map((record) => record.id),
          insertPoint: {
            at: dragContext.insertIndex,
            anchorId: dragContext.hoverRecord?.id ?? '',
          },
          trigger: RecordsMovedTrigger.DRAG_AND_DROP,
        })
      },
      canDrop: (payload) => {
        // Allow dropping if the dragged item's group is the same as the current group
        // Disallow if the dragged item's group is not the same and the group is completed or overdue
        const recordDropPayload = payload as DropRecordItemPayload
        const draggedItem = dragDropMonitor.getItem() as RecordItemDragData
        const dropGroupType = recordDropPayload.groupType

        if (recordDropPayload.groupType !== draggedItem.groupType) {
          return (
            dropGroupType !== RecordGroupType.ListComplete &&
            dropGroupType !== RecordGroupType.Overdue
          )
        }

        return true
      },
    },
    [DropTargetType.OUTLINE_RECORD]: {
      onHover: (payload: DropPayload) => {
        const outlineDropPayload = payload as DropOutlineRecordPayload

        dragContext.setHoverRecord(outlineDropPayload.id)
      },
      getDropProps: (_payload, extras): DropTargetProps => {
        return {
          hovered: extras?.isOver,
          isValidAndHovered: extras?.isOver && extras?.canDrop,
          canDrop: extras?.canDrop,
        } as DropTargetProps
      },
      onDrop: (payload, extras) => {
        const outlineDropPayload = payload as DropOutlineRecordPayload

        if (!extras?.isOver) {
          return
        }

        exec({
          type: 'MOVE_RECORDS',
          parentRecordId: outlineDropPayload.id,
          recordIds: dragContext.draggedRecords.map((record) => record.id),
          trigger: RecordsMovedTrigger.DRAG_AND_DROP,
        })
      },
    },
    [DropTargetType.SHORTCUT]: {
      onHover: (payload: DropPayload) => {
        const shortcutDropPayload = payload as DropShortcutPayload

        dragContext.setHoverRecord(shortcutDropPayload.id)
      },
      getDropProps: (_payload, extras): DropTargetProps => {
        return {
          hovered: extras?.isOver,
          canDrop: extras?.canDrop,
          isValidAndHovered: extras?.isOver && extras?.canDrop,
          isDragInProgress: dragContext.isDragInProgress,
        } as DropTargetProps
      },
      onDrop: (payload, extras) => {
        const shortcutDropPayload = payload as DropShortcutPayload

        if (!extras?.isOver) {
          return
        }

        exec({
          type: 'MOVE_RECORDS',
          parentRecordId: shortcutDropPayload.id,
          recordIds: dragContext.draggedRecords.map((record) => record.id),
          trigger: RecordsMovedTrigger.DRAG_AND_DROP,
        })
      },
      canDrop: (payload) => {
        const shortcutDropPayload = payload as DropShortcutPayload

        if (UNSUPPORTED_DROP_RECORD_IDS.includes(shortcutDropPayload.id))
          return false

        return true
      },
    },
    [DropTargetType.RECORD_GROUP]: {
      onHover: () => {
        dragContext.clearHoverRecord()
      },
      getDropProps: (_payload, extras): DropTargetProps => {
        return {
          hovered: extras?.isOver,
          canDrop: extras?.canDrop,
          isValidAndHovered: extras?.isOver && extras?.canDrop,
        } as DropTargetProps
      },
      onDrop: (payload) => {
        const recordGroupDropPayload = payload as DropRecordGroupPayload
        if (recordGroupDropPayload.isRecordGroupEmpty) {
          exec({
            type: 'MOVE_RECORDS',
            parentRecordId: '',
            recordIds: dragContext.draggedRecords.map((record) => record.id),
            groupId: recordGroupDropPayload.groupId,
            trigger: RecordsMovedTrigger.DRAG_AND_DROP,
          })
        }
      },
      canDrop: (payload) => {
        const recordGroupDropPayload = payload as DropRecordGroupPayload

        // Don't allow dropping into an empty completed record group
        return !recordGroupDropPayload.groupId.includes(
          RecordGroupType.ListComplete,
        )
      },
    },
  }
}
