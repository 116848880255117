import React from 'react'
import ChromeLogo from '../../../assets/onboarding/chrome-icon.png'
import gmailIconSrc from '../../../assets/onboarding/gmail-icon.png'
import outlookIconSrc from '../../../assets/onboarding/outlook-icon.png'
import { styled } from '../../../common/stationary/styled'
import { isMobile } from '../../../common/utils/env'
import { useAuth } from '../../../providers'
import { AuthStyles as S } from '../AuthStyles'
import AuthLogo from './AuthLogo'

interface WaitingForAuthProps {
  className?: string
  emailAddress: string
}

/**
 * WaitingForAuth
 */

const WaitingForAuth: React.FC<WaitingForAuthProps> = ({
  className,
  emailAddress,
}) => {
  const { isChromeExtensionAuthFlow } = useAuth()

  return (
    <S.WaitingForAuth className={className}>
      <S.AuthHeader>
        <AuthLogo
          href={!isMobile ? '/' : undefined}
          secondaryIconSrc={isChromeExtensionAuthFlow ? ChromeLogo : undefined}
          mode={'isWaiting'}
        />
        <S.AuthTitle>
          We’ve sent a magic link to <strong>{emailAddress}</strong>
        </S.AuthTitle>
        {isChromeExtensionAuthFlow && (
          <S.ClipperMessage>Web Clipper for Chrome</S.ClipperMessage>
        )}
      </S.AuthHeader>
      <S.AuthBody>
        <WaitingForAuthStyles.Divider />
        <WaitingForAuthStyles.MagicLinkHint>
          <strong>Click the link in your email</strong> to log in
        </WaitingForAuthStyles.MagicLinkHint>
        <WaitingForAuthStyles.CheckSpamHint>
          <i>Can’t find the email? Check your spam folder!</i>
        </WaitingForAuthStyles.CheckSpamHint>
        <WaitingForAuthStyles.Shortcuts>
          <WaitingForAuthStyles.EmailClientShortcut
            href="https://mail.google.com/mail/u/0/"
            target="_blank"
          >
            <WaitingForAuthStyles.EmailClientIcon src={gmailIconSrc} />
            Open Gmail
          </WaitingForAuthStyles.EmailClientShortcut>
          <WaitingForAuthStyles.EmailClientShortcut
            href="https://outlook.live.com/mail/0/inbox"
            target="_blank"
          >
            <WaitingForAuthStyles.EmailClientIcon src={outlookIconSrc} />
            Open Outlook
          </WaitingForAuthStyles.EmailClientShortcut>
        </WaitingForAuthStyles.Shortcuts>
        <S.ContactUs>
          Having issues?
          <a href="mailto:membership@capture.so">Contact us</a>
        </S.ContactUs>
      </S.AuthBody>
    </S.WaitingForAuth>
  )
}

export default WaitingForAuth

const WaitingForAuthStyles = {
  Divider: styled.div(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.alpha.border.medium}`,
    marginBottom: 32,
    width: 64,
  })),
  MagicLinkHint: styled.div(() => ({
    marginBottom: 24,

    strong: {
      fontWeight: 700,
    },
  })),
  Shortcuts: styled.div(() => ({
    display: 'flex',
    gap: 8,
    marginBottom: 24,
    marginTop: 32,
  })),
  EmailClientShortcut: styled.a(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    height: 40,
    padding: '0 12px',
    borderRadius: 6,
    ...theme.text.publicSans['13.5:20'],
    background: theme.colors.alpha.tone.extraWeak,
    cursor: 'pointer',
    color: theme.colors.text[900],

    ':hover': {
      textDecoration: 'underline',
      background: theme.colors.alpha.tone.weak,
    },
  })),
  EmailClientIcon: styled.img(() => ({
    width: 16,
  })),
  CheckSpamHint: styled(S.CheckSpamHint)(() => ({
    marginTop: -12,
  })),
}
