import { first, isEmpty, last } from 'lodash'
import { useCallback, useMemo } from 'react'

import { scrollAction } from '../events'
import { BaseSelection } from '../libs/selection'
import { SelectionInteractions } from '../libs/selection/types'
import { immediateExecute } from '../utils'

export interface SelectionHotKeyCommands {
  onDownArrow: () => string[]
  onUpArrow: () => string[]
  onShiftDown: () => string[]
  onShiftUp: () => string[]
  onCmdDown: () => string[]
  onCmdUp: () => string[]
  onEsc: () => void
}

export const useSelectionHotKeyCommands = (
  selection: BaseSelection,
  selectionInteractions: SelectionInteractions,
  idsInOrder: string[],
): SelectionHotKeyCommands => {
  const isSelectionEmpty = selection.ids.length === 0
  const selectedIdsInOrder = idsInOrder.filter((id) =>
    selection.ids.includes(id),
  )
  const firstSelectedItemInStructure = first(selectedIdsInOrder)
  const lastSelectedItemInStructure = last(selectedIdsInOrder)

  const onDownArrow = useCallback(() => {
    let newSelection: string[] = []
    if (isSelectionEmpty) {
      if (selection.defaultId !== undefined) {
        newSelection = [selection.defaultId]
        selection.set(newSelection)
      } else {
        newSelection = selectionInteractions.selectFirstItem()
      }
    } else {
      newSelection = selectionInteractions.moveDown()
    }
    if (isEmpty(newSelection)) return []
    scrollAction({ target: `record`, to: { id: first(newSelection) } })
    return newSelection
  }, [isSelectionEmpty, selection, selectionInteractions])

  const onShiftDown = useCallback(() => {
    if (!isSelectionEmpty) {
      const selectedRecords = selectionInteractions.moveFocusDown()
      if (isEmpty(selectedRecords)) return []
      scrollAction({ target: `record`, to: { id: first(selectedRecords) } })
      return selectedRecords
    }
    return []
  }, [isSelectionEmpty, selectionInteractions])

  const onCmdDown = useCallback(() => {
    if (!isSelectionEmpty) {
      const affectedRecords = selectionInteractions.shiftItemsDown()
      // we scroll after it has been able to render the re-organized list
      immediateExecute(() => {
        scrollAction({
          target: `record`,
          to: { id: lastSelectedItemInStructure },
        })
      })
      return affectedRecords
    }
    return []
  }, [isSelectionEmpty, selectionInteractions, lastSelectedItemInStructure])

  const onUpArrow = useCallback(() => {
    let newSelection: string[] = []
    if (isSelectionEmpty) {
      if (selection.defaultId !== undefined) {
        newSelection = [selection.defaultId]
        selection.set(newSelection)
      } else {
        newSelection = selectionInteractions.selectLastItem()
      }
    } else {
      newSelection = selectionInteractions.moveUp()
    }
    if (isEmpty(newSelection)) return []
    scrollAction({
      target: `record`,
      to: { id: first(newSelection) },
      options: { extraOffsetTop: 8 },
    })
    return newSelection
  }, [isSelectionEmpty, selection, selectionInteractions])

  const onShiftUp = useCallback(() => {
    if (!isSelectionEmpty) {
      const selectedRecords = selectionInteractions.moveFocusUp()
      if (isEmpty(selectedRecords)) return []
      scrollAction({ target: `record`, to: { id: first(selectedRecords) } })
      return selectedRecords
    }
    return []
  }, [isSelectionEmpty, selectionInteractions])

  const onCmdUp = useCallback(() => {
    if (!isSelectionEmpty) {
      const affectedRecords = selectionInteractions.shiftItemsUp()
      // we scroll after it has been able to render the re-organized list
      immediateExecute(() => {
        scrollAction({
          target: `record`,
          to: { id: firstSelectedItemInStructure },
        })
      })
      return affectedRecords
    }
    return []
  }, [isSelectionEmpty, selectionInteractions, firstSelectedItemInStructure])

  const onEsc = useCallback(() => {
    const selectionFocus = selection.ids[0]
    selection.clear()
    if (selectionFocus !== undefined) selection.setDefaultId(selectionFocus)
  }, [selection])

  return useMemo(
    () => ({
      onDownArrow,
      onUpArrow,
      onShiftDown,
      onShiftUp,
      onCmdDown,
      onCmdUp,
      onEsc,
    }),
    [onDownArrow, onUpArrow, onShiftDown, onShiftUp, onCmdDown, onCmdUp, onEsc],
  )
}
