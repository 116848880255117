import React, { useCallback, useState } from 'react'

import { Icon, cx, styled } from '../../../../../common/stationary'

interface FileThumbnailProps {
  url?: string
  thumbnail?: string
  loading?: boolean
  isMovie?: boolean
  isImage?: boolean
  progress: number
  onClick?: (e: React.MouseEvent) => void
}

const LOADING_BAR_WIDTH = 48

export const FileThumbnail: React.FC<FileThumbnailProps> = ({
  url,
  thumbnail,
  loading,
  isMovie,
  isImage,
  progress,
  onClick,
}) => {
  const [failed, setFailed] = useState(false)

  const onError = useCallback(() => setFailed(true), [])

  return thumbnail && !failed ? (
    <S.FileThumbnail href={url} onClick={onClick} className="thumbnail">
      <S.Image src={thumbnail} onError={onError} />
      {isMovie ? <S.PlayIcon /> : null}
    </S.FileThumbnail>
  ) : (
    <S.Empty
      href={url}
      onClick={onClick}
      className={cx({
        placeholder: !loading,
      })}
    >
      {loading ? (
        <S.Loading>
          <S.LoadingBar
            style={{ width: (LOADING_BAR_WIDTH / 100) * progress }}
          />
        </S.Loading>
      ) : isMovie ? (
        <S.PlayIcon />
      ) : isImage ? (
        <S.FileIcon boxSize={16} variant="glyphImage" />
      ) : (
        <S.FileIcon boxSize={16} variant="glyphPaperClip" />
      )}
    </S.Empty>
  )
}

const PlayIcon = (props: { className?: string }) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <circle opacity="0.7" cx="18" cy="18" r="18" fill="black" />
    <path d="M14 25V11L24 18L14 25Z" fill="white" />
  </svg>
)

const S = {
  FileThumbnail: styled.a(({ theme }) => ({
    display: `flex`,
    flex: 1,
    height: `100%`,
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[0],
    alignItems: `center`,
    justifyContent: `center`,
    position: `relative`,
    overflow: `hidden`,
  })),
  Loading: styled.div(({ theme }) => ({
    boxSizing: `border-box`,
    border: `1px solid ${theme.colors.gray[700]}`,
    width: `${LOADING_BAR_WIDTH}px`,
    height: `4px`,
    pointerEvents: `none`,
    borderRadius: 999,
  })),
  LoadingBar: styled.div(({ theme }) => ({
    color: theme.colors.gray[500],
    width: 0,
    height: `2px`,
    pointerEvents: `none`,
    animationDuration: `300ms`,
    animationTimingFunction: `ease-in-out`,
    borderRadius: 999,
  })),
  Image: styled.img(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    height: `100%`,
    background: theme.colors.surface.lowest,
    objectFit: `cover`,
  })),
  PlayIcon: styled(PlayIcon)({
    position: `absolute`,
    top: `calc(50% - 18px)`,
    left: `calc(50% - 18px)`,

    '&.empty': {
      opacity: 0.2,
    },
  }),
  FileIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.gray[500],
    pointerEvents: `none`,
  })),
  Empty: styled.a(({ theme }) => ({
    display: `flex`,
    flex: 1,
    height: 480,
    borderRadius: 8,
    background: theme.colors.surface.lowest,
    boxShadow: theme.effects.boxShadow.elevation[0],
    boxSizing: `border-box`,
    alignItems: `center`,
    justifyContent: `center`,
    position: `relative`,
  })),
}
