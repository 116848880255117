import React from 'react'

import { RouteType } from '../../../common/routes'
import HeaderBreadcrumbs from './HeaderBreadcrumbs'
import { useBreadcrumbs } from './useBreadcrumbs'
import { styled } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'

export interface HeaderTitleProps {
  listId: string | undefined
  recordId: string | undefined
  routeType?: RouteType
}

export const HeaderTitle: React.FC<HeaderTitleProps> = ({
  listId,
  recordId,
  routeType,
}) => {
  const breadcrumbs = useBreadcrumbs(listId, recordId, routeType)

  return (
    <S.HeaderTitle>
      <S.Title breadcrumbs={breadcrumbs} />
    </S.HeaderTitle>
  )
}

const S = {
  HeaderTitle: styled.div({
    display: `flex`,
    alignItems: `center`,
  }),
  Title: styled(HeaderBreadcrumbs)(({ theme }) => ({
    width: isMobile ? `calc(100vw - 150px)` : '100%',
  })),
}
