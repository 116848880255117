import { compact, every, filter } from 'lodash'

import { canBeConvertedToUrlRecord, Records } from '../../../../records'
import {
  RichText,
  DataRecord,
  TextRecord,
  UpdateRecordPayload,
  URLMetadata,
  UpdateTextRecordPayload,
  UpdateNoteRecordPayload,
} from '@eleventhlabs/capture-shared'
import { ConvertRecordToURLReturnValue } from '../useExecDefaultAction'
import * as Analytics from '../../../../analytics/capture-analytics-actions'

export const convertToUrlRecord = async (
  records: Record<string, DataRecord>,
  recordIds: string[],
  activeScreen: Analytics.ActiveScreenValueType | undefined,
  updateRecord: (recordId: string, payload: UpdateRecordPayload) => void,
  updateRecordText: (
    recordId: string,
    payload: UpdateTextRecordPayload | UpdateNoteRecordPayload,
  ) => void,
  setSelectionDefaultId: (id: string) => void,
  getUrlMetaData: (url: string) => Promise<URLMetadata>,
): Promise<ConvertRecordToURLReturnValue> => {
  const selectedRecords = recordIds.map((id) => records[id])
  const canBeConverted = every(selectedRecords, canBeConvertedToUrlRecord)

  if (!canBeConverted) {
    return {
      allSuccess: false,
      convertedIds: [],
    }
  }

  const successes = await Promise.all(
    selectedRecords.map(async (record) => {
      const textRecord = record as TextRecord
      const success = await Records.convertToUrlRecord(
        textRecord.id,
        RichText.getRawText(textRecord.richText.value).trim(),
        updateRecord,
        updateRecordText,
        setSelectionDefaultId,
        getUrlMetaData,
      )

      if (success) {
        Analytics.recordTypeChanged({
          recordType: Analytics.RecordType.URL,
          activeScreen,
          recordIds: [textRecord.id],
        })
        return textRecord.id
      }

      return null
    }),
  )

  return {
    allSuccess: every(successes),
    convertedIds: compact(successes),
  }
}
