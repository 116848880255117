import {
  isNoteRecord,
  isTextRecord,
  RCMutation,
} from '@eleventhlabs/capture-shared'
import { WriteTransaction } from 'replicache'
import { getRecord, upsertRecord } from './utils'

export const updateRecordText = async (
  tx: WriteTransaction,
  args: RCMutation.UpdateRecordTextArgs,
) => {
  for (const { recordId, richText } of args.updates) {
    const record = await getRecord(tx, recordId)
    if (record === undefined) continue

    if (!isTextRecord(record) && !isNoteRecord(record)) continue

    // Don't update the latest rich text version on clients because we want clients to be able to always
    // reflect the local changes. If the client is offline then comes online, we want the server to update the canonical
    // rich text version.
    await upsertRecord(tx, record.id, {
      ...record,
      richText,
    })
  }
}
