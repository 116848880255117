import { differenceInDays, parseISO, startOfToday } from 'date-fns'

export const getRecordsDatesSetEventProps = (
  dateString: string | null | undefined,
) => {
  if (dateString === undefined)
    return { numDaysUntilDate: undefined, dateIsEmpty: undefined }
  const today = startOfToday()
  const dateIsEmpty = dateString === null
  let numDaysUntilDate: number | undefined
  if (dateString !== null) {
    const dateToSet = parseISO(dateString)
    numDaysUntilDate = differenceInDays(dateToSet, today)
  }
  return { numDaysUntilDate, dateIsEmpty }
}
