import { first, last } from 'lodash'

import { BaseSelection, SelectionInteractions } from '../libs/selection'

export const setSelectionForShiftClickToTarget = (
  recordIds: string[],
  selection: BaseSelection,
  targetId: string,
  selectionInteractions: SelectionInteractions,
): string[] => {
  const rangeToSelect = getRangeForShiftClickToTarget(
    recordIds,
    selection,
    targetId,
  )
  const isTargetAtStartOfRange = first(rangeToSelect) === targetId
  const anchorLocation = isTargetAtStartOfRange ? `top` : `bottom`
  return selectionInteractions.setToRangeIncludingItems(
    rangeToSelect,
    anchorLocation,
  )
}

const getRangeForShiftClickToTarget = (
  recordIds: string[],
  selection: BaseSelection,
  targetId: string,
): string[] => {
  const selectionInOrder = sortItemsByOrderInList(recordIds, selection.ids)
  const [trailingId, leadingId] = [
    first(selectionInOrder) as string,
    last(selectionInOrder) as string,
  ]
  const isTargetBeforeTrailing =
    getTrailingItemInList(recordIds, [targetId, trailingId]) === targetId
  const rangeFromTargetToLeading: [string, string] = [targetId, leadingId]
  const rangeFromTrailingToTarget: [string, string] = [trailingId, targetId]
  return isTargetBeforeTrailing
    ? rangeFromTargetToLeading
    : rangeFromTrailingToTarget
}

const sortItemsByOrderInList = (list: string[], items: string[]): string[] => {
  return items.sort((a, b) => list.indexOf(a) - list.indexOf(b))
}

const getTrailingItemInList = (list: string[], items: string[]): string => {
  return first(sortItemsByOrderInList(list, items)) as string
}
