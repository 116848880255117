import React from 'react'

import {
  ReactDnDContextValue,
  useReactDnDContext,
} from '../../contexts/ReactDnDContext'

export interface WithDnDContext extends ReactDnDContextValue {}

export function withDnDContext<T>(
  WrappedComponent: React.FC<T & WithDnDContext>,
): React.FC<T> {
  const ComponentWithDnDContext = (props: T) => {
    const contextValue = useReactDnDContext()

    return <WrappedComponent {...props} {...contextValue} />
  }

  ComponentWithDnDContext.displayName = `ComponentWithDnDContext`

  return ComponentWithDnDContext
}
