import { appMenu, nativeWindow, object, tray } from '@todesktop/client-core'
import React, { useEffect } from 'react'
import { EditorsManager } from '../common/EditorsManager'
import Analytics from '../common/analytics/capture-analytics-actions'
import Desktop from '../common/desktop/desktop'
import GlobalShortcut from '../common/desktop/globalShortcut'
import {
  QuickCaptureTrigger,
  useOpenQuickCaptureOrDefault,
} from '../common/desktop/useOpenQuickCaptureOrDefault'
import {
  DESKTOP_TRAY_SHOW_QUICK_CAPTURE_EVENT,
  QUICK_CAPTURE_EDITOR_ID,
  QUICK_CAPTURE_SHORTCUT,
} from '../common/desktop/types'
import { Env } from '../common/env'
import { isDesktop } from '../common/utils/env'
import { useAuth } from '../providers'
import { useLocation } from '@gatsbyjs/reach-router'

export const QUICK_CAPTURE_SCREEN_PATHNAME = 'quickCapture'

const RegisterQuickCaptureWindow: React.FC<any> = () => {
  const { userId } = useAuth()

  useSubscribeOnlyOnceForAppEffects(userId)
  useRegisterQuickCaptureTrayEvents(userId)

  return null
}

/**
 * We do this check so that we don't register any events for the quick capture window specifically.
 * This is to prevent race conditions from the main window and the quick capture window from registering
 * global event handlers at the same time. Since none of the windows share React state, we need the main
 * window to be the one that registers the event callbacks and modifies state. The quick capture window
 * should be as dumb as possible.
 *
 * We may be able to move the quick capture window into its own page to separate out its logic a bit more
 * in order to make this easier to understand.
 *
 * We also may need to revisit this logic if we want to register more events for the quick capture window somewhere else,
 * or if we have more than one electron window.
 */
const isQuickCaptureScreen = (path: string) =>
  path === `/${QUICK_CAPTURE_SCREEN_PATHNAME}`

const useSubscribeOnlyOnceForAppEffects = (userId: string | null) => {
  const openQuickCaptureOrDefault = useOpenQuickCaptureOrDefault(userId)

  const location = useLocation()

  if (isQuickCaptureScreen(location.pathname)) return

  if (isDesktop) {
    Desktop.subscribeOnce(
      'registerQuickCaptureGlobalShortcut',
      async (reset) => {
        const isRegistered = await GlobalShortcut.isRegistered(
          QUICK_CAPTURE_SHORTCUT,
        )

        if (isRegistered) {
          await GlobalShortcut.unregister(QUICK_CAPTURE_SHORTCUT)
        }

        // Register global shortcut for quickCapture window once it's back
        await GlobalShortcut.register(QUICK_CAPTURE_SHORTCUT, async () => {
          Analytics.desktopGlobalShortcutPressed({
            userId: userId ?? '',
            value: QUICK_CAPTURE_SHORTCUT,
          })
          return await openQuickCaptureOrDefault(
            QuickCaptureTrigger.KEYBOARD_SHORTCUT,
          )
        })

        window.addEventListener('unload', () => {
          GlobalShortcut.unregister(QUICK_CAPTURE_SHORTCUT)
          reset()
        })
      },
    )

    // Only subscribe to
    Desktop.subscribeOnce('focusQCEditorOnWindowFocus', async (reset) => {
      const quickCaptureWindow = await Desktop.quickCaptureWindow.getRef()

      if (quickCaptureWindow) {
        nativeWindow.on(
          'focus',
          () => {
            EditorsManager.effects.focus({ id: QUICK_CAPTURE_EDITOR_ID })
          },
          { ref: quickCaptureWindow },
        )

        window.addEventListener('unload', () => {
          reset()
        })
      }
    })
  }
}

const useRegisterQuickCaptureTrayEvents = (userId: string | null) => {
  const openQuickCaptureOrDefault = useOpenQuickCaptureOrDefault(userId)
  const location = useLocation()

  if (isQuickCaptureScreen(location.pathname)) return

  useEffect(() => {
    if (isDesktop) {
      const registerTrayShowQuickCaptureEvent = async () => {
        const unsubscribe = await appMenu.on(
          DESKTOP_TRAY_SHOW_QUICK_CAPTURE_EVENT,
          async () => {
            Analytics.desktopQuickCaptureTrayItemClicked({
              userId: userId ?? '',
            })
            await openQuickCaptureOrDefault(QuickCaptureTrigger.TRAY)
          },
        )

        return unsubscribe
      }

      const registerTrayClickEvent = async () => {
        const trayRef = await object.retrieve({ id: Env.DESKTOP_TRAY_ID })

        if (trayRef) {
          await tray.on(
            'click',
            async () => {
              Analytics.desktopTrayClicked({
                userId: userId ?? '',
              })
              await openQuickCaptureOrDefault(QuickCaptureTrigger.TRAY)
            },
            {
              ref: trayRef,
            },
          )
        }
      }

      registerTrayShowQuickCaptureEvent()
      registerTrayClickEvent()
    }
  }, [userId, openQuickCaptureOrDefault])
}

export default RegisterQuickCaptureWindow
