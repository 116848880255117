import React, { createContext, useContext, useRef } from 'react'
import { DragDropContext, DragDropContextProps } from 'react-beautiful-dnd'

import { emptyFn } from '../utils'

export interface DnDContextValue {
  onDragEndRef: React.MutableRefObject<DragDropContextProps['onDragEnd']>
}

/*
 * Context
 */

const defaultValue: DnDContextValue = {
  onDragEndRef: { current: emptyFn },
}

export const DnDContext = createContext<DnDContextValue>(defaultValue)
DnDContext.displayName = `DnDContext`

/*
 * Provider
 */

export interface DnDProviderProps {
  children: React.ReactNode
}

export const DnDProvider = ({ children }: DnDProviderProps) => {
  const onDragEndRef: DnDContextValue['onDragEndRef'] = useRef(emptyFn)
  const onDragEnd: DragDropContextProps['onDragEnd'] = React.useCallback(
    (result, provided) => {
      onDragEndRef.current(result, provided)
    },
    [],
  )
  const value = { onDragEndRef }
  return (
    <DnDContext.Provider value={value}>
      <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
    </DnDContext.Provider>
  )
}

/*
 * Hooks
 */

export const useDnDContext = (): DnDContextValue => {
  const context = useContext(DnDContext)

  if (context === undefined) {
    throw new Error(`useDnDContext must be used within an DnDProvider`)
  }

  return context
}
