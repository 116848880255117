import { createContext, useContext, useState } from 'react'

import { ThemeName, themesConfig } from '../stationary/themes'

export interface ThemeNameContextValue {
  setThemeName: (themeName: ThemeName) => void
  themeName: ThemeName
}

/*
 * Context & Provider
 */

const defaultValue: ThemeNameContextValue = {
  setThemeName: () => undefined,
  themeName: themesConfig.StationaryLight.name,
}

export const ThemeNameContext = createContext(defaultValue)
ThemeNameContext.displayName = `ThemeNameContext`

/*
 * Hooks
 */

export const useThemeNameValue = () => {
  const [themeName, setThemeName] = useState<ThemeName>(defaultValue.themeName)

  return { themeName, setThemeName }
}

export const useThemeName = () => {
  const themeNameValue = useContext(ThemeNameContext)

  return themeNameValue
}
