import React from 'react'

import { ItemType } from '../CommandBar.types'
import ResultItemContentAction from './ResultItemContentAction'
import ResultItemContentGroup from './ResultItemContentGroup'

export interface ResultItemContentProps {
  type: ItemType
  content: string
  isActive: boolean
}

const ResultItemContent = ({
  type,
  content,
  isActive,
}: ResultItemContentProps) => {
  return type === ItemType.Group ? (
    <ResultItemContentGroup content={content} />
  ) : (
    <ResultItemContentAction content={content} isActive={isActive} />
  )
}

export default ResultItemContent
