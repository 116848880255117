import { useCallback, useMemo } from 'react'

import { ShowSidebarToggleTrigger } from '../../../common/analytics/capture-analytics-actions'
import { useExecContext } from '../../../common/contexts/ExecContext'
import { HeaderShortcut } from '../components/HeaderLeft'

export function useHeaderShortcuts(
  isLeftHidden: boolean,
  isFullscreen: boolean,
  isMobile: boolean,
): HeaderShortcut[] {
  const { exec } = useExecContext()

  const toggleSidebar = useCallback(
    (e) => {
      e.preventDefault()
      exec({
        type: 'TOGGLE_SIDEBAR',
        trigger: ShowSidebarToggleTrigger.SIDEBAR_TOGGLE_HEADER_BUTTON,
      })
    },
    [exec],
  )

  const closeFullscreen = useCallback(
    (e) => {
      e.preventDefault()
      exec({ type: 'EXIT_FULLSCREEN' })
    },
    [exec],
  )

  return useMemo(() => {
    const shortcuts: HeaderShortcut[] = []
    if (!(isFullscreen && isMobile) && isLeftHidden)
      shortcuts.push({
        variant: `glyphHamburgerAlt`,
        onClick: toggleSidebar,
      })
    if (isFullscreen)
      shortcuts.push({
        variant: `glyphX`,
        onClick: closeFullscreen,
      })
    return shortcuts
  }, [isLeftHidden, toggleSidebar, isFullscreen, closeFullscreen])
}
