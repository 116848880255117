import React from 'react'

import { titleOrDefault } from '../../../common/records'
import {
  screenHeaderStyles,
  styled,
  useTheme,
} from '../../../common/stationary'
import { RouteType, getMetadataForRoute } from '../../../common/routes'
import { RouteIcon } from '../../../components/RouteIcon'

export interface InboxHeaderProps {
  id: string
}

const InboxHeader: React.FC<InboxHeaderProps> = ({ id }: InboxHeaderProps) => {
  const theme = useTheme()
  const metadata = getMetadataForRoute({ routeType: RouteType.Inbox }, theme)

  if (!metadata) return null

  return (
    <S.InboxHeader>
      <S.Content>
        <S.Icon>
          <RouteIcon boxSize={32} routeType={metadata.type} />
        </S.Icon>
        <S.InboxHeaderText>
          <S.InboxHeaderTitle>
            {titleOrDefault(metadata.title)}
          </S.InboxHeaderTitle>
        </S.InboxHeaderText>
      </S.Content>
    </S.InboxHeader>
  )
}

export default InboxHeader

export const InboxHeaderHeight = 48

const S = {
  InboxHeader: styled.div({
    ...screenHeaderStyles,
  }),
  Content: styled.div({
    display: `flex`,
    flex: `0 0 64px`,
    width: `100%`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    margin: `24px 0 8px 0`,
  }),
  Icon: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    // marginLeft: 12,
    marginRight: 12,
    userSelect: `none`,
    outline: `none`,
  }),
  InboxHeaderText: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flex: `1`,
    marginLeft: 4,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    overflowX: `hidden`,
    fontWeight: `bold`,
    ...theme.text.publicSans['33:40'],
  })),
  InboxHeaderTitle: styled.div({
    width: `100%`,
  }),
}
