import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'

import { isImage } from '../../../../common/files'
import { FileMetadata } from '@eleventhlabs/capture-shared'
import {
  getFileDescription,
  isMovie,
} from '../../../RecordItem/components/FileRecordItemContent/utils'
import { FileDescription } from './components/FileDescription'
import { FileThumbnail } from './components/FileThumbnail'
import { FileTitle } from './components/FileTitle'
import { Icon, cx, styled } from '../../../../common/stationary'

interface FullScreenFileRecordItemContentProps {
  id: string
  url?: string
  thumbnailUrl?: string
  metadata?: FileMetadata
  className?: string
  style?: any
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
  renameFile?: (fileName: string) => void
}

export const FullScreenFileRecordItemContent: React.FC<
  FullScreenFileRecordItemContentProps
> = ({ id, url, thumbnailUrl, metadata, className, renameFile }) => {
  const loading = isEmpty(url)

  const description = getFileDescription(metadata)
  const thumbnail = isImage(metadata?.mimeType)
    ? thumbnailUrl ?? url
    : undefined

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.metaKey || e.shiftKey) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      } else {
        e.preventDefault()
        if (url) window.open(url, `_blank`)
      }
    },
    [url],
  )

  return (
    <S.FullScreenFileRecordItemContent
      className={cx(className, {
        loading,
      })}
      title={url}
    >
      <S.Title>
        <FileTitle
          id={id}
          url={url}
          title={metadata?.name}
          loading={loading}
          renameFile={renameFile}
          onClick={onClick}
        />
      </S.Title>
      <S.Details>
        <FileDescription description={description} loading={loading} />
      </S.Details>
      <S.Thumbnail>
        <FileThumbnail
          url={url}
          loading={loading}
          progress={100}
          thumbnail={thumbnail}
          isMovie={isMovie(metadata?.mimeType ?? ``)}
          isImage={!!isImage(metadata?.mimeType ?? ``)}
          onClick={onClick}
        />
      </S.Thumbnail>
      <S.OpenFile href={url} onClick={onClick}>
        <S.OpenFileText>Open file</S.OpenFileText>
        <S.Icon boxSize={16} variant="glyphArrowE" />
      </S.OpenFile>
    </S.FullScreenFileRecordItemContent>
  )
}

const S = {
  FullScreenFileRecordItemContent: styled.div({
    display: `flex`,
    width: `100%`,
    maxWidth: `100%`,
    flexDirection: `column`,
    userSelect: `none`,
  }),
  Title: styled.div({
    display: `flex`,
    flexDirection: `row`,
    flex: 1,
    alignItems: `center`,
    justifyContent: `flex-start`,
    paddingLeft: 24,
    cursor: `pointer`,
  }),
  Details: styled.div({
    display: `flex`,
    flexDirection: `row`,
    flex: 1,
    alignItems: `center`,
    justifyContent: `flex-start`,
    paddingLeft: 24,
  }),
  Thumbnail: styled.div({
    display: `flex`,
    flex: 1,
    flexDirection: `row`,
    margin: `32px 0`,
    cursor: `pointer`,
  }),
  OpenFile: styled.a({
    display: `flex`,
    flex: 1,
    flexDirection: `row`,
    paddingLeft: 24,
    alignItems: `center`,
    textDecoration: `none`,
    justifyContent: `flex-start`,
    cursor: `pointer`,
  }),
  OpenFileText: styled.div(({ theme }) => ({
    display: `flex`,
    fontWeight: 500,
    ...theme.text.publicSans['16:24'],
    color: theme.colors.text[500],
  })),
  Icon: styled(Icon)(({ theme }) => ({
    display: `flex`,
    color: theme.colors.text[500],
    marginLeft: 5,
  })),
}
