import React, { useState } from 'react'

import { useScrollIntoView } from '../../common/hooks/scroll/useScrollIntoView'
import { NoteRecord } from '@eleventhlabs/capture-shared'
import NoteContent from './components/NoteContent'
import { NoteHandlers } from './types'
import { useShouldAutofocus } from '../RecordItem/hooks/useShouldAutofocus'
import ContentErrorBoundary from '../../common/errors/ContentErrorBoundary'
import { cx, styled } from '../../common/stationary'
import { usePlaceholderText } from '../RecordItem/hooks/usePlaceholderText'

export interface NoteProps extends NoteHandlers {
  isMouseActive?: boolean
  isSelected?: boolean
  note: NoteRecord
}

export const Note: React.FC<NoteProps> = ({
  isMouseActive,
  isSelected,
  onBlur,
  onClick,
  onFocus,
  onMouseDown,
  onChange,
  onPaste,
  onArrowRightOrDownAtBoundary,
  onArrowLeftOrUpAtBoundary,
  onBackspaceWhenEmpty,
  onKeyDown,
  note,
}) => {
  const { htmlRef } = useScrollIntoView<HTMLDivElement>(note.id, `record-note`)
  const [placeholderType, setPlaceholderType] = useState<string | undefined>()
  const { placeholder } = usePlaceholderText(note.id, placeholderType, true)

  const { shouldAutofocus } = useShouldAutofocus(note.id)

  return (
    <S.Note
      className={cx(`item`, {
        isSelected,
        isMouseActive,
      })}
      ref={htmlRef}
    >
      <S.Content>
        <NoteContent
          autofocus={shouldAutofocus}
          note={note}
          onChange={onChange}
          onPaste={onPaste}
          onBlur={onBlur}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onFocus={onFocus}
          onArrowRightOrDownAtBoundary={onArrowRightOrDownAtBoundary}
          onArrowLeftOrUpAtBoundary={onArrowLeftOrUpAtBoundary}
          onBackspaceWhenEmpty={onBackspaceWhenEmpty}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          placeholderType={placeholderType}
          setPlaceholderType={setPlaceholderType}
          error={{
            fallbackComponent: ContentErrorBoundary,
            fallbackComponentProps: { record: note },
          }}
        />
      </S.Content>
    </S.Note>
  )
}

const S = {
  Note: styled.div({
    display: `flex`,
    width: `100%`,
    flexDirection: `row`,
    position: `relative`,
  }),
  Content: styled.div({
    display: `flex`,
    flex: 1,
  }),
}
