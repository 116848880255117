import { Capacitor } from '@capacitor/core'
import OneSignal from 'onesignal-cordova-plugin'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Env } from '../env'

type PermissionEvent = 'pushNotifications'
type PermissionCallback = Parameters<
  typeof OneSignal.promptForPushNotificationsWithUserResponse
>[0]

type NotificationEvent = 'notificationOpened'
type NotificationCallback = Parameters<
  typeof OneSignal.setNotificationOpenedHandler
>[0]

interface OneSignalContextValue {
  appId: string
  setAppId: (appId?: string) => void
  setUserId: typeof OneSignal.setExternalUserId
  setLogLevel: typeof OneSignal.setLogLevel
  promptForPermission: (e: PermissionEvent, cb?: PermissionCallback) => void
  addListener: (e: NotificationEvent, cb: NotificationCallback) => void
}

/**
 * Patch to prevent error from throwing on undefined
 * We probably want an initial value anyway, but we also want to check to make
 * sure that useOneSignalInit is working how we expect
 */
const oneSignalContextInitialValue: OneSignalContextValue = {
  appId: '',
  setAppId: () => undefined,
  setUserId: () => undefined,
  setLogLevel: () => undefined,
  promptForPermission: () => undefined,
  addListener: () => undefined,
}

/* CONSTANTS */

const PLATFORM = Capacitor.getPlatform()

/* CONTEXT, PROVIDER */

export const OneSignalContext = createContext<
  OneSignalContextValue | undefined
>(undefined)

OneSignalContext.displayName = `OneSignalContext`

export const OneSignalProvider = (props: { children: ReactNode }) => {
  const oneSignal = useOneSignalInit()

  return <OneSignalContext.Provider value={oneSignal} {...props} />
}

/* HOOKS */

/**
 * Hack to fix issue with onesignal-cordova-plugin and SSR frameworks like Next.js and Gatsby
 * @see https://github.com/OneSignal/OneSignal-Cordova-SDK/issues/739
 */
export const useOneSignalInit = () => {
  const [oneSignal, setOneSignal] = useState<OneSignalContextValue>(
    oneSignalContextInitialValue,
  )

  useEffect(() => {
    import('onesignal-cordova-plugin')
      .then((x) => x.default)
      .then((OneSignal) => {
        const _oneSignal: OneSignalContextValue = {
          appId: Env.oneSignalAppId,
          setAppId: function (appId) {
            if (['ios', 'android'].includes(PLATFORM)) {
              this.appId = appId || this.appId
              OneSignal.setAppId(this.appId)
            }
          },
          setUserId: function (...args) {
            if (['ios', 'android'].includes(PLATFORM)) {
              OneSignal.setExternalUserId(...args)
            }
          },
          setLogLevel: function (...args) {
            if (['ios', 'android'].includes(PLATFORM)) {
              OneSignal.setLogLevel(...args)
            }
          },
          promptForPermission: function (e, cb) {
            switch (e) {
              case 'pushNotifications':
                if (['ios', 'android'].includes(PLATFORM)) {
                  OneSignal.promptForPushNotificationsWithUserResponse(cb)
                }
                break
              default:
                throw Error(
                  `'${e}' is not a valid argument in promptForPermission`,
                )
            }
          },
          addListener: function (e, cb) {
            switch (e) {
              case 'notificationOpened':
                if (['ios', 'android'].includes(PLATFORM)) {
                  OneSignal.setNotificationOpenedHandler(cb)
                }
                break
              default:
                throw Error(`'${e}' is not a valid argument in addListener`)
            }
          },
        }

        _oneSignal.setAppId(_oneSignal.appId)
        setOneSignal(_oneSignal)
      })
  }, [])

  return oneSignal
}

export const useOneSignal = (cb: (context: OneSignalContextValue) => void) => {
  const context = useContext(OneSignalContext)

  useEffect(() => context && cb(context), [context])
}

export const useOneSignalContext = (): OneSignalContextValue => {
  const context = useContext(OneSignalContext)

  if (context === undefined) {
    throw new Error(
      `useOneSignalContext must be used within an OneSignalProvider`,
    )
  }

  return context
}
