import React, { useState } from 'react'
import { Transition } from 'react-transition-group'

import { SlideProps } from '.'
import { cx, styled } from '../../../../common/stationary'
import { isIOS, isMobile } from '../../../../common/utils/env'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/ImmersiveSlide'

import diagramAndroidNotificationSrc from '../../../../assets/onboarding/diagram-android-notification-capture@2x.png'
import diagramIOSNotificationSrc from '../../../../assets/onboarding/diagram-ios-notification@2x.png'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import SlideModalOverlay from './components/SlideModalOverlay'
import { PushNotifications } from '@capacitor/push-notifications'
import { useOneSignalContext } from '../../../../common/contexts/OneSignalContext'
import ConfirmationModal from '../ConfirmationModal'

export const IntroReminders: React.FC<SlideProps> = ({ goForward }) => {
  const [
    isNotificationPermissionsModalOpen,
    setIsNotificationPermissionsModalOpen,
  ] = useState<boolean>(false)

  const oneSignal = useOneSignalContext()

  const { onboardingAnalytics } = useOnboardingContext()

  const onPrimaryButtonClicked = () => {
    onboardingAnalytics.action(`Notifications modal shown`)

    setIsNotificationPermissionsModalOpen(true)
  }

  const notificationDiagramSrc = isIOS
    ? diagramIOSNotificationSrc
    : diagramAndroidNotificationSrc

  const closeModalAndContinue = () => {
    setIsNotificationPermissionsModalOpen(false)

    setTimeout(() => {
      goForward()
    }, 500)
  }

  const requestNotificationPermissionsAndGoForward = async () => {
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      oneSignal.promptForPermission('pushNotifications', (granted) => {
        if (!granted) {
          onboardingAnalytics.action('Push notification permissions denied')
        }

        onboardingAnalytics.action('Push notification permissions granted')

        closeModalAndContinue()
      })
    } else {
      onboardingAnalytics.action(
        `Push notification permissions skipped with status ${permStatus.receive}: `,
      )

      closeModalAndContinue()
    }
  }

  const onSkipButtonClick = () => {
    onboardingAnalytics.action('Push notification permissions manually skipped')
  }

  return (
    <S.IntroReminders className={cx({ isModalOpen: true })}>
      <S.Inner>
        <TransitionSlideContainer>
          <SlideIntro>
            <SlideTitle>Easy reminders 🔔</SlideTitle>
            <SlideDescription>
              Get a push notification on your device whenever you need a nudge.
            </SlideDescription>
            <SlideButtonGroup>
              <SlidePrimaryButton onClick={onPrimaryButtonClicked}>
                <strong>Continue</strong>
              </SlidePrimaryButton>
            </SlideButtonGroup>
          </SlideIntro>
          <SlideDiagrams>
            <Transition appear in timeout={0}>
              {(state) => (
                <S.Diagram
                  style={{
                    height:
                      state === 'entering'
                        ? 40 // Magic number to account for the extra line in IntroInbox title
                        : Dimensions.DiagramHeight - Dimensions.DiagramOffset,
                    opacity: state === 'entering' ? 0 : 1,
                    transform:
                      state === 'entering'
                        ? 'translateY(-8px)'
                        : 'translateY(16px)',
                    pointerEvents: state === 'entering' ? 'none' : 'initial',
                  }}
                >
                  <S.PushNotificationWrapper>
                    <S.PushNotification
                      src={notificationDiagramSrc}
                      srcSet={`${notificationDiagramSrc} 2x`}
                    />
                  </S.PushNotificationWrapper>
                </S.Diagram>
              )}
            </Transition>
          </SlideDiagrams>
        </TransitionSlideContainer>
      </S.Inner>
      <SlideModalOverlay
        className={cx({
          isVisible: isNotificationPermissionsModalOpen,
        })}
      />
      {isNotificationPermissionsModalOpen && (
        <ConfirmationModal
          goForward={goForward}
          closeModal={() => setIsNotificationPermissionsModalOpen(false)}
          config={{
            headerContent: 'Enable reminders in Capture?',
            bodyContent: `Tap “OK” on the next screen to allow Capture to send you push notifications on this device.`,
            leftButton: {
              content: 'Maybe Later',
              onClick: onSkipButtonClick,
              goForwardOnClick: true,
            },
            rightButton: {
              content: 'OK',
              onClick: requestNotificationPermissionsAndGoForward,
            },
          }}
        />
      )}
    </S.IntroReminders>
  )
}

const Dimensions = {
  DiagramHeight: 80,
  DiagramOffset: 16,
}

const S = {
  IntroReminders: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',

    ...(isMobile && {
      padding: '0 24px',
    }),
  })),
  Inner: styled.div(() => ({
    width: '100%',
    maxWidth: isMobile ? 320 : 768,
  })),
  Diagram: styled.div(() => ({
    position: 'relative',
    zIndex: 1,
    transition: 'height 0.8s ease, opacity 0.4s ease, transform 0.4s ease',
  })),
  PushNotificationWrapper: styled.div(({}) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: Dimensions.DiagramHeight,
    marginTop: -30,
  })),
  PushNotification: styled.img(({ theme }) => ({
    all: 'unset',
    position: 'absolute',
    borderRadius: 24,
    background: theme.colors.surface.base,

    boxShadow: theme.effects.boxShadow.elevation[4],
  })),
}
