import {
  CreateRecordPayload,
  CURRENT_RICH_TEXT_VERSION,
  DataRecord,
  isTickleableRecord,
  RecordType,
  UpdateNoteRecordPayload,
  UpdateRecordPayload,
  UpdateTextRecordPayload,
} from '@eleventhlabs/capture-shared'
import { EditorsManager } from '../../../../EditorsManager'
import { RecordItemTargetType } from '../../../../recordItem/RecordItemTargetType'
import { isBeforeToday } from '../../../../utils'
import { CreateRecordResult } from './createRecord/types'

export const recordKeyDownEnter = (
  e: React.KeyboardEvent<HTMLDivElement>,
  records: Record<string, DataRecord>,
  recordId: string,
  createBelowAnchor: (
    payload: CreateRecordPayload,
    anchorId: string,
  ) => CreateRecordResult,
  updateRecordText: (
    recordId: string,
    payload: UpdateTextRecordPayload | UpdateNoteRecordPayload,
  ) => void,
  fullscreenRecordId: string | undefined,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
): CreateRecordResult => {
  if (fullscreenRecordId !== undefined) return undefined
  if (e.metaKey && !isOverdue(records, recordId)) {
    const { head, tail } = EditorsManager.get.valuesIfSplitAtSelection(recordId)
    EditorsManager.effects.deleteAllAfterSelectionInclusive(recordId)
    updateRecordText(recordId, {
      richText: { value: head, version: CURRENT_RICH_TEXT_VERSION },
    })
    const result = createBelowAnchor(
      {
        type: RecordType.Text,
        richText: { value: tail, version: CURRENT_RICH_TEXT_VERSION },
      },
      recordId,
    )
    if (!result) return undefined
    // TODO:
    // Must enforce blur to ensure the focus works.
    // A single mutation for updateRecord and createBelowAnchor should fix.
    EditorsManager.effects.blur(recordId)

    setRecentlyCreatedRecords((recentlyCreatedRecords) => ({
      ...recentlyCreatedRecords,
      [result.id]: RecordItemTargetType.Text,
    }))

    return result
  }
  return undefined
}

const isOverdue = (
  records: Record<string, DataRecord>,
  recordId: string,
): boolean => {
  const record = records[recordId]
  return (
    isTickleableRecord(record) &&
    record.tickler !== undefined &&
    isBeforeToday(record.tickler.isoDate)
  )
}
