import { useCallback } from 'react'

import { useExecContext } from '../../contexts/ExecContext'
import { useUIContext } from '../../contexts/UIContext'
import { EditorsManager } from '../../EditorsManager'
import { onBackspaceAtFirstPointWhenNotEmpty as handleBackspaceAtFirstPointWhenNotEmpty } from './onBackspaceAtFirstPointWhenNotEmpty'
import { onKeyDown as handleKeyDown } from './onKeyDown'
import { RichTextEventHandlers } from './RichTextEventHandlers'
import Analytics from '../../analytics/capture-analytics-actions'

export const useRichTextEventHandlers = (): RichTextEventHandlers => {
  const { exec } = useExecContext()

  const onArrowRightOrDownAtBoundary = useCallback(
    (recordId: string) => {
      return exec({ type: 'RECORD_ARROW_RIGHT_OR_DOWN_AT_BOUNDARY', recordId })
    },
    [exec],
  )

  const onArrowLeftOrUpAtBoundary = useCallback(
    (recordId: string) => {
      return exec({ type: 'RECORD_ARROW_LEFT_OR_UP_AT_BOUNDARY', recordId })
    },
    [exec],
  )

  const onBackspaceAtFirstPointWhenNotEmpty = useCallback(
    (recordId: string) => {
      return handleBackspaceAtFirstPointWhenNotEmpty(exec, recordId)
    },
    [exec],
  )

  const onChange = useCallback(
    (richText, recordId) => {
      return exec({ type: 'RECORD_CHANGE', recordId, richText })
    },
    [exec],
  )

  const { setRecentlyCreatedRecords } = useUIContext()

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>, recordId: string) => {
      return handleKeyDown(e, exec, recordId, setRecentlyCreatedRecords)
    },
    [exec],
  )

  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>, recordId: string) => {
      return exec({ type: 'RECORD_PASTE_FROM_CLIPBOARD', event, recordId })
    },
    [exec],
  )

  const onEnter = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>, recordId: string) => {
      return exec({
        type: 'RECORD_KEY_DOWN_ENTER',
        event,
        recordId,
        trigger: Analytics.RecordCreatedTrigger.KEYBOARD_COMMAND_ENTER,
      })
    },
    [exec],
  )

  const onEscape = useCallback(
    (e: React.KeyboardEvent<HTMLElement>, recordId: string) => {
      EditorsManager.effects.blur(recordId)
      exec({ type: 'SELECT_RECORD', recordId })
      return true
    },
    [exec],
  )

  return {
    onArrowLeftOrUpAtBoundary,
    onArrowRightOrDownAtBoundary,
    onBackspaceAtFirstPointWhenNotEmpty,
    onChange,
    onEnter,
    onEscape,
    onKeyDown,
    onPaste,
  }
}
