import React, { useLayoutEffect, useMemo, useState } from 'react'

import {
  WithDnDContext,
  withDnDContext,
} from '../../common/dnd/enhancers/withDnDContext'
import { RecordItemTargetType } from '../../common/recordItem'
import {
  cx,
  extractRGB,
  stackBackgroundStyles,
  styled,
} from '../../common/stationary'
import {
  CreateRecordButton,
  CreateRecordButtonConfig,
} from './CreateRecordButton'
import { useCaptureButtonsEventHandlers } from './hooks/useCaptureButtonsEventHandlers'
import { BOOKMARK, RECORD } from '../../common/utils/uiConstants'
import {
  KeyMappedActionIdType,
  keysFromActionType,
  useKeyMappedActions,
} from '../../common/keyMappableActions'
import { useKeyCaps } from '../../common/keyMappableActions/useKeyCaps'
import { SelectionActionBar } from '../SelectionActionBar'
import { isMobile } from '../../common/utils/env'

const toolTipPlacement = `top`

interface CaptureButtonsProps {
  groupId?: string
  className?: string
  isMouseActive: boolean
  isSecondaryButtonTextHidden: boolean
  onFileSelected: (files: File[]) => void
  createRecord: (
    type:
      | RecordItemTargetType.Text
      | RecordItemTargetType.Bookmark
      | RecordItemTargetType.Page,
  ) => void
}

type CaptureButtonsPropsEnhanced = CaptureButtonsProps & WithDnDContext

const CaptureButtons = ({
  className,
  onFileSelected,
  isDragInProgress,
  isSecondaryButtonTextHidden,
  isMouseActive,
  createRecord,
}: CaptureButtonsPropsEnhanced) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const { onPrimaryClick, onPageClick, onBookmarkClick, onFileClick } =
    useCaptureButtonsEventHandlers({
      createRecord,
    })

  const keyCaps = useKeyCaps()

  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined)

  const captureButtonsMap: CreateRecordButtonConfig[] = useMemo(() => {
    return [
      {
        iconVariant: 'glyphPlus',
        isTextHidden: false,
        onClick: onPrimaryClick,
        text: 'Capture',
        tooltipText: `Quick ${RECORD}`,
        targetType: RecordItemTargetType.Text,
        buttonVariant: 'simple',
      },
      {
        iconVariant: 'glyphTextMulti',
        isTextHidden: isSecondaryButtonTextHidden,
        onClick: onPageClick,
        text: 'Note',
        tooltipText: 'Create note w/ heading',
        targetType: RecordItemTargetType.Page,
        buttonVariant: 'plain',
      },
      {
        iconVariant: 'glyphLinkSingle',
        isTextHidden: isSecondaryButtonTextHidden,
        onClick: onBookmarkClick,
        text: BOOKMARK,
        tooltipText: `Save ${BOOKMARK.toLocaleLowerCase()}`,
        targetType: RecordItemTargetType.Bookmark,
        buttonVariant: 'plain',
      },
      {
        iconVariant: 'glyphFile',
        isFilePickerButton: true,
        isTextHidden: isSecondaryButtonTextHidden,
        onClick: onFileClick,
        onFileSelected: onFileSelected,
        text: 'File',
        tooltipText: 'Upload file',
        targetType: RecordItemTargetType.File,
        buttonVariant: 'plain',
      },
    ]
  }, [
    onFileSelected,
    onPrimaryClick,
    onPageClick,
    onBookmarkClick,
    isSecondaryButtonTextHidden,
  ])

  useLayoutEffect(() => {
    let totalButtonWidth = 0

    // Get the width of all child buttons in the capture button bar
    Array.from(
      document.querySelectorAll('.createRecordButton:not(.isOverflow)'),
    )
      // Only take into account the number of buttons in a single capture buttons instance
      .slice(0, captureButtonsMap.length)
      .forEach((buttonElement, index) => {
        totalButtonWidth += buttonElement.clientWidth
      })

    // Account for padding of 8px between all buttons
    totalButtonWidth += 32

    const trigger = () => {
      const current = ref.current
      if (!current) return

      const hasOverflow = current.clientWidth < totalButtonWidth

      setIsOverflow(hasOverflow)
    }

    // Call it once on first load
    trigger()

    // Recalculate overflow on resize
    window.addEventListener('resize', trigger)

    return () => {
      window.removeEventListener('resize', trigger)
    }
  }, [])

  return (
    <S.CaptureButtons className={cx(className, { isDragInProgress })} ref={ref}>
      <SelectionActionBar />
      <S.CreateRecordButton
        key={captureButtonsMap[0].targetType}
        isMouseActive={isMouseActive}
        isOverflow={false}
        disabled={!isMouseActive}
        tooltip={{
          title: captureButtonsMap[0].tooltipText,
          keycaps: keyCaps['KEY_MAPPED_CREATE_RECORD_AT_TOP'],
        }}
        tippyProps={{
          placement: toolTipPlacement,
        }}
        {...captureButtonsMap[0]}
      />
      {captureButtonsMap
        .slice(1, captureButtonsMap.length)
        .map((buttonConfigObj) => (
          <S.CreateRecordButton
            key={buttonConfigObj.targetType}
            isMouseActive={isMouseActive}
            isOverflow={isOverflow ?? false}
            disabled={!isMouseActive}
            tooltip={{
              title: buttonConfigObj.tooltipText,
            }}
            tippyProps={{
              placement: toolTipPlacement,
            }}
            {...buttonConfigObj}
          />
        ))}
    </S.CaptureButtons>
  )
}

export default withDnDContext(CaptureButtons)

export const CAPTURE_BUTTONS_HEIGHT = !isMobile ? 48 : 0

const S = {
  CaptureButtons: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    gap: 2,
    position: 'sticky',
    zIndex: 8,
    top: 56,
    height: CAPTURE_BUTTONS_HEIGHT,
    width: `100%`,
    marginBottom: 16,
    paddingLeft: 6,
    ...theme.text.publicSans['16:24'],
    lineHeight: `24px`,
    userSelect: `none`,
    outline: `none`,

    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 2,
      left: -20,
      right: -20,
      zIndex: -1,
      borderRadius: '0 0 8px 8px',
      background: `linear-gradient(
        rgba(${extractRGB(theme.colors.surface.lower)} / 1),
        rgba(${extractRGB(theme.colors.surface.lower)} / 1),
        rgba(${extractRGB(theme.colors.surface.lower)} / 0))`,
    },

    ':after': {
      content: '""',
      position: 'absolute',
      top: 2,
      bottom: 2,
      left: 0,
      right: 0,
      zIndex: -1,
      borderRadius: 8,
      ...stackBackgroundStyles({
        backgroundColors: [
          theme.colors.surface.lower,
          theme.colors.alpha.tone.extraWeak,
        ],
      }),
    },

    '&.isDragInProgress': {
      pointerEvents: `none`,
    },
  })),
  CreateRecordButton: styled(CreateRecordButton)(({ theme }) => ({})),
}
