import { useCallback, useState } from 'react'

import { emptyFn } from '../../utils'

export interface ShowProfileActionsData {
  showProfileActions: boolean
  setShowProfileActions: (value: boolean) => void
  toggleShowProfileActions: () => void
}

export const useProfileActions = () => {
  const [showProfileActions, setShowProfileActions] = useState(false)

  const toggleShowProfileActions = useCallback(() => {
    setShowProfileActions((value) => !value)
  }, [setShowProfileActions])

  return {
    showProfileActions,
    setShowProfileActions,
    toggleShowProfileActions,
  }
}

export const defaultShowProfileActions = {
  showProfileActions: false,
  setShowProfileActions: emptyFn,
  toggleShowProfileActions: emptyFn,
}
