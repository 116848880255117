import { forEach, sortBy } from 'lodash'

type KeyHandler = (event: KeyboardEvent) => void

export interface HotKey {
  priority: number
  handler: KeyHandler
  preventDefault: boolean
  preventLowerPriorities: boolean
}

export interface KeyboardManager {
  hotKeys: Record<string, HotKey[]>
  subscribe: (keys: string, hotKey: HotKey) => void
  unsubscribe: (keys: string, hotKey: HotKey) => void
  onKeyDown: (keys: string, event: KeyboardEvent) => void
  setMinimumPriority: (newMinimumPriority: number) => () => void
}

const keyboardManager: KeyboardManager = ((): KeyboardManager => {
  const hotKeys: Record<string, HotKey[]> = {}
  let minimumPriority = 0

  const subscribe = (keys: string, hotKey: HotKey) => {
    hotKeys[keys] = [...(hotKeys[keys] ?? []), hotKey]
  }

  const unsubscribe = (keys: string, hotKey: HotKey) => {
    hotKeys[keys] = (hotKeys[keys] ?? []).filter(
      (storedHotKey) => storedHotKey.handler !== hotKey.handler,
    )
  }

  const onKeyDown = (keys: string, event: KeyboardEvent) => {
    let preventPrioritiesLowerThan = 0
    const sortedHotKeys = sortBy(
      hotKeys[keys] ?? [],
      (hotKey) => -hotKey.priority,
    )

    forEach(sortedHotKeys, (hotKey) => {
      if (hotKey.preventDefault) event.preventDefault()
      if (
        preventPrioritiesLowerThan > hotKey.priority ||
        minimumPriority > hotKey.priority
      )
        return
      if (hotKey.preventLowerPriorities)
        preventPrioritiesLowerThan = hotKey.priority

      hotKey.handler(event)
    })
  }

  const setMinimumPriority = (newMinimumPriority: number) => {
    minimumPriority = newMinimumPriority > 0 ? newMinimumPriority : 0
    return () => {
      minimumPriority = 0
    }
  }

  return {
    hotKeys,
    subscribe,
    unsubscribe,
    onKeyDown,
    setMinimumPriority,
  }
})()

export default keyboardManager
