import React, { useCallback } from 'react'
import { DraggableChildrenFn, DraggableProvided } from 'react-beautiful-dnd'

import OutlineListItem, { ListItem } from './OutlineListItem'

export const useOutlineListItemRenderClone = (
  items: ListItem[],
): DraggableChildrenFn => {
  return useCallback(
    (provided, snapshot, rubric) => {
      const index = rubric.source.index
      const item = items[index]
      return <RenderClone provided={provided} item={item} />
    },
    [items],
  )
}

const RenderClone: React.FC<{
  provided: DraggableProvided
  item: ListItem
}> = ({ provided, item }) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      <OutlineListItem isActive={false} {...item} />
    </div>
  )
}
