import React, { useMemo } from 'react'
import {
  css,
  cx,
  Theme,
  useTheme,
  tooltipStyles,
} from '../../common/stationary'
import _ from 'lodash'

type BaseComponent = {
  className?: string
  editorClassName?: string
  notesOpen?: boolean
  placeholderType?: string
  placeholderClass?: string | undefined
}

export const withEditorTheme = <T extends BaseComponent>(
  Component: React.FC<T>,
): React.FC<T> => {
  const WrappedComponent: React.FC<T> = ({
    editorClassName,
    className,
    placeholderType,
    placeholderClass,
    ...props
  }) => {
    const theme = useTheme()
    const ss = getEditorCSS(theme)

    const _className = cx(ss.RichText, className ?? ``)

    const _editorClassName = cx(editorClassName, ss.Editor, className ?? ``)

    const isH1 = placeholderType === 'h1'
    const isH2 = placeholderType === 'h2'
    const isH3 = placeholderType === 'h3'
    const isP = placeholderType === 'p'
    const isFullscreen = editorClassName?.includes('isFullscreen') || false
    const notesOpen = editorClassName?.includes('notesOpen') || false

    const _placeholderClass = useMemo(() => {
      return (
        placeholderClass ??
        (!placeholderType
          ? null
          : cx(ss.Placeholder, {
              isFullscreen,
              isH1,
              isH2,
              isH3,
              isP,
              notesOpen,
            }))
      )
    }, [
      placeholderClass,
      placeholderType,
      isFullscreen,
      isH1,
      isH2,
      isH3,
      isP,
      notesOpen,
    ])

    const _contentEditableStyle = {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      paddingBottom: 20,
    }

    const _editorTheme = {
      paragraph: ss.Paragraph,
      heading: {
        h1: ss.H1,
        h2: ss.H2,
        h3: ss.H3,
      },
      list: {
        listitem: ss.ListItem,
        listitemChecked: ss.CheckboxChecked,
        listitemUnchecked: ss.CheckboxUnchecked,
        nested: {
          listitem: ss.NestedListItem,
        },
        ol: ss.OrderedList1,
        olDepth: [
          ss.OrderedList1,
          ss.OrderedList2,
          ss.OrderedList3,
          ss.OrderedList4,
          ss.OrderedList5,
        ],
        ul: ss.BulletList,
      },
      link: ss.Link,
      text: {
        bold: ss.TextBold,
        italic: ss.TextItalic,
        underline: ss.TextUnderline,
        strikethrough: ss.TextStrikethrough,
        underlineStrikethrough: ss.TextUnderlineStrikethrough,
        code: ss.TextCode,
      },
      code: ss.Code,
      floatingLinkViewer: ss.FloatingLinkViewer,
    }

    return (
      <Component
        {...(props as T)}
        editorClassName={_editorClassName}
        contentEditableStyle={_contentEditableStyle}
        className={_className}
        placeholderClass={_placeholderClass}
        editorTheme={_editorTheme}
      />
    )
  }
  return WrappedComponent
}

const getEditorCSS = (theme: Theme) => ({
  RichText: css({
    width: `100%`,
    lineHeight: `24px`,
  }),
  Editor: css({
    width: `100%`,

    '>div': {
      '&:focus-visible': {
        outline: 'none',
      },
    },
  }),
  H1: css({
    ...theme.text.publicSans['27.5:36'],
    fontWeight: 700,
    paddingTop: 12,
    marginBottom: 8,

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  H2: css({
    ...theme.text.publicSans['23:32'],
    fontWeight: 700,
    paddingTop: 12,
    marginBottom: 8,

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  H3: css({
    ...theme.text.publicSans['19:28'],
    fontWeight: 700,
    paddingTop: 12,
    marginBottom: 8,

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  Paragraph: css({
    ...theme.text.publicSans['16:24'],
    fontWeight: 400,
    paddingTop: 0,
    marginBottom: 8,

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  BulletList: css({
    marginBottom: 8,
    listStyle: 'disc',
    marginLeft: 20,

    li: {
      marginBottom: 8,

      ':last-child': {
        marginBottom: 0,
      },
    },
    'li span': {
      position: `relative`,
    },
    'li[role="checkbox"] span': {
      position: `relative`,
      marginLeft: 4,
      marginTop: 0,
      display: `block`,
    },
    'li>ul': {
      paddingLeft: 0,
    },

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  OrderedList1: css({
    marginBottom: 8,
    listStyle: 'decimal',
    marginLeft: 25,

    li: {
      marginBottom: 8,

      ':last-child': {
        marginBottom: 0,
      },
    },
    'li span': {
      position: `relative`,
      display: `block`,
      marginLeft: 6,
    },
    'li>ol': {
      paddingLeft: 0,
    },

    'li>em': {
      paddingLeft: 8,
    },
    'li>strong': {
      paddingLeft: 8,
    },

    '> li': {
      marginLeft: '0px',
    },

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  OrderedList2: css({
    marginBottom: 8,
    listStyle: `lower-alpha`,

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  OrderedList3: css({
    marginBottom: 8,
    listStyle: `lower-roman`,

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  OrderedList4: css({
    marginBottom: 8,
    listStyle: `decimal`,

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  OrderedList5: css({
    marginBottom: 8,
    listStyle: `lower-alpha`,

    ':only-child, :last-child': {
      marginBottom: 0,
    },
  }),
  ListItem: css({}),
  CheckboxUnchecked: css({
    position: `relative`,
    paddingLeft: 26,
    marginLeft: -21,
    listStyle: `none`,

    '::before': {
      content: '""',
      position: 'absolute',
      top: 4,
      left: 2,
      width: 16,
      height: 16,
      border: `1px solid ${theme.colors.alpha.border.extraStrong}`,
      borderRadius: 4,
      cursor: 'pointer',
      transitionDuration: `0.2s`,
    },
  }),
  CheckboxChecked: css({
    position: `relative`,
    paddingLeft: 26,
    marginLeft: -21,
    listStyleType: `none`,

    '&[aria-checked="true"]': {
      textDecoration: 'line-through',
      color: theme.colors.text[500],
    },

    'li span': {
      position: `relative`,
      marginLeft: 4,
      display: `block`,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 4,
      left: 2,
      width: 16,
      height: 16,
      border: `1px solid ${theme.colors.alpha.selection.solid}`,
      borderRadius: 4,
      background: theme.colors.alpha.selection.solid,
      cursor: 'pointer',
      transitionDuration: `0.2s`,
    },
    '::after': {
      content: `"✓"`,
      position: `absolute`,
      top: 0,
      left: 4,
      color: theme.colors.alpha.tint.solid,
      pointerEvents: 'none',
    },
  }),
  NestedListItem: css({
    listStyleType: `none`,
    paddingLeft: 0,
    marginLeft: 26,

    '&::before': {
      display: `none`,
    },
    '&::after': {
      display: `none`,
    },
  }),
  Code: css({
    tabSize: 2,
    overflowX: `auto`,
    position: `relative`,
    padding: '0 4px',
    fontFamily: 'Courier New',
    fontSize: '112.5%',
    fontWeight: 600,
    background: theme.colors.alpha.tone.mediumWeak,

    [`>code`]: {
      padding: 0,
      background: 'none',
    },
  }),

  TextBold: css({
    fontWeight: `bold`,
  }),

  TextItalic: css({
    fontStyle: `italic`,
  }),

  TextUnderline: css({
    textDecoration: `underline`,
  }),

  TextStrikethrough: css({
    textDecoration: `line-through`,
  }),

  TextUnderlineStrikethrough: css({
    textDecoration: `underline line-through`,
  }),

  TextCode: css({
    padding: '0 4px',
    fontFamily: 'Courier New',
    fontSize: '112.5%',
    fontWeight: 600,
    background: theme.colors.alpha.tone.mediumWeak,
  }),

  Link: css({
    color: `rgb(33, 111, 219)`,
    textDecoration: `underline`,

    '&:hover': {
      cursor: `pointer`,
    },
  }),
  Placeholder: css({
    color: theme.colors.text[500],
    overflow: `hidden`,
    position: `absolute`,
    textOverflow: `ellipsis`,
    left: 0,
    ...theme.text.publicSans['16:24'],
    userSelect: `none`,
    display: `inline-block`,
    pointerEvents: `none`,
    top: 20,
    paddingLeft: 20,

    '&.isFullscreen': {
      top: 48,

      '&.notesOpen': {
        marginTop: 99,
      },
    },

    '&.isH1': {
      ...theme.text.publicSans['27.5:36'],
      fontWeight: 700,
      paddingTop: 12,
    },

    '&.isH2': {
      ...theme.text.publicSans['23:32'],
      fontWeight: 700,
      paddingTop: 12,
    },

    '&.isH3': {
      ...theme.text.publicSans['19:28'],
      fontWeight: 700,
      paddingTop: 12,
    },
  }),
  FloatingLinkViewer: css({
    display: `flex`,
    alignItems: `center`,
    justifyContent: 'flex-start',
    height: 28,
    padding: 8,
    borderRadius: 6,
    fontWeight: 600,
    zIndex: 500,
    maxWidth: 500,
    ...theme.text.publicSans[`13.5:20`],

    ...tooltipStyles.rootVariants['shade']({ theme }),

    '>a': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
})
