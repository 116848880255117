import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

import Analytics, {
  ActiveScreenValueType,
  TargetRecordType,
} from '../../../common/analytics/capture-analytics-actions'
import { useActiveScreen } from '../../../common/analytics/capture-analytics-web'
import { useExecContext } from '../../../common/contexts/ExecContext'
import { RecordItemTargetType } from '../../../common/recordItem'

interface CaptureButtonsEventHandlers {
  onPrimaryClick: () => void
  onPageClick: () => void
}

export const useFloatingActionsEventHandlers =
  (): CaptureButtonsEventHandlers => {
    const { exec } = useExecContext()
    // We know active screen is always defined here b/c the buttons only show up on
    // a valid screen
    const activeScreen = useActiveScreen() as ActiveScreenValueType

    const onPrimaryClick = useCallback(() => {
      exec({
        type: 'CREATE_RECORD_AT_FIRST_POSITION',
        openRecordInFullScreen: true,
        recordId: uuidv4(),
        trigger: Analytics.RecordCreatedTrigger.FLOATING_ACTION,
        targetType: RecordItemTargetType.Text,
      })

      Analytics.floatingButtonClicked({
        targetRecordType: TargetRecordType.TEXT,
        activeScreen,
      })
    }, [exec])

    const onPageClick = useCallback(() => {
      exec({
        type: 'CREATE_RECORD_AT_FIRST_POSITION',
        openRecordInFullScreen: true,
        recordId: uuidv4(),
        targetType: RecordItemTargetType.Page,
        trigger: Analytics.RecordCreatedTrigger.FLOATING_ACTION,
      })

      Analytics.floatingButtonClicked({
        targetRecordType: TargetRecordType.PAGE,
        activeScreen,
      })
    }, [exec])

    return {
      onPrimaryClick,
      onPageClick,
    }
  }
