import { format, isSameDay, parseISO } from 'date-fns'
import { isArray } from 'lodash'
import React, { useCallback } from 'react'
import Calendar, { CalendarTileProperties } from 'react-calendar'

import { useHotkeysThreshold } from '../../common/hooks/useHotkeysThreshold'
import { Key, Priorities, useKeys } from '../../common/hooks/useKeys'
// import { calendarStyles } from '../../common/styles'
import {
  Icon,
  calendarStyles,
  calendarTileStyles,
  css,
  cx,
  styled,
  useTheme,
} from '../../common/stationary'
import { tokenFormatISO } from '../../common/utils'
import { Modal } from '../../common/dialogs'

import Sugar from 'sugar'

interface CalendarDialogProps {
  initialDate: Date
  maxDate?: Date
  onSelectDate: (value: string) => void
  isOpen?: boolean
  onDismiss: (e?: React.SyntheticEvent) => void
}

export const CalendarDialog: React.FC<CalendarDialogProps> = ({
  isOpen,
  onDismiss,
  initialDate,
  maxDate,
  onSelectDate,
}) => {
  // const aggregatedStyles = useMemo(() => ({ ...calendarStyles, ...styles }), [])
  // const { ss } = useCaptureTheme(aggregatedStyles)

  const theme = useTheme()

  const close = useCallback(() => onDismiss && onDismiss(), [onDismiss])

  useKeys(Key.Esc, close, Priorities.Dialog, true)
  useHotkeysThreshold(Priorities.Dialog)

  const onCalendarSelect = useCallback(
    (date: Date | Date[]) => {
      if (isArray(date)) return
      onSelectDate(format(date, tokenFormatISO))
    },
    [onSelectDate],
  )

  const currentDate = Sugar.Date.create()

  return (
    <S.CalendarDialog
      aria-label="CalendarDialog"
      open={isOpen ?? false}
      onClose={close}
    >
      <S.Inner>
        <Calendar
          className={cx(css(calendarStyles({ theme })))}
          tileClassName={({ date }: CalendarTileProperties) => {
            return cx(
              {
                currentDate: currentDate
                  ? isSameDay(date, parseISO(currentDate.toISOString()))
                  : false,
              },
              css(calendarTileStyles({ theme })),
            )
          }}
          onChange={onCalendarSelect}
          value={initialDate}
          minDate={initialDate}
          maxDate={maxDate}
          calendarType="US"
          prev2Label={null}
          next2Label={null}
          prevLabel={<Icon boxSize={12} variant="glyphChevronLeft12" />}
          nextLabel={<Icon boxSize={12} variant="glyphChevronRight12" />}
        />
      </S.Inner>
    </S.CalendarDialog>
  )
}

export default CalendarDialog

const S = {
  CalendarDialog: styled(Modal)({}),
  Inner: styled.div(({ theme }) => ({
    marginTop: 96,
    maxWidth: '100%',

    width: 300,
    height: `fit-content`,
    overflow: `hidden`,
    borderRadius: 8,
    background: theme.colors.surface.base,
    padding: `28px`,
    boxSizing: `content-box`,
  })),
  Calender: styled(Calendar)(({ theme }) => ({
    padding: 16,
    ...theme.text.publicSans['13.5:20'],
    // width: 160,

    '.react-calendar__navigation': {
      display: 'flex',
    },

    '.react-calendar__navigation__arrow': {
      height: 32,
      width: 24,
      padding: 0,
      border: `none`,
      background: `none`,
      appearance: `none`,
      outline: `none`,
      cursor: `pointer`,
      color: theme.colors.text[900],

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover': {
        background: theme.colors.alpha.tone.mediumWeak,
      },
    },
    '.react-calendar__navigation__label': {
      height: 32,
      padding: 0,
      border: `none`,
      fontWeight: `bold`,
      background: `none`,
      appearance: `none`,
      outline: `none`,
      cursor: `pointer`,
      color: theme.colors.text[900],
      pointerEvents: 'none', // Disables label click. This prevents the user from selecting the month and year, since the default UX in react-calendar is confusing

      '&:hover': {
        background: theme.colors.alpha.tone.mediumWeak,
      },
    },
    '.react-calendar__month-view__weekdays__weekday': {
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      ...theme.text.publicSans['11.5:16'],
      lineHeight: `16px`,
      height: 32,
      color: theme.colors.text[700],

      abbr: {
        textDecoration: `none`,
      },
    },
    '.react-calendar__month-view__days__day--neighboringMonth': {
      color: theme.colors.text[500],
    },
    '.react-calendar__navigation__label__labelText': {
      fontWeight: `bold`,
      ...theme.text.publicSans['13.5:20'],
    },
  })),
}
