import { EditorsManager } from '../../../../EditorsManager'
import Analytics, {
  ActiveScreenValueType,
} from '../../../../analytics/capture-analytics-actions'
import { RecordItemTargetType } from '../../../../recordItem'

export const noteBlurred = (
  recordId: string | undefined,
  noteId: string,
  setFocusedRecordId: (recordId?: string | undefined) => void,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
  activeScreen: ActiveScreenValueType | undefined,
) => {
  const editorStats = EditorsManager.analytics.flush(noteId)
  if (editorStats) {
    Analytics.annotationContentUpdated({
      ...editorStats,
      activeScreen,
      recordId: noteId,
    })
  }

  setFocusedRecordId(undefined)

  if (recordId) {
    setRecentlyCreatedRecords(
      (recentlyCreatedRecords: Record<string, RecordItemTargetType>) => {
        delete recentlyCreatedRecords[noteId]
        return recentlyCreatedRecords
      },
    )
  }
}
