import { useCallback } from 'react'

import { useNavigate } from '@gatsbyjs/reach-router'

import { focusAction } from '../events'
import { getDetailsUrl } from '../routes'

interface PostNavigateOptions {
  focus?: boolean
}

export const useNavigateToDetails = () => {
  const navigate = useNavigate()

  const navigateToList = useCallback(
    (id: string, options: PostNavigateOptions = { focus: false }) => {
      navigate(getDetailsUrl(id)).then(() => {
        if (options.focus) focusAction({ id })
      })
    },
    [navigate],
  )

  return navigateToList
}
