import { titleOrDefault } from '../../records'
import { ListRecord } from '@eleventhlabs/capture-shared'
import { Destination, InboxDestination, InboxDestinationText } from './types'
import { IconVariantName } from '../../stationary'

export const createLocalDestination = (
  id: string,
  title: string,
  emoji?: string,
  iconVariant?: IconVariantName,
): Destination => {
  return {
    emoji,
    iconVariant,
    title: titleOrDefault(title),
    id,
  }
}

export const mapListRecordToDestination = (
  listRecord: ListRecord,
): Destination => {
  return createLocalDestination(
    listRecord.id,
    listRecord.title,
    listRecord.emoji,
  )
}

export const inboxDestination: Destination = createLocalDestination(
  InboxDestination,
  InboxDestinationText,
  undefined,
  `glyphSquircleInbox`,
)
