import Analytics from '../../analytics/capture-analytics-actions'
import { ExecAction } from '../../useExec'

export const onBackspaceWhenEmpty = (
  exec: ExecAction,
  noteId: string,
): boolean => {
  exec({
    type: `REMOVE_ANNOTATION`,
    noteId,
    trigger: Analytics.AnnotationDeletedTrigger.KEYBOARD_BACKSPACE,
  })
  return true
}
