import { first, isEmpty } from 'lodash'
import { useCallback } from 'react'

import {
  isItemRecord,
  isParentableRecord,
  RecordType,
  ReferenceInsertPoint,
  RichText,
  SortDirection,
  UpdateTicklerPayload,
} from '@eleventhlabs/capture-shared'
import { useNavigate } from '@gatsbyjs/reach-router'

import * as Analytics from '../../../analytics/capture-analytics-actions'
import { ScreenTypeValueType } from '../../../analytics/capture-analytics-actions'
import {
  getRecordsDatesSetEventProps,
  getRecordsMovedDestinationType,
  getScreenNavigationStartedScreenTypeFromRouteType,
  useActiveScreen,
} from '../../../analytics/capture-analytics-web'
import {
  getDestinationTypeFromMoveToPlaceIndex,
  getRecordType,
} from '../../../analytics/helpers'
import {
  SHOULD_SKIP_LANDING_COOKIE_NAME,
  useAuth,
} from '../../../contexts/AuthContext'
import { useDerivedStateContext } from '../../../contexts/DerivedStateContext'
import { useModelStoreContext } from '../../../contexts/ModelStoreContext'
import { useReactDnDContext } from '../../../contexts/ReactDnDContext'
import { useSelectionContext } from '../../../contexts/SelectionContext'
import { useSnackbarContext } from '../../../contexts/SnackbarContext'
import { useThemeName } from '../../../contexts/ThemeNameContext'
import { useUIContext } from '../../../contexts/UIContext'
import { NoteList } from '../../../data/notes'
import { PlaceTag } from '../../../data/tags'
import {
  useCollapseRecordItems,
  useExpandRecordItems,
  useToggleRecordItemsIsExpanded,
} from '../../../expandRecord'
import { useActiveRecordIds } from '../../../hooks/useActiveRecordIds'
import { useConvertToFileRecord } from '../../../hooks/useConvertToFileRecord'
import { useGetUrlMetadata } from '../../../hooks/useGetUrlMetadata'
import { useNavigateToDetails } from '../../../hooks/useNavigateToDetails'
import { useNavigateToList } from '../../../hooks/useNavigateToList'
import {
  getAutoSystemThemeName,
  systemThemeName,
} from '../../../hooks/useSavedTheme'
import { RecordItemTargetType } from '../../../recordItem'
import { Records } from '../../../records'
import { RouteType, useRouteFromLocation } from '../../../routes'
import {
  useRecordGroupsOrdering,
  useRecordGroupsSelectionInteractions,
} from '../../../selection/useRecordGroupsInteractions'
import { themesConfig } from '../../../stationary'
import { deleteCookie } from '../../../utils'
import { ExecActionFunction } from '../../Action.type'
import {
  addAnnotation,
  closeAnnotations,
  convertToTextRecord,
  convertToUrlRecord,
  copy,
  createCollection,
  createRecord,
  CreateRecordPosition,
  CreateRecordResult,
  deleteRecords,
  emptyTrash,
  exitFullscreen,
  expandToFullscreen,
  fileRecordOpenSrcInTab,
  goToInbox,
  goToToday,
  goToTrash,
  moveRecords,
  MoveRecordsResult,
  moveRecordsToTrash,
  navigateTo,
  noteArrowLeftOrUpAtBoundary,
  noteArrowRightOrDownAtBoundary,
  noteBlurred,
  noteChange,
  noteEscaped,
  noteFocused,
  noteKeyDownLowerThan,
  noteMouseDown,
  openAnnotations,
  placeTagClick,
  recordArrowLeftOrUpAtBoundary,
  recordArrowRightOrDownAtBoundary,
  recordBackspaceAtFirstPointWhenNotEmpty,
  recordBackspaceWhenEmpty,
  recordBlurred,
  recordChange,
  recordClicked,
  recordFocused,
  recordHandleClicked,
  recordKeyDownEnter,
  recordMouseDown,
  recordPasteFromClipboard,
  recordRenameFile,
  recordSkipOccurrence,
  removeDate,
  removeList,
  removeNote,
  renameCollection,
  renameFile,
  restoreRecords,
  selectAllRecords,
  selectRecord,
  setCollectionEmoji,
  sortByDate,
  sortByLocation,
  switchToTheme,
  toggleAnnotations,
  toggleIsCompleted,
  toggleNextFourteenDays,
  toggleShowCompleted,
  toggleSidebar,
  updateRecordTickler,
  urlRecordOpenSrcInTab,
  viewJWT,
} from './actions'
import {
  useFocusNextRecord,
  useFocusPrevRecord,
} from './actions/analyticsHelpers/focus'
import { deleteNotes } from './actions/deleteNotes'
import { onboardingSubmitQuickCapture } from './actions/onboardingSubmitQuickCapture'
import { toggleKeyboardShortcutsPane } from './actions/openKeyboardShortcutsPane'
import { getParentId } from './actions/utils'

export type ExecDefaultAction = ExecActionFunction<DefaultActionDefinitions>

export const useExecDefaultAction = (): ExecDefaultAction => {
  const { revokeSessionAndDeleteCookies, setErrorState } = useAuth()
  const navigate = useNavigate()
  const navigateToList = useNavigateToList()
  const navigateToDetails = useNavigateToDetails()
  const { store, commands } = useModelStoreContext()
  const {
    showCompleted,
    todayFilters: { toggleShowNextFourteenDays },
    shouldDisplayNotesForRecordId,
    setNotesVisibilityForRecordIds,
    toggleIsLeftHidden,
    isLeftHidden,
    toggleIsRightHidden,
    toggleShowCompleted: _toggleShowCompleted,
    setSavedThemeName,
    lastRoute,
    setLastRoute,
    setLastSelection,
    setLastDefaultId,
    setShouldRestoreSelection,
    setRecentlyCreatedRecords,
  } = useUIContext()
  const { selection, selectedRecords, focusedRecordId, setFocusedRecordId } =
    useSelectionContext()
  const activeScreen = useActiveScreen()
  const { routeType, listId } = useRouteFromLocation()
  const getUrlMetadata = useGetUrlMetadata()

  const { recordGroups, recordGroupCommands, visibleItemIds, uploadFile } =
    useDerivedStateContext()
  const { fullscreenRecordId, activeRecordIfSingle, activeRecords } =
    useActiveRecordIds(visibleItemIds)

  const { draggedRecords, hoverRecord } = useReactDnDContext()

  const selectionInteractions = useRecordGroupsSelectionInteractions(
    selection,
    recordGroups,
    recordGroupCommands,
  )
  const recordGroupsOrdering = useRecordGroupsOrdering(recordGroups)

  const { setSoftDeleteConfirmationCount } = useSnackbarContext()

  const _addNoteAtBottomOfList = useCallback(
    (
      recordId: string,
      triggerCreatedAnnotation: Analytics.AnnotationCreatedTriggerValueType,
      triggerShowAnnotation: Analytics.ShowAnnotationsToggleTriggerValueType,
    ) =>
      NoteList.addNoteAtBottomOfList(
        commands.createNote,
        setNotesVisibilityForRecordIds,
        recordId,
        triggerShowAnnotation,
        setRecentlyCreatedRecords,
      ),
    [commands, setNotesVisibilityForRecordIds, setRecentlyCreatedRecords],
  )

  const convertNewRecordToUrlRecord = useCallback(
    async (recordId: string, url: string) => {
      const success = await Records.convertToUrlRecord(
        recordId,
        url,
        commands.updateRecord,
        commands.updateRecordText,
        selection.setDefaultId,
        getUrlMetadata,
      )
      return success
    },
    [
      commands.updateRecord,
      commands.updateRecordText,
      selection.setDefaultId,
      getUrlMetadata,
    ],
  )

  const { convertToFileRecord } = useConvertToFileRecord(
    commands.updateRecord,
    commands.deleteRecords,
    selection.setDefaultId,
    uploadFile,
  )

  // Set up analytics for different actions
  const focusNextRecord = useFocusNextRecord(visibleItemIds)
  const focusPrevRecord = useFocusPrevRecord(visibleItemIds)

  // Expand / collapse record item
  const collapseRecordItems = useCollapseRecordItems()
  const expandRecordItems = useExpandRecordItems()
  const toggleRecordItemsIsExpanded = useToggleRecordItemsIsExpanded()

  // Reset theme on logout
  const { setThemeName } = useThemeName()

  // Types can be terse to read, but the "action" has
  // a "type" property and any other parameters in the ActionDef's payload.
  // @ts-ignore
  const execDefaultAction: ExecDefaultAction = (action) => {
    switch (action.type) {
      case `ADD_ANNOTATION`: {
        const { noteOpened, noteCreated, newNoteId } = addAnnotation(
          store.records,
          activeRecords(action.recordId),
          _addNoteAtBottomOfList,
          setNotesVisibilityForRecordIds,
          action.createAnnotationTrigger,
          action.showAnnotationsTrigger,
          setRecentlyCreatedRecords,
        )

        if (noteOpened) {
          Analytics.showAnnotationsToggled({
            activeScreen,
            numRecords: 1,
            showAnnotationsToggleTrigger: action.showAnnotationsTrigger,
          })
        } else if (noteCreated && newNoteId) {
          Analytics.annotationCreated({
            activeScreen,
            annotationCreatedTrigger: action.createAnnotationTrigger,
            recordId: newNoteId,
          })
        }
        return
      }
      case 'RECORD_ITEMS_COLLAPSE': {
        const { targetId } = action
        const activeIds = activeRecords()
        const recordIds =
          targetId !== undefined && !activeIds.includes(targetId)
            ? [targetId]
            : activeIds
        collapseRecordItems(recordIds)
        Analytics.recordCollapsed({
          activeScreen,
          recordIds,
          numRecords: recordIds.length,
          recordCollapsedTrigger: action.trigger,
        })
        return
      }
      case `COPY`: {
        return copy(store.records, selection, visibleItemIds)
      }
      case 'RECORD_ITEMS_EXPAND': {
        const { targetId } = action
        const activeIds = activeRecords()
        const recordIds =
          targetId !== undefined && !activeIds.includes(targetId)
            ? [targetId]
            : activeIds
        expandRecordItems(recordIds)
        Analytics.recordExpanded({
          activeScreen,
          numRecords: recordIds.length,
          recordExpandedTrigger: action.trigger,
          recordIds,
        })
      }
      case `EXIT_FULLSCREEN`: {
        if (!fullscreenRecordId) return
        const record = store.records[fullscreenRecordId]
        if (!isParentableRecord(record)) return
        return exitFullscreen(
          record?.parentId,
          lastRoute,
          setLastRoute,
          navigate,
          setShouldRestoreSelection,
        )
      }
      case `EXPAND_TO_FULLSCREEN`: {
        const { recordId, trigger, triggeredByKeyboard, triggeredByUI } = action
        const activeRecord = activeRecordIfSingle(recordId)
        if (activeScreen && activeRecord) {
          const record = store.records[activeRecord]
          if (isItemRecord(record)) {
            Analytics.recordOpened({
              activeScreen,
              recordId: activeRecord,
              recordOpenedTrigger: trigger,
              recordType: record.type,
              triggeredByKeyboard,
              triggeredByUi: triggeredByUI,
            })
          }
        }
        return (
          activeRecord &&
          expandToFullscreen(
            activeRecord,
            location.pathname,
            setLastRoute,
            navigateToDetails,
            selection.ids,
            setLastSelection,
            selection.defaultId,
            setLastDefaultId,
          )
        )
      }
      case `MOVE_RECORDS`: {
        const draggedRecordIds = draggedRecords.map((record) => record.id)
        const moveRecordsResult = moveRecords(
          recordGroupCommands,
          recordGroupsOrdering,
          commands,
          action,
          draggedRecordIds,
          hoverRecord,
        )
        if (moveRecordsResult.success) {
          // TODO: Consolidate analytics with RecordsReordered / RecordsMoved
          Analytics.recordsMoved({
            activeScreen,
            moveToDestinationType: getRecordsMovedDestinationType(
              action.parentRecordId,
            ),
            numRecords: action.recordIds.length,
            recordsMovedTrigger: action.trigger,
            moveType: moveRecordsResult.moveType,
            recordIds: action.recordIds,
            newParentId: action.parentRecordId,
          })
        }
        return moveRecordsResult
      }
      case `REMOVE_LIST`: {
        // TODO: Add Analytics
        return removeList(
          store.records,
          navigate,
          commands.deleteRecords,
          action.listId ?? listId,
          listId,
        )
      }
      case `TOGGLE_ANNOTATIONS`: {
        const { notesOpened, notesCreated, notesClosed, newNoteId } =
          toggleAnnotations(
            store.records,
            activeRecords(action.recordId),
            commands.deleteNotes,
            shouldDisplayNotesForRecordId,
            _addNoteAtBottomOfList,
            setNotesVisibilityForRecordIds,
            action.showAnnotationsTrigger,
            action.createAnnotationTrigger,
            setRecentlyCreatedRecords,
          )

        if (notesOpened || notesClosed) {
          Analytics.showAnnotationsToggled({
            activeScreen,
            numRecords: notesOpened || notesClosed,
            showAnnotationsToggleTrigger: action.showAnnotationsTrigger,
          })
        } else if (notesCreated && newNoteId) {
          Analytics.annotationCreated({
            activeScreen,
            annotationCreatedTrigger: action.createAnnotationTrigger,
            recordId: newNoteId,
          })
        }

        return
      }
      case `RECORDS_TOGGLE_IS_COMPLETED`: {
        const result = toggleIsCompleted(
          action.targetId,
          activeRecords(),
          store.records,
          commands.completeRecords,
          commands.uncompleteRecords,
        )
        if (result.succeeded) {
          if (result.payload.setToValue) {
            Analytics.recordsCompleted({
              activeScreen,
              numRecords: result.payload.count,
              toggleRecordsCompletedTrigger: action.trigger,
              recordIds: result.payload.affectedRecordIds,
            })
          } else {
            Analytics.recordsUncompleted({
              activeScreen,
              numRecords: result.payload.count,
              toggleRecordsCompletedTrigger: action.trigger,
              recordIds: result.payload.affectedRecordIds,
            })
          }
          return result.payload.setToValue
        }
        return false
      }
      case `TOGGLE_SHOW_COMPLETED`: {
        const toggleTo = toggleShowCompleted(_toggleShowCompleted)
        Analytics.showCompletedToggled({
          activeScreen,
          showCompletedToggleTrigger: action.trigger,
          showCompletedToggleValue: toggleTo,
        })
        return toggleTo
      }
      case `FOCUS_FILE_TITLE_INPUT`: {
        return renameFile(activeRecords())
      }
      case `CREATE_RECORD_AT_START_OF_GROUP`: {
        const {
          recordId,
          groupId,
          targetType,
          trigger,
          triggeredByKeyboard,
          triggeredByUI,
          openRecordInFullScreen,
          richText,
          focusNewRecord,
        } = action

        return createRecord({
          recordGroupsCommands: recordGroupCommands,
          location: {
            position: CreateRecordPosition.START_OF_GROUP,
          },
          setRecentlyCreatedRecords,
          positionArgs: {
            id: recordId,
            groupId: groupId ?? recordGroups[0]?.id,
            targetType,
            currentRoute: location.pathname,
            setLastRoute,
            currentSelection: selection.ids,
            setLastSelection,
            setLastDefaultId,
            navigate: navigateToDetails,
            openRecordInFullScreen,
            richText,
            focusNewRecord,
          },
        }).then((result) => {
          if (result) {
            Analytics.recordCreated({
              destinationType: getDestinationTypeFromMoveToPlaceIndex(
                result.location,
              ),
              recordCreatedTrigger: trigger,
              recordType: getRecordType(result.type),
              recordId: result.id,
              activeScreen,
            })

            if (openRecordInFullScreen && activeScreen) {
              Analytics.recordOpened({
                recordId: result.id,
                activeScreen,
                triggeredByKeyboard,
                triggeredByUi: triggeredByUI,
                recordOpenedTrigger: `Record Created`,
                recordType: getRecordType(result.type),
              })
            }
          }

          return result
        })
      }
      case `CREATE_COLLECTION`: {
        const id = createCollection(
          commands.createRecord,
          navigate,
          isLeftHidden,
          () => toggleSidebar(toggleIsLeftHidden),
        )

        Analytics.listCreated({
          activeScreen,
          listCreatedTrigger: action.trigger,
          listCreatedWithEmptyTitle: true,
          recordId: id,
        })
        return
      }
      case `MOVE_RECORDS_TO_TRASH`: {
        const { recordId, trigger, triggeredByKeyboard, triggeredByUI } = action
        const { softDeletedIds, hardDeletedIds } = moveRecordsToTrash(
          store.records,
          recordId,
          fullscreenRecordId,
          routeType,
          lastRoute,
          setLastRoute,
          navigate,
          setShouldRestoreSelection,
          activeRecords,
          visibleItemIds,
          selection,
          commands.deleteRecords,
          commands.softDeleteRecords,
        )
        if (!isEmpty(hardDeletedIds)) {
          Analytics.recordsDeleted({
            activeScreen,
            numRecords: hardDeletedIds.length,
            recordsDeletedTrigger: trigger,
            triggeredByKeyboard,
            triggeredByUi: triggeredByUI,
            recordIds: hardDeletedIds,
          })
        }
        if (!isEmpty(softDeletedIds)) {
          Analytics.recordsSoftDeleted({
            activeScreen,
            numRecords: softDeletedIds.length,
            recordsSoftDeletedTrigger: trigger,
            triggeredByKeyboard,
            triggeredByUi: triggeredByUI,
            recordIds: softDeletedIds,
          })
        }

        setSoftDeleteConfirmationCount(softDeletedIds.length)

        return {
          numSoftDeleted: softDeletedIds.length,
          numDeleted: hardDeletedIds.length,
        }
      }
      case `DELETE_RECORDS`: {
        const { recordIds, trigger, triggeredByKeyboard, triggeredByUI } =
          action
        if (routeType === RouteType.Details && fullscreenRecordId) {
          const record = store.records[fullscreenRecordId]
          const parentId = isParentableRecord(record)
            ? record.parentId
            : undefined
          exitFullscreen(
            parentId,
            lastRoute,
            setLastRoute,
            navigate,
            setShouldRestoreSelection,
          )
        }
        const deletedRecordIds =
          recordIds &&
          deleteRecords(
            recordIds,
            visibleItemIds,
            selection,
            commands.deleteRecords,
          )
        if (recordIds && !isEmpty(deletedRecordIds)) {
          Analytics.recordsDeleted({
            activeScreen,
            numRecords: recordIds.length,
            recordsDeletedTrigger: trigger,
            triggeredByKeyboard,
            triggeredByUi: triggeredByUI,
            recordIds,
          })
        }
        return recordIds?.length ?? 0
      }
      case `EMPTY_TRASH`: {
        const { trigger, triggeredByKeyboard, triggeredByUI } = action
        const numDeleted = emptyTrash(
          store.softDeletedRecords,
          commands.deleteRecords,
        )
        if (numDeleted) {
          Analytics.emptyTrash({
            activeScreen,
            numRecords: numDeleted,
            emptyTrashTrigger: trigger,
            triggeredByKeyboard,
            triggeredByUi: triggeredByUI,
          })
        }
        return numDeleted
      }
      case `RESTORE_RECORDS`: {
        const { trigger, triggeredByKeyboard, triggeredByUI } = action
        const restoredRecordIds = restoreRecords(
          action.recordId,
          activeRecords,
          commands.unSoftDeleteRecords,
        )
        if (!isEmpty(restoredRecordIds)) {
          Analytics.recordsRestored({
            activeScreen,
            numRecords: restoredRecordIds.length,
            recordsRestoredTrigger: trigger,
            triggeredByKeyboard,
            triggeredByUi: triggeredByUI,
            recordIds: restoredRecordIds,
          })
        }
        return restoredRecordIds.length
      }
      case `SELECT_ALL_RECORDS`: {
        Analytics.recordSelected({
          activeScreen,
          recordSelectedTrigger: action.trigger,
          recordIds: null,
        })
        return selectAllRecords(visibleItemIds, selection.set)
      }
      case `RENAME_COLLECTION`: {
        return renameCollection(listId)
      }
      case `CONVERT_RECORD_TO_URL`: {
        return convertToUrlRecord(
          store.records,
          activeRecords(),
          activeScreen,
          commands.updateRecord,
          commands.updateRecordText,
          selection.setDefaultId,
          getUrlMetadata,
        )
      }
      case `CONVERT_RECORD_TO_TEXT`: {
        const targetRecords = activeRecords()
        const success = convertToTextRecord(
          store.records,
          targetRecords,
          commands.updateRecordText,
        )

        if (success) {
          Analytics.recordTypeChanged({
            recordType: Analytics.RecordType.TEXT,
            activeScreen,
            recordIds: targetRecords,
          })
        }
      }
      case `SET_COLLECTION_EMOJI`: {
        return setCollectionEmoji(listId)
      }
      case `TOGGLE_NEXT_FOURTEEN_DAYS`: {
        return toggleNextFourteenDays(toggleShowNextFourteenDays)
      }
      case `TOGGLE_SIDEBAR`: {
        Analytics.showSidebarToggled({
          activeScreen,
          showSidebarToggleTrigger: action.trigger,
          showSidebarToggleValue: !isLeftHidden,
        })
        return toggleSidebar(toggleIsLeftHidden)
      }
      case `GO_TO_INBOX`: {
        Analytics.screenNavigationStarted({
          activeScreen,
          screenType: Analytics.ScreenType.INBOX,
          screenNavigationStartedTrigger: action.trigger,
        })
        return goToInbox(navigate)
      }
      case `GO_TO_TODAY`: {
        Analytics.screenNavigationStarted({
          activeScreen,
          screenType: Analytics.ScreenType.TODAY,
          screenNavigationStartedTrigger: action.trigger,
        })
        return goToToday(navigate)
      }
      case `GO_TO_TRASH`: {
        Analytics.screenNavigationStarted({
          activeScreen,
          screenType: Analytics.ScreenType.TRASH,
          screenNavigationStartedTrigger: action.trigger,
        })
        return goToTrash(navigate)
      }
      case `NAVIGATE_TO`: {
        Analytics.screenNavigationStarted({
          activeScreen,
          screenType: getScreenNavigationStartedScreenTypeFromRouteType(
            routeType,
          ) as ScreenTypeValueType,
          screenNavigationStartedTrigger: action.trigger,
        })
        return navigateTo(navigateToList, action.destinationId)
      }
      case `LOGOUT`: {
        Analytics.logoutInitiated({ logoutTrigger: action.logoutTrigger })
        setErrorState(action.errorState ?? undefined)
        revokeSessionAndDeleteCookies()
        return
      }
      case `VIEW_JWT`: {
        return viewJWT(store.user.username)
      }
      case `SORT_BY_DATE`: {
        return sortByDate(
          routeType,
          listId,
          action.direction,
          commands.sortRecords,
        )
      }
      case `SORT_BY_LOCATION`: {
        return sortByLocation(routeType, action.direction, commands.sortRecords)
      }
      case `UPDATE_RECORD_TICKLER`: {
        const { numDaysUntilDate, dateIsEmpty } = getRecordsDatesSetEventProps(
          action.ticklerPayload.isoDate,
        )
        const result = updateRecordTickler(
          action.targetId,
          activeRecords(),
          action.ticklerPayload,
          commands.updateRecordDate,
        )
        Analytics.recordsDatesSet({
          numDaysUntilDate,
          numRecords: result.recordIds.length,
          activeScreen,
          dateIsEmpty: dateIsEmpty as boolean,
          recordIds: result.recordIds,
        })
        return result
      }
      case `SWITCH_TO_LIGHT_THEME`: {
        Analytics.themeNameToggled({
          themeNameToggleValue: themesConfig.StationaryLight.name,
          themeNameToggleTrigger: action.trigger,
        })
        return switchToTheme(
          themesConfig.StationaryLight.name,
          setSavedThemeName,
        )
      }
      case `SWITCH_TO_DARK_THEME`: {
        Analytics.themeNameToggled({
          themeNameToggleValue: themesConfig.StationaryDark.name,
          themeNameToggleTrigger: action.trigger,
        })
        return switchToTheme(
          themesConfig.StationaryDark.name,
          setSavedThemeName,
        )
      }
      case `SWITCH_TO_SYSTEM_THEME`: {
        Analytics.themeNameToggled({
          themeNameToggleValue: getAutoSystemThemeName(),
          themeNameToggleTrigger: action.trigger,
        })
        return switchToTheme(systemThemeName, setSavedThemeName)
      }
      case `PLACE_TAG_CLICK`: {
        return placeTagClick(action.tag, navigate, navigateToList)
      }
      case `SELECT_RECORD`: {
        return selectRecord(action.recordId, selection.set)
      }
      case `RECORD_FOCUSED`: {
        return recordFocused(
          store.user.id,
          action.recordId,
          setFocusedRecordId,
          selection.clear,
        )
      }
      case `RECORD_BLURRED`: {
        return recordBlurred(
          action.recordId,
          setFocusedRecordId,
          setRecentlyCreatedRecords,
          selection.setDefaultId,
          activeScreen,
        )
      }
      case `RECORD_ARROW_LEFT_OR_UP_AT_BOUNDARY`: {
        const prevRecord =
          first(visibleItemIds) === action.recordId
            ? undefined
            : store.records[
                visibleItemIds[visibleItemIds.indexOf(action.recordId) - 1]
              ]
        return recordArrowLeftOrUpAtBoundary(
          action.recordId,
          prevRecord,
          focusPrevRecord,
          shouldDisplayNotesForRecordId,
        )
      }
      case `RECORD_ARROW_RIGHT_OR_DOWN_AT_BOUNDARY`: {
        return recordArrowRightOrDownAtBoundary(
          action.recordId,
          visibleItemIds,
          store.records[action.recordId],
          focusNextRecord,
          shouldDisplayNotesForRecordId,
        )
      }
      case `RECORD_BACKSPACE_WHEN_EMPTY`: {
        const { hardDeleted, softDeleted } = recordBackspaceWhenEmpty(
          store.records,
          action.recordId,
          visibleItemIds,
          commands.deleteRecords,
          commands.softDeleteRecords,
          setFocusedRecordId,
        )
        if (hardDeleted) {
          Analytics.recordsDeleted({
            activeScreen,
            numRecords: 1,
            recordsDeletedTrigger:
              Analytics.RecordsDeletedTrigger.RECORDITEM_BACKSPACE,
            triggeredByKeyboard: true,
            triggeredByUi: false,
            recordIds: [action.recordId],
          })
        }
        if (softDeleted) {
          Analytics.recordsSoftDeleted({
            activeScreen,
            numRecords: 1,
            recordsSoftDeletedTrigger:
              Analytics.RecordsSoftDeletedTrigger.RECORDITEM_BACKSPACE,
            triggeredByKeyboard: true,
            triggeredByUi: false,
            recordIds: [action.recordId],
          })
        }
        // propagates down to editor to halt future handler processing
        return true
      }
      case `RECORD_BACKSPACE_AT_FIRST_POINT_WHEN_NOT_EMPTY`: {
        return recordBackspaceAtFirstPointWhenNotEmpty(
          store.records,
          visibleItemIds,
          action.recordId,
          commands.deleteRecords,
        )
      }
      case `RECORD_CHANGE`: {
        return recordChange(
          action.richText,
          action.recordId,
          commands.updateRecordText,
        )
      }
      case `RECORD_KEY_DOWN_ENTER`: {
        const { trigger } = action
        const result = recordKeyDownEnter(
          action.event,
          store.records,
          action.recordId,
          recordGroupCommands.createBelowAnchor,
          commands.updateRecordText,
          fullscreenRecordId,
          setRecentlyCreatedRecords,
        )

        if (!result) return false

        Analytics.recordCreated({
          destinationType: getDestinationTypeFromMoveToPlaceIndex(
            result.location,
          ),
          recordCreatedTrigger: trigger,
          recordType: getRecordType(RecordType.Text),
          recordId: result.id,
          activeScreen,
        })

        return true
      }
      case `RECORD_PASTE_FROM_CLIPBOARD`: {
        return recordPasteFromClipboard(
          action.event,
          store.records[action.recordId],
          commands.updateRecordText,
          convertNewRecordToUrlRecord,
          convertToFileRecord,
        )
      }
      case `RECORD_CLICKED`: {
        return recordClicked(store.records, action.recordId)
      }
      case `RECORD_MOUSE_DOWN`: {
        const affectedRecords = recordMouseDown(
          action.event,
          action.recordId,
          visibleItemIds,
          selection,
          selectionInteractions,
          focusedRecordId,
        )
        if (action.event.shiftKey || action.event.metaKey) {
          Analytics.recordSelected({
            activeScreen,
            recordSelectedTrigger: action.trigger,
            recordIds: affectedRecords,
          })
        }
        return
      }
      case 'RECORD_HANDLE_CLICKED': {
        const affectedRecords = recordHandleClicked(
          action.event,
          action.recordId,
          visibleItemIds,
          selection,
          selectionInteractions,
        )

        Analytics.recordSelected({
          activeScreen,
          recordSelectedTrigger:
            Analytics.RecordSelectedTrigger.DRAG_HANDLE_CLICK,
          recordIds: affectedRecords,
        })
        return
      }
      case 'RECORD_RENAME_FILE': {
        const { fileName, recordId } = action
        return recordRenameFile(
          store.records,
          recordId,
          fileName,
          commands.updateRecord,
        )
      }
      case 'RECORD_SKIP_OCCURRENCE': {
        const { recordId } = action
        return recordSkipOccurrence(recordId, commands.skipOccurrence)
      }
      case 'NOTE_ESCAPED': {
        return noteEscaped(action.recordId, selection.set)
      }
      case 'NOTE_FOCUSED': {
        const { noteId } = action
        return noteFocused(noteId, selection.clear, setFocusedRecordId)
      }
      case 'NOTE_BLURRED': {
        const { noteId } = action
        const parentId = action.parentId ?? getParentId(noteId, store.records)
        return noteBlurred(
          parentId,
          noteId,
          setFocusedRecordId,
          setRecentlyCreatedRecords,
          activeScreen,
        )
      }
      case 'NOTE_MOUSE_DOWN': {
        const { noteId, event } = action
        const parentId = action.parentId ?? getParentId(noteId, store.records)
        return noteMouseDown(
          event,
          parentId,
          visibleItemIds,
          selection,
          selectionInteractions,
          focusedRecordId,
        )
      }
      case 'NOTE_ARROW_LEFT_OR_UP_AT_BOUNDARY': {
        const parentId =
          action.parentId ?? getParentId(action.noteId, store.records)
        return noteArrowLeftOrUpAtBoundary(
          store.records,
          action.noteId,
          parentId,
        )
      }
      case `NOTE_ARROW_RIGHT_OR_DOWN_AT_BOUNDARY`: {
        const parentId =
          action.parentId ?? getParentId(action.noteId, store.records)
        return noteArrowRightOrDownAtBoundary(
          store.records,
          action.noteId,
          parentId,
          visibleItemIds,
        )
      }
      case 'NOTE_CHANGE': {
        return noteChange(action.richText, action.noteId, commands.updateNote)
      }
      case 'NOTE_KEY_DOWN_LOWER_THAN': {
        const parentId =
          action.parentId ?? getParentId(action.noteId, store.records)
        if (!parentId) return false
        return noteKeyDownLowerThan(
          action.event,
          store.records[parentId],
          action.noteId,
          closeAnnotations,
          store.records,
          activeRecords(parentId),
          selection.set,
          commands.deleteNotes,
          setNotesVisibilityForRecordIds,
        )
      }
      case 'CREATE_FILE_RECORD_AT_START_OF_GROUP': {
        const { files, groupId, index, trigger } = action

        return createRecord({
          isFileRecord: true,
          recordGroupsCommands: recordGroupCommands,
          location: {
            groupId,
            index,
          },
          setRecentlyCreatedRecords,
          positionArgs: undefined,
          createFileRecordExtras: {
            acceptedFiles: files,
            updateRecord: commands.updateRecord,
            deleteRecords: commands.deleteRecords,
            setSelectionDefaultId: selection.setDefaultId,
            uploadFile,
          },
        }).then((result) => {
          if (result) {
            Analytics.recordCreated({
              destinationType: getDestinationTypeFromMoveToPlaceIndex(
                result.location,
              ),
              recordCreatedTrigger: trigger,
              recordType: getRecordType(RecordType.File),
              recordId: result.id,
              activeScreen,
            })
          }

          return result
        })
      }
      case 'URL_RECORD_OPEN_SRC_IN_TAB': {
        const { recordId } = action
        return urlRecordOpenSrcInTab(store.records, recordId)
      }
      case 'FILE_RECORD_OPEN_SRC_IN_TAB': {
        const { recordId } = action
        return fileRecordOpenSrcInTab(store.records, recordId)
      }
      case 'RECORD_ITEMS_TOGGLE_IS_EXPANDED': {
        const activeIds = activeRecords()
        const recordIds =
          action.targetId !== undefined && !activeIds.includes(action.targetId)
            ? [action.targetId]
            : activeIds
        const { recordsExpanded, recordsCollapsed } =
          toggleRecordItemsIsExpanded(recordIds)

        if (isEmpty(recordsExpanded)) {
          Analytics.recordExpanded({
            activeScreen,
            numRecords: recordIds.length,
            recordExpandedTrigger:
              action.trigger as Analytics.RecordExpandedTriggerValueType,
            recordIds: recordsExpanded,
          })
        } else {
          Analytics.recordCollapsed({
            activeScreen,
            numRecords: recordIds.length,
            recordIds: recordsCollapsed,
            recordCollapsedTrigger:
              action.trigger as Analytics.RecordCollapsedTriggerValueType,
          })
        }
        return
      }
      case 'CREATE_RECORD_INSERT_BELOW': {
        const { trigger } = action

        return createRecord({
          recordGroupsCommands: recordGroupCommands,
          location: {
            position: CreateRecordPosition.BELOW_CURRENT,
          },
          setRecentlyCreatedRecords,
          positionArgs: {
            selection,
          },
        }).then((result) => {
          if (result) {
            Analytics.recordCreated({
              destinationType: getDestinationTypeFromMoveToPlaceIndex(
                result.location,
              ),
              recordCreatedTrigger: trigger,
              recordType: getRecordType(result.type),
              recordId: result.id,
              activeScreen,
            })
          }

          return result
        })
      }
      case 'RECORDS_DELETE_NOTES': {
        const deletedNoteIds = deleteNotes(
          selection,
          selectedRecords,
          commands.deleteNotes,
        )
        Analytics.annotationDeleted({
          numRecords: deletedNoteIds.length,
          annotationDeletedTrigger: action.trigger,
          activeScreen,
          recordIds: deletedNoteIds,
        })
        return
      }
      case 'RECORDS_MARK_DONE': {
        const recordIds = activeRecords()
        commands.completeRecords(recordIds)
        Analytics.recordsCompleted({
          activeScreen,
          numRecords: recordIds.length,
          toggleRecordsCompletedTrigger: action.trigger,
          recordIds,
        })
        return
      }
      case 'RECORDS_MARK_NOT_DONE': {
        const recordIds = activeRecords()
        commands.uncompleteRecords(recordIds)
        Analytics.recordsUncompleted({
          activeScreen,
          numRecords: recordIds.length,
          toggleRecordsCompletedTrigger: action.trigger,
          recordIds,
        })
        return
      }
      case 'RECORDS_REMOVE_TICKLER': {
        const recordIds = activeRecords()
        for (const id of recordIds) {
          commands.updateRecordDate(id, { isoDate: null, isoLocalTime: null })
        }
        return
      }
      case 'RECORD_ITEMS_HIDE_NOTES': {
        const { trigger } = action
        const recordIds = activeRecords()
        Analytics.showAnnotationsToggled({
          activeScreen,
          showAnnotationsToggleTrigger: trigger,
          numRecords: recordIds.length,
        })
        closeAnnotations(
          store.records,
          recordIds,
          setNotesVisibilityForRecordIds,
        )
        return
      }
      case 'RECORD_ITEMS_SHOW_NOTES': {
        const recordIds = activeRecords()
        const { notesOpened, notesCreated, newNoteId } = openAnnotations(
          store.records,
          recordIds,
          shouldDisplayNotesForRecordId,
          _addNoteAtBottomOfList,
          setNotesVisibilityForRecordIds,
          action.triggerShowAnnotation,
          action.triggerCreatedAnnotation,
          setRecentlyCreatedRecords,
        )

        if (notesOpened) {
          Analytics.showAnnotationsToggled({
            activeScreen,
            numRecords: notesOpened,
            showAnnotationsToggleTrigger: action.triggerShowAnnotation,
          })
        } else if (notesCreated && newNoteId) {
          Analytics.annotationCreated({
            activeScreen,
            annotationCreatedTrigger: action.triggerCreatedAnnotation,
            recordId: newNoteId,
          })
        }

        return
      }
      case `REMOVE_ANNOTATION`: {
        const _activeRecords = activeRecords().map((id) => store.records[id])
        const parentId = getParentId(action.noteId, store.records)
        const deletedNoteId = removeNote(
          _activeRecords,
          action.noteId,
          parentId,
          selection,
          commands.deleteNotes,
        )
        if (deletedNoteId) {
          Analytics.annotationDeleted({
            activeScreen,
            annotationDeletedTrigger: action.trigger,
            numRecords: 1,
            recordIds: [deletedNoteId],
          })
        }
      }
      case `REMOVE_DATE`: {
        // TODO: Add Analytics
        return removeDate(activeRecords(), commands.updateRecordDate)
      }
      case 'CREATE_FILE_RECORD_AT_FIRST_POSITION': {
        const { files, trigger } = action

        return createRecord({
          isFileRecord: true,
          recordGroupsCommands: recordGroupCommands,
          location: {
            position: CreateRecordPosition.FIRST_POSITION,
          },
          setRecentlyCreatedRecords,
          positionArgs: undefined,
          createFileRecordExtras: {
            acceptedFiles: files,
            updateRecord: commands.updateRecord,
            deleteRecords: commands.deleteRecords,
            setSelectionDefaultId: selection.setDefaultId,
            uploadFile,
          },
        }).then((result) => {
          if (result) {
            Analytics.recordCreated({
              destinationType: getDestinationTypeFromMoveToPlaceIndex(
                result.location,
              ),
              recordCreatedTrigger: trigger,
              recordType: getRecordType(RecordType.File),
              recordId: result.id,
              activeScreen,
            })
          }

          return result
        })
      }
      case 'CREATE_RECORD_AT_FIRST_POSITION': {
        const {
          trigger,
          targetType,
          openRecordInFullScreen,
          richText,
          focusNewRecord,
        } = action

        return createRecord({
          recordGroupsCommands: recordGroupCommands,
          location: {
            position: CreateRecordPosition.FIRST_POSITION,
          },
          setRecentlyCreatedRecords,
          positionArgs: {
            targetType: targetType ?? RecordItemTargetType.Text,
            openRecordInFullScreen,
            currentRoute: location.pathname,
            setLastRoute,
            currentSelection: selection.ids,
            setLastSelection,
            setLastDefaultId,
            navigate: navigateToDetails,
            richText,
            focusNewRecord,
          },
        }).then((result) => {
          if (result) {
            Analytics.recordCreated({
              destinationType: getDestinationTypeFromMoveToPlaceIndex(
                result.location,
              ),
              recordCreatedTrigger: trigger,
              recordType: getRecordType(result.type),
              recordId: result.id,
              activeScreen,
            })
          }
          return result
        })
      }
      case 'TOGGLE_KEYBOARD_SHORTCUTS_PANE': {
        return toggleKeyboardShortcutsPane(toggleIsRightHidden)
      }
      case 'ONBOARDING_SUBMIT_QUICK_CAPTURE': {
        return onboardingSubmitQuickCapture(commands.createRecord, action.text)
      }
      default:
        console.error({ action })
        throw new Error(`Unexpected action type: ${JSON.stringify(action)}`)
    }
  }

  return execDefaultAction
}

// Describe the set of Actions

type OnboardingSubmitQuickCaptureActionDef = {
  type: 'ONBOARDING_SUBMIT_QUICK_CAPTURE'
  payload: {
    text: string
  }
  returnType: void
}

type RecordsMarkDoneActionDef = {
  type: 'RECORDS_MARK_DONE'
  payload: {
    trigger: Analytics.ToggleRecordsCompletedTriggerValueType
  }
  returnType: void
}

type RecordsMarkNotDoneActionDef = {
  type: 'RECORDS_MARK_NOT_DONE'
  payload: {
    trigger: Analytics.ToggleRecordsCompletedTriggerValueType
  }
  returnType: void
}

type RecordItemsShowNotesActionDef = {
  type: 'RECORD_ITEMS_SHOW_NOTES'
  payload: {
    triggerCreatedAnnotation: Analytics.AnnotationCreatedTriggerValueType
    triggerShowAnnotation: Analytics.ShowAnnotationsToggleTriggerValueType
  }
  returnType: void
}

type RecordItemsHideNotesActionDef = {
  type: 'RECORD_ITEMS_HIDE_NOTES'
  payload: {
    trigger: Analytics.ShowAnnotationsToggleTriggerValueType
  }
  returnType: void
}

type RecordsDeleteNotesActionDef = {
  type: 'RECORDS_DELETE_NOTES'
  payload: {
    trigger: Analytics.AnnotationDeletedTriggerValueType
  }
  returnType: void
}

type RecordsRemoveTicklerDef = {
  type: 'RECORDS_REMOVE_TICKLER'
  payload: {}
  returnType: void
}

type AddAnnotationActionDef = {
  type: 'ADD_ANNOTATION'
  payload: {
    recordId?: string
    showAnnotationsTrigger: Analytics.ShowAnnotationsToggleTriggerValueType
    createAnnotationTrigger: Analytics.AnnotationCreatedTriggerValueType
  }
  returnType: void
}

type RecordItemsCollapseActionDef = {
  type: `RECORD_ITEMS_COLLAPSE`
  payload: {
    targetId?: string
    trigger: Analytics.RecordCollapsedTriggerValueType
  }
  returnType: void
}

type CopyActionDef = {
  type: 'COPY'
  payload: {}
  returnType: void
}

type ExitFullscreenActionDef = {
  type: 'EXIT_FULLSCREEN'
  payload: {}
  returnType: void
}

type RecordItemsExpandActionDef = {
  type: `RECORD_ITEMS_EXPAND`
  payload: {
    targetId?: string
    trigger: Analytics.RecordExpandedTriggerValueType
  }
  returnType: void
}

type RecordItemsToggleIsExpandActionDef = {
  type: `RECORD_ITEMS_TOGGLE_IS_EXPANDED`
  payload: {
    targetId?: string
    trigger:
      | Analytics.RecordExpandedTriggerValueType
      | Analytics.RecordCollapsedTriggerValueType
  }
  returnType: void
}

type ExpandToFullscreenActionDef = {
  type: 'EXPAND_TO_FULLSCREEN'
  payload: {
    recordId?: string
    trigger: Analytics.RecordOpenedTriggerValueType
    triggeredByKeyboard: boolean
    triggeredByUI: boolean
  }
  returnType: void
}

type MoveRecordsActionDef = {
  type: 'MOVE_RECORDS'
  payload: {
    groupId?: string
    insertPoint?: ReferenceInsertPoint
    parentRecordId: string
    recordIds: string[]
    trigger: Analytics.RecordsMovedTriggerValueType
  }
  returnType: MoveRecordsResult
}

type RemoveAnnotationActionDef = {
  type: 'REMOVE_ANNOTATION'
  payload: {
    noteId?: string
    trigger: Analytics.AnnotationDeletedTriggerValueType
  }
  returnType: void
}

type RemoveDateActionDef = {
  type: 'REMOVE_DATE'
  payload: {}
  returnType: void
}

type RemoveListActionDef = {
  type: 'REMOVE_LIST'
  payload: { listId?: string }
  returnType: void
}

type ToggleAnnotationsActionDef = {
  type: 'TOGGLE_ANNOTATIONS'
  payload: {
    recordId?: string
    showAnnotationsTrigger: Analytics.ShowAnnotationsToggleTriggerValueType
    createAnnotationTrigger: Analytics.AnnotationCreatedTriggerValueType
  }
  returnType: void
}

export type ToggleRecordsIsCompleted =
  | {
      succeeded: true
      payload: {
        setToValue: boolean
        count: number
      }
    }
  | { succeeded: false }

type ToggleRecordsIsCompletedActionDef = {
  type: 'RECORDS_TOGGLE_IS_COMPLETED'
  payload: {
    targetId?: string
    trigger: Analytics.ToggleRecordsCompletedTriggerValueType
  }
  returnType: boolean
}

type ToggleShowCompletedActionDef = {
  type: 'TOGGLE_SHOW_COMPLETED'
  payload: {
    trigger: Analytics.ShowCompletedToggleTriggerValueType
  }
  returnType: boolean
}

type FocusFileTitleInputActionDef = {
  type: 'FOCUS_FILE_TITLE_INPUT'
  payload: {}
  returnType: void
}

type CreateCollectionActionDef = {
  type: 'CREATE_COLLECTION'
  payload: {
    trigger: Analytics.ListCreatedTriggerValueType
  }
  returnType: string
}

type MoveRecordsToTrashActionDef = {
  type: 'MOVE_RECORDS_TO_TRASH'
  payload: {
    recordId?: string
    trigger:
      | `Keyboard Backspace`
      | `Command Bar`
      | `Record Mini Bar`
      | 'Selection Action Bar'
    triggeredByKeyboard: boolean
    triggeredByUI: boolean
  }
  returnType: {
    numDeleted: number
    numSoftDeleted: number
  }
}

type DeleteRecordsActionDef = {
  type: 'DELETE_RECORDS'
  payload: {
    recordIds?: string[]
    trigger: Analytics.RecordsDeletedTriggerValueType
    triggeredByKeyboard: boolean
    triggeredByUI: boolean
  }
  returnType: number
}

type EmptyTrashActionDef = {
  type: 'EMPTY_TRASH'
  payload: {
    trigger: Analytics.EmptyTrashTriggerValueType
    triggeredByKeyboard: boolean
    triggeredByUI: boolean
  }
  returnType: number
}

type RestoreRecordsActionDef = {
  type: 'RESTORE_RECORDS'
  payload: {
    recordId?: string
    trigger: Analytics.RecordsRestoredTriggerValueType
    triggeredByKeyboard: boolean
    triggeredByUI: boolean
  }
  returnType: number
}

type SelectAllRecordsActionDef = {
  type: 'SELECT_ALL_RECORDS'
  payload: {
    trigger: Analytics.RecordSelectedTriggerValueType
  }
  returnType: void
}

type RenameCollectionActionDef = {
  type: 'RENAME_COLLECTION'
  payload: {}
  returnType: void
}

export type ConvertRecordToURLReturnValue = {
  allSuccess: boolean
  convertedIds: string[]
}
type ConvertRecordToURLActionDef = {
  type: 'CONVERT_RECORD_TO_URL'
  payload: {}
  returnType: Promise<ConvertRecordToURLReturnValue>
}
type ConvertRecordToTextActionDef = {
  type: 'CONVERT_RECORD_TO_TEXT'
  payload: {}
  returnType: void
}

type SetCollectionEmojiActionDef = {
  type: 'SET_COLLECTION_EMOJI'
  payload: {}
  returnType: void
}

type ToggleNextFourteenDaysActionDef = {
  type: 'TOGGLE_NEXT_FOURTEEN_DAYS'
  payload: {}
  returnType: void
}

type ToggleSidebarActionDef = {
  type: 'TOGGLE_SIDEBAR'
  payload: {
    trigger: Analytics.ShowSidebarToggleTriggerValueType
  }
  returnType: void
}

type GoToInboxActionDef = {
  type: 'GO_TO_INBOX'
  payload: {
    trigger: Analytics.ScreenNavigationStartedTriggerValueType
  }
  returnType: void
}

type GoToTodayActionDef = {
  type: 'GO_TO_TODAY'
  payload: {
    trigger: Analytics.ScreenNavigationStartedTriggerValueType
  }
  returnType: void
}

type GoToTrashActionDef = {
  type: 'GO_TO_TRASH'
  payload: {
    trigger: Analytics.ScreenNavigationStartedTriggerValueType
  }
  returnType: void
}

type NavigateToActionDef = {
  type: 'NAVIGATE_TO'
  payload: {
    destinationId: string
    trigger: Analytics.ScreenNavigationStartedTriggerValueType
  }
  returnType: void
}

type LogoutActionDef = {
  type: 'LOGOUT'
  payload: {
    logoutTrigger: Analytics.LogoutTriggerValueType
    errorState?: null
  }
  returnType: void
}

type ViewJWTActionDef = {
  type: 'VIEW_JWT'
  payload: {}
  returnType: void
}

type SortByDateActionDef = {
  type: 'SORT_BY_DATE'
  payload: { direction: SortDirection }
  returnType: void
}

type SortByLocationActionDef = {
  type: 'SORT_BY_LOCATION'
  payload: { direction: SortDirection }
  returnType: void
}

type UpdateRecordTicklerActionDef = {
  type: 'UPDATE_RECORD_TICKLER'
  payload: {
    targetId?: string | undefined
    ticklerPayload: UpdateTicklerPayload
  }
  returnType: { recordIds: string[] }
}

type SwitchToLightThemeActionDef = {
  type: 'SWITCH_TO_LIGHT_THEME'
  payload: {
    trigger: Analytics.ThemeNameToggleTriggerValueType
  }
  returnType: void
}

type SwitchToDarkThemeActionDef = {
  type: 'SWITCH_TO_DARK_THEME'
  payload: {
    trigger: Analytics.ThemeNameToggleTriggerValueType
  }
  returnType: void
}

type SwitchToSystemThemeActionDef = {
  type: 'SWITCH_TO_SYSTEM_THEME'
  payload: {
    trigger: Analytics.ThemeNameToggleTriggerValueType
  }
  returnType: void
}

type PlaceTagClickActionDef = {
  type: 'PLACE_TAG_CLICK'
  payload: {
    tag: PlaceTag
  }
  returnType: void
}

interface BaseRecordActionPayload {
  recordId: string
}

type SelectRecordActionDef = {
  type: 'SELECT_RECORD'
  payload: BaseRecordActionPayload
  returnType: void
}

type RecordFocusedActionDef = {
  type: 'RECORD_FOCUSED'
  payload: BaseRecordActionPayload
  returnType: void
}

type RecordBlurredActionDef = {
  type: 'RECORD_BLURRED'
  payload: BaseRecordActionPayload
  returnType: void
}

type RecordArrowLeftOrUpAtBoundaryActionDef = {
  type: 'RECORD_ARROW_LEFT_OR_UP_AT_BOUNDARY'
  payload: BaseRecordActionPayload
  returnType: boolean
}

type RecordArrowRightOrDownAtBoundaryActionDef = {
  type: 'RECORD_ARROW_RIGHT_OR_DOWN_AT_BOUNDARY'
  payload: BaseRecordActionPayload
  returnType: boolean
}

type RecordBackspaceWhenEmptyActionDef = {
  type: 'RECORD_BACKSPACE_WHEN_EMPTY'
  payload: BaseRecordActionPayload
  returnType: boolean
}

type RecordBackspaceAtFirstPointWhenNotEmptyActionDef = {
  type: 'RECORD_BACKSPACE_AT_FIRST_POINT_WHEN_NOT_EMPTY'
  payload: BaseRecordActionPayload
  returnType: void
}

type RecordChangeActionDef = {
  type: 'RECORD_CHANGE'
  payload: RecordChangePayload
  returnType: void
}

type RecordChangePayload = BaseRecordActionPayload & {
  richText: RichText
}

type RecordKeyDownEnterActionDef = {
  type: 'RECORD_KEY_DOWN_ENTER'
  payload: RecordKeyDownEnterPayload
  returnType: boolean
}

type RecordKeyDownEnterPayload = BaseRecordActionPayload & {
  event: React.KeyboardEvent<HTMLDivElement>
  trigger: Analytics.RecordCreatedTriggerValueType
}

type RecordPasteFromClipboardActionDef = {
  type: 'RECORD_PASTE_FROM_CLIPBOARD'
  payload: RecordPasteFromClipboardPayload
  returnType: boolean
}

type RecordPasteFromClipboardPayload = BaseRecordActionPayload & {
  event: React.ClipboardEvent<HTMLDivElement>
}

type RecordClickedActionDef = {
  type: 'RECORD_CLICKED'
  payload: BaseRecordActionPayload
  returnType: boolean
}

type RecordMouseDownActionDef = {
  type: 'RECORD_MOUSE_DOWN'
  payload: BaseRecordActionPayload & {
    event: React.MouseEvent<Element, MouseEvent>
    trigger: Analytics.RecordSelectedTriggerValueType
  }
  returnType: boolean
}

type RecordHandleClickedActionDef = {
  type: 'RECORD_HANDLE_CLICKED'
  payload: BaseRecordActionPayload & {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  }
  returnType: boolean
}

type RecordRenameFileActionDef = {
  type: 'RECORD_RENAME_FILE'
  payload: BaseRecordActionPayload & {
    fileName: string
  }
  returnType: void
}

type RecordSkipOccurrenceActionDef = {
  type: 'RECORD_SKIP_OCCURRENCE'
  payload: BaseRecordActionPayload
  returnType: void
}

type NoteEscapedActionDef = {
  type: 'NOTE_ESCAPED'
  payload: {
    recordId: string
  }
  returnType: boolean
}

type NoteFocusedActionDef = {
  type: 'NOTE_FOCUSED'
  payload: BaseNoteActionPayload
  returnType: boolean
}

type NoteBlurredActionDef = {
  type: 'NOTE_BLURRED'
  payload: BaseNoteActionPayload
  returnType: boolean
}

type NoteMouseDownActionDef = {
  type: 'NOTE_MOUSE_DOWN'
  payload: BaseNoteActionPayload & {
    event: React.MouseEvent<Element, MouseEvent>
  }
  returnType: boolean
}

type NoteArrowLeftOrUpAtBoundaryActionDef = {
  type: 'NOTE_ARROW_LEFT_OR_UP_AT_BOUNDARY'
  payload: BaseNoteActionPayload
  returnType: boolean
}

type NoteArrowRightOrDownAtBoundaryActionDef = {
  type: 'NOTE_ARROW_RIGHT_OR_DOWN_AT_BOUNDARY'
  payload: BaseNoteActionPayload
  returnType: boolean
}

type NoteChangeActionDef = {
  type: 'NOTE_CHANGE'
  payload: BaseNoteActionPayload & {
    richText: RichText
  }
  returnType: boolean
}

type NoteKeyDownLowerThanActionDef = {
  type: 'NOTE_KEY_DOWN_LOWER_THAN'
  payload: BaseNoteActionPayload & {
    event: React.KeyboardEvent<HTMLDivElement>
  }
  returnType: boolean
}

type UrlRecordOpenSrcInTabActionDef = {
  type: 'URL_RECORD_OPEN_SRC_IN_TAB'
  payload: BaseRecordActionPayload
  returnType: void
}

type FileRecordOpenSrcInTabActionDef = {
  type: 'FILE_RECORD_OPEN_SRC_IN_TAB'
  payload: BaseRecordActionPayload
  returnType: void
}

interface BaseNoteActionPayload {
  noteId: string
  parentId?: string
}

type CreateFileRecordAtStartOfGroupActionDef = {
  type: 'CREATE_FILE_RECORD_AT_START_OF_GROUP'
  payload: {
    files: File[]
    groupId: string | undefined
    index: number | undefined
    trigger: Analytics.RecordCreatedTriggerValueType
  }
  returnType: CreateRecordResult
}

type CreateRecordAtStartOfGroupActionDef = {
  type: 'CREATE_RECORD_AT_START_OF_GROUP'
  payload: {
    groupId?: string
    openRecordInFullScreen?: boolean
    recordId: string
    targetType:
      | RecordItemTargetType.Text
      | RecordItemTargetType.Bookmark
      | RecordItemTargetType.Page
    trigger: Analytics.RecordCreatedTriggerValueType
    triggeredByKeyboard: boolean
    triggeredByUI: boolean
    richText?: RichText
    focusNewRecord?: boolean
  }
  returnType: Promise<CreateRecordResult>
}

type CreateRecordInsertBelowActionDef = {
  type: 'CREATE_RECORD_INSERT_BELOW'
  payload: {
    trigger: Analytics.RecordCreatedTriggerValueType
  }
  returnType: CreateRecordResult
}

type CreateFileRecordAtFirstPositionActionDef = {
  type: 'CREATE_FILE_RECORD_AT_FIRST_POSITION'
  payload: {
    files: File[]
    trigger: Analytics.RecordCreatedTriggerValueType
  }
  returnType: CreateRecordResult
}

type CreateRecordAtFirstPositionActionDef = {
  type: 'CREATE_RECORD_AT_FIRST_POSITION'
  payload: {
    recordId?: string
    openRecordInFullScreen?: boolean
    targetType?:
      | RecordItemTargetType.Text
      | RecordItemTargetType.Bookmark
      | RecordItemTargetType.Page
    richText?: RichText
    focusNewRecord?: boolean
    trigger: Analytics.RecordCreatedTriggerValueType
  }
  returnType: Promise<CreateRecordResult>
}

type OpenKeyboardShortcutsPane = {
  type: 'TOGGLE_KEYBOARD_SHORTCUTS_PANE'
  payload: {}
  returnType: void
}

export type DefaultActionDefinitions =
  | AddAnnotationActionDef
  | CopyActionDef
  | ExitFullscreenActionDef
  | ExpandToFullscreenActionDef
  | MoveRecordsActionDef
  | RemoveAnnotationActionDef
  | RemoveDateActionDef
  | RemoveListActionDef
  | ToggleAnnotationsActionDef
  | ToggleRecordsIsCompletedActionDef
  | ToggleShowCompletedActionDef
  | FocusFileTitleInputActionDef
  | CreateRecordAtStartOfGroupActionDef
  | CreateCollectionActionDef
  | MoveRecordsToTrashActionDef
  | DeleteRecordsActionDef
  | EmptyTrashActionDef
  | RestoreRecordsActionDef
  | SelectAllRecordsActionDef
  | RenameCollectionActionDef
  | ConvertRecordToURLActionDef
  | ConvertRecordToTextActionDef
  | SetCollectionEmojiActionDef
  | ToggleNextFourteenDaysActionDef
  | ToggleSidebarActionDef
  | GoToInboxActionDef
  | GoToTodayActionDef
  | GoToTrashActionDef
  | NavigateToActionDef
  | LogoutActionDef
  | ViewJWTActionDef
  | SortByDateActionDef
  | SortByLocationActionDef
  | UpdateRecordTicklerActionDef
  | SwitchToLightThemeActionDef
  | SwitchToDarkThemeActionDef
  | SwitchToSystemThemeActionDef
  | PlaceTagClickActionDef
  | SelectRecordActionDef
  | RecordFocusedActionDef
  | RecordBlurredActionDef
  | RecordArrowLeftOrUpAtBoundaryActionDef
  | RecordArrowRightOrDownAtBoundaryActionDef
  | RecordBackspaceWhenEmptyActionDef
  | RecordBackspaceAtFirstPointWhenNotEmptyActionDef
  | RecordChangeActionDef
  | RecordKeyDownEnterActionDef
  | RecordPasteFromClipboardActionDef
  | RecordClickedActionDef
  | RecordMouseDownActionDef
  | RecordHandleClickedActionDef
  | RecordRenameFileActionDef
  | RecordSkipOccurrenceActionDef
  | NoteEscapedActionDef
  | NoteFocusedActionDef
  | NoteBlurredActionDef
  | NoteMouseDownActionDef
  | NoteArrowLeftOrUpAtBoundaryActionDef
  | NoteArrowRightOrDownAtBoundaryActionDef
  | NoteChangeActionDef
  | NoteKeyDownLowerThanActionDef
  | CreateFileRecordAtStartOfGroupActionDef
  | UrlRecordOpenSrcInTabActionDef
  | FileRecordOpenSrcInTabActionDef
  | CreateRecordInsertBelowActionDef
  | RecordItemsCollapseActionDef
  | RecordItemsExpandActionDef
  | RecordItemsToggleIsExpandActionDef
  | RecordsMarkDoneActionDef
  | RecordsMarkNotDoneActionDef
  | RecordItemsShowNotesActionDef
  | RecordItemsHideNotesActionDef
  | RecordsDeleteNotesActionDef
  | RecordsRemoveTicklerDef
  | CreateRecordAtFirstPositionActionDef
  | OpenKeyboardShortcutsPane
  | CreateFileRecordAtFirstPositionActionDef
  | OnboardingSubmitQuickCaptureActionDef
