import React, { useCallback, useRef } from 'react'

import { useHotkeysThreshold } from '../../common/hooks/useHotkeysThreshold'
import { Key, Priorities, useKeys } from '../../common/hooks/useKeys'
import { UseSendToDestinationData } from '../../common/hooks/useSendToDestinations'
import MoveTo from '../MoveTo/MoveTo'
import { useMoveActionHandlers } from '../MoveTo/hooks/useMoveActionHandlers'
import { styled } from '../../common/stationary'
import { Modal } from '../../common/dialogs'

interface MoveToDialogProps {
  isMouseRecentlyActive: boolean
  selectedRecordIds: string[]
  sendToDestinationData: UseSendToDestinationData
  isOpen?: boolean
  onDismiss: (e?: React.SyntheticEvent) => void
}

export const MoveToDialog: React.FC<MoveToDialogProps> = ({
  isOpen,
  isMouseRecentlyActive,
  onDismiss,
  selectedRecordIds,
  sendToDestinationData,
}) => {
  const MoveToInputRef = useRef<HTMLInputElement>(null)
  const close = useCallback(() => onDismiss && onDismiss(), [onDismiss])

  // Hack: we need to register this here, because @reach/dialog unmounts the internal component on Esc
  // before it gets captured and executed, so DatePicker Esc capture never executes and does not prevent
  // lower priorities to run (send to destination is not affected as it focuses the input)
  useKeys(Key.Esc, close, Priorities.Dialog, true)
  useHotkeysThreshold(Priorities.Dialog)

  const {
    inputValue: moveInputValue,
    items,
    isSelectedAddNewCollection,
    handleResultClick,
    sendToNewDestination,
    onHover,
    ...moveToHandlers
  } = useMoveActionHandlers(sendToDestinationData, selectedRecordIds, close)

  return (
    <S.MoveToDialog
      aria-label="MoveToDialog"
      open={isOpen ?? false}
      // initialFocusRef={MoveToInputRef} // we use @reach Dialog underneath and if we let it autofocus it will focus on the input and expand the datepicker
      onClose={close}
    >
      <S.Inner>
        <MoveTo
          inputRef={MoveToInputRef}
          selectedRecordIds={selectedRecordIds}
          isSelectedAddNewCollection={isSelectedAddNewCollection}
          isMouseRecentlyActive={isMouseRecentlyActive}
          inputValue={moveInputValue}
          items={items}
          handleResultClick={handleResultClick}
          onItemHover={onHover}
          {...moveToHandlers}
        />
      </S.Inner>
    </S.MoveToDialog>
  )
}

export default MoveToDialog

const S = {
  MoveToDialog: styled(Modal)({
    maxWidth: 448,
  }),
  Inner: styled.div({
    marginTop: 96,
    maxWidth: '100%',
  }),
}
