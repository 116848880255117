import { ExecAction } from '../../useExec'
import Analytics from '../../analytics/capture-analytics-actions'
import { EditorsManager } from '../../EditorsManager'
import { scrollAction } from '../../events'
import { RecordItemTargetType } from '../../recordItem/RecordItemTargetType'
import { immediateExecute, isVisibleKey } from '../../utils'
import { KeyboardEvent } from '../types'

export const onKeyDown = (
  e: KeyboardEvent,
  exec: ExecAction,
  recordId: string,
  setRecentlyCreatedRecords: React.Dispatch<
    React.SetStateAction<Record<string, RecordItemTargetType>>
  >,
) => {
  // Only scroll to current position if the user is typing
  if (isVisibleKey(e)) {
    immediateExecute(() => keepSelectionVisible(recordId))
  }
  if (e.key === `/`) {
    if (onKeyDownSlash(e, exec)) return true
  }
  if (e.key === `k`) {
    if (onKeyDownK(e, exec, recordId)) return true
  }
  setRecentlyCreatedRecords(
    (recentlyCreatedRecords: Record<string, RecordItemTargetType>) => {
      delete recentlyCreatedRecords[recordId]
      return recentlyCreatedRecords
    },
  )
  return false
}

const onKeyDownSlash = (e: KeyboardEvent, exec: ExecAction) => {
  if (e.metaKey) {
    e.preventDefault()
    exec({
      type: 'TOGGLE_SIDEBAR',
      trigger: Analytics.ShowSidebarToggleTrigger.KEYBOARD_COMMAND_BACKSLASH,
    })
    return true
  }
  return false
}

const onKeyDownK = (e: KeyboardEvent, exec: ExecAction, recordId: string) => {
  if (e.metaKey) {
    EditorsManager.effects.blur(recordId)
    exec({ type: 'SELECT_RECORD', recordId })
    exec({
      type: 'OPEN_COMMAND_BAR',
      trigger: Analytics.CommandBarFlowStartedTrigger.KEYBOARD_CMDK,
    })
    return true
  }
  return false
}

const keepSelectionVisible = (recordId: string) => {
  const caretBounds = getCaretCoordinates()
  if (!caretBounds) return
  scrollAction({
    target: `record`,
    to: { id: recordId },
    options: {
      offsetBottom: 30,
      elementBounds: caretBounds,
    },
  })
}

function getCaretCoordinates(): DOMRect | undefined {
  const isSupported = typeof window.getSelection !== `undefined`
  if (isSupported) {
    const selection = window.getSelection()
    if (selection && selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0).cloneRange()
      range.collapse(true)
      const rect = range.getClientRects()[0]
      return rect
    }
  }
}
