import React, { useCallback, useEffect } from 'react'

import {
  subscribeToFocusEvent,
  subscribeToTriggerEvent,
} from '../../../common/events'
import { useFocus } from '../../../common/hooks/useFocus'
import { titleOrDefault } from '../../../common/records'
import {
  RouteType,
  SystemListId,
  SystemListsRouteType,
  getMetadataForRoute,
  isSystemListRoute,
} from '../../../common/routes'
import { Icon, cx, styled, useTheme } from '../../../common/stationary'
import { emptyFn } from '../../../common/utils'
import EmojiDialog from '../../../components/EmojiDialog'
import { RouteIcon } from '../../../components/RouteIcon'
import { useCollectionHandlers } from '../../hooks/useCollectionHandlers'

export interface ListHeaderProps {
  title: string
  emoji?: string
  id: string
}

const ListHeader: React.FC<ListHeaderProps> = ({
  title,
  emoji,
  id,
}: ListHeaderProps) => {
  const {
    htmlRef: textRef,
    focus: focusText,
    blur: blurText,
  } = useFocus<HTMLInputElement>()

  const {
    inputId,
    editing,
    setEditing,
    inputValue,
    onItemClick,
    onChange,
    onBlur,
    onKeyDown,
    openEmojiPicker,
    closeEmojiPicker,
    isEmojiPickerOpen,
    onSelectEmoji,
  } = useCollectionHandlers(title, id, blurText, true)

  const renderer = useCallback(
    () =>
      isEmojiPickerOpen ? (
        <EmojiDialog
          isOpen={isEmojiPickerOpen}
          onDismiss={closeEmojiPicker}
          onSelectEmoji={onSelectEmoji}
          emoji={emoji}
        />
      ) : null,
    [emoji, isEmojiPickerOpen, closeEmojiPicker, onSelectEmoji],
  )

  const stopPropagationClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      event.stopPropagation(),
    [],
  )

  useEffect(() => {
    return subscribeToFocusEvent(({ payload }) => {
      if (payload.id === inputId) {
        if (editing && textRef.current) {
          focusText()
          return true
        } else {
          setEditing(true)
        }
      }
      return false
    })
  }, [textRef, inputId, editing, focusText, setEditing])

  useEffect(() => {
    return subscribeToTriggerEvent(({ payload }) => {
      if (payload.target === `open-list-emoji-picker`) {
        openEmojiPicker()
        return true
      }
      return false
    })
  }, [openEmojiPicker])

  const theme = useTheme()
  const routeType = SystemListsRouteType[id as SystemListId] ?? RouteType.List
  const systemList = isSystemListRoute(routeType)
  const metadata = getMetadataForRoute({ routeType }, theme)

  return (
    <S.ListHeader>
      <S.Content>
        {emoji ? (
          <S.Emoji onClick={openEmojiPicker}>
            <Icon boxSize={32} variant="emoji" value={emoji} />
          </S.Emoji>
        ) : metadata ? (
          <S.Icon>
            <RouteIcon boxSize={32} routeType={metadata.type} />
          </S.Icon>
        ) : null}
        <S.ListHeaderText>
          {editing ? (
            <S.TextEditable
              data-placeholder="Untitled"
              value={inputValue}
              onClick={stopPropagationClick}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              ref={textRef}
              placeholder="Untitled"
            />
          ) : (
            <S.ListHeaderTitle
              onClick={systemList ? emptyFn : onItemClick}
              className={cx({ systemList })}
            >
              {systemList ? metadata?.title : titleOrDefault(inputValue)}
            </S.ListHeaderTitle>
          )}
        </S.ListHeaderText>
        {renderer()}
      </S.Content>
    </S.ListHeader>
  )
}

export default ListHeader

export const ListHeaderHeight = 48

const S = {
  ListHeader: styled.div({
    display: `flex`,
    flexDirection: `column`,
    alignItems: `flex-start`,
    justifyContent: `flex-start`,
    userSelect: `none`,
    outline: `none`,
    width: `100%`,
    padding: `0 20px`,
  }),
  Content: styled.div({
    display: `flex`,
    flex: `0 0 64px`,
    width: `100%`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    margin: `24px 0 8px 0`,
  }),
  Icon: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginRight: 12,
    userSelect: `none`,
    outline: `none`,
  }),
  Emoji: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    marginLeft: 12,
    marginRight: 12,
    borderRadius: 6,
    userSelect: `none`,
    outline: `none`,
    cursor: `pointer`,

    '&:hover': {
      background: theme.colors.alpha.tone.weak,
    },
  })),
  ListHeaderText: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flex: `1`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    overflowX: `hidden`,
    fontWeight: `bold`,
    ...theme.text.publicSans['33:40'],
    marginLeft: 4,
  })),
  ListHeaderTitle: styled.div({
    width: `100%`,
    '&:not(.systemList)': {
      cursor: `text`,
    },
  }),
  TextEditable: styled.input(({ theme }) => ({
    width: `100%`,
    outline: `none`,
    height: `40px`,
    ...theme.text.publicSans['33:40'],
    borderRadius: 6,
    boxSizing: `border-box`,

    '::placeholder': {
      color: theme.colors.text[500],
    },
  })),
}
