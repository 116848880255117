import emoji from 'node-emoji'

import {
  CreateRecordPayload,
  MoveToExistingPlaceIndex,
  RecordType,
  RootRecordId,
} from '@eleventhlabs/capture-shared'
import { NavigateFn } from '@gatsbyjs/reach-router'

import { RecordCreatedTriggerValueType } from '../../../../analytics/capture-analytics-actions'
import { focusAction, scrollAction } from '../../../../events'
import { immediateExecute } from '../../../../utils'
import { isMobile } from '../../../../utils/env'
import { createInputId } from '../../../../utils/input'

export const createCollection = (
  createRecord: (
    payload: CreateRecordPayload,
    index: MoveToExistingPlaceIndex,
    trigger?: RecordCreatedTriggerValueType,
  ) => string,
  navigate: NavigateFn,
  isLeftHidden: boolean,
  toggleSidebar: () => void,
): string => {
  if (isMobile && !isLeftHidden) toggleSidebar()

  const id = createRecord(
    {
      type: RecordType.List,
      title: ``,
      emoji: emoji.random().key,
    },
    {
      id: RootRecordId,
      point: { at: `start` },
    },
  )
  immediateExecute(() => navigate(id))
  focusAction({ id: createInputId(id) })
  immediateExecute(() => scrollAction({ target: `outline`, to: { id } }))
  return id
}
