import * as React from 'react'
import FileIcon from '../assets/icon/icon-file.svg'
import { cx, extractRGB, styled } from '../common/stationary'
import { useUIContext } from '../common/contexts/UIContext'
import { SideBarDimensions } from './SideBar'

export const FileDropOverlay: React.FC = () => {
  const { isDarkMode } = useUIContext()
  const isSideBarHidden = localStorage.getItem('isLeftHidden') === 'true'

  return (
    <S.FileDropOverlay
      style={{ left: !isSideBarHidden ? SideBarDimensions.width : 0 }}
    >
      <S.FileDropOverlayCTA>
        <S.FileIconWrapper className={cx({ isDarkMode })}>
          <S.FileIcon />
        </S.FileIconWrapper>
        <S.FileDropOverlayCTAText>
          <S.FileDropOverlayCTAMainCallout>
            Upload file
          </S.FileDropOverlayCTAMainCallout>
          <S.FileDropOverlayCTADescription>
            Drag and drop anywhere on the screen
          </S.FileDropOverlayCTADescription>
        </S.FileDropOverlayCTAText>
      </S.FileDropOverlayCTA>
    </S.FileDropOverlay>
  )
}

const S = {
  FileDropOverlay: styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    background: `rgba(${extractRGB(theme.colors.surface.base)} / 0.9)`,
    height: '100vh',
  })),
  FileDropOverlayCTA: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    paddingTop: 48,
    position: 'relative',
    marginTop: 144,
    borderRadius: 8,
    border: `1px dashed rgba(0, 0, 0, 0.20)`,

    background: theme.colors.surface.lowest,
    maxWidth: 720,
    width: '100%',
    height: 256,
    flexShrink: 0,
  })),
  FileIconWrapper: styled.div({
    // Icon is default black
    '&.isDarkMode': {
      filter: 'invert(1)',
    },
  }),
  FileIcon: styled(FileIcon)({
    width: 72,
    height: 72,
  }),
  FileDropOverlayCTAText: styled.div(({ theme }) => ({
    width: '100%',
  })),
  FileDropOverlayCTAMainCallout: styled.div(({ theme }) => ({
    fontWeight: 700,
    textAlign: 'center',
    ...theme.text.publicSans['27.5:36'],
    fontSize: 26,
  })),
  FileDropOverlayCTADescription: styled.div(({ theme }) => ({
    fontWeight: 400,
    textAlign: 'center',
    ...theme.text.publicSans['16:24'],
    fontSize: 15,
  })),
}
