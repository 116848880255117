import { useCallback, useMemo } from 'react'

import Analytics from '../../../common/analytics/capture-analytics-actions'
import { useExecContext } from '../../../common/contexts/ExecContext'
import { MenuAction } from '../../../common/stationary'
import { SHOW_COMPLETED } from '../../../common/utils/uiConstants'
import { useKeyCaps } from '../../../common/keyMappableActions/useKeyCaps'

export function useShowCompletedAction(showCompleted: boolean): MenuAction {
  const { exec } = useExecContext()

  const onShowCompletedChange = useCallback(() => {
    exec({
      type: 'TOGGLE_SHOW_COMPLETED',
      trigger: Analytics.ShowCompletedToggleTrigger.HEADER_TOGGLE,
    })
  }, [exec])

  const keyCaps = useKeyCaps()

  return useMemo(() => {
    return {
      type: `menuItem`,
      menuItemProps: {
        title: SHOW_COMPLETED,
        switchProps: {
          checked: !!showCompleted,
          disabled: showCompleted === undefined,
          onMouseDown: (e) => e.preventDefault(),
          onChange: onShowCompletedChange,
        },
        keycaps: keyCaps['KEY_MAPPED_TOGGLE_MARKED_DONE_VISIBLE'],
      },
    }
  }, [showCompleted, onShowCompletedChange, keyCaps])
}
