import { useCallback, useRef } from 'react'

export const useFocus = <T extends HTMLElement>() => {
  const htmlRef = useRef<T>(null)

  const focus = useCallback(() => {
    if (htmlRef.current) htmlRef.current.focus()
  }, [])

  const blur = useCallback(() => {
    if (htmlRef.current) htmlRef.current.blur()
  }, [])

  return {
    htmlRef,
    focus,
    blur,
  }
}
