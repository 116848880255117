import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import { isImage } from '../../../../common/files'
import { FileMetadata } from '@eleventhlabs/capture-shared'
import { FileDescription } from './components/FileDescription'
import { FileThumbnail } from './components/FileThumbnail'
import { FileTitle } from './components/FileTitle'
import { getFileDescription, isMovie } from './utils'
import { cx, styled } from '../../../../common/stationary'

interface FileRecordItemContentProps {
  id: string
  url?: string
  thumbnailUrl?: string
  metadata?: FileMetadata
  hasTags: boolean
  notesOpen: boolean
  uploadProgress: number
  className?: string
  style?: any
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onTitleClick?: (e: React.MouseEvent) => void
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
  renameFile?: (fileName: string) => void
  isExpanded: boolean
  initialHeight?: number
  isCompleted?: boolean
}

let heightToDisplay

export const FileRecordItemContent: React.FC<FileRecordItemContentProps> = ({
  className,
  hasTags,
  id,
  metadata,
  notesOpen,
  onClick,
  onMouseDown,
  onTitleClick,
  renameFile,
  thumbnailUrl,
  uploadProgress,
  url,
  isExpanded,
  initialHeight,
  isCompleted,
}) => {
  const [transition, setTransition] = useState(isExpanded)

  heightToDisplay = initialHeight ?? 489

  useEffect(() => {
    setTimeout(() => setTransition(isExpanded), 1)
  }, [isExpanded])

  const loading = isEmpty(url)

  const description = getFileDescription(metadata)
  const thumbnail = isImage(metadata?.mimeType)
    ? thumbnailUrl ?? url
    : undefined

  return isExpanded ? (
    <S.ExpandedContainer
      title={url}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      <S.Expanded className={cx({ hasTags, isCompleted })}>
        <FileThumbnail
          loading={loading}
          progress={uploadProgress}
          thumbnail={thumbnail}
          notesOpen={notesOpen}
          isMovie={isMovie(metadata?.mimeType ?? ``)}
          isExpanded={isExpanded}
        />
      </S.Expanded>
      <S.ExpandedContent
        className={cx({
          hasTags,
        })}
      >
        <div>
          <FileTitle
            id={id}
            loading={loading}
            onClick={onTitleClick}
            renameFile={renameFile}
            title={metadata?.name}
            isExpanded={isExpanded}
            isCompleted={isCompleted}
            url={url}
          />
        </div>
        <S.ContentRow
          className={cx({
            isExpanded,
            hasTags,
            bottomRow: true,
          })}
        >
          <FileDescription description={description} loading={loading} />
        </S.ContentRow>
      </S.ExpandedContent>
    </S.ExpandedContainer>
  ) : (
    <S.Container
      className={cx(className, {
        validURL: !!url,
        loading,
        isExpanded,
        transition,
        hasTags,
      })}
      title={url}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      <S.Content className={cx({ hasTags })}>
        <div>
          <FileTitle
            id={id}
            loading={loading}
            onClick={onTitleClick}
            renameFile={renameFile}
            title={metadata?.name}
            url={url}
          />
        </div>
        <S.ContentRow className={cx({ bottomRow: true })}>
          <FileDescription description={description} loading={loading} />
        </S.ContentRow>
      </S.Content>
      <S.RightSide className={cx({ hasTags, transition })}>
        <FileThumbnail
          loading={loading}
          progress={uploadProgress}
          thumbnail={thumbnail}
          notesOpen={notesOpen}
          isMovie={isMovie(metadata?.mimeType ?? ``)}
        />
      </S.RightSide>
    </S.Container>
  )
}

const S = {
  Container: styled.div({
    display: `flex`,
    width: `100%`,
    maxWidth: `100%`,
    flexDirection: `row`,
    userSelect: `none`,
    height: 96,
    transition: 'height .2s',

    '&:hover .edit': {
      display: `inline-flex`,
    },

    '&.hasTags': {
      height: 120,
    },

    '&.transition': {
      height: heightToDisplay || 489,
    },
  }),
  LeftSide: styled.div({
    display: `flex`,
    flex: `0 0 72px`,
    height: `56px`,
    flexDirection: `row`,
  }),
  Content: styled.div({
    display: `flex`,
    flex: 1,
    height: `100%`,
    minWidth: 0,
    flexDirection: `column`,
    padding: 24,

    '&.hasTags': {
      padding: `24px 24px 48px 24px`,
    },
  }),
  ContentRow: styled.div({
    display: `flex`,
    flex: 1,
    width: `100%`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,

    '&.bottomRow': {
      zIndex: 6,
    },

    '&.isExpanded.hasTags': {
      marginBottom: 20,
    },
  }),
  RightSide: styled.div({
    display: `flex`,
    flex: `0 0 192px`,
    height: '96px',
    flexDirection: `row`,

    '&.hasTags': {
      height: `120px`,
    },
  }),
  Expanded: styled.div({
    width: '100%',
    display: 'block',
    aspectRatio: '2 / 1',
    objectFit: 'scale-down',
    alignContent: 'center',
    '&.isCompleted': {
      opacity: 0.6,
    },
  }),
  ExpandedContent: styled.div({
    display: `flex`,
    flex: 1,
    height: `70`,
    minWidth: 0,
    flexDirection: `column`,
    padding: 24,

    '&.hasTags': {
      height: 120,
    },
  }),
  ExpandedContainer: styled.div({
    width: '100%',

    '&:hover .edit': {
      display: `inline-flex`,
    },
  }),
}
