import { keyBy } from 'lodash'
import { WriteTransaction } from 'replicache'

import {
  RCMutation,
  dateGroupFromIso,
  zonedIsoDateFromUtcAndTz,
} from '@eleventhlabs/capture-shared'

import {
  getTickleableRecord,
  getTickleableRecords,
  updateFIndexesForMoveIntoDateGroup,
  updateTickler,
} from './utils'

export const updateTicklers = async (
  tx: WriteTransaction,
  {
    recordIds,
    tickler: payload,
    executedAtOnClient,
    tzOnClient,
  }: RCMutation.UpdateTicklersArgs,
): Promise<void> => {
  if (payload.isoDate !== undefined && payload.isoDate !== null) {
    const todayIsoWhenExecutedOnClient = zonedIsoDateFromUtcAndTz(
      executedAtOnClient,
      tzOnClient,
    )
    if (payload.isoDate) {
      const dateGroup = dateGroupFromIso(
        todayIsoWhenExecutedOnClient,
        payload.isoDate,
      )
      await updateFIndexes(
        tx,
        recordIds,
        dateGroup,
        executedAtOnClient,
        tzOnClient,
      )
    }
  }
  for (const recordId of recordIds) {
    const record = await getTickleableRecord(tx, recordId)
    await updateTickler(tx, record, payload)
  }
}

export const updateFIndexes = async (
  tx: WriteTransaction,
  recordIds: string[],
  iso: string,
  executedAtOnClient: number,
  tzOnClient: string | undefined,
) => {
  const records = keyBy(await getTickleableRecords(tx, recordIds), `id`)
  const toUpdateFIndex = recordIds.filter(
    (id) => records[id].tickler?.isoDate !== iso,
  )
  const todayIsoWhenExecutedOnClient = zonedIsoDateFromUtcAndTz(
    executedAtOnClient,
    tzOnClient,
  )
  const dateGroup = dateGroupFromIso(todayIsoWhenExecutedOnClient, iso)
  await updateFIndexesForMoveIntoDateGroup(
    tx,
    toUpdateFIndex,
    dateGroup,
    { at: `start` },
    executedAtOnClient,
    tzOnClient,
  )
}
