import React, { useEffect, useState } from 'react'
import { SlideProps } from '.'
import diagramDesktopAppMacSrc from '../../../../assets/onboarding/diagram-desktop-app@2x.png'
import diagramDesktopAppWindowsSrc from '../../../../assets/onboarding/diagram-desktop-app-windows@2x.png'
import { Env } from '../../../../common/env'
import { Key, useKeys } from '../../../../common/hooks/useKeys'
import { cx, styled } from '../../../../common/stationary'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import { OnboardingKeys, prettifyKey } from '../../hooks/useOnboardingHotkeys'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/SplitCardSlide'
import { useReward } from 'react-rewards'
import { isWindows } from '../../../../common/utils/env'
import { isMacLike } from '../../../../common/utils/env/env'

type PlatformState = 'macOS' | 'Windows'
type InstallState = 'Start' | 'WaitingForDownload' | 'Finish'

export const InstallDesktopApp: React.FC<SlideProps> = ({ goForward }) => {
  const [installState, setInstallState] = useState<InstallState>('Start')

  const { onboardingAnalytics } = useOnboardingContext()

  const platformState: PlatformState = isWindows ? 'Windows' : 'macOS'

  const { reward: emojiReward } = useReward('emojiRewardId', 'emoji', {
    emoji: ['✅'],
    zIndex: 1,
  })

  useEffect(() => {
    if (installState === 'Finish') emojiReward()
  }, [installState])

  useEffect(() => {
    if (installState === 'WaitingForDownload') {
      setTimeout(() => {
        setInstallState('Finish')
      }, 1000)
    }
  }, [platformState, installState, setInstallState])

  useKeys(Key.Enter, () => installState === 'Finish' && goForward())

  const getActivePlatformTitle = () =>
    platformState === 'macOS' ? 'macOS' : 'Windows'

  const getActivePlatformDownloadUrl = () => {
    if (platformState === 'macOS') return `${Env.captureWebUrl}/macos`
    if (platformState === 'Windows') return `${Env.captureWebUrl}/windows`
  }

  const handleDownloadButtonClick = () => {
    onboardingAnalytics.action('Desktop Download Button Clicked')
    setInstallState('WaitingForDownload')
    /**
     * IMPORTANT: window.open must be fired from a synchronous onClick to not get blocked in Safari
     */
    window.open(getActivePlatformDownloadUrl(), '_blank')
  }

  return (
    <S.InstallDesktopApp>
      <TransitionSlideContainer>
        <SlideIntro>
          <SlideTitle>Desktop app</SlideTitle>
          <SlideDescription>
            Quickly capture from any window with the{' '}
            <strong>global keyboard shortcut</strong>{' '}
            {OnboardingKeys.ShowQuickCapture.map(prettifyKey).map(
              (key, index) => (
                <S.PrettyKeys key={index}>{key}</S.PrettyKeys>
              ),
            )}{' '}
            — no more switching apps to take notes.
          </SlideDescription>
          <SlidePrimaryButton
            className={cx({ isSuccess: installState === 'Finish' })}
            disabled={installState !== 'Start'}
            id="emojiRewardId"
            onClick={handleDownloadButtonClick}
          >
            <strong>Download for {getActivePlatformTitle()}</strong>
            {installState === 'Finish' && <span>✅</span>}
          </SlidePrimaryButton>
          <SlideButtonGroup
            style={{
              display: installState !== 'Finish' ? 'none' : undefined,
            }}
          >
            <SlidePrimaryButton onClick={() => goForward()}>
              <strong>Continue</strong>
            </SlidePrimaryButton>
          </SlideButtonGroup>
        </SlideIntro>
        <SlideDiagrams>
          <img
            src={
              isMacLike ? diagramDesktopAppMacSrc : diagramDesktopAppWindowsSrc
            }
            srcSet={`${
              isMacLike ? diagramDesktopAppMacSrc : diagramDesktopAppWindowsSrc
            } 2x`}
          />
        </SlideDiagrams>
      </TransitionSlideContainer>
    </S.InstallDesktopApp>
  )
}

const S = {
  InstallDesktopApp: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',
  })),
  PrettyKeys: styled.span(({ theme }) => ({
    display: 'inline-block',
    margin: '0 1px',
    padding: '2px 4px',
    border: `1px solid ${theme.colors.alpha.border.weak}`,
    borderRadius: 4,
    ...theme.text.publicSans['11.5:16'],
    fontWeight: 700,
    color: theme.colors.text[700],
    background: theme.colors.alpha.tone.extraWeak,
    transform: 'translateY(-2px)',
  })),
}
