import React from 'react'

import { styled } from '../../../../common/stationary'

export interface SelectionCounterProps {
  count: number
}

const SelectionCounter = ({ count }: SelectionCounterProps) => {
  return count > 0 ? <S.Counter>{count} selected</S.Counter> : null
}

export default SelectionCounter

const S = {
  Counter: styled.div(({ theme }) => ({
    position: `absolute`,
    right: 20,
    top: 22,
    display: `flex`,
    flexDirection: `row`,
    padding: `4px 10px`,
    border: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    boxShadow: `0px 1px 3px ${theme.colors.alpha.tone.weak}, 0px 0px 12px ${theme.colors.alpha.tone.weak}`,
    borderRadius: 6,
    fontSize: `14px`,
    lineHeight: `20px`,
    color: theme.colors.text[500],
  })),
}
