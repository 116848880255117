import React, { useCallback } from 'react'

import { VariableVirtualListAutosized } from '../../../virtualized'
import {
  HandleResultPayload,
  ItemType,
  ResultGroupItem,
} from '../CommandBar.types'
import { withScrollerRef } from '../enhancers/withScrollerRef'
import { ResultItemDimensions } from './ResultItem'
import { ResultItemRow } from './ResultItemRow'

const ResultList: React.FC<{
  items: ResultGroupItem[]
  className?: string
  onHover?: (id: string) => void
  handleResultClick: (payload: HandleResultPayload) => void
}> = (props) => {
  const itemSize = useCallback(
    (index: number) =>
      props.items[index]?.type === ItemType.Group
        ? ResultItemDimensions.Group.height
        : ResultItemDimensions.Action.height,
    [props.items],
  )

  return (
    <List
      itemCount={props.items.length}
      itemSize={itemSize}
      itemData={props}
      className={props.className}
      Row={ResultItemRow}
    />
  )
}

const List = withScrollerRef(VariableVirtualListAutosized)

export default ResultList
