import {
  capitalize,
  filter,
  isArray,
  isEmpty,
  map,
  split,
  toLower,
} from 'lodash'
import { useEffect } from 'react'

import keyboardManager, { HotKey } from '../keyboardManager'

type KeyHandler = (event: KeyboardEvent) => void

import { Key as SharedKey } from '@eleventhlabs/capture-shared'
import { isMacLike } from '../utils/env'

export type ClientKeyType = keyof typeof SharedKey

export type ClientKey = {
  [K in ClientKeyType]: (typeof SharedKey)[K]
}

export const Key: ClientKey = {
  ...SharedKey,
  // Add or override keys based on the platform
  CommandA: isMacLike ? `command+a` : `ctrl+a`,
  CommandC: isMacLike ? `command+c` : `ctrl+c`,
  CommandD: isMacLike ? `command+d` : `ctrl+d`,
  CommandE: isMacLike ? `command+e` : `ctrl+e`,
  CommandDown: isMacLike ? `command+down` : `ctrl+down`,
  CommandEnter: isMacLike ? `command+enter` : `ctrl+enter`,
  CommandGreaterThan: isMacLike ? `command+shift+.` : `ctrl+shift+.`,
  CommandJ: isMacLike ? `command+j` : `ctrl+j`,
  CommandK: isMacLike ? `command+k` : `ctrl+k`,
  CommandP: isMacLike ? `command+p` : `ctrl+p`,
  CommandShiftDown: isMacLike ? `command+shift+down` : `ctrl+shift+down`,
  CommandShiftE: isMacLike ? `command+shift+e` : `ctrl+shift+e`,
  CommandShiftEnter: isMacLike ? `command+shift+enter` : `ctrl+shift+enter`,
  CommandShiftJ: isMacLike ? `command+shift+j` : `ctrl+shift+j`,
  CommandShiftK: isMacLike ? `command+shift+k` : `ctrl+shift+k`,
  CommandShiftP: isMacLike ? `command+shift+p` : `ctrl+shift+p`,
  CommandShiftUp: isMacLike ? `command+shift+up` : `ctrl+shift+up`,
  CommandBackslash: isMacLike ? `command+\\` : `ctrl+\\`,
  CommandSlash: isMacLike ? `command+/` : `ctrl+/`,
  CommandUp: isMacLike ? `command+up` : `ctrl+up`,
  OptionDown: isMacLike ? `option+down` : `alt+down`,
  OptionJ: isMacLike ? `option+j` : `alt+j`,
  OptionK: isMacLike ? `option+k` : `alt+k`,
  OptionUp: isMacLike ? `option+up` : `alt+up`,
} as ClientKey

export enum Priorities {
  Lowest = 0,
  Low = 5,
  Default = 10,
  High = 15,
  Dialog = 20,
  Highest = 25,
}

export const getShortcutKeys = (shortcut: string | undefined): string[] => {
  if (!shortcut) return []
  if (shortcut === 'shift+/') return ['?']

  return map(
    filter(split(shortcut, `+`), (key) => !isEmpty(key)),
    shortcutKeyToSymbol,
  )
}

export const shortcutKeyToSymbol = (shortcut: string): string => {
  const value = toLower(shortcut)
  switch (value) {
    case `command`:
      return `⌘`
    case `ctrl`:
      return `Ctrl`
    case `shift`:
      return `⇧`
    case `enter`:
      return `↵`
    case `up`:
      return `↑`
    case `down`:
      return `↓`
    case `left`:
      return `←`
    case `right`:
      return `→`
    default:
      return capitalize(shortcut)
  }
}

/**
 * Use this to register as many handlers as you want to a specific hotkey from whatever components you want
 */

export const useKeys = (
  keys: string | string[],
  handler: KeyHandler,
  priority = Priorities.Default,
  preventLowerPriorities = false,
  preventDefault = true,
  deps?: any[],
): void => {
  const dependencies = deps ?? [handler]

  useEffect(() => {
    const hotKey: HotKey = {
      priority,
      handler,
      preventDefault,
      preventLowerPriorities,
    }

    const keysArray = isArray(keys) ? keys : [keys]

    keysArray.forEach((key) => keyboardManager.subscribe(key, hotKey))
    return () =>
      keysArray.forEach((key) => keyboardManager.unsubscribe(key, hotKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
