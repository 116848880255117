import { useCallback } from 'react'
import { ReadTransaction, Replicache } from 'replicache'

import { RecordWithTickler } from '@eleventhlabs/capture-shared'
import { getOverdueRecordsForDate } from './query'
import {
  JSONObjWithId,
  useSubscribeArrWithStableIdentities,
} from './useSubscribe'

export const useOverdueRecords = (
  rep: Replicache | null,
  thresholdISO: string,
  def: RecordWithTickler[],
  deps?: any[],
) => {
  const includeSoftDeleted = false
  const get = useCallback(
    async (tx: ReadTransaction) => {
      return (await getOverdueRecordsForDate(
        tx,
        thresholdISO,
        includeSoftDeleted,
      )) as unknown as JSONObjWithId[]
    },
    [thresholdISO],
  )
  const overdueRecords =
    useSubscribeArrWithStableIdentities(
      rep,
      get,
      def as unknown as JSONObjWithId[],
      deps,
    ) ?? def
  return overdueRecords as unknown as RecordWithTickler[]
}
