import {
  Icon,
  gradientAnimationStyles,
  gradientBackgroundStyles,
  spinAnimationStyles,
  styled,
} from '../../common/stationary'

export const AuthStyles = {
  AuthScreen: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    ...gradientBackgroundStyles({ theme }),
    ...gradientAnimationStyles({ animationDuration: '30s' }),
  })),

  Main: styled.main(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 32,
    padding: '0 8px',
    textAlign: 'center',
  })),

  AuthCardWrapper: styled.div(() => ({
    height: 490,
    overflow: 'visible',
  })),

  AuthCard: styled.div(({ theme }) => ({
    width: 384,
    maxWidth: '100%',
    padding: `32px 24px`,
    borderRadius: 12,
    boxShadow: theme.effects.boxShadow.elevation[2],
    background: theme.colors.surface.base,
  })),

  AuthRelatedMessage: styled.div(() => ({
    width: 384,
    padding: 32,
  })),

  AuthHeader: styled.div(() => ({
    display: `flex`,
    flex: 1,
    flexDirection: `column`,
    alignItems: `center`,
    padding: '16px 0 24px',
  })),

  AuthTitle: styled.div(({ theme }) => ({
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...theme.text.publicSans['23:32'],
    color: theme.colors.text[900],

    strong: {
      fontWeight: 800,
    },
  })),

  ClipperMessage: styled.div(({ theme }) => ({
    marginTop: 8,
    ...theme.text.publicSans['16:24'],
    fontWeight: 700,
    color: theme.colors.text[700],
  })),

  AuthBody: styled.div({
    flex: 1,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
    marginTop: 8,
  }),

  AuthForm: styled.form({
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
  }),

  AuthPassword: styled.input(({ theme }) => ({
    all: 'unset',
    boxSizing: 'border-box',
    height: 48,
    width: '100%',
    border: `1px solid ${theme.colors.alpha.border.medium}`,
    borderRadius: 8,
    marginBottom: 8,
    background: theme.colors.alpha.tone.extraWeak,
    textAlign: 'left',
    paddingLeft: 16,
    paddingRight: 16,
    color: theme.colors.text[900],

    '&:focus': {
      borderColor: theme.colors.alpha.border.strong,
    },

    '::placeholder': {
      color: theme.colors.text[500],
    },
  })),

  InputWithIcon: styled.div(() => ({
    display: 'flex',
    '> input': {
      paddingRight: 40,
    },
  })),

  InlineIconPositioning: styled.div(() => ({
    marginTop: 14,
    marginLeft: -30,
  })),

  AuthSubmit: styled.button(({ theme }) => ({
    all: 'unset',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    height: 48,
    width: '100%',
    marginTop: 8,
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[1],
    fontWeight: 700,
    background: theme.colors.text[900],
    color: theme.colors.alpha.invertedTone.solid,
    cursor: 'pointer',

    ':disabled': {
      opacity: 0.6,
      cursor: 'default',
    },
  })),

  SubmitIcon: styled(Icon)({
    '&.isLoading': {
      ...spinAnimationStyles({ animationDuration: '2s' }),
    },
  }),

  GoToLogin: styled.button(({ theme }) => ({
    all: 'unset',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    height: 48,
    width: '100%',
    marginBottom: 32,
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[1],
    fontWeight: 700,
    background: theme.colors.text[900],
    color: theme.colors.alpha.invertedTone.solid,
    cursor: 'pointer',
  })),

  AuthMessage: styled.div(({ theme }) => ({
    width: '100%',
    padding: '12px 16px',
    borderRadius: 8,
    ...theme.text.publicSans['13.5:20'],
    background: theme.colors.gray[100],
    color: theme.colors.text[700],
    marginTop: 16,

    strong: {
      fontWeight: 700,
    },

    a: {
      fontWeight: 700,

      ':hover': {
        textDecoration: 'underline',
      },

      cursor: 'pointer',
    },

    '&.isSuccess': {
      background: theme.colors.green[100],
      color: theme.colors.green[900],
    },

    '&.isError': {
      background: theme.colors.red[100],
      color: theme.colors.red[900],
    },
  })),

  WaitingForAuth: styled.div(() => ({
    textAlign: 'center',
  })),

  ActionHint: styled.div(({ theme }) => ({
    marginBottom: 12,
    color: theme.colors.text[900],

    strong: {
      fontWeight: 700,
    },
  })),

  CheckSpamHint: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    height: 24,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[700],

    i: {
      fontStyle: 'italic',
    },
  })),

  AlternativeLoginMethodMessage: styled.div(({ theme }) => ({
    fontWeight: 600,
    paddingTop: 32,
    cursor: 'pointer',
    color: theme.colors.text[600],
    fontSize: 13,
  })),

  ContactUs: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    height: 24,
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[700],

    a: {
      fontWeight: 600,

      ':hover': {
        textDecoration: 'underline',
      },
    },
  })),
}
