import { WriteTransaction } from 'replicache'

import { OnboardingTrack, RCMutation } from '@eleventhlabs/capture-shared'

import { replicacheUserRepository } from '../replicacheUserRepository'
import { cloneDeep, set } from 'lodash'
import { IDBUser } from '../models/IDBUser'

export const userMarkOnboardingComplete = async (
  tx: WriteTransaction,
  { executedAtOnClient, track }: RCMutation.UserMarkOnboardingComplete,
) => {
  const user = await replicacheUserRepository.getUser(tx)
  const updatedUser = createUserWithTrackCompleted(
    user,
    track,
    executedAtOnClient,
  )
  await replicacheUserRepository.putUser(tx, updatedUser)
}

function createUserWithTrackCompleted(
  user: IDBUser,
  track: OnboardingTrack,
  completedAt: number,
): IDBUser {
  let updatedUser = cloneDeep(user)
  initializeTrackIfNotInitialized(updatedUser, track)
  completeTrack(updatedUser, track, completedAt)
  return updatedUser
}

function initializeTrackIfNotInitialized(u: IDBUser, track: OnboardingTrack) {
  const isTrackNotInitialized = u.data?.onboarding?.track === undefined
  if (isTrackNotInitialized) initializeTrack(u, track)
}

function initializeTrack(u: IDBUser, track: OnboardingTrack) {
  set(u, `data.onboarding.track.${track}`, {
    savePoints: {},
    completedSlides: {},
    isComplete: true,
  })
}

function completeTrack(
  u: IDBUser,
  track: OnboardingTrack,
  completedAt: number,
) {
  set(u, `data.onboarding.track.${track}.isComplete`, true)
  set(u, `data.onboarding.track.${track}.completedAt`, completedAt)
}
