import { first, last } from 'lodash'

import { BaseSelection } from './base'
import {
  getContiguousSliceContainingTarget,
  getItemsSortedByOrder,
  getOrderedRangeIncludingItems,
  sortByOrderAndGetFirstItem,
  sortByOrderAndGetLastItem,
} from './ordered'
import {
  Direction,
  FocusLocation,
  Ordered,
  Reorderable,
  SelectionInteractions,
} from './types'

export const createSelectionInteractions = <T>(
  selection: BaseSelection,
  structure: Ordered & Reorderable<T>,
): SelectionInteractions => {
  return {
    moveUp: (): string[] => {
      const newSelection = moveUp(selection.ids, structure)
      selection.set(newSelection)
      return newSelection
    },
    moveDown: () => {
      const newSelection = moveDown(selection.ids, structure)
      selection.set(newSelection)
      return newSelection
    },
    moveFocusDown: () => {
      const newSelection = moveFocusDown(selection.ids, structure)
      selection.set(newSelection)
      return newSelection
    },
    moveFocusUp: () => {
      const newSelection = moveFocusUp(selection.ids, structure)
      selection.set(newSelection)
      return newSelection
    },
    selectFirstItem: () => {
      const newSelection = structure.isEmpty()
        ? []
        : [structure.firstItem as string]
      selection.set(newSelection)
      return newSelection
    },
    selectLastItem: () => {
      const newSelection = structure.isEmpty()
        ? []
        : [structure.lastItem as string]
      selection.set(newSelection)
      return newSelection
    },
    setToRangeIncludingItems: (
      items: string[],
      focusLocation: FocusLocation,
    ) => {
      const range = getOrderedRangeIncludingItems(structure, items)
      const rangeWithCorrectFocus =
        focusLocation === `bottom` ? range : range.reverse()
      selection.set(rangeWithCorrectFocus)
      return rangeWithCorrectFocus
    },
    shiftItemsDown: () => {
      shiftItemsDown(selection.ids, structure)
      return selection.ids
    },
    shiftItemsUp: () => {
      shiftItemsUp(selection.ids, structure)
      return selection.ids
    },
  }
}

/*
 * ####
 * MOVE
 * ####
 */

const moveDown = (selectedIds: string[], structure: Ordered): string[] => {
  if (structure.isEmpty()) return []
  if (selectedIds.length === 0) return []
  const leadingItem = sortByOrderAndGetLastItem(structure, selectedIds)
  const nextItem = structure.getNextItem(leadingItem) as string
  return nextItem === undefined ? [leadingItem] : [nextItem]
}

const moveUp = (selectedIds: string[], structure: Ordered): string[] => {
  if (structure.isEmpty() === undefined) return []
  if (selectedIds.length === 0) return []
  const trailingItem = sortByOrderAndGetFirstItem(structure, selectedIds)
  const prevItem = structure.getPrevItem(trailingItem) as string
  return prevItem === undefined ? [trailingItem] : [prevItem]
}

/*
 * ##########
 * MOVE FOCUS
 * ##########
 */

const moveFocusDown = (selectedIds: string[], structure: Ordered): string[] => {
  if (structure.isEmpty() === undefined) return []
  if (selectedIds.length === 0) return []

  const focus = selectedIds[0]
  if (focus === structure.lastItem) return selectedIds

  const selectionSlice = getContiguousSliceContainingTarget(
    focus,
    selectedIds,
    structure,
  )
  const isFocusFirstInSlice = focus === selectionSlice[0]
  const isSliceCollapsed = selectionSlice.length === 1
  if (isFocusFirstInSlice && !isSliceCollapsed) {
    return getContractedSelection(selectedIds, structure, `forward`)
  } else {
    return getExpandedSelection(selectedIds, structure, `forward`)
  }
}

const moveFocusUp = (selectedIds: string[], structure: Ordered): string[] => {
  if (structure.isEmpty() === undefined) return []
  if (selectedIds.length === 0) return []

  const focus = selectedIds[0]
  if (focus === structure.firstItem) return selectedIds

  const selectionSlice = getContiguousSliceContainingTarget(
    focus,
    selectedIds,
    structure,
  )

  const isFocusLastInSlice = focus === selectionSlice.slice(-1)[0]
  const isSliceCollapsed = selectionSlice.length === 1
  if (isFocusLastInSlice && !isSliceCollapsed) {
    return getContractedSelection(selectedIds, structure, `backward`)
  } else {
    return getExpandedSelection(selectedIds, structure, `backward`)
  }
}

const getContractedSelection = (
  selectedIds: string[],
  structure: Ordered,
  dir: Direction,
): string[] => {
  const focus = selectedIds[0]
  const newFocus = getNextItemForDirection(focus, structure, dir)
  if (newFocus === undefined) throw new Error(``)
  const withoutOldOrNewFocus = selectedIds.filter(
    (id) => id !== focus && id !== newFocus,
  )
  return [newFocus, ...withoutOldOrNewFocus]
}

const getExpandedSelection = (
  selectedIds: string[],
  structure: Ordered,
  dir: Direction,
): string[] => {
  const focus = selectedIds[0]
  const newFocus = getNextItemForDirection(focus, structure, dir)
  if (newFocus === undefined) throw new Error(``)
  const withoutNewFocus = selectedIds.filter((id) => id !== newFocus)
  return [newFocus, ...withoutNewFocus]
}

/*
 * #####
 * SHIFT
 * #####
 */

const shiftItemsDown = <T>(
  selectedIds: string[],
  structure: Ordered & Reorderable<T>,
): void => {
  if (selectedIds.length === 0) return

  const selectedIdsInStructureOrder = getItemsSortedByOrder(
    structure,
    selectedIds,
  )

  const lastSelectedIdInStructure = last(selectedIdsInStructureOrder) as string
  const insertPosition = structure.getInsertPositionAfterTarget(
    lastSelectedIdInStructure,
  )
  if (insertPosition === undefined) return

  structure.moveItemsToInsertPosition(
    selectedIdsInStructureOrder,
    insertPosition,
  )
}

const shiftItemsUp = <T>(
  selectedIds: string[],
  structure: Ordered & Reorderable<T>,
): void => {
  if (selectedIds.length === 0) return

  const selectedIdsInStructureOrder = getItemsSortedByOrder(
    structure,
    selectedIds,
  )

  const firstSelectedIdInStructure = first(
    selectedIdsInStructureOrder,
  ) as string
  const insertPosition = structure.getInsertPositionBeforeTarget(
    firstSelectedIdInStructure,
  )
  if (insertPosition === undefined) return

  structure.moveItemsToInsertPosition(
    selectedIdsInStructureOrder,
    insertPosition,
  )
}

/*
 * #######
 * Helpers
 * #######
 */

const getNextItemForDirection = (
  id: string,
  ordered: Ordered,
  dir: Direction,
) => {
  return dir === `forward` ? ordered.getNextItem(id) : ordered.getPrevItem(id)
}
