import { useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useModelStoreContext } from '../contexts/ModelStoreContext'
import { useReplicacheContext } from '../replicache/ReplicacheContext'
import { Env } from '../env'

export const useBootCommandBarApp = () => {
  const {
    store: { user },
  } = useModelStoreContext()
  const { userId } = useAuth()
  const { isReady } = useReplicacheContext()

  useEffect(() => {
    if (!Env.isSSR && isReady) {
      window.CommandBar.boot(
        userId,
        { createdAt: user.createdAt, lastModifiedAt: user.lastModified },
        { products: ['nudges'] },
      )

      return () => {
        window.CommandBar.shutdown()
      }
    }
  }, [userId, user, isReady])
}
