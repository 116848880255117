import {
  IconUnstyled,
  IconUnstyledProps,
  IconVariantName as _IconVariantName,
  iconStyles,
} from '@eleventhlabs/stationary'

import { styled } from '../styled'
import { Theme } from '../theme'

export type IconVariantName = _IconVariantName

export type IconProps = IconUnstyledProps

export const Icon = styled(IconUnstyled)(iconStyles.root)

export type IconConfigPresetName =
  | `Inbox`
  | `Today`
  | `Trash`
  | `Details`
  | `File`
  | `FileImage`
  | `FileVideo`
  | `List`
  | `Note`
  | `Text`
  | `URL`

export interface IconConfig {
  color?: string
  value?: IconProps['value']
  variant?: IconProps['variant']
}

export const getIconConfigPresets = (
  presetName: IconConfigPresetName,
  theme?: Theme,
) => {
  const presets: Record<IconConfigPresetName, IconConfig> = {
    Inbox: {
      color: theme && theme.colors.blue[700],
      variant: `glyphSquircleInbox`,
    },
    Today: {
      color: theme && theme.colors.green[700],
      variant: `glyphCalSingle`,
    },
    Trash: {
      color: theme && theme.colors.gray[700],
      variant: `glyphTrash`,
    },
    Details: {
      variant: `glyphArrowNeSw`,
    },
    File: {
      variant: `glyphPaperClip`,
    },
    FileImage: {
      variant: `glyphImage`,
    },
    FileVideo: {
      variant: `glyphPaperClip`,
    },
    List: {
      variant: 'glyphTwoRectHorizontal',
    },
    Text: {
      variant: `glyphTextMulti`,
    },
    URL: {
      variant: `glyphLinkSingle`,
    },
    Note: {
      variant: `glyphBlurbSquircle`,
    },
  }

  return presets[presetName]
}
