import { some } from 'lodash'

import {
  CompleteableRecord,
  CreateRecordPayload,
  InboxRecordId,
  MoveToExistingPlaceIndex,
  RecordDict,
  RecordType,
} from '@eleventhlabs/capture-shared'
import { ModelStoreCommands } from '../../../modelStore'
import { InboxRecordGroupId } from '../../selectors/inbox/data'
import { RecordGroupsCommands } from '../../types'

export const createInboxRecordGroupsCommands = (
  records: RecordDict,
  commands: ModelStoreCommands,
): RecordGroupsCommands => {
  const isCompleted = (id: string) => {
    return (records[id] as CompleteableRecord).isCompleted
  }
  return {
    createAtFirstInsertPosition: (payload: CreateRecordPayload) => {
      const moveToIndex: MoveToExistingPlaceIndex = {
        id: InboxRecordId,
        point: { at: `start` },
      }
      const recordId = commands.createRecord(payload, moveToIndex)

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    createAtIndexInGroup: (
      payload: CreateRecordPayload,
      groupId: string,
      index: number,
    ) => {
      if (payload.type === RecordType.Note) {
        throw new Error(``)
      }
      if (groupId === InboxRecordGroupId.Completed) {
        console.error(`Cannot create completed records`)
        return
      }
      const moveToIndex: MoveToExistingPlaceIndex = {
        id: InboxRecordId,
        point: { at: `index`, index },
      }

      const recordId = commands.createRecord(
        {
          ...payload,
          isCompleted: groupId === InboxRecordGroupId.Completed,
          completedAt: Date.now(),
        },
        moveToIndex,
      )

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    addItemAtBottomOfGroup: (payload: CreateRecordPayload, groupId: string) => {
      if (payload.type === RecordType.Note) {
        throw new Error(``)
      }
      if (groupId === InboxRecordGroupId.Completed) {
        console.error(`Cannot create completed records`)
        return
      }

      const moveToIndex: MoveToExistingPlaceIndex = {
        id: InboxRecordId,
        point: { at: `end` },
      }

      const recordId = commands.createRecord(
        {
          ...payload,
          isCompleted: groupId === InboxRecordGroupId.Completed,
          completedAt: Date.now(),
        },
        moveToIndex,
      )

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    createBelowAnchor: (payload: CreateRecordPayload, anchorId: string) => {
      if (payload.type === RecordType.Note) {
        throw new Error(``)
      }
      const anchorRecord = records[anchorId] as CompleteableRecord
      if (anchorRecord.isCompleted) {
        console.error(`Cannot create completed records`)
        return
      }
      const payloadWithCompleted = anchorRecord.isCompleted
        ? { ...payload, isCompleted: true, completedAt: Date.now() }
        : payload

      const moveToIndex: MoveToExistingPlaceIndex = {
        id: InboxRecordId,
        point: { at: `after`, anchorId },
      }

      const recordId = commands.createRecord(payloadWithCompleted, moveToIndex)

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    moveBelowAnchor: (ids: string[], anchorId: string) => {
      const anchorRecord = records[anchorId] as CompleteableRecord
      if (some(ids.map((id) => isCompleted(id)))) {
        console.error(`Cannot move completed records`)
        return
      }
      if (anchorRecord.isCompleted) {
        console.error(`Cannot complete records via reorder`)
        return
      }
      commands.moveRecords(ids, {
        id: InboxRecordId,
        point: { at: `after`, anchorId },
      })
    },
    moveAboveAnchor: (ids: string[], anchorId: string) => {
      const anchorRecord = records[anchorId] as CompleteableRecord
      if (some(ids.map((id) => isCompleted(id)))) {
        console.error(`Cannot move completed records`)
        return
      }
      if (anchorRecord.isCompleted) {
        console.error(`Cannot complete records via reorder`)
        return
      }
      commands.moveRecords(ids, {
        id: InboxRecordId,
        point: { at: `before`, anchorId },
      })
    },
    moveToStartOfGroup: (ids: string[], groupId: string) => {
      if (some(ids.map((id) => isCompleted(id)))) {
        console.error(`Cannot move completed records`)
        return
      }
      if (groupId === InboxRecordGroupId.Completed) {
        console.error(`Cannot complete records via reorder`)
        return
      }
      commands.moveRecords(ids, {
        id: InboxRecordId,
        point: { at: `start` },
      })
    },
    moveToEndOfGroup: (ids: string[], groupId: string) => {
      if (some(ids.map((id) => isCompleted(id)))) {
        console.error(`Cannot move completed records`)
        return
      }
      if (groupId === InboxRecordGroupId.Completed) {
        console.error(`Cannot complete records via reorder`)
        return
      }
      commands.moveRecords(ids, {
        id: InboxRecordId,
        point: { at: `end` },
      })
    },
  }
}
