import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { cx } from '@emotion/css'
import React, { FC, FormEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ChromeLogo from '../../assets/onboarding/chrome-icon.png'
import Analytics from '../../common/analytics/capture-analytics-actions'
import { useAuth } from '../../common/contexts/AuthContext'
import { RouteType, RoutesPath } from '../../common/routes'
import { isMobile } from '../../common/utils/env'
import { AuthStyles as S } from '../LoginScreen/AuthStyles'

import { safeNavigate } from '../../common/navigate'
import { ThemeProvider, getThemes, styled } from '../../common/stationary'
import AuthLogo from '../LoginScreen/components/AuthLogo'
import ResetPasswordEmailSentMessage from '../LoginScreen/components/ResetPasswordEmailSentMessage'
import LoginFooter from '../LoginScreen/components/LoginFooter'
import ChangePasswordSuccess from './components/ChangePasswordSuccess'
import ResetPasswordForm from './components/ResetPasswordForm'
import {
  PASSWORD_RESET,
  PASSWORD_RESETTING,
  PASSWORD_SET,
  PASSWORD_SETTING,
} from '../../common/utils/uiConstants'

const { StationaryLight } = getThemes()
/*
 * ResetPasswordScreen
 */
const ResetPasswordScreen: FC<RouteComponentProps> = ({}) => {
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isMatchingError, setIsMatchingError] = useState(false)
  const [isResetError, setIsResetError] = useState(false)
  const [isMagicLinkEmailSuccess, setIsMagicLinkEmailSuccess] = useState(false)
  const [isPasswordChangeSuccess, setIsPasswordChangeSuccess] = useState(false)
  const {
    resetPasswordWithSession,
    isUserWithPassword,
    isChromeExtensionAuthFlow,
    sendSetPasswordEmail,
    sendResetPasswordEmail,
    authenticateViaMagicLink,
    errorState,
    setErrorState,
    getIsUserWithPassword,
  } = useAuth()
  const token = getQueryParams().get('token')

  if (!token) safeNavigate('/login')

  useEffect(() => {
    if (token) authenticateViaMagicLink(token)
    Analytics.resetPasswordScreenLoaded()
  }, [])

  const validatePasswordsAndSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (password !== confirmationPassword) {
      setIsMatchingError(true)
      return
    }

    setIsLoading(true)

    if (!token) return

    setIsMatchingError(false)

    try {
      await resetPasswordWithSession(password)
      setIsPasswordChangeSuccess(true)
      getIsUserWithPassword()
    } catch {
      setIsLoading(false)
      setIsResetError(true)
    }
  }

  const handleResendLink = async () => {
    const sendLinkFn = isUserWithPassword
      ? sendResetPasswordEmail
      : sendSetPasswordEmail
    try {
      await sendLinkFn()
      setIsMagicLinkEmailSuccess(true)
      setErrorState(undefined)
    } catch {}
  }

  const errorMessage = () => {
    if (isMatchingError) {
      return (
        <>
          Your passwords don't match. <strong>Please try again</strong>
        </>
      )
    }
    if (errorState === 'expired') {
      return (
        <>
          This{' '}
          {isUserWithPassword
            ? PASSWORD_RESET.toLowerCase()
            : PASSWORD_SET.toLowerCase()}{' '}
          password link has expired or previously been used.{' '}
          <strong>
            <a onClick={handleResendLink}>
              Send a new{' '}
              {isUserWithPassword
                ? PASSWORD_RESET.toLowerCase()
                : PASSWORD_SET.toLowerCase()}{' '}
              password link
            </a>
          </strong>{' '}
          or <a onClick={() => safeNavigate('inbox')}>return to Inbox</a>
        </>
      )
    }
    if (errorState === 'timeout') {
      return (
        <>
          It has been more than five minutes — your password cannot be{' '}
          {isUserWithPassword
            ? PASSWORD_RESET.toLowerCase()
            : PASSWORD_SET.toLowerCase()}
          .{' '}
          <strong>
            <a onClick={handleResendLink}>
              Send a new{' '}
              {isUserWithPassword
                ? PASSWORD_RESET.toLowerCase()
                : PASSWORD_SET.toLowerCase()}{' '}
              password link
            </a>
          </strong>{' '}
          or <a onClick={() => safeNavigate('inbox')}>return to Inbox</a>
        </>
      )
    }
    if (errorState === 'pkce-mismatch')
      return (
        <>
          Make sure to use the <strong>same device or browser</strong> when you
          open the{' '}
          {isUserWithPassword
            ? PASSWORD_RESET.toLowerCase()
            : PASSWORD_SET.toLowerCase()}{' '}
          password link from your email.{' '}
          <div style={{ marginTop: 8 }}>
            You can{' '}
            <strong>
              <a onClick={handleResendLink}>
                send a new{' '}
                {isUserWithPassword
                  ? PASSWORD_RESET.toLowerCase()
                  : PASSWORD_SET.toLowerCase()}{' '}
                password link
              </a>
            </strong>{' '}
          </div>
        </>
      )
    if (isResetError) {
      return (
        <>
          There was a problem resetting your password.{' '}
          <strong>Please try again</strong> with a different password.
        </>
      )
    }
    return null
  }

  const resetErrors = () => {
    setIsMatchingError(false)
    setIsResetError(false)
  }

  const loginMessage = errorMessage()

  return (
    <ThemeProvider theme={StationaryLight}>
      <S.AuthScreen>
        <Helmet>
          <title>
            {`Capture - ${
              isUserWithPassword ? PASSWORD_RESET : PASSWORD_SET
            } password`}
          </title>
        </Helmet>
        <S.Main>
          <ResetStyles.ResetWrapper>
            {isMagicLinkEmailSuccess ? (
              <ResetPasswordEmailSentMessage
                showAlternativeLoginMethod={false}
              />
            ) : isPasswordChangeSuccess ? (
              <ChangePasswordSuccess />
            ) : (
              <S.AuthCard>
                <>
                  <S.AuthHeader>
                    <AuthLogo
                      href={!isMobile ? '/' : undefined}
                      secondaryIconSrc={
                        isChromeExtensionAuthFlow ? ChromeLogo : undefined
                      }
                    />
                    <S.AuthTitle>
                      <strong>
                        {isUserWithPassword ? PASSWORD_RESET : PASSWORD_SET}{' '}
                        your password
                      </strong>
                    </S.AuthTitle>
                    {isChromeExtensionAuthFlow && (
                      <S.ClipperMessage>
                        Web Clipper for Chrome
                      </S.ClipperMessage>
                    )}
                    <ResetStyles.Warning>
                      <strong>Be aware:</strong>{' '}
                      {isUserWithPassword
                        ? PASSWORD_RESETTING
                        : PASSWORD_SETTING}{' '}
                      your password will log you out from all active sessions
                      for security.
                    </ResetStyles.Warning>
                  </S.AuthHeader>
                  <S.AuthBody>
                    <ResetPasswordForm
                      isUserWithPassword={isUserWithPassword}
                      isLoading={isLoading}
                      onSubmit={(event) => {
                        validatePasswordsAndSubmit(event)
                      }}
                      password={password}
                      setPassword={setPassword}
                      confirmationPassword={confirmationPassword}
                      setConfirmationPassword={setConfirmationPassword}
                      resetErrors={resetErrors}
                    />
                    {loginMessage && (
                      <S.AuthMessage
                        className={cx({
                          isError: loginMessage !== null,
                        })}
                      >
                        {loginMessage}
                      </S.AuthMessage>
                    )}
                  </S.AuthBody>
                </>
              </S.AuthCard>
            )}
            {!isPasswordChangeSuccess && (
              <S.AlternativeLoginMethodMessage
                style={{ marginTop: !!errorMessage ? -16 : 0 }}
                onClick={() => {
                  setErrorState(undefined)
                  safeNavigate(RoutesPath[RouteType.Inbox])
                }}
              >
                Back to Inbox
              </S.AlternativeLoginMethodMessage>
            )}
          </ResetStyles.ResetWrapper>
        </S.Main>
        {!isMobile && <LoginFooter />}
      </S.AuthScreen>
    </ThemeProvider>
  )
}

function getQueryParams(): URLSearchParams {
  const location = window.location
  const search = location?.search
  return new URLSearchParams(search)
}

export default ResetPasswordScreen

const ResetStyles = {
  Warning: styled.div(({ theme }) => ({
    fontSize: 14,
    marginTop: 12,
    color: theme.colors.orange[900],

    strong: {
      fontWeight: 700,
    },
  })),
  ResetWrapper: styled(S.AuthCardWrapper)(() => ({
    height: 670,
  })),
}
