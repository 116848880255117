import React, { createContext, useContext, useMemo } from 'react'
import { ConsoleLogger, ILogger, SentryLogger } from '../logging'
import { useAuth } from './AuthContext'

export interface LoggerContextValue {
  logger: ILogger
}

/*
 * Context
 */

const defaultValue: LoggerContextValue = {
  logger: new ConsoleLogger(),
}

export const LoggerContext = createContext<LoggerContextValue>(defaultValue)
LoggerContext.displayName = `LoggerContext`

/*
 * Provider
 */

export interface LoggerContextProviderProps {
  children: React.ReactNode
}

export const LoggerProvider = ({ children }: LoggerContextProviderProps) => {
  const { user } = useAuth()

  const value = useMemo(() => {
    return {
      logger: new SentryLogger(user),
    }
  }, [])

  return (
    <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>
  )
}

/*
 * Hooks
 */

export const useLogger = (): ILogger => {
  const context = useContext(LoggerContext)

  if (context === undefined) {
    throw new Error(`useLogger must be used within an LoggerContext`)
  }

  return context.logger
}
