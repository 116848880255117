import { isEmpty } from 'lodash'
import React from 'react'
import { cx, styled } from '../../../common/stationary'

export interface ResultItemContentProps {
  content: string
  isActive: boolean
}

const ResultItemContent = ({ content, isActive }: ResultItemContentProps) => {
  return (
    <S.Content
      className={cx({
        empty: isEmpty(content),
        isActive,
      })}
    >
      {isEmpty(content) ? `Untitled` : content}
    </S.Content>
  )
}

export default ResultItemContent

const S = {
  Content: styled.div(({ theme }) => ({
    display: `block`,
    flex: 1,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    fontWeight: 500,
    userSelect: `none`,
    color: theme.colors.text[700],

    textOverflow: `ellipsis`,
    overflowX: `hidden`,
    whiteSpace: `nowrap`,

    '&.empty': {
      color: theme.colors.text[700],
    },

    '&.isActive': {
      color: theme.colors.text[900],
    },
  })),
}
