import { ExecAction } from '../useExec'
import Analytics from '../analytics/capture-analytics-actions'
import { Tag, TagType } from '../data/tags'
import { ClickDivEvent } from './types'

export const onTagClick = (
  event: ClickDivEvent,
  exec: ExecAction,
  recordId: string,
  tag: Tag,
) => {
  event.stopPropagation()
  if (tag.type === TagType.DATE) {
    exec({
      type: 'OPEN_SCHEDULE_DIALOG',
      recordId,
      trigger: Analytics.DatePickerFlowStartedTrigger.RECORDITEM_TAG,
    })
  }
  if (tag.type === TagType.PLACE) {
    exec({ type: 'PLACE_TAG_CLICK', tag })
  }
  if (tag.type === TagType.NOTES) {
    exec({
      type: 'TOGGLE_ANNOTATIONS',
      recordId,
      showAnnotationsTrigger:
        Analytics.ShowAnnotationsToggleTrigger.RECORDITEM_BUTTON,
      createAnnotationTrigger:
        Analytics.AnnotationCreatedTrigger.RECORD_ITEM_BUTTON,
    })
  }
}
