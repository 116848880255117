import {
  addDays,
  format,
  isSameDay,
  isToday,
  isTomorrow,
  parseISO,
} from 'date-fns'
import React, { useMemo } from 'react'

import { Icon, useTheme } from '../../../common/stationary'
import { tokenFormatISO } from '../../../common/utils'
import { CalendarDayIcon } from '../../CalendarDayIcon'
import { DatePickerQuickDate, RecordDateModel } from '../Schedule.types'

export const useQuickDates = (
  theme: any,
  onSelect: (value: RecordDateModel) => void,
): DatePickerQuickDate[] => {
  const quickDates: DatePickerQuickDate[] = useMemo(() => {
    const dateToday = new Date()
    const dateTomorrow = addDays(dateToday, 1)
    const dateNextWeek = addDays(dateToday, 7)

    const theme = useTheme()

    return [
      {
        iconColor: theme.colors.green[700],
        icon: <CalendarDayIcon boxSize={20} />,
        onClick: () => onSelect(getQuickDateModel(`Today`)),
        title: `Today`,
        metaText: format(dateToday, `iii`),
        nlpInputText: `Today`,
      },
      {
        iconColor: theme.colors.orange[700],
        icon: <Icon boxSize={20} variant="glyphSun" />,
        onClick: () => onSelect(getQuickDateModel(`Tomorrow`)),
        title: `Tomorrow`,
        metaText: format(dateTomorrow, `iii`),
        nlpInputText: `Tomorrow`,
      },
      {
        iconColor: theme.colors.violet[700],
        icon: <Icon boxSize={20} variant="glyphCalArrowRight" />,
        onClick: () => onSelect(getQuickDateModel(`Next week`)),
        title: `Next Week`,
        metaText: format(dateNextWeek, `EEE MMM d`),
        nlpInputText: `Next week`,
      },
      {
        iconColor: theme.colors.text[700],
        icon: <Icon boxSize={20} variant="glyphCircleStrike" />,
        onClick: () => onSelect(getQuickDateModel(`no date`)),
        title: `No Date`,
        nlpInputText: `No date`,
      },
    ]
  }, [theme, onSelect])

  return quickDates
}

const normalize = (value: string) => value.trim().toLowerCase()

export const getNoDateModel = () => ({
  isoDate: null,
  isoLocalTime: null,
  recurrence: null,
  reminder: null,
})

export function getQuickDateModel(nlpInputText: string): RecordDateModel {
  const dateToday = new Date()
  const dateTomorrow = addDays(dateToday, 1)
  const dateNextWeek = addDays(dateToday, 7)

  const normalizedInput = normalize(nlpInputText)
  const noDate = getNoDateModel()

  if (normalizedInput === `today`)
    return { isoDate: format(dateToday, tokenFormatISO) }
  if (normalizedInput === `tomorrow`)
    return { isoDate: format(dateTomorrow, tokenFormatISO) }
  if (normalizedInput === `next week`)
    return { isoDate: format(dateNextWeek, tokenFormatISO) }
  if (normalizedInput === `no date`) return noDate

  return {}
}

export function isNoDate(nlpInputText: string): boolean {
  const dateModel = getQuickDateModel(nlpInputText)
  return dateModel.isoDate === null
}

export function getQuickDateIndex(isoDate?: string | null): number {
  if (isoDate === undefined) return -1
  if (isoDate === null) return 3

  const date = parseISO(isoDate)
  const dateToday = new Date()
  const dateNextWeek = addDays(dateToday, 7)

  if (isToday(date)) return 0
  if (isTomorrow(date)) return 1
  if (isSameDay(date, dateNextWeek)) return 2

  return -1
}

export function isQuickDate(isoDate?: string | null): boolean {
  const index = getQuickDateIndex(isoDate)
  return index >= 0
}
