import { format, isSameDay, parseISO } from 'date-fns'
import { isArray, isNumber } from 'lodash'
import React, { useCallback } from 'react'
import Calendar, { CalendarTileProperties } from 'react-calendar'
import Sugar from 'sugar'

import {
  RecurrenceProp,
  isDateInRecurrence,
} from '@eleventhlabs/capture-shared'
import {
  convertISOtoDisplay,
  convertReminderMinutesToDisplay,
  tokenFormatISO,
} from '../../../common/utils'
import {
  DatePickerQuickDate,
  RecordDateModel,
  RecurrenceModel,
} from '../Schedule.types'
import DateShortcutItem from './DateShortcutItem'
import NextOcurrencePreview from './NextOcurrencePreview'
import { RecurrenceInput } from './RecurrenceInput'
import { ReminderInput } from './ReminderInput'
import { TimeInput } from './TimeInput'
import {
  Icon,
  calendarStyles,
  calendarTileStyles,
  css,
  cx,
  styled,
  useTheme,
  withTooltip,
} from '../../../common/stationary'

export interface ScheduleBodyProps {
  quickDates: DatePickerQuickDate[]
  value?: string
  selectedIndex?: number
  dateModel: RecordDateModel
  previewModel?: RecordDateModel
  hadInitialRecurrenceOrTime: boolean
  setDate: (isoDate: string | null) => void
  setTime: (time: string | null) => void
  setReminder: (minutesBefore: number | null) => void
  setRecurrence: (recurrence: RecurrenceModel | null) => void
  onShortcutMouseEnter: (index: number) => void
  onShortcutMouseLeave: (index: number) => void
  onPreviewClick: () => void
}

const ScheduleBody = ({
  quickDates,
  value,
  selectedIndex,
  dateModel,
  previewModel,
  hadInitialRecurrenceOrTime,
  setTime,
  setReminder,
  setRecurrence,
  setDate,
  onShortcutMouseEnter,
  onShortcutMouseLeave,
  onPreviewClick,
}: ScheduleBodyProps) => {
  const theme = useTheme()

  const currentDate = Sugar.Date.create(value)

  const onCalendarSelect = useCallback(
    (date: Date | Date[]) => {
      if (isArray(date)) return
      setDate(format(date, tokenFormatISO))
    },
    [setDate],
  )

  const dateValue =
    dateModel.isoDate != undefined ? dateModel.isoDate : undefined
  const timeValue =
    dateModel.isoLocalTime != undefined ? dateModel.isoLocalTime : undefined
  const reminderValue =
    dateModel.reminder?.minutesBefore != undefined
      ? dateModel.reminder.minutesBefore
      : undefined
  const recurrenceValue =
    dateModel.recurrence != undefined ? dateModel.recurrence : undefined

  return (
    <S.Container>
      <S.Date>
        <S.DateLeft>
          <S.DateShortcutGroup>
            <NextOcurrencePreview
              hadInitialRecurrenceOrTime={hadInitialRecurrenceOrTime}
              isMemory={!!previewModel}
              date={previewModel ? previewModel?.isoDate : dateModel.isoDate}
              isoTime={previewModel?.isoLocalTime ?? timeValue}
              recurrence={previewModel?.recurrence ?? recurrenceValue}
              onClick={onPreviewClick}
            />
            {quickDates.map((quickDate, index) => (
              <DateShortcutItem
                key={quickDate.title}
                index={index}
                iconColor={quickDate.iconColor}
                icon={quickDate.icon}
                metaText={quickDate.metaText}
                title={quickDate.title}
                isSelected={selectedIndex === index}
                onClick={quickDate.onClick}
                onMouseEnter={onShortcutMouseEnter}
                onMouseLeave={onShortcutMouseLeave}
              />
            ))}
          </S.DateShortcutGroup>
        </S.DateLeft>
        <S.DateRight>
          <Calendar
            className={cx(css(calendarStyles({ theme })))}
            tileClassName={({ date }: CalendarTileProperties) => {
              return cx(
                {
                  currentDate: dateValue
                    ? isSameDay(date, parseISO(dateValue))
                    : false,
                  futureRecurrent: isDateInRecurrence(
                    date,
                    recurrenceValue as RecurrenceProp,
                    dateValue,
                  ),
                },
                css(calendarTileStyles({ theme })),
              )
            }}
            onChange={onCalendarSelect}
            value={currentDate}
            calendarType="US"
            prev2Label={null}
            next2Label={null}
            prevLabel={<Icon boxSize={16} variant="glyphChevronLeft12" />}
            nextLabel={<Icon boxSize={16} variant="glyphChevronRight12" />}
          />
        </S.DateRight>
      </S.Date>
      <S.BottomControls>
        <TimeInput value={timeValue} setTime={setTime} />
        {isNumber(reminderValue) ? (
          <ReminderInputWithTooltip
            value={reminderValue}
            setReminder={setReminder}
            tooltip={{
              title:
                reminderValue === 0
                  ? `At ${convertISOtoDisplay(timeValue)}`
                  : `${convertReminderMinutesToDisplay(reminderValue)} before`,
            }}
            tippyProps={{
              placement: `top`,
            }}
          />
        ) : (
          <ReminderInput value={reminderValue} setReminder={setReminder} />
        )}
        <S.EmptySpace />
        <RecurrenceInput
          date={dateValue}
          value={recurrenceValue}
          setRecurrence={setRecurrence}
        />
      </S.BottomControls>
    </S.Container>
  )
}

const ReminderInputWithTooltip = withTooltip(ReminderInput)

export default ScheduleBody

const S = {
  Container: styled.div({
    display: `flex`,
    flexDirection: `column`,
  }),
  Date: styled.div({
    display: `flex`,
    flexDirection: `row`,
    width: `100%`,
  }),
  DateLeft: styled.div(({ theme }) => ({
    display: `flex`,
    flex: 1,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderTop: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    borderRight: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    borderBottom: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
  })),
  DateShortcutGroup: styled.ul({
    padding: 8,
    width: `100%`,
  }),
  DateRight: styled.div({
    display: `flex`,
    flex: 1,
  }),
  BottomControls: styled.div({
    display: `flex`,
    flexDirection: `row`,
    padding: 14,
    width: `100%`,
  }),
  EmptySpace: styled.div({
    display: `flex`,
    flex: 1,
  }),
}
