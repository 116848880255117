import React from 'react'

import { Icon, cx, styled, withTooltip } from '../../../../common/stationary'

interface NotesTagProps {
  notesOpen: boolean
  isCompleted: boolean
  text: string
  onClick: (e: any) => void
  className?: string
}

const NotesTag = React.forwardRef<HTMLDivElement, NotesTagProps>(
  ({ notesOpen, isCompleted, onClick, className }, ref) => {
    return (
      <S.Container
        onClick={onClick}
        className={cx(
          className,
          {
            open: notesOpen,
          },
          { completed: isCompleted },
        )}
        ref={ref}
      >
        <S.Icon boxSize={16} variant="glyphBlurbSquircle" className="icon" />
      </S.Container>
    )
  },
)

export const NotesTagWithTooltip = withTooltip(NotesTag)

const S = {
  Container: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    textAlign: `center`,
    marginRight: 2,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 500,
    lineHeight: `16px`,
    whiteSpace: `nowrap`,
    userSelect: `none`,
    color: theme.colors.text[700],
    cursor: `pointer`,
    height: 24,

    '&:hover': {
      color: theme.colors.text[900],
      opacity: 1,

      '& .icon': {
        color: theme.colors.text[900],
        opacity: 1,
      },
    },

    '&.open': {
      opacity: 1,
      color: theme.colors.text[900],

      '& .icon': {
        color: theme.colors.text[900],
        opacity: 1,
      },
    },

    '&.completed': {
      color: theme.colors.text[500],
    },
  })),
  Icon: styled(Icon)(({ theme }) => ({
    color: theme.colors.text[700],
  })),
}
