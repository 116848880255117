import React from 'react'

import Analytics from '../../common/analytics/capture-analytics-actions'
import useScreenLoadedAnalytics from '../../common/analytics/helpers/hooks/useScreenLoadedAnalytics'
import { useUIContext } from '../../common/contexts/UIContext'
import { RecordGroupType } from '../../common/data/recordGroups'
import { useDragToSelectRecords } from '../../common/dragToSelect/useDragToSelect'
import { useScrollWindowAtEdgesOnDrag } from '../../common/hooks/listeners/useScrollWindowAtEdgesOnDrag'
import { RouteType } from '../../common/routes'
import {
  screenInnerStyles,
  screenStyles,
  styled,
} from '../../common/stationary'
import { isMobile } from '../../common/utils/env'
import { FileDropOverlay } from '../../components/FileDropOverlay'
import Header from '../../components/Header'
import { RecordGroup } from '../../components/RecordGroup/RecordGroup'
import {
  EmptyStateText,
  RecordGroupEmptyState,
} from '../../components/RecordGroupEmptyState'
import { ClickOutsideListener } from '../../components/helpers/ClickOutsideListener'
import { withDragLayer } from '../enhancers/withDragLayer'
import { withFloatingActions } from '../enhancers/withFloatingActions'
import { withRecordGroupHotKeys } from '../enhancers/withRecordGroupHotKeys'
import { withRecordGroupScreen } from '../enhancers/withRecordGroupScreen'
import { CommonRecordGroupScreenProps } from '../types'
import InboxHeader from './components/InboxHeader'

export type InboxProps = Record<string, unknown>

export type InboxScreenProps = InboxProps & CommonRecordGroupScreenProps

const InboxScreen = ({
  createFileRecordAtStartOfGroup,
  className,
  focusedId,
  groups,
  miniBarHandlers,
  noteHandlers,
  onClickOutside,
  recordHandlers,
  routeType,
  selectedIds,
  shouldDisplayNotesForRecordId,
  createRecordAtStartOfGroup,
  uploadProgress,
  onTouchStart,
  onTouchMove,
  isDragActive,
}: InboxScreenProps) => {
  const { isMouseRecentlyActive } = useUIContext()

  const dragBoundaryRef = useScrollWindowAtEdgesOnDrag<HTMLDivElement>()

  useScreenLoadedAnalytics(Analytics.ScreenType.INBOX, RouteType.Inbox)

  useDragToSelectRecords(dragBoundaryRef)

  return (
    <S.InboxScreen
      ref={dragBoundaryRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
    >
      {isDragActive && <FileDropOverlay />}
      <Header />
      <ClickOutsideListener onClickOutside={onClickOutside}>
        <S.InboxScreenInner className={className}>
          <InboxHeader id={RouteType.Inbox} />
          {groups.map((group) => (
            <RecordGroup
              createFileRecordAtStartOfGroup={createFileRecordAtStartOfGroup}
              createRecordAtStartOfGroup={createRecordAtStartOfGroup}
              emptyStateRenderer={() => (
                <RecordGroupEmptyState>
                  <EmptyStateText>
                    Nothing in <strong>Inbox</strong>
                  </EmptyStateText>
                </RecordGroupEmptyState>
              )}
              focusedId={focusedId}
              isMouseActive={isMouseRecentlyActive}
              key={`${group.type}-${group.id}`}
              miniBarHandlers={miniBarHandlers}
              noteHandlers={noteHandlers}
              recordHandlers={recordHandlers}
              routeType={routeType}
              selectedIds={selectedIds}
              shouldDisplayNotesForRecordId={shouldDisplayNotesForRecordId}
              showCaptureButtons={
                !isMobile && group.type !== RecordGroupType.ListComplete
              }
              softHeader={group.type === RecordGroupType.ListComplete}
              uploadProgress={uploadProgress}
              {...group}
            />
          ))}
        </S.InboxScreenInner>
      </ClickOutsideListener>
    </S.InboxScreen>
  )
}

export default withFloatingActions(
  withDragLayer(withRecordGroupScreen(withRecordGroupHotKeys(InboxScreen))),
)

const S = {
  InboxScreen: styled.div({
    ...screenStyles,
  }),
  InboxScreenInner: styled.div({
    ...screenInnerStyles,
  }),
}
