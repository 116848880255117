import React from 'react'
import { useUIContext } from '../../common/contexts/UIContext'
import { styled } from '../../common/stationary'

import QuestionMarkIcon from '../../assets/icon/icon-question-mark.svg'
import XIcon from '../../assets/icon/icon-x.svg'
import { isMobile } from '../../common/utils/env'

export const FloatingHelpCenterButton: React.FC = () => {
  const { isRightHidden, toggleIsRightHidden } = useUIContext()

  if (isMobile) return null

  return (
    <S.QuestionIconContainer onClick={toggleIsRightHidden}>
      {isRightHidden ? <S.QuestionIcon /> : <S.XIcon />}
    </S.QuestionIconContainer>
  )
}

export const FloatingHelpCenterButtonDimensions = {
  width: 32,
  height: 32,
}

export const FloatingHelpCenterButtonSpacing = {
  bottom: 24,
  right: 24,
}

const S = {
  QuestionIconContainer: styled.div(({ theme }) => ({
    all: 'unset',
    display: 'flex',
    width: FloatingHelpCenterButtonDimensions.width,
    height: FloatingHelpCenterButtonDimensions.height,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
    background: theme.colors.alpha.tone.solid,

    position: 'fixed',
    right: FloatingHelpCenterButtonSpacing.right,
    bottom: FloatingHelpCenterButtonSpacing.bottom,
    zIndex: 500,
    boxShadow: theme.effects.boxShadow.elevation[3],
    cursor: 'pointer',
  })),
  QuestionIcon: styled(QuestionMarkIcon)(({ theme }) => ({
    width: 20,
    height: 20,

    path: {
      fill: theme.colors.alpha.invertedTone.solid,
    },
    cursor: 'pointer',
  })),
  XIcon: styled(XIcon)(({ theme }) => ({
    width: 20,
    height: 20,

    path: {
      fill: theme.colors.alpha.invertedTone.solid,
    },
    cursor: 'pointer',
  })),
}
