import { useCallback } from 'react'

import { useExecContext } from '../../contexts/ExecContext'
import { NoteRichTextEventHandlers } from './NoteRichTextEventHandlers'
import { onBackspaceWhenEmpty as handleBackspaceWhenEmpty } from './onBackspaceWhenEmpty'
import { onKeyDown as handleKeyDown } from './onKeyDown'

export const useNoteRichTextEventHandlers = (): NoteRichTextEventHandlers => {
  const { exec } = useExecContext()

  const onArrowRightOrDownAtBoundary = useCallback(
    (noteId: string) => {
      exec({ type: 'NOTE_ARROW_RIGHT_OR_DOWN_AT_BOUNDARY', noteId })
      return true
    },
    [exec],
  )

  const onArrowLeftOrUpAtBoundary = useCallback(
    (noteId: string) => {
      exec({ type: 'NOTE_ARROW_LEFT_OR_UP_AT_BOUNDARY', noteId })
      return true
    },
    [exec],
  )

  const onBackspaceWhenEmpty = useCallback(
    (noteId: string) => {
      return handleBackspaceWhenEmpty(exec, noteId)
    },
    [exec],
  )

  const onChange = useCallback(
    (richText, noteId) => {
      exec({ type: 'NOTE_CHANGE', noteId, richText })
    },
    [exec],
  )

  const onKeyDown = useCallback(
    (
      e: React.KeyboardEvent<HTMLDivElement>,
      noteId: string,
      recordId: string,
    ) => {
      return handleKeyDown(e, exec, noteId, recordId)
    },
    [exec],
  )

  return {
    onArrowRightOrDownAtBoundary,
    onArrowLeftOrUpAtBoundary,
    onBackspaceWhenEmpty,
    onChange,
    onKeyDown,
  }
}
