import { WriteTransaction } from 'replicache'

import { OnboardingTrack, RCMutation } from '@eleventhlabs/capture-shared'
import { replicacheUserRepository } from '../replicacheUserRepository'
import { cloneDeep, set } from 'lodash'
import { IDBUser } from '../models/IDBUser'

export const userSetOnboardingSavePoint = async (
  tx: WriteTransaction,
  { executedAtOnClient, track, slide }: RCMutation.UserSetOnboardingSavePoint,
) => {
  const user = await replicacheUserRepository.getUser(tx)
  const updatedUser = createUserWithSavePoint(
    user,
    track,
    slide,
    executedAtOnClient,
  )
  await replicacheUserRepository.putUser(tx, updatedUser)
}

function createUserWithSavePoint(
  user: IDBUser,
  track: OnboardingTrack,
  slide: string,
  savedAt: number,
): IDBUser {
  const obj = cloneDeep(user)
  initializeTrackIfNotInitialized(obj, track)
  const path = ['data', 'onboarding', 'track', track, 'savePoints', slide]
  const value = savedAt
  return set(obj, path, value)
}

function initializeTrackIfNotInitialized(u: IDBUser, track: OnboardingTrack) {
  const isTrackNotInitialized = u.data?.onboarding?.track === undefined
  if (isTrackNotInitialized) initializeTrack(u, track)
}

function initializeTrack(u: IDBUser, track: OnboardingTrack) {
  set(u, `data.onboarding.track.${track}`, {
    savePoints: {},
    completedSlides: {},
    isComplete: true,
  })
}
