import { first } from 'lodash'

import { EditorsManager } from '../../../../EditorsManager'
import { focusPrev } from '../../../../noteEventHandlers/richTextEventHandlers/focusHelpers'
import { isNoteableRecord, RecordDict } from '@eleventhlabs/capture-shared'

export const noteArrowLeftOrUpAtBoundary = (
  records: RecordDict,
  noteId: string,
  recordId: string | undefined,
) => {
  if (!recordId) return
  const record = records[recordId]
  const noteIds = isNoteableRecord(record) ? record.notes : []
  if (first(noteIds) !== noteId) {
    EditorsManager.effects.blur(noteId)
    focusPrev(noteId, noteIds)
  } else {
    EditorsManager.effects.blur(noteId)
    if (recordId)
      EditorsManager.effects.focus({ id: recordId, position: `end` })
  }
}
