import React, { useCallback } from 'react'

import { HandleResultPayload, ResultGroupItem } from '../CommandBar.types'
import { cx, styled } from '../../../../common/stationary'

export interface ResultItemProps {
  item: ResultGroupItem
  isActive: boolean
  className?: string
  onHover?: (id: string) => void
  handleResultClick: (payload: HandleResultPayload) => void
}

const ResultItem = ({
  item,
  isActive,
  className,
  onHover,
  handleResultClick,
}: ResultItemProps) => {
  const onClick = useCallback(() => {
    handleResultClick({ id: item.id, trigger: `click` })
  }, [item, handleResultClick])

  const onMouseEnter = useCallback(() => {
    onHover && onHover(item.id)
  }, [item, onHover])

  return (
    <S.Row
      className={cx(className, { isActive })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {item.renderPre}
      {item.renderContent}
      {item.renderPost}
    </S.Row>
  )
}

export default ResultItem

export const ResultItemDimensions = {
  Action: {
    height: 48,
  },
  Group: {
    height: 40,
  },
}

const S = {
  Row: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    height: `100%`,
    width: `100%`,
    alignItems: `center`,
    cursor: `pointer`,
    boxSizing: `border-box`,
    overflowX: `hidden`,
    borderLeft: `3px solid transparent`,

    '&.isActive': {
      borderLeft: `3px solid ${theme.colors.blue[700]}`,
      background: theme.colors.alpha.tone.weak,
    },
  })),
}
