import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

import { selectAction, subscribeToSelectEvent } from '../../common/events'
import { emptyFn, immediateExecute } from '../../common/utils'

import { Button, css, cx, styled, useTheme } from '../../common/stationary'

export interface DialogInputProps {
  inputRef?: React.RefObject<HTMLInputElement>
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  inputValue?: string
  validInput?: boolean
  className?: string
  onSubmit?: () => void
}

// const sizeOverride: AugmentedControlAttributes['sizeOverride'] = {
//   dimension: {
//     height: 40,
//     paddingHorizontal: 4,
//   },
//   style: {},
// }

const DialogInput = ({
  inputRef,
  onKeyDown,
  onChange,
  placeholder,
  inputValue,
  validInput,
  className,
  onSubmit = emptyFn,
}: DialogInputProps) => {
  //
  const theme = useTheme()

  useEffect(() => {
    return subscribeToSelectEvent(({ payload }) => {
      if (payload.target === `dialog-input`) {
        if (inputRef?.current) {
          inputRef.current.focus()
          inputRef.current.select()
        }
        return true
      }
      return false
    })
  }, [inputRef])

  useEffect(() => {
    if (!isEmpty(inputValue))
      immediateExecute(() => selectAction({ target: `dialog-input` }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.DialogInput className={className}>
      <S.Input
        autoFocus
        // classNameInner={css({
        //   ...theme.text.publicSans['19:28'],
        //   color: theme.colors.text[900],
        //   outline: `none`,
        // })}
        placeholder={placeholder}
        ref={inputRef}
        value={inputValue}
        // sizeOverride={sizeOverride}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      {validInput !== undefined ? (
        <S.UpdateButton
          variant="solid"
          className={cx({
            disabled: !validInput,
          })}
          disabled={!validInput}
          onClick={onSubmit}
        >
          Update
        </S.UpdateButton>
      ) : null}
    </S.DialogInput>
  )
}

export default DialogInput

const S = {
  DialogInput: styled.div({
    display: `flex`,
    flexDirection: `row`,
    padding: `8px 0`,
  }),
  Input: styled.input(({ theme }) => ({
    display: `flex`,
    flex: `1`,
    // fontSize: '40px',
    ...theme.text.publicSans['19:28'],
  })),
  UpdateButton: styled(Button)(({ theme }) => ({
    padding: `6px 14px`,
    borderRadius: 6,
  })),
}
