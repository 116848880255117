import { last } from 'lodash'

import { EditorsManager } from '../../../../EditorsManager'
import { focusNext } from '../../../../noteEventHandlers/richTextEventHandlers/focusHelpers'
import { isNoteableRecord, RecordDict } from '@eleventhlabs/capture-shared'

export const noteArrowRightOrDownAtBoundary = (
  records: RecordDict,
  noteId: string,
  recordId: string | undefined,
  recordIds: string[],
) => {
  if (!recordId) return
  const record = records[recordId]
  const noteIds = isNoteableRecord(record) ? record.notes : []
  if (last(noteIds) !== noteId) {
    EditorsManager.effects.blur(noteId)
    focusNext(noteId, noteIds)
  } else {
    EditorsManager.effects.blur(noteId)
    if (recordId) focusNext(recordId, recordIds)
  }
}
