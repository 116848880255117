import { findIndex, first, flatMap, last } from 'lodash'
import { useMemo } from 'react'

import {
  RecordGroups as RGHelpers,
  RecordGroupData,
  RecordGroupsCommands,
} from '../data/recordGroups'
import {
  BaseSelection,
  Ordered,
  Reorderable,
  SelectionInteractions,
  createSelectionInteractions,
} from '../libs/selection'
import { createRecordGroupsOrdering } from '../libs/selection/ordered'

export const useRecordGroupsSelectionInteractions = (
  selection: BaseSelection,
  recordGroups: RecordGroupData[],
  recordGroupCommands: RecordGroupsCommands,
): SelectionInteractions => {
  const structure = useMemo(
    () => createRecordGroupsOrderedStructure(recordGroups, recordGroupCommands),
    [recordGroups, recordGroupCommands],
  )
  return useMemo(
    () => createSelectionInteractions(selection, structure),
    [selection, structure],
  )
}

export const useRecordGroupsOrdering = (
  recordGroups: RecordGroupData[],
): Ordered => {
  return useMemo(() => createRecordGroupsOrdering(recordGroups), [recordGroups])
}

type InsertPositionType =
  | `startOfGroup`
  | `endOfGroup`
  | `aboveAnchor`
  | `belowAnchor`
type InsertPosition = { type: InsertPositionType } & (
  | { type: `startOfGroup`; groupId: string }
  | { type: `endOfGroup`; groupId: string }
  | { type: `aboveAnchor`; anchorId: string }
  | { type: `belowAnchor`; anchorId: string }
)

const createRecordGroupsOrderedStructure = (
  recordGroups: RecordGroupData[],
  recordGroupCommands: RecordGroupsCommands,
): Ordered & Reorderable<InsertPosition> => {
  const recordIds = flatMap(recordGroups, (g) => g.items.map((i) => i.id))
  return {
    firstItem: first(recordIds),
    getNextItem: (id: string) => RGHelpers.getNextItem(recordGroups, id),
    getPrevItem: (id: string) => RGHelpers.getPrevItem(recordGroups, id),
    isEmpty: () => recordIds.length === 0,
    lastItem: last(recordIds),
    getInsertPositionAfterTarget: (id: string): InsertPosition | undefined => {
      const groupIndex = findIndex(recordGroups, (g) =>
        g.items.map((i) => i.id).includes(id),
      )
      const group = recordGroups[groupIndex]
      const isLastRecordInGroup = last(group?.items.map((i) => i.id)) === id
      if (isLastRecordInGroup) {
        const nextGroup = recordGroups[groupIndex + 1]
        if (nextGroup === undefined) return
        else return { type: `startOfGroup`, groupId: nextGroup.id }
      } else {
        return {
          type: `belowAnchor`,
          anchorId: RGHelpers.getNextItem(recordGroups, id) as string,
        }
      }
    },
    getInsertPositionBeforeTarget: (id: string): InsertPosition | undefined => {
      const groupIndex = findIndex(recordGroups, (g) =>
        g.items.map((i) => i.id).includes(id),
      )
      const group = recordGroups[groupIndex]
      const isFirstRecordInGroup = first(group?.items.map((i) => i.id)) === id
      if (isFirstRecordInGroup) {
        const prevGroup = recordGroups[groupIndex - 1]
        if (prevGroup === undefined) return
        else return { type: `endOfGroup`, groupId: prevGroup.id }
      } else {
        return {
          type: `aboveAnchor`,
          anchorId: RGHelpers.getPrevItem(recordGroups, id) as string,
        }
      }
    },
    moveItemsToInsertPosition: (
      ids: string[],
      position: InsertPosition,
    ): void => {
      if (position.type === `startOfGroup`) {
        recordGroupCommands.moveToStartOfGroup(ids, position.groupId)
        return
      } else if (position.type === `endOfGroup`) {
        recordGroupCommands.moveToEndOfGroup(ids, position.groupId)
        return
      } else if (position.type === `aboveAnchor`) {
        recordGroupCommands.moveAboveAnchor(ids, position.anchorId)
      } else if (position.type === `belowAnchor`) {
        recordGroupCommands.moveBelowAnchor(ids, position.anchorId)
      } else throw new Error(``)
    },
  }
}
