import { EditorsManager } from '../../../../EditorsManager'
import { ws, WSEventName } from '../../../../websocket'
import { getPrivateUserChannelName } from '../../../../websocket/utils'

export const recordFocused = (
  userId: string,
  recordId: string,
  setFocusedRecordId: (recordId: string) => void,
  clearSelection: () => void,
) => {
  EditorsManager.analytics.register(recordId)

  setFocusedRecordId(recordId)
  clearSelection()

  ws.sendClientEvent(getPrivateUserChannelName(userId), {
    name: WSEventName.RECORD_EDITING_STARTED,
    payload: {
      recordId,
    },
  })
}
