import { useEffect } from 'react'

import { RecordGroupData } from '../data/recordGroups'
import { useKeepActiveRecordIntoView } from '../hooks/scroll/useKeepActiveRecordIntoView'
import { useKeepDefaultIdValid } from '../hooks/scroll/useKeepDefaultIdValid'
import { useKeepOnlyValidSelected } from '../hooks/scroll/useKeepOnlyValidSelected'
import { BaseSelection } from '../libs/selection'
import { RecordDict } from '@eleventhlabs/capture-shared'
import { useRemoveCompletedFromSelectionOnToggle } from './useRemoveCompletedFromSelectionOnToggle'

export const useCommonSelectionBehavior = (
  selection: BaseSelection,
  focusedRecordId: string | undefined,
  recordGroups: RecordGroupData[],
  records: RecordDict,
  showCompleted: boolean,
) => {
  useEffect(() => {
    selection.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useRemoveCompletedFromSelectionOnToggle(selection, records, showCompleted)
  useKeepActiveRecordIntoView(focusedRecordId)
  useKeepOnlyValidSelected(recordGroups)
  useKeepDefaultIdValid(recordGroups)
}
