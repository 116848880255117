import React from 'react'

import { KeyboardShortcutsList } from './components/KeyboardShortcutsList'

import QuestionMarkIcon from '../../assets/icon/icon-question-mark.svg'
import EnvelopeIcon from '../../assets/icon/icon-envelope.svg'
import DownloadIcon from '../../assets/icon/icon-download.svg'

import { styled } from '../../common/stationary'
import { useModal } from '../../common/modals/core/hooks/useModal'
import { ModalName } from '../../common/modals/core/modalConfig'

export interface KeyboardShortcutsProps {}

export const KeyboardShortcutsPane: React.FC<KeyboardShortcutsProps> = () => {
  const { openModal } = useModal()

  const onInstallPlatformsClick = () => {
    openModal(ModalName.DOWNLOADS_MODAL)
  }

  return (
    <S.Content>
      <S.Header>
        <S.QuestionIconContainer>
          <S.QuestionIcon />
        </S.QuestionIconContainer>
        <S.Title>Help &amp; Keyboard</S.Title>
      </S.Header>
      <S.ContactUsPrompt>
        <S.ContextBoxRow onClick={onInstallPlatformsClick}>
          <S.DownloadIcon />
          <strong>Install the apps/extensions</strong>
        </S.ContextBoxRow>
        <S.ContentBoxLinkRow href="mailto:membership@capture.so">
          <S.EnvelopeIcon />
          Share feedback
        </S.ContentBoxLinkRow>
      </S.ContactUsPrompt>
      <KeyboardShortcutsList />
    </S.Content>
  )
}

const S = {
  Content: styled.div(({ theme }) => ({
    position: `relative`,
    display: `flex`,
    width: `100%`,
    height: `100%`,
    flexDirection: `column`,
    alignItems: 'center',
    background: theme.colors.surface.base,
    borderLeft: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
  })),
  Close: styled.div(({ theme }) => ({
    position: `absolute`,
    display: `block`,
    top: 16,
    right: 16,
    padding: `2px`,
    color: theme.colors.text[500],
    cursor: `pointer`,
  })),
  Header: styled.div({
    display: `flex`,
    width: `100%`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 20,
    gap: 6,
  }),
  QuestionIconContainer: styled.div(({ theme }) => ({
    display: 'flex',
    width: 16,
    height: 16,
    padding: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
    background: `#D9D9D9`,
  })),
  QuestionIcon: styled(QuestionMarkIcon)(({ theme }) => ({
    width: 12,
    height: 12,
  })),
  Title: styled.div(({ theme }) => ({
    display: `flex`,
    flex: 1,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 400,
    color: theme.colors.text[900],
  })),
  ContactUsPrompt: styled.div(({ theme }) => ({
    width: 272,
    height: 72,
    flexShrink: 0,
    borderRadius: 8,
    background: theme.colors.surface.lowest,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 4,
    paddingBottom: 4,

    '& > *': {
      opacity: 1,
    },

    color: theme.colors.text[700],
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 400,

    '& a': {
      textDecoration: `underline`,
    },
  })),
  ContextBoxRow: styled.div(() => ({
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    gap: 6,
    paddingLeft: 16,
    paddingTop: 6,
    paddingBottom: 6,
    cursor: `pointer`,

    strong: {
      fontWeight: 600,
    },
  })),
  ContentBoxLinkRow: styled.a(() => ({
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    gap: 6,
    paddingLeft: 16,
    paddingTop: 6,
    paddingBottom: 6,
    cursor: `pointer`,
    textDecoration: 'none !important',

    strong: {
      fontWeight: 600,
    },
  })),
  EnvelopeIcon: styled(EnvelopeIcon)(() => ({
    height: 16,
    cursor: 'pointer',
  })),
  DownloadIcon: styled(DownloadIcon)(() => ({
    height: 16,
    cursor: 'pointer',
  })),
}
