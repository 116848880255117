import { ApiEndpoints } from '../../../../ApiEndpoints'
import { apiCall } from '../../../../api/client'

export const viewJWT = (username: string) => {
  apiCall(`${ApiEndpoints.rpcURI}/legacyRaycastJWT`, {
    method: `GET`,
    mode: `cors`,
    headers: {
      'Content-Type': `application/json`,
    },
  }).then(async (response) => {
    const responseJson = await response.json()
    window.prompt(`${username}'s JWT:`, responseJson.token ?? undefined)
  })
}
