import { isFileRecord, RecordDict } from '@eleventhlabs/capture-shared'

export const fileRecordOpenSrcInTab = (
  records: RecordDict,
  recordId: string,
) => {
  const record = records[recordId]
  if (isFileRecord(record)) window.open(record.url, `_blank`)
  else throw new Error(`Unexpected record type`)
}
