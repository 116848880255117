import { first, last } from 'lodash'

import { EditorsManager } from '../../EditorsManager'

export const focusNext = (id: string, list: string[]) => {
  if (last(list) === id) return false
  const nextId = list[list.indexOf(id) + 1]
  EditorsManager.effects.focus({
    id: nextId,
    position: `start`,
  })
  return true
}

export const focusPrev = (id: string, list: string[]) => {
  if (first(list) === id) return false
  const prevId = list[list.indexOf(id) - 1]
  EditorsManager.effects.focus({ id: prevId, position: `end` })
  return true
}
