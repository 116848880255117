import { CommandId } from '../CommandId'
import {
  KeyMappedActionIdType,
  keysFromActionType,
  useKeyMappedActions,
} from '../../keyMappableActions'
import { IconVariantName } from '../../stationary'
import {
  ADD_NOTE,
  CLOSE_FULLSREEN,
  COMPLETE,
  COMPLETED,
  FULLSCREEN,
  HIDE_COMPLETED,
  HIDE_NOTE,
  NOTE,
  OPEN_FULLSCREEN,
  RECORD,
  REMOVE_NOTE,
  SHOW_COMPLETED,
  SHOW_NOTE,
  UNCOMPLETE,
} from '../../utils/uiConstants'
import { useCallback, useMemo } from 'react'
import { isMobile } from '../../utils/env'

export interface CommandBarHandlers {
  onDownArrow: (event: KeyboardEvent) => void
  onUpArrow: (event: KeyboardEvent) => void
  onEnter: (event: KeyboardEvent) => void
  onEsc: (event: KeyboardEvent) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onHover?: (id: string) => void
  handleSearchInputKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void
  handleResultClick: (payload: HandleResultPayload) => void
}

export interface HandleResultPayload {
  id?: string
  trigger: `click` | `keyboard`
}

export interface ResultGroup {
  items: ResultGroupItem[]
}

export enum ItemType {
  Action = `Action`,
  Group = `Group`,
}

export interface ResultGroupItem {
  id: string
  type: ItemType
  renderPre?: JSX.Element
  renderContent?: JSX.Element
  renderPost?: JSX.Element
  isSelected?: (index: number) => boolean
}

export interface ResultListItemData {
  items: ResultGroupItem[]
  onHover?: (id: string) => void
  handleResultClick: (payload: HandleResultPayload) => void
}

export interface GroupItem {
  id: string
  type: ItemType
  title: string
  keywords: string[]
  shortcut?: string[]
  filters: CommandFilter[]
  icon: IconVariantName
}

enum Domain {
  RECORD = `RECORD`,
  COLLECTION = `COLLECTION`,
  VIEW = `VIEW`,
  NAVIGATION = `NAVIGATION`,
  HELP = `HELP`,
  ACCOUNT = `ACCOUNT`,
}

export interface CommandBarDomain {
  id: Domain
  title: string
  commands: CommandBarCommand[]
}

export interface CommandBarCommand {
  id: CommandId
  label: string
  keywords: string[]
  filters: CommandFilter[]
  shouldStayOpen?: boolean
  icon: IconVariantName
  shortcut?: string[]
}

export enum CommandFilter {
  AT_LEAST_ONE_SELECTION = `AT_LEAST_ONE_SELECTION`,
  AT_LEAST_ONE_COLLAPSED = 'AT_LEAST_ONE_COLLAPSED',
  AT_LEAST_ONE_NOTE_CLOSED_OR_NO_NOTES = `AT_LEAST_ONE_NOTE_CLOSED_OR_NO_NOTES`,
  ALL_HAVE_NOTES_AND_OPEN = `ALL_HAVE_NOTES_AND_OPEN`,
  NONE_COLLAPSED = `NONE_COLLAPSED`,
  RECORD_HAS_ANNOTATIONS = `RECORD_HAS_ANNOTATIONS`,
  RECORD_HAS_NO_ANNOTATIONS = `RECORD_HAS_NO_ANNOTATIONS`,
  RECORD_HAS_ANNOTATIONS_OPEN = `RECORD_HAS_ANNOTATIONS_OPEN`,
  RECORD_HAS_ANNOTATIONS_CLOSED = `RECORD_HAS_ANNOTATIONS_CLOSED`,
  RECORDS_CAN_BE_CONVERTED_TO_URL = `RECORDS_CAN_BE_CONVERTED_TO_URL`,
  RECORDS_CAN_BE_CONVERTED_TO_TEXT = `RECORDS_CAN_BE_CONVERTED_TO_TEXT`,
  RECORD_HAS_DATE = `RECORD_HAS_DATE`,
  SINGLE_SELECTION = `SINGLE_SELECTION`,
  RECORD_TYPE_IS_FILE = `RECORD_TYPE_IS_FILE`,
  ACTIVE_SCREEN_IS_COLLECTION = `ACTIVE_SCREEN_IS_COLLECTION`,
  ACTIVE_SCREEN_IS_TODAY = `ACTIVE_SCREEN_IS_TODAY`,
  ACTIVE_SCREEN_IS_TRASH = `ACTIVE_SCREEN_IS_TRASH`,
  ACTIVE_SCREEN_IS_COLLECTION_INBOX = `ACTIVE_SCREEN_IS_COLLECTION_INBOX`,
  ACTIVE_SCREEN_IS_NOT_INBOX = `ACTIVE_SCREEN_IS_NOT_INBOX`,
  ACTIVE_SCREEN_IS_NOT_TODAY = `ACTIVE_SCREEN_IS_NOT_TODAY`,
  ACTIVE_SCREEN_IS_NOT_TRASH = `ACTIVE_SCREEN_IS_NOT_TRASH`,
  LIGHT_MODE_ACTIVE = `LIGHT_MODE_ACTIVE`,
  DARK_MODE_ACTIVE = `DARK_MODE_ACTIVE`,
  ACTIVE_SCREEN_IS_FULLSCREEN = `ACTIVE_SCREEN_IS_FULLSCREEN`,
  ACTIVE_SCREEN_IS_NOT_FULLSCREEN = `ACTIVE_SCREEN_IS_NOT_FULLSCREEN`,
  AT_LEAST_ONE_UNCOMPLETED = `AT_LEAST_ONE_UNCOMPLETED`,
  ALL_COMPLETED = `ALL_COMPLETED`,
  ACTIVE_SCREEN_IS_RECORD_GROUP = `ACTIVE_SCREEN_IS_RECORD_GROUP`,
  SHOWING_COMPLETED = `SHOWING_COMPLETED`,
  NOT_SHOWING_COMPLETED = `NOT_SHOWING_COMPLETED`,
  SIDEBAR_COLLAPSED = `SIDEBAR_COLLAPSED`,
  SIDEBAR_EXPANDED = `SIDEBAR_EXPANDED`,
  SHOWING_EMPTY_DATES = `SHOWING_EMPTY_DATES`,
  NOT_SHOWING_EMPTY_DATES = `NOT_SHOWING_EMPTY_DATES`,
  SHOWING_PAST_DATES = `SHOWING_PAST_DATES`,
  NOT_SHOWING_PAST_DATES = `NOT_SHOWING_PAST_DATES`,
}

// IMPORTANT: filters work with an AND premise
export const useCommandBarDomains = (): CommandBarDomain[] => {
  const keyMappedActions = useKeyMappedActions()
  const getKeys = useCallback(
    (id: KeyMappedActionIdType) => {
      return keysFromActionType(id, keyMappedActions)
    },
    [keyMappedActions],
  )

  const filterCommands = (commands: CommandBarCommand[]) => {
    return commands.filter(
      (command) =>
        !(isMobile && command.id === CommandId.TOGGLE_KEYBOARD_SHORTCUTS_PANE),
    )
  }

  return useMemo(() => {
    const domains: CommandBarDomain[] = [
      {
        id: Domain.RECORD,
        title: `Capture`,
        commands: filterCommands([
          {
            id: CommandId.CREATE_RECORD,
            label: `Quick ${RECORD}`,
            keywords: [`create`, `new`, `record`, `quick`, RECORD],
            icon: `glyphPlus`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_NOT_TRASH],
            shortcut: getKeys('KEY_MAPPED_CREATE_RECORD_AT_TOP'),
          },
          {
            id: CommandId.RECORDS_MARK_DONE,
            label: COMPLETE,
            keywords: [`mark`, `as`, `done`, COMPLETE],
            icon: `glyphCheckCircle`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.AT_LEAST_ONE_UNCOMPLETED,
            ],
            shortcut: getKeys('KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE'),
          },
          {
            id: CommandId.RECORDS_MARK_NOT_DONE,
            label: UNCOMPLETE,
            keywords: [`mark`, `as`, `not`, `done`, UNCOMPLETE],
            icon: `glyphArrowNCircle`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.ALL_COMPLETED,
            ],
            shortcut: getKeys('KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE'),
          },
          {
            id: CommandId.OPEN_MOVE_TO_DIALOG,
            label: `Move to`,
            keywords: [`move`, `to`],
            icon: `glyphArrowESquircle`,
            filters: [CommandFilter.AT_LEAST_ONE_SELECTION],
            shouldStayOpen: true,
            shortcut: getKeys('KEY_MAPPED_OPEN_MOVE_TO_DIALOG'),
          },
          {
            id: CommandId.OPEN_SCHEDULE_DIALOG,
            label: `Schedule`,
            keywords: [`schedule`],
            icon: `glyphCalendarSingleSquircle`,
            filters: [CommandFilter.AT_LEAST_ONE_SELECTION],
            shouldStayOpen: true,
            shortcut: getKeys('KEY_MAPPED_OPEN_SCHEDULE_TO_DIALOG'),
          },
          {
            id: CommandId.RECORD_ADD_NOTE,
            label: ADD_NOTE,
            keywords: [`Add`, `note`, NOTE, ADD_NOTE],
            icon: `glyphPlus`,
            filters: [
              CommandFilter.SINGLE_SELECTION,
              CommandFilter.RECORD_HAS_NO_ANNOTATIONS,
            ],
          },
          {
            id: CommandId.RECORD_ITEMS_SHOW_NOTES,
            label: SHOW_NOTE,
            keywords: [`show`, `hide`, `note`, NOTE, SHOW_NOTE],
            icon: `glyphEyeOpen`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.AT_LEAST_ONE_NOTE_CLOSED_OR_NO_NOTES,
            ],
            shortcut: getKeys('KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN'),
          },
          {
            id: CommandId.RECORD_ITEMS_HIDE_NOTES,
            label: HIDE_NOTE,
            keywords: [`show`, `hide`, `note`, NOTE, HIDE_NOTE],
            icon: `glyphLightning`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.ALL_HAVE_NOTES_AND_OPEN,
            ],
            shortcut: getKeys('KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN'),
          },
          {
            id: CommandId.RECORD_ITEMS_EXPAND,
            label: `Expand`,
            keywords: [`expand`],
            icon: `glyphLightning`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.AT_LEAST_ONE_COLLAPSED,
            ],
            shortcut: getKeys('KEY_MAPPED_RECORD_TOGGLE_EXPANDED'),
          },
          {
            id: CommandId.RECORD_ITEMS_COLLAPSE,
            label: `Collapse`,
            keywords: [`collapse`],
            icon: `glyphLightning`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.NONE_COLLAPSED,
            ],
            shortcut: getKeys('KEY_MAPPED_RECORD_TOGGLE_EXPANDED'),
          },
          {
            id: CommandId.RECORDS_MOVE_TO_TRASH,
            label: `Move to trash`,
            keywords: [`delete`, `move`, `to`, `trash`],
            icon: `glyphTrash`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.ACTIVE_SCREEN_IS_NOT_TRASH,
            ],
            shortcut: getKeys('KEY_MAPPED_RECORD_DELETE'),
          },
          {
            id: CommandId.RECORDS_RESTORE,
            label: `Restore`,
            keywords: [`Restore`, `undelete`],
            icon: `glyphArrowNCircle`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.ACTIVE_SCREEN_IS_TRASH,
            ],
          },
          {
            id: CommandId.RECORDS_COPY,
            label: `Copy`,
            keywords: [`copy`],
            icon: `glyphStackedSquircles`,
            filters: [CommandFilter.AT_LEAST_ONE_SELECTION],
            shortcut: getKeys('KEY_MAPPED_RECORD_COPY'),
          },
          {
            id: CommandId.RECORDS_REMOVE_DATE,
            label: `Remove date`,
            keywords: [`remove`, `date`],
            icon: `glyphDashNeSwCircle`,
            filters: [
              CommandFilter.AT_LEAST_ONE_SELECTION,
              CommandFilter.RECORD_HAS_DATE,
            ],
          },
          {
            id: CommandId.RECORDS_REMOVE_NOTE,
            label: REMOVE_NOTE,
            keywords: [`remove`, `note`, REMOVE_NOTE],
            icon: `glyphTrash`,
            filters: [
              CommandFilter.SINGLE_SELECTION,
              CommandFilter.RECORD_HAS_ANNOTATIONS,
            ],
          },
          {
            id: CommandId.FOCUS_FILE_TITLE_INPUT,
            label: `Rename file`,
            keywords: [`rename`, `file`],
            icon: `glyphCaret`,
            filters: [
              CommandFilter.SINGLE_SELECTION,
              CommandFilter.RECORD_TYPE_IS_FILE,
            ],
          },
          {
            id: CommandId.EXPAND_TO_FULLSCREEN,
            label: OPEN_FULLSCREEN,
            keywords: [`open`, `in`, `fullscreen`, FULLSCREEN, OPEN_FULLSCREEN],
            icon: `glyphArrowNeSw`,
            filters: [
              CommandFilter.SINGLE_SELECTION,
              CommandFilter.ACTIVE_SCREEN_IS_NOT_FULLSCREEN,
            ],
            shortcut: getKeys('KEY_MAPPED_FULLSCREEN_OPEN'),
          },
          {
            id: CommandId.EXIT_FULLSCREEN,
            label: CLOSE_FULLSREEN,
            keywords: [`exit`, `fullscreen`, FULLSCREEN, CLOSE_FULLSREEN],
            icon: `glyphArrowNeSwInverted`,
            filters: [
              CommandFilter.SINGLE_SELECTION,
              CommandFilter.ACTIVE_SCREEN_IS_FULLSCREEN,
            ],
            shortcut: getKeys('KEY_MAPPED_FULLSCREEN_CLOSE'),
          },
          {
            id: CommandId.CREATE_COLLECTION,
            label: `Create new collection`,
            keywords: [`create`, `new`, `collection`],
            icon: `glyphLightning`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_NOT_FULLSCREEN],
          },
          {
            id: CommandId.RECORDS_SELECT_ALL,
            label: `Select all`,
            keywords: [`select`, `all`],
            icon: `glyphLightning`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_RECORD_GROUP],
            shortcut: getKeys('KEY_MAPPED_SELECT_ALL_RECORDS'),
          },
          {
            id: CommandId.TOGGLE_SHOW_COMPLETED,
            label: SHOW_COMPLETED,
            keywords: [`show`, `hide`, `completed`, COMPLETED, SHOW_COMPLETED],
            icon: `glyphLightning`,
            filters: [
              CommandFilter.ACTIVE_SCREEN_IS_TODAY,
              CommandFilter.NOT_SHOWING_COMPLETED,
            ],
            shortcut: getKeys('KEY_MAPPED_TOGGLE_MARKED_DONE_VISIBLE'),
          },
          {
            id: CommandId.TOGGLE_SHOW_COMPLETED,
            label: HIDE_COMPLETED,
            keywords: [`show`, `hide`, `completed`, COMPLETED, HIDE_COMPLETED],
            icon: `glyphLightning`,
            filters: [
              CommandFilter.ACTIVE_SCREEN_IS_TODAY,
              CommandFilter.SHOWING_COMPLETED,
            ],
            shortcut: getKeys('KEY_MAPPED_TOGGLE_MARKED_DONE_VISIBLE'),
          },
          {
            id: CommandId.RECORDS_SORT_BY_DATE,
            label: `Sort by date`,
            keywords: [`sort`, `by`, `date`],
            icon: `glyphLightning`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_COLLECTION_INBOX],
          },
          {
            id: CommandId.RECORDS_SORT_BY_LOCATION,
            label: `Sort by collection`,
            keywords: [`sort`, `by`, `location`, `collection`],
            icon: `glyphLightning`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_TODAY],
          },
        ]),
      },
      {
        id: Domain.COLLECTION,
        title: `Collection`,
        commands: filterCommands([
          {
            id: CommandId.COLLECTION_RENAME,
            label: `Rename collection`,
            keywords: [`rename`, `collection`],
            icon: `glyphLightning`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_COLLECTION],
          },
          {
            id: CommandId.COLLECTION_SET_EMOJI,
            label: `Set collection emoji d`,
            keywords: [`set`, `collection`, `emoji`],
            icon: `glyphStar`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_COLLECTION],
          },
        ]),
      },
      {
        id: Domain.VIEW,
        title: `View`,
        commands: filterCommands([
          {
            id: CommandId.TOGGLE_NEXT_FOURTEEN_DAYS,
            label: `Show next 14 days`,
            keywords: [`show`, `hide`, `empty`, `dates`],
            icon: `glyphEyeOpen`,
            filters: [
              CommandFilter.ACTIVE_SCREEN_IS_TODAY,
              CommandFilter.NOT_SHOWING_EMPTY_DATES,
            ],
          },
          {
            id: CommandId.TOGGLE_NEXT_FOURTEEN_DAYS,
            label: `Hide next 14 days`,
            keywords: [`show`, `hide`, `empty`, `dates`],
            icon: `glyphEyeOpen`,
            filters: [
              CommandFilter.ACTIVE_SCREEN_IS_TODAY,
              CommandFilter.SHOWING_EMPTY_DATES,
            ],
          },
          {
            id: CommandId.TOGGLE_SIDEBAR,
            label: `Show sidebar`,
            keywords: [`show`, `hide`, `sidebar`],
            icon: `glyphHamburgerAlt`,
            filters: [CommandFilter.SIDEBAR_COLLAPSED],
            shortcut: getKeys('KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE'),
          },
          {
            id: CommandId.TOGGLE_SIDEBAR,
            label: `Hide sidebar`,
            keywords: [`show`, `hide`, `sidebar`],
            icon: `glyphHamburgerAlt`,
            filters: [CommandFilter.SIDEBAR_COLLAPSED],
            shortcut: getKeys('KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE'),
          },
        ]),
      },
      {
        id: Domain.NAVIGATION,
        title: `Navigation`,
        commands: filterCommands([
          {
            id: CommandId.GO_TO_SCREEN,
            label: `Go to...`,
            keywords: [`go`, `to`, `destination`, `collection`],
            icon: `glyphArrowE`,
            filters: [],
            shouldStayOpen: true,
            shortcut: getKeys('KEY_MAPPED_OPEN_GO_TO_DIALOG'),
          },
          {
            id: CommandId.GO_TO_INBOX,
            label: `Go to Inbox`,
            keywords: [`go`, `to`, `inbox`],
            icon: `glyphInboxSquircle`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_NOT_INBOX],
          },
          {
            id: CommandId.GO_TO_TODAY,
            label: `Go to Today`,
            keywords: [`go`, `to`, `today`],
            icon: `glyphCalendarFillSquircle`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_NOT_TODAY],
          },
          {
            id: CommandId.GO_TO_TRASH,
            label: `Go to Trash`,
            keywords: [`go`, `to`, `trash`, `bin`, `deleted`, `view`],
            icon: `glyphTrash`,
            filters: [CommandFilter.ACTIVE_SCREEN_IS_NOT_TRASH],
          },
        ]),
      },
      {
        id: Domain.HELP,
        title: `Help`,
        commands: filterCommands([
          {
            id: CommandId.TOGGLE_KEYBOARD_SHORTCUTS_PANE,
            label: `View shortcuts`,
            keywords: [`view`, `shortcuts`],
            icon: `glyphLightning`,
            filters: [],
            shouldStayOpen: true,
            shortcut: getKeys('KEY_MAPPED_OPEN_KEYBOARD_SHORTCUT_DIALOG'),
          },
        ]),
      },
      {
        id: Domain.ACCOUNT,
        title: `Account`,
        commands: filterCommands([
          {
            id: CommandId.LOGOUT,
            label: `Log out`,
            keywords: [`log out`, `logout`, `sign out`],
            icon: `glyphLightning`,
            filters: [],
          },
          {
            id: CommandId.VIEW_JWT,
            label: `View JWT`,
            keywords: [`view`, `jwt`],
            icon: `glyphLightning`,
            filters: [],
          },
          {
            id: CommandId.THEME_SWITCH_TO_LIGHT,
            label: `Set theme to light mode`,
            keywords: [
              `Set`,
              `theme`,
              `to`,
              `light`,
              `dark`,
              `system`,
              `mode`,
              `appearance`,
              `switch`,
            ],
            icon: `glyphSun`,
            filters: [CommandFilter.DARK_MODE_ACTIVE],
          },
          {
            id: CommandId.THEME_SWITCH_TO_DARK,
            label: `Set theme to dark mode`,
            keywords: [
              `set`,
              `theme`,
              `to`,
              `light`,
              `dark`,
              `system`,
              `mode`,
              `appearance`,
              `switch`,
            ],
            icon: `glyphLightning`,
            filters: [CommandFilter.LIGHT_MODE_ACTIVE],
          },
          {
            id: CommandId.THEME_SWITCH_TO_SYSTEM,
            label: `Set theme to system preference`,
            keywords: [
              `set`,
              `theme`,
              `to`,
              `system`,
              `preference`,
              `light`,
              `dark`,
              `system`,
              `mode`,
              `appearance`,
              `match`,
              `operating`,
              `switch`,
            ],
            icon: `glyphLightning`,
            filters: [],
          },
        ]),
      },
    ]

    return domains.filter((domain) => domain.commands.length > 0)
  }, [getKeys])
}
