import { CSSObject } from '../styled'

export const customScrollbarStyles = ({
  alwaysVisible = false,
  thumbColor,
  trackColor = 'transparent',
  width,
}: {
  alwaysVisible?: boolean
  thumbColor: string
  trackColor?: string
  width: number
}): CSSObject => ({
  overflow: 'scroll',
  overflowX: 'hidden',

  '&::-webkit-scrollbar': {
    width: width,
    background: 'none',
  },
  '&::-webkit-scrollbar-track': {
    background: 'none',
    backgroundColor: trackColor,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 999,
    border: '2px solid rgba(0, 0, 0, 0)',
    background: 'none',
    backgroundClip: 'padding-box',
    backgroundColor: alwaysVisible ? thumbColor : 'transparent',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: thumbColor,
  },
})
