const defaultEdgeSize = 100
const maxStep = 50

let timer: any = null

/**
 * Inspired by: https://www.bennadel.com/blog/3460-automatically-scroll-the-window-when-the-user-approaches-the-viewport-edge-in-javascript.htm
 *
 * Adjust window scrolling given mouse event (currently only supports vertical scroll, but can be augmented to support horizontal scroll too)
 * - The viewport and document dimensions can all be cached and then recalculated on window-resize events (for the most part)
 *
 * Majority of info in this function with regards to document dimension is from: https://javascript.info/size-and-scroll-window
 *
 * @param event Mouse Event
 * @param manualEdgeSize If we want the boundary size to be something different than the default of 100.
 * @returns boolean
 */
export const scrollWindowAtEdges = (event: any, manualEdgeSize?: number) => {
  // Get the viewport-relative coordinates of the mousemove event.
  const viewportY = event.clientY

  // Get the viewport dimensions.
  const viewportHeight = document.documentElement.clientHeight

  // Figure out if the mouse is within the "edge" of the
  // viewport, which may require scrolling the window. To do this, we need to
  // calculate the boundaries of the edge in the viewport (these coordinates
  // are relative to the viewport grid system).
  const boundarySize = manualEdgeSize ?? defaultEdgeSize
  const edgeTop = boundarySize + 100
  const edgeBottom = viewportHeight - boundarySize

  const isInTopEdge = viewportY < edgeTop
  const isInBottomEdge = viewportY > edgeBottom

  // If the mouse is not in the viewport edge, there's no need to calculate
  // anything else.
  if (!(isInTopEdge || isInBottomEdge)) {
    clearTimeout(timer)
    return
  }

  // Get the document dimensions.
  //
  // NOTE: The various property reads here are for cross-browser compatibility
  // as outlined in the JavaScript.info site (link provided above).
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.body.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight,
  )

  // Calculate the maximum scroll offset in each direction. Since you can only
  // scroll the overflow portion of the document, the maximum represents the
  // length of the document that is NOT in the viewport.
  const maxScrollY = documentHeight - viewportHeight

  // We want to adjust the window scroll in immediate response to the event
  // We also want to continue adjusting the window scroll if the user rests their mouse in the edge boundary.
  startWindowScrollListener(
    edgeTop,
    edgeBottom,
    isInTopEdge,
    isInBottomEdge,
    viewportY,
    maxScrollY,
    boundarySize,
  )
}

export const clearWindowScrollListener = () => {
  clearTimeout(timer)
}

const startWindowScrollListener = (
  topEdgeBoundary: number,
  bottomEdgeBoundary: number,
  isInTopEdge: boolean,
  isInBottomEdge: boolean,
  mousePositionY: number,
  maxScroll: number,
  boundarySize: number,
) => {
  clearTimeout(timer)

  // If we've had to adjust the window scroll once, check if we need to do it again
  if (
    adjustWindowScroll(
      topEdgeBoundary,
      bottomEdgeBoundary,
      isInTopEdge,
      isInBottomEdge,
      mousePositionY,
      maxScroll,
      boundarySize,
    )
  ) {
    timer = setTimeout(
      () =>
        // Recursively check if we need to scroll the window
        startWindowScrollListener(
          topEdgeBoundary,
          bottomEdgeBoundary,
          isInTopEdge,
          isInBottomEdge,
          mousePositionY,
          maxScroll,
          boundarySize,
        ),
      30,
    )
  }
}

// Adjust the window scroll based on the user's mouse position. Returns True
// or False depending on whether or not the window scroll was changed.
const adjustWindowScroll = (
  topEdgeBoundary: number,
  bottomEdgeBoundary: number,
  isInTopEdge: boolean,
  isInBottomEdge: boolean,
  mousePositionY: number,
  maxScroll: number,
  boundarySize: number,
) => {
  // Get the current scroll position of the document.
  const currentScrollY = window.pageYOffset

  // Determine if the window can be scrolled in any particular direction.
  const canScrollUp = currentScrollY > 0
  const canScrollDown = currentScrollY < maxScroll

  // Keep track of the next scroll, starting with the current scroll.
  // Each of these values can then be adjusted independently in the logic
  // below.
  let nextScrollY = currentScrollY

  // The closer the mouse is to the edge, the more "intense" we want the scrolling to be
  if (isInTopEdge && canScrollUp) {
    const intensity = (topEdgeBoundary - mousePositionY) / boundarySize

    nextScrollY = nextScrollY - maxStep * intensity
  } else if (isInBottomEdge && canScrollDown) {
    const intensity = (mousePositionY - bottomEdgeBoundary) / boundarySize

    nextScrollY = nextScrollY + maxStep * intensity
  }

  // Sanitize invalid maximums. An invalid scroll offset won't break the
  // subsequent .scrollTo() call; however, it will make it harder to
  // determine if the .scrollTo() method should have been called in the
  // first place.
  nextScrollY = Math.max(0, Math.min(maxScroll, nextScrollY))

  if (nextScrollY !== currentScrollY) {
    window.scrollTo(window.pageXOffset, nextScrollY)
    return true
  } else {
    return false
  }
}
