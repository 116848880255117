import {
  ButtonRootVariantName,
  muiButtonStyles,
} from '@eleventhlabs/stationary'
import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled'

import { excludeItems, styled } from '../styled'

export type ButtonVariantName = ButtonRootVariantName

export interface ButtonStyledProps {
  variant?: ButtonVariantName
}

export type ButtonProps = ButtonUnstyledProps & ButtonStyledProps

export const Button = styled(ButtonUnstyled, {
  shouldForwardProp: excludeItems([`variant`]),
})<ButtonStyledProps>(({ variant, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ...(variant && muiButtonStyles.rootVariants[variant]({ theme })),
}))
