import { useEffect } from 'react'

import { scrollAction } from '../../events'

export const useKeepFocusedIntoView = (focusedRecordId: string | undefined) => {
  useEffect(() => {
    if (focusedRecordId)
      scrollAction({
        target: `record`,
        to: {
          id: focusedRecordId,
        },
        options: { position: `minimal`, minimumVisibile: 80 },
      })
  }, [focusedRecordId])
}
