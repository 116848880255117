import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'

import { RecordGroupType, RecordItemData } from '../../common/data/recordGroups'
import { TagType } from '../../common/data/tags'
import {
  DragSourceType,
  RecordItemDragProps,
} from '../../common/dnd/drag/types'
import { useRegisterDragSource } from '../../common/dnd/drag/useRegisterDragSource'
import {
  DropTargetType,
  RecordItemDropProps,
} from '../../common/dnd/drop/types'
import { useRegisterDropTarget } from '../../common/dnd/drop/useRegisterDropTarget'
import { DropIndicatorPosition } from '../../common/dnd/hooks/useDropIndicatorPosition'
import ContentErrorBoundary from '../../common/errors/ContentErrorBoundary'
import { ScrollOptions } from '../../common/hooks/scroll/useScroll'
import { useScrollIntoView } from '../../common/hooks/scroll/useScrollIntoView'
import { NoteHandlers as GenericNoteHandlers } from '../../common/noteEventHandlers'
import { MiniBarEventHandlers } from '../../common/recordItemEventHandlers'
import { ClickDivEvent } from '../../common/recordItemEventHandlers/types'
import {
  Icon,
  collapsedStripClassName,
  collapsedStripGradientStyles,
  collapsedStripStyles,
  cx,
  propertiesStripClassName,
  recordContentInnerStyles,
  recordContentStyles,
  recordItemInnerStyles,
  recordItemStyles,
  recordItemStyles_focus,
  recordItemStyles_hover,
  recordItemStyles_inSelection,
  recordItemStyles_inSelection_hover,
  styled,
} from '../../common/stationary'
import { emptyFn } from '../../common/utils'
import { isMobile } from '../../common/utils/env'
import FloatingLinkPreview from '../FloatingLinkPreview'
import { isImageRecord } from './components/FileRecordItemContent/utils'
import { MiniBar } from './components/MiniBar'
import { NoteList } from './components/NoteList/NoteList'
import RecordContent from './components/RecordContent'
import { RecordHandle } from './components/RecordHandle'
import { RecordMoveTo } from './components/RecordMoveTo'
import { RecordToggle } from './components/RecordToggle'
import { TagStrip } from './components/TagStrip'
import { useExpandAndCollapse } from './hooks/useExpandAndCollapse'
import { useMiniBar } from './hooks/useMiniBar'
import { usePlaceholderText } from './hooks/usePlaceholderText'
import { useShouldAutofocus } from './hooks/useShouldAutofocus'
import { RecordItemDimension, RecordItemHandlers } from './types'
import { SideBarDimensions } from '../SideBar'
import { UIContext, useUIContext } from '../../common/contexts/UIContext'
import { useReactDnDContext } from '../../common/contexts/ReactDnDContext'
import { useRegisterRecordRef } from '../../common/RecordsManager/useRegisterRecordRef'
import { useSelectionContext } from '../../common/contexts/SelectionContext'

export interface RecordItemProps extends RecordItemHandlers {
  className?: string
  isFocused?: boolean
  isMouseActive?: boolean
  isSelected?: boolean
  record: RecordItemData
  showToggle: boolean
  showMoveTo: boolean
  tagsToAutoHide?: Partial<Record<TagType, boolean>>
  noteHandlers: GenericNoteHandlers
  miniBarHandlers: MiniBarEventHandlers
  uploadProgress: number
  shouldDisplayNotesForRecordId: (recordId: string) => boolean
  scrollOptions?: ScrollOptions
  index?: number
  dropIndicatorPosition?: DropIndicatorPosition
  groupType?: RecordGroupType
}

export const RecordItem: React.FC<RecordItemProps> = ({
  className,
  expandOnFocus,
  isFocused = false,
  isMouseActive,
  isSelected = false,
  miniBarHandlers,
  noteHandlers,
  onArrowLeftOrUpAtBoundary,
  onArrowRightOrDownAtBoundary,
  onBackspaceAtFirstPointWhenNotEmpty,
  onBackspaceWhenEmpty,
  onBlur,
  onChange,
  onClick,
  onEnter,
  onEscape,
  onFileTitleClick,
  onFocus,
  onHandleClick = emptyFn,
  onKeyDown,
  onMouseDown,
  onMoveTo = emptyFn,
  onPaste,
  onShowMoreClick,
  onTagClick = emptyFn,
  onToggle = emptyFn,
  onTap = emptyFn,
  onUrlTitleClick,
  record,
  renameFile,
  scrollOptions,
  shouldDisplayNotesForRecordId,
  showMoveTo,
  showToggle,
  skipOccurrence = emptyFn,
  uploadProgress = 0,
  index,
  dropIndicatorPosition,
  groupType,
}) => {
  const [over, setOver] = useState(false)
  const [hover, setHover] = useState(false)
  const { isLeftHidden, isRightHidden } = useUIContext()

  const fullScreenWidthDropTargetRef = React.useRef(null)

  const { htmlRef } = useScrollIntoView<HTMLDivElement>(
    record.id,
    `record`,
    scrollOptions,
  )

  useRegisterRecordRef(record.id, htmlRef)

  // collapse & expand
  const hasNotes = !isEmpty(record.notesList?.notes)
  const notesOpen = hasNotes && shouldDisplayNotesForRecordId(record.id)
  const hasTags = hasNotes || !isEmpty(record.tags)
  const _isImageRecord = isImageRecord(record)

  const {
    containerRef,
    contentRef,
    height,
    maxHeight,
    isShortRecord,
    isLongRecord,
    isCollapsed,
    isExpanded,
    isFullyExpanded,
    isMaxHeightInTransition,
    onShowMoreClick: _onShowMoreClick,
    onFocus: _onFocus,
  } = useExpandAndCollapse(
    record,
    hasTags,
    onShowMoreClick,
    onFocus,
    expandOnFocus,
    isFocused,
    _isImageRecord,
  )

  const { shouldAutofocus } = useShouldAutofocus(record.id)

  const [placeholderType, setPlaceholderType] = useState<string | undefined>()
  const { placeholder } = usePlaceholderText(record.id, placeholderType)
  const _onBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      onBlur && onBlur(event)
    },
    [contentRef, onBlur],
  )

  // minibar
  const { onMouseEnter, onMouseOver, onMouseLeave, miniBarActions } =
    useMiniBar(
      record,
      miniBarHandlers,
      isFullyExpanded,
      setHover,
      setOver,
      isLongRecord,
      isMaxHeightInTransition,
      notesOpen,
      hasNotes,
    )

  const [dragRef, { isInDragGroup }] =
    useRegisterDragSource<RecordItemDragProps>(DragSourceType.RECORD, {
      index: index ?? 0,
      record,
      showToggle,
      groupType: groupType ?? RecordGroupType.ListMain,
    })

  const [dropRef, { isValidAndHovered, isDragInProgress }] =
    useRegisterDropTarget<RecordItemDropProps>(
      DropTargetType.RECORD,
      [DragSourceType.RECORD],
      {
        index: index ?? 0,
        record,
        hoverRef: htmlRef,
        groupType: groupType ?? RecordGroupType.ListMain,
      },
    )

  const displayMiniBar =
    !isMobile && isMouseActive && (hover || over) && !isDragInProgress

  // We use a different ref than the html ref on the actual record item so that we can
  // manually extend the drop target boundary past the bounds of the item. This allows us to
  // display the drop indicator even if we're dragging outside of the record item / record group.
  dropRef(fullScreenWidthDropTargetRef)

  const _onTap = (event: ClickDivEvent, recordId: string) => {
    event.stopPropagation()
    event.preventDefault()
    onTap(event, recordId)
  }

  const { selection } = useSelectionContext()
  const clearSelection = () => selection.clear()

  return (
    <S.RecordItemWrapper
      onClick={(event) => (isMobile ? _onTap(event, record.id) : null)}
    >
      {dropIndicatorPosition === DropIndicatorPosition.TOP &&
        isValidAndHovered && (
          <S.DropIndicatorTop className="recordItemDropIndicator" />
        )}
      {!isMobile && <FloatingLinkPreview triggerElement={htmlRef.current} />}
      <S.RecordItemDnDDropTarget
        ref={fullScreenWidthDropTargetRef}
        className={cx({
          isLeftHidden,
          isRightHidden,
          isDragInProgress: isDragInProgress,
        })}
        onClick={clearSelection}
      />
      <S.RecordItem
        className={cx(className, `recordItem`, {
          isSelected,
          isFocused,
          isMouseActive,
          isLongRecord,
          isDragging: isInDragGroup,
          isDragInProgress: isDragInProgress,
        })}
        ref={htmlRef}
        onMouseEnter={onMouseEnter}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <S.RecordItemExtensionLeft />
        <S.RecordItemExtensionTop />
        {isMobile ? null : (
          <S.RecordHandle
            className="handle"
            onHandleClick={onHandleClick}
            drag={dragRef}
          />
        )}
        <RecordMoveTo
          onAction={onMoveTo}
          showAction={showMoveTo}
          completed={record.isCompleted}
        />
        <RecordToggle
          onAction={onToggle}
          showAction={showToggle}
          completed={record.isCompleted}
        />
        {displayMiniBar ? (
          <S.MiniBar className="MiniBar" actions={miniBarActions} />
        ) : null}
        <S.RecordItemInner
          className={cx({
            isCollapsed,
            notesOpen,
            hasTags,
          })}
          onMouseDown={onMouseDown}
        >
          <S.RecordContent
            className={cx({
              isCollapsed,
              isFullyExpanded,
              isShortRecord,
              isImageRecord: _isImageRecord,
            })}
            style={{
              maxHeight,
              height,
            }}
            ref={containerRef}
          >
            <S.RecordContentInner
              className={cx({
                isDragInProgress,
              })}
              ref={contentRef}
            >
              <RecordContent
                notesOpen={notesOpen}
                onArrowLeftOrUpAtBoundary={onArrowLeftOrUpAtBoundary}
                onArrowRightOrDownAtBoundary={onArrowRightOrDownAtBoundary}
                onBackspaceAtFirstPointWhenNotEmpty={
                  onBackspaceAtFirstPointWhenNotEmpty
                }
                onBackspaceWhenEmpty={onBackspaceWhenEmpty}
                onBlur={_onBlur}
                onChange={onChange}
                onClick={onClick}
                onEnter={onEnter}
                onEscape={onEscape}
                onFileTitleClick={onFileTitleClick}
                onFocus={_onFocus}
                onKeyDown={onKeyDown}
                onMouseDown={onMouseDown}
                onPaste={onPaste}
                onUrlTitleClick={onUrlTitleClick}
                record={record}
                renameFile={renameFile}
                uploadProgress={uploadProgress}
                autofocus={shouldAutofocus}
                placeholder={placeholder}
                placeholderType={placeholderType}
                setPlaceholderType={setPlaceholderType}
                isExpanded={isExpanded}
                isCompleted={record.isCompleted}
                maxHeight={maxHeight}
                error={{
                  fallbackComponent: ContentErrorBoundary,
                  fallbackComponentProps: {
                    hasTags,
                    record,
                  },
                }}
              />
            </S.RecordContentInner>
            {isCollapsed && !_isImageRecord ? (
              <S.CollapsedStrip
                className={cx(collapsedStripClassName, {
                  isDragInProgress,
                  hasTags,
                })}
                onClick={_onShowMoreClick}
              >
                <Icon boxSize={16} variant="glyphExpandNS" />
                Show More...
              </S.CollapsedStrip>
            ) : null}
            <S.TagStrip
              tags={record.tags}
              onTagClick={onTagClick}
              skipOccurrence={skipOccurrence}
              isCompleted={record.isCompleted}
              notesOpen={notesOpen}
              className={cx(propertiesStripClassName, {
                isImageRecord: _isImageRecord,
                isLongRecord,
                isCollapsed,
                isSelected,
                notesOpen,
              })}
            />
          </S.RecordContent>
          {notesOpen ? (
            <NoteList
              {...record.notesList}
              parentId={record.id}
              handlers={noteHandlers}
              isMouseActive={isMouseActive}
              isSelected={isSelected}
              isCompleted={record.isCompleted}
            />
          ) : null}
        </S.RecordItemInner>
      </S.RecordItem>
      {dropIndicatorPosition === DropIndicatorPosition.BOTTOM &&
        isValidAndHovered && (
          <S.DropIndicatorBottom className="recordItemDropIndicator" />
        )}
    </S.RecordItemWrapper>
  )
}

const RECORD_ITEM_MARGIN_BOTTOM = 8

const RecordItemTransition = {
  duration: `0.125s`,
  maxHeightDuration: `0.3s`,
}

const S = {
  RecordItemWrapper: styled.div({
    position: 'relative',
  }),
  RecordItem: styled.div(({ theme }) => ({
    ...recordItemStyles({ theme }),
    marginBottom: RECORD_ITEM_MARGIN_BOTTOM,
    transition: `min-height ${RecordItemTransition.duration},
      height ${RecordItemTransition.duration},
      margin ${RecordItemTransition.duration},
      padding ${RecordItemTransition.duration},
      opacity ${RecordItemTransition.duration}`,

    /**
     * Do this to disable any hover effects while any drag is happening. Can't
     * do pointerEvents: none because we need pointer events for insertion
     * pointer
     */
    '&:not(.isDragInProgress)': {
      '&.isMouseActive:hover:not(.isSelected):not(.isFocused)': {
        ...recordItemStyles_hover({ theme }),
      },
      '&.isMouseActive:hover .handle': {
        display: `flex`,
      },

      '&.isSelected': {
        ...recordItemStyles_inSelection({ theme }),

        '&:hover': {
          ...recordItemStyles_inSelection_hover({ theme }),
        },
      },

      '&.isFocused': {
        ...recordItemStyles_focus({ theme }),
      },
    },

    '&.isDragging': {
      opacity: 0.4,
    },
  })),
  RecordItemInner: styled.div({
    ...recordItemInnerStyles,
  }),
  DropIndicatorTop: styled.div(({ theme }) => ({
    position: `absolute`,
    top: -5,
    left: 0,
    width: `100%`,
    height: 2,
    backgroundColor: theme.colors.alpha.selection.solid,
  })),
  DropIndicatorBottom: styled.div(({ theme }) => ({
    position: `absolute`,
    bottom: -5,
    left: 0,
    width: `100%`,
    height: 2,
    backgroundColor: theme.colors.alpha.selection.solid,
  })),
  RecordItemExtensionLeft: styled.div({
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: -RecordItemDimension.insetLeft,
    width: RecordItemDimension.insetLeft,
  }),
  RecordItemExtensionTop: styled.div({
    position: `absolute`,
    top: -RecordItemDimension.insetTop,
    height: RecordItemDimension.insetTop,
    left: 0,
    right: 0,
  }),
  RecordHandle: styled(RecordHandle)({
    display: `none`,
    position: `absolute`,
    top: 0,
    left: -20, // @todo Refactor to remove magic number, this needs to equal RecordHandle -width
    userSelect: `none`,
  }),
  RecordContent: styled.div({
    ...recordContentStyles,

    transition: `max-height ${RecordItemTransition.maxHeightDuration}`,

    '&.isCollapsed': {
      transition: `max-height ${RecordItemTransition.maxHeightDuration}`,
    },

    '&.isFullyExpanded, &.isShortRecord': {
      transition: `none`,
    },
  }),
  RecordContentInner: styled.div({
    ...recordContentInnerStyles,

    '&.isDragInProgress': {
      pointerEvents: `none`,
    },
  }),
  MiniBar: styled(MiniBar)({
    position: `absolute`,
    top: -16,
    right: 8,
  }),
  CollapsedTag: styled.div({
    position: `absolute`,
    right: 15,
    bottom: 15,

    '&.hasTags': {
      bottom: RecordItemDimension.propertiesStrip + 15,
    },
  }),
  Tooltip: styled.div(({ theme }) => ({
    borderRadius: 6,
    padding: `4px 8px`,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 500,
  })),
  CollapsedStrip: styled.div(({ theme }) => ({
    ...collapsedStripStyles({ theme }),
    color: theme.colors.text[700],
    cursor: 'pointer',

    '&::before': { ...collapsedStripGradientStyles },

    '&:not(.isDragInProgress)': {
      '&:hover': { color: theme.colors.text[900] },
    },

    '&.hasTags': {
      bottom: RecordItemDimension.propertiesStrip,
    },
  })),
  TagStrip: styled(TagStrip)(({ theme }) => ({
    '&:not(.isLongRecord)': {
      background: 'none !important',
    },
  })),
  RecordItemDnDDropTarget: styled.div({
    position: 'absolute',
    top: 0,
    bottom: -RECORD_ITEM_MARGIN_BOTTOM,
    left: '-100vw',
    width: '200vw',

    '&.isDragInProgress': {
      zIndex: 98,
    },
  }),
}
