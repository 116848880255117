import React from 'react'

import { preventDefault } from '../../../../common/events/utils'
import { Icon, styled, withTooltip } from '../../../../common/stationary'

interface RecurrenceIconProps {
  className?: string
  onClick: (e: any) => void
}

const RecurrenceIcon = React.forwardRef<HTMLDivElement, RecurrenceIconProps>(
  ({ className, onClick }, ref) => {
    return (
      <S.Container
        className={className}
        onClick={onClick}
        onMouseDown={preventDefault}
        ref={ref}
      >
        <Icon boxSize={16} variant="glyphCycleCwCircle" />
      </S.Container>
    )
  },
)

export const RecurrenceIconWithTooltip = withTooltip(RecurrenceIcon)

const S = {
  Container: styled.div(({ theme }) => ({
    display: `flex`,
    marginLeft: 6,
    color: theme.colors.text[700],

    '&:hover': {
      color: theme.colors.text[900],
    },
  })),
}
