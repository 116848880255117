import { Env } from '../../env'

export const getSessionJwt = () => {
  const cookieString = !Env.isSSR ? document.cookie : ''
  const cookies = cookieString.split('; ')

  for (const cookie of cookies) {
    const [name, value] = cookie.split('=')
    if (name === 'stytch_session_jwt') {
      return decodeURIComponent(value)
    }
  }

  return null // Cookie not found
}
