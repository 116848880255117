import {
  BaseSelection,
  SelectionInteractions,
} from '../../../../libs/selection'
import { setSelectionForShiftClickToTarget } from '../../../../selection'
import { blurActiveElement } from '../../../../utils'

export const recordMouseDown = (
  event: React.MouseEvent<Element, MouseEvent>,
  recordId: string,
  recordIds: string[],
  selection: BaseSelection,
  selectionInteractions: SelectionInteractions,
  focusedRecordId: string | undefined,
): string[] => {
  const isClickedRecordAlsoFocusedRecord = recordId === focusedRecordId
  if (isClickedRecordAlsoFocusedRecord) return []

  const isSelectionEmpty = selection.ids.length === 0
  if (event.shiftKey) {
    if (isSelectionEmpty && focusedRecordId === undefined) {
      selection.togglePresence(recordId)
      return [recordId]
    } else if (isSelectionEmpty && focusedRecordId !== undefined) {
      const anchor =
        recordIds.indexOf(recordId) < recordIds.indexOf(focusedRecordId)
          ? `top`
          : `bottom`
      return selectionInteractions.setToRangeIncludingItems(
        [recordId, focusedRecordId],
        anchor,
      )
    } else if (!isSelectionEmpty) {
      return setSelectionForShiftClickToTarget(
        recordIds,
        selection,
        recordId,
        selectionInteractions,
      )
    }
    event.preventDefault()
    event.stopPropagation()
    blurActiveElement()

    return []
  }

  if (event.metaKey) {
    selection.togglePresence(recordId)
    event.preventDefault()
    event.stopPropagation()
    blurActiveElement()

    return [recordId]
  }

  return []
}
