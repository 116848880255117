import { useEffect } from 'react'
import { ws } from '../websocket'
import { useSubscribeToClientWebsocketChannel } from './useSubscribeToClientWebsocketChannel'

export const useSubscribeToWebsocket = (userId: string | null) => {
  useSubscribeToClientWebsocketChannel(userId)

  useEffect(() => {
    if (!userId) return

    ws.subscribe(userId)

    return () => ws.unsubscribe(userId)
  }, [userId])
}
