import React from 'react'
import { Transition } from 'react-transition-group'

import { SlideProps } from '.'
import { styled } from '../../../../common/stationary'
import { isIOS, isMobile } from '../../../../common/utils/env'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/ImmersiveSlide'

import diagramTextYourselfIOSSrc from '../../../../assets/onboarding/diagram-text-yourself-ios@2x.png'
import diagramTextYourselfAndroidSrc from '../../../../assets/onboarding/diagram-text-yourself-android@2x.png'

export const IntroTextYourself: React.FC<SlideProps> = ({ goForward }) => {
  const diagramTextYourselfSrc = isIOS
    ? diagramTextYourselfIOSSrc
    : diagramTextYourselfAndroidSrc

  return (
    <S.IntroTextYourself>
      <S.Inner>
        <TransitionSlideContainer>
          <SlideIntro>
            <SlideTitle>“Text yourself" anything 📲</SlideTitle>
            <SlideDescription>
              The easiest way to capture notes, to-dos, and web pages on the go.
            </SlideDescription>
            <SlideButtonGroup>
              <SlidePrimaryButton onClick={() => goForward()}>
                <strong>Continue</strong>
              </SlidePrimaryButton>
            </SlideButtonGroup>
          </SlideIntro>
          <S.SlideDiagrams>
            <Transition appear in timeout={0}>
              {(state) => (
                <S.Diagram
                  style={{
                    opacity: state === 'entering' ? 0 : 1,
                    pointerEvents: state === 'entering' ? 'none' : 'initial',
                  }}
                >
                  <S.TextYourselfWrapper>
                    <S.TextYourself
                      src={diagramTextYourselfSrc}
                      srcSet={`${diagramTextYourselfSrc} 2x`}
                    />
                  </S.TextYourselfWrapper>
                </S.Diagram>
              )}
            </Transition>
          </S.SlideDiagrams>
        </TransitionSlideContainer>
      </S.Inner>
    </S.IntroTextYourself>
  )
}

const S = {
  IntroTextYourself: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',

    ...(isMobile && {
      padding: '0 24px',
    }),
  })),
  Inner: styled.div(() => ({
    width: '100%',
    maxWidth: isMobile ? 320 : 768,
  })),
  Diagram: styled.div(() => ({
    position: 'relative',
    zIndex: 1,
    transition: 'height 0.8s ease, opacity 0.4s ease',
  })),
  SlideDiagrams: styled(SlideDiagrams)(() => ({
    // This is a bit of hack, to allow visible overflow
    // so this can work at any height
    height: 400,
  })),
  TextYourselfWrapper: styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
  })),
  TextYourself: styled.img(() => ({})),
}
