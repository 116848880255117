import React, { useCallback, useState } from 'react'

import { keyframes } from '@emotion/react'
import { Icon, styled } from '../../../../../common/stationary'

interface URLThumbnailProps {
  url?: string
  thumbnail?: string
  loading?: boolean
  onClick?: (e: React.MouseEvent) => void
}

export const URLThumbnail: React.FC<URLThumbnailProps> = ({
  url,
  thumbnail,
  loading,
  onClick,
}) => {
  const [failed, setFailed] = useState(false)

  const onError = useCallback(() => setFailed(true), [])

  return thumbnail && !failed ? (
    <S.URLThumbnail href={url} onClick={onClick} className="thumbnail">
      <S.Image src={thumbnail} onError={onError} />
    </S.URLThumbnail>
  ) : (
    <S.Empty href={url} onClick={onClick}>
      {loading ? (
        <S.Spinner boxSize={16} variant="glyphCircleThreeQuarters" />
      ) : (
        <S.Link boxSize={16} variant="glyphLinkSingle" />
      )}
    </S.Empty>
  )
}

const kf = {
  spin: keyframes({
    from: { transform: `rotate(0deg)` },
    to: { transform: `rotate(360deg)` },
  }),
}

const S = {
  URLThumbnail: styled.a(({ theme }) => ({
    display: `flex`,
    flex: 1,
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[0],
    boxSizing: `border-box`,
    alignItems: `center`,
    justifyContent: `center`,
    overflow: `hidden`,
    cursor: `pointer`,
  })),
  Spinner: styled(Icon)(({ theme }) => ({
    color: theme.colors.gray[500],
    pointerEvents: `none`,
    animationName: kf.spin,
    animationDuration: `800ms`,
    animationIterationCount: `infinite`,
    animationTimingFunction: `ease-in-out`,
  })),
  Link: styled(Icon)(({ theme }) => ({
    color: theme.colors.gray[500],
    pointerEvents: `none`,
  })),
  Image: styled.img(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    height: `100%`,
    background: theme.colors.alpha.tone.weak,
    objectFit: `cover`,
  })),
  Empty: styled.a(({ theme }) => ({
    display: `flex`,
    flex: 1,
    height: 480,
    borderRadius: 8,
    background: theme.colors.surface.lowest,
    boxShadow: theme.effects.boxShadow.elevation[0],
    boxSizing: `border-box`,
    alignItems: `center`,
    justifyContent: `center`,
    cursor: `pointer`,
  })),
}
