import React, { useMemo } from 'react'
import { FixedSizeList } from 'react-window'

import { useScrollCommands } from '../../../common/hooks/scroll/useScrollCommands'
import { useScrollable } from '../../../common/hooks/scroll/useScrollable'
import { VirtualListAutosized } from '../../../common/virtualized'
import { ResultGroupItem } from '../QuickSwitcher.types'

type ListProps = React.ComponentProps<typeof VirtualListAutosized>

interface ListPropsWithData extends ListProps {
  itemData: { items: ResultGroupItem[] }
}

export const withScrollerRef = <T extends ListPropsWithData>(
  WrappedComponent: React.FC<T>,
) => {
  const WithScrollerRef: React.FC<T> = (props: T) => {
    const items = props.itemData.items
    const ids = useMemo(() => items.map((item) => item.id), [items])
    const { scrollRef, scrollCommands } = useScrollCommands<FixedSizeList>(ids)

    useScrollable(`quick-switch`, scrollCommands)

    return <WrappedComponent ref={scrollRef} {...props} />
  }

  WithScrollerRef.displayName = `WithScollerRef`
  return WithScrollerRef
}
