import React, { createContext, useContext, useState } from 'react'

export interface DragToSelectContextValue {
  isDragToSelectInProgress: boolean
  setIsDragToSelectInProgress: (isDragToSelectInProgress: boolean) => void
}

/*
 * Context
 */

const defaultValue: DragToSelectContextValue = {
  isDragToSelectInProgress: false,
  setIsDragToSelectInProgress: () => {},
}

export const DragToSelectContext =
  createContext<DragToSelectContextValue>(defaultValue)
DragToSelectContext.displayName = `DragToSelectContext`

/*
 * Provider
 */

export interface DragToSelectContextProviderProps {
  children: React.ReactNode
}

export const DragToSelectProvider = ({
  children,
}: DragToSelectContextProviderProps) => {
  const [isDragToSelectInProgress, setIsDragToSelectInProgress] =
    useState<boolean>(false)

  return (
    <DragToSelectContext.Provider
      value={{ isDragToSelectInProgress, setIsDragToSelectInProgress }}
    >
      {children}
    </DragToSelectContext.Provider>
  )
}

/*
 * Hooks
 */

export const useDragToSelectContext = (): DragToSelectContextValue => {
  const context = useContext(DragToSelectContext)

  if (context === undefined) {
    throw new Error(
      `useDragToSelectContext must be used within an DragToSelectContext`,
    )
  }

  return context
}
