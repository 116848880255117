import { useCallback, useEffect } from 'react'
import { useReactDnDContext } from '../../contexts/ReactDnDContext'
import {
  clearWindowScrollListener,
  scrollWindowAtEdges,
} from '../../scroll/scrollWindowAtEdges'
import { useHover } from '../useHover'
import { useDragToSelectContext } from '../../dragToSelect/DragToSelectContext'

export const useScrollWindowAtEdgesOnDrag = <T extends HTMLElement>(
  edgeSize?: number,
) => {
  const dndState = useReactDnDContext()
  const dragToSelectState = useDragToSelectContext()
  const [hoverRef, isHovered] = useHover<T>()

  const _handleMouseMove = useCallback(
    (event: any) => {
      return scrollWindowAtEdges(event, edgeSize)
    },
    [edgeSize],
  )

  const bindScrollListener = useCallback(() => {
    window.addEventListener('mousemove', _handleMouseMove, false)
  }, [])

  const unBindScrollListener = useCallback(() => {
    window.removeEventListener('mousemove', _handleMouseMove)
    // Clear the listener that is actually running the window scrolling logic.
    // This is separate from binding the actual event handler
    clearWindowScrollListener()
  }, [])

  useEffect(() => {
    // If we're dragging and are hovered over the screen
    if (
      dragToSelectState.isDragToSelectInProgress ||
      (dndState.isDragInProgress && isHovered)
    ) {
      bindScrollListener()
    } else {
      unBindScrollListener()
    }
    return () => {
      unBindScrollListener()
    }
  }, [
    isHovered,
    dndState.isDragInProgress,
    dragToSelectState.isDragToSelectInProgress,
  ])

  return hoverRef
}
