import {
  KeyMappedActionIdType,
  useKeyMappedActions,
  KeyMappedActions,
} from '../../common/keyMappableActions'
import {
  CLOSE_FULLSREEN,
  COMPLETED,
  GO_TO,
  NOTE,
  OPEN_FULLSCREEN,
} from '../../common/utils/uiConstants'

export interface KeyboardShortcut {
  keys: string[]
  description: string
}

export interface KeyboardShortcutGroup {
  title: string
  shortcuts: KeyboardShortcut[]
}
const shortcutFromKeyMappedAction = (
  { id, description }: { id: KeyMappedActionIdType; description: string },
  keyMappedActions: KeyMappedActions,
): KeyboardShortcut => {
  return {
    description,
    keys: keyMappedActions[id].keys.map((key) => key),
  }
}

export const useShortcutsGroup = () => {
  const keyMappedActions = useKeyMappedActions()
  const shortcutGroups: KeyboardShortcutGroup[] = [
    {
      title: `General`,
      shortcuts: [
        shortcutFromKeyMappedAction(
          {
            description: `Open command bar`,
            id: 'KEY_MAPPED_OPEN_COMMAND_BAR_DIALOG',
          },
          keyMappedActions,
        ),
      ],
    },
    {
      title: `Capture`,
      shortcuts: [
        shortcutFromKeyMappedAction(
          {
            description: `Create`,
            id: 'KEY_MAPPED_CREATE_RECORD_AT_TOP',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: `Archive/unarchive record`,
            id: 'KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: `Schedule`,
            id: 'KEY_MAPPED_OPEN_SCHEDULE_TO_DIALOG',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: `Move`,
            id: 'KEY_MAPPED_OPEN_MOVE_TO_DIALOG',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: `Add/show/hide ${NOTE.toLocaleLowerCase()}`,
            id: 'KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: OPEN_FULLSCREEN,
            id: 'KEY_MAPPED_FULLSCREEN_OPEN',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: CLOSE_FULLSREEN,
            id: 'KEY_MAPPED_FULLSCREEN_CLOSE',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: `Expand/collapse`,
            id: 'KEY_MAPPED_RECORD_TOGGLE_EXPANDED',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: `Copy to clipboard`,
            id: 'KEY_MAPPED_RECORD_COPY',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: `Select all`,
            id: 'KEY_MAPPED_SELECT_ALL_RECORDS',
          },
          keyMappedActions,
        ),
      ],
    },
    {
      title: `View`,
      shortcuts: [
        shortcutFromKeyMappedAction(
          {
            description: `Show/hide sidebar`,
            id: 'KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE',
          },
          keyMappedActions,
        ),
        shortcutFromKeyMappedAction(
          {
            description: `Show/hide ${COMPLETED.toLocaleLowerCase()}`,
            id: 'KEY_MAPPED_TOGGLE_MARKED_DONE_VISIBLE',
          },
          keyMappedActions,
        ),
      ],
    },
    {
      title: `Navigation`,
      shortcuts: [
        shortcutFromKeyMappedAction(
          {
            description: GO_TO,
            id: 'KEY_MAPPED_OPEN_GO_TO_DIALOG',
          },
          keyMappedActions,
        ),
      ],
    },
    {
      title: `Help`,
      shortcuts: [
        shortcutFromKeyMappedAction(
          {
            description: `View keyboard shortcuts`,
            id: 'KEY_MAPPED_OPEN_KEYBOARD_SHORTCUT_DIALOG',
          },
          keyMappedActions,
        ),
      ],
    },
  ]
  return shortcutGroups
}
