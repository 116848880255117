import { filter, isEmpty, take, uniq } from 'lodash'
import { useCallback, useMemo } from 'react'

import { useLocalStorage } from '../contexts/LocalStorageContext'
import { ModelStoreCommands } from '../data/modelStore'
import {
  Destinations,
  SendToDestinationRecordPayload,
  SendToDestinationSubmitPayload,
  VIRTUAL_DESTINATION,
} from '../data/useDestinations/types'
import useLocalDestinations from '../data/useDestinations/useLocalDestinations'
import {
  CreateListRecordPayload,
  CreateTextRecordPayload,
  DataRecord,
  RecordDict,
  RootRecord,
} from '@eleventhlabs/capture-shared'
import { emptyFn } from '../utils'

export interface DestinationGroup {
  heading: string
  resultsList: string[]
}

export interface DestinationGroups {
  recents: DestinationGroup
  results: DestinationGroup
}

export interface UseSendToDestinationData {
  destinations: Destinations
  groups: DestinationGroups
  sendToDestination: (
    payload: SendToDestinationSubmitPayload,
  ) => string | undefined
}

const emptyGroup = {
  heading: ``,
  resultsList: [],
}

export const emptySendToDestinationData: UseSendToDestinationData = {
  destinations: {},
  groups: {
    recents: emptyGroup,
    results: emptyGroup,
  },
  sendToDestination: emptyFn as any,
}

const RECENT_DESTINATIONS_TO_SAVE = 50
const RECENT_DESTINATIONS_TO_DISPLAY = 50

const useSendToDestination = (
  records: RecordDict,
  root: RootRecord,
  commands: ModelStoreCommands,
): UseSendToDestinationData => {
  const [recentDestinations, setRecentDestinations] =
    useLocalStorage(`recentDestinations`)
  const localDestinations = useLocalDestinations(records, root, commands)
  const destinations = localDestinations.destinations

  const groups: DestinationGroups = useMemo(
    () => ({
      recents: {
        heading: `Recents`,
        // destinations
        resultsList: take(
          filter<string>(
            uniq<string>(
              recentDestinations ? JSON.parse(recentDestinations) : [],
            ),
            (id) => !!destinations[id],
          ),
          RECENT_DESTINATIONS_TO_DISPLAY,
        ),
      },
      results: {
        heading: `Collections`,
        // destinations
        resultsList: [...Object.keys(localDestinations.destinations)],
      },
    }),
    [localDestinations.destinations, destinations, recentDestinations],
  )

  const sendSelectedRecordsToNewLocalDestination = useCallback(
    (
      recordIds: string[],
      title: string,
      payload: CreateTextRecordPayload | CreateListRecordPayload,
    ): string => {
      return localDestinations.sendToNewDestination({
        title: title ?? ``,
        recordIds,
        payload,
      })
    },
    [localDestinations],
  )

  const sendSelectedRecordsToExistingLocalDestination = useCallback(
    (
      recordIds: string[],
      destinationId: string,
      payload: CreateTextRecordPayload | CreateListRecordPayload,
    ) => {
      recordIds.forEach((recordId) =>
        localDestinations.sendToDestination({
          destinationId,
          recordId,
          payload,
        }),
      )
    },
    [localDestinations],
  )

  const sendSelectedRecordsToLocalDestination = useCallback(
    (
      recordIds: string[],
      destinationId: string,
      title: string,
      payload: CreateTextRecordPayload | CreateListRecordPayload,
    ): string | undefined => {
      if (destinationId === VIRTUAL_DESTINATION) {
        return sendSelectedRecordsToNewLocalDestination(
          recordIds,
          title,
          payload,
        )
      } else {
        sendSelectedRecordsToExistingLocalDestination(
          recordIds,
          destinationId,
          payload,
        )
      }
    },
    [
      sendSelectedRecordsToNewLocalDestination,
      sendSelectedRecordsToExistingLocalDestination,
    ],
  )

  const sendToLocalDestination = useCallback(
    (
      recordIds: string[],
      destinationId: string,
      title: string,
      payload: CreateTextRecordPayload | CreateListRecordPayload,
    ): string | undefined => {
      if (isEmpty(recordIds)) return
      return sendSelectedRecordsToLocalDestination(
        recordIds,
        destinationId,
        title,
        payload,
      )
    },
    [sendSelectedRecordsToLocalDestination],
  )

  const sendToDestination = useCallback(
    ({
      title,
      destinationId,
      recordIds,
      recordData,
    }: SendToDestinationSubmitPayload): string | undefined => {
      if (!destinationId) return // unhappy path, tried to call send to before server destinations were loaded

      const newSectionTitle = title ?? ``
      const payload = getDestinationLocalPayloadFromRecordData(recordData)
      const newSectionId = sendToLocalDestination(
        recordIds,
        destinationId,
        newSectionTitle,
        payload,
      )

      if (destinationId !== VIRTUAL_DESTINATION) {
        setRecentDestinations(
          recentDestinations
            ? JSON.stringify(
                take(
                  uniq([destinationId, ...JSON.parse(recentDestinations)]),
                  RECENT_DESTINATIONS_TO_SAVE,
                ),
              )
            : JSON.stringify([destinationId]),
        )
      }

      return newSectionId
    },
    [sendToLocalDestination, recentDestinations, setRecentDestinations],
  )

  return {
    destinations,
    groups,
    sendToDestination,
  }
}

export default useSendToDestination

const getDestinationLocalPayloadFromRecordData = (
  data: SendToDestinationRecordPayload = {},
  record?: DataRecord,
): CreateListRecordPayload => {
  const payload: CreateListRecordPayload = { ...(record as any) } ?? {}

  if (data.title) payload.title = data.title
  if (data.isCompleted != null) payload.isCompleted = data.isCompleted
  if (data.isoDate) {
    payload.tickler = { isoDate: data.isoDate }
  }

  return payload
}
