import React, { ReactNode, createContext, useContext, useEffect } from 'react'

import { emptyFn, getTimezone } from '../utils'
import { useLocalStorage } from './LocalStorageContext'

export interface GlobalLoggedInContextValue {
  timezone: string | null
  setTimezone: (value: string | null) => void
  clientID: string | null
  setClientID: (value: string | null) => void
}

/*
 * Context & Provider
 */

const defaultGlobalLoggedInCtxValue: GlobalLoggedInContextValue = {
  timezone: null,
  setTimezone: emptyFn,
  clientID: null,
  setClientID: emptyFn,
}

export const GlobalLoggedInContext = createContext(
  defaultGlobalLoggedInCtxValue,
)
GlobalLoggedInContext.displayName = `GlobalLoggedInContext`

/*
 * Provider
 */

export interface GlobalLoggedInProviderProps {
  children: ReactNode
}

export const GlobalLoggedInProvider = ({
  children,
}: GlobalLoggedInProviderProps) => {
  const value = useGlobalLoggedInCtxValue()
  return (
    <GlobalLoggedInContext.Provider value={value}>
      {children}
    </GlobalLoggedInContext.Provider>
  )
}

/*
 * Hooks
 */

export const useGlobalLoggedInCtxValue = (): GlobalLoggedInContextValue => {
  const [timezone, setTimezone] = useLocalStorage(`timezone`)
  const tz = getTimezone()
  useEffect(() => {
    setTimezone(tz)
  }, [tz, setTimezone])
  const [clientID, setClientID] = useLocalStorage(`clientID`)
  return {
    timezone,
    setTimezone,
    clientID,
    setClientID,
  }
}

export const useGlobalLoggedInContext = (): GlobalLoggedInContextValue =>
  useContext(GlobalLoggedInContext)
