import { format, parseISO, startOfToday } from 'date-fns'
import React from 'react'

import { convertISOtoDisplay, getFrequencyTitle } from '../../../common/utils'
import { RecurrenceModel } from '../Schedule.types'
import { isQuickDate } from '../hooks/useQuickDates'
import { Icon, cx, styled } from '../../../common/stationary'

export interface NextOcurrencePreviewProps {
  date: string | undefined | null
  isoTime: string | undefined
  recurrence: RecurrenceModel | undefined
  hadInitialRecurrenceOrTime: boolean
  isMemory?: boolean
  onClick: () => void
}

const NextOcurrencePreview = ({
  date,
  isoTime,
  recurrence,
  isMemory = false,
  hadInitialRecurrenceOrTime,
  onClick,
}: NextOcurrencePreviewProps) => {
  const jsDate = date ? parseISO(date) : startOfToday()
  const isNoDate = date === null
  const hadInitialRecurrenceOrTimeAndNoDate =
    isNoDate && hadInitialRecurrenceOrTime
  const noQuickDateOrHasTimeOrRecurrence =
    !isQuickDate(date) || isoTime || recurrence
  const shouldDisplay =
    date !== undefined &&
    (hadInitialRecurrenceOrTimeAndNoDate || noQuickDateOrHasTimeOrRecurrence)

  if (!shouldDisplay) return null

  const noDateTitle = (
    <S.DescriptionRecurrence>No time, does not repeat</S.DescriptionRecurrence>
  )

  const recurrenceTitle =
    recurrence != undefined ? (
      <S.DescriptionRecurrence>
        {getFrequencyTitle(recurrence.frequency, recurrence.byDayOfWeek)}
      </S.DescriptionRecurrence>
    ) : isoTime ? (
      <S.DescriptionRecurrence>
        at {convertISOtoDisplay(isoTime)}
      </S.DescriptionRecurrence>
    ) : null

  return (
    <S.NextOcurrencePreview onClick={onClick}>
      <S.Container className={cx({ memory: isMemory })}>
        <S.IconCol>
          {date === null ? (
            <Icon boxSize={16} variant="glyphDashNeSwCircle" />
          ) : recurrence ? (
            <Icon boxSize={16} variant="glyphCycleCwCircle" />
          ) : (
            <Icon boxSize={16} variant="glyphCalEmpty" />
          )}
        </S.IconCol>
        <S.DescriptionCol>
          <S.DescriptionDate>
            {date == null
              ? `No Date`
              : recurrence
              ? `${format(jsDate, `EEE MMM d`)} to Forever`
              : format(jsDate, `EEE MMM d`)}
          </S.DescriptionDate>
          {date === null ? noDateTitle : null}
          {recurrenceTitle}
        </S.DescriptionCol>
      </S.Container>
    </S.NextOcurrencePreview>
  )
}

export default NextOcurrencePreview

const S = {
  NextOcurrencePreview: styled.li(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    marginBottom: 8,
    paddingBottom: 8,
    borderBottom: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    cursor: `pointer`,
  })),
  Container: styled.div(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    flexDirection: `row`,
    userSelect: `none`,
    borderRadius: 6,
    background: theme.colors.alpha.tone.weak,
    '&.memory': {
      opacity: 0.6,
    },
  })),
  IconCol: styled.div({
    display: `flex`,
    flex: `0 0 44px`,
    flexDirection: `row`,
    padding: `12px 0 0 12px`,
  }),
  DescriptionCol: styled.div({
    display: `flex`,
    flex: 1,
    flexDirection: `column`,
    padding: `10px 0`,
  }),
  DescriptionDate: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 20px`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    fontWeight: 500,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    color: theme.colors.text[900],
    '&.empty': {
      color: theme.colors.text[500],
    },
  })),
  DescriptionRecurrence: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 16px`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    fontWeight: 500,
    ...theme.text.publicSans['11.5:16'],
    lineHeight: `16px`,
    color: theme.colors.text[500],
  })),
}
