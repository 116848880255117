import { flowRight } from 'lodash'

import { withVirtualListWrapper } from '../../../common/virtualized'
import {
  ResultListItemRowProps,
  withPropsForSingleRow,
} from '../enhancers/withPropsForSingleRow'
import ResultItem from './ResultItem'

export const ResultItemRow: React.FC<ResultListItemRowProps> = flowRight(
  withVirtualListWrapper,
  withPropsForSingleRow,
)(ResultItem)
