import { Key, Priorities, useKeys } from '../hooks/useKeys'
import { emptyFn } from '../utils'

type DialogHotKey = {
  handler?: (e: KeyboardEvent) => void
  preventLowerPriorities?: boolean
}
const emptyHandler = {
  handler: (e: KeyboardEvent) => {},
  preventLowerPriorities: true,
}

interface DialogHotKeys {
  onBackspace?: DialogHotKey
  onC?: DialogHotKey
  onCmdA?: DialogHotKey
  onCmdC?: DialogHotKey
  onCmdD?: DialogHotKey
  onCmdDown?: DialogHotKey
  onCmdEnter?: DialogHotKey
  onCmdGreaterThan?: DialogHotKey
  onCmdSlash?: DialogHotKey
  onCmdUp?: DialogHotKey
  onDownArrow?: DialogHotKey
  onEnter?: DialogHotKey
  onEsc?: DialogHotKey
  onF?: DialogHotKey
  onG?: DialogHotKey
  onI?: DialogHotKey
  onJ?: DialogHotKey
  onL?: DialogHotKey
  onLeft?: DialogHotKey
  onM?: DialogHotKey
  onN?: DialogHotKey
  onQMark?: DialogHotKey
  onRight?: DialogHotKey
  onS?: DialogHotKey
  onShiftDown?: DialogHotKey
  onShiftTab?: DialogHotKey
  onShiftUp?: DialogHotKey
  onT?: DialogHotKey
  onTab?: DialogHotKey
  onUpArrow?: DialogHotKey
  onGreaterThan?: DialogHotKey
  onLowerThan?: DialogHotKey
  onCmdK?: DialogHotKey
}

export const useOverrideHotKeysForDialog = ({
  onBackspace = emptyHandler,
  onC = emptyHandler,
  onCmdA = emptyHandler,
  onCmdC = emptyHandler,
  onCmdD = emptyHandler,
  onCmdDown = emptyHandler,
  onCmdEnter = emptyHandler,
  onCmdGreaterThan = emptyHandler,
  onCmdSlash = emptyHandler,
  onCmdUp = emptyHandler,
  onDownArrow = emptyHandler,
  onEnter = emptyHandler,
  onEsc = emptyHandler,
  onF = emptyHandler,
  onG = emptyHandler,
  onI = emptyHandler,
  onJ = emptyHandler,
  onL = emptyHandler,
  onLeft = emptyHandler,
  onM = emptyHandler,
  onN = emptyHandler,
  onQMark = emptyHandler,
  onRight = emptyHandler,
  onS = emptyHandler,
  onShiftDown = emptyHandler,
  onShiftTab = emptyHandler,
  onShiftUp = emptyHandler,
  onT = emptyHandler,
  onTab = emptyHandler,
  onUpArrow = emptyHandler,
  onGreaterThan = emptyHandler,
  onLowerThan = emptyHandler,
  onCmdK = emptyHandler,
}: DialogHotKeys) => {
  const defaultHandler = emptyFn
  const defaultPreventLowerPriorities = true
  useKeys(
    [Key.Delete, Key.Backspace],
    onBackspace.handler ?? defaultHandler,
    Priorities.Dialog,
    onBackspace?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.C,
    onC.handler ?? defaultHandler,
    Priorities.Dialog,
    onC?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.CommandDown, Key.CommandShiftDown],
    onCmdDown.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdDown?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.CommandEnter,
    onCmdEnter.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdEnter?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.CommandSlash,
    onCmdSlash.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdSlash?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.CommandUp, Key.CommandShiftUp],
    onCmdUp.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdUp?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.Down, Key.J],
    onDownArrow.handler ?? defaultHandler,
    Priorities.Dialog,
    onDownArrow?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.ShiftDown, Key.ShiftJ],
    onShiftDown.handler ?? defaultHandler,
    Priorities.Dialog,
    onShiftDown?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.ShiftTab],
    onShiftTab.handler ?? defaultHandler,
    Priorities.Dialog,
    onShiftTab?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.ShiftUp, Key.ShiftK],
    onShiftUp.handler ?? defaultHandler,
    Priorities.Dialog,
    onShiftUp?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.Tab],
    onTab.handler ?? defaultHandler,
    Priorities.Dialog,
    onTab?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.Up, Key.K],
    onUpArrow.handler ?? defaultHandler,
    Priorities.Dialog,
    onUpArrow?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.CommandA,
    onCmdA.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdA?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.CommandC,
    onCmdC.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdC?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.CommandD,
    onCmdD.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdD?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.CommandGreaterThan,
    onCmdGreaterThan.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdGreaterThan?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.Enter,
    onEnter.handler ?? defaultHandler,
    Priorities.Dialog,
    onEnter?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.Esc,
    onEsc.handler ?? defaultHandler,
    Priorities.Dialog,
    onEsc?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.F,
    onF.handler ?? defaultHandler,
    Priorities.Dialog,
    onF?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.G,
    onG.handler ?? defaultHandler,
    Priorities.Dialog,
    onG?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.J,
    onJ.handler ?? defaultHandler,
    Priorities.Dialog,
    onJ?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    [Key.H, Key.Left],
    onLeft.handler ?? defaultHandler,
    Priorities.Dialog,
    onLeft?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.I,
    onI.handler ?? defaultHandler,
    Priorities.Dialog,
    onI?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.M,
    onM.handler ?? defaultHandler,
    Priorities.Dialog,
    onM?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.N,
    onN.handler ?? defaultHandler,
    Priorities.Dialog,
    onN?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.QMark,
    onQMark.handler ?? defaultHandler,
    Priorities.Dialog,
    onQMark?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.Right,
    onRight.handler ?? defaultHandler,
    Priorities.Dialog,
    onRight?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.L,
    onL.handler ?? defaultHandler,
    Priorities.Dialog,
    onL?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.S,
    onS.handler ?? defaultHandler,
    Priorities.Dialog,
    onS?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.T,
    onT.handler ?? defaultHandler,
    Priorities.Dialog,
    onT?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.GreaterThan,
    onGreaterThan.handler ?? defaultHandler,
    Priorities.Dialog,
    onGreaterThan?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.LowerThan,
    onLowerThan.handler ?? defaultHandler,
    Priorities.Dialog,
    onLowerThan?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
  useKeys(
    Key.CommandK,
    onCmdK.handler ?? defaultHandler,
    Priorities.Dialog,
    onCmdK?.preventLowerPriorities ?? defaultPreventLowerPriorities,
  )
}

export default useOverrideHotKeysForDialog
