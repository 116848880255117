import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { SlideProps } from '.'
import diagramDesktopAppMacSrc from '../../../../assets/onboarding/diagram-desktop-app-quick-capture-focused@2x.png'
import diagramDesktopAppWindowsSrc from '../../../../assets/onboarding/diagram-desktop-app-quick-capture-focused-windows@2x.png'
import { useQuickCaptureInteraction } from '../../../../common/contexts/onboarding/QuickCaptureInteractionContext'
import { Key, useKeys } from '../../../../common/hooks/useKeys'
import { cx, styled } from '../../../../common/stationary'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import { OnboardingKeys, prettifyKey } from '../../hooks/useOnboardingHotkeys'
import { SlideWaitingHint } from './variants/ImmersiveSlide'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideSecondaryAction,
  SlideTitle,
  SlideWaitingSpinner,
  TransitionSlideContainer,
} from './variants/SplitCardSlide'
import { useReward } from 'react-rewards'
import { isMacLike } from '../../../../common/utils/env/env'

export const DesktopKeyboardShortcut: React.FC<SlideProps> = ({
  goForward,
}) => {
  const { onboardingAnalytics } = useOnboardingContext()

  const { didPressKeyboardShortcut, setDidPressKeyboardShortcut } =
    useQuickCaptureInteraction()

  useLayoutEffect(() => {
    setDidPressKeyboardShortcut(false)
  }, [setDidPressKeyboardShortcut])

  const { reward: emojiReward } = useReward('emojiRewardId', 'emoji', {
    emoji: ['✅'],
    zIndex: 1,
  })

  useEffect(() => {
    if (didPressKeyboardShortcut) emojiReward()
  }, [didPressKeyboardShortcut])

  useKeys(Key.Enter, () => didPressKeyboardShortcut && goForward())
  return (
    <S.InstallDesktopApp>
      <TransitionSlideContainer>
        <SlideIntro>
          <SlideTitle>The keyboard shortcut</SlideTitle>
          <SlideDescription>
            Quickly capture from any window with the global keyboard shortcut{' '}
            {OnboardingKeys.ShowQuickCapture.map(prettifyKey).map(
              (key, index) => (
                <S.PrettyKeys key={index}>{key}</S.PrettyKeys>
              ),
            )}{' '}
            — no more switching apps to take notes.
          </SlideDescription>
          <SlideWaitingHint
            className={cx({ isSuccess: didPressKeyboardShortcut })}
            id="emojiRewardId"
          >
            <span>
              Press{' '}
              <strong>
                {OnboardingKeys.ShowQuickCapture.map((key, index) => {
                  let prettyKeyString = prettifyKey(key)
                  if (index !== OnboardingKeys.ShowQuickCapture.length - 1) {
                    prettyKeyString += ' + '
                  }
                  return prettyKeyString
                })}
              </strong>
            </span>
            {didPressKeyboardShortcut ? (
              <span>✅</span>
            ) : (
              <SlideWaitingSpinner
                boxSize={16}
                variant="glyphCircleThreeQuarters"
              />
            )}
          </SlideWaitingHint>
          <SlideButtonGroup
            style={{
              display: didPressKeyboardShortcut ? 'none' : undefined,
            }}
          >
            <Transition appear in timeout={4000}>
              {(state) => (
                <SlideSecondaryAction
                  style={{
                    opacity: state === 'entering' ? 0 : 1,
                    pointerEvents: state === 'entering' ? 'none' : 'auto',
                    transition: 'opacity 0.8s ease',
                  }}
                >
                  <a
                    className={cx('isItalic')}
                    onClick={() => {
                      goForward()
                      onboardingAnalytics.action('Global Shortcut Skip For Now')
                    }}
                  >
                    Skip for now
                  </a>
                </SlideSecondaryAction>
              )}
            </Transition>
          </SlideButtonGroup>
          <SlideButtonGroup
            style={{
              display: didPressKeyboardShortcut ? undefined : 'none',
            }}
          >
            <SlidePrimaryButton onClick={() => goForward()}>
              <strong>Continue</strong>
            </SlidePrimaryButton>
          </SlideButtonGroup>
        </SlideIntro>
        <SlideDiagrams>
          <img
            src={
              isMacLike ? diagramDesktopAppMacSrc : diagramDesktopAppWindowsSrc
            }
            srcSet={`${
              isMacLike ? diagramDesktopAppMacSrc : diagramDesktopAppWindowsSrc
            } 2x`}
          />
        </SlideDiagrams>
      </TransitionSlideContainer>
    </S.InstallDesktopApp>
  )
}

const S = {
  InstallDesktopApp: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',
  })),
  PrettyKeys: styled.span(({ theme }) => ({
    display: 'inline-block',
    margin: '0 1px',
    padding: '2px 4px',
    border: `1px solid ${theme.colors.alpha.border.weak}`,
    borderRadius: 4,
    ...theme.text.publicSans['11.5:16'],
    fontWeight: 700,
    color: theme.colors.text[700],
    background: theme.colors.alpha.tone.extraWeak,
    transform: 'translateY(-2px)',
  })),
}
