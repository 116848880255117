import { App } from '@capacitor/app'
import { AnalyticsBrowser } from '@segment/analytics-next'
import Desktop from '../../desktop/desktop'
import { CURRENT_APP_VERSION, Env } from '../../env'
import { envIsBeta, envIsProd, isDesktop, isMobileApp } from '../../utils/env'
import { getEnvForAnalytics, getPlatformForAnalytics } from '../helpers'
import Avo, { AvoEnv, CustomDestination } from './Avo'
import { silentLogger } from './AvoLogger'

// Env
const useAvoEnvProd = envIsBeta || envIsProd

const avoEnv = useAvoEnvProd ? AvoEnv.Prod : AvoEnv.Dev

// Logs

const defaultAvoSuppressLogs = true
const shouldSuppressLogs = Env.avoSuppressLogs ?? defaultAvoSuppressLogs
const avoLogger = shouldSuppressLogs ? silentLogger : undefined

// Web Debugger
// Note: web debugger will never show when noop is true

const defaultHideWebDebugger = true
const webDebugger = !(Env.avoHideWebDebugger ?? defaultHideWebDebugger)

// Noop

const noopDefault = true
const noop = Env.avoNoop ?? noopDefault

let analytics: AnalyticsBrowser | null = null

const segmentCustomDestination: CustomDestination = {
  make: function (_env: AvoEnv, apiKey: string) {
    const segmentInitSettings = {
      writeKey: apiKey,
      // Route all cdn.segment.com domains to our own custom domain
      cdnURL: Env.segmentProxyCDNUrl,
    }

    analytics = AnalyticsBrowser.load(segmentInitSettings)
  },
  logEvent: function (eventName: string, eventProperties: object) {
    analytics?.track(eventName, eventProperties)
  },
  setUserProperties: function (userId: string, userProperties: object) {
    analytics?.identify(userId, userProperties)
  },
  identify: function (userId: string) {
    analytics?.identify(userId)
  },
  unidentify: function () {
    analytics?.identify(null)
  },
  logPage: function (pageName: string, eventProperties: object) {
    analytics?.page(Object.assign(eventProperties, { pageName: pageName }))
  },
  // The following methods are used for group analytics and are not required. Learn more about group analytics <Link passHref href="/data-design/groups"><a>here</a></Link>
  setGroupProperties(
    _groupType: string,
    groupId: string,
    groupProperties: object,
  ) {
    analytics?.group(groupId, groupProperties)
  },
  addCurrentUserToGroup: function (_groupType: string, groupId: string) {
    // Segment automatically associates the currently identified user with the group
    analytics?.group(groupId)
  },
  logEventWithGroups: function (
    _eventName: string,
    _eventProperties: object,
    _groupTypesToGroupIds: object,
  ) {
    // Not supported by the Segment SDK
  },
}

const systemProperties = {
  appVersion: CURRENT_APP_VERSION,
  platform: getPlatformForAnalytics(),
  environment: getEnvForAnalytics(),
  desktopAppVersion: isDesktop ? Desktop.getVersion() : undefined,
  mobileAppVersion: undefined,
}

Avo.initAvo(
  {
    env: avoEnv,
    avoLogger,
    noop,
    webDebugger,
  },
  systemProperties,
  {},
  segmentCustomDestination,
)

if (isMobileApp) {
  App.getInfo().then((appInfo) => {
    Avo.setSystemProperties({
      ...systemProperties,
      mobileAppVersion: appInfo.version,
    })
  })
}

// Exports

export * from './Avo'
export { Avo as default }
