import { SmsManager } from '@byteowls/capacitor-sms'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { Browser } from '@capacitor/browser'
import { SlideProps } from '.'
import emptyIcon from '../../../../assets/onboarding/empty-icon@2x.png'
import imessageIcon from '../../../../assets/onboarding/imessage-icon.png'
import androidMessagesIcon from '../../../../assets/onboarding/android-messages-icon@2x.png'
import whatsappIconIOS from '../../../../assets/onboarding/whatsapp-icon-square@2x.png'
import whatsappIconAndroid from '../../../../assets/onboarding/whatsapp-icon-darker@2x.png'
import SelectionCircleInner from '../../../../assets/onboarding/selection-circle-inner.svg'
import SelectionCircleOuter from '../../../../assets/onboarding/selection-circle-outer.svg'
import { Env } from '../../../../common/env'
import { useReplicacheContext } from '../../../../common/replicache/ReplicacheContext'
import { cx, styled } from '../../../../common/stationary'
import { useExecuteOnForeground } from '../../../../common/capacitor/appStateChange/useExecuteOnForeground'
import { isIOS, isMobile } from '../../../../common/utils/env'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideIntro,
  SlidePrimaryButton,
  SlideSecondaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/ImmersiveSlide'
import { OnboardingMessagingApp } from '../../hooks/useOnboardingScreen'

interface MessagingAppConfig {
  name: string
  key: OnboardingMessagingApp
  icon: any
  color: string
  disclaimerContent: JSX.Element
  open?: () => Promise<void>
}

export const DemoMessages: React.FC<SlideProps> = ({
  goForward,
  updateWidgetStyle,
  resetWidgetStyle,
  updateWidgetProps,
  resetWidgetProps,
}) => {
  const {
    onboardingInboxRecords,
    setSelectedMessagingApp,
    onboardingAnalytics,
  } = useOnboardingContext()
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined,
  )
  const [hasTexted, setHasTexted] = useState<boolean>(false)
  const { rep } = useReplicacheContext()

  const [isInboxShowing, setIsInboxShowing] = useState<boolean>(false)

  const prevRecordLength = useRef(onboardingInboxRecords.length)

  const isUnsupportedAppSelected = selectedIndex === 2
  const isAnyMessagingAppSelected = selectedIndex !== undefined

  useEffect(() => {
    if (
      onboardingInboxRecords.length !== 0 &&
      onboardingInboxRecords.length !== prevRecordLength.current
    ) {
      setHasTexted(true)

      onboardingAnalytics.action(`First text sent`)

      updateWidgetProps('InteractiveInbox', {
        showWaitingForTextPlaceholder: false,
      })

      prevRecordLength.current = onboardingInboxRecords.length
    }
  }, [onboardingAnalytics, onboardingInboxRecords.length])

  const initiatePull = useCallback(() => {
    rep?.pull()
  }, [rep])

  // Pull whenever the app is foregrounded to get the text info ASAP
  useExecuteOnForeground(initiatePull, {
    executeImmediately: false,
    runOnce: false,
  })

  // Don't show the InteractiveInbox until the user chooses a messaging app
  useLayoutEffect(() => {
    updateWidgetProps('InteractiveInbox', {
      showWaitingForTextPlaceholder: true,
      waitingForTextPlaceholderButtonDelayMs: 5000,
      onWaitingForTextPlaceholderClick: () => {
        onboardingAnalytics.action('Skipped sending first text')

        goForward()
      },
    })

    updateWidgetStyle('InteractiveInbox', { display: 'none' })

    return () => {
      resetWidgetStyle('InteractiveInbox')
      resetWidgetProps('InteractiveInbox')
    }
  }, [])

  const sanitizePhoneNumber = (phoneNumber: string) => {
    // Remove all non-numeric characters from the phone number
    return phoneNumber.replace(/\D/g, '')
  }

  const openMessages = async () => {
    const messagesCaptureText = `My first capture from ${
      isIOS ? 'iMessage' : 'Messages'
    }!`

    await SmsManager.send({
      numbers: [sanitizePhoneNumber(Env.captureContactPhoneNumber)],
      text: messagesCaptureText,
    })
  }

  const openWhatsApp = async () => {
    const whatsAppCaptureText = `My first capture from WhatsApp!`
    const whatsAppUrl = `https://wa.me/${sanitizePhoneNumber(
      Env.captureContactPhoneNumber,
    )}?text=${encodeURIComponent(whatsAppCaptureText)}`

    await Browser.open({ url: whatsAppUrl })
  }

  const platformSpecificMessagingAppString = isIOS ? 'iMessage' : 'Messages'

  const messagingApps: MessagingAppConfig[] = [
    {
      name: isIOS ? 'iMessage' : 'Messages',
      key: OnboardingMessagingApp.DEFAULT_MESSAGING_APP,
      icon: isIOS ? imessageIcon : androidMessagesIcon,
      color: isIOS ? '#34C759' : '#1A73E8',
      disclaimerContent: (
        <>
          ✨ This will open a new text in {platformSpecificMessagingAppString} —
          hit send, then
          <strong> come back to this screen</strong>
        </>
      ),
      open: openMessages,
    },
    {
      name: 'WhatsApp',
      key: OnboardingMessagingApp.WHATSAPP,
      icon: isIOS ? whatsappIconIOS : whatsappIconAndroid,
      color: isIOS ? '#25D366' : '#0F9D58',
      disclaimerContent: (
        <>
          ✨ This will open a new text in WhatsApp — hit send, then
          <strong> come back to this screen</strong>
        </>
      ),
      open: openWhatsApp,
    },
    {
      name: 'None of the above',
      key: OnboardingMessagingApp.NONE,
      color: '#777',
      disclaimerContent: (
        <>
          👆{' '}
          <strong>
            Select {platformSpecificMessagingAppString} or WhatsApp
          </strong>{' '}
          to set up Capture’s “text yourself” feature
        </>
      ),
      icon: emptyIcon,
    },
  ]

  const showInteractiveInbox = useCallback(() => {
    updateWidgetStyle('InteractiveInbox', {
      display: undefined,
    })

    setIsInboxShowing(true)
  }, [updateWidgetStyle])

  const onPrimaryButtonClick = useCallback(() => {
    if (!isAnyMessagingAppSelected) return

    // Pop the messaing app and show the inbox to wait for the text
    if (!isInboxShowing) {
      const selectedMessagingApp = messagingApps[selectedIndex]

      setSelectedMessagingApp(selectedMessagingApp.key)

      onboardingAnalytics.action(
        `${selectedMessagingApp.name} messaging app opened`,
      )

      if (isIOS) {
        selectedMessagingApp
          .open?.()
          .then(() => {
            // Don't show inbox immediately to avoid jarring UX
            setTimeout(() => {
              showInteractiveInbox()
            }, 500)
          })
          .catch(() => {
            // Do nothing for now
            // @see https://github.com/moberwasserlechner/capacitor-sms#error-codes
          })
      } else {
        // Promise doesn't seem to resolve correctly on Android
        // Therefore, we will just immediately show the InteractiveInbox
        setTimeout(() => {
          selectedMessagingApp.open?.()
          showInteractiveInbox()
        }, 500)
      }
    } else {
      goForward()

      resetWidgetStyle('InteractiveInbox')
    }
  }, [
    selectedIndex,
    isAnyMessagingAppSelected,
    isInboxShowing,
    showInteractiveInbox,
  ])

  return (
    <S.DemoMessage>
      <S.Inner>
        <TransitionSlideContainer>
          <SlideIntro
            style={{
              transition: 'opacity 0.5s ease-in-out',
              opacity: isInboxShowing ? 0.4 : 1,
            }}
          >
            <SlideTitle>Set up messaging</SlideTitle>
            <SlideDescription>
              Which app do you prefer to use to access Capture’s{' '}
              <strong>“text yourself”</strong> feature?
            </SlideDescription>
          </SlideIntro>
          {!isInboxShowing && (
            <S.MessagingAppsContainer>
              {messagingApps.map((app, index) => {
                const isSelected = index === selectedIndex

                return (
                  <S.MessagingAppCard
                    key={app.name}
                    style={{
                      outline: isSelected ? `1px solid ${app.color}` : `none`,
                    }}
                    onClick={() => setSelectedIndex(index)}
                  >
                    <S.MessagingAppCardLeft>
                      <S.MessagingAppIcon>
                        <img src={app.icon} />
                      </S.MessagingAppIcon>
                      <S.MessagingAppName>{app.name}</S.MessagingAppName>
                    </S.MessagingAppCardLeft>
                    <S.SelectionDotContainer
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <S.SelectionDotOuter
                        style={{
                          stroke: app.color,
                        }}
                      />
                      <S.SelectionDotInner
                        className={cx({
                          isSelected,
                        })}
                        style={{
                          fill: app.color,
                        }}
                      />
                    </S.SelectionDotContainer>
                  </S.MessagingAppCard>
                )
              })}
            </S.MessagingAppsContainer>
          )}
          <S.DisclaimerPin
            style={{
              transition: 'visibility 0.5s, opacity 0.5s linear',
              visibility: !hasTexted || !isInboxShowing ? 'hidden' : 'visible',
              opacity: !hasTexted || !isInboxShowing ? 0 : 1,
              transform: 'translateY(24px)',
              zIndex: 2,
              marginTop: isMobile ? -32 : 'unset',
            }}
          >
            <S.DisclaimerText>
              🥳 <strong>You’ve captured your first text!</strong> Texts to
              Capture will show up here in your inbox
            </S.DisclaimerText>
          </S.DisclaimerPin>
          {isAnyMessagingAppSelected && (
            <SlideButtonGroup>
              {!isInboxShowing && (
                <S.DisclaimerPin>
                  <S.DisclaimerText>
                    {messagingApps[selectedIndex].disclaimerContent}
                  </S.DisclaimerText>
                </S.DisclaimerPin>
              )}
              {isUnsupportedAppSelected && !isInboxShowing ? (
                <SlideSecondaryButton onClick={() => goForward()}>
                  <strong>Skip for now</strong>
                </SlideSecondaryButton>
              ) : (
                <SlidePrimaryButton
                  onClick={onPrimaryButtonClick}
                  disabled={isInboxShowing && !hasTexted}
                >
                  <strong>
                    {isInboxShowing ? 'Continue' : 'Text Capture'}
                  </strong>
                </SlidePrimaryButton>
              )}
            </SlideButtonGroup>
          )}
        </TransitionSlideContainer>
      </S.Inner>
    </S.DemoMessage>
  )
}

const S = {
  DemoMessage: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',

    ...(isMobile && {
      padding: '0 24px',
    }),
  })),
  Inner: styled.div(() => ({
    width: '100%',
    maxWidth: isMobile ? 320 : 768,
  })),
  MessagingAppsContainer: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    justifyContent: 'center',
  })),
  MessagingAppCard: styled.div(({ theme }) => ({
    height: 80,
    flexShrink: 0,
    padding: `0px, 20px`,
    borderRadius: 10,
    background: theme.colors.surface.base,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: theme.effects.boxShadow.elevation[1],
  })),
  MessagingAppCardLeft: styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    marginLeft: 16,
  })),
  MessagingAppIcon: styled.div(() => ({
    width: 48,
    height: 48,
  })),
  MessagingAppName: styled.span(({ theme }) => ({
    fontWeight: 700,
    ...theme.text.publicSans['16:24'],
  })),
  SelectionDotContainer: styled.svg(({ theme }) => ({
    marginRight: 24,
  })),
  SelectionDotOuter: styled(SelectionCircleOuter)(({ theme }) => ({})),
  SelectionDotInner: styled(SelectionCircleInner)(({ theme }) => ({
    opacity: 0,

    '&.isSelected': {
      opacity: 1,
    },
  })),
  TextYourselfSuccessTip: styled.img(({ theme }) => ({
    zIndex: 2,
    transform: 'translateY(16px)',
  })),
  DisclaimerPin: styled.div(({ theme }) => ({
    position: 'relative',
    bottom: '6px',
    padding: '12px 20px',
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[4],
    color: theme.colors.text[900],
    background: theme.colors.surface.base,

    transition: 'opacity 0.1s ease, transform 0.2s ease',

    ':before': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderTop: `12px solid ${theme.colors.alpha.shade.weak}`,
    },
    ':after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: `10px solid ${theme.colors.surface.base}`,
    },
  })),
  DisclaimerText: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    color: theme.colors.text[900],
    textAlign: 'center',
    fontWeight: 400,

    strong: {
      fontWeight: 700,
    },
  })),
}
