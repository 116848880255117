import {
  InboxRecordId,
  MoveToExistingPlaceIndex,
  RecordType,
  RootRecordId,
} from '@eleventhlabs/capture-shared'
import Analytics from '../capture-analytics-actions'
import {
  CAPACITOR_PLATFORM,
  envIsBeta,
  envIsDev,
  envIsStaging,
  isMobileWeb,
} from '../../utils/env'
import { Env } from '../../env'

export const getRecordType = (t: RecordType) => {
  if (t === RecordType.Text) return Analytics.RecordType.TEXT
  if (t === RecordType.File) return Analytics.RecordType.FILE
  if (t === RecordType.URL) return Analytics.RecordType.URL
  if (t === RecordType.List) return Analytics.RecordType.LIST
  throw new Error()
}

export const getDestinationTypeFromMoveToPlaceIndex = (
  index: MoveToExistingPlaceIndex,
) => {
  if (index.id === InboxRecordId) return Analytics.DestinationType.INBOX
  if (index.id === RootRecordId) return Analytics.DestinationType.USER_LIST
  else return Analytics.DestinationType.USER_LIST
}

export const getPlatformForAnalytics = () => {
  //@ts-ignore
  if (!Env.isSSR && window.todesktop) return Analytics.Platform.DESKTOP
  else if (CAPACITOR_PLATFORM == 'web') {
    if (isMobileWeb) return Analytics.Platform.MOBILE_WEB
    return Analytics.Platform.WEB
  } else if (CAPACITOR_PLATFORM === 'android') return Analytics.Platform.ANDROID
  else if (CAPACITOR_PLATFORM === 'ios') return Analytics.Platform.I_O_S
  return Analytics.Platform.WEB
}

export const getEnvForAnalytics = () => {
  if (envIsDev) return Analytics.Environment.DEV
  else if (envIsBeta) return Analytics.Environment.BETA
  else if (envIsStaging) return Analytics.Environment.STAGING
  else return Analytics.Environment.PROD
}
