import { Capacitor, CapacitorCookies } from '@capacitor/core'
import { useStytch, useStytchSession, useStytchUser } from '@stytch/react'
import {
  MagicLinksAuthenticateResponse,
  MagicLinksSendResponse,
  OAuthAuthenticateResponse,
  OAuthStartResponse,
  OTPsAuthenticateResponse,
  OTPsSendResponse,
  PasswordAuthenticateResponse,
  PasswordResetBySessionResponse,
  PasswordStrengthCheckResponse,
  SDKAPIUnreachableError,
  Session,
  StytchSDKAPIError,
  User as StytchUser,
} from '@stytch/vanilla-js'
import { isEmpty } from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ApiEndpoints } from '../ApiEndpoints'
import Analytics from '../analytics/capture-analytics-actions'
import { apiCall } from '../api/client'
import { Env } from '../env'
import { getSessionDurationMinutes } from '../stytch/getSessionDurationMinutes'
import { deleteCookie } from '../utils'
import { isAndroidNative, isDesktop, isIOSNative } from '../utils/env'
import { useLocalStorageBoolean } from './LocalStorageContext'

export type AuthErrorState =
  | 'expired'
  | 'timeout'
  | 'pkce-mismatch'
  | 'oauth_failure'
  | 'unknown'

export const SHOULD_SKIP_LANDING_COOKIE_NAME = 'should_skip_landing'
export const CX_LOGIN_LOCAL_STORAGE_KEY = 'isChromeExtensionAuthFlow'
export const IS_USER_WITH_PASSWORD_LOCAL_STORAGE_KEY = 'isUserWithPassword'
const CREATE_PASSWORD_EMAIL_TEMPLATE = 'magic_link_create_password'
const RESET_PASSWORD_EMAIL_TEMPLATE: string = 'magic_link_password_reset'
const RESET_PASSWORD_TIMEOUT_ERROR_TYPE = 'session_too_old_to_reset_password'
const PKCE_MISMATCH_ERROR_TYPE = 'pkce_mismatch'
const PKCE_EXPECTED_CODE_VERIFIER_ERROR_TYPE = 'pkce_expected_code_verifier'

const ONE_WEEK_IN_MINUTES = 60 * 24 * 7

type LoginInformation = {
  offerPasswordLogin: boolean
}

export enum StytchTokenType {
  MAGIC_LINK = 'magic_links',
  OAUTH = 'oauth',
}

export interface AuthContextValue {
  authenticateViaMagicLink: (
    token: string,
  ) => Promise<MagicLinksAuthenticateResponse>
  authenticateViaOAuth: (token: string) => Promise<OAuthAuthenticateResponse>
  authenticateViaPassword: (
    email: string,
    password: string,
  ) => Promise<PasswordAuthenticateResponse>
  extendSession: () => Promise<'success' | 'failure' | 'offline'>
  getSessionTokens: () => SessionTokens | null
  getIsUserWithPassword: (emailFromInput?: string) => Promise<LoginInformation>
  getRedirectUrl: () => string
  resetPasswordWithSession: (
    password: string,
  ) => Promise<PasswordResetBySessionResponse>
  errorState: AuthErrorState | undefined
  setErrorState: React.Dispatch<
    React.SetStateAction<AuthErrorState | undefined>
  >
  authenticateViaSessionTokens: (sessionTokens: SessionTokens) => Promise<void>
  forceDeleteStytchCookies: () => Promise<void>
  revokeSessionAndDeleteCookies: () => Promise<void>
  sendMagicLink: (email: string) => Promise<MagicLinksSendResponse>
  sendSetPasswordEmail: (email?: string) => Promise<MagicLinksSendResponse>
  sendResetPasswordEmail: (email?: string) => Promise<MagicLinksSendResponse>
  startGoogleOAuthAuthentication: () => Promise<OAuthStartResponse>
  startGoogleOAuthRegistration: (args: {
    registration_token?: string
    invite_code?: string
  }) => Promise<OAuthStartResponse>
  sendPhoneVerificationCode: (phoneNumber: string) => Promise<OTPsSendResponse>
  verifyOTP: (
    otp: string,
    otpAuthenticateMethodId: string,
    phoneNumber: string,
  ) => Promise<OTPsAuthenticateResponse>
  strengthCheck: (password: string) => Promise<PasswordStrengthCheckResponse>
  isChromeExtensionAuthFlow: boolean
  setIsChromeExtensionAuthFlow: (isCx: boolean) => void
  isUserWithPassword: boolean
  isLoggedIn: boolean
  user: StytchUser | null
  userId: string | null
  email?: string
  session: Session | null
  setSessionFromTokens: (sessionTokens: SessionTokens) => Promise<any>
}

export type SessionTokens = { session_jwt: string; session_token: string }

/*
 * Context & Provider
 */

const defaultAuthContextValue: AuthContextValue = {
  authenticateViaMagicLink: async (token: string) => {
    return Promise.resolve({} as MagicLinksAuthenticateResponse)
  },
  authenticateViaOAuth: async (token: string) => {
    return Promise.resolve({} as OAuthAuthenticateResponse)
  },
  authenticateViaPassword: async (email: string, password: string) => {
    return Promise.resolve({} as PasswordAuthenticateResponse)
  },
  revokeSessionAndDeleteCookies: async () => {
    Promise.resolve()
  },
  getIsUserWithPassword: async (emailFromInput?: string) => {
    return { offerPasswordLogin: false }
  },
  getRedirectUrl: () => '',
  getSessionTokens: () => null,
  extendSession: async () => 'offline',
  resetPasswordWithSession: async (password: string) => {
    return Promise.resolve({} as PasswordResetBySessionResponse)
  },
  errorState: undefined,
  setErrorState: () => undefined,
  authenticateViaSessionTokens: async (sessionTokens: SessionTokens) => {},
  forceDeleteStytchCookies: async () => {},
  sendMagicLink: async (email: string) => {
    return Promise.resolve({} as MagicLinksSendResponse)
  },
  sendSetPasswordEmail: async (email?: string) => {
    return Promise.resolve({} as MagicLinksSendResponse)
  },
  sendResetPasswordEmail: async (email?: string) => {
    return Promise.resolve({} as MagicLinksSendResponse)
  },
  startGoogleOAuthAuthentication: async () => {
    return Promise.resolve({} as OAuthStartResponse)
  },
  startGoogleOAuthRegistration: async () => {
    return Promise.resolve({} as OAuthStartResponse)
  },
  strengthCheck: async (password: string) => {
    return Promise.resolve({} as PasswordStrengthCheckResponse)
  },
  sendPhoneVerificationCode: async (phoneNumber: string) => {
    return Promise.resolve({} as OTPsSendResponse)
  },
  verifyOTP: async (
    otp: string,
    otpAuthenticateMethodId: string,
    phoneNumber: string,
  ) => {
    return Promise.resolve({} as OTPsAuthenticateResponse)
  },
  isChromeExtensionAuthFlow: false,
  setIsChromeExtensionAuthFlow: (isCx: boolean) => {},
  isUserWithPassword: false,
  isLoggedIn: false,
  user: null,
  userId: null,
  email: undefined,
  session: null,
  setSessionFromTokens: (sessionTokens: SessionTokens) => Promise.resolve(),
}

export const AuthContext = createContext(defaultAuthContextValue)
AuthContext.displayName = `AuthContext`

export const AuthProvider = AuthContext.Provider

export const useAuthContextValue = (): AuthContextValue => {
  const { user } = useStytchUser()
  const { session } = useStytchSession()
  const stytchClient = useStytch()

  // isLoggedIn

  const hasVerifiedEmail =
    (user?.emails ?? []).filter((e) => e.verified).length > 0

  const isLoggedIn =
    !!user && !!user.trusted_metadata.captureId && hasVerifiedEmail

  // email

  const email = user?.emails[0].email as string

  // userId

  const userId = user?.trusted_metadata.captureId as string

  /**
   * Tokens and session management
   */

  const getSessionTokens = useCallback(() => {
    return stytchClient.session.getTokens()
  }, [stytchClient])

  const setSessionFromTokens = useCallback(
    (sessionTokens: SessionTokens) => {
      stytchClient.session.updateSession(sessionTokens)
      try {
        return stytchClient.session.authenticate()
      } catch (error) {
        Analytics.stytchAuthenticateForTokensFailed(
          toStytchFailedProps(error, {}),
        )
        throw error
      }
    },
    [stytchClient],
  )

  const authenticateViaSessionTokens = async (sessionTokens: SessionTokens) => {
    await setSessionFromTokens(sessionTokens)
  }

  const extendSession = useCallback(async () => {
    try {
      await stytchClient.session.authenticate({
        session_duration_minutes: getSessionDurationMinutes(),
      })
      return 'success' as const
    } catch (error: any) {
      const isOffline =
        isStytchSDKAPIUnreachableError(error) ||
        ('message' in error && error.message === 'Load failed')
      // Don't logout the member if they're offline
      if (isOffline) return 'offline' as const
      // Session expired
      else {
        Analytics.stytchAuthenticateToExtendSessionFailed(
          toStytchFailedProps(error, {}),
        )
        return 'failure' as const
      }
    }
  }, [stytchClient])

  /**
   * We aggressively call revokeSessionAndDeleteCookies.
   * To avoid a race condition that occurs on back-to-back session.revoke calls,
   * use session.getSync instead of checking the session object.
   */
  async function revokeSessionAndDeleteCookies() {
    if (stytchClient.session.getSync()) await revokeSession()
    await forceDeleteStytchCookies()
  }

  async function revokeSession() {
    try {
      await stytchClient.session.revoke({ forceClear: true })
      Analytics.stytchSessionRevokeSucceeded()
    } catch (error) {
      Analytics.stytchSessionRevokeFailed(toStytchFailedProps(error, {}))
    }
  }

  /**
   * We've seen Stytch fail to delete cookies properly in Capacitor.
   * These cookies prevent re-authentication, locking out mobile clients.
   * So, we throw everything at the wall to attempt to delete the cookies.
   */
  async function forceDeleteStytchCookies() {
    // Try to use the built-in Stytch method.
    stytchClient.session.updateSession({
      session_jwt: null,
      session_token: null,
    })
    // Try to directly remove the cookies.
    deleteCookie('stytch_session')
    deleteCookie('stytch_session_jwt')
    // Try to use a Capacitor-specific solution.
    if (Capacitor.isNativePlatform()) {
      await CapacitorCookies.clearAllCookies()
    }
  }

  // -- End Tokens and Session Management

  // TODO:
  // Rely on URL parameters, not state/localStorage,
  //   to specificy chrome extension flow.

  const [isChromeExtensionAuthFlow, setIsChromeExtensionAuthFlow] =
    useLocalStorageBoolean(CX_LOGIN_LOCAL_STORAGE_KEY, false)

  /**
   * Load isUserWithPassword
   * Used to decide if member can SET vs RESET their password.
   * Used to decide if member can login with password on LoginScreen.
   */

  useEffect(() => {
    if (email) getIsUserWithPassword()
  }, [email])

  const getIsUserWithPassword = async (
    emailFromInput?: string,
  ): Promise<LoginInformation> => {
    const emailToCheck = emailFromInput ? emailFromInput : email

    const response = await apiCall(ApiEndpoints.loginInformationUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: emailToCheck }),
    })

    const { offerPasswordLogin }: LoginInformation = await response.json()
    setIsUserWithPassword(offerPasswordLogin)

    return { offerPasswordLogin }
  }

  // Use localStorage to ensure value is available asap on future loads

  const [isUserWithPassword, setIsUserWithPassword] = useLocalStorageBoolean(
    IS_USER_WITH_PASSWORD_LOCAL_STORAGE_KEY,
    false,
  )

  // -- End isUserWithPassword

  /**
   * Manage errorState
   * TODO: Should this really be controlled by AuthContext?
   * Used in LoginScren, PkceErrorMessage, ResetPasswordScreen
   */

  const [errorState, setErrorState] = useState<AuthErrorState | undefined>()
  const handleSetErrorState = (error: any) => {
    if (error.error_type === RESET_PASSWORD_TIMEOUT_ERROR_TYPE) {
      setErrorState('timeout')
    } else if (error.error_message.includes('used or expired')) {
      setErrorState('expired')
    } else if (
      error.error_type === PKCE_MISMATCH_ERROR_TYPE ||
      error.error_type === PKCE_EXPECTED_CODE_VERIFIER_ERROR_TYPE
    ) {
      setErrorState('pkce-mismatch')
    } else {
      setErrorState('unknown')
    }
  }

  // -- End errorState

  const strengthCheck = async (password: string) => {
    return await stytchClient.passwords.strengthCheck({
      password,
    })
  }

  const authenticateViaOAuth = async (token: string) => {
    try {
      const response = await stytchClient.oauth.authenticate(token, {
        session_duration_minutes: getSessionDurationMinutes(),
      })

      if (!response.user.trusted_metadata.captureId) {
        throw new StytchInvalidSessionError(
          'authenticateViaOAuth: No captureId in trusted_metadata.',
        )
      }

      Analytics.stytchOauthAuthenticateSucceeded({
        stytchUserIdAuthenticated: response.user_id,
      })

      return response
    } catch (error) {
      setErrorState('oauth_failure')
      Analytics.stytchOauthAuthenticateFailed(toStytchFailedProps(error, {}))
      // If we have a session but it's invalid, logout
      if (error instanceof StytchInvalidSessionError) {
        await revokeSessionAndDeleteCookies()
      }
      throw error
    }
  }

  class StytchInvalidSessionError extends Error {
    constructor(message: string) {
      super(message)
      this.name = 'StytchInvalidSessionError'
    }
  }

  const startGoogleOAuthAuthentication = async () => {
    return await stytchClient.oauth.google.start({
      login_redirect_url: getRedirectUrl(),
      signup_redirect_url: getRedirectUrl(),
    })
  }

  const startGoogleOAuthRegistration = async (args: {
    registration_token?: string
    invite_code?: string
  }) => {
    await stytchClient.oauth.google.start({
      login_redirect_url: registerWithGoogleRedirectUrl(args),
      signup_redirect_url: registerWithGoogleRedirectUrl(args),
    })
  }

  function getRedirectUrl() {
    const domain =
      isDesktop || isAndroidNative || isIOSNative
        ? Env.customAppDeeplinkProtocol
        : window.location.origin
    const path = 'authenticate'

    let redirectUrl = `${domain}/${path}`

    const queryParams = new URLSearchParams(location?.search)
    const codeChallenge = queryParams.get('code_challenge')

    if (!isEmpty(codeChallenge) && !isLoggedIn) {
      redirectUrl = redirectUrl + `?desktop_code_challenge=${codeChallenge}`
    }

    return redirectUrl
  }

  const authenticateViaPassword = async (email: string, password: string) => {
    try {
      const response = await stytchClient.passwords.authenticate({
        email,
        password,
        session_duration_minutes: getSessionDurationMinutes(),
      })
      Analytics.stytchPasswordAuthenticateSucceeded()
      return response
    } catch (error) {
      Analytics.stytchPasswordAuthenticateFailed(toStytchFailedProps(error, {}))
      throw error
    }
  }

  const setPasswordMagicLinkOptions = {
    login_magic_link_url: getResetPasswordRedirectUrl(),
    login_expiration_minutes: ONE_WEEK_IN_MINUTES,
    signup_magic_link_url: getResetPasswordRedirectUrl(),
    signup_expiration_minutes: ONE_WEEK_IN_MINUTES,
    login_template_id: CREATE_PASSWORD_EMAIL_TEMPLATE,
  }

  const sendSetPasswordEmail = async (
    emailFromInput?: string,
  ): Promise<MagicLinksSendResponse> => {
    const emailForCreatePassword = emailFromInput ? emailFromInput : email
    if (!emailForCreatePassword) {
      throw Error(
        `Tried to set password for invalid email address ${emailFromInput}`,
      )
    }

    try {
      const response = await stytchClient.magicLinks.email.send(
        emailForCreatePassword,
        setPasswordMagicLinkOptions,
      )
      Analytics.stytchSendCreatePasswordEmailSuccess({
        emailForCreatePassword,
      })
      return response
    } catch (error) {
      Analytics.stytchSendCreatePasswordEmailFailed(
        toStytchFailedProps(error, {
          emailForCreatePassword,
        }),
      )
      throw error
    }
  }

  const resetPasswordMagicLinkOptions = {
    login_magic_link_url: getResetPasswordRedirectUrl(),
    login_expiration_minutes: ONE_WEEK_IN_MINUTES,
    signup_magic_link_url: getResetPasswordRedirectUrl(),
    signup_expiration_minutes: ONE_WEEK_IN_MINUTES,
    login_template_id: RESET_PASSWORD_EMAIL_TEMPLATE,
  }

  const sendResetPasswordEmail = async (emailFromInput?: string) => {
    const emailForResetPassword = emailFromInput ? emailFromInput : email
    if (!emailForResetPassword) {
      throw Error(
        `Tried to reset password for invalid email address ${emailFromInput}`,
      )
    }
    try {
      const response = await stytchClient.magicLinks.email.send(
        emailForResetPassword,
        resetPasswordMagicLinkOptions,
      )
      Analytics.stytchSendResetPasswordEmailSuccess({
        emailForResetPassword,
      })
      return response
    } catch (error) {
      Analytics.stytchSendResetPasswordEmailFailed(
        toStytchFailedProps(error, {
          emailForResetPassword,
        }),
      )
      throw error
    }
  }

  function getResetPasswordRedirectUrl() {
    const domain =
      isDesktop || isAndroidNative || isIOSNative
        ? Env.customAppDeeplinkProtocol
        : window.location.origin
    const path = 'reset-password'

    return `${domain}/${path}`
  }

  const resetPasswordWithSession = async (password: string) => {
    try {
      const response = await stytchClient.passwords.resetBySession({
        password,
        session_duration_minutes: getSessionDurationMinutes(),
      })
      Analytics.stytchResetPasswordBySessionSuccess({
        stytchUserIdAuthenticated: response.user_id,
      })
      return response
    } catch (error) {
      Analytics.stytchResetPasswordBySessionFailed(
        toStytchFailedProps(error, { emailForResetPasswordBySession: email }),
      )
      handleSetErrorState(error)
      throw error
    }
  }

  const magicLinkRedirectUrl = getRedirectUrl()
  const magicLinkSendOptions = {
    login_magic_link_url: magicLinkRedirectUrl,
    login_expiration_minutes: ONE_WEEK_IN_MINUTES,
    signup_magic_link_url: magicLinkRedirectUrl,
    signup_expiration_minutes: ONE_WEEK_IN_MINUTES,
  }

  const sendMagicLink = async (email: string) => {
    setErrorState(undefined)
    try {
      const response = await stytchClient.magicLinks.email.send(
        email,
        magicLinkSendOptions,
      )
      Analytics.stytchSendMagicLinkSucceeded({
        emailForSendMagicLink: email,
      })
      return response
    } catch (error: any) {
      Analytics.stytchSendMagicLinkFailed(
        toStytchFailedProps(error, { emailForSendMagicLink: email }),
      )
      await revokeSessionAndDeleteCookies()
      throw error
    }
  }

  const authenticateViaMagicLink = useCallback(
    async (token: string) => {
      try {
        const magicLinkResponse = await stytchClient.magicLinks.authenticate(
          token,
          {
            session_duration_minutes: getSessionDurationMinutes(),
          },
        )
        Analytics.stytchMagicLinkAuthenticateSucceeded({
          stytchUserIdAuthenticated: magicLinkResponse.user.user_id,
        })
        return magicLinkResponse
      } catch (error: any) {
        Analytics.stytchMagicLinkAuthenticateFailed(
          toStytchFailedProps(error, {}),
        )
        handleSetErrorState(error)
        throw error
      }
    },
    [stytchClient],
  )

  const sendPhoneVerificationCode = useCallback(
    async (phoneNumber: string) => {
      try {
        const response = await stytchClient.otps.sms.send(phoneNumber, {
          expiration_minutes: 5,
        })
        Analytics.stytchOtpSmsSendSucceeded({
          phoneNumber,
        })
        return response
      } catch (error: any) {
        Analytics.stytchOtpSmsSendFailed(
          toStytchFailedProps(error, { phoneNumber }),
        )
        throw error
      }
    },
    [stytchClient],
  )

  const verifyOTP = useCallback(
    async (
      otp: string,
      otpAuthenticateMethodId: string,
      phoneNumber: string,
    ) => {
      try {
        const response = await stytchClient.otps.authenticate(
          otp,
          otpAuthenticateMethodId,
          {
            session_duration_minutes: getSessionDurationMinutes(),
          },
        )

        Analytics.stytchOtpAuthenticateSucceeded({
          phoneNumber,
          stytchOtpAuthenticateMethodId: otpAuthenticateMethodId,
        })

        return response
      } catch (error) {
        Analytics.stytchOtpAuthenticateFailed(
          toStytchFailedProps(error, {
            phoneNumber,
            stytchOtpAuthenticateMethodId: otpAuthenticateMethodId,
          }),
        )
        throw error
      }
    },
    [stytchClient, userId],
  )

  return {
    authenticateViaMagicLink,
    authenticateViaOAuth,
    authenticateViaPassword,
    authenticateViaSessionTokens,
    email,
    errorState,
    extendSession,
    forceDeleteStytchCookies,
    getIsUserWithPassword,
    getRedirectUrl,
    getSessionTokens,
    isChromeExtensionAuthFlow,
    isLoggedIn,
    isUserWithPassword,
    resetPasswordWithSession,
    revokeSessionAndDeleteCookies,
    sendMagicLink,
    sendPhoneVerificationCode,
    sendResetPasswordEmail,
    sendSetPasswordEmail,
    session,
    setErrorState,
    setIsChromeExtensionAuthFlow,
    setSessionFromTokens,
    startGoogleOAuthAuthentication,
    startGoogleOAuthRegistration,
    strengthCheck,
    user,
    userId,
    verifyOTP,
  }
}

export const useAuth = (): AuthContextValue => useContext(AuthContext)

function toStytchFailedProps<T extends {}>(
  error: unknown,
  toSpread: T,
): {
  errorMessage: string | undefined
  errorName: string | undefined
  stytchErrorMessage: string | undefined
  stytchErrorType: string | undefined
  stytchRequestId: string | undefined
  stytchStatusCode: number | undefined
} & T {
  if (isStytchSDKAPIError(error)) {
    return {
      errorName: error.name,
      errorMessage: undefined,
      stytchErrorMessage: error.error_message,
      stytchErrorType: error.error_type,
      stytchRequestId: error.request_id,
      stytchStatusCode: error.status_code,
      ...toSpread,
    }
  } else if (error instanceof Error) {
    return {
      errorMessage: error.message,
      errorName: error.name,
      stytchErrorMessage: undefined,
      stytchErrorType: undefined,
      stytchRequestId: undefined,
      stytchStatusCode: undefined,
      ...toSpread,
    }
  } else {
    return {
      errorMessage: `Thrown value was not an Error: ${String(error)}`,
      errorName: undefined,
      stytchErrorMessage: undefined,
      stytchErrorType: undefined,
      stytchRequestId: undefined,
      stytchStatusCode: undefined,
      ...toSpread,
    }
  }
}

function isStytchSDKAPIError(error: any): error is StytchSDKAPIError {
  return (
    error instanceof StytchSDKAPIError || error.name === 'StytchSDKAPIError'
  )
}

function isStytchSDKAPIUnreachableError(
  error: any,
): error is SDKAPIUnreachableError {
  return (
    error instanceof SDKAPIUnreachableError ||
    error.name === 'SDKAPIUnreachableError'
  )
}

function registerWithGoogleRedirectUrl({
  registration_token = '',
  invite_code = '',
}: {
  registration_token?: string
  invite_code?: string
}) {
  const domain = getDomainForRedirectUrl()
  const path = `authenticate`
  const query = `?isRegisterWithGoogle=${true}&registration_token=${registration_token}&invite_code=${invite_code}`

  let redirectUrl = `${domain}/${path}${query}`
  return redirectUrl
}

function getDomainForRedirectUrl() {
  return isDesktop || isAndroidNative || isIOSNative
    ? Env.customAppDeeplinkProtocol
    : window.location.origin
}
