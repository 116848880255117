import React from 'react'

import SelectionCounter from './SelectionCounter'
import { styled, useTheme } from '../../../../common/stationary'

export interface SearchInputProps {
  query?: string
  inputRef?: React.RefObject<HTMLInputElement>
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  selectionCount: number
}

const SearchInput = ({
  query = ``,
  inputRef,
  onKeyDown,
  onChange,
  placeholder,
  selectionCount,
}: SearchInputProps) => {
  const theme = useTheme()

  return (
    <S.InputContainer>
      <S.Input
        autoFocus
        value={query}
        placeholder={placeholder}
        ref={inputRef}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      <SelectionCounter count={selectionCount} />
    </S.InputContainer>
  )
}

export default SearchInput

const S = {
  InputContainer: styled.div({
    display: `flex`,
    flexDirection: `row`,
    position: `relative`,
  }),
  Input: styled.input(({ theme }) => ({
    display: `flex`,
    flex: `1`,
    height: `72px`,
    ...theme.text.publicSans['19:28'],
    lineHeight: `32px`,
    fontWeight: 400,
    outline: `none`,
    padding: `20px`,
  })),
}
