import { every, flatMap, map } from 'lodash'
import { useEffect, useMemo } from 'react'

import { useSelectionContext } from '../../contexts/SelectionContext'
import { RecordGroupData } from '../../data/recordGroups'

export const useKeepOnlyValidSelected = (recordGroups: RecordGroupData[]) => {
  const { selection } = useSelectionContext()

  const validRecordIds = useMemo(
    () => flatMap(recordGroups, (group) => map(group.items, (item) => item.id)),
    [recordGroups],
  )

  useEffect(() => {
    const validSelection = every(selection.ids, (id) =>
      validRecordIds.includes(id),
    )
    if (validSelection) return
    selection.set(selection.ids.filter((id) => validRecordIds.includes(id)))
  }, [selection, validRecordIds])
}
