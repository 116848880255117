import {
  Frack,
  InboxRecord,
  InboxRecordId,
  RecordDict,
  isInboxRecord,
  isParentableRecord,
  must,
} from '@eleventhlabs/capture-shared'

export const Inbox = {
  getRecord(recordDict: RecordDict) {
    const inboxRecord = recordDict[InboxRecordId]
    return must(inboxRecord, isInboxRecord)
  },
  getChildrenArray(inboxRecord: InboxRecord) {
    return Frack.toArray(inboxRecord.children)
  },
  getChildrenRecords(inboxRecord: InboxRecord, recordDict: RecordDict) {
    const inboxRecordIds = Inbox.getChildrenArray(inboxRecord)
    return inboxRecordIds.map((id) => must(recordDict[id], isParentableRecord))
  },
}

export default Inbox
