import { Theme } from '../stationary'

const baseSelectionBoxStyle: Partial<CSSStyleDeclaration> = {
  position: 'absolute',
  zIndex: '9',
}

const getRenderedSelectionBoxStyle = ({
  width,
  height,
  left,
  top,
  outlineColor,
  backgroundColor,
}: {
  width: number
  height: number
  left: number
  top: number
  outlineColor: string
  backgroundColor: string
}): Partial<CSSStyleDeclaration> => {
  return {
    width: `${width}px`,
    height: `${height}px`,
    left: `${left}px`,
    top: `${top}px`,
    outline: `1px solid ${outlineColor}`,
    background: backgroundColor,
  }
}

class SelectionBox {
  private container: HTMLDivElement | null
  private element: HTMLDivElement | null = null
  private start = { x: 0, y: 0 }
  private end = { x: 0, y: 0 }

  constructor(container: HTMLDivElement | null) {
    this.container = container
  }

  initialize(xPositionInPixels: number, yPositionInPixels: number) {
    if (!this.container) return

    this.element = document.createElement('div')
    this.container.style.position = 'relative'
    this.setStyle(baseSelectionBoxStyle)

    const containerRect = this.container.getBoundingClientRect()
    this.start = {
      x: xPositionInPixels - containerRect.left - window.scrollX,
      y: yPositionInPixels - containerRect.top - window.scrollY,
    }

    // At the moment of initialization, this.end should be the same as this.start
    this.end = { ...this.start }

    this.container.appendChild(this.element)
  }

  remove() {
    this.element?.remove()
    this.element = null
  }

  getBoundingClientRect() {
    return this.element?.getBoundingClientRect()
  }

  getStart() {
    return this.start
  }

  getEnd() {
    return this.end
  }

  updatePositionStyle(xCoord: number, yCoord: number, theme: Theme) {
    if (!this.container || !this.element) return

    const rect = this.container.getBoundingClientRect()
    this.end = { x: xCoord - rect.left, y: yCoord - rect.top } // Use e.clientX and e.clientY instead of e.pageX and e.pageY
    const width = Math.abs(this.end.x - this.start.x)
    const height = Math.abs(this.end.y - this.start.y)
    const left = Math.min(this.end.x, this.start.x)
    const top = Math.min(this.end.y, this.start.y)

    this.setStyle(
      getRenderedSelectionBoxStyle({
        width,
        height,
        left,
        top,
        outlineColor: theme.colors.alpha.selection.extraStrong,
        backgroundColor: theme.colors.alpha.selection.mediumStrong,
      }),
    )
  }

  setStyle(style: Partial<CSSStyleDeclaration>) {
    if (!this.element) return

    return Object.assign(this.element.style, style)
  }

  getStyle() {
    if (!this.element) return

    return this.element.style
  }

  exists() {
    return this.element !== null
  }
}

export class DragToSelectManager {
  public selectionBox: SelectionBox
  private isDragToSelecting: boolean = false

  constructor(container: HTMLDivElement | null) {
    this.selectionBox = new SelectionBox(container)
  }

  startSelection(xPositionInPixels: number, yPositionInPixels: number) {
    this.isDragToSelecting = true

    this.selectionBox.initialize(xPositionInPixels, yPositionInPixels)
  }

  getSelectionBox() {
    return this.selectionBox
  }

  stopSelection() {
    this.isDragToSelecting = false

    this.selectionBox.remove()
  }

  isSelecting() {
    return this.isDragToSelecting
  }
}
