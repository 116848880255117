import React from 'react'

import { useUIContext } from '../../common/contexts/UIContext'
import { cx, styled } from '../../common/stationary'

export function withHideMouseWhenInactive<T extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const ComponentWithMouseHiddenWhenInactive = (props: T) => {
    const { isMouseRecentlyActive } = useUIContext()

    return (
      <S.MouseInactive className={cx({ isMouseRecentlyActive })}>
        <WrappedComponent {...props} />
      </S.MouseInactive>
    )
  }
  ComponentWithMouseHiddenWhenInactive.displayName = `ComponentWithMouseHiddenWhenInactive`
  return ComponentWithMouseHiddenWhenInactive
}

const S = {
  MouseInactive: styled.div({
    '&:not(.isMouseRecentlyActive)': {
      cursor: `none`,
    },
  }),
}
