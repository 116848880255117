import React, { useCallback, useEffect } from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'
import { useReactDnDContext } from '../../contexts/ReactDnDContext'
import { DragSourceType } from '../drag/types'
import { RecordItemPreview } from './previews/RecordItemPreview'
import { styled } from '../../stationary'

const getItemStyles = (currentOffset: XYCoord | null) => {
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }
  const { x, y } = currentOffset
  return {
    transform: `translate(${x}px, ${y}px)`,
  }
}

export default function ReactDnDDragLayer() {
  const { itemType, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const { isDragInProgress, draggedRecords } = useReactDnDContext()

  useEffect(() => {
    // Need this to override the cursor while dragging no matter what
    if (isDragInProgress) {
      const cursorStyle = document.createElement('style')
      cursorStyle.innerHTML = '*{cursor: grabbing!important;}'
      cursorStyle.id = 'cursor-style'
      document.head.appendChild(cursorStyle)
    } else {
      document.getElementById('cursor-style')?.remove()
    }
  }, [isDragInProgress])

  const renderItem = useCallback(() => {
    switch (itemType) {
      case DragSourceType.RECORD:
        return (
          <RecordItemPreview
            record={item.record}
            dragCount={draggedRecords.length}
            showToggle={item.showToggle}
          />
        )
      default:
        return null
    }
  }, [itemType, item, draggedRecords])

  if (!isDragInProgress) {
    return null
  }

  return (
    <S.LayerStyles style={getItemStyles(currentOffset)}>
      {renderItem()}
    </S.LayerStyles>
  )
}

const S = {
  LayerStyles: styled.div({
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 20, // @todo Refactor to remove magic number, this needs to equal RecordHandle width
    top: 0,
    right: 0,
    bottom: 0,
    cursor: 'grabbing !important',
    boxSizing: 'border-box',
  }),
}
