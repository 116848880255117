import { useCallback, useEffect, useRef } from 'react'

import { emptyFn, startOfTodayISO } from '../../utils'
import { useLocalStorageBoolean } from '../LocalStorageContext'
import React from 'react'
import { useStartOfTodayISO } from '../../hooks/useStartOfTodayISO'

export interface TodayFiltersData {
  showNextFourteenDays: boolean
  setShowNextFourteenDays: (value: boolean) => void
  toggleShowNextFourteenDays: () => void
  todayDate: string
  setTodayDate: (value: string) => void
}

export const defaultTodayFilters = {
  showNextFourteenDays: false,
  setShowNextFourteenDays: emptyFn,
  toggleShowNextFourteenDays: emptyFn,
  todayDate: startOfTodayISO(),
  setTodayDate: emptyFn,
}

export const useTodayFilters = () => {
  const [showNextFourteenDays, setShowNextFourteenDays] =
    useLocalStorageBoolean(`showNextFourteenDays`, false)

  const toggleShowNextFourteenDays = useCallback(() => {
    const nextValue = !showNextFourteenDays
    setShowNextFourteenDays(nextValue)
    return nextValue
  }, [showNextFourteenDays, setShowNextFourteenDays])

  const [todayDate, setTodayDate] = React.useState(startOfTodayISO())

  const prevTodayDateRef = useRef<string>()
  useEffect(() => {
    prevTodayDateRef.current = todayDate
  }, [todayDate])

  const todayIso = useStartOfTodayISO().today
  useEffect(() => {
    if (todayDate === prevTodayDateRef.current) setTodayDate(todayIso)
  }, [todayIso])

  return {
    showNextFourteenDays,
    setShowNextFourteenDays,
    toggleShowNextFourteenDays,
    todayDate,
    setTodayDate,
  }
}
