import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { RichText } from '@eleventhlabs/capture-shared'
import { upperFirst } from 'lodash'
import captureLogoBlack from '../../assets/quickCapture/capture-logo-black@2x.png'
import captureLogoWhite from '../../assets/quickCapture/capture-logo-white@2x.png'
import { EditorsManager } from '../../common/EditorsManager'
import Analytics from '../../common/analytics/capture-analytics-actions'
import Desktop from '../../common/desktop/desktop'
import {
  QUICK_CAPTURE_BROADCAST_CHANNEL_NAME,
  QUICK_CAPTURE_EDITOR_ID,
} from '../../common/desktop/types'
import { Env } from '../../common/env'
import { Key } from '../../common/hooks/useKeys'
import { useSavedThemeName } from '../../common/hooks/useSavedTheme'
import { safeNavigate } from '../../common/navigate'
import { RecordItemTargetType } from '../../common/recordItem'
import { RouteType, RoutesPath } from '../../common/routes'
import {
  Icon,
  css,
  cx,
  spinAnimationStyles,
  styled,
  useTheme,
} from '../../common/stationary'
import { emptyFn } from '../../common/utils'
import { envIsProd, isDesktop } from '../../common/utils/env'
import { usePlaceholderText } from '../../components/RecordItem/hooks/usePlaceholderText'
import { CaptureRichTextEditor } from '../../components/RichTextEditor/RichTextEditor'
import { useAuth } from '../../providers'

/**
 * QuickCaptureScreen
 */

export const QuickCaptureScreen: React.FC<RouteComponentProps> = () => {
  const [contents, setContents] = useState(RichText.create())
  const [placeholderType, setPlaceholderType] = useState<string | undefined>()

  const { userId, user } = useAuth()

  // If we're not in Desktop, don't show the screen
  useRouteToInboxIfNotDesktop()

  useHideQuickCaptureIfNotLoggedIn(userId)

  const { isDarkMode } = useSavedThemeName()

  const quickCaptureBroadcastChannel = useRef<BroadcastChannel>()

  const { placeholder } = usePlaceholderText(
    QUICK_CAPTURE_EDITOR_ID,
    placeholderType,
    false,
    RecordItemTargetType.Text,
  )
  const placeHolderClass =
    useQuickCaptureScreenPlaceholderClass(placeholderType)

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(
      QUICK_CAPTURE_BROADCAST_CHANNEL_NAME,
    )
    quickCaptureBroadcastChannel.current = broadcastChannel

    return () => broadcastChannel.close()
  }, [])

  const sendRichTextPayloadToMainWindow = useCallback(() => {
    quickCaptureBroadcastChannel.current?.postMessage({ richText: contents })
  }, [contents])

  const submitQuickCapture = useCallback(async () => {
    if (RichText.isEmpty(contents)) return
    EditorsManager.effects.clear(QUICK_CAPTURE_EDITOR_ID)

    sendRichTextPayloadToMainWindow()

    Analytics.desktopQuickCaptureSubmitted({ userId: userId ?? '' })

    Desktop.quickCaptureWindow.hide()
  }, [contents, userId])

  // EVENT HANDLERS
  const onEscape = useCallback((e) => {
    e.preventDefault()

    Desktop.quickCaptureWindow.hide()

    return true
  }, [])

  const onEnter = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!e.metaKey && !e.ctrlKey) return false
      submitQuickCapture()
      return true
    },
    [submitQuickCapture],
  )

  if (!isDesktop) return null

  return (
    <S.QuickCaptureScreen>
      <S.EditorContainer>
        <S.Editor
          id={QUICK_CAPTURE_EDITOR_ID}
          autofocus
          setPlaceholderType={setPlaceholderType}
          placeholder={placeholder}
          placeholderType={placeholderType}
          validateAnalytics={emptyFn}
          onListItemChange={emptyFn}
          richText={contents}
          onChange={(text: RichText) => {
            setContents(text)
          }}
          onEscape={onEscape}
          onEnter={onEnter}
          placeholderClass={placeHolderClass}
        />
      </S.EditorContainer>
      <S.Footer>
        <S.CaptureLogo
          src={isDarkMode ? captureLogoWhite : captureLogoBlack}
          onClick={() => Desktop.mainWindow.show()}
        />
        <S.ActionButtonContainerMidPadding>
          {!envIsProd && (
            <span>{user?.emails[0]['email'] + ' : ' + Env.appEnv}</span>
          )}
        </S.ActionButtonContainerMidPadding>
        <S.FooterRight>
          <S.ActionButtonContainer onClick={submitQuickCapture}>
            <S.ActionButtonMainText>Save to Inbox</S.ActionButtonMainText>
            <S.ActionButtonShortcutsContainer>
              {Key.CommandEnter.split('+').map((key) => {
                let displayKey = key
                if (key === 'command') displayKey = 'Cmd'

                return (
                  <S.ActionButtonShortcut>
                    {upperFirst(displayKey)}
                  </S.ActionButtonShortcut>
                )
              })}
            </S.ActionButtonShortcutsContainer>
          </S.ActionButtonContainer>
        </S.FooterRight>
      </S.Footer>
    </S.QuickCaptureScreen>
  )
}

const useRouteToInboxIfNotDesktop = () => {
  useLayoutEffect(() => {
    if (!isDesktop) safeNavigate(RoutesPath[RouteType.Inbox])
  }, [])
}

const useHideQuickCaptureIfNotLoggedIn = (userId: string | null) => {
  useEffect(() => {
    // If the userId becomes null for some reason while we're looking at the window, just hide it.
    // This will happen if the user's session gets revoked.
    if (!userId) {
      Desktop.quickCaptureWindow.hide()
    }
  }, [userId])
}

const usePlaceholderCSS = () => {
  const theme = useTheme()

  return useMemo(
    () =>
      css({
        color: theme.colors.text[500],
        overflow: `hidden`,
        position: `absolute`,
        textOverflow: `ellipsis`,
        ...theme.text.publicSans['16:24'],
        userSelect: `none`,
        display: `inline-block`,
        pointerEvents: `none`,
        top: 28,
        paddingLeft: 20,

        '&.isH1': {
          ...theme.text.publicSans['27.5:36'],
          fontWeight: 700,
          paddingTop: 12,
        },

        '&.isH2': {
          ...theme.text.publicSans['23:32'],
          fontWeight: 700,
          paddingTop: 12,
        },

        '&.isH3': {
          ...theme.text.publicSans['19:28'],
          fontWeight: 700,
          paddingTop: 12,
        },
      }),
    [theme],
  )
}

const useQuickCaptureScreenPlaceholderClass = (
  placeHolderType: string | undefined,
) => {
  const [placeholderClass, setPlaceholderClass] = useState<string | undefined>()

  const placeholderCSS = usePlaceholderCSS()

  useEffect(() => {
    const isH1 = placeHolderType === 'h1'
    const isH2 = placeHolderType === 'h2'
    const isH3 = placeHolderType === 'h3'
    const isP = placeHolderType === 'p'

    setPlaceholderClass(
      !placeHolderType
        ? undefined
        : cx(placeholderCSS, {
            isH1,
            isH2,
            isH3,
            isP,
          }),
    )
  }, [placeHolderType, placeholderCSS])

  return placeholderClass
}

/**
 * Styled
 */

const S = {
  QuickCaptureScreen: styled.div(({ theme }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      background: theme.colors.surface.lower,
      height: `100vh`,
      overflow: `hidden`,
      borderRadius: 12,
      '-webkit-app-region': 'drag',
      '>*': {
        '-webkit-app-region': 'no-drag',
      },
    }
  }),
  EditorContainer: styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: 8,
    flex: `1 1 auto`,
    overflow: `auto`,
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[2],
  })),
  Editor: styled(CaptureRichTextEditor)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: theme.colors.surface.base,
    borderRadius: 8,
    minWidth: `100%`,
    minHeight: 96,
    flex: `1 1 auto`,
    overflow: `auto`,
  })),
  Footer: styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    userSelect: `none`,
  })),
  CaptureLogo: styled.img(() => ({
    width: `85.714px`,
    height: `20px`,
    flexShrink: 0,
    margin: `14px 20px`,
    cursor: 'pointer',
  })),
  ActionButtonContainer: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: `center`,
    gap: 4,
    borderRadius: 6,
    marginRight: 12,
    marginBottom: 12,
    marginTop: 12,
    '&:hover': {
      background: theme.colors.alpha.tone.weak,
      cursor: 'pointer',
    },
    '&:active': {
      background: theme.colors.alpha.tone.mediumWeak,
    },
  })),
  ActionButtonMainText: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    fontStyle: `normal`,
    fontWeight: 900,
    padding: `0px 8px`,
    marginLeft: 4,
    marginTop: 4,
    marginBottom: 4,
    flex: `0 0 auto`,
  })),
  ActionButtonContainerMidPadding: styled.div(() => ({
    '-webkit-app-region': 'drag',
    display: 'flex',
    flex: `1 1 auto`,
    height: `100%`,
    justifyContent: 'center',
    alignItems: 'center',
  })),
  ActionButtonShortcutsContainer: styled.div(({ theme }) => ({
    display: 'flex',
    gap: 4,
    marginRight: 4,
    marginTop: 4,
    marginBottom: 4,
    flex: `0 0 auto`,
  })),
  ActionButtonShortcut: styled.div(({ theme }) => ({
    display: `flex`,
    height: 24,
    padding: `2px 8px`,
    justifyContent: `center`,
    alignItems: `center`,
    borderRadius: 4,
    background: theme.colors.alpha.tone.weak,

    fontWeight: 600,
    ...theme.text.publicSans['11.5:16'],
    fontStyle: `normal`,
  })),
  FooterRight: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: `center`,
    gap: 4,
  })),
  SpinnerIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.gray[400],
    ...spinAnimationStyles({ animationDuration: '2s' }),
  })),
}
