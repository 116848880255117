import { size } from 'lodash'

import {
  BaseEvent,
  EventHandler,
  EventType,
  UnsubscribeHandler,
} from './common/types'
import {
  FocusEvent,
  FocusEventListener,
  FocusFullScreenRecordEvent,
} from './focus/types'
import { ScrollEvent } from './scroll/types'
import { SelectEvent } from './select/types'
import { TriggerEvent } from './trigger/types'
import { EventQueue, ValidEvent, ValidListener } from './types'
import { processQueueForAllListeners, processQueueForListener } from './utils'

interface EventManager {
  queues: Record<EventType, EventQueue>
  createNewQueue: (
    eventType: EventType,
    size?: number,
    keepOldest?: boolean,
  ) => void
  subscribe: (listener: ValidListener) => UnsubscribeHandler
  dispatch: (event: ValidEvent) => void
}

const eventManager: EventManager = ((): EventManager => {
  const queues: Record<string, EventQueue> = {}

  const createNewQueue = (
    eventType: EventType,
    size?: number,
    keepOldest = false,
  ) => {
    const newQueue = {
      size,
      keepOldest,
      events: [],
      listeners: [],
    }
    queues[eventType] = newQueue
  }

  const subscribe = (listener: ValidListener): UnsubscribeHandler => {
    const queue = queues[listener.type]
    if (!queue) throw new Error(`Tried to subscribe to a non-existing queue`)

    queue.listeners.push(listener)
    queue.events = processQueueForListener(queue, listener)

    return () => {
      const index = queue.listeners.findIndex((l) => l === listener)
      queue.listeners.splice(index, 1)
    }
  }

  const dispatch = (event: ValidEvent) => {
    const queue = queues[event.type]

    // check if the event fits in the queue size
    if (queue.size && queue.size <= size(queue.events)) {
      if (queue.keepOldest) return
      queue.events.shift()
    }

    queue.events.push(event)
    queue.events = processQueueForAllListeners(queue)
  }

  return {
    queues,
    createNewQueue,
    subscribe,
    dispatch,
  }
})()

eventManager.createNewQueue(EventType.Focus, 1)
eventManager.createNewQueue(EventType.FocusFullScreenRecord, 1)
eventManager.createNewQueue(EventType.Select, 1)
eventManager.createNewQueue(EventType.Scroll, 1)
eventManager.createNewQueue(EventType.Trigger, 5)

const actionCreator =
  <T extends BaseEvent>(type: EventType) =>
  (payload: T['payload']) =>
    eventManager.dispatch({
      type,
      payload,
    })

export const subscribeToFocusEvent = (handler: EventHandler<FocusEvent>) =>
  eventManager.subscribe({
    type: EventType.Focus,
    handler,
  })

/**
 * This exists separately from subscribeToFocusEvent so we can use it when a record might steal focus
 * in cases where a normal record may appear on the screen very quickly before it gets opened in fullscreen.
 */
export const subscribeToFocusFullScreenRecordEvent = (
  handler: EventHandler<FocusFullScreenRecordEvent>,
) =>
  eventManager.subscribe({
    type: EventType.FocusFullScreenRecord,
    handler,
  })

export const subscribeToSelectEvent = (handler: EventHandler<SelectEvent>) =>
  eventManager.subscribe({
    type: EventType.Select,
    handler,
  })

export const subscribeToScrollEvent = (handler: EventHandler<ScrollEvent>) =>
  eventManager.subscribe({
    type: EventType.Scroll,
    handler,
  })

export const subscribeToTriggerEvent = (handler: EventHandler<TriggerEvent>) =>
  eventManager.subscribe({
    type: EventType.Trigger,
    handler,
  })

export const focusAction = actionCreator<FocusEvent>(EventType.Focus)
export const selectAction = actionCreator<SelectEvent>(EventType.Select)
export const scrollAction = actionCreator<ScrollEvent>(EventType.Scroll)
export const triggerAction = actionCreator<TriggerEvent>(EventType.Trigger)
export const focusFullScreenRecordAction =
  actionCreator<FocusFullScreenRecordEvent>(EventType.FocusFullScreenRecord)
