import React from 'react'

import { useUIContext } from '../../../common/contexts/UIContext'
import { cx, styled } from '../../../common/stationary'
import TodayHeader, { TodayHeaderProps } from './TodayHeader'
import { SideBarDimensions } from '../../../components/SideBar'

const TodayHeaderFixedWrapper = React.forwardRef<
  HTMLDivElement,
  TodayHeaderProps
>((props, ref) => {
  const { isLeftHidden } = useUIContext()

  return (
    <S.TodayHeaderFixedWrapper ref={ref}>
      <S.LeftFiller
        className={cx({
          isLeftHidden,
        })}
      />
      <S.TodayHeaderFixedWrapperInner
        className={cx({
          isLeftHidden,
        })}
      >
        <TodayHeader {...props} />
      </S.TodayHeaderFixedWrapperInner>
    </S.TodayHeaderFixedWrapper>
  )
})

export default TodayHeaderFixedWrapper

export const TodayHeaderWrapperDimensions = {
  height: 96,
}

const S = {
  TodayHeaderFixedWrapper: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    position: `fixed`,
    left: 0,
    right: 0,
    top: 0,
    transform: `translateY(0%)`,
    transition: `min-width 0.2s ease-in-out`,
    userSelect: `none`,
    pointerEvents: `none`,
    background: theme.colors.surface.lower,
    zIndex: 9,
  })),
  LeftFiller: styled.div({
    flexShrink: 0,
    display: `flex`,
    width: SideBarDimensions.width,
    pointerEvents: `none`,

    // we try to match the sidebar animation
    transition: 'width 0.2s ease',

    '&.isLeftHidden': {
      width: 0,
    },
  }),
  TodayHeaderFixedWrapperInner: styled.div(({ theme }) => ({
    display: `flex`,
    flexShrink: 1,
    // width: LayoutDimension.center.width,
    width: 832,
    maxWidth: '100%',
    alignItems: `center`,
    justifyContent: `center`,
    // background: theme.colors.surface.base,
    position: `relative`,
    pointerEvents: `all`,
    paddingTop: 'calc(56px + env(safe-area-inset-top))', // HeaderDimension.height

    transitionDuration: `0.33s`,
    transitionProperty: `width, max-width`,
  })),
}
