import { isEmpty } from 'lodash'
import React from 'react'

import { HandleResultAction, MoveItemData } from '../MoveTo.types'
import NoResults from './NoResults'
import { ResultItemDimensions } from './ResultItem'
import ResultList from './ResultList'
import { cx, styled } from '../../../common/stationary'

export interface DestinationFinderProps {
  query: string
  items: MoveItemData[]
  onResultClick: HandleResultAction
  onItemHover: (id: string) => void
}

export const DestinationFinder = ({
  query,
  items,
  onResultClick,
  onItemHover,
}: DestinationFinderProps) => {
  const thereAreNoResults = isEmpty(items)

  return (
    <S.SearchResultsContainer
      className={cx({
        empty: isEmpty(items) && isEmpty(query),
      })}
    >
      <div
        style={{
          height: items.length * ResultItemDimensions.Item.height,
          maxHeight: 216,
        }}
      >
        <ResultList
          items={items}
          onHover={onItemHover}
          handleResultClick={onResultClick}
        />
      </div>
      {thereAreNoResults && !isEmpty(query) ? (
        <NoResults query={query} />
      ) : null}
    </S.SearchResultsContainer>
  )
}

export default DestinationFinder

const S = {
  SearchResultsContainer: styled.div(({ theme }) => ({
    maxHeight: 450,
    padding: `8px 0`,
    overflowY: `scroll`,

    // Hide scrollbars: https://stackoverflow.com/a/49278385/4542197
    scrollbarWidth: `none`,
    msOverflowStyle: `none`,

    borderBottom: `1px solid ${theme.colors.alpha.border.mediumWeak}`,

    '&.empty': {
      padding: 0,
      borderBottom: `none`,
    },

    '&::-webkit-scrollbar': {
      display: `none`,
      height: 0,
      width: 0,
    },
  })),
}
