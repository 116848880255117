import { RichTextEditor as RichTextEditorBase } from '@eleventhlabs/capture-shared'
import { withCustomHandlers } from './customHandlers'
import { withCustomStyle } from './customStyle'
import { withDebounce } from './debounce'
import { RichTextEditorProps } from './RichTextEditorProps'
import { withSync } from './sync'
import { withEditorTheme } from './withEditorTheme'
import { withLogging } from './withLogging'
import { withRegistration } from './withRegistration'

export const FullScreenRichTextEditor: React.FC<RichTextEditorProps> =
  withEditorTheme(
    withCustomStyle(
      withCustomHandlers(
        withSync(
          withDebounce(withRegistration(withLogging(RichTextEditorBase))),
        ),
      ),
    ),
  )
