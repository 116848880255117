import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router'

import styled from '@emotion/styled'
import React, { FC } from 'react'
import CaptureLogo from '../assets/brand/brand-logotype.svg'
import createNewContactIOSSrc from '../assets/onboarding/create-new-contact-ios@2x.png'
import doneIconHighlightedIOSSrc from '../assets/onboarding/done-icon-highlighted-ios@2x.png'
import createNewContactAndroidSrc from '../assets/onboarding/create-new-contact-android@2x.png'
import { screenInnerStyles, screenStyles } from '../common/stationary'
import { AddToContactsPlatform } from './OnboardingScreen/components/slides/AddToContacts'
import { safeNavigate } from '../common/navigate'
import { RouteType, RoutesPath } from '../common/routes'

/*
 * DownloadContactScreen
 */

const DownloadContactScreen: FC<RouteComponentProps> = () => {
  // [@willwang888 TODO]: Analytics for this

  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)

  const platform = queryParams.get('platform')

  if (!platform || platform === AddToContactsPlatform.None) {
    // Go to Inbox
    safeNavigate(RoutesPath[RouteType.Inbox])

    return null
  }

  const isIOS = platform === AddToContactsPlatform.IOS

  const downloadContact = () => {
    // Appending an anchor element and clicking on it to simulate a direct download instead of a window.open
    const a = document.createElement('a')
    a.href = 'https://capture.so/Capture.vcf'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const createNewContactImageSrc = isIOS
    ? createNewContactIOSSrc
    : createNewContactAndroidSrc

  const doneIconHighlightedSrc = isIOS ? doneIconHighlightedIOSSrc : null

  const instructionsList: {
    text: string | JSX.Element
    supplementalComponent?: JSX.Element
  }[] = [
    {
      text: (
        <>
          Tap <strong>{isIOS ? 'Open' : 'Download'} Contact...</strong>
        </>
      ),
      supplementalComponent: (
        <S.OpenContactButton onClick={() => downloadContact()}>
          {isIOS ? 'Open' : 'Download'} Contact
        </S.OpenContactButton>
      ),
    },
    {
      text: isIOS ? (
        <>
          On the next screen, tap <strong>Create New Contact</strong>, then tap{' '}
          <strong>Done</strong>
        </>
      ) : (
        <>
          Once the contact finishes downloading, tap <strong>Open</strong>
        </>
      ),
      supplementalComponent: (
        <S.CreateNewContact
          src={createNewContactImageSrc}
          srcSet={`${createNewContactImageSrc} 2x`}
        />
      ),
    },
    {
      text: isIOS ? (
        <>
          Tap <strong>Done</strong> at the top-left of this screen to close the
          page
        </>
      ) : (
        <>
          Select an account to save the contact to, then tap <strong>X</strong>{' '}
          to close this page
        </>
      ),
      supplementalComponent: (
        <S.DoneIconHighlighted
          src={doneIconHighlightedSrc}
          srcSet={`${doneIconHighlightedSrc} 2x`}
        />
      ),
    },
  ]

  return (
    <S.DownloadContactScreen>
      <S.DownloadContactScreenInner>
        <S.Title>
          How to add Capture to contacts on {isIOS ? 'iOS/iPadOS' : 'Android'}
        </S.Title>
        <S.InstructionsList>
          {instructionsList.map((step, index) => {
            return (
              <li key={index}>
                <S.InstructionText>{step.text}</S.InstructionText>
                {step.supplementalComponent}
              </li>
            )
          })}
        </S.InstructionsList>
        <S.Footer>
          <S.CaptureLogo />
          <S.CopyrightText>{COPYRIGHT_TEXT}</S.CopyrightText>
        </S.Footer>
      </S.DownloadContactScreenInner>
    </S.DownloadContactScreen>
  )
}

const COPYRIGHT_TEXT = '© 2023 Eleventh, Inc.'

export default DownloadContactScreen

const S = {
  DownloadContactScreen: styled.div({
    ...screenStyles,
    paddingTop: 'calc(32px + env(safe-area-inset-top))', // HeaderDimension.height
    height: `100vh`,
  }),
  DownloadContactScreenInner: styled.div({
    ...screenInnerStyles,
    height: `100%`,
  }),
  Title: styled.h1(({ theme }) => ({
    fontWeight: 800,
    marginBottom: 16,
    ...theme.text.publicSans['27.5:36'],
    color: theme.colors.text[900],
    margin: '0px 24px 16px',
  })),
  InstructionText: styled.div(({ theme }) => ({
    ...theme.text.publicSans['16:24'],
    color: theme.colors.text[900],
    marginBottom: 8,
    width: '100%',

    display: 'inline-block',
    verticalAlign: 'top', // to align the content with the list number

    strong: {
      fontWeight: 700,
    },
  })),
  OpenContactButton: styled.button(({ theme }) => ({
    display: `inline-flex`,
    height: 40,
    padding: `0px 20px`,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    flexShrink: 0,
    borderRadius: 6,
    background: theme.colors.alpha.tone.solid,

    boxShadow: theme.effects.boxShadow.elevation[2],

    color: theme.colors.alpha.invertedTone.solid,
    ...theme.text.publicSans['16:24'],
    fontWeight: 700,
  })),
  CreateNewContact: styled.img(({ theme }) => ({
    all: 'unset',
    borderRadius: 8,
    outline: `1px solid ${theme.colors.alpha.border.weak}`,
    overflow: 'hidden',
  })),
  DoneIconHighlighted: styled.img(({ theme }) => ({
    all: 'unset',
    borderRadius: 8,
    outline: `1px solid ${theme.colors.alpha.border.weak}`,
    overflow: 'hidden',
  })),
  CaptureLogo: styled(CaptureLogo)(({ theme }) => ({
    height: 20,
    width: 'auto',

    path: {
      fill: theme.colors.text[900],
    },
  })),
  CopyrightText: styled.div(({ theme }) => ({
    ...theme.text.publicSans['11.5:16'],
    color: theme.colors.text[500],
    fontWeight: 400,
  })),
  Footer: styled.div(() => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,

    display: 'inline-flex',
    height: 72,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 'env(safe-area-inset-bottom)',

    alignItems: 'center',
    flexShrink: 0,
    justifyContent: 'space-between',
  })),
  InstructionsList: styled.ol(() => ({
    listStyleType: 'decimal',
    listStylePosition: 'outside',

    li: {
      marginLeft: 48,
      marginBottom: 16,
    },
  })),
}
