import React from 'react'

import { FloatingActions } from '../../components/FloatingActions'

export function withFloatingActions<T extends {}>(
  WrappedComponent: React.FC<T>,
): React.FC<T> {
  const ComponentWithFloatingActions = (props: T) => {
    return (
      <>
        <WrappedComponent {...props} />
        <FloatingActions />
      </>
    )
  }

  ComponentWithFloatingActions.displayName = `ComponentWithFloatingActions`

  return ComponentWithFloatingActions
}
