import { isImage } from '../../../../common/files'
import { FileMetadata, RecordType } from '@eleventhlabs/capture-shared'
import { RecordItemData } from '../../../../common/data/recordGroups'

const getImageExtension = (mimeType: string) =>
  mimeType.split(`image/`)[1] ?? ``

const getImageDescription = (mimeType: string) =>
  `${getImageExtension(mimeType).toUpperCase()} image`

const getGenericFileDescription = (mimeType: string) => {
  const subType = (mimeType.split(`/`)[1] ?? ``).split(`;`)[0] ?? ``
  return `${subType.toUpperCase()} ${getMimeTypeName(mimeType)}`
}

const getMimeTypeName = (mimeType: string) => {
  const type = mimeType.split(`/`)[0] ?? ``

  switch (type) {
    case `application`:
      return `file`
    case `video`:
      return `movie`
  }

  return type
}

export const isMovie = (mimeType: string) => {
  return getMimeTypeName(mimeType) === `movie`
}

export const getFileDescription = (metadata?: FileMetadata): string => {
  if (!metadata) return ``
  const type = metadata?.mimeType ?? ``
  return isImage(type)
    ? getImageDescription(type)
    : getGenericFileDescription(type)
}

export const isImageRecord = (record: RecordItemData) => {
  return Boolean(
    record.type === RecordType.File && isImage(record.fileMetadata?.mimeType),
  )
}
