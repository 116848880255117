import Analytics from '../analytics/capture-analytics-actions'
import { useAuth } from '../contexts/AuthContext'
import { useQuickCaptureInteraction } from '../contexts/onboarding/QuickCaptureInteractionContext'
import { useStableCallback } from '../utils'
import Desktop from './desktop'

export enum QuickCaptureTrigger {
  TRAY = 'tray',
  KEYBOARD_SHORTCUT = 'keyboard_shortcut',
}
export const useOpenQuickCaptureOrDefault = (userId: string | null) => {
  const { extendSession, revokeSessionAndDeleteCookies } = useAuth()
  const { setDidPressKeyboardShortcut, setDidClickTrayIcon } =
    useQuickCaptureInteraction()

  return useStableCallback(
    async (trigger: QuickCaptureTrigger) => {
      if (!userId) {
        Analytics.desktopQuickCaptureMainWindowShown({
          userId: userId ?? '',
        })

        // If we're not logged in, just show the main window to prompt user to log in
        await Desktop.mainWindow.show()
      } else {
        const isVisible = await Desktop.quickCaptureWindow.isVisible()
        if (isVisible) {
          Analytics.desktopQuickCaptureHidden({
            userId: userId ?? '',
          })
          await Desktop.quickCaptureWindow.hide()
        } else {
          const result = await extendSession()
          if (result === 'failure') {
            // We don't have access to exec.
            // So, revoke the session and show the main window.
            // Main window will run teardown process.
            revokeSessionAndDeleteCookies()
            Desktop.mainWindow.show()
            return
          }

          Analytics.desktopQuickCaptureShown({
            userId: userId ?? '',
          })

          if (trigger === QuickCaptureTrigger.KEYBOARD_SHORTCUT) {
            setDidPressKeyboardShortcut(true)
          } else if (trigger === QuickCaptureTrigger.TRAY) {
            setDidClickTrayIcon(true)
          }

          // Show but don't focus the window so it doesn't focus the main window when it closes
          await Desktop.quickCaptureWindow.show({
            inactive: true,
          })
        }
      }
    },
    [userId, extendSession],
  )
}
