import { app, nativeWindow, object } from '@todesktop/client-core'
import { Env } from '../env'
import { isDesktop } from '../utils/env'
import { ToDesktopRefObject } from './types'

class Window {
  id: string

  constructor(id: string) {
    this.id = id
  }

  /**
   * Show the desktop window to the user.
   *
   * @param {boolean} [args.inactive] - Shows the window corresponding to the `ref` but doesn't focus it. If this window is not the main window, the main window does not get focused when this window closes.
   * @return {void | any} Returns nothing or any value.
   */
  async show(args?: { inactive: boolean }): Promise<void> {
    const window = await this.getRef()
    if (args?.inactive) {
      return nativeWindow.showInactive({ ref: window })
    }
    return nativeWindow.show({ ref: window })
  }

  /**
   * Hide the desktop window. Keeps the window in memory. Good for apps that run in the background or sit in the system tray.
   */
  async hide(): Promise<void> {
    const window = await this.getRef()
    return nativeWindow.hide({ ref: window })
  }

  /**
   * Close the desktop window and remove it from memory.
   *
   * This destroys the window, removing it from view and requires re-initialization on next start up.
   */
  async close(): Promise<void> {
    const window = await this.getRef()
    return nativeWindow.close({ ref: window })
  }

  /**
   * Whether the window is visible to the user.
   */
  async isVisible(): Promise<boolean> {
    const window = await this.getRef()
    return await nativeWindow.isVisible({
      ref: window,
    })
  }

  async getRef(): Promise<ToDesktopRefObject | undefined> {
    if (isDesktop) {
      const result = await object.retrieve({ id: this.id })
      return result ? (result as ToDesktopRefObject) : undefined
    }
    return undefined
  }
}

export default class Desktop {
  static mainWindow = new Window(Env.DESKTOP_MAIN_CAPTURE_WINDOW_ID)
  static quickCaptureWindow = new Window(Env.DESKTOP_QUICK_CAPTURE_WINDOW_ID)

  /**
   * Executes a function once for the entire application.
   */
  static subscribeOnce = <T = unknown>(
    id: string,
    callback: (reset: () => void) => T,
  ): Promise<T> => {
    return app.once(id, callback)
  }

  /**
   * Gets the version of our Desktop app.
   */
  static getVersion = (): string => {
    return app.getVersion()
  }
}
