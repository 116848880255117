import {
  DeleteableRecord,
  ItemRecord,
  RecordDict,
  RecordWithTickler,
  UserDataKeyboardShortcuts,
} from '@eleventhlabs/capture-shared'

import { ModelStoreUser, UserDataOnboarding } from './useUser'

export type ModelStore = {
  records: RecordDict
  overdueRecords: RecordWithTickler[]
  softDeletedRecords: DeleteableRecord[]
  recordsWithDates: { [isoDate: string]: RecordWithTickler[] }
  smsRecords: Array<ItemRecord>
  user: ModelStoreUser
}

export const defaultUserDataKeyboardShortcuts: UserDataKeyboardShortcuts = {
  startingVersion: 0,
  lastComputationVersion: 0,
  currentComputedKeyboardShortcuts: {},
  userCustomOverrides: {},
}
