import { WriteTransaction } from 'replicache'

import { RCMutation } from '@eleventhlabs/capture-shared'

import { addToChildren, getDeleteableRecord, updateRecord } from './utils'
import { omit } from 'lodash'

export const unSoftDeleteRecords = async (
  tx: WriteTransaction,
  { recordIds }: RCMutation.UnSoftDeleteRecordArgs,
) => {
  for (const recordId of recordIds) {
    await unSoftDeleteRecord(tx, recordId)
  }
}

const unSoftDeleteRecord = async (tx: WriteTransaction, recordId: string) => {
  const record = await getDeleteableRecord(tx, recordId)
  await updateRecord(tx, record.id, {
    ...omit(record, [`softDeletedAt`]),
    isSoftDeleted: false,
  })
  if (!record.parentId) throw Error('')
  await addToChildren(tx, record.parentId, [recordId], { at: 'start' })
}
