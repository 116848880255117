import {
  add,
  eachDayOfInterval,
  endOfDay,
  formatISO,
  startOfDay,
} from 'date-fns'
import { forEach } from 'lodash'

import { dateToShortTitle, todayPageDateToTitle } from '../../../../utils'
import { ModelStore } from '../../../modelStore'
import { RecordGroupData, RecordGroupType } from '../../types'
import { RecordViewType } from '../RecordViewType'
import { createRecordGroupData } from '../common'
import { getRecordForViewType } from '../getRecordForViewType'

export const OverdueRecordGroupId = `overdue`
export const TodayNextFewWeeksSpan = 2

export const createTodayRecordGroupsForDate = (
  data: ModelStore,
  date: Date,
  showCompleted: boolean,
  showNextFourteenDays: boolean,
): RecordGroupData[] => {
  const groups: RecordGroupData[] = []
  const overdueRecords = data.overdueRecords
  if (overdueRecords.length > 0) {
    const overdueRecordGroupItems = data.overdueRecords.map((r) =>
      getRecordForViewType(data.records, r.id, RecordViewType.OverdueGroup),
    )
    const overdueRecordGroup = createRecordGroupData(
      overdueRecordGroupItems,
      RecordGroupType.Overdue,
      OverdueRecordGroupId,
      `Overdue`,
    )
    groups.push(overdueRecordGroup)
  }

  const daysToInclude = eachDayOfInterval({
    start: startOfDay(date),
    end: showNextFourteenDays
      ? add(startOfDay(date), { weeks: TodayNextFewWeeksSpan })
      : endOfDay(date),
  })
  forEach(daysToInclude, (date) => {
    const iso = formatISO(date, { representation: `date` })
    const recordsWithISO = data.recordsWithDates[iso] ?? []
    const recordsWithISOGroupItems = recordsWithISO
      .filter((r) => showCompleted || !r.isCompleted)
      .map((r) =>
        getRecordForViewType(data.records, r.id, RecordViewType.DateGroup),
      )
    const dateRecordGroup = createRecordGroupData(
      recordsWithISOGroupItems,
      RecordGroupType.Date,
      formatISO(date, { representation: `date` }),
      todayPageDateToTitle(date),
      dateToShortTitle(date),
    )
    groups.push(dateRecordGroup)
  })
  return groups
}
