import { RouteComponentProps, Router } from '@gatsbyjs/reach-router'
import { Session } from '@stytch/vanilla-js'
import { init as initCommandBar } from 'commandbar'
import React, { FC, useEffect, useRef } from 'react'
import * as Analytics from '../common/analytics/capture-analytics-actions'
import { AuthenticateRoute, DesktopAuthenticateRoute } from '../common/auth'
import { SHOULD_SKIP_LANDING_COOKIE_NAME } from '../common/contexts/AuthContext'
import { useThemeName } from '../common/contexts/ThemeNameContext'
import { Env } from '../common/env'
import { safeNavigate } from '../common/navigate'
import { LoginRoutePath } from '../common/routes'
import { deleteCookie } from '../common/utils'
import AppUrlListener from '../components/AppUrlListener'
import RegisterQuickCaptureWindow, {
  QUICK_CAPTURE_SCREEN_PATHNAME,
} from '../components/RegisterQuickCaptureWindow'
import { Providers, useAuth } from '../providers'
import { LoggedIn } from '../screens'
import DownloadContactScreen from '../screens/DownloadContactScreen'
import LoginScreen from '../screens/LoginScreen/LoginScreen'
import { QuickCaptureScreen } from '../screens/QuickCaptureScreen/QuickCaptureScreen'
import { RegistrationScreen } from '../screens/RegistrationScreen/RegistrationScreen'
import ResetPasswordScreen from '../screens/ResetPasswordScreen/ResetPasswordScreen'

!Env.isSSR && initCommandBar(Env.COMMAND_BAR_TEAM_ID)

const App: FC = () => (
  <Providers>
    <AppUrlListener />
    <RegisterQuickCaptureWindow />
    <Router>
      <AuthenticateRoute path="authenticate" />
      <DesktopAuthenticateRoute path="desktop-authenticate" />
      <DownloadContactScreen path="onboardingDownloadContact" />
      <LoginScreen path="login" />
      <RegistrationScreen path="registration" />
      <RegistrationScreen path="invite" />
      {/*
       * This route needs to be in both places to account for time it takes to authenticate when
       * user is re-entering a forgot password flow from the login screen.
       *
       * Without this, the router will automatically re-route back to login and the
       * reset-password context will be lost, landing the user in inbox upon successful
       * authentication, instead of on the reset-password screen.
       */}
      <ResetPasswordScreen path="reset-password" />
      {/*
       * QuickCaptureScreen is in the unauthed providers for now because:
       * 1. It's a new window that does not share react context state with the main window. Electron windows run in an entirely different process and context changes from one do not automatically sync to other windows.
       * 2. We need to access the updated theme state from the main window, but because of 1), we need to read the theme state from localstorage from the useSavedThemeName hook.
       * 3. We currently can't have more than 1 listener on a localstorage key and moving the screen into protected providers breaks that (UIContext / useSavedThemeName try to register a listener each time they're used).
       *
       * Solutions for the above 3 would be to either:
       * 1. Pull the saved theme name localstorage listener up higher into the unprotected providers
       * 2. Remove the single listener limitation in localstorage proxy
       * 3. Manually get the item from localstorage and register a listener by bypassing the localstorageproxy entirely.
       *
       * Bias for shipping calls for getting quick capture out and coming back to this later if necessary.
       */}
      <QuickCaptureScreen path={QUICK_CAPTURE_SCREEN_PATHNAME} />
      <PrivateRoute path="/*" Screen={LoggedIn} />
    </Router>
  </Providers>
)

export default App

type PrivateRouteProps = RouteComponentProps & {
  Screen: FC<RouteComponentProps>
}

const PrivateRoute: FC<PrivateRouteProps> = ({ Screen, ...restProps }) => {
  const { isLoggedIn } = useAuth()
  useSessionEnded()
  useLoggedOut()
  return isLoggedIn ? <Screen {...restProps} /> : null
}

const useLoggedOut = () => {
  const { isLoggedIn, revokeSessionAndDeleteCookies } = useAuth()

  /**
   * When we get background session revokes, we want to make sure the invalid
   * cookies are deleted. This is especially important on iOS, where cookies sometimes
   * hang around, due to a mobile safari bug in capacitor.
   *
   * Additionally, in a multiple client scenario, (read: 3+ tabs open on web) if we don't
   * ensure that we are switching from isLoggedIn to !isLoggedIn, we often
   * see a race condition where the cookies get deleted immediately after being issued because
   * a background client is on the log in screen
   */

  function onLoggedOut() {
    revokeSessionAndDeleteCookies().then(() => {
      safeNavigate(LoginRoutePath)
    })
    Analytics.loggedOut()
  }

  const prevLoggedInValue = useRef<boolean>()
  useEffect(() => {
    if (prevLoggedInValue.current !== isLoggedIn && !isLoggedIn) {
      onLoggedOut()
    }
    prevLoggedInValue.current = isLoggedIn
  }, [isLoggedIn, onLoggedOut])
}

const useSessionEnded = () => {
  const { session, revokeSessionAndDeleteCookies } = useAuth()
  const { setThemeName } = useThemeName()

  function onSessionChangedToNull() {
    Analytics.sessionRemoved()
    revokeSessionAndDeleteCookies()
    deleteCookie(SHOULD_SKIP_LANDING_COOKIE_NAME)
    setThemeName('StationaryLight')
  }

  const prevSessionValue = useRef<Session | null>(null)
  useEffect(() => {
    if (prevSessionValue.current !== session && session === null) {
      onSessionChangedToNull()
    }
    prevSessionValue.current = session
  }, [session, onSessionChangedToNull])
}
