import { DataRecord, isNoteableRecord } from '@eleventhlabs/capture-shared'

import { flatten, uniq } from 'lodash'
import { BaseSelection } from '../../../../libs/selection'

export const deleteNotes = (
  selection: BaseSelection,
  selectedRecords: DataRecord[],
  deleteNotes: (noteIds: string[]) => void,
) => {
  const noteIds = uniq(
    flatten(
      selectedRecords.map((record) => {
        if (isNoteableRecord(record)) {
          return record.notes
        }
        return []
      }),
    ),
  )

  deleteNotes(noteIds)

  selection.clear()

  return noteIds
}
