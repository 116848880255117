import {
  CURRENT_RICH_TEXT_VERSION,
  RichText,
  UpdateNoteRecordPayload,
  UpdateTextRecordPayload,
} from '@eleventhlabs/capture-shared'

export const recordChange = (
  value: RichText,
  recordId: string,
  updateRecordText: (
    recordId: string,
    payload: UpdateTextRecordPayload | UpdateNoteRecordPayload,
  ) => void,
) => {
  updateRecordText(recordId, {
    richText: { value, version: CURRENT_RICH_TEXT_VERSION },
  })
}
