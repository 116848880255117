import { NoteRecord } from '@eleventhlabs/capture-shared'
import { NoteListData } from '../types'
import { NOTE } from '../../../utils/uiConstants'

export const createNoteListData = (
  notes: NoteRecord[],
  title?: string,
  showAddNote = true,
): NoteListData => ({
  title: title ?? NOTE,
  notes,
  showAddNote,
})
