import { parseISO } from 'date-fns'
import React, { useMemo } from 'react'
import { NavigateFn, useNavigate } from '@gatsbyjs/reach-router'

import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { titleOrDefault } from '../../../common/records'
import {
  RouteType,
  getBreadcrumbRoute,
  getTitleFromRoute,
} from '../../../common/routes'
import {
  DataRecord,
  RootRecordId,
  UserCreatedRecord,
  isUserCreateableRecord,
} from '@eleventhlabs/capture-shared'
import {
  getDaySubTitle,
  startOfTodayISO,
  todayPageDateToTitle,
} from '../../../common/utils'
import { Breadcrumb } from './types'
import { RouteIcon } from '../../RouteIcon'
import { cx, styled } from '../../../common/stationary'
import { isMobile } from '../../../common/utils/env'
import { useTodayFilters } from '../../../common/contexts/helpers/useTodayFilters'
import { useTodayControls } from '../../../screens/TodayScreen/hooks/useTodayControls'

export function useBreadcrumbs(
  listId: string | undefined,
  recordId: string | undefined,
  routeType?: RouteType,
): Breadcrumb[] {
  const { store } = useModelStoreContext()
  const { activeDate } = useTodayControls(store)
  const navigate = useNavigate()
  const records = store.records

  const breadcrumbs: Breadcrumb[] = useMemo(() => {
    switch (routeType) {
      case RouteType.Today: {
        const date = parseISO(activeDate)
        const dayTitle = getDaySubTitle(date)

        return [
          buildTodayTitleBreadcrumb(todayPageDateToTitle(date), routeType),
          buildTodaySubtitleBreadcrumb(dayTitle || ''),
        ]
      }
      case RouteType.Inbox:
      case RouteType.List: {
        return [buildBreadcrumb(navigate, records, false, routeType, listId)]
      }
      case RouteType.Details: {
        let currentId = recordId
        if (!currentId) return []
        const routeOfIds: string[] = [currentId]
        do {
          const currentRecord = records[currentId] as UserCreatedRecord
          if (
            currentRecord.parentId !== RootRecordId &&
            currentRecord.parentId !== undefined
          )
            routeOfIds.push(currentRecord.parentId)
          currentId = currentRecord.parentId
        } while (
          currentId !== undefined &&
          isUserCreateableRecord(records[currentId])
        )
        return routeOfIds.reverse().map((id, index) => {
          const notLastElement = index < routeOfIds.length - 1
          return buildBreadcrumb(
            navigate,
            records,
            notLastElement,
            routeType,
            listId,
            id,
          )
        })
      }
      default:
        return []
    }
  }, [navigate, records, routeType, listId, recordId])

  return breadcrumbs
}

function buildBreadcrumb(
  navigate: NavigateFn,
  records: Record<string, DataRecord>,
  notLastElement: boolean,
  routeType: RouteType,
  listId?: string,
  recordId?: string,
): Breadcrumb {
  const onClick = () => {
    const route = getBreadcrumbRoute(records, routeType, listId, recordId) || ``
    navigate(route)
  }
  // title
  const titleRenderer = () => (
    <S.TitleText
      onClick={notLastElement ? onClick : undefined}
      className={cx({
        notLastElement,
      })}
    >
      {titleOrDefault(getTitleFromRoute(records, routeType, listId, recordId))}
    </S.TitleText>
  )

  // icon
  const id = recordId ?? listId
  const record = id ? records[id] : undefined

  const iconRenderer = () => (
    <RouteIcon boxSize={16} routeType={routeType} value={record} />
  )

  return {
    id: `${routeType}-${id}`,
    titleRenderer,
    iconRenderer,
  }
}

function buildTodayTitleBreadcrumb(
  title: string,
  routeType: RouteType,
): Breadcrumb {
  const titleRenderer = () => <S.TitleText>{title}</S.TitleText>

  const iconRenderer = () => <RouteIcon boxSize={16} routeType={routeType} />

  return {
    id: `today-title`,
    titleRenderer,
    iconRenderer,
  }
}

function buildTodaySubtitleBreadcrumb(dayTitle: string): Breadcrumb {
  const titleRenderer = () => <S.DayTitle>{dayTitle}</S.DayTitle>

  return {
    id: `today-subtitle`,
    titleRenderer,
  }
}

const S = {
  TitleText: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 600,
    lineHeight: `16px`,
    color: theme.colors.text[900],
    marginLeft: 5,
    maxWidth: isMobile ? `50%` : '180px',
    textOverflow: `ellipsis`,
    overflowX: `hidden`,

    '&.notLastElement': {
      fontWeight: 400,
      cursor: `pointer`,
    },
  })),
  DayTitle: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `16px`,
    fontWeight: 500,
    whiteSpace: `nowrap`,
    color: theme.colors.text[900],
  })),
  DayTitleSeparator: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `16px`,
    fontWeight: 800,
    whiteSpace: `nowrap`,
    color: theme.colors.text[900],
    margin: `0 5px`,
  })),
  DaySubTitle: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `16px`,
    fontWeight: 500,
    whiteSpace: `nowrap`,
    color: theme.colors.text[500],
  })),
}
