import { omit } from 'lodash'
import { WriteTransaction } from 'replicache'

import { CompleteableRecord, RCMutation } from '@eleventhlabs/capture-shared'

import { getRecord, upsertRecord } from './utils'

export const uncompleteRecords = async (
  tx: WriteTransaction,
  { recordIds }: RCMutation.UncompleteRecordsArgs,
) => {
  for (const recordId of recordIds) {
    await uncompleteRecord(tx, recordId)
  }
}

const uncompleteRecord = async (tx: WriteTransaction, recordId: string) => {
  const record = await getRecord(tx, recordId)
  await upsertRecord(tx, record.id, {
    ...(omit(record, [`completedAt`]) as CompleteableRecord),
    isCompleted: false,
  })
  // TODO:
  // If record type is CompletedOccurrence
  // Reset ISO of the original
  // Delete the CompletedOccurrence
}
