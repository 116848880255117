import { ModalUnstyled, ModalUnstyledProps } from '@mui/base'
import React, { ChangeEvent, useState } from 'react'
import { BiClipboard, BiMailSend, BiX } from 'react-icons/bi'

import diagramWrappedGiftEmojiSrc from '../../../assets/diagrams/diagram-wrapped-gift-emoji@2x.png'
import Analytics from '../../../common/analytics/capture-analytics-actions'
import { Env } from '../../../common/env'
import { ClickEvent } from '../../../common/recordItemEventHandlers/types'
import { cx, styled, styledMixin } from '../../../common/stationary'
import {
  ContentDescription,
  ContentDiagramDialog,
  ContentDiagramDialogInnerWidth,
  ContentDivider,
  ContentTitle,
  DialogContent,
  DialogDiagram,
  DialogHeader,
  DialogInner,
  DividerText,
  HeaderCloseButton,
} from '../../../components/ContentDiagramDialog'

const INVITE_LINK_INPUT_ID = 'invite-link-input'

const getInviteLinkUrl = (inviteCode: string) =>
  `${Env.captureWebUrl}/invite?code=${inviteCode}`

const getInviteEmailMailToLink = (inviteEmail: string, inviteLinkUrl: string) =>
  `mailto:${inviteEmail}?subject:SUBJECT&body=${inviteLinkUrl}`

export type InviteModalEmailValueStatus = 'VALID' | 'EMPTY' | 'NOT_EMAIL'

export type InviteModalEmailFormStatus = 'READY' | 'SUCCESS' | 'ERROR'

export interface InviteModalProps extends Omit<ModalUnstyledProps, 'children'> {
  inviteCode: string
  inviteEmailFormStatus: InviteModalEmailFormStatus
  inviteEmailValue: string
  inviteEmailValueStatus: InviteModalEmailValueStatus
  onClose?: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick',
  ) => void
  onInviteEmailChange?: (e: ChangeEvent<HTMLInputElement>) => void
  /**
   * InviteEmailForm is not currently an actual `<form />` since we're taking a
   * shortcut and using an `<a href="mailto..." />` to handle email invites
   */
  onInviteEmailFormSubmit?: () => void
}

export function InviteModal({
  inviteEmailFormStatus,
  inviteEmailValue,
  inviteEmailValueStatus,
  inviteCode,
  onClose,
  onInviteEmailChange,
  onInviteEmailFormSubmit,
  open,
  slots,
  ...props
}: InviteModalProps) {
  const inviteLinkUrl = getInviteLinkUrl(inviteCode)

  const [inviteClicked, setInviteClicked] = useState(false)

  const handleCloseButtonClick = (e: ClickEvent) => {
    onClose && onClose(e, 'buttonClick')
    setInviteClicked(false)
  }

  const handleInviteLinkControlClick = () => {
    setInviteClicked(true)

    Analytics.inviteCodeCopied({
      inviteCode,
    })
    Analytics.inviteSharingStarted({ inviteCode })

    if (document && inviteLinkUrl.length > 0) {
      var input = document.getElementById(
        INVITE_LINK_INPUT_ID,
      ) as HTMLInputElement
      input.select()
      document.execCommand('copy')
    }
  }

  return (
    <S.InviteModal
      onClose={onClose}
      open={open}
      slots={{ backdrop: S.Backdrop, ...slots }}
      {...props}
    >
      <ContentDiagramDialog>
        <DialogHeader>
          <HeaderCloseButton onClick={handleCloseButtonClick}>
            <BiX />
          </HeaderCloseButton>
        </DialogHeader>
        <DialogInner>
          <DialogContent>
            <ContentTitle>Share invites</ContentTitle>
            <ContentDescription>
              Know someone who’d like Capture? Gift them early access using{' '}
              <strong>your invite link</strong>:
            </ContentDescription>
            <S.InviteLinkControl onClick={handleInviteLinkControlClick}>
              <S.InviteLinkInput
                id={INVITE_LINK_INPUT_ID}
                placeholder="Invite link unavailable"
                readOnly
                value={inviteLinkUrl}
              />
              <S.InviteLinkCopyButton onClick={handleInviteLinkControlClick}>
                <BiClipboard />
                <strong>{!inviteClicked ? 'Copy' : 'Copied!'}</strong>
              </S.InviteLinkCopyButton>
            </S.InviteLinkControl>
            <ContentDivider>
              <DividerText>Or</DividerText>
            </ContentDivider>
            <ContentDescription>Send an invite via email:</ContentDescription>
            <S.InviteEmailForm>
              <S.InviteEmailInput
                onChange={onInviteEmailChange}
                placeholder="Email address"
                required
                type="email"
                value={inviteEmailValue}
              />
              <S.InviteEmailSendButton
                className={cx({ isFaded: inviteEmailValueStatus === 'EMPTY' })}
                href={
                  inviteEmailValueStatus === 'VALID'
                    ? getInviteEmailMailToLink(inviteEmailValue, inviteLinkUrl)
                    : undefined
                }
                onClick={onInviteEmailFormSubmit}
              >
                <BiMailSend />
                <strong>Send invite</strong>
              </S.InviteEmailSendButton>
            </S.InviteEmailForm>
            <S.InviteEmailCallout className={cx(inviteEmailFormStatus)}>
              {inviteEmailFormStatus === 'SUCCESS' && (
                <>Opening invite in email client...</>
              )}
              {inviteEmailFormStatus === 'ERROR' &&
                inviteEmailValueStatus === 'EMPTY' && (
                  <>Please enter an email</>
                )}
              {inviteEmailFormStatus === 'ERROR' &&
                inviteEmailValueStatus === 'NOT_EMAIL' && (
                  <>Please enter a valid email</>
                )}
            </S.InviteEmailCallout>
          </DialogContent>
        </DialogInner>
        <DialogInner>
          <DialogDiagram src={diagramWrappedGiftEmojiSrc} />
        </DialogInner>
      </ContentDiagramDialog>
    </S.InviteModal>
  )
}

// const ContentDiagramDialogInnerWidth = 448

const InviteInputMixin = styledMixin`
  flex: 1;
  height: 40px;
  min-width: 0;
  padding: 0 16px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.text[600]};
  }
`

const InviteButtonMixin = styledMixin`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 32px;
  margin-right: 4px;
  padding: 0 12px;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.effects.boxShadow.elevation[2]};
  background: ${({ theme }) => theme.colors.surface.base};
  ${({ theme }) => theme.text.publicSans['13.5:20']};
  cursor: pointer;

  strong {
    font-weight: 600;
  }
  svg {
    color: ${({ theme }) => theme.colors.text[600]};
  }
  :disabled, &.isFaded {
    opacity: 0.8;
  }
  :hover:not(:disabled) {
    box-shadow: ${({ theme }) => theme.effects.boxShadow.elevation[3]};
    opacity: 1;
  }
`

const S = {
  InviteModal: styled(ModalUnstyled)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    z-index: 999;

    @media screen and (max-width: ${ContentDiagramDialogInnerWidth * 2}px) {
      align-items: flex-start;
      overflow-y: scroll;
    }
  `,
  Backdrop: styled.div`
    position: fixed;
    inset: 0;
    z-index: -1;
    background: ${({ theme }) => theme.colors.alpha.shade.extraStrong};
  `,
  InviteLinkControl: styled.div`
    display: flex;
    align-items: center;
    margin: 0 -8px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.alpha.tone.weak};
    cursor: pointer;

    :hover {
      background: ${({ theme }) => theme.colors.alpha.tone.mediumWeak};
    }
    :active {
      background: ${({ theme }) => theme.colors.alpha.tone.weak};
    }
  `,
  InviteLinkInput: styled.input`
    ${InviteInputMixin};
    padding-right: 0;
    ${({ theme }) => theme.text.publicSans['13.5:20']};
  `,
  InviteLinkCopyButton: styled.button`
    ${InviteButtonMixin};
  `,
  InviteEmailForm: styled.div`
    display: flex;
    align-items: center;
    margin: 0 -8px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.alpha.tone.weak};
    border: 1px solid ${({ theme }) => theme.colors.alpha.tone.mediumWeak};

    :has(input:focus) {
      border-color: ${({ theme }) => theme.colors.alpha.tone.mediumStrong};
    }
  `,
  InviteEmailInput: styled.input`
    ${InviteInputMixin};
  `,
  InviteEmailSendButton: styled.a`
    ${InviteButtonMixin};
  `,
  InviteEmailCallout: styled.div`
    margin-top: 8px;
    padding: 2px 0;
    ${({ theme }) => theme.text.publicSans['13.5:20']};

    &.SUCCESS {
      color: ${({ theme }) => theme.colors.green[700]};
    }
    &.ERROR {
      color: ${({ theme }) => theme.colors.red[700]};
    }
  `,
}
