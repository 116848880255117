export const decodeRecentPicks = (
  itemIds: string[],
  recentPicksStr: string | null,
): string[] => {
  if (!recentPicksStr) return []

  const recentPicks = JSON.parse(recentPicksStr) as string[]
  return recentPicks.filter((id) => itemIds.includes(id))
}

export const alphabeticallyAscending = (a: string, b: string) => {
  const aL = a.toLowerCase()
  const bL = b.toLowerCase()
  if (aL < bL) return -1
  if (bL < aL) return 1
  return 0
}

export const alphabeticallyDescending = (a: string, b: string) => {
  const aL = a.toLowerCase()
  const bL = b.toLowerCase()
  if (aL > bL) return -1
  if (bL > aL) return 1
  return 0
}
