import React from 'react'

import { DragSourceType } from '../../../common/dnd/drag/types'
import {
  DropTargetType,
  ShortcutDropProps,
} from '../../../common/dnd/drop/types'
import { useRegisterDropTarget } from '../../../common/dnd/drop/useRegisterDropTarget'
import { RouteType, isSystemListId } from '../../../common/routes'
import { Button, Count, cx, styled } from '../../../common/stationary'
import { RouteIcon } from '../../RouteIcon'

export interface Shortcut {
  count?: number
  id: string
  onClick?: React.MouseEventHandler
  placement: 'top' | 'bottom'
  routeType: RouteType
  title: string
}

export interface ShortcutGroupProps {
  activeIndex?: number
  className?: string
  shortcuts: Shortcut[]
  placement: 'top' | 'bottom'
}

export const ShortcutGroup: React.FC<ShortcutGroupProps> = ({
  activeIndex,
  className,
  shortcuts,
  placement,
}) => (
  <S.ShortcutGroup className={className}>
    {shortcuts.map(
      (shortcut, index) =>
        shortcut.placement === placement && (
          <ShortcutItem
            key={index}
            config={shortcut}
            isRouteActive={index === activeIndex}
          />
        ),
    )}
  </S.ShortcutGroup>
)

interface ShortcutItemProps {
  config: Shortcut
  isRouteActive: boolean
}

const ShortcutItem: React.FC<ShortcutItemProps> = ({
  config: { count, id, onClick, routeType, title },
  isRouteActive,
}) => {
  if (!isSystemListId(id)) return null

  const [dropRef, { isValidAndHovered, isDragInProgress }] =
    useRegisterDropTarget<ShortcutDropProps>(
      DropTargetType.SHORTCUT,
      [DragSourceType.RECORD],
      { id },
    )

  return (
    <S.ShortcutItem
      className={cx({ isDragInProgress, isRouteActive, isValidAndHovered })}
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
      ref={dropRef}
    >
      <S.IconWrapper>
        <RouteIcon boxSize={18} routeType={routeType} />
      </S.IconWrapper>
      {title}
      {count !== undefined ? (
        <S.CountWrapper>
          <S.Count value={count} showZero />
        </S.CountWrapper>
      ) : null}
    </S.ShortcutItem>
  )
}

const ShortcutItemDimensions = {
  height: 36,
}

const S = {
  ShortcutGroup: styled.div({
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
  }),
  Divider: styled.div(({ theme }) => ({
    display: 'flex',
    height: 1,
    background: theme.colors.alpha.border.weak,
  })),
  ShortcutItem: styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 8,
    height: ShortcutItemDimensions.height,
    cursor: 'pointer',
    transition: 'background 0.2s ease',

    '&:not(.isDragInProgress)': {
      '&:hover': {
        background: theme.colors.alpha.tone.extraWeak,
      },
    },
    '&.isRouteActive': {
      fontWeight: 600,
      background: theme.colors.alpha.tone.weak,
    },
    '&.isValidAndHovered': {
      background: theme.colors.alpha.selection.medium,
    },
  })),
  IconWrapper: styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
  }),
  CountWrapper: styled.div({
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 10,
  }),
  Count: styled(Count)(({ theme }) => ({
    borderRadius: 4,
    color: theme.colors.text[500],
    background: theme.colors.alpha.tone.extraWeak,
  })),
}
