import { WriteTransaction } from 'replicache'

import {
  RCMutation,
  UpdateRecordPayload,
  withPropUpdatesApplied,
} from '@eleventhlabs/capture-shared'

import { getRecord, upsertRecord } from './utils'

export const updateProps = async (
  tx: WriteTransaction,
  { updates }: RCMutation.UpdatePropsArgs,
) => {
  for (const { recordId, payload } of updates) {
    await updatePropsForRecord(tx, recordId, payload)
  }
}

const updatePropsForRecord = async (
  tx: WriteTransaction,
  recordId: string,
  payload: UpdateRecordPayload,
): Promise<void> => {
  const record = await getRecord(tx, recordId)
  if (record === undefined) return
  const updatedRecord = withPropUpdatesApplied(record, payload)
  await upsertRecord(tx, record.id, updatedRecord)
}
