import React from 'react'

import { ResultType } from '../../../common/data/useDestinations'
import {
  HandleResultAction,
  ResultItem as ResultItemData,
} from '../MoveTo.types'
import MoveItem from './MoveItem'
import MoveSeparator from './MoveSeparator'

export interface ResultItemProps {
  item: ResultItemData
  isActive: boolean
  onHover: (id: string) => void
  handleResultClick: HandleResultAction
}

const ResultItem = ({
  item,
  isActive,
  onHover,
  handleResultClick,
}: ResultItemProps) =>
  item.type === ResultType.Destination ? (
    <MoveItem
      key={item.id}
      {...item}
      isSelected={isActive}
      handleResultClick={handleResultClick}
      onHover={onHover}
    />
  ) : (
    <MoveSeparator {...item} />
  )

export default ResultItem

export const ResultItemDimensions = {
  Separator: {
    height: 32,
  },
  Item: {
    height: 40,
  },
}
