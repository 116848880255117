import { useCallback, useMemo } from 'react'

import Analytics from '../../../common/analytics/capture-analytics-actions'
import { useExecContext } from '../../../common/contexts/ExecContext'
import { useModelStoreContext } from '../../../common/contexts/ModelStoreContext'
import { useUIContext } from '../../../common/contexts/UIContext'
import { DialogType } from '../../../common/dialogs/types'
import { Env } from '../../../common/env'
import { downloadObjectAsJson } from '../../../common/utils/downloadObjectAsJson'
import { isMobile } from '../../../common/utils/env'
import { useModal } from '../../../common/modals/core/hooks/useModal'
import { ModalName } from '../../../common/modals/core/modalConfig'
import { useKeyCaps } from '../../../common/keyMappableActions/useKeyCaps'
import { PASSWORD_RESET, PASSWORD_SET } from '../../../common/utils/uiConstants'
import { useAuth } from '../../../providers'

interface UserMenuConfig {
  shouldShow?: boolean
  onClick?: () => void
  title?: string
  keycaps?: string[]
  disabled?: boolean
  type: 'item' | 'divider'
}
export const useUserMenu = () => {
  const { exec } = useExecContext()
  const { store } = useModelStoreContext()
  const { showView, toggleIsLeftHidden } = useUIContext()
  const { openModal } = useModal()
  const { isUserWithPassword } = useAuth()

  const keyCaps = useKeyCaps()

  const username = store.user.username

  const signOut = useCallback(() => {
    exec({ type: 'LOGOUT', logoutTrigger: 'Menu Bar' })
  }, [exec])

  const downloadUserDataAsJson = useCallback(() => {
    downloadObjectAsJson(store, `userData`)
  }, [store])

  const downloadUserData = useMemo(
    () => (Env.appEnv === `development` ? downloadUserDataAsJson : undefined),
    [downloadUserDataAsJson],
  )

  const viewJWT = useCallback(async () => {
    exec({ type: 'VIEW_JWT' })
  }, [username])

  const resetPassword = useCallback(() => {
    openModal(ModalName.RESET_PASSWORD_MODAL)
  }, [openModal])

  const showKeyboardShortcutsPane = useCallback(
    () => exec({ type: 'TOGGLE_KEYBOARD_SHORTCUTS_PANE' }),
    [showView],
  )

  const showCommandBar = useCallback(
    () => showView(DialogType.CommandBar),
    [showView],
  )

  const goToTrash = useCallback(() => {
    if (isMobile) toggleIsLeftHidden()

    exec({
      type: 'GO_TO_TRASH',
      trigger: Analytics.ScreenNavigationStartedTrigger.USER_ACTIONS_CLICK,
    })
  }, [exec, toggleIsLeftHidden])

  const actions = useMemo(
    () => ({
      signOut,
      downloadUserData,
      viewJWT,
      resetPassword,
      showKeyboardShortcutsPane,
      showCommandBar,
      goToTrash,
    }),
    [
      signOut,
      downloadUserData,
      viewJWT,
      resetPassword,
      showKeyboardShortcutsPane,
      showCommandBar,
      goToTrash,
    ],
  )

  const userMenuItems: UserMenuConfig[] = useMemo(
    () => [
      {
        onClick: actions.showCommandBar,
        title: 'Open Command Bar',
        type: 'item',
        keycaps: keyCaps['KEY_MAPPED_OPEN_COMMAND_BAR_DIALOG'],
        shouldShow: true,
      },
      {
        onClick: actions.showKeyboardShortcutsPane,
        title: 'View keyboard shortcuts',
        type: 'item',
        keycaps: keyCaps['KEY_MAPPED_OPEN_KEYBOARD_SHORTCUT_DIALOG'],
        shouldShow: !isMobile,
      },
      {
        onClick: actions.goToTrash,
        title: 'View Trash',
        type: 'item',
        shouldShow: true,
      },
      {
        type: 'divider',
        shouldShow: true,
      },
      {
        onClick: actions.viewJWT,
        title: 'View JWT',
        type: 'item',
        shouldShow: true,
      },
      {
        onClick: actions.resetPassword,
        title: `${isUserWithPassword ? PASSWORD_RESET : PASSWORD_SET} password`,
        type: 'item',
        shouldShow: true,
      },
      {
        onClick: actions.signOut,
        title: 'Log out',
        type: 'item',
        shouldShow: true,
      },
      {
        type: 'divider',
        shouldShow: Env.appEnv === `DEVELOPMENT`,
      },
      {
        onClick: actions.downloadUserData,
        title: 'Download data as JSON',
        type: 'item',
        disabled: actions.downloadUserData === undefined,
        shouldShow: Env.appEnv === `DEVELOPMENT`,
      },
    ],
    [actions, keyCaps],
  )

  return userMenuItems
}
