import { indexOf, isEmpty } from 'lodash'

import { EditorsManager } from '../../../../EditorsManager'
import {
  DataRecord,
  isNoteableRecord,
  RecordType,
} from '@eleventhlabs/capture-shared'

export const recordBackspaceAtFirstPointWhenNotEmpty = (
  records: Record<string, DataRecord>,
  recordIds: string[],
  recordId: string,
  deleteRecordsById: (recordIds: string[]) => void,
) => {
  const record = records[recordId]
  const prevId = recordIds[indexOf(recordIds, recordId) - 1]

  const isTypeText = record.type === RecordType.Text
  const isPrevTypeText = records[prevId]?.type === RecordType.Text
  const canDelete = !isNoteableRecord(record) || isEmpty(record.notes)
  if (!canDelete || !isPrevTypeText || !isTypeText) return false

  const tailRichText = EditorsManager.get.richText(recordId)
  deleteRecordsById([recordId])
  EditorsManager.effects.appendAndFocusAtIntersection(prevId, tailRichText)
}
