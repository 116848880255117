import React from 'react'

import { keyframes } from '@emotion/react'
import {
  Button,
  ButtonProps,
  Icon,
  IconVariantName,
  WithTooltipProps,
  css,
  cx,
  styled,
  withTooltip,
} from '../../../common/stationary'

interface SecondaryButtonProps extends WithTooltipProps<ButtonProps> {
  isSelected: boolean
  onPointerEnter: () => void
  iconVariant: IconVariantName
}

export const SecondaryButton = React.forwardRef<
  HTMLButtonElement,
  SecondaryButtonProps
>(({ isSelected, onPointerEnter, iconVariant, ...props }, ref) => (
  <S.SecondaryButton
    ref={ref}
    className={cx({
      isSelected,
    })}
    onPointerEnter={onPointerEnter}
    {...props}
  >
    <Icon
      boxSize={16}
      variant={iconVariant}
      className={css({
        color: 'white',
      })}
    />
  </S.SecondaryButton>
))

const kf = {
  animatePop: keyframes({
    '0%': {
      opacity: 0,
      transform: `scale(0.5, 0.5)`,
    },
    '100%': {
      opacity: 1,
      transform: `scale(1, 1)`,
    },
  }),
}

const S = {
  SecondaryButton: styled(withTooltip(Button))(({ theme }) => ({
    all: 'unset',
    height: '56px',
    width: '56px',
    borderRadius: '999px',
    boxShadow: theme.effects.boxShadow.elevation[3],
    background: `linear-gradient(#0000, rgb(0 0 0/40%)) top/100% 800%`,
    backgroundColor: theme.colors.blue[700],
    cursor: `pointer`,
    display: `flex`,
    alignItems: 'center',
    justifyContent: 'center',
    WebkitTouchCallout: `none`,
    WebkitUserSelect: `none`,
    WebkitTextSizeAdjust: `none`,

    // Animate button in
    animationDuration: `0.1s`,
    animationName: kf.animatePop,
    animationTimingFunction: `cubic-bezier(.26,.53,.74,1.48)`,
    animationFillMode: `backwards`,
    '&.isSelected': {
      backgroundPosition: `bottom`,
    },
  })),
}
