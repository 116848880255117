import { renderToString } from 'react-dom/server'
import { useReactDnDContext } from '../../../../contexts/ReactDnDContext'
import {
  BaseSelection,
  SelectionInteractions,
} from '../../../../libs/selection'
import { setSelectionForShiftClickToTarget } from '../../../../selection'

export const recordHandleClicked = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  recordId: string,
  recordIds: string[],
  selection: BaseSelection,
  selectionInteractions: SelectionInteractions,
): string[] => {
  deselectAllText()

  if (event.metaKey) {
    selection.togglePresence(recordId)
    return [recordId]
  }

  const isSelectionEmpty = selection.ids.length === 0
  if (event.shiftKey) {
    if (isSelectionEmpty) {
      selection.set([recordId])
      return [recordId]
    } else {
      return setSelectionForShiftClickToTarget(
        recordIds,
        selection,
        recordId,
        selectionInteractions,
      )
    }
  }

  const isRegularClick = !(event.metaKey || event.shiftKey)
  if (isRegularClick) {
    const isClickedRecordInSelection = selection.ids.includes(recordId)
    const isSelectionCollapsed = selection.ids.length === 1
    const isSelectionCollapsedAtClickedRecord =
      isClickedRecordInSelection && isSelectionCollapsed
    if (isSelectionCollapsedAtClickedRecord) selection.clear()
    else selection.set([recordId])

    return [recordId]
  }

  return []
}

function deselectAllText() {
  if (window.getSelection) {
    window?.getSelection()?.removeAllRanges()
  }
}
