import { Keyboard } from '@capacitor/keyboard'
import { useEffect, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import { isMobile } from '../../../utils/env'

/**
 * This hook is a best-effort to detect whether the virtual keyboard is
 * showing in a mobile environment. Capacitor provides a `Keyboard` API we can
 * use, but it doesn't work for mobile web. To do that, we make a best guess with
 * `visualViewport` since the `VirtualKeyboard` API isn't well supported in mobile browers.
 *
 * - https://stackoverflow.com/a/74618784
 * - https://developer.mozilla.org/en-US/docs/Web/API/VisualViewport
 */
const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75

export const useVirtualKeyboardState = (): {
  isVirtualKeyboardVisible: boolean
} => {
  const [isVirtualKeyboardVisible, setIsVirtualKeyboardVisible] =
    useState(false)

  useEffect(() => {
    if (!isMobile) return

    const visualViewportResizeListener = (event: any) => {
      if (
        (event.target.height * event.target.scale) / window.screen.height <
        VIEWPORT_VS_CLIENT_HEIGHT_RATIO
      ) {
        setIsVirtualKeyboardVisible(true)
      } else {
        setIsVirtualKeyboardVisible(false)
      }
    }

    // Use the Capacitor Keyboard plugin if we can — works on mobile app env.
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardWillShow', () => {
        setIsVirtualKeyboardVisible(true)
      })
      Keyboard.addListener('keyboardDidShow', () => {
        setIsVirtualKeyboardVisible(true)
      })

      Keyboard.addListener('keyboardWillHide', () => {
        setIsVirtualKeyboardVisible(false)
      })

      Keyboard.addListener('keyboardDidHide', () => {
        setIsVirtualKeyboardVisible(false)
      })
    } else if ('visualViewport' in window && window.visualViewport) {
      // Hack for mobile web since Capacitor keyboard doesn't work there :(
      window.visualViewport.addEventListener(
        'resize',
        visualViewportResizeListener,
      )
    }

    return () => {
      if (Capacitor.isPluginAvailable('Keyboard')) {
        Keyboard.removeAllListeners()
      }

      if ('visualViewport' in window && window.visualViewport) {
        window.visualViewport.removeEventListener(
          'resize',
          visualViewportResizeListener,
        )
      }
    }
  }, [])

  return { isVirtualKeyboardVisible }
}
