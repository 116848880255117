import { Key, useKeys } from '../../common/hooks/useKeys'
import { emptyFn } from '../../common/utils'

export interface ScreenHotKeys {
  onBackspace?: (e: KeyboardEvent) => void
  onC?: (e: KeyboardEvent) => void
  onCmdA?: (e: KeyboardEvent) => void
  onCmdC?: (e: KeyboardEvent) => void
  onCmdD?: (e: KeyboardEvent) => void
  onCmdDown?: (e: KeyboardEvent) => void
  onCmdEnter?: (e: KeyboardEvent) => void
  onCmdGreaterThan?: (e: KeyboardEvent) => void
  onCmdSlash?: (e: KeyboardEvent) => void
  onCmdUp?: (e: KeyboardEvent) => void
  onDownArrow?: (e: KeyboardEvent) => void
  onEnter?: (e: KeyboardEvent) => void
  onEsc?: (e: KeyboardEvent) => void
  onF?: (e: KeyboardEvent) => void
  onG?: (e: KeyboardEvent) => void
  onI?: (e: KeyboardEvent) => void
  onJ?: (e: KeyboardEvent) => void
  onL?: (e: KeyboardEvent) => void
  onLeft?: (e: KeyboardEvent) => void
  onM?: (e: KeyboardEvent) => void
  onN?: (e: KeyboardEvent) => void
  onQMark?: (e: KeyboardEvent) => void
  onRight?: (e: KeyboardEvent) => void
  onS?: (e: KeyboardEvent) => void
  onShiftDown?: (e: KeyboardEvent) => void
  onShiftTab?: () => void
  onShiftUp?: (e: KeyboardEvent) => void
  onT?: (e: KeyboardEvent) => void
  onTab?: (e: KeyboardEvent) => void
  onUpArrow?: (e: KeyboardEvent) => void
  onX?: (e: KeyboardEvent) => void
  onGreaterThan?: (e: KeyboardEvent) => void
  onLowerThan?: (e: KeyboardEvent) => void
  onCmdK?: (e: KeyboardEvent) => void
}

export const useScreenHotKeys = ({
  onBackspace = emptyFn,
  onC = emptyFn,
  onCmdA = emptyFn,
  onCmdC = emptyFn,
  onCmdD = emptyFn,
  onCmdDown = emptyFn,
  onCmdEnter = emptyFn,
  onCmdGreaterThan = emptyFn,
  onCmdSlash = emptyFn,
  onCmdUp = emptyFn,
  onDownArrow = emptyFn,
  onEnter = emptyFn,
  onEsc = emptyFn,
  onF = emptyFn,
  onG = emptyFn,
  onI = emptyFn,
  onJ = emptyFn,
  onL = emptyFn,
  onLeft = emptyFn,
  onM = emptyFn,
  onN = emptyFn,
  onQMark = emptyFn,
  onRight = emptyFn,
  onS = emptyFn,
  onShiftDown = emptyFn,
  onShiftTab = emptyFn,
  onShiftUp = emptyFn,
  onT = emptyFn,
  onTab = emptyFn,
  onUpArrow = emptyFn,
  onGreaterThan = emptyFn,
  onLowerThan = emptyFn,
  onCmdK = emptyFn,
  onX = emptyFn,
}: ScreenHotKeys) => {
  useKeys([Key.Delete, Key.Backspace], onBackspace)
  useKeys(Key.C, onC)
  useKeys([Key.CommandDown, Key.CommandShiftDown], onCmdDown)
  useKeys(Key.CommandEnter, onCmdEnter)
  useKeys(Key.CommandSlash, onCmdSlash)
  useKeys([Key.CommandUp, Key.CommandShiftUp], onCmdUp)
  useKeys([Key.Down, Key.J], onDownArrow)
  useKeys([Key.ShiftDown, Key.ShiftJ], onShiftDown)
  useKeys([Key.ShiftTab], onShiftTab)
  useKeys([Key.ShiftUp, Key.ShiftK], onShiftUp)
  useKeys([Key.Tab], onTab)
  useKeys([Key.Up, Key.K], onUpArrow)
  useKeys(Key.CommandA, onCmdA)
  useKeys(Key.CommandC, onCmdC)
  useKeys(Key.CommandD, onCmdD)
  useKeys(Key.CommandGreaterThan, onCmdGreaterThan)
  useKeys(Key.Enter, onEnter)
  useKeys(Key.Esc, onEsc)
  useKeys(Key.F, onF)
  useKeys(Key.G, onG)
  useKeys(Key.J, onJ)
  useKeys([Key.H, Key.Left], onLeft)
  useKeys(Key.I, onI)
  useKeys(Key.M, onM)
  useKeys(Key.N, onN)
  useKeys(Key.QMark, onQMark)
  useKeys(Key.Right, onRight)
  useKeys(Key.L, onL)
  useKeys(Key.S, onS)
  useKeys(Key.T, onT)
  useKeys(Key.GreaterThan, onGreaterThan)
  useKeys(Key.LowerThan, onLowerThan)
  useKeys(Key.CommandK, onCmdK)
  useKeys(Key.X, onX)
}
