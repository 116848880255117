import { useCallback } from 'react'
import { ReadTransaction, Replicache } from 'replicache'

import { getSoftDeletedRecords } from './query'
import {
  JSONObjWithId,
  useSubscribeArrWithStableIdentities,
} from './useSubscribe'
import { DeleteableRecord } from '@eleventhlabs/capture-shared'

export const useSoftDeletedRecords = (
  rep: Replicache | null,
  def: DeleteableRecord[],
  deps?: any[],
) => {
  const get = useCallback(async (tx: ReadTransaction) => {
    return (await getSoftDeletedRecords(tx)) as unknown as JSONObjWithId[]
  }, [])
  const softDeletedRecords =
    useSubscribeArrWithStableIdentities(
      rep,
      get,
      def as unknown as JSONObjWithId[],
      deps,
    ) ?? def

  return softDeletedRecords as unknown as DeleteableRecord[]
}
