import React, { useCallback, useState } from 'react'

import { RouteComponentProps } from '@gatsbyjs/reach-router'

import {
  ActiveScreen,
  EmptyTrashTrigger,
  permanentlyDeleteFlowStarted,
  PermanentlyDeleteFlowStartedTrigger,
  PermanentlyDeleteFlowStartedTriggerValueType,
  RecordsDeletedTrigger,
} from '../../common/analytics/capture-analytics-actions'
import { useExecContext } from '../../common/contexts/ExecContext'
import { useSelectionContext } from '../../common/contexts/SelectionContext'
import { useMappedKeys } from '../../common/keyMappableActions/useKeyMappedActionHotkeys'
import { useOpenConfirmDeleteRecordsModal } from '../../common/modals/core/hooks/useOpenConfirmDeleteRecordsModal'
import { RouteType } from '../../common/routes'
import { WithModelStore, withModelStore } from '../enhancers/withData'
import { WithSelection, withSelection } from '../enhancers/withSelection'
import { withSideBar } from '../enhancers/withSideBar'
import { withSnackbar } from '../enhancers/withSnackbar'
import { WithUIContext, withUIContext } from '../enhancers/withUIContext'
import { ScreenProps } from '../types'
import TrashScreen from './TrashScreen'

export type TrashScreenContainerProps = ScreenProps & RouteComponentProps

type TrashScreenContainerComponentProps = TrashScreenContainerProps &
  WithModelStore &
  WithUIContext &
  WithSelection

const TrashScreenContainer = (props: TrashScreenContainerComponentProps) => {
  useConfirmDeleteRecordsHotkey()
  return <TrashScreen {...props} routeType={RouteType.Trash} />
}

const useConfirmDeleteRecordsHotkey = () => {
  const { selection } = useSelectionContext()
  const { exec } = useExecContext()

  const openConfirmDeleteRecordsModal = useOpenConfirmDeleteRecordsModal()

  const onRecordDelete = useCallback(() => {
    if (selection.ids.length === 0) return
    openConfirmDeleteRecordsModal({
      trigger: PermanentlyDeleteFlowStartedTrigger.KEYBOARD_BACKSPACE,
    })
  }, [exec, selection, openConfirmDeleteRecordsModal])
  useMappedKeys({ KEY_MAPPED_RECORD_DELETE: onRecordDelete })
}

export default withSnackbar(
  withSideBar(
    withUIContext(withModelStore(withSelection(TrashScreenContainer))),
  ),
)
