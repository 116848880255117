import { useCallback, useMemo } from 'react'

import { isImageRecord } from '../../../components/RecordItem/components/FileRecordItemContent/utils'
import { PermanentlyDeleteFlowStartedTrigger } from '../../analytics/capture-analytics-actions'
import { RecordItemData } from '../../data/recordGroups'
import { MiniBarAction, MiniBarActionType, MiniBarEventHandlers } from './types'
import { useOpenConfirmDeleteRecordsModal } from '../../modals/core/hooks/useOpenConfirmDeleteRecordsModal'
import {
  ADD_NOTE,
  COMPLETE,
  FULLSCREEN,
  HIDE_NOTE,
  OPEN_FULLSCREEN,
  SHOW_NOTE,
  UNCOMPLETE,
} from '../../utils/uiConstants'

export const useMiniBarActions = (
  record: RecordItemData,
  handlers: MiniBarEventHandlers,
  isFullyExpanded: boolean,
  isLongRecord: boolean,
  isMaxHeightInTransition: boolean,
  notesOpen: boolean,
  hasNotes: boolean,
): [MiniBarAction[]] => {
  const {
    moveRecords: _moveRecords,
    completeRecords: _completeRecords,
    scheduleRecords: _scheduleRecords,
    addNoteToRecord: _addNoteToRecord,
    moveRecordsToTrash: _moveRecordsToTrash,
    expandRecord: _expandRecord,
    collapseRecord: _collapseRecord,
    restoreRecords: _restoreRecords,
    blurRecord: _blurRecord,
    hideNote: _hideNote,
    expandToFullscreen,
  } = handlers
  const recordId = record.id
  const isCompleted = !!record.isCompleted

  const openConfirmDeleteRecordsModal = useOpenConfirmDeleteRecordsModal()

  const completeRecords = useCallback(() => {
    _completeRecords(recordId)
    _blurRecord(recordId)
  }, [recordId, _completeRecords, _blurRecord])

  const moveRecords = useCallback(() => {
    _moveRecords(recordId)
  }, [recordId, _moveRecords])

  const scheduleRecords = useCallback(() => {
    _scheduleRecords(recordId)
  }, [recordId, _scheduleRecords])

  const addNoteToRecord = useCallback(() => {
    _addNoteToRecord(recordId)
  }, [recordId, _addNoteToRecord])

  const moveRecordsToTrash = useCallback(() => {
    _moveRecordsToTrash(recordId)
  }, [recordId, _moveRecordsToTrash])

  const blurRecord = useCallback(() => {
    _blurRecord(recordId)
  }, [recordId, _blurRecord])

  const collapseRecord = useCallback(() => {
    _collapseRecord(recordId)
    _blurRecord(recordId)
  }, [recordId, _collapseRecord, _blurRecord])

  const expandRecord = useCallback(() => {
    _expandRecord(recordId)
    _blurRecord(recordId)
  }, [recordId, _expandRecord, _blurRecord])

  const restoreRecord = useCallback(() => {
    _restoreRecords(recordId)
  }, [recordId, _restoreRecords])

  const hideNote = useCallback(() => {
    _hideNote(recordId)
    _blurRecord(recordId)
  }, [recordId, _hideNote, _blurRecord])

  const openInFullscreen = useCallback(() => {
    expandToFullscreen(recordId)
    _blurRecord(recordId)
  }, [recordId, expandToFullscreen, _blurRecord])

  const isExpandedLongRecord =
    isLongRecord && (isFullyExpanded || isMaxHeightInTransition)

  const isCollapsedLongRecord =
    isLongRecord && !isFullyExpanded && !isMaxHeightInTransition

  const isExpandedImageRecord = isImageRecord(record) && isFullyExpanded

  const isCollapsedImageRecord = isImageRecord(record) && !isFullyExpanded

  const canCollapse = isExpandedLongRecord || isExpandedImageRecord

  const canExpand = isCollapsedLongRecord || isCollapsedImageRecord

  const canRestore = record.isSoftDeleted

  const getAnnotationActionTooltip =
    useCallback((): MiniBarAction['tooltip'] => {
      let title = ADD_NOTE
      if (hasNotes) {
        title = notesOpen ? HIDE_NOTE : SHOW_NOTE
      }

      return { title }
    }, [notesOpen])

  const miniBarActions: MiniBarAction[] = useMemo(
    () => [
      ...(canRestore
        ? ([
            {
              type: MiniBarActionType.StandAlone,
              title: `Restore`,
              variant: `glyphCcwCircle`,
              tooltip: {
                title: `Restore`,
              },
              onMouseDown: restoreRecord,
            },
          ] as any)
        : []),
      ...(canCollapse
        ? ([
            {
              type: MiniBarActionType.StandAlone,
              title: `Collapse`,
              variant: `glyphArrowNSInverted`,
              tooltip: {
                title: `Collapse`,
              },
              onMouseDown: collapseRecord,
            },
          ] as any)
        : []),
      ...(!canCollapse && canExpand
        ? ([
            {
              type: MiniBarActionType.StandAlone,
              title: `Expand`,
              variant: `glyphArrowNS`,
              tooltip: {
                title: `Expand`,
              },
              onMouseDown: expandRecord,
            },
          ] as any)
        : []),
      {
        type: MiniBarActionType.MiniBar,
        title: isCompleted ? UNCOMPLETE : COMPLETE,
        variant: isCompleted ? `glyphArrowNCircle` : `glyphCheckCircle`,
        tooltip: isCompleted
          ? {
              title: UNCOMPLETE,
            }
          : {
              title: COMPLETE,
            },
        onMouseDown: completeRecords,
      },
      {
        type: MiniBarActionType.MiniBar,
        title: `MoveTo`,
        variant: `glyphArrowESquircle`,
        tooltip: {
          title: `Move`,
        },
        onMouseDown: moveRecords,
      },
      {
        type: MiniBarActionType.MiniBar,
        title: `Schedule`,
        variant: `glyphCalendarSingleSquircle`,
        tooltip: {
          title: `Schedule`,
        },
        onMouseDown: scheduleRecords,
      },
      {
        type: MiniBarActionType.MiniBar,
        title: ADD_NOTE,
        variant: `glyphBlurbSquircle`,
        notesOpen,
        tooltip: getAnnotationActionTooltip(),
        onMouseDown: notesOpen ? hideNote : addNoteToRecord,
      },
      {
        type: MiniBarActionType.MiniBar,
        title: FULLSCREEN,
        variant: `glyphArrowNeSw`,
        tooltip: {
          title: OPEN_FULLSCREEN,
        },
        onMouseDown: openInFullscreen,
      },
      {
        type: MiniBarActionType.MiniBar,
        title: `Move to Trash`,
        variant: `glyphTrash`,
        tooltip: {
          title: 'Move to Trash',
        },
        onMouseDown: moveRecordsToTrash,
      },
    ],
    [
      isCompleted,
      isFullyExpanded,
      completeRecords,
      moveRecords,
      scheduleRecords,
      blurRecord,
      openInFullscreen,
      isMaxHeightInTransition,
      isLongRecord,
      notesOpen,
      canCollapse,
      canExpand,
      getAnnotationActionTooltip,
    ],
  )

  return [miniBarActions]
}
