import { isEmpty } from 'lodash'
import React, { useCallback, useMemo } from 'react'

import {
  RecordCreatedTrigger,
  RecordCreatedTriggerValueType,
} from '../../common/analytics/capture-analytics-actions'
import {
  RecordGroupData,
  RecordGroupType,
} from '../../common/data/recordGroups'
import { TagType } from '../../common/data/tags'
import { DragSourceType } from '../../common/dnd/drag/types'
import {
  DropTargetType,
  RecordGroupDropProps,
} from '../../common/dnd/drop/types'
import { useRegisterDropTarget } from '../../common/dnd/drop/useRegisterDropTarget'
import { useGetDropIndicatorPosition } from '../../common/dnd/hooks/useDropIndicatorPosition'
import { ScrollOptions } from '../../common/hooks/scroll/useScroll'
import { useScrollIntoView } from '../../common/hooks/scroll/useScrollIntoView'
import { useScrollObserver } from '../../common/hooks/scroll/useScrollObserver'
import { NoteHandlers as GenericNoteHandlers } from '../../common/noteEventHandlers'
import { RecordItemTargetType } from '../../common/recordItem'
import {
  MiniBarEventHandlers,
  RecordItemHandlers as GenericRecordItemHandlers,
} from '../../common/recordItemEventHandlers'
import { RouteType } from '../../common/routes'
import CaptureButtons from '../CaptureButtons'
import {
  RecordItem as BaseRecordItem,
  RecordItemDimension,
} from '../RecordItem'
import { withRecordItemEventHandlers } from './withRecordItemEventHandlers'
import { cx, styled } from '../../common/stationary'
import { TodayId } from '@eleventhlabs/capture-shared'

const RecordItem = withRecordItemEventHandlers(BaseRecordItem)

interface RecordGroupProps extends RecordGroupData {
  createFileRecordAtStartOfGroup: (
    files: File[],
    groupId: string,
    trigger: RecordCreatedTriggerValueType,
  ) => void
  emptyStateRenderer?: () => JSX.Element
  focusedId?: string
  isMouseActive: boolean
  miniBarHandlers: MiniBarEventHandlers
  noteHandlers: GenericNoteHandlers
  recordHandlers: GenericRecordItemHandlers
  reschedule?: (event: unknown, ids: string[]) => void
  routeType: RouteType
  scrollOptions?: ScrollOptions
  selectedIds: string[]
  shouldDisplayNotesForRecordId: (recordId: string) => boolean
  showCaptureButtons?: boolean
  softHeader?: boolean
  createRecordAtStartOfGroup: (
    groupId: string,
    recordItemTargetType:
      | RecordItemTargetType.Text
      | RecordItemTargetType.Bookmark
      | RecordItemTargetType.Page,
    trigger: RecordCreatedTriggerValueType,
    triggeredByKeyboard: boolean,
    triggeredByUI: boolean,
  ) => void
  uploadProgress: Record<string, number>
  visibilityStatusUpdate?: (
    id: string,
    pixelsVisible: number,
    offsetFromTopViewport: number,
  ) => void
}

export const RecordGroup = React.forwardRef<HTMLDivElement, RecordGroupProps>(
  (
    {
      createFileRecordAtStartOfGroup,
      createRecordAtStartOfGroup,
      emptyStateRenderer,
      focusedId,
      id,
      isMouseActive,
      items,
      miniBarHandlers,
      noteHandlers,
      recordHandlers,
      reschedule,
      routeType = RouteType.Inbox,
      scrollOptions,
      selectedIds,
      shouldDisplayNotesForRecordId,
      showCaptureButtons = true,
      showCount = false,
      showMoveTo = false,
      showToggle = false,
      softHeader,
      subTitle,
      title,
      type,
      uploadProgress,
      visibilityStatusUpdate,
    },
    ref,
  ) => {
    const { htmlRef } = useScrollIntoView<HTMLDivElement>(
      id,
      `group`,
      scrollOptions,
    )
    const recordScrollOptions = useMemo(
      () => ({
        ...scrollOptions,
        position: `smart` as any,
      }),
      [scrollOptions],
    )
    useScrollObserver(id, htmlRef, visibilityStatusUpdate, scrollOptions)

    const getDropIndicatorPosition = useGetDropIndicatorPosition()

    const _reschedule = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        reschedule &&
        reschedule(
          event,
          items.map((item) => item.id),
        ),
      [items, reschedule],
    )

    const isSecondaryButtonTextHidden =
      routeType === RouteType.Today && title !== TodayId

    const captureButtonsOnFileSelected = useCallback(
      (files: File[]) => {
        createFileRecordAtStartOfGroup(
          files,
          id,
          RecordCreatedTrigger.CAPTURE_BUTTON_BAR,
        )
      },
      [id, createFileRecordAtStartOfGroup],
    )

    const captureButtonCreateRecord = useCallback(
      (
        type:
          | RecordItemTargetType.Text
          | RecordItemTargetType.Bookmark
          | RecordItemTargetType.Page,
      ) => {
        const triggeredByKeyboard = false
        const triggeredByUI = true
        createRecordAtStartOfGroup(
          id,
          type,
          RecordCreatedTrigger.CAPTURE_BUTTON_BAR,
          triggeredByKeyboard,
          triggeredByUI,
        )
      },
      [id, createRecordAtStartOfGroup],
    )

    const [dropRef, { isValidAndHovered }] =
      useRegisterDropTarget<RecordGroupDropProps>(
        DropTargetType.RECORD_GROUP,
        [DragSourceType.RECORD],
        {
          groupId: id,
          isRecordGroupEmpty: isEmpty(items),
        },
      )

    return (
      <S.RecordGroup ref={htmlRef}>
        <div ref={dropRef}>
          {title ? (
            <>
              <S.GroupHeader>
                <S.GroupTitle
                  className={cx({
                    softHeader,
                  })}
                >
                  {title}
                </S.GroupTitle>
                {subTitle ? (
                  <>
                    ·<S.GroupSubTitle>{subTitle}</S.GroupSubTitle>
                  </>
                ) : null}
                {showCount ? <S.GroupCount>{items.length}</S.GroupCount> : null}
                <S.Filler />
                {reschedule ? (
                  <S.Reschedule onClick={_reschedule}>Reschedule</S.Reschedule>
                ) : null}
              </S.GroupHeader>
              <S.HeaderDivider />
            </>
          ) : null}
          {showCaptureButtons ? (
            <CaptureButtons
              groupId={id}
              isSecondaryButtonTextHidden={isSecondaryButtonTextHidden}
              isMouseActive={isMouseActive}
              onFileSelected={captureButtonsOnFileSelected}
              createRecord={captureButtonCreateRecord}
            />
          ) : null}
          <div ref={ref}>
            {isEmpty(items) && isValidAndHovered && <S.DropIndicator />}
            {isEmpty(items) && emptyStateRenderer ? emptyStateRenderer() : null}
            {items.map((record, index) => (
              <RecordItem
                key={record.id}
                record={record}
                isFocused={focusedId === record.id}
                isSelected={selectedIds.includes(record.id)}
                isMouseActive={isMouseActive}
                showToggle={showToggle}
                showMoveTo={showMoveTo}
                tagsToAutoHide={{
                  [TagType.DATE]: type === RecordGroupType.Date,
                }}
                {...recordHandlers}
                miniBarHandlers={miniBarHandlers}
                noteHandlers={noteHandlers}
                scrollOptions={recordScrollOptions}
                shouldDisplayNotesForRecordId={shouldDisplayNotesForRecordId}
                uploadProgress={uploadProgress[record.id]}
                index={index}
                dropIndicatorPosition={getDropIndicatorPosition(index)}
                groupType={type}
              />
            ))}
          </div>
        </div>
      </S.RecordGroup>
    )
  },
)

const S = {
  DropIndicator: styled.div(({ theme }) => ({
    left: 0,
    width: `100%`,
    height: 2,
    backgroundColor: theme.colors.alpha.selection.solid,
  })),
  RecordGroup: styled.div({
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    marginBottom: 24,
    paddingLeft: RecordItemDimension.insetLeft,
    paddingRight: RecordItemDimension.insetRight,
  }),
  GroupHeader: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `row`,
    width: `100%`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    marginTop: 4,
    marginBottom: 10,
    fontWeight: 500,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    userSelect: `none`,
  })),
  GroupTitle: styled.div({
    display: `flex`,
    marginRight: 5,
    [`&.softHeader`]: {
      opacity: 0.5,
      fontWeight: 400,
    },
  }),
  GroupSubTitle: styled.div(({ theme }) => ({
    display: `flex`,
    marginLeft: 5,
    color: theme.colors.text[900],
    opacity: 0.5,
  })),
  GroupCount: styled.div(({ theme }) => ({
    display: `flex`,
    marginLeft: 10,
    color: theme.colors.text[900],
    opacity: 0.5,
  })),
  Filler: styled.div({
    display: `flex`,
    flex: 1,
  }),
  Reschedule: styled.div(({ theme }) => ({
    display: `flex`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `20px`,
    userSelect: `none`,
    fontWeight: 400,
    color: theme.colors.text[900],
    opacity: 0.3,
    cursor: `pointer`,

    '&:hover': {
      opacity: 0.6,
    },
  })),
  HeaderDivider: styled.div(({ theme }) => ({
    display: `flex`,
    width: `100%`,
    height: `1px`,
    marginBottom: 16,
    background: theme.colors.alpha.border.mediumWeak,
  })),
}
