import { ApiEndpoints } from '../ApiEndpoints'
import { apiCall } from './client'

export const getUrlMetadata = async (url: string) => {
  try {
    const response = await apiCall(ApiEndpoints.metadataURI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        url,
      }),
    })

    return await response.json()
  } catch (error) {
    return {}
  }
}
