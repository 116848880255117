import React from 'react'

import { EditorsManager } from '../../../common/EditorsManager'
import { useStableCallback } from '../../../common/utils'
import { RecordItemOnFocus } from '../../RecordItem'

type HasOnIdAndKeyDownHandler = {
  id: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => boolean
}

export type EditorWithCustomHandlers = {
  onArrowRightOrDownAtBoundary?: () => boolean
  onArrowLeftOrUpAtBoundary?: () => boolean
  onBackspaceWhenEmpty?: () => boolean
  onBackspaceAtFirstPointWhenNotEmpty?: () => boolean
  onBlur?: RecordItemOnFocus
}

export type WithCustomHandlerProps = HasOnIdAndKeyDownHandler &
  EditorWithCustomHandlers

export const withCustomHandlers = <T extends HasOnIdAndKeyDownHandler>(
  Component: React.FC<T>,
): React.FC<T & EditorWithCustomHandlers> => {
  const WrappedComponent = ({
    id,
    onBlur: _onBlur,
    onKeyDown: _onKeyDown,
    onArrowLeftOrUpAtBoundary,
    onArrowRightOrDownAtBoundary,
    onBackspaceAtFirstPointWhenNotEmpty,
    onBackspaceWhenEmpty,
    ...props
  }: WithCustomHandlerProps) => {
    const onArrowDown: (e: React.KeyboardEvent<HTMLDivElement>) => boolean =
      useStableCallback((e: React.KeyboardEvent<HTMLDivElement>) =>
        onArrowDownOrRight(id, onArrowRightOrDownAtBoundary),
      )

    const onArrowLeft: (e: React.KeyboardEvent<HTMLDivElement>) => boolean =
      useStableCallback((e: React.KeyboardEvent<HTMLDivElement>) =>
        onArrowLeftOrUp(id, onArrowLeftOrUpAtBoundary),
      )

    const onArrowRight: (e: React.KeyboardEvent<HTMLDivElement>) => boolean =
      useStableCallback((e: React.KeyboardEvent<HTMLDivElement>) =>
        onArrowDownOrRight(id, onArrowRightOrDownAtBoundary),
      )

    const onArrowUp: (e: React.KeyboardEvent<HTMLDivElement>) => boolean =
      useStableCallback((e: React.KeyboardEvent<HTMLDivElement>) =>
        onArrowLeftOrUp(id, onArrowLeftOrUpAtBoundary),
      )

    const onBackspace: (e: React.KeyboardEvent<HTMLDivElement>) => boolean =
      useStableCallback((e: React.KeyboardEvent<HTMLDivElement>) =>
        onBackspaceWithCustomHandlers(
          id,
          onBackspaceAtFirstPointWhenNotEmpty,
          onBackspaceWhenEmpty,
        ),
      )

    const onBlur: (e: React.KeyboardEvent<HTMLDivElement>) => boolean =
      useStableCallback((e: React.FocusEvent<HTMLDivElement>) => {
        onBlurWithCustomHandlers(e, id, _onBlur)
      })

    const onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => boolean =
      useStableCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => _onKeyDown?.(e) ?? false,
      )

    return (
      <Component
        {...(props as T)}
        id={id}
        onArrowDown={onArrowDown}
        onArrowLeft={onArrowLeft}
        onArrowRight={onArrowRight}
        onArrowUp={onArrowUp}
        onBackspace={onBackspace}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
    )
  }
  return WrappedComponent
}

const onBlurWithCustomHandlers = (
  e: React.FocusEvent<HTMLDivElement, Element>,
  id: string,
  _onBlur?: RecordItemOnFocus,
): boolean => {
  // a checkbox click event will have a related target that let's us know that
  // we need to manually blur.
  // We also need to check if the localName is li, so that
  // we don't accidentally blur when someone is trying to shift+click to highlight.
  if (e && e.relatedTarget && e.relatedTarget.localName !== 'li') {
    EditorsManager.effects.blur(id)
  }
  _onBlur && _onBlur(e)

  return false
}

const onBackspaceWithCustomHandlers = (
  id: string,
  onBackspaceAtFirstPointWhenNotEmpty?: () => boolean,
  onBackspaceWhenEmpty?: () => boolean,
): boolean => {
  if (EditorsManager.get.isEmptyParagraph(id)) {
    return onBackspaceWhenEmpty?.() ?? false
  } else if (
    !EditorsManager.get.isEmptyParagraph(id) &&
    EditorsManager.get.isSelectionFocusAtStart(id) &&
    EditorsManager.get.isSelectionEmpty(id) &&
    EditorsManager.get.isFirstLineParagraph(id)
  ) {
    return onBackspaceAtFirstPointWhenNotEmpty?.() ?? false
  }
  return false
}

const onArrowLeftOrUp = (
  id: string,
  onArrowLeftOrUpAtBoundary?: () => boolean,
): boolean => {
  const isFocusedAtStartBoundary =
    EditorsManager.get.isSelectionFocusAtStart(id) &&
    EditorsManager.get.isSelectionEmpty(id)
  if (isFocusedAtStartBoundary) return onArrowLeftOrUpAtBoundary?.() ?? false
  else return false
}

const onArrowDownOrRight = (
  id: string,
  onArrowDownOrRightAtBoundary?: () => boolean,
): boolean => {
  const isFocusedAtEndBoundary =
    EditorsManager.get.isSelectionFocusAtEnd(id) &&
    EditorsManager.get.isSelectionEmpty(id)
  if (isFocusedAtEndBoundary) return onArrowDownOrRightAtBoundary?.() ?? false
  else return false
}
