import { UpdateTicklerPayload } from '@eleventhlabs/capture-shared'

export const removeDate = (
  selectedIds: string[],
  updateRecordTickler: (id: string, payload: UpdateTicklerPayload) => void,
) => {
  for (const id of selectedIds) {
    updateRecordTickler(id, { isoDate: null })
  }
}
