import React, { useState } from 'react'

import { RecordItemData } from '../../../common/data/recordGroups'
import { RecordType } from '@eleventhlabs/capture-shared'
import { FullScreenRecordItemHandlers } from '../types'
import { FullScreenFileRecordItemContent } from './FullScreenFileRecordItemContent'
import { FullScreenURLRecordItemContent } from './FullScreenURLRecordItemContent'
import { FullScreenRichTextEditor } from '../../RichTextEditor/FullScreenRichTextEditor'
import { withErrorBoundary } from '../../../common/errors/enhancers/withErrorBoundary'
import { ContentErrorBoundaryErrorProps } from '../../../common/errors/ContentErrorBoundary'
import { useEditorAnalyticsHandlers } from '../../../common/analytics/helpers/hooks/useValidateEditorAnalytics'
import { css, cx } from '../../../common/stationary'

interface FullScreenRecordContentProps extends FullScreenRecordItemHandlers {
  record: RecordItemData
  notesOpen: boolean
  className?: string
  style?: any
  placeholder?: string
  placeholderType?: string
  setPlaceholderType: (type?: string) => void
  autofocus?: boolean
}

const FullScreenRecordContent: React.FC<FullScreenRecordContentProps> = ({
  record,
  onChange,
  onBlur,
  onPaste,
  onClick,
  onMouseDown,
  onFocus,
  onKeyDown,
  onArrowLeftOrUpAtBoundary,
  renameFile,
  placeholder,
  placeholderType,
  setPlaceholderType,
  autofocus,
  notesOpen,
}) => {
  const isFullscreen = true

  const completedStyles = css({
    opacity: `.5`,
  })
  const className = cx({
    [completedStyles]: record.isCompleted,
    isFullscreen,
    notesOpen,
  })

  const { validateEditorAnalytics, onListItemChange } =
    useEditorAnalyticsHandlers(record.id, record.type)

  switch (record.type) {
    case RecordType.Text: {
      if (!record.richText) return null
      return (
        <FullScreenRichTextEditor
          autofocus={autofocus}
          id={record.id}
          richText={record.richText.value}
          onChangeDebounced={onChange}
          onBlur={onBlur}
          onPaste={onPaste}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onArrowLeftOrUpAtBoundary={onArrowLeftOrUpAtBoundary}
          placeholder={placeholder}
          placeholderType={placeholderType}
          setPlaceholderType={setPlaceholderType}
          validateAnalytics={validateEditorAnalytics}
          onListItemChange={onListItemChange}
          editorClassName={cx(
            css({
              '&.startsWithHeadingOrIsMultiline': {
                paddingBottom: 8,
              },
            }),
            className,
          )}
        />
      )
    }
    case RecordType.URL:
      return (
        <FullScreenURLRecordItemContent
          id={record.id}
          url={record.url}
          onClick={onClick}
          onMouseDown={onMouseDown}
          className={className}
          {...record?.urlMetadata}
        />
      )
    case RecordType.File:
      return (
        <FullScreenFileRecordItemContent
          id={record.id}
          url={record.url}
          thumbnailUrl={record.thumbnailUrl}
          metadata={record.fileMetadata}
          onClick={onClick}
          onMouseDown={onMouseDown}
          renameFile={renameFile}
          className={className}
        />
      )
    default:
      return null
  }
}

export default withErrorBoundary<
  FullScreenRecordContentProps,
  ContentErrorBoundaryErrorProps
>(FullScreenRecordContent)
