import { useCallback, useEffect, useMemo, useState } from 'react'
import { ContentEditableEvent } from 'react-contenteditable'

import { useModelStoreContext } from '../../common/contexts/ModelStoreContext'
import { focusAction } from '../../common/events'
import { immediateExecute } from '../../common/utils'
import { createInputId } from '../../common/utils/input'
import { useCollectionCommands } from './useCollectionCommands'
import { useOnListItemClick } from './useOnListItemClick'

export const useCollectionHandlers = (
  title: string,
  listId: string | undefined,
  blurInput: () => void,
  editableOnClick: boolean,
) => {
  const inputId = createInputId(listId)
  const [editing, setEditing] = useState(false)
  const [inputValue, setInputValue] = useState(title)

  const { commands } = useModelStoreContext()
  const navigateOnItemClick = useOnListItemClick()

  useEffect(() => {
    setInputValue(title)
  }, [title])

  const {
    openEmojiPicker,
    closeEmojiPicker,
    isEmojiPickerOpen,
    onSelectEmoji,
    removeItem,
  } = useCollectionCommands(listId)

  const { updateRecord } = commands

  const onItemChange = useCallback((text: string) => {
    setInputValue(text)
  }, [])

  const onChange = useCallback(
    (event: ContentEditableEvent) => {
      onItemChange(event.target.value)
    },
    [onItemChange],
  )

  const onItemClick = useCallback(() => {
    if (editableOnClick) {
      setEditing(true)
      focusAction({ id: inputId })
    } else {
      if (listId) navigateOnItemClick(listId)
    }
  }, [inputId, listId, editableOnClick, navigateOnItemClick])

  const onBlur = useCallback(() => {
    setEditing(false)
    if (!listId) return
    updateRecord(listId, { title: inputValue })
  }, [listId, inputValue, updateRecord])

  const onKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case `Enter`: {
          blurInput()
          return
        }
        case `Escape`: {
          setInputValue(title)
          immediateExecute(blurInput)
          return
        }
        default:
      }
    },
    [title, blurInput],
  )

  const handlers = useMemo(
    () => ({
      inputId,
      editing,
      setEditing,
      inputValue,
      onItemClick,
      onChange,
      onBlur,
      onKeyDown,
      openEmojiPicker,
      closeEmojiPicker,
      isEmojiPickerOpen,
      onSelectEmoji,
      removeItem,
    }),
    [
      inputId,
      editing,
      setEditing,
      inputValue,
      onItemClick,
      onChange,
      onBlur,
      onKeyDown,
      openEmojiPicker,
      closeEmojiPicker,
      isEmojiPickerOpen,
      onSelectEmoji,
      removeItem,
    ],
  )

  return handlers
}
