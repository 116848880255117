import { isNumber } from 'lodash'

export type LocalStorageItem = string | null

export type LocalStorageItemSetter = (
  item: LocalStorageItem,
) => LocalStorageItem

export type LocalStorageKey = string

/*
 * LocalStorageBridge
 */

interface LocalStorageBridgeOptions<T> {
  default: T
  key: LocalStorageKey
  proxy: LocalStorageProxy
}

export abstract class LocalStorageBridge<T extends string> {
  readonly default: T
  readonly key: LocalStorageKey
  readonly proxy: LocalStorageProxy

  constructor(options: LocalStorageBridgeOptions<T>) {
    this.default = options.default
    this.key = options.key
    this.proxy = options.proxy
  }

  get = (): T => {
    const item = this.proxy.getItem(this.key) as T
    return item !== null ? item : this.default
  }

  set = (item: T): T => {
    return this.proxy.setItem(this.key, item) as T
  }

  abstract getScriptComponentHTML(): string
}

/*
 * LocalStorageProxy
 */

class LocalStorageProxyError extends Error {
  constructor(message: string) {
    super(message)
    this.name = `LocalStorageProxyError`
  }
}

export class LocalStorageProxy {
  private _cache: Record<LocalStorageKey, LocalStorageItem> = {}
  private _keyToListenerDict: Record<
    LocalStorageKey,
    (item: LocalStorageItem) => void
  > = {}
  private _localStorage: Storage | undefined
  private _storageEventListenerRegistered = false
  private _window: Window | undefined

  constructor(window: Window | undefined, localStorage: Storage | undefined) {
    this._window = window
    this._localStorage = localStorage
  }

  public getItem = (key: LocalStorageKey): LocalStorageItem => {
    const itemFromCache = this._getItemFromCache(key)
    if (itemFromCache !== null) return itemFromCache

    return this._getItemFromLocalStorage(key)
  }

  public setItem = (
    key: LocalStorageKey,
    item: LocalStorageItem,
  ): LocalStorageItem => {
    this._setItemToLocalStorage(key, item)
    this._setItemToCache(key, item)
    this._callListenerForKey(key, item)

    return item
  }

  public removeItems = (keys: LocalStorageKey[]): void => {
    const localStorage = this._localStorage
    if (!localStorage) return

    keys.forEach((key) => {
      localStorage.removeItem(key)
      this._setItemToCache(key, null)
    })
  }

  public addListener = (
    key: LocalStorageKey,
    cb: (item: LocalStorageItem) => void,
  ): void => {
    if (key.length === 0) {
      throw new LocalStorageProxyError(
        `Cannot listen to changes on empty key ""`,
      )
    }
    if (key in this._keyToListenerDict) {
      throw new LocalStorageProxyError(
        `Cannot have multiple listeners for key ${key}`,
      )
    }

    // Prime the cache so we can detect changes to the item for this key.
    const initialItem = this._getItemFromLocalStorage(key)
    this._setItemToCache(key, initialItem)

    this._keyToListenerDict[key] = cb
  }

  public removeListener = (key: LocalStorageKey): void => {
    if (!(key in this._keyToListenerDict)) {
      throw new LocalStorageProxyError(
        `Cannot remove non-existant listener for key ${key}.`,
      )
    }

    this._keyToListenerDict = removeKeyFromDict(key, this._keyToListenerDict)
  }

  public registerStorageEventListener = (): void => {
    if (this._storageEventListenerRegistered) {
      throw new LocalStorageProxyError(`Already listening to storage events`)
    }

    if (typeof this._window !== `undefined`) {
      this._window.addEventListener(`storage`, this._storageEventListener)
      this._storageEventListenerRegistered = true
    }
  }

  public unRegisterStorageEventListener = (): void => {
    if (!this._storageEventListenerRegistered) {
      throw new LocalStorageProxyError(
        `Not listening to storage events already.`,
      )
    }

    if (typeof this._window !== `undefined`) {
      this._window.removeEventListener(`storage`, this._storageEventListener)
      this._storageEventListenerRegistered = false
    }
  }

  private _getItemFromLocalStorage = (
    key: LocalStorageKey,
  ): LocalStorageItem => {
    if (typeof this._localStorage === `undefined`) return null

    return this._localStorage.getItem(key)
  }

  private _setItemToLocalStorage = (
    key: LocalStorageKey,
    item: LocalStorageItem,
  ): void => {
    if (typeof this._localStorage === `undefined`) return

    if (item === null) {
      this._localStorage.removeItem(key)
    } else {
      this._localStorage.setItem(key, item)
    }
  }

  private _getItemFromCache = (key: LocalStorageKey): LocalStorageItem => {
    return key in this._cache ? this._cache[key] : null
  }

  private _setItemToCache = (
    key: LocalStorageKey,
    item: LocalStorageItem,
  ): void => {
    if (item === null) {
      this._cache = removeKeyFromDict(key, this._cache)
    } else {
      this._cache[key] = item
    }
  }

  private _callListenerForKey = (
    key: LocalStorageKey,
    item: LocalStorageItem,
  ): void => {
    if (key in this._keyToListenerDict) {
      this._keyToListenerDict[key](item)
    }
  }

  private _storageEventListener = (e: StorageEvent): void => {
    if (e === null || e.key == null) return

    const { key, newValue } = e

    if (!(key in this._keyToListenerDict)) return

    const itemFromCache = this._getItemFromCache(key)
    const itemFromLocalStorage = newValue

    if (itemFromCache !== itemFromLocalStorage) {
      this._setItemToCache(key, itemFromLocalStorage)
      this._callListenerForKey(key, itemFromLocalStorage)
    }
  }
}

/*
 * Helpers
 */

const removeKeyFromDict = <T>(
  key: string,
  dict: Record<string, T>,
): Record<string, T> => {
  if (!(key in dict)) return dict

  const { [key]: _, ...newDict } = dict

  return newDict
}

export const localStorageToBoolean = (
  value: string | undefined | null,
): boolean => {
  return !!(value && value === `true`)
}

export const booleanToLocalStorage = (
  value: boolean | undefined | null,
): string => {
  return value ? `true` : `false`
}

export const localStorageToNumber = (
  value: string | undefined | null,
): number => {
  const parsedValue = value ? parseInt(value) : 0
  return isNumber(parsedValue) ? parsedValue : 0
}

export const numberToLocalStorage = (
  value: number | undefined | null,
): string => {
  return value ? `${value}` : `0`
}

export const localStorageToList = (
  value: string | undefined | null,
): string[] => {
  return value ? JSON.parse(value) : []
}

export const listToLocalStorage = (
  value: string[] | undefined | null,
): string => {
  return value ? JSON.stringify(value) : `[]`
}
