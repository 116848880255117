import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  TouchEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react'

interface LongPressHandlers {
  onMouseDown: MouseEventHandler
  onTouchStart: TouchEventHandler
  onMouseUp: MouseEventHandler
  onMouseLeave: MouseEventHandler
  onTouchEnd: TouchEventHandler
}

interface UseLongPressArgs {
  shouldPreventDefault?: boolean
  delay?: number
  onRelease?: () => void
}

interface LongPressReturnType {
  isLongPressing: boolean
  setIsLongPressing: Dispatch<SetStateAction<boolean>>
  handlers: LongPressHandlers
}

const addEventListeners = (
  event: any,
  listeners: any[],
  listener: any,
  { passive }: { passive: boolean },
) => {
  listeners.map((l) => {
    event.target.addEventListener(l, listener, {
      passive,
    })
  })
}

const removeEventListeners = (event: any, listeners: any[], listener: any) => {
  listeners.map((l) => {
    event.target.removeEventListener(l, listener)
  })
}

const PREVENT_DEFAULT_LISTENERS: string[] = [
  'touchstart',
  'touchend',
  'touchmove',
  'mousedown',
  'mouseup',
  'mouseleave',
]

export const useLongPress = ({
  shouldPreventDefault = true,
  delay = 500,
  onRelease,
}: UseLongPressArgs): LongPressReturnType => {
  const [isLongPressing, setIsLongPressing] = useState(false)
  const timeout = useRef<any>()
  const target = useRef<HTMLDivElement>()

  const startPress = useCallback(
    (event) => {
      timeout.current && clearTimeout(timeout.current)
      if (shouldPreventDefault && event.target) {
        preventDefault(event)
        addEventListeners(event, PREVENT_DEFAULT_LISTENERS, preventDefault, {
          passive: false,
        })
        target.current = event.target
      }
      timeout.current = setTimeout(() => {
        setIsLongPressing(true)
      }, delay)
    },
    [shouldPreventDefault],
  )

  const stopPress = useCallback(
    (event) => {
      timeout.current && clearTimeout(timeout.current)
      setIsLongPressing(false)
      if (shouldPreventDefault && target.current) {
        preventDefault(event)
        removeEventListeners(event, PREVENT_DEFAULT_LISTENERS, preventDefault)
      }
    },
    [shouldPreventDefault, isLongPressing],
  )

  const onPressStart = useCallback(
    (event) => {
      startPress(event)
    },
    [startPress],
  )

  const onPressEnd = useCallback(
    (event) => {
      stopPress(event)
      onRelease && onRelease()
    },
    [stopPress, onRelease],
  )

  return {
    isLongPressing,
    setIsLongPressing,
    handlers: {
      onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) =>
        onPressStart(e),
      onTouchStart: (e: React.TouchEvent<Element>) => onPressStart(e),
      onMouseUp: (e: React.MouseEvent<Element, MouseEvent>) => onPressEnd(e),
      onMouseLeave: (e: React.MouseEvent<Element, MouseEvent>) => onPressEnd(e),
      onTouchEnd: (e: React.TouchEvent<Element>) => onPressEnd(e),
    },
  }
}

const isTouchEvent = (event: any) => {
  return 'touches' in event
}

const preventDefault = (event: any) => {
  if (!isTouchEvent(event)) return

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault()
  }
}

export default useLongPress
