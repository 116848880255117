import { debounce, max, min } from 'lodash'
import { useCallback, useLayoutEffect } from 'react'

const ONE_SECOND_IN_MS = 1000
// time that have to elaps since the last time the debounce function was invoked (we will wait 25ms after items has finished changing)
const DEBOUNCE_TIMEOUT = 0.02 * ONE_SECOND_IN_MS
// maximum time that the function can be delayed before it's invoked (we want to make sure we compute at least every 50ms)
const DEBOUNCE_MAX_WAIT = 0.04 * ONE_SECOND_IN_MS

interface ScrollOptions {
  offsetTop?: number
  offsetBottom?: number
}

export const useScrollObserver = (
  id: string,
  htmlRef: React.RefObject<HTMLDivElement>,
  visibilityStatusUpdate?: (
    id: string,
    pixelsVisible: number,
    offsetFromTopViewport: number,
  ) => void,
  options?: ScrollOptions,
) => {
  const updateVisibility = useCallback(() => {
    if (htmlRef.current && visibilityStatusUpdate && options) {
      const { offsetTop: viewportTop = 0, offsetBottom = 0 } = options
      const { top, bottom } = htmlRef.current.getBoundingClientRect()
      const viewportBottom = window.innerHeight - offsetBottom
      const topAnchor = max([top, viewportTop])
      const bottomAnchor = min([bottom, viewportBottom])
      if (
        topAnchor === undefined ||
        bottomAnchor === undefined ||
        topAnchor > bottomAnchor
      )
        visibilityStatusUpdate(id, 0, top - viewportTop)
      else
        visibilityStatusUpdate(id, bottomAnchor - topAnchor, top - viewportTop)
    }
    // `htmlRef` added to the dependency array for eslint not to complain although it will never trigger useCallback
    // https://epicreact.dev/why-you-shouldnt-put-refs-in-a-dependency-array/
  }, [htmlRef, id, options, visibilityStatusUpdate])

  useLayoutEffect(() => {
    const delayedUpdateVisibility = debounce(
      updateVisibility,
      DEBOUNCE_TIMEOUT,
      {
        maxWait: DEBOUNCE_MAX_WAIT,
      },
    )

    window.addEventListener(`scroll`, delayedUpdateVisibility)

    return () => window.removeEventListener(`scroll`, delayedUpdateVisibility)
  }, [updateVisibility])

  return {
    htmlRef,
  }
}
