import { indexOf } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import Analytics, {
  RecordsMovedTrigger,
} from '../../../common/analytics/capture-analytics-actions'
import {
  getRecordsMovedDestinationType,
  useActiveScreen,
} from '../../../common/analytics/capture-analytics-web'
import { useUIContext } from '../../../common/contexts/UIContext'
import {
  AddSection,
  ResultType,
  VIRTUAL_DESTINATION,
} from '../../../common/data/useDestinations/types'
import { scrollAction, triggerAction } from '../../../common/events'
import { useNavigateToList } from '../../../common/hooks/useNavigateToList'
import { UseSendToDestinationData } from '../../../common/hooks/useSendToDestinations'
import { emptyFn } from '../../../common/utils'
import {
  HandleResultPayload,
  InputHandlers,
  MoveItemData,
  NewDestination,
  ResultItem,
} from '../MoveTo.types'
import useDestinationSearch from './useDestinationSearch'

export interface MoveToHandlersData extends InputHandlers {
  inputValue: string
  items: MoveItemData[]
  isSelectedAddNewCollection: boolean
  handleResultClick: (payload: HandleResultPayload) => void
  sendToNewDestination: (payload: NewDestination) => void
  handleNewCollectionClick: () => void
  onHover: (id: string) => void
}

export const useMoveActionHandlers = (
  sendToDestinationData: UseSendToDestinationData,
  selectedRecordIds: string[],
  close: () => void = emptyFn,
): MoveToHandlersData => {
  const [query, setQuery] = useState(``)

  const navigateToList = useNavigateToList()

  const { destinations, groups } = sendToDestinationData

  const onDidSubmit = useOnDidSubmitWithAnalytics(
    sendToDestinationData.sendToDestination,
  )

  const { itemIds, results } = useDestinationSearch(
    destinations,
    groups,
    query ?? ``,
  )
  const resultsCount = itemIds.length

  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  useEffect(() => {
    // when the query changes, it will always select the first result
    setSelectedIndex(0)
  }, [query])

  const sendToNewDestination = useCallback(
    (destination: NewDestination) => {
      const newDestinationId = onDidSubmit({
        ...destination,
        destinationId: VIRTUAL_DESTINATION,
        recordIds: selectedRecordIds,
        recordData: {},
      })
      if (newDestinationId) {
        navigateToList(newDestinationId)
      }
      close()
    },
    [onDidSubmit, selectedRecordIds, close, navigateToList],
  )

  const handleDidSubmit = useCallback(
    (destinationId: string) => {
      const destination = destinations[destinationId]
      onDidSubmit({
        recordIds: selectedRecordIds,
        destinationId,
        recordData: {},
        title: destination?.title,
      })
      close()
    },
    [onDidSubmit, selectedRecordIds, destinations, close],
  )
  const handleDestinationResultClick = useCallback(
    (destinationId: string) => {
      handleDidSubmit(destinationId)
    },
    [handleDidSubmit],
  )

  const isSelected = useCallback(
    (index: number) => index === selectedIndex,
    [selectedIndex],
  )

  const items: MoveItemData[] = useMemo(
    () =>
      itemIds.map((id) => {
        if (id === `recent-separator`)
          return {
            ...RecentSeparatorItem,
            isSelected: () => false,
          }
        if (id === `destination-separator`)
          return {
            ...DestinationSeparatorItem,
            isSelected: () => false,
          }

        const destination = results[id] ?? {}
        return {
          id: destination.id,
          emoji: destination.emoji,
          iconVariant: destination.iconVariant,
          title: destination.title,
          isSelected,
          type: ResultType.Destination,
        }
      }),
    [itemIds, results, isSelected],
  )

  const onBlur = useCallback(() => setSelectedIndex(0), [])

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((event.target as any)?.value)
    setSelectedIndex(0)
  }, [])

  const handleResultClick = useCallback(
    ({ id }: HandleResultPayload) => {
      triggerAction({ target: `update-default`, invalidIds: selectedRecordIds })
      if (id === AddSection)
        sendToNewDestination({
          title: query ?? ``,
        })
      else handleDestinationResultClick(id)
    },
    [
      query,
      selectedRecordIds,
      sendToNewDestination,
      handleDestinationResultClick,
    ],
  )

  const scrollToNewIndex = useCallback(
    (newSelectedIndex?: number) => {
      if (newSelectedIndex === undefined) return
      const id = items[newSelectedIndex]?.id
      if (!id) return
      scrollAction({ target: `move-to`, to: { id } })
    },
    [items],
  )

  const moveSelectedDown = useCallback(() => {
    let newSelectedIndex = selectedIndex
    do {
      newSelectedIndex = Math.min((newSelectedIndex ?? -1) + 1, resultsCount)
      setSelectedIndex(newSelectedIndex)
    } while (
      items[newSelectedIndex]?.type === ResultType.Separator &&
      newSelectedIndex < resultsCount
    )
    if (newSelectedIndex < resultsCount) scrollToNewIndex(newSelectedIndex)
  }, [items, selectedIndex, resultsCount, scrollToNewIndex])

  const moveSelectedUp = useCallback(() => {
    if (!selectedIndex) return
    let newSelectedIndex = selectedIndex
    do {
      newSelectedIndex = Math.max(
        newSelectedIndex - 1,
        items[0]?.type === ResultType.Separator ? 1 : 0,
      )
      setSelectedIndex(newSelectedIndex)
    } while (
      items[newSelectedIndex]?.type === ResultType.Separator &&
      newSelectedIndex > 1
    )
    scrollToNewIndex(newSelectedIndex)
  }, [items, selectedIndex, scrollToNewIndex])

  const submitSelectedToNewSection = useCallback(() => {
    handleResultClick({ id: AddSection, type: ResultType.Add })
  }, [handleResultClick])

  const submitSelected = useCallback(() => {
    if (selectedIndex === undefined) return
    if (selectedIndex === resultsCount) {
      submitSelectedToNewSection()
      return
    }
    const item = items[selectedIndex]
    if (!item || item.type === ResultType.Separator) return
    handleResultClick({ id: item.id, type: item.type })
  }, [
    selectedIndex,
    items,
    resultsCount,
    handleResultClick,
    submitSelectedToNewSection,
  ])

  const handleSearchInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case `Tab`:
          event.preventDefault()
          break
        case `ArrowDown`: {
          if (event.shiftKey) return
          event.preventDefault()
          moveSelectedDown()
          break
        }
        case `ArrowUp`: {
          if (event.shiftKey) return
          event.preventDefault()
          moveSelectedUp()
          break
        }
        case `Enter`: {
          event.preventDefault()
          if (event.metaKey) {
            submitSelectedToNewSection()
          } else {
            submitSelected()
          }
          break
        }
        case `Escape`:
          close()
          break
        default:
      }
    },
    [
      moveSelectedDown,
      moveSelectedUp,
      submitSelected,
      submitSelectedToNewSection,
      close,
    ],
  )

  const { isMouseRecentlyActive } = useUIContext()
  const onHover = useCallback(
    (id: string) => {
      if (!isMouseRecentlyActive) return
      const index = indexOf(itemIds, id)
      setSelectedIndex(index)
    },
    [itemIds, isMouseRecentlyActive],
  )

  const onDownArrow = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault()
      moveSelectedDown()
    },
    [moveSelectedDown],
  )

  const onUpArrow = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault()
      moveSelectedUp()
    },
    [moveSelectedUp],
  )

  const onEnter = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault()
      submitSelected()
    },
    [submitSelected],
  )

  const onSchedule = emptyFn
  const onEsc = close
  const onComplete = emptyFn
  const onMove = close
  const onSubmit = emptyFn

  const handlers = useMemo(
    () => ({
      onDownArrow,
      onUpArrow,
      onEnter,
      onSchedule,
      onComplete,
      onMove,
      onEsc,
      onChange,
      onSubmit,
      handleSearchInputKeyDown,
      handleResultClick,
      handleNewCollectionClick: submitSelectedToNewSection,
      onBlur,
      items,
      sendToNewDestination,
      onHover,
      inputValue: query,
      isSelectedAddNewCollection: selectedIndex === resultsCount,
    }),
    [
      onDownArrow,
      onUpArrow,
      onEnter,
      onSchedule,
      onComplete,
      onMove,
      onEsc,
      onChange,
      onSubmit,
      handleSearchInputKeyDown,
      handleResultClick,
      submitSelectedToNewSection,
      onBlur,
      items,
      sendToNewDestination,
      onHover,
      query,
      selectedIndex,
      resultsCount,
    ],
  )

  return handlers
}

const useOnDidSubmitWithAnalytics = (
  onDidSubmit: UseSendToDestinationData['sendToDestination'],
) => {
  const activeScreen = useActiveScreen()
  const onDidSubmitWithAnalytics: UseSendToDestinationData['sendToDestination'] =
    useCallback(
      (payload) => {
        const newDestinationId = onDidSubmit(payload)

        const moveToDestinationType = getRecordsMovedDestinationType(
          payload.destinationId,
        )
        if (
          moveToDestinationType ===
            Analytics.MoveToDestinationType.NEW_USER_LIST &&
          newDestinationId
        ) {
          Analytics.listCreated({
            activeScreen,
            listCreatedTrigger: Analytics.ListCreatedTrigger.MOVE_TO_FLOW,
            listCreatedWithEmptyTitle: (payload.title ?? ``).length === 0,
            recordId: newDestinationId,
          })
        }

        Analytics.recordsMoved({
          activeScreen,
          numRecords: payload.recordIds.length,
          moveToDestinationType,
          recordsMovedTrigger: RecordsMovedTrigger.MOVE_TO_DIALOG,
          moveType: Analytics.MoveType.REPARENT,
          recordIds: payload.recordIds,
          newParentId: newDestinationId || payload.destinationId,
        })

        return newDestinationId
      },
      [activeScreen, onDidSubmit],
    )
  return onDidSubmitWithAnalytics
}

const RecentSeparatorItem: ResultItem = {
  id: `recent-separator`,
  title: `Recents`,
  type: ResultType.Separator,
}

const DestinationSeparatorItem: ResultItem = {
  id: `destination-separator`,
  title: `Collections`,
  type: ResultType.Separator,
}
