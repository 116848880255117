import React from 'react'

import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { RouteType } from '../../common/routes'
import { WithModelStore, withModelStore } from '../enhancers/withData'
import { WithSelection, withSelection } from '../enhancers/withSelection'
import { withSideBar } from '../enhancers/withSideBar'
import { withSnackbar } from '../enhancers/withSnackbar'
import { WithUIContext, withUIContext } from '../enhancers/withUIContext'
import { ScreenProps } from '../types'
import TodayScreen from './TodayScreen'
import { useTodayControls } from './hooks/useTodayControls'

export type TodayScreenContainerProps = ScreenProps & RouteComponentProps

type TodayScreenContainerComponentProps = TodayScreenContainerProps &
  WithModelStore &
  WithUIContext &
  WithSelection

const TodayScreenContainer = (props: TodayScreenContainerComponentProps) => {
  const { store } = props

  const todayControls = useTodayControls(store)

  return (
    <TodayScreen
      {...props}
      routeType={RouteType.Today}
      controls={todayControls}
      activeDate={todayControls.activeDate}
    />
  )
}

export default withSnackbar(
  withSideBar(
    withUIContext(withModelStore(withSelection(TodayScreenContainer))),
  ),
)
