import { useCallback } from 'react'

import {
  FileMetadata,
  RecordType,
  UpdateRecordPayload,
} from '@eleventhlabs/capture-shared'
import { Records } from '../records'

export const useConvertToFileRecord = (
  updateRecord: (recordId: string, payload: UpdateRecordPayload) => void,
  deleteRecords: (recordIds: string[]) => void,
  setSelectionDefaultId: (id: string) => void,
  uploadFile: (recordId: string, efile: File) => Promise<FileMetadata>,
) => {
  const convertToFileRecord = useCallback(
    (recordId: string, file: File) => {
      updateRecord(recordId, { type: RecordType.File })
      Records.addFileToRecord(
        recordId,
        file,
        updateRecord,
        deleteRecords,
        setSelectionDefaultId,
        uploadFile,
      )
    },
    [updateRecord, deleteRecords, setSelectionDefaultId, uploadFile],
  )

  return {
    convertToFileRecord,
  }
}
