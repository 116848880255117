import { useState } from 'react'

import { emptyFn } from '../../utils'

export interface FullscreenControlsData {
  lastRoute?: string
  setLastRoute: (value: string | undefined) => void
  lastSelection: string[]
  setLastSelection: (value: string[]) => void
  lastDefaultId?: string
  setLastDefaultId: (value: string | undefined) => void
  shouldRestoreSelection: boolean
  setShouldRestoreSelection: (value: boolean) => void
}

export const defaultFullscreenControls = {
  setLastRoute: emptyFn,
  lastSelection: [],
  setLastSelection: emptyFn,
  setLastDefaultId: emptyFn,
  shouldRestoreSelection: false,
  setShouldRestoreSelection: emptyFn,
}

export const useFullscreenControls = () => {
  const [lastRoute, setLastRoute] = useState<string | undefined>(undefined)
  const [shouldRestoreSelection, setShouldRestoreSelection] = useState(false)
  const [lastSelection, setLastSelection] = useState<string[]>([])
  const [lastDefaultId, setLastDefaultId] = useState<string | undefined>(
    undefined,
  )

  return {
    lastRoute,
    setLastRoute,
    lastSelection,
    setLastSelection,
    lastDefaultId,
    setLastDefaultId,
    shouldRestoreSelection,
    setShouldRestoreSelection,
  }
}
