import { isEmpty } from 'lodash'
import emoji from 'node-emoji'
import { useCallback, useMemo } from 'react'

import { Frack, UpdateRecordPayload } from '@eleventhlabs/capture-shared'

import {
  InboxRecordId,
  RecordDict,
  RecordType,
  RootRecord,
} from '@eleventhlabs/capture-shared'
import { ModelStoreCommands } from '../modelStore'
import {
  Destinations,
  InboxDestination,
  SendToDestinationActionPayload,
  SendToNewDestinationActionPayload,
} from './types'
import { inboxDestination, mapListRecordToDestination } from './utils'
import { useStartOfTodayISO } from '../../hooks/useStartOfTodayISO'

export interface UseLocalDestinationsData {
  destinations: any
  sendToDestination: (payload: SendToDestinationActionPayload) => void
  sendToNewDestination: (payload: SendToNewDestinationActionPayload) => string
}

const useLocalDestinations = (
  records: RecordDict,
  root: RootRecord,
  commands: ModelStoreCommands,
): UseLocalDestinationsData => {
  const startOfTodayIso = useStartOfTodayISO().today
  const destinations = useMemo(() => {
    const destinations: Destinations = {
      [InboxDestination]: inboxDestination,
    }
    Frack.toArray(root.children).forEach((recordId) => {
      const listRecord = records[recordId]
      if (listRecord.type !== RecordType.List)
        throw new Error(`Unexpected record type`)
      destinations[recordId] = mapListRecordToDestination(listRecord)
    })

    return destinations
  }, [records, root, startOfTodayIso])

  // Commands

  const sendToInboxDestination = useCallback(
    ({ recordId, payload }: SendToDestinationActionPayload) => {
      if (!recordId) return
      if (recordId && !isEmpty(payload)) {
        commands.updateRecord(recordId, payload as UpdateRecordPayload)
      }
      commands.moveRecords([recordId], {
        id: InboxRecordId,
        point: { at: `start` },
      })
    },
    [commands],
  )
  const sendToSectionDestination = useCallback(
    ({ destinationId, recordId, payload }: SendToDestinationActionPayload) => {
      if (!recordId) return
      if (recordId && !isEmpty(payload)) {
        commands.updateRecord(recordId, payload as UpdateRecordPayload)
      }
      commands.moveRecords([recordId], {
        id: destinationId,
        point: { at: `start` },
      })
    },
    [commands],
  )

  const sendToDestination = useCallback(
    (payload: SendToDestinationActionPayload) => {
      if (payload.destinationId === InboxDestination)
        sendToInboxDestination(payload)
      else sendToSectionDestination(payload)
    },
    [sendToInboxDestination, sendToSectionDestination],
  )

  const sendToNewDestination = useCallback(
    ({
      title,
      recordIds,
      payload,
    }: SendToNewDestinationActionPayload): string => {
      if (!isEmpty(payload) && !isEmpty(recordIds)) {
        recordIds.forEach((id) => {
          commands.updateRecord(id, payload as UpdateRecordPayload)
        })
      }
      const id = commands.moveRecords(recordIds, {
        payload: {
          emoji: emoji.random().key,
          title,
          type: RecordType.List,
        },
      })
      if (id === undefined) throw new Error(`Expected new list id `)
      return id
    },
    [commands],
  )

  return { destinations, sendToDestination, sendToNewDestination }
}

export default useLocalDestinations
