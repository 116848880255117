import { isArray, isEqualWith, isObject, omitBy, includes } from 'lodash'

export const comparisonFunc = (a: any, b: any) => {
  if (isArray(a) || isArray(b)) return
  if (!isObject(a) || !isObject(b)) return

  //@ts-ignore
  const hasUndefinedA = includes(a, undefined)
  // @ts-ignore
  const hasUndefinedB = includes(b, undefined)

  if (!hasUndefinedA && !hasUndefinedB) return

  // Filter out properties with undefined values
  const filteredA = omitBy(a, (value) => value === undefined)
  const filteredB = omitBy(b, (value) => value === undefined)

  // Call recursively, after filtering all undefined properties
  return isEqualWith(filteredA, filteredB, comparisonFunc)
}
