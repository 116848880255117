import { styled } from '../common/stationary/styled'

export const ContentDiagramDialogInnerWidth = 448

export const ContentDiagramDialog = styled.div`
  display: flex;
  position: relative;
  margin: calc(env(safe-area-inset-top) + 0px)
    calc(env(safe-area-inset-right) + 0px)
    calc(env(safe-area-inset-bottom) + 0px)
    calc(env(safe-area-inset-left) + 0px);
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.effects.boxShadow.elevation[4]};
  background: ${({ theme }) => theme.colors.surface.base};
  overflow: hidden;

  @media screen and (max-width: ${ContentDiagramDialogInnerWidth * 2}px) {
    flex-direction: column-reverse;
    margin: calc(env(safe-area-inset-top) + 8px)
      calc(env(safe-area-inset-right) + 8px)
      calc(env(safe-area-inset-bottom) + 8px)
      calc(env(safe-area-inset-left) + 8px);
  }
`

export const DialogHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`

export const HeaderCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  margin: 16px;
  border-radius: 999px;
  background: ${({ theme }) => theme.colors.alpha.shade.strong};
  opacity: 0.6;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.alpha.tint.solid};
    font-size: 24px;
  }
  :hover {
    opacity: 1;
  }
`

export const DialogInner = styled.div`
  width: ${ContentDiagramDialogInnerWidth}px;

  @media screen and (max-width: ${ContentDiagramDialogInnerWidth * 2}px) {
    width: 100%;
    max-width: ${ContentDiagramDialogInnerWidth}px;
  }
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 48px;
  min-height: 100%;
`

export const ContentTitle = styled.div`
  margin-bottom: 16px;
  ${({ theme }) => theme.text.publicSans['33:40']};
  font-weight: 800;
`

export const ContentDescription = styled.div`
  margin-bottom: 16px;

  strong {
    font-weight: 700;
  }
`

export const ContentUl = styled.ul`
  margin-bottom: 8px;
  margin-left: 16px;
  list-style: disc;
`

export const ContentLi = styled.li`
  padding-bottom: 8px;

  strong {
    font-weight: 700;
  }
`

export const ContentFiller = styled.div`
  flex: 1;
`

export const ContentDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 24px 0;

  :before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: ${({ theme }) => theme.colors.alpha.tone.mediumWeak};
  }
`

export const DividerText = styled.div`
  position: relative;
  padding: 0 8px;
  background: ${({ theme }) => theme.colors.surface.base};
  color: ${({ theme }) => theme.colors.text[700]};
  ${({ theme }) => theme.text.publicSans['11.5:16']};
  ${({ theme }) => theme.text.publicSans.uppercase as any};
  font-weight: 700;
`

export const ContentControls = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 8px;
`

export const ContentPrimaryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.effects.boxShadow.elevation[2]};
  background: ${({ theme }) => theme.colors.text[900]};
  color: ${({ theme }) => theme.colors.alpha.invertedTone.solid};
  font-weight: 700;
  cursor: pointer;

  :hover {
    box-shadow: ${({ theme }) => theme.effects.boxShadow.elevation[3]};
    filter: brightness(1.25);
  }
`

export const ContentControlHint = styled.div`
  color: ${({ theme }) => theme.colors.text[700]};
  ${({ theme }) => theme.text.publicSans['13.5:20']};
  font-style: italic;
`

export const DialogDiagram = styled.img`
  display: block;
`
