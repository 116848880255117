import React from 'react'

import { Icon, styled } from '../../../../common/stationary'

const HeaderBreadCrumbErrorBoundary: React.FC = () => {
  return (
    <S.ErrorBoundary>
      <Icon boxSize={16} variant="glyphWarning" />
      Capture is out of date!
    </S.ErrorBoundary>
  )
}

export default HeaderBreadCrumbErrorBoundary

const S = {
  ErrorBoundary: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 500,
    lineHeight: `16px`,
    color: theme.colors.orange[700],
    marginLeft: 5,
    maxWidth: `180px`,
    textOverflow: `ellipsis`,
    overflowX: `hidden`,
    fontStyle: `italic`,

    '&.notLastElement': {
      fontWeight: 400,
      cursor: `pointer`,
    },
  })),
}
