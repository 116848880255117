import React from 'react'

import { Key, Priorities, useKeys } from '../../hooks/useKeys'
import { CommandBarHandlers, ResultGroup } from './CommandBar.types'
import ResultList from './components/ResultList'
import SearchInput from './components/SearchInput'
import { styled } from '../../stationary'

export interface CommandBarProps {
  query?: string
  groupData: ResultGroup
  placeholder?: string
  inputRef?: React.RefObject<HTMLInputElement>
  handlers: CommandBarHandlers
  selectionCount: number
}

const ListItemSize = 48
const ListMaxHeight = 320

export const CommandBar = ({
  query,
  groupData,
  placeholder,
  inputRef,
  handlers,
  selectionCount,
}: CommandBarProps) => {
  const {
    onDownArrow,
    onUpArrow,
    onEnter,
    onChange,
    onEsc,
    onHover,
    handleResultClick,
    handleSearchInputKeyDown,
  } = handlers

  useKeys([Key.Down, Key.J], onDownArrow, Priorities.Dialog, true)
  useKeys([Key.Up, Key.K], onUpArrow, Priorities.Dialog, true)
  useKeys(Key.Enter, onEnter, Priorities.Dialog, true)
  useKeys(Key.Esc, onEsc, Priorities.Dialog, true)

  const listHeight = groupData.items.length * ListItemSize

  return (
    <S.CommandBar>
      <S.Header>
        <SearchInput
          query={query}
          inputRef={inputRef}
          onKeyDown={handleSearchInputKeyDown}
          placeholder={placeholder}
          selectionCount={selectionCount}
          onChange={onChange}
        />
      </S.Header>
      <S.Body>
        <S.List
          style={{
            height: listHeight,
            maxHeight: ListMaxHeight,
          }}
        >
          <ResultList
            items={groupData.items}
            onHover={onHover}
            handleResultClick={handleResultClick}
          />
        </S.List>
      </S.Body>
    </S.CommandBar>
  )
}

export default CommandBar

const S = {
  CommandBar: styled.div(({ theme }) => ({
    display: `flex`,
    flexDirection: `column`,
    background: theme.colors.surface.base,
  })),
  Header: styled.div(({ theme }) => ({
    boxShadow: `0 0 0 1px ${theme.colors.alpha.border.mediumWeak}`,
  })),
  Body: styled.div({
    display: `flex`,
    flexDirection: `column`,
    flex: 1,
  }),
  List: styled.div({
    display: `flex`,
    flexDirection: `column`,
    margin: `8px 0`,
  }),
  ResultList: styled(ResultList)({
    overflowX: `hidden !important` as any,
  }),
}
