import { CSSObject } from '../styled'
import { Theme } from '../theme'

/**
 * calendarStyles
 */

export const calendarStyles = ({ theme }: { theme: Theme }): CSSObject => ({
  padding: 16,
  ...theme.text.publicSans['13.5:20'],
  // width: 160,

  '.react-calendar__navigation': {
    display: 'flex',
  },

  '.react-calendar__navigation__arrow': {
    height: 32,
    width: 24,
    padding: 0,
    border: `none`,
    background: `none`,
    appearance: `none`,
    outline: `none`,
    cursor: `pointer`,
    color: theme.colors.text[900],

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      background: theme.colors.alpha.tone.medium,
    },
  },
  '.react-calendar__navigation__label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    padding: 0,
    border: `none`,
    fontWeight: `bold`,
    background: `none`,
    appearance: `none`,
    outline: `none`,
    cursor: `pointer`,
    color: theme.colors.text[900],
    pointerEvents: 'none', // Disables label click. This prevents the user from selecting the month and year, since the default UX in react-calendar is confusing

    '&:hover': {
      background: theme.colors.alpha.tone.medium,
    },
  },
  '.react-calendar__month-view__weekdays__weekday': {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    ...theme.text.publicSans['13.5:20'],
    lineHeight: `16px`,
    height: 32,
    color: theme.colors.text[700],

    abbr: {
      textDecoration: `none`,
    },
  },
  '.react-calendar__month-view__days__day--neighboringMonth': {
    color: theme.colors.text[500],
  },
  '.react-calendar__navigation__label__labelText': {
    fontWeight: `bold`,
    ...theme.text.publicSans['13.5:20'],
  },
})

/**
 * calendarTileStyles
 */

export const calendarTileStyles = ({ theme }: { theme: Theme }): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  margin: 0,
  padding: 0,
  border: `none`,
  borderRadius: 999,
  ...theme.text.publicSans['13.5:20'],
  background: `none`,
  appearance: `none`,
  outline: `none`,
  cursor: `pointer`,
  color: theme.colors.text[900],

  '&:hover': {
    background: theme.colors.alpha.tone.medium,
  },

  '&.currentDate': {
    background: theme.colors.alpha.selection.solid,
    color: theme.colors.alpha.tint.solid,
  },

  '&.futureRecurrent': {
    background: theme.colors.alpha.tone.mediumStrong,
  },
})
