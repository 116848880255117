import React from 'react'

import { SlideProps } from '.'
import { Key, useKeys } from '../../../../common/hooks/useKeys'
import { styled } from '../../../../common/stationary'
import { isMobile } from '../../../../common/utils/env'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  TransitionSlideContainer,
} from './variants/ImmersiveSlide'

export const IntroInbox: React.FC<SlideProps> = ({ goForward }) => {
  useKeys([Key.Enter], () => goForward())

  return (
    <S.IntroInbox>
      <S.Inner>
        <TransitionSlideContainer>
          <SlideIntro>
            <SlideTitle>An inbox for your ideas 💡</SlideTitle>
            <SlideDescription>
              To-dos, web pages, screenshots — anything you capture will show up
              here.
            </SlideDescription>
            <SlideButtonGroup>
              <SlidePrimaryButton onClick={() => goForward()}>
                <strong>Continue</strong>
              </SlidePrimaryButton>
            </SlideButtonGroup>
          </SlideIntro>
        </TransitionSlideContainer>
      </S.Inner>
    </S.IntroInbox>
  )
}

const S = {
  IntroInbox: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',

    ...(isMobile && {
      padding: '0 24px',
    }),
  })),
  Inner: styled.div(() => ({
    width: '100%',
    maxWidth: isMobile ? 320 : 768,
  })),
}
