import { useCallback, useEffect, useRef } from 'react'

import { emptyFn } from '../utils'

export enum ClickOutsideEventType {
  OnClick = `click`,
  OnMouseDown = `mousedown`,
  OnMouseUp = `mouseup`,
}

interface ClickOutsideProps {
  mouseEvent?: ClickOutsideEventType
  onClickOutside?: (event: MouseEvent) => void
}

/**
 * This hooks detect any click OUTSIDE the assigned ref
 * The ref must be assigned to the desired component
 */

export const useClickOutside = <T extends HTMLElement>({
  mouseEvent = ClickOutsideEventType.OnMouseDown,
  onClickOutside = emptyFn,
}: ClickOutsideProps) => {
  const ref = useRef<T>(null)

  const handleClickOutside = useCallback(
    (event) => {
      if (!ref?.current?.contains(event.target)) {
        onClickOutside(event)
      }
    },
    [onClickOutside],
  )

  useEffect(() => {
    document.addEventListener(mouseEvent, handleClickOutside)
    return () => {
      document.removeEventListener(mouseEvent, handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouseEvent, handleClickOutside])

  // This must be assigned to the desired component to observe
  return { ref }
}
