import { cx } from '@emotion/css'
import { styled } from '../../../common/stationary'
import { isEmpty } from 'lodash'
import React, { FC, FormEvent } from 'react'
import ClearInputIcon from '../../../assets/icon/icon-clear-input.svg'
import { AuthStyles as S } from '../AuthStyles'

export interface LoginFormProps {
  isLoading: boolean
  onSubmit?: (event: FormEvent<HTMLFormElement>, username: string) => void
  isPasswordLogin: boolean
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
  username: string
  setUsername: React.Dispatch<React.SetStateAction<string>>
  resetLoginState: () => void
}

/**
 * LoginForm
 */

const LoginForm: FC<LoginFormProps> = (props: LoginFormProps) => {
  const {
    onSubmit = () => undefined,
    isLoading,
    username,
    setUsername,
    isPasswordLogin,
    password,
    setPassword,
    resetLoginState,
  } = props

  const handleClearEmail = () => {
    resetLoginState()
    setUsername('')
  }

  return (
    <S.AuthForm onSubmit={(event) => onSubmit(event, username)}>
      <S.InputWithIcon>
        <LoginFormStyles.LoginUsername
          name="username"
          disabled={isLoading}
          onChange={(event) => {
            resetLoginState()
            setUsername(event.target.value)
          }}
          placeholder="Enter your email address..."
          value={username}
          autoFocus
          type="email"
        />
        {!isEmpty(username) && (
          <S.InlineIconPositioning>
            <LoginFormStyles.ClearInputIcon onClick={handleClearEmail} />
          </S.InlineIconPositioning>
        )}
      </S.InputWithIcon>

      {isPasswordLogin && (
        <S.AuthPassword
          name="password"
          type="password"
          onChange={(event) => setPassword(event.target.value)}
          value={password}
          placeholder="Enter your password"
        />
      )}
      <S.AuthSubmit type="submit" disabled={isLoading || isEmpty(username)}>
        Continue with email
        {isLoading && (
          <S.SubmitIcon
            className={cx({ isLoading })}
            boxSize={16}
            variant="glyphCircleThreeQuarters"
          />
        )}
      </S.AuthSubmit>
    </S.AuthForm>
  )
}

export default LoginForm

const LoginFormStyles = {
  LoginUsername: styled.input(({ theme }) => ({
    all: 'unset',
    boxSizing: 'border-box',
    height: 48,
    width: '100%',
    marginBottom: 8,
    border: `1px solid ${theme.colors.alpha.border.medium}`,
    borderRadius: 8,
    background: theme.colors.alpha.tone.extraWeak,
    textAlign: 'left',
    paddingLeft: 16,
    paddingRight: 16,
    color: theme.colors.text[900],

    '&:focus': {
      borderColor: theme.colors.alpha.border.strong,
    },

    '::placeholder': {
      color: theme.colors.text[500],
    },

    '&:disabled': {
      color: theme.colors.text[600],
    },
  })),
  ClearInputIcon: styled(ClearInputIcon)(() => ({
    height: 16,
    cursor: 'pointer',
  })),
}
