import { useCallback, useMemo } from 'react'

import { useExecContext } from '../contexts/ExecContext'
import { useNoteRichTextEventHandlers } from './richTextEventHandlers'
import { ClickHTMLElementEvent, FocusEvent, NoteHandlers } from './types'

export const useNoteEventHandlers = (): NoteHandlers => {
  const { exec } = useExecContext()

  const onFocus = useCallback(
    (ev: FocusEvent, noteId: string) => {
      exec({ type: 'NOTE_FOCUSED', noteId })
    },
    [exec],
  )

  const onBlur = useCallback(
    async (ev: FocusEvent, noteId: string) => {
      exec({ type: 'NOTE_BLURRED', noteId })
    },
    [exec],
  )

  const onMouseDown = useCallback(
    (event: ClickHTMLElementEvent, noteId: string, parentId?: string) => {
      exec({ type: 'NOTE_MOUSE_DOWN', event, noteId, parentId })
    },
    [exec],
  )

  const {
    onArrowLeftOrUpAtBoundary,
    onArrowRightOrDownAtBoundary,
    onBackspaceWhenEmpty,
    onChange,
    onPaste,
    onKeyDown,
  } = useNoteRichTextEventHandlers()

  const handlers = useMemo(
    () => ({
      onArrowLeftOrUpAtBoundary,
      onArrowRightOrDownAtBoundary,
      onBackspaceWhenEmpty,
      onBlur,
      onChange,
      onPaste,
      onFocus,
      onKeyDown,
      onMouseDown,
    }),
    [
      onArrowLeftOrUpAtBoundary,
      onArrowRightOrDownAtBoundary,
      onBackspaceWhenEmpty,
      onBlur,
      onChange,
      onPaste,
      onFocus,
      onKeyDown,
      onMouseDown,
    ],
  )

  return handlers
}
