import { useCallback } from 'react'
import { useDragDropManager } from 'react-dnd'
import { useReactDnDContext } from '../../../../contexts/ReactDnDContext'
import { useSelectionContext } from '../../../../contexts/SelectionContext'
import { RecordGroupType } from '../../../../data/recordGroups'
import {
  DragItemData,
  DragPayload,
  DragProps,
  DragSourceType,
  RecordItemDragData,
} from '../../types'

export interface DragSourceFactoryHandlers {
  // Called on drag start, returns the info about the current dragged item(s)
  onDragStart: (payload: DragPayload) => DragItemData
  // Props returned to the caller about drag state / anything else needed to render & do app-level logic
  getDragProps: (payload: DragPayload) => DragProps
  // Called when the drag ends
  onDragEnd: () => void
  // Whether the record can be dragged
  canDrag?: (payload: DragPayload) => boolean
}

export const useDragSourceFactory = <
  DragSourceProps extends DragProps,
>(): Record<DragSourceType, DragSourceFactoryHandlers> => {
  // Use react-dnd drag-drop monitor
  const dragDropMonitor = useDragDropManager().getMonitor()
  const dragContext = useReactDnDContext()
  const { selection } = useSelectionContext()

  const isBeingDragged = useCallback(
    (payload: DragPayload) => {
      return (
        dragContext.isDragInProgress &&
        dragContext.draggedRecords.map((r) => r.id).includes(payload.record.id)
      )
    },
    [dragContext.draggedRecords, dragContext.isDragInProgress],
  )

  return {
    [DragSourceType.RECORD]: {
      onDragStart: (payload: DragPayload): RecordItemDragData => {
        // If currently dragged record is not in selection, just drag that one.
        // Delesect current selection and don’t use current selection
        if (!selection.ids.includes(payload.record.id)) {
          dragContext.setDraggedRecords(payload.record)
        } else {
          dragContext.setDraggedRecords()
        }

        dragContext.setIsDragInProgress(true)

        return {
          type: DragSourceType.RECORD,
          index: payload.index ?? 0,
          record: payload.record,
          showToggle: payload.showToggle,
          groupType: payload.groupType,
        }
      },
      getDragProps: (payload): DragSourceProps => {
        return {
          isDragging: dragDropMonitor.isDragging(),
          isInDragGroup: isBeingDragged(payload),
        } as DragSourceProps
      },
      onDragEnd: () => {
        // Set dragging to be false finally
        dragContext.setIsDragInProgress(false)
        dragContext.setIsDragIntent(false)
        dragContext.setHoverIndex(-1)
      },
      canDrag: (payload) => {
        // Only allow dragging from non-completed group
        return payload.groupType !== RecordGroupType.ListComplete
      },
    },
  }
}
