import { isBefore, parseISO } from 'date-fns'
import { some } from 'lodash'

import {
  CreateRecordPayload,
  MoveToExistingPlaceIndex,
  RecordDict,
  RecordType,
  isTickleableRecord,
  isWithTickler,
  InboxRecordId,
} from '@eleventhlabs/capture-shared'
import { ModelStoreCommands } from '../../../modelStore'
import { OverdueRecordGroupId } from '../../selectors/today'
import { RecordGroupsCommands } from '../../types'
import { getAreAllOverdue } from '../../utils'

export const createTodayRecordGroupsCommands = (
  activeDateIso: string,
  todayIso: string,
  records: RecordDict,
  commands: ModelStoreCommands,
): RecordGroupsCommands => {
  const isBeforeToday = (str: string): boolean =>
    isBefore(parseISO(str), parseISO(todayIso))
  const areAnyOverdue = (ids: string[]) =>
    some(
      ids.map((id) => {
        const record = records[id]
        return (
          isTickleableRecord(record) &&
          record.tickler &&
          isBeforeToday(record.tickler.isoDate)
        )
      }),
    )
  return {
    createAtFirstInsertPosition: (payload: CreateRecordPayload) => {
      if (payload.type === RecordType.Note) throw new Error(``)
      const moveToIndex: MoveToExistingPlaceIndex = {
        id: InboxRecordId,
        point: { at: `start` },
      }

      const recordId = commands.createRecord(
        {
          ...payload,
          tickler: { isoDate: activeDateIso },
          pointInDateGroup: { at: `start` },
        },
        moveToIndex,
      )

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    createAtIndexInGroup: (
      payload: CreateRecordPayload,
      groupId: string,
      index: number,
    ) => {
      if (groupId === OverdueRecordGroupId) throw new Error(``)
      if (payload.type === RecordType.Note) throw new Error(``)

      const moveToIndex: MoveToExistingPlaceIndex = {
        id: InboxRecordId,
        point: { at: `start` },
      }

      const recordId = commands.createRecord(
        {
          ...payload,
          tickler: { isoDate: groupId },
          pointInDateGroup: { at: `index`, index },
        },
        moveToIndex,
      )

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    addItemAtBottomOfGroup: (payload: CreateRecordPayload, groupId: string) => {
      if (groupId === OverdueRecordGroupId) throw new Error(``)
      if (payload.type === RecordType.Note) throw new Error(``)

      const moveToIndex: MoveToExistingPlaceIndex = {
        id: InboxRecordId,
        point: { at: `start` },
      }
      const recordId = commands.createRecord(
        {
          ...payload,
          tickler: { isoDate: groupId },
          pointInDateGroup: { at: `end` },
        },
        moveToIndex,
      )

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    createBelowAnchor: (payload: CreateRecordPayload, anchorId: string) => {
      if (payload.type === RecordType.Note) throw new Error(``)
      if (areAnyOverdue([anchorId]))
        throw new Error(`Cannot create records in the overdue section`)
      const anchorRecord = records[anchorId]
      if (!isWithTickler(anchorRecord))
        throw new Error(`Expected date for record`)

      const moveToIndex: MoveToExistingPlaceIndex = {
        id: InboxRecordId,
        point: { at: `start` },
      }

      const recordId = commands.createRecord(
        {
          ...payload,
          tickler: { isoDate: anchorRecord.tickler.isoDate },
          pointInDateGroup: { at: `after`, anchorId },
        },
        moveToIndex,
      )

      return { id: recordId, type: payload.type, location: moveToIndex }
    },
    moveBelowAnchor: (ids: string[], anchorId: string) => {
      const anchorRecord = records[anchorId]
      if (!isWithTickler(anchorRecord))
        throw new Error(`Expected date for record`)
      if (isBeforeToday(anchorRecord.tickler.isoDate)) {
        const areAllOverdue = getAreAllOverdue(records, todayIso, ids)
        if (!areAllOverdue) return
        else {
          commands.moveRecords(ids, {
            isoDate: `overdue`,
            point: { at: `after`, anchorId },
          })
        }
      } else {
        commands.moveRecords(ids, {
          isoDate: anchorRecord.tickler.isoDate,
          point: { at: `after`, anchorId },
        })
      }
    },
    moveAboveAnchor: (ids: string[], anchorId: string) => {
      const anchorRecord = records[anchorId]
      if (!isWithTickler(anchorRecord))
        throw new Error(`Expected date for record`)
      if (isBeforeToday(anchorRecord.tickler.isoDate)) {
        const areAllOverdue = getAreAllOverdue(records, todayIso, ids)
        if (!areAllOverdue) return
        else {
          commands.moveRecords(ids, {
            isoDate: `overdue`,
            point: { at: `before`, anchorId },
          })
        }
      } else {
        commands.moveRecords(ids, {
          isoDate: anchorRecord.tickler.isoDate,
          point: { at: `before`, anchorId },
        })
      }
    },
    moveToStartOfGroup: (ids: string[], groupId: string) => {
      if (groupId === OverdueRecordGroupId) return
      commands.moveRecords(ids, {
        isoDate: groupId,
        point: { at: `start` },
      })
    },
    moveToEndOfGroup: (ids: string[], groupId: string) => {
      if (groupId === OverdueRecordGroupId) return
      commands.moveRecords(ids, {
        isoDate: groupId,
        point: { at: `end` },
      })
    },
  }
}
