import { isEmpty, isString } from 'lodash'
import md5 from 'md5'
import { v4 as uuidv4 } from 'uuid'

const isFile = (item: DataTransferItem): boolean => item?.type.includes(`image`)

export const getFileFromClipboardEvent = (
  event: React.ClipboardEvent<HTMLDivElement>,
): File | null | undefined => {
  const items = event.clipboardData?.items

  if (isEmpty(items)) return
  const item = items[0]
  if (!isFile(item)) return

  return item.getAsFile()
}

export const createFileName = (
  file: File,
  options: {
    withTimestamp?: boolean
    withId?: boolean
    withUser?: string
  } = {},
): string => {
  const fileParts = isString(file.name) ? file.name.split(`.`) : []
  const fileExtension = fileParts.pop()

  if (options.withUser) fileParts.push(md5(options.withUser))
  if (options.withId) fileParts.push(uuidv4())
  if (options.withTimestamp) fileParts.push(`${Date.now()}`)

  return `${fileParts.join(`_`)}${fileExtension ? `.${fileExtension}` : ``}`
}

export const createThumbnailName = (file: File): string => {
  const fileParts = isString(file.name) ? file.name.split(`.`) : []
  const fileExtension = fileParts.pop()
  return `${fileParts.join(`_`)}${fileParts.length > 0 ? `_` : ``}thumbnail${
    fileExtension ? `.${fileExtension}` : ``
  }`
}

export const isImage = (mimeType?: string) => {
  if (!mimeType) return false
  const imageType = /image.*/
  return mimeType.match(imageType)
}

export const isVideo = (mimeType?: string) => {
  if (!mimeType) return false
  const videoType = /video.*/
  return mimeType.match(videoType)
}
