import { first } from 'lodash'

import {
  ShowAnnotationsToggleTrigger,
  ShowAnnotationsToggleTriggerValueType,
} from '../../../../analytics/capture-analytics-actions'
import { DataRecord, isNoteableRecord } from '@eleventhlabs/capture-shared'

export const noteKeyDownLowerThan = (
  e: React.KeyboardEvent<HTMLDivElement>,
  record: DataRecord,
  noteId: string,
  closeAnnotations: (
    records: Record<string, DataRecord>,
    recordIds: string[],
    selectIds: (ids: string[]) => void,
    deleteNotes: (noteIds: string[]) => void,
    setNotesVisibilityForRecordIds: (
      ids: string[],
      visibility: boolean,
      trigger: ShowAnnotationsToggleTriggerValueType,
    ) => void,
    showAnnotationsTrigger: ShowAnnotationsToggleTriggerValueType,
  ) => void,
  records: Record<string, DataRecord>,
  recordIds: string[],
  selectIds: (ids: string[]) => void,
  deleteNotes: (noteIds: string[]) => void,
  setNotesVisibilityForRecordIds: (
    ids: string[],
    visibility: boolean,
    trigger: ShowAnnotationsToggleTriggerValueType,
  ) => void,
) => {
  const isFirstAndOnlyNote =
    isNoteableRecord(record) && first(record.notes) === noteId
  if (isFirstAndOnlyNote) {
    e.preventDefault()
    closeAnnotations(
      records,
      recordIds,
      selectIds,
      deleteNotes,
      setNotesVisibilityForRecordIds,
      ShowAnnotationsToggleTrigger.KEYBOARD_LT,
    )
    return true
  }
  return false
}
