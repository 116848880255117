import React, { useCallback } from 'react'

import { InboxDestination } from '../../../common/data/useDestinations'
import {
  RouteType,
  SystemListsRouteType,
  isSystemListId,
} from '../../../common/routes'
import { Icon, cx, styled } from '../../../common/stationary'
import { RouteIcon } from '../../RouteIcon'
import { HandleResultAction, ResultItem } from '../MoveTo.types'

export interface MoveItemProps extends ResultItem {
  isSelected: boolean
  handleResultClick: HandleResultAction
  onHover: (id: string) => void
}

const MoveItem = ({
  id,
  emoji,
  // iconVariant,
  title,
  type,
  isSelected,
  handleResultClick,
  onHover,
}: MoveItemProps) => {
  const onClick = useCallback(
    () => handleResultClick({ id, type }),
    [handleResultClick, id, type],
  )

  const onMouseEnter = useCallback(() => onHover(id), [onHover, id])

  let routeType
  if (id === InboxDestination) routeType = RouteType.Inbox
  else
    routeType = isSystemListId(id) ? SystemListsRouteType[id] : RouteType.List

  return (
    <S.MoveItem
      className={cx({ isSelected })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <S.MoveItemButton>
        <S.MoveItemLeft>
          <RouteIcon
            boxSize={16}
            routeType={routeType}
            value={emoji ? { emoji } : undefined}
          />
        </S.MoveItemLeft>
        <S.MoveItemTitle className={cx({ isSelected })}>
          {title}
        </S.MoveItemTitle>
        {isSelected ? (
          <S.MoveItemRight>
            <S.MoveItemRightIcon boxSize={16} variant="glyphArrowE" />
          </S.MoveItemRight>
        ) : null}
      </S.MoveItemButton>
    </S.MoveItem>
  )
}

export default MoveItem

const S = {
  MoveItem: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    boxSizing: `border-box`,
    height: 40,
    margin: `0 8px`,
    cursor: 'pointer',

    '&.isSelected': {
      background: theme.colors.alpha.tone.weak,
      borderRadius: 8,
    },
  })),
  MoveItemButton: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    width: `100%`,
    padding: `0 16px`,
    textAlign: `left`,
  }),
  MoveItemLeft: styled.div({
    paddingRight: 12,
    width: 32,
  }),
  MoveItemTitle: styled.div(({ theme }) => ({
    flex: 1,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 500,
    lineHeight: `20px`,
    color: theme.colors.text[700],

    '&.isSelected': {
      color: theme.colors.text[900],
    },
  })),
  MoveItemRight: styled.div({
    paddingLeft: 12,
  }),
  MoveItemRightIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.text[500],
  })),
}
