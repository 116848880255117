import { useCallback, useContext, useMemo } from 'react'
import { ModalContext } from '../../ModalContext'
import { ModalComponentProps, ModalName } from '../modalConfig'

interface UseModalHooks {
  openModal: (
    name: ModalName,
    props: ModalComponentProps[ModalName],
    recordId?: string | undefined,
  ) => void
  closeModal: () => void
}

/**
 * Returns an object containing functions for opening and closing modals, as well as
 * helper functions for selecting records and clearing selection.
 *
 * @return {UseModalHooks} An object containing the openModal and closeModal functions.
 */
export const useModal = (): UseModalHooks => {
  const { setCurrentModal } = useContext(ModalContext)

  const openModal = useCallback(
    (
      name: ModalName,
      props: ModalComponentProps[ModalName],
      recordId?: string | undefined,
    ) => {
      setCurrentModal({ name, props })
    },
    [setCurrentModal],
  )

  const closeModal = useCallback(() => {
    setCurrentModal(null)
  }, [setCurrentModal])

  return useMemo(() => {
    return { openModal, closeModal }
  }, [openModal, closeModal])
}
