import hotkeys, { HotkeysEvent } from 'hotkeys-js'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { SlideProps } from '.'
import CaptureLogo from '../../../../assets/brand/brand-logotype.svg'
import { QUICK_CAPTURE_SHORTCUT } from '../../../../common/desktop/types'
import { Key } from '../../../../common/hooks/useKeys'
import { Keycap, KeycapGroup, cx, styled } from '../../../../common/stationary'
import { SubmitRecordDelay } from '../../OnboardingScreen'
import { useOnboardingContext } from '../../OnboardingScreenContext'
import {
  HotkeyConfig,
  OnboardingKeys,
  prettifyKey,
  printKeys,
  useOnboardingHotkeys,
} from '../../hooks/useOnboardingHotkeys'
import {
  SlideButtonGroup,
  SlideDescription,
  SlideDiagrams,
  SlideIntro,
  SlidePrimaryButton,
  SlideTitle,
  SlideWaitingHint,
  SlideWaitingSpinner,
  TransitionSlideContainer,
} from './variants/ImmersiveSlide'
import { isEqual, sortBy } from 'lodash'

type DemoState = 'Start' | 'ShowingQuickCapture' | 'WaitingForRecord' | 'Finish'

export const DemoQuickCapture: React.FC<SlideProps> = ({
  goForward,
  submitRecord,
  updateWidgetStyle,
}) => {
  const [demoState, setDemoState] = useState<DemoState>('Start')
  const [textEditorValue, setTextEditorValue] = useState('')
  const [highlightedKeys, setHighlightedKeys] = useState<string[]>([])

  const formRef = useRef<HTMLFormElement>(null)
  const textEditorRef = useRef<HTMLTextAreaElement>(null)

  const { onboardingAnalytics } = useOnboardingContext()

  useEffect(() => {
    if (demoState === 'WaitingForRecord')
      /**
       * Delay to time setDemoState with the animations and emoji confetti
       */
      setTimeout(() => {
        setDemoState('Finish')
      }, SubmitRecordDelay)
  }, [demoState, setDemoState])

  useEffect(() => {
    if (demoState === 'Start') {
      updateWidgetStyle('InteractiveInbox', { opacity: 0.8 })
    }
    if (demoState === 'ShowingQuickCapture') {
      textEditorRef.current?.focus()
    }
    if (demoState === 'WaitingForRecord') {
      updateWidgetStyle('InteractiveInbox', { opacity: 1 })
    }
  }, [demoState])

  const handleTextEditorChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (e) => setTextEditorValue(e.target.value)

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (textEditorValue.length > 0) {
      submitRecord(textEditorValue)
      setDemoState('WaitingForRecord')
    }
  }

  const onDismissQuickCapture = useCallback(
    (_event: KeyboardEvent) => {
      if (demoState === 'ShowingQuickCapture') {
        setDemoState('Start')
        onboardingAnalytics.action('Quick Capture Dismissed (Keys)')
      }
    },
    [demoState],
  )

  const onShowQuickCapture = useCallback(
    (event: KeyboardEvent) => {
      // If we're currently holding down the shortcut, don't do anything so we don't keep flashing the quick capture dialog
      if (isShowQuickCaptureShortcutDown.current) return

      isShowQuickCaptureShortcutDown.current = true

      event.preventDefault() // Prevent spacebar from inserting spaces into editor

      if (demoState === 'Start' || demoState === 'Finish') {
        setDemoState('ShowingQuickCapture')
        onboardingAnalytics.action('Show Quick Capture (Keys)')
        setTextEditorValue('')
      }
      if (demoState === 'ShowingQuickCapture') setDemoState('Start')
    },
    [demoState],
  )

  const isShowQuickCaptureShortcutDown = useRef<boolean>(false)

  const onSubmitQuickCapture = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault()
      event.stopPropagation()
      if (demoState === 'ShowingQuickCapture') {
        formRef.current?.dispatchEvent(new Event('submit', { bubbles: true }))
        onboardingAnalytics.action('Quick Capture Submitted (Keys)')
      }
    },
    [demoState, onboardingAnalytics],
  )

  const onKeyDown = useCallback(
    (event: KeyboardEvent, handler: HotkeysEvent) => {
      let highlightedKeys: string[] = []

      if (event.type === 'keydown') {
        if (handler.keys.includes(18)) {
          highlightedKeys.push('Alt')
        }

        if (handler.keys.includes(32)) {
          highlightedKeys.push(' ')

          // Prevent spacebar from inserting spaces into editor if you hold alt + space
          if (isShowQuickCaptureShortcutDown.current) {
            return event.preventDefault()
          }
        }
      }

      if (event.type === 'keyup') {
        if (handler.keys.includes(18)) {
          highlightedKeys = highlightedKeys.filter((key) => key !== 'Alt')
        } else if (handler.keys.includes(32)) {
          highlightedKeys = highlightedKeys.filter((key) => key !== ' ')
        }

        isShowQuickCaptureShortcutDown.current = false
      }

      const didPressQuickCaptureShortcutIgnoreOrder = isEqual(
        sortBy(highlightedKeys),
        sortBy(OnboardingKeys.ShowQuickCapture),
      )

      // Need to check this here because Windows is finicky
      if (didPressQuickCaptureShortcutIgnoreOrder) {
        onShowQuickCapture(event)
      }

      setHighlightedKeys(highlightedKeys)
    },
    [onShowQuickCapture],
  )

  const onMoveForward = useCallback(() => {
    demoState === 'Finish' && goForward()
  }, [demoState])

  const hotKeyConfig: HotkeyConfig[] = useMemo(
    () => [
      { key: Key.Esc, handler: onDismissQuickCapture },
      {
        key: QUICK_CAPTURE_SHORTCUT,
        handler: onShowQuickCapture,
      },
      {
        key: Key.CommandEnter,
        handler: onSubmitQuickCapture,
      },
      {
        key: Key.Enter,
        handler: onMoveForward,
      },
      {
        key: '*',
        handler: onKeyDown,
        listenToKeyUp: true,
        listenToKeyDown: true,
      },
    ],
    [
      onDismissQuickCapture,
      onShowQuickCapture,
      onSubmitQuickCapture,
      onMoveForward,
      onKeyDown,
    ],
  )

  // Using this hook instead of `useKeys` from our current system because we need to refactor our
  // current system to be allowed to not only register an arbitrary set of keys at any point, but also configure
  // the key filters, scope, and whether to allow them in inputs. The current keyboard shortcut system by default
  // does not allow keys to be registered in inputs.
  useOnboardingHotkeys(hotKeyConfig, 'demoQuickCapture')

  const demoQuickCaptureRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (demoState === 'Finish') {
      // Focus the div so "Enter" can be triggered correctly and we can move forward without having to focus the screen manually.
      // Emoji rewards seems to steal focus sometimes and interferes with our onboarding hotkey handlers, even though they are registered.
      demoQuickCaptureRef?.current?.focus()
    }
  }, [demoState])

  return (
    <S.DemoQuickCapture
      ref={demoQuickCaptureRef}
      tabIndex={-1}
      onKeyDown={(event) => {
        if (event.key === 'Enter') onMoveForward()
      }}
    >
      <S.Inner>
        <S.Overlay
          onClick={() => {
            setDemoState('Start')
            onboardingAnalytics.action('Quick Capture Dismissed (Click Away)')
          }}
          style={{
            opacity: demoState === 'ShowingQuickCapture' ? 1 : 0,
            pointerEvents:
              demoState === 'ShowingQuickCapture' ? 'initial' : 'none',
          }}
        />
        <TransitionSlideContainer>
          <SlideIntro>
            <SlideTitle>Off your mind, into Capture 🧠</SlideTitle>
            <SlideDescription>
              <strong>One keyboard shortcut</strong> for taking notes and
              capturing thoughts.
            </SlideDescription>
            <SlideButtonGroup>
              {demoState !== 'WaitingForRecord' && demoState !== 'Finish' && (
                <SlideWaitingHint>
                  <span>
                    Press{' '}
                    <strong>
                      {printKeys(
                        OnboardingKeys.ShowQuickCapture.map(prettifyKey),
                      )}
                    </strong>
                  </span>
                  <SlideWaitingSpinner
                    boxSize={16}
                    variant="glyphCircleThreeQuarters"
                  />
                </SlideWaitingHint>
              )}
              {(demoState === 'WaitingForRecord' || demoState === 'Finish') && (
                <>
                  <SlidePrimaryButton
                    disabled={demoState !== 'Finish'}
                    onClick={() => goForward()}
                  >
                    <strong>Continue</strong>
                    {demoState === 'WaitingForRecord' && (
                      <SlideWaitingSpinner
                        boxSize={16}
                        variant="glyphCircleThreeQuarters"
                      />
                    )}
                  </SlidePrimaryButton>
                </>
              )}
              <S.RepeatQuickCaptureHint
                className={cx({
                  isShown:
                    demoState === 'WaitingForRecord' || demoState === 'Finish',
                })}
              >
                or press{' '}
                <strong>
                  {printKeys(OnboardingKeys.ShowQuickCapture.map(prettifyKey))}
                </strong>{' '}
                to Quick Capture again
              </S.RepeatQuickCaptureHint>
            </SlideButtonGroup>
          </SlideIntro>
          <SlideDiagrams>
            <Transition appear in timeout={0}>
              {(state) => (
                <S.Diagram
                  style={{
                    height:
                      state === 'entering' ||
                      demoState === 'WaitingForRecord' ||
                      demoState === 'Finish'
                        ? 0
                        : Dimensions.DiagramHeight,
                    opacity:
                      state === 'entering' ||
                      demoState === 'WaitingForRecord' ||
                      demoState === 'Finish'
                        ? 0
                        : 1,
                    pointerEvents:
                      state === 'entering' ||
                      demoState === 'WaitingForRecord' ||
                      demoState === 'Finish'
                        ? 'none'
                        : 'initial',
                  }}
                >
                  <S.QuickCapturePlaceholder>
                    <S.LargeKeycapGroup>
                      {OnboardingKeys.ShowQuickCapture.map((key, index) => (
                        <S.LargeKeycap
                          className={cx({
                            isHighlighted: highlightedKeys.indexOf(key) !== -1,
                          })}
                          key={index}
                        >
                          {prettifyKey(key)}
                        </S.LargeKeycap>
                      ))}
                    </S.LargeKeycapGroup>
                  </S.QuickCapturePlaceholder>
                  <S.PlaceholderCaption>
                    Keyboard shortcut not working?{' '}
                    <a
                      onClick={() => {
                        onboardingAnalytics.action(
                          'Show Quick Capture (Skipped)',
                        )
                        setDemoState('ShowingQuickCapture')
                      }}
                    >
                      Skip for now
                    </a>
                  </S.PlaceholderCaption>
                  <S.QuickCapture
                    onSubmit={handleSubmit}
                    ref={formRef}
                    style={{
                      opacity: demoState === 'ShowingQuickCapture' ? 1 : 0,
                      transform:
                        demoState === 'ShowingQuickCapture'
                          ? 'translateY(0)'
                          : 'translateY(4px)',
                      pointerEvents:
                        demoState === 'ShowingQuickCapture'
                          ? 'initial'
                          : 'none',
                    }}
                  >
                    <S.AnnouncementPin>
                      <S.AnnouncementTitle>
                        You’ve found Quick Capture! ⚡️
                      </S.AnnouncementTitle>
                      <S.AnnouncementDescription>
                        Add something e.g. “remember to buy milk”, then press{' '}
                        <S.AnnouncementKeycapGroup>
                          {OnboardingKeys.SubmitQuickCapture.map(
                            prettifyKey,
                          ).map((keycap, index) => (
                            <S.AnnouncementKeycap key={index}>
                              {keycap}
                            </S.AnnouncementKeycap>
                          ))}
                        </S.AnnouncementKeycapGroup>{' '}
                        to save it
                      </S.AnnouncementDescription>
                    </S.AnnouncementPin>
                    <S.TextEditor
                      disabled={demoState !== 'ShowingQuickCapture'}
                      onChange={handleTextEditorChange}
                      placeholder="What’s on your mind?"
                      ref={textEditorRef}
                      value={textEditorValue}
                    />
                    <S.QuickCaptureFooter>
                      <S.CaptureLogo />
                      <S.SubmitButtonGroup>
                        <S.SubmitDivider />
                        <S.SubmitButton
                          onClick={() => {
                            onboardingAnalytics.action(
                              'Quick Capture Submitted (Button)',
                            )
                          }}
                          disabled={textEditorValue.length === 0}
                          type="submit"
                        >
                          <strong>Save to Inbox</strong>
                          <S.SmallKeycapGroup>
                            {OnboardingKeys.SubmitQuickCapture.map(
                              (key, index) => (
                                <S.SmallKeycap key={index}>
                                  {prettifyKey(key)}
                                </S.SmallKeycap>
                              ),
                            )}
                          </S.SmallKeycapGroup>
                        </S.SubmitButton>
                      </S.SubmitButtonGroup>
                    </S.QuickCaptureFooter>
                  </S.QuickCapture>
                </S.Diagram>
              )}
            </Transition>
          </SlideDiagrams>
        </TransitionSlideContainer>
      </S.Inner>
    </S.DemoQuickCapture>
  )
}

const useDemoQuickCaptureHotkeys = ({
  onDismissQuickCapture,
  onShowQuickCapture,
  onSubmitQuickCapture,
  onKeyDown,
}: {
  onDismissQuickCapture: (event: KeyboardEvent) => void
  onShowQuickCapture: (event: KeyboardEvent) => void
  onSubmitQuickCapture: (event: KeyboardEvent) => void
  onKeyDown: (event: KeyboardEvent, hotKeyEvent: HotkeysEvent) => void
}) => {
  // Using hotkeysJS directly here until we can modify our own keyboard shortcut infra to
  // be configurable around text inputs.
  useEffect(() => {
    hotkeys(Key.Esc, 'onboardingDemoQuickCapture', onDismissQuickCapture)
    hotkeys(
      QUICK_CAPTURE_SHORTCUT,
      'onboardingDemoQuickCapture',
      onShowQuickCapture,
    )
    hotkeys(
      Key.CommandEnter,
      'onboardingDemoQuickCapture',
      onSubmitQuickCapture,
    )

    // HotkeysJS doesn't seem to capture "alt" well, or at least not on @willwang888's machine.
    // Hack around this by listening to all keys
    hotkeys(
      '*',
      { scope: 'onboardingDemoQuickCapture', keyup: true, keydown: true },
      onKeyDown,
    )

    hotkeys.setScope('onboardingDemoQuickCapture')

    // Unset all the callbacks that we've registered for this screen / scope once we move on
    return () => {
      hotkeys.deleteScope('onboardingDemoQuickCapture')
    }
  }, [
    onDismissQuickCapture,
    onShowQuickCapture,
    onSubmitQuickCapture,
    onKeyDown,
  ])
}

const Dimensions = {
  DiagramHeight: 160 + 40 + 56,
}

const S = {
  DemoQuickCapture: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 48px',
  })),
  Inner: styled.div(() => ({
    width: '100%',
    maxWidth: 768,
  })),

  Overlay: styled.div(({ theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: theme.colors.alpha.invertedTone.extraStrong,
    zIndex: 1,

    transition: 'opacity 0.1s ease',
  })),

  RepeatQuickCaptureHint: styled.div(({ theme }) => ({
    flex: 1,
    ...theme.text.publicSans['13.5:20'],
    fontStyle: 'italic',
    textAlign: 'right',
    color: theme.colors.text[600],
    opacity: 0,
    pointerEvents: 'none',

    transition: 'opacity 0.8s ease',

    strong: {
      fontWeight: 700,
    },

    '&.isShown': {
      opacity: 1,
      transitionDelay: '1s',
    },
  })),

  Diagram: styled.div(() => ({
    position: 'relative',

    transition: 'height 0.8s ease, opacity 0.4s ease',
  })),
  QuickCapturePlaceholder: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 160,
    border: `2px dotted ${theme.colors.alpha.border.medium}`,
    borderRadius: 10,
  })),
  PlaceholderCaption: styled.div(({ theme }) => ({
    marginTop: 16,
    ...theme.text.publicSans['13.5:20'],
    fontStyle: 'italic',
    lineHeight: '24px',
    textAlign: 'center',
    color: theme.colors.text[600],
    cursor: 'default',

    a: {
      fontWeight: 600,
      cursor: 'pointer',

      transition: 'opacity 0.4s ease',

      ':hover': {
        textDecoration: 'underline',
      },
    },
  })),
  LargeKeycapGroup: styled.div(() => ({
    display: 'flex',
    gap: 4,
  })),
  LargeKeycap: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    padding: '0 16px',
    border: `1px solid ${theme.colors.text[900]}`,
    borderRadius: 6,
    ...theme.text.publicSans['16:24'],
    fontWeight: 700,
    opacity: 0.8,
    cursor: 'default',

    transition: 'outline 0.1s ease, opacity 0.1s ease, transform 0.2s ease',

    '&.isHighlighted': {
      outline: `1px solid ${theme.colors.text[900]}`,
      opacity: 1,
      transform: 'translateY(-4px)',
    },
  })),
  SmallKeycapGroup: styled.div(() => ({
    display: 'flex',
    gap: 2,
  })),
  SmallKeycap: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    padding: '0 8px',
    border: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    borderRadius: 4,
    ...theme.text.publicSans['11.5:16'],
    fontWeight: 700,
    color: theme.colors.text[700],
    background: theme.colors.surface.base,
  })),
  QuickCapture: styled.form(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    borderRadius: 10,
    boxShadow: theme.effects.boxShadow.elevation[4],
    background: theme.colors.surface.lower,

    transition: 'opacity 0.1s ease, transform 0.2s ease',
  })),
  AnnouncementPin: styled.div(({ theme }) => ({
    position: 'absolute',
    bottom: '100%',
    left: 0,
    margin: '2px 8px',
    padding: '16px 24px 16px 16px',
    outline: `1px solid ${theme.colors.alpha.shade.extraStrong}`,
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[4],
    color: theme.colors.alpha.tint.solid,
    background: theme.colors.alpha.shade.solid,

    transition: 'opacity 0.1s ease, transform 0.2s ease',

    ':before': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: 16,
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderTop: `12px solid ${theme.colors.alpha.shade.extraStrong}`,
    },
    ':after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: 18,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: `10px solid ${theme.colors.alpha.shade.solid}`,
    },
  })),
  AnnouncementTitle: styled.div(({ theme }) => ({
    ...theme.text.publicSans['16:24'],
    fontWeight: 800,
  })),
  AnnouncementDescription: styled.div(({ theme }) => ({
    ...theme.text.publicSans['13.5:20'],
    lineHeight: '24px',

    strong: {
      fontStyle: 'normal',
      fontWeight: 700,
    },
  })),
  AnnouncementKeycapGroup: styled(KeycapGroup)(() => ({
    display: 'inline',
    margin: '0 2px',
  })),
  AnnouncementKeycap: styled(Keycap)(() => ({})),
  TextEditor: styled.textarea(({ theme }) => ({
    flexBasis: 104,
    margin: '8px 8px 0 8px',
    padding: 20,
    borderRadius: 8,
    boxShadow: theme.effects.boxShadow.elevation[1],
    background: theme.colors.surface.base,

    transition: 'box-shadow 0.1s ease',

    '::placeholder': {
      color: theme.colors.text[500],
    },

    ':focus': {
      boxShadow: theme.effects.boxShadow.elevation[2],
    },
  })),
  QuickCaptureFooter: styled.div(() => ({
    flexBasis: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  })),
  CaptureLogo: styled(CaptureLogo)(({ theme }) => ({
    height: 20,
    width: 'auto',
    marginLeft: 20,

    path: {
      fill: theme.colors.text[900],
    },
  })),
  SubmitButtonGroup: styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    padding: 12,
  })),
  SubmitButton: styled.button(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    padding: 4,
    borderRadius: 6,
    ...theme.text.publicSans['13.5:20'],
    cursor: 'pointer',
    opacity: 0.85,

    strong: {
      padding: '0 8px',
      fontWeight: 700,
    },

    ':hover': {
      opacity: 1,
    },
    ':disabled': {
      opacity: 0.6,
      cursor: 'not-allowed',
    },
  })),
  SubmitDivider: styled.div(({ theme }) => ({
    height: 20,
    width: 1,
    background: theme.colors.alpha.border.weak,
  })),
}
