import {
  CURRENT_RICH_TEXT_VERSION,
  DataRecord,
  UpdateNoteRecordPayload,
  UpdateTextRecordPayload,
} from '@eleventhlabs/capture-shared'
import { EditorsManager } from '../../../../EditorsManager'
import { getFileFromClipboardEvent } from '../../../../files'
import { isLink } from '../../../../utils'

export const recordPasteFromClipboard = (
  event: React.ClipboardEvent<HTMLDivElement>,
  record: DataRecord,
  updateRecordText: (
    recordId: string,
    payload: UpdateTextRecordPayload | UpdateNoteRecordPayload,
  ) => void,
  convertToUrlRecord: (recordId: string, url: string) => Promise<boolean>,
  convertToFileRecord: (recordId: string, file: File) => void,
) => {
  event.preventDefault()
  const clipboardValue = event.clipboardData.getData(`Text`)

  const file = getFileFromClipboardEvent(event)

  const richText = EditorsManager.get.richText(record.id)

  if (clipboardValue.includes('\t')) {
    // Product decision: replace tabs with nothing, can be revisited if
    // this end up having undesirable implications at some point
    const modifiedValue = clipboardValue.replace(/\t/gm, '')

    // Write the modified value to the clipboard
    navigator.clipboard.writeText(modifiedValue)

    return true
  }

  if (isLink(clipboardValue) && EditorsManager.get.isVisuallyEmpty(record.id)) {
    convertToUrlRecord(record.id, clipboardValue)
  } else if (file) {
    // Bail if the record has something already
    if (!EditorsManager.get.isVisuallyEmpty(record.id)) return

    convertToFileRecord(record.id, file)
  } else {
    updateRecordText(record.id, {
      richText: { value: richText, version: CURRENT_RICH_TEXT_VERSION },
    })
  }
}
