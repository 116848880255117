import { ApiEndpoints } from '../ApiEndpoints'
import { apiCall } from './client'

export const getAWSSignedData = async () => {
  return await apiCall(ApiEndpoints.awsSignedDataURI, {
    method: `GET`,
    headers: {
      'Content-Type': `application/json; charset=utf-8`,
    },
  })
    .then(async (response) => await response.json())
    .catch(() => ({}))
}
