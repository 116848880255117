import {
  FileMetadata,
  MoveToExistingPlaceIndex,
  RecordType,
  RichText,
  UpdateRecordPayload,
} from '@eleventhlabs/capture-shared'
import { BaseSelection } from '../../../../../libs/selection'
import { PostNavigateOptions } from '../../../../../hooks/useNavigateToList'
import { RecordItemTargetType } from '../../../../../recordItem'

export enum CreateRecordPosition {
  FIRST_POSITION = `first_position`,
  START_OF_GROUP = `index_in_group`,
  BELOW_CURRENT = `below_current`,
}

export interface CreateRecordLocation<P> {
  position?: P
  groupId?: string
  index?: number
  anchorId?: string
}

export interface CreateRecordAtStartOfGroupArgs {
  id: string
  groupId: string
  targetType:
    | RecordItemTargetType.Text
    | RecordItemTargetType.Page
    | RecordItemTargetType.Bookmark
  currentRoute: string
  setLastRoute: (route: string) => void
  setLastSelection: (value: string[]) => void
  currentSelection: string[]
  setLastDefaultId: (value: string | undefined) => void
  navigate: (id: string, options?: PostNavigateOptions) => void
  openRecordInFullScreen: boolean | undefined
  richText?: RichText
  focusNewRecord?: boolean
}

export interface CreateRecordAtFirstPositionArgs {
  targetType:
    | RecordItemTargetType.Text
    | RecordItemTargetType.Page
    | RecordItemTargetType.Bookmark
  openRecordInFullScreen: boolean | undefined
  currentRoute: string
  setLastRoute: (route: string) => void
  currentSelection: string[]
  setLastSelection: (value: string[]) => void
  setLastDefaultId: (value: string | undefined) => void
  navigate: (id: string, options?: PostNavigateOptions) => void
  richText?: RichText
  focusNewRecord?: boolean
}

export interface CreateRecordInsertBelowArgs {
  selection: BaseSelection
}

export type CreateRecordPositionToArgs = {
  [CreateRecordPosition.FIRST_POSITION]: CreateRecordAtFirstPositionArgs
  [CreateRecordPosition.START_OF_GROUP]: CreateRecordAtStartOfGroupArgs
  [CreateRecordPosition.BELOW_CURRENT]: CreateRecordInsertBelowArgs
}

export interface CreateFileRecordExtras {
  updateRecord: (recordId: string, payload: UpdateRecordPayload) => void
  deleteRecords: (recordIds: string[]) => void
  setSelectionDefaultId: (id: string) => void
  uploadFile: (recordId: string, file: File) => Promise<FileMetadata>
  acceptedFiles: File[]
}

export type CreateRecordResult =
  | {
      id: string
      type: RecordType
      location: MoveToExistingPlaceIndex
    }
  | undefined
