import { useMemo } from 'react'

import { RouteType, useRouteFromLocation } from '../../routes'
import Analytics, { ActiveScreenValueType } from '../capture-analytics-actions'

export const useActiveScreen = (): ActiveScreenValueType | undefined => {
  const { routeType } = useRouteFromLocation()

  return useMemo(() => {
    if (routeType === undefined) return undefined
    else return getActiveScreenFromRoute(routeType)
  }, [routeType])
}

const getActiveScreenFromRoute = (route: RouteType, listId?: string) => {
  if (route === RouteType.Inbox) return Analytics.ActiveScreen.INBOX
  if (route === RouteType.Today) return Analytics.ActiveScreen.TODAY
  if (route === RouteType.List) return Analytics.ActiveScreen.USER_LIST
  if (route === RouteType.Details) return Analytics.ActiveScreen.FULL_SCREEN
  if (route === RouteType.Trash) return Analytics.ActiveScreen.TRASH
  if (route === RouteType.ResetPassword)
    return Analytics.ActiveScreen.RESET_PASSWORD
  throw new Error(``)
}
