import { RecordCreatedTriggerValueType } from '../../analytics/capture-analytics-actions'
import {
  CreateRecordPayload,
  FileRecord,
  ListRecord,
  MoveToExistingPlaceIndex,
  TextRecord,
  URLRecord,
  WithSoftDelete,
} from '@eleventhlabs/capture-shared'
import { WithNotes } from '../notes'
import { WithTags } from '../tags'
import { CreateRecordResult } from '../../useExec/groups/default/actions/createRecord/types'

type TextRecordWithoutNotes = Omit<TextRecord, 'notes'>
type URLRecordWithoutNotes = Omit<URLRecord, 'notes'>
type FileRecordWithoutNotes = Omit<FileRecord, 'notes'>
type ListRecordWithoutNotes = Omit<ListRecord, 'notes'>

export type RecordItemData = (
  | TextRecordWithoutNotes
  | URLRecordWithoutNotes
  | FileRecordWithoutNotes
  | ListRecordWithoutNotes
) &
  WithTags &
  WithNotes &
  WithSoftDelete

export enum RecordGroupType {
  ListMain = `ListMain`,
  ListComplete = `ListComplete`,
  Overdue = `Overdue`,
  Date = `Date`,
  Trash = `Trash`,
}

export interface RecordGroupData {
  id: string
  type: RecordGroupType
  title?: string
  subTitle?: string
  items: RecordItemData[]
  showToggle?: boolean
  showMoveTo?: boolean
  showCount?: boolean
}

export interface RecordGroupsCommands {
  createAtFirstInsertPosition: (
    payload: CreateRecordPayload,
  ) => CreateRecordResult
  createAtIndexInGroup: (
    payload: CreateRecordPayload,
    groupId: string,
    index: number,
  ) => CreateRecordResult
  addItemAtBottomOfGroup: (
    payload: CreateRecordPayload,
    groupId: string,
  ) => CreateRecordResult
  createBelowAnchor: (
    payload: CreateRecordPayload,
    anchorId: string,
  ) => CreateRecordResult
  moveBelowAnchor: (ids: string[], anchorId: string) => void
  moveAboveAnchor: (ids: string[], anchorId: string) => void
  moveToStartOfGroup: (ids: string[], groupId: string) => void
  moveToEndOfGroup: (ids: string[], groupId: string) => void
}
