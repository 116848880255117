import { Theme } from '@eleventhlabs/stationary'
import { keyframes } from '@emotion/css'
import { CSSObject } from '@emotion/styled'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { FC } from 'react'
import { useIsOnboardingCompletedFromLocalStorage } from '../common/onboarding'
import { Icon, Layout } from '../common/stationary/components'
import { spinAnimationStyles } from '../common/stationary/mixinStyles'
import { styled } from '../common/stationary/styled'
import { SideBarDimensions } from '../components/SideBar'

const LoadingScreen: FC<RouteComponentProps> = () => {
  const isSideBarHidden = localStorage.getItem('isLeftHidden') === 'true'
  const isOnboardingCompleted = useIsOnboardingCompletedFromLocalStorage()

  return (
    <Layout
      leftColumn={
        isOnboardingCompleted
          ? {
              children: (
                <S.LoadingSideBar>
                  <S.SideBarHeader>
                    <S.ToggleButtonSkeleton />
                    <S.MenuButtonSkeleton />
                  </S.SideBarHeader>
                  <S.SideBarOutline>
                    <S.OutlineShortcutSkeleton />
                    <S.OutlineShortcutSkeleton className="long" />
                    <S.OutlineShortcutSkeleton />
                    <S.OutlineShortcutSkeleton />
                    <S.DividerSkeleton />
                    <S.OutlineHeaderSkeleton />
                    <S.OutlineShortcutSkeleton className="long" />
                    <S.OutlineShortcutSkeleton className="long" />
                    <S.OutlineShortcutSkeleton />
                  </S.SideBarOutline>
                </S.LoadingSideBar>
              ),
              width: SideBarDimensions.width,
              isExpanded: !isSideBarHidden,
            }
          : undefined
      }
    >
      <S.LoadingMain>
        {isOnboardingCompleted ? (
          <S.MainHeader>
            <S.BreadcrumbSkeleton />
            <S.MoreControlsSkeleton />
          </S.MainHeader>
        ) : null}
        <S.MainInner>
          <S.SpinnerIcon boxSize={32} variant="glyphCircleThreeQuarters" />
        </S.MainInner>
      </S.LoadingMain>
    </Layout>
  )
}

export default LoadingScreen

const S = {
  LoadingSideBar: styled.div(({ theme }) => ({
    height: '100%',
    paddingTop: 'env(safe-area-inset-top)',
    background: theme.colors.surface.lowest,
  })),
  SideBarHeader: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 56,
    padding: '0 8px',
  })),
  ToggleButtonSkeleton: styled.div(({ theme }) => ({
    height: 20,
    width: 20,
    margin: 10,
    ...skeletonBackgroundStyles({ theme }),
  })),
  MenuButtonSkeleton: styled.div(({ theme }) => ({
    height: 18,
    width: 18,
    margin: 11,
    ...skeletonBackgroundStyles({ theme }),
  })),
  SideBarOutline: styled.div(() => ({
    padding: 8,
  })),
  OutlineShortcutSkeleton: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 36,

    ':before': {
      content: '""',
      position: 'absolute',
      width: 20,
      height: 20,
      marginLeft: 10,
      ...skeletonBackgroundStyles({ theme }),
    },
    ':after': {
      content: '""',
      position: 'absolute',
      width: 120,
      height: 12,
      marginLeft: 10,
      ...skeletonBackgroundStyles({ theme }),
    },

    '&.long': {
      ':after': {
        width: 160,
      },
    },
  })),
  DividerSkeleton: styled.div(({ theme }) => ({
    height: 1,
    margin: `8px 12px`,
    background: theme.colors.alpha.border.weak,
  })),
  OutlineHeaderSkeleton: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 36,

    ':before': {
      content: '""',
      position: 'absolute',
      height: 12,
      width: 80,
      marginLeft: 10,
      ...skeletonBackgroundStyles({ theme }),
    },
  })),

  LoadingMain: styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    paddingTop: 'env(safe-area-inset-top)',
  })),
  MainHeader: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 56,
  })),
  BreadcrumbSkeleton: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 28,
    margin: 14,

    ':before': {
      content: '""',
      position: 'absolute',
      width: 18,
      height: 18,
      marginLeft: 5,
      ...skeletonBackgroundStyles({ theme }),
    },
    ':after': {
      content: '""',
      position: 'absolute',
      width: 120,
      height: 12,
      marginLeft: 10,
      ...skeletonBackgroundStyles({ theme }),
    },
  })),
  MoreControlsSkeleton: styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 12,
    width: 80,
    margin: 22,
    ...skeletonBackgroundStyles({ theme }),
  })),
  MainInner: styled.div(() => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })),
  SpinnerIcon: styled(Icon)(({ theme }) => ({
    color: theme.colors.gray[400],
    ...spinAnimationStyles({ animationDuration: '2s' }),
  })),
}

const skeletonBackgroundStyles = ({ theme }: { theme: Theme }): CSSObject => ({
  position: 'relative',
  borderRadius: 4,

  animationName: keyframes({
    '0%': {
      background: theme.colors.gray[300],
    },
    '50%': {
      background: theme.colors.gray[400],
    },
    '100%': {
      background: theme.colors.gray[300],
    },
  }),
  animationDuration: '2s',
  animationIterationCount: `infinite`,
})
