export interface QuickSwitcherHandlers {
  onDownArrow: (event: KeyboardEvent) => void
  onUpArrow: (event: KeyboardEvent) => void
  onEnter: (event: KeyboardEvent) => void
  onEsc: (event: KeyboardEvent) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onHover?: (id: string) => void
  handleSearchInputKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void
  handleResultClick: (payload: HandleResultPayload) => void
}

export interface HandleResultPayload {
  id: string
  type: ItemType
}

export interface ResultGroup {
  title?: string
  items: ResultGroupItem[]
}

export interface ResultGroupItem {
  id: string
  type: ItemType
  renderPre?: JSX.Element
  renderContent?: JSX.Element
  renderPost?: JSX.Element
  isSelected?: (index: number) => boolean
}

export interface ResultListItemData {
  items: ResultGroupItem[]
  onHover?: (id: string) => void
  handleResultClick: (payload: HandleResultPayload) => void
}

export enum ItemType {
  Collection = `Collection`,
  SystemListRoute = `SystemListRoute`,
}
