import React, { useCallback, useMemo, useRef } from 'react'

import { useModelStoreContext } from '../../common/contexts/ModelStoreContext'
import { useHotkeysThreshold } from '../../common/hooks/useHotkeysThreshold'
import { Key, Priorities, useKeys } from '../../common/hooks/useKeys'
import Schedule from '../Schedule/Schedule'
import { RecurrenceModel, ReminderModel } from '../Schedule/Schedule.types'
import { useScheduleHandlers } from '../Schedule/hooks/useScheduleHandlers'
import { styled, useTheme } from '../../common/stationary'
import { Modal } from '../../common/dialogs'

export interface ScheduleDialogProps {
  selectedRecordIds: string[]
  activeRecordDueDate?: string
  onDateSelect: (
    isoDate: string | null,
    isoTime?: string | null,
    recurrence?: RecurrenceModel | null,
    reminder?: ReminderModel | null,
  ) => void
  isOpen?: boolean

  onDismiss: (e?: React.SyntheticEvent) => void
}

const ScheduleDialog: React.FC<ScheduleDialogProps> = ({
  isOpen,
  onDismiss,
  selectedRecordIds,
  activeRecordDueDate,
  onDateSelect,
}) => {
  const theme = useTheme()

  const { store } = useModelStoreContext()
  const inputRef = useRef<HTMLInputElement>(null)

  const selectedRecords = useMemo(
    () => selectedRecordIds.map((id) => store.records[id]),
    [store.records, selectedRecordIds],
  )
  const close = useCallback(() => onDismiss && onDismiss(), [onDismiss])

  // Hack: we need to register this here, because @reach/dialog unmounts the internal component on Esc
  // before it gets captured and executed, so DatePicker Esc capture never executes and does not prevent
  // lower priorities to run (send to destination is not affected as it focuses the input)
  useKeys(Key.Esc, close, Priorities.Dialog, true)
  useHotkeysThreshold(Priorities.Dialog)

  const {
    quickDates,
    selectedIndex,
    inputValue: dateInputValue,
    validInput,
    dateModel,
    previewModel,
    ...scheduleHandlers
  } = useScheduleHandlers(theme, selectedRecords, onDateSelect, close)

  return (
    <S.ScheduleDialog
      aria-label="ScheduleDialog"
      open={isOpen ?? false}
      // initialFocusRef={inputRef} // we use @reach Dialog underneath and if we let it autofocus it will focus on the input and expand the datepicker
      onClose={close}
    >
      <S.Inner>
        <Schedule
          inputRef={inputRef}
          selectedRecordIds={selectedRecordIds}
          inputValue={dateInputValue}
          validInput={validInput}
          quickDates={quickDates}
          activeRecordDueDate={activeRecordDueDate}
          selectedIndex={selectedIndex}
          dateModel={dateModel}
          previewModel={previewModel}
          onPreviewClick={scheduleHandlers.onSubmit}
          {...scheduleHandlers}
        />
      </S.Inner>
    </S.ScheduleDialog>
  )
}

export default ScheduleDialog

const S = {
  ScheduleDialog: styled(Modal)({
    // maxWidth: 512,
  }),
  Inner: styled.div({
    maxWidth: '100%',
    marginTop: 96,
  }),
}
