import { isBefore, isToday, isTomorrow, parseISO, startOfToday } from 'date-fns'
import React from 'react'

import { preventDefault } from '../../../../common/events/utils'
import {
  convertISOtoDisplay,
  isInNextSevenDaysExclusive,
} from '../../../../common/utils'
import { Icon, cx, styled, withTooltip } from '../../../../common/stationary'

interface DateTagProps {
  text: string
  date: string
  isoLocalTime?: string
  className?: string
  onClick: (e: any) => void
}

const DateTag = React.forwardRef<HTMLDivElement, DateTagProps>(
  ({ text, date, isoLocalTime, className, onClick }, ref) => {
    const _date = parseISO(date)
    const _isOverdue = isBefore(_date, startOfToday())
    const _isToday = isToday(_date)
    const _isThisWeek = isInNextSevenDaysExclusive(_date)
    const _isTomorrow = isTomorrow(_date)

    return (
      <S.Tag
        className={cx(
          className,
          { tagDateOverdue: _isOverdue },
          { tagDateToday: _isToday },
          { tagDateThisWeek: _isThisWeek },
          { tagDateTomorrow: _isTomorrow },
        )}
        onClick={onClick}
        onMouseDown={preventDefault}
        ref={ref}
      >
        <S.Icon boxSize={16} variant="glyphCalendarSingleSquircle" />
        <S.Text>{text}</S.Text>
        {isoLocalTime ? (
          <S.TimeTag className="time" onClick={onClick}>
            {`${convertISOtoDisplay(isoLocalTime)}`}
          </S.TimeTag>
        ) : null}
      </S.Tag>
    )
  },
)

export const DateTagWithTooltip = withTooltip(DateTag)

const S = {
  Tag: styled.div(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    textAlign: `center`,
    color: theme.colors.text[700],

    '&.tagDateOverdue': {
      color: theme.colors.red[700],
    },
    '&.tagDateThisWeek': {
      color: theme.colors.violet[700],
    },
    '&.tagDateToday': {
      color: theme.colors.green[700],
    },
    '&.tagDateTomorrow': {
      color: theme.colors.orange[700],
    },

    '&:hover': {
      color: theme.colors.text[900],
      '& .time': {
        color: theme.colors.text[900],
      },
    },

    '& .time': {
      color: theme.colors.text[700],
    },
  })),
  Icon: styled(Icon)({
    display: `flex`,
    marginRight: 6,
  }),
  Text: styled.div({
    display: `flex`,
  }),
  TimeTag: styled.div({
    display: `flex`,
    flexWrap: `nowrap`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    fontWeight: 400,
    marginLeft: 5,
  }),
}
