import {
  CreateListRecordPayload,
  CreateTextRecordPayload,
} from '@eleventhlabs/capture-shared'
import { IconVariantName } from '../../stationary'

export interface Destination {
  iconVariant?: IconVariantName
  emoji?: string
  title: string
  id: string
}

export type Destinations = Record<string, Destination>

export interface SendToDestinationActionPayload {
  destinationId: string
  recordId?: string
  payload: CreateTextRecordPayload | CreateListRecordPayload
}

export interface SendToNewDestinationActionPayload {
  title: string
  recordIds: string[]
  payload: CreateTextRecordPayload | CreateListRecordPayload
}

export interface SendToDestinationRecordPayload {
  title?: string
  isCompleted?: boolean
  isoDate?: string
}

export interface SendToDestinationSubmitPayload {
  destinationId: string
  title?: string
  recordIds: string[]
  recordData: SendToDestinationRecordPayload
}

export enum ResultType {
  Add = `Add`,
  Separator = `Separator`,
  Destination = `Destination`,
}

export const InboxDestination = `inbox-destination`
export const InboxDestinationText = `Inbox`

export const AddSection = `add-section`

export const VIRTUAL_DESTINATION = `virtual-destination`
