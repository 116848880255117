import ModalUnstyled, { ModalUnstyledProps } from '@mui/base/ModalUnstyled'
import React, { useEffect } from 'react'

import { useUIContext } from '../../contexts/UIContext'
import { cx, styled } from '../../stationary'

const stopEventPropagation: React.MouseEventHandler = (e) => e.stopPropagation()

export interface ModalProps {
  onClose?: ModalUnstyledProps['onClose']
  open: ModalUnstyledProps['open']
}

export const Modal: React.FC<ModalProps> = ({ children, onClose, open }) => {
  const { setIsAModalOpen } = useUIContext()

  useEffect(() => {
    setIsAModalOpen(open)
    return () => {
      setIsAModalOpen(false)
    }
  }, [open])

  const Backdrop = () => <S.Backdrop open={open} onClose={onClose} />

  return (
    // Prevent click events from bubbling up to parents.
    <div onClick={stopEventPropagation}>
      <S.Modal
        disableAutoFocus
        onClose={onClose}
        open={open}
        slots={{ backdrop: Backdrop }}
      >
        <S.Inner>{children}</S.Inner>
      </S.Modal>
    </div>
  )
}

interface BackdropProps {
  className?: string
  closeOnClick?: boolean
  onClose?: ModalProps['onClose']
  open?: ModalProps['open']
}

const BackdropUnstyled = React.forwardRef<HTMLDivElement, BackdropProps>(
  ({ onClose, open, className, closeOnClick = true, ...props }, ref) => {
    const onClick = React.useCallback(
      (e) => {
        closeOnClick && onClose?.(e, `backdropClick`)
      },
      [onClose],
    )
    return (
      <div
        className={cx({ 'MuiBackdrop-open': open }, className)}
        onClick={onClick}
        ref={ref}
        {...props}
      />
    )
  },
)

const S = {
  Modal: styled(ModalUnstyled)({
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    zIndex: 999,
  }),
  Inner: styled.div({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    pointerEvents: 'none',

    '>*': {
      pointerEvents: 'initial',
    },
  }),
  Backdrop: styled(BackdropUnstyled)(({ theme }) => ({
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    zIndex: -1,
    background: theme.colors.alpha.shade.strong,
  })),
}
