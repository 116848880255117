import { useMemo } from 'react'
import * as Analytics from '../analytics/capture-analytics-actions'
import { useExecContext } from '../contexts/ExecContext'
import { Key, useKeys } from '../hooks/useKeys'
import { KeyMappedActionIdType, KeyMappedActions } from './KeyMappedAction'
import { useKeyMappedActions } from './useKeyMappedActions'

export const useKeyMappedActionHotkeys = (
  actionsToMap: KeyMappedActionIdType[],
) => {
  const { exec } = useExecContext()

  // We're memoizing this object so that it doesn't get rebuilt every time it gets passed in to
  // useMappedKeys. Registering event handlers often can be expensive so we want to avoid downstream impact as much as possible.
  const actionIdToHandlerMap = useMemo(() => {
    return {
      KEY_MAPPED_FULLSCREEN_CLOSE: (e: KeyboardEvent) => {
        if (!actionsToMap.includes('KEY_MAPPED_FULLSCREEN_CLOSE')) return
        e.preventDefault()
        exec({ type: 'EXIT_FULLSCREEN' })
      },
      KEY_MAPPED_OPEN_GO_TO_DIALOG: () => {
        if (!actionsToMap.includes('KEY_MAPPED_OPEN_GO_TO_DIALOG')) return
        exec({
          type: 'OPEN_GO_TO_DIALOG',
          trigger: Analytics.QuickSwitcherFlowStartedTrigger.KEYBOARD_G,
        })
      },
      KEY_MAPPED_RECORD_TOGGLE_EXPANDED: () => {
        if (!actionsToMap.includes('KEY_MAPPED_RECORD_TOGGLE_EXPANDED')) return
        exec({
          type: 'RECORD_ITEMS_TOGGLE_IS_EXPANDED',
          trigger: Analytics.RecordExpandedTrigger.KEYBOARD_X,
        })
      },
      KEY_MAPPED_FULLSCREEN_OPEN: () => {
        if (!actionsToMap.includes('KEY_MAPPED_FULLSCREEN_OPEN')) return
        exec({
          type: 'EXPAND_TO_FULLSCREEN',
          trigger: Analytics.RecordOpenedTrigger.KEYBOARD_ARROW_RIGHT,
          triggeredByKeyboard: true,
          triggeredByUI: false,
        })
      },
      KEY_MAPPED_OPEN_KEYBOARD_SHORTCUT_DIALOG: () => {
        if (!actionsToMap.includes('KEY_MAPPED_OPEN_KEYBOARD_SHORTCUT_DIALOG'))
          return
        // We're toggling the right panel instead of just setting it to "open", as the naming of the keymapped action suggests.
        // The name is leftover from when the keyboard shortcuts were shown in a dialog, but now that it's shown in the right pane,
        // toggling makes a lot more UX sense.
        //
        // TODO: come back and clean up this naming.
        return exec({ type: 'TOGGLE_KEYBOARD_SHORTCUTS_PANE' })
      },
      KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN: () => {
        if (!actionsToMap.includes('KEY_MAPPED_RECORD_TOGGLE_NOTE_OPEN')) return
        exec({
          type: 'TOGGLE_ANNOTATIONS',
          showAnnotationsTrigger:
            Analytics.ShowAnnotationsToggleTrigger.KEYBOARD_GT,
          createAnnotationTrigger:
            Analytics.AnnotationCreatedTrigger.KEYBOARD_RIGHT,
        })
      },
      KEY_MAPPED_OPEN_MOVE_TO_DIALOG: () => {
        if (!actionsToMap.includes('KEY_MAPPED_OPEN_MOVE_TO_DIALOG')) return
        exec({
          type: 'OPEN_MOVE_TO_DIALOG',
          trigger: Analytics.MoveToPickerFlowStartedTrigger.KEYBOARD_M,
        })
      },
      KEY_MAPPED_OPEN_SCHEDULE_TO_DIALOG: () => {
        if (!actionsToMap.includes('KEY_MAPPED_OPEN_SCHEDULE_TO_DIALOG')) return
        exec({
          type: 'OPEN_SCHEDULE_DIALOG',
          trigger: Analytics.DatePickerFlowStartedTrigger.KEYBOARD_S,
        })
      },
      KEY_MAPPED_SELECT_ALL_RECORDS: () => {
        if (!actionsToMap.includes('KEY_MAPPED_SELECT_ALL_RECORDS')) return
        exec({
          type: 'SELECT_ALL_RECORDS',
          trigger: Analytics.RecordSelectedTrigger.KEYBOARD_CMDA,
        })
      },
      KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE: () => {
        if (!actionsToMap.includes('KEY_MAPPED_RECORD_TOGGLE_MARKED_DONE'))
          return
        exec({
          type: 'RECORDS_TOGGLE_IS_COMPLETED',
          trigger: Analytics.ToggleRecordsCompletedTrigger.KEYBOARD_C,
        })
      },
      KEY_MAPPED_TOGGLE_MARKED_DONE_VISIBLE: () => {
        if (!actionsToMap.includes('KEY_MAPPED_TOGGLE_MARKED_DONE_VISIBLE'))
          return
        exec({
          type: 'TOGGLE_SHOW_COMPLETED',
          trigger: Analytics.ShowCompletedToggleTrigger.KEYBOARD_T,
        })
      },
      KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE: (e: KeyboardEvent) => {
        if (!actionsToMap.includes('KEY_MAPPED_TOGGLE_SIDEBAR_VISIBLE')) return
        e.preventDefault()
        exec({
          type: 'TOGGLE_SIDEBAR',
          trigger:
            Analytics.ShowSidebarToggleTrigger.KEYBOARD_COMMAND_BACKSLASH,
        })
      },
      KEY_MAPPED_RECORD_COPY: () => {
        if (!actionsToMap.includes('KEY_MAPPED_RECORD_COPY')) return
        exec({ type: 'COPY' })
      },
      KEY_MAPPED_RECORD_DELETE: () => {
        if (!actionsToMap.includes('KEY_MAPPED_RECORD_DELETE')) return
        exec({
          type: 'DELETE_RECORDS',
          trigger: Analytics.RecordsDeletedTrigger.KEYBOARD_BACKSPACE,
          triggeredByKeyboard: true,
          triggeredByUI: false,
        })
      },
      KEY_MAPPED_OPEN_COMMAND_BAR_DIALOG: () => {
        if (!actionsToMap.includes('KEY_MAPPED_OPEN_COMMAND_BAR_DIALOG')) return
        exec({
          type: 'OPEN_COMMAND_BAR',
          trigger: Analytics.CommandBarFlowStartedTrigger.KEYBOARD_CMDK,
        })
      },
      KEY_MAPPED_CREATE_RECORD_INSERT_BELOW: () => {
        if (!actionsToMap.includes('KEY_MAPPED_CREATE_RECORD_INSERT_BELOW'))
          return
        exec({
          type: 'CREATE_RECORD_INSERT_BELOW',
          trigger: Analytics.RecordCreatedTrigger.KEYBOARD_COMMAND_ENTER,
        })
      },
      KEY_MAPPED_CREATE_RECORD_AT_TOP: () => {
        exec({
          type: 'CREATE_RECORD_AT_FIRST_POSITION',
          trigger: Analytics.RecordCreatedTrigger.KEYBOARD_N,
        })
      },
    }
  }, [actionsToMap, exec])

  useMappedKeys(actionIdToHandlerMap)
}

export const useMappedKeys = (keyToHandler: KeyMappedActionIdToHandler) => {
  useMappedKey(Key.Delete, keyToHandler)
  useMappedKey(Key.Backspace, keyToHandler)
  useMappedKey(Key.C, keyToHandler)
  useMappedKey(Key.D, keyToHandler)
  useMappedKey(Key.CommandDown, keyToHandler)
  useMappedKey(Key.CommandShiftDown, keyToHandler)
  useMappedKey(Key.CommandEnter, keyToHandler)
  useMappedKey(Key.CommandSlash, keyToHandler)
  useMappedKey(Key.CommandUp, keyToHandler)
  useMappedKey(Key.CommandShiftUp, keyToHandler)
  useMappedKey(Key.Down, keyToHandler)
  useMappedKey(Key.E, keyToHandler)
  useMappedKey(Key.J, keyToHandler)
  useMappedKey(Key.ShiftDown, keyToHandler)
  useMappedKey(Key.ShiftJ, keyToHandler)
  useMappedKey(Key.ShiftTab, keyToHandler)
  useMappedKey(Key.ShiftUp, keyToHandler)
  useMappedKey(Key.ShiftK, keyToHandler)
  useMappedKey(Key.Tab, keyToHandler)
  useMappedKey(Key.Up, keyToHandler)
  useMappedKey(Key.K, keyToHandler)
  useMappedKey(Key.CommandA, keyToHandler)
  useMappedKey(Key.CommandC, keyToHandler)
  useMappedKey(Key.CommandD, keyToHandler)
  useMappedKey(Key.CommandGreaterThan, keyToHandler)
  useMappedKey(Key.Enter, keyToHandler)
  useMappedKey(Key.Esc, keyToHandler)
  useMappedKey(Key.F, keyToHandler)
  useMappedKey(Key.G, keyToHandler)
  useMappedKey(Key.J, keyToHandler)
  useMappedKey(Key.H, keyToHandler)
  useMappedKey(Key.Left, keyToHandler)
  useMappedKey(Key.I, keyToHandler)
  useMappedKey(Key.M, keyToHandler)
  useMappedKey(Key.N, keyToHandler)
  useMappedKey(Key.O, keyToHandler)
  useMappedKey(Key.QMark, keyToHandler)
  useMappedKey(Key.Right, keyToHandler)
  useMappedKey(Key.L, keyToHandler)
  useMappedKey(Key.S, keyToHandler)
  useMappedKey(Key.T, keyToHandler)
  useMappedKey(Key.GreaterThan, keyToHandler)
  useMappedKey(Key.LowerThan, keyToHandler)
  useMappedKey(Key.CommandK, keyToHandler)
  useMappedKey(Key.X, keyToHandler)
  useMappedKey(Key.CommandShiftP, keyToHandler)
  useMappedKey(Key.V, keyToHandler)
  useMappedKey(Key.U, keyToHandler)
  useMappedKey(Key.B, keyToHandler)
  useMappedKey(Key.Semicolon, keyToHandler)
  useMappedKey(Key.CtrlM, keyToHandler)
  useMappedKey(Key.CommandBackslash, keyToHandler)
  useMappedKey(Key.CommandShiftE, keyToHandler)
}

const useMappedKey = (
  key: (typeof Key)[keyof typeof Key],
  keyToHandler: KeyMappedActionIdToHandler,
) => {
  const keyMappedActions = useKeyMappedActions()
  useKeys(
    [key],
    getHandlerForId(getIdForKey(key, keyMappedActions), keyToHandler),
  )
}

/**
 * Returns the action ID associated with the specified key, or null if no matching action is found.
 */

const getIdForKey = (
  key: string,
  keyMappedActions: KeyMappedActions,
): KeyMappedActionIdType | null => {
  // Iterate through each action and its key mappings
  for (const [actionId, { keys }] of Object.entries(keyMappedActions)) {
    // Check if the specified key is included in the array of keys for this action
    if (keys.includes(key)) {
      // Return the action ID if a matching key is found
      return actionId as KeyMappedActionIdType
    }
  }
  // Return null if no matching action is found
  return null
}

const getHandlerForId = (
  id: KeyMappedActionIdType | null,
  keyToHandler: KeyMappedActionIdToHandler,
): KeyHandler => {
  return (id ? keyToHandler[id] : null) ?? (() => {})
}

type KeyHandler = (event: KeyboardEvent) => void

type KeyMappedActionIdToHandler = {
  [K in KeyMappedActionIdType]?: KeyHandler
}
