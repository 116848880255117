import {
  Frack,
  isCompleteableRecord,
  RecordDict,
  RecordType,
  RootRecord,
} from '@eleventhlabs/capture-shared'
import { pick } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { ListCreatedTrigger } from '../../../common/analytics/capture-analytics-actions'
import { useExecContext } from '../../../common/contexts/ExecContext'
import { useNavigateToList } from '../../../common/hooks/useNavigateToList'
import { Category } from '../components/Outline'
import { ListItem } from '../components/OutlineList/OutlineListItem'

export const useOutlineCategories = (root: RootRecord, records: RecordDict) => {
  const [activeCategoryId, setActiveCategoryId] = useState('Collections')
  const navigateToList = useNavigateToList()

  const { exec } = useExecContext()

  const id = `Collections`
  const title = `Collections`

  const items: ListItem[] = useMemo(
    () =>
      Frack.toArray(root.children).map((lId) => {
        const listRecord = records[lId]
        if (listRecord.type !== RecordType.List) {
          throw new Error(`Unexpected record type: ${listRecord.type}`)
        }
        const count = Frack.toArray(listRecord.children).filter((id) => {
          const record = records[id]
          return isCompleteableRecord(record) && !record.isCompleted
        }).length
        return {
          ...pick(listRecord, [`id`, `title`, `emoji`]),
          count,
        }
      }),
    [root.children, records],
  )

  const onHeadingClick = useCallback(() => {
    setActiveCategoryId(id)
    navigateToList(items[0].id)
  }, [navigateToList])

  const categories = useMemo(
    (): Category[] => [
      {
        id,
        title,
        items,
        actions: [
          {
            iconVariant: `glyphPlus`,
            title: `Create collection`,
            onClick: (event) => {
              event.preventDefault()
              exec({
                type: 'CREATE_COLLECTION',
                trigger: ListCreatedTrigger.SIDEBAR_CLICK,
              })
            },
          },
        ],
        onHeadingClick,
      },
    ],
    [items, exec],
  )

  return { activeCategoryId, categories }
}
