import React, { useEffect } from 'react'

import { useClickOutside } from '../../../../common/hooks/useClickOutside'
import { useFocus } from '../../../../common/hooks/useFocus'
import { Key, Priorities, useKeys } from '../../../../common/hooks/useKeys'
import { emptyFn, immediateExecute } from '../../../../common/utils'
import { cx, styled } from '../../../../common/stationary'

export interface ReminderModalProps {
  value: string | undefined
  validInput: boolean
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
  close: () => void
  className?: string
}

export const ReminderModal = ({
  value,
  validInput,
  onKeyDown,
  onChange,
  onSubmit,
  close,
  className,
}: ReminderModalProps) => {
  const { ref } = useClickOutside<HTMLDivElement>({ onClickOutside: close })
  const { htmlRef: textRef, focus: focusText } = useFocus<HTMLInputElement>()

  useEffect(() => {
    immediateExecute(() => {
      focusText()
      if (textRef.current) textRef.current.select()
    })
  }, [textRef, focusText])

  useKeys(Key.Esc, close, Priorities.Highest, true)

  return (
    <S.Container className={className} ref={ref}>
      <S.TitleRow>Set a reminder...</S.TitleRow>
      <S.InputRow>
        <S.InputContainer>
          <S.Input
            placeholder="eg: 1h 15m"
            value={value ?? ``}
            onKeyDown={onKeyDown}
            onChange={onChange}
            ref={textRef}
          />
        </S.InputContainer>
        <S.InputLabel>before</S.InputLabel>
      </S.InputRow>
      <S.ButtonsRow>
        <S.Cancel onClick={close}>Cancel</S.Cancel>
        <S.Done
          className={cx({
            isValid: validInput,
          })}
          onClick={validInput ? onSubmit : emptyFn}
        >
          Done
        </S.Done>
      </S.ButtonsRow>
    </S.Container>
  )
}

const S = {
  Container: styled.div(({ theme }) => ({
    display: `flex`,
    width: 224,
    flexDirection: `column`,
    border: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    borderRadius: 8,
    background: theme.colors.surface.base,
    cursor: `default`,
    userSelect: `none`,
  })),
  TitleRow: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 32px`,
    flexDirection: `row`,
    padding: `12px 0 4px 16px`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    fontWeight: 500,
    ...theme.text.publicSans['11.5:16'],
    lineHeight: `16px`,
    textTransform: `uppercase`,
    letterSpacing: `0.08em`,
    color: theme.colors.text[700],
  })),
  InputRow: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 48px`,
    flexDirection: `row`,
    padding: `0 12px 16px 12px`,
    borderBottom: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
  })),
  InputLabel: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 72px`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    fontWeight: 400,
    ...theme.text.publicSans['16:24'],
    lineHeight: `24px`,
    color: theme.colors.text[900],
    paddingLeft: 8,
  })),
  InputContainer: styled.div({
    display: `flex`,
    flex: 1,
    height: 30,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
  }),
  Input: styled.input(({ theme }) => ({
    display: `flex`,
    flex: 1,
    height: 28,
    width: 144,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    fontWeight: 400,
    ...theme.text.publicSans['16:24'],
    lineHeight: `24px`,
    color: theme.colors.text[900],
    background: theme.colors.surface.lowest,
    borderRadius: 6,
    border: `1px solid ${theme.colors.alpha.border.mediumWeak}`,
    outline: `none`,
    padding: `2px 6px`,
  })),
  ButtonsRow: styled.div({
    display: `flex`,
    flex: `0 0 48px`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `flex-end`,
  }),
  Cancel: styled.div(({ theme }) => ({
    display: `flex`,
    fontWeight: 400,
    ...theme.text.publicSans['16:24'],
    lineHeight: `24px`,
    color: theme.colors.text[500],
    cursor: `pointer`,
    marginRight: 16,
  })),
  Done: styled.div(({ theme }) => ({
    display: `flex`,
    fontWeight: 500,
    ...theme.text.publicSans['16:24'],
    lineHeight: `24px`,
    color: theme.colors.blue[700],
    marginRight: 16,
    opacity: 0.4,

    '&.isValid': {
      opacity: 1,
      cursor: `pointer`,
    },
  })),
}
