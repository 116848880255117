import React, { useCallback, useEffect, useState } from 'react'

import { subscribeToFocusEvent } from '../../../../../common/events'
import { useFocus } from '../../../../../common/hooks/useFocus'
import { styled } from '../../../../../common/stationary'

interface FileTitleProps {
  url?: string
  id: string
  title?: string
  loading?: boolean
  renameFile?: (fileName: string) => void
  onClick?: (e: React.MouseEvent) => void
}

export const FileTitle: React.FC<FileTitleProps> = ({
  id,
  url,
  title,
  renameFile,
  onClick,
}) => {
  const [editing, setEditing] = useState(false)
  const [text, setText] = useState(title ?? ``)

  const { htmlRef: textRef, focus: focusText } = useFocus<HTMLInputElement>()

  const enableEditing = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setEditing(true)
  }, [])
  const disableEditing = useCallback(() => setEditing(false), [])

  const onBlur = useCallback(() => {
    disableEditing()
    renameFile && renameFile(text)
  }, [text, renameFile, disableEditing])

  const stopPropagationClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
    },
    [],
  )

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const value = event.target.value
    setText(value)
  }, [])

  const onKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case `Enter`: {
          renameFile && renameFile(text)
          disableEditing()
          return
        }
        case `Escape`: {
          setText(title ?? ``)
          disableEditing()
          return
        }
        default:
      }
    },
    [text, title, renameFile, disableEditing],
  )

  useEffect(() => {
    if (editing) {
      focusText()
    }
  }, [id, editing, focusText])

  useEffect(() => {
    setText(title ?? ``)
  }, [title])

  const inputId = `file-name-${id}`
  useEffect(() => {
    return subscribeToFocusEvent(({ payload }) => {
      if (payload.id === inputId && !editing) {
        setEditing(true)
        return true
      }
      return false
    })
  }, [inputId, editing, focusText])

  return (
    <S.FileTitle>
      {editing ? (
        <S.TextEditable
          className="text"
          data-placeholder={text}
          value={text}
          onClick={stopPropagationClick}
          onMouseDown={stopPropagationClick}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          ref={textRef}
        />
      ) : title ? (
        <S.Title>
          <S.TitleText href={url} onClick={onClick}>
            {title}
          </S.TitleText>
          <S.EditIcon
            className="edit"
            onClick={enableEditing}
            onMouseDown={stopPropagationClick}
          >
            Rename
          </S.EditIcon>
        </S.Title>
      ) : (
        <S.Empty />
      )}
      <S.Filler />
    </S.FileTitle>
  )
}

const S = {
  FileTitle: styled.div({
    display: `flex`,
    width: `100%`,

    '&:hover .edit': {
      display: `flex`,
    },
  }),
  TextEditable: styled.input(({ theme }) => ({
    width: `100%`,
    height: `100%`,
    outline: `none`,
    lineHeight: `24px`,
    background: theme.colors.alpha.tone.weak,
    border: `1px solid ${theme.colors.alpha.border.weak}`,
    borderRadius: 6,
    boxSizing: `border-box`,
    padding: `0 3px`,

    '&[contenteditable=true]:empty:before': {
      content: `attr(data-placeholder)`,
      display: `block`,
      color: theme.colors.text[900],
    },
  })),
  EditIcon: styled.div(({ theme }) => ({
    display: `none`,
    alignItems: `center`,
    justifyContent: `center`,
    width: 60,
    height: `100%`,
    marginLeft: 8,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 500,
    color: theme.colors.text[500],
    cursor: `pointer`,

    '&:hover': {
      color: theme.colors.text[700],
    },
  })),
  Title: styled.div({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    overflow: `hidden`,
    whiteSpace: `nowrap`,
    textOverflow: `ellipsis`,
  }),
  TitleText: styled.a(({ theme }) => ({
    display: `block`,
    fontWeight: `bold`,
    ...theme.text.publicSans['33:40'],
    color: theme.colors.text[900],
    overflow: `hidden`,
    whiteSpace: `nowrap`,
    textOverflow: `ellipsis`,
    textDecoration: `underline`,
    textDecorationColor: theme.colors.alpha.border.mediumStrong,
    textDecorationThickness: `2px`,
  })),
  Empty: styled.div(({ theme }) => ({
    display: `flex`,
    flex: `0 0 160px`,
    height: 16,
    background: theme.colors.surface.lowest,
    borderRadius: 6,
  })),
  Filler: styled.div({
    display: `flex`,
    flex: 1,
  }),
}
