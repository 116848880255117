import { every } from 'lodash'
import { useCallback } from 'react'
import * as z from 'zod'

import { useLocalStorageDict } from '../../hooks/useLocalStorageDict'
import { emptyFn } from '../../utils'

export interface NotesVisibilityData {
  shouldDisplayNotesForRecordId: (id: string) => boolean
  setNotesVisibilityForRecordIds: (ids: string[], visibility: boolean) => void
  toggleNotesVisibilityForRecordIds: (ids: string[]) => boolean
}

export const defaultNotesVisibility = {
  shouldDisplayNotesForRecordId: () => false,
  setNotesVisibilityForRecordIds: emptyFn,
  toggleNotesVisibilityForRecordIds: () => false,
}

export type RecordItemIsNoteVisibleDict = { [id: string]: boolean }
export type SetRecordItemIsNoteVisibleDict = React.Dispatch<
  React.SetStateAction<RecordItemIsNoteVisibleDict>
>

const defaultIsNoteVisible = true

export const useNotesVisibility = () => {
  const [recordItemIsNoteVisible, setRecordItemIsNoteVisible] =
    useLocalStorageDict('noteVisibility', z.boolean(), {})

  const shouldDisplayNotesForRecordId = useCallback(
    (id: string) => {
      const isNoteVisible = recordItemIsNoteVisible[id]
      if (isNoteVisible === undefined) return defaultIsNoteVisible
      else return isNoteVisible
    },
    [recordItemIsNoteVisible],
  )

  const setNotesVisibilityForRecordIds = useCallback(
    (ids: string[], visibility: boolean) => {
      setRecordItemIsNoteVisible((prev) => {
        const updateDict: { [id: string]: boolean } = {}
        for (const id of ids) {
          if (prev[id] !== visibility) {
            updateDict[id] = visibility
          }
        }
        return {
          ...prev,
          ...updateDict,
        }
      })
    },
    [setRecordItemIsNoteVisible],
  )

  const toggleNotesVisibilityForRecordIds = useCallback(
    (ids: string[]) => {
      const toggleToValue = !every(ids, (id) =>
        shouldDisplayNotesForRecordId(id),
      )
      setNotesVisibilityForRecordIds(ids, toggleToValue)
      return toggleToValue
    },
    [shouldDisplayNotesForRecordId, setNotesVisibilityForRecordIds],
  )

  return {
    shouldDisplayNotesForRecordId,
    setNotesVisibilityForRecordIds,
    toggleNotesVisibilityForRecordIds,
  }
}
