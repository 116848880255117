import { CSSProperties } from 'react'

import { WidgetType } from '../widgets'

import { AddToContacts } from './AddToContacts'
import { DemoMessages } from './DemoMessages'
import { DemoQuickCapture } from './DemoQuickCapture'
import { InstallBrowserApp } from './InstallBrowserApp'
import { InstallChromeExtension } from './InstallChromeExtension'
import { InstallDesktopApp } from './InstallDesktopApp'
import { InstallMobileApp } from './InstallMobileApp'
import { IntroInbox } from './IntroInbox'
import { IntroReminders } from './IntroReminders'
import { IntroTextYourself } from './IntroTextYourself'
import { PinToMessages } from './PinToMessages'
import { VerifyPhone } from './VerifyPhone'
import { WidgetPropsType } from '../../OnboardingScreen'
import { DesktopKeyboardShortcut } from './DesktopKeyboardShortcut'
import { DesktopMenuBarShortcut } from './DesktopMenuBarShortcut'
import { DesktopKeepInDock } from './DesktopKeepInDock'
import { getShouldSkipTrackItem, getSlideType, track } from '../tracks'

export interface SlideProps {
  goBackward: (skipToIndex?: number) => void
  goForward: (skipToIndex?: number) => void
  submitRecord: (record: string) => void
  updateWidgetStyle: (widgetType: WidgetType, style: CSSProperties) => void
  resetWidgetStyle: (widgetType: WidgetType) => void
  updateWidgetProps: (widgetType: WidgetType, props: WidgetPropsType) => void
  resetWidgetProps: (widgetType: WidgetType) => void
}

export type SlideType = keyof typeof Slides
export type ShouldSkipIfCompletedState = 'AnyTrack' | 'CurrentTrack' | 'Never'

interface Slide {
  component: React.ComponentType<SlideProps>
  analytics: {
    onCompleteAction: string
    onLoadAction: string
  }
  shouldSkipIfCompleted: ShouldSkipIfCompletedState
  forceSkip?: boolean
}

/*
 * Slides
 */
export const Slides: Record<string, Slide> = {
  AddToContacts: {
    component: AddToContacts,
    analytics: {
      onCompleteAction: 'Add To Contacts Completed',
      onLoadAction: 'Add To Contacts Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  DemoMessages: {
    component: DemoMessages,
    analytics: {
      onCompleteAction: 'Demo Messages Completed',
      onLoadAction: 'Demo Messages Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  DemoQuickCapture: {
    component: DemoQuickCapture,
    analytics: {
      onCompleteAction: 'Demo Quick Capture Completed',
      onLoadAction: 'Demo Quick Capture Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  InstallBrowserApp: {
    component: InstallBrowserApp,
    analytics: {
      onCompleteAction: 'Install Browser App Completed',
      onLoadAction: 'Install Browser App Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  InstallChromeExtension: {
    component: InstallChromeExtension,
    analytics: {
      onCompleteAction: 'Install Chrome Extension Completed',
      onLoadAction: 'Install Chrome Extension Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  InstallDesktopApp: {
    component: InstallDesktopApp,
    analytics: {
      onCompleteAction: 'Install Desktop App Completed',
      onLoadAction: 'Install Desktop App Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  InstallMobileApp: {
    component: InstallMobileApp,
    analytics: {
      onCompleteAction: 'Install Mobile App Completed',
      onLoadAction: 'Install Mobile App Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  IntroInbox: {
    component: IntroInbox,
    analytics: {
      onCompleteAction: 'Intro Inbox Completed',
      onLoadAction: 'Intro Inbox Loaded',
    },
    shouldSkipIfCompleted: 'CurrentTrack',
  },
  IntroReminders: {
    component: IntroReminders,
    analytics: {
      onCompleteAction: 'Intro Reminders Completed',
      onLoadAction: 'Intro Reminders Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  IntroTextYourself: {
    component: IntroTextYourself,
    analytics: {
      onCompleteAction: 'Intro Text Yourself Completed',
      onLoadAction: 'Intro Text Yourself Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  PinToMessages: {
    component: PinToMessages,
    analytics: {
      onCompleteAction: 'Pin To Messages Completed',
      onLoadAction: 'Pin To Messages Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  VerifyPhone: {
    component: VerifyPhone,
    analytics: {
      onCompleteAction: 'Verify Phone Completed',
      onLoadAction: 'Verify Phone Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  DesktopKeyboardShortcut: {
    component: DesktopKeyboardShortcut,
    analytics: {
      onCompleteAction: 'Desktop Keyboard Shortcut Completed',
      onLoadAction: 'Desktop Keyboard Shortcut Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  DesktopMenuBarShortcut: {
    component: DesktopMenuBarShortcut,
    analytics: {
      onCompleteAction: 'Desktop Menu Bar Shortcut Completed',
      onLoadAction: 'Desktop Menu Bar Shortcut Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
  DesktopKeepInDock: {
    component: DesktopKeepInDock,
    analytics: {
      onCompleteAction: 'Desktop Keep In Dock Completed',
      onLoadAction: 'Desktop Keep In Dock Loaded',
    },
    shouldSkipIfCompleted: 'AnyTrack',
  },
}

export const getShouldSkipIfCompletedState = (
  slideName: SlideType,
): ShouldSkipIfCompletedState => {
  return Slides[slideName].shouldSkipIfCompleted
}

export const getShouldSkipSlide = (slideName: SlideType): boolean => {
  const trackItem = track.find(
    (trackItem) => getSlideType(trackItem) === slideName,
  )
  if (!trackItem) return false

  return getShouldSkipTrackItem(trackItem)
}

export const getSlideByName = (slideName: SlideType): Slide => {
  return Slides[slideName]
}
