import { ModalUnstyled, ModalUnstyledProps } from '@mui/base'
import React from 'react'
import { BiX } from 'react-icons/bi'

import diagramBrowserAppSrc from '../../../assets/onboarding/diagram-browser-app-example@2x.png'
import { styled } from '../../../common/stationary'
import {
  ContentControlHint,
  ContentControls,
  ContentDescription,
  ContentDiagramDialog,
  ContentDiagramDialogInnerWidth,
  ContentFiller,
  ContentLi,
  ContentPrimaryButton,
  ContentTitle,
  ContentUl,
  DialogContent,
  DialogDiagram,
  DialogHeader,
  DialogInner,
  HeaderCloseButton,
} from '../../../components/ContentDiagramDialog'

export interface RegistrationWelcomeModalProps
  extends Omit<ModalUnstyledProps, 'children'> {
  onClose?: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick',
  ) => void
}

export function RegistrationWelcomeModal({
  onClose,
  open,
  slots,
  ...props
}: RegistrationWelcomeModalProps) {
  return (
    <S.RegistrationWelcomeModal
      onClose={onClose}
      open={open}
      slots={{ backdrop: S.Backdrop, ...slots }}
      {...props}
    >
      <ContentDiagramDialog>
        <DialogHeader>
          <HeaderCloseButton
            onClick={(e) => onClose && onClose(e, 'buttonClick')}
          >
            <BiX />
          </HeaderCloseButton>
        </DialogHeader>
        <DialogInner>
          <DialogContent>
            <ContentTitle>Welcome to Capture!</ContentTitle>
            <ContentDescription>
              Never lose a thought — quickly capture notes, to-dos, web pages,
              and more:
            </ContentDescription>
            <ContentUl>
              <ContentLi>
                <strong>A private inbox</strong>: one place to review everything
                you’ve captured
              </ContentLi>
              <ContentLi>
                <strong>Make a to-do out of anything</strong>: get reminders on
                your phone
              </ContentLi>
              <ContentLi>
                <strong>Capture from anywhere</strong>: SMS, desktop, WhatsApp,
                and more
              </ContentLi>
            </ContentUl>
            <ContentFiller />
            <ContentControls>
              <ContentPrimaryButton
                onClick={(e) => onClose && onClose(e, 'buttonClick')}
              >
                <strong>Continue</strong>
              </ContentPrimaryButton>
              <ContentControlHint>to create your account</ContentControlHint>
            </ContentControls>
          </DialogContent>
        </DialogInner>
        <DialogInner>
          <DialogDiagram src={diagramBrowserAppSrc} />
        </DialogInner>
      </ContentDiagramDialog>
    </S.RegistrationWelcomeModal>
  )
}

const S = {
  RegistrationWelcomeModal: styled(ModalUnstyled)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    z-index: 999;

    @media screen and (max-width: ${ContentDiagramDialogInnerWidth * 2}px) {
      align-items: flex-start;
      overflow-y: scroll;
    }
  `,
  Backdrop: styled.div`
    position: fixed;
    inset: 0;
    z-index: -1;
    background: ${({ theme }) => theme.colors.alpha.shade.extraStrong};
  `,
}
