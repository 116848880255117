import { memoize } from 'lodash'

type MouseEventAction = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
) => void

// preventDefault before action

const onMouseDownAction = memoize(
  (action?: MouseEventAction): MouseEventAction =>
    (event) => {
      event.preventDefault()
      action && action(event)
    },
)

export default onMouseDownAction
