import { ExecAction } from '../useExec'
import { ToggleRecordsCompletedTrigger } from '../analytics/capture-analytics-actions'
import { ClickDivEvent } from './types'

export const onToggle = (
  event: ClickDivEvent,
  exec: ExecAction,
  recordId: string,
): boolean => {
  return exec({
    type: 'RECORDS_TOGGLE_IS_COMPLETED',
    targetId: recordId,
    trigger: ToggleRecordsCompletedTrigger.RECORDITEM_TOGGLE,
  })
}
