import { ExecAction } from '../../useExec'

export const onBackspaceAtFirstPointWhenNotEmpty = (
  exec: ExecAction,
  recordId: string,
): boolean => {
  exec({
    type: 'RECORD_BACKSPACE_AT_FIRST_POINT_WHEN_NOT_EMPTY',
    recordId,
  })
  return true
}
