export enum DialogType {
  DatePicker = `DatePicker`,
  DestinationPicker = `DestinationPicker`,
  ToggleCompleted = `ToggleCompleted`,
  EmojiPicker = `EmojiPicker`,
  Schedule = `Schedule`,
  MoveTo = `MoveTo`,
  QuickSwitcher = `QuickSwitcher`,
  KeyboardShortcuts = `KeyboardShortcuts`,
  Calendar = `Calendar`,
  CommandBar = `CommandBar`,
  ResetPassword = `ResetPassword`,
}
