import { useLocation } from '@reach/router'
import jwt_decode from 'jwt-decode'
import { err, ok } from 'neverthrow'
import { useEffect, useState } from 'react'
import * as z from 'zod'
import * as Analytics from '../../common/analytics/capture-analytics-actions'
import { apiCall, isSuccessStatusCode } from '../../common/api/client'
import { Env } from '../../common/env'
import { safeNavigate } from '../../common/navigate'
import { isMobileIOSWeb } from '../../common/utils/env'
import { useAuth } from '../../providers'

type UseRegistrationScreen = {
  email: string | null
  inviteCode: string | null
  isRegistrationTokenValid: boolean
  isRegistrationTokenValidRequestErr: string | null
  isRegistrationTokenValidRequestState: RequestState
  registrationToken: string | null
  resetSubmitErrors: () => void
  submitRegistration: (args: SubmitRegistrationArgs) => Promise<void>
  submitRegistrationErr: string | null
  submitRegistrationRequestState: RequestState
}

interface SubmitRegistrationArgs {
  email: string
  firstName: string
  password?: string
}

export function useRegistrationScreen(): UseRegistrationScreen {
  const { decodedRegistrationToken, registrationToken, inviteCode } =
    useRegistrationToken()

  const email =
    decodeToken !== null ? decodedRegistrationToken?.email ?? null : null

  useEffect(() => {
    Analytics.registrationLoaded({ emailAddressInToken: email ?? undefined })
    if (inviteCode) {
      Analytics.invitationLoaded({ inviteCode: inviteCode.toUpperCase() })
    }
  }, [email, inviteCode])

  const {
    isRegistrationTokenValid,
    isRegistrationTokenValidRequestErr,
    isRegistrationTokenValidRequestState,
  } = useIsRegistrationTokenValid(registrationToken, inviteCode)

  const {
    submitRegistration,
    submitRegistrationErr,
    submitRegistrationRequestState,
    resetSubmitErrors,
  } = useSubmitRegistration()

  return {
    email,
    inviteCode,
    isRegistrationTokenValid,
    isRegistrationTokenValidRequestErr,
    isRegistrationTokenValidRequestState,
    registrationToken,
    resetSubmitErrors,
    submitRegistration,
    submitRegistrationErr,
    submitRegistrationRequestState,
  }
}

interface TokenPayload {
  email: string
  magic_link_token: string
}

const decodeToken = (token: string) => {
  try {
    return jwt_decode<TokenPayload>(token)
  } catch (e) {
    console.error((e as Error)?.message ?? 'Invalid token specified')
    return null
  }
}

function useRegistrationToken() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const registrationToken = params.get('registration_token')
  const decodedRegistrationToken = registrationToken
    ? decodeToken(registrationToken || '')
    : null

  const isRegistrationTokenOk =
    decodedRegistrationToken !== null &&
    decodedRegistrationToken.email !== undefined

  const inviteCode = params.get('code')

  return {
    registrationToken,
    decodedRegistrationToken,
    isRegistrationTokenOk,
    inviteCode,
  }
}

export const enum RequestState {
  NONE = 'NONE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

function useIsRegistrationTokenValid(
  registrationToken: string | null,
  inviteCode: string | null,
) {
  const [
    isRegistrationTokenValidRequestState,
    setIsRegistrationTokenValidRequestState,
  ] = useState(RequestState.NONE)
  const [isRegistrationTokenValid, setIsRegistrationTokenValid] =
    useState(false)
  const [
    isRegistrationTokenValidRequestErr,
    setIsRegistrationTokenValidRequestErr,
  ] = useState<string | null>(null)

  const checkRegistrationTokenValidity = async () => {
    setIsRegistrationTokenValidRequestState(RequestState.LOADING)
    _isRegistrationTokenValid(registrationToken ?? '')
      .then(() => {
        setIsRegistrationTokenValidRequestState(RequestState.SUCCESS)
        setIsRegistrationTokenValid(true)
        setIsRegistrationTokenValidRequestErr(null)
        Analytics.validateRegistrationTokenSucceeded()
      })
      .catch((error) => {
        setIsRegistrationTokenValidRequestState(RequestState.ERROR)
        setIsRegistrationTokenValid(false)
        setIsRegistrationTokenValidRequestErr(
          IS_VALID_ERROR_CODE_TO_MESSAGES[error.error_code] ??
            IS_VALID_ERROR_CODE_TO_MESSAGES.default,
        )
        Analytics.validateRegistrationTokenFailed({
          errorCode: error.error_code,
        })
      })
  }

  useEffect(() => {
    if (registrationToken) checkRegistrationTokenValidity()
  }, [registrationToken])

  useEffect(() => {
    if (inviteCode)
      setIsRegistrationTokenValidRequestState(RequestState.SUCCESS)
  }, [inviteCode])

  return {
    isRegistrationTokenValidRequestErr,
    isRegistrationTokenValidRequestState,
    isRegistrationTokenValid,
  }
}

const IS_VALID_ERROR_CODES = {
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_REGISTRATION_TOKEN: 'INVALID_REGISTRATION_TOKEN',
  INVALID_REQUEST: 'INVALID_REQUEST',
  REGISTRATION_TOKEN_VALID: 'REGISTRATION_TOKEN_VALID',
}

const IS_VALID_ERROR_CODE_TO_MESSAGES = {
  [IS_VALID_ERROR_CODES.INTERNAL_SERVER_ERROR]: 'An unknown error occurred.',
  [IS_VALID_ERROR_CODES.INVALID_REGISTRATION_TOKEN]: '',
  [IS_VALID_ERROR_CODES.INVALID_REQUEST]: '',
}

const _isRegistrationTokenValid = (registrationToken: string) => {
  return new Promise(async (resolve, reject) => {
    const response = await apiCall(
      `${Env.captureApiUrl}/signupFunnel/isRegistrationTokenValid`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: registrationToken }),
      },
    )
    const data = await response.json()
    if ('error_code' in data) {
      reject(data)
    } else {
      resolve(data)
    }
  })
}

function useSubmitRegistration() {
  const { setSessionFromTokens } = useAuth()

  const [requestState, setRequestState] = useState(RequestState.NONE)
  const [errMsg, setErrMsg] = useState<string | null>(null)

  const submitRegistration = async (args: SubmitRegistrationArgs) => {
    setRequestState(RequestState.LOADING)
    const params = getQueryParams()

    // Use invite_code method if registration_token is missing
    const reqBody = params['registration_token']
      ? {
          registration_token: params['registration_token'] ?? '',
          token: params['token'] ?? '',
          stytch_token_type: params['stytch_token_type'] ?? '',
        }
      : {
          invite_code: params['code'] ?? '',
        }

    // Submit registration
    const submitRegistrationResponse = await submitRegistrationRequest({
      reg: args,
      ...reqBody,
      isIOSMobileWeb: isMobileIOSWeb,
    })
    if (submitRegistrationResponse.isErr()) {
      handleSubmitRegistrationError(submitRegistrationResponse.error, args)
      return
    }

    const response = submitRegistrationResponse.value

    // Set session if one was created
    if (response.wasSessionCreated) {
      await setSessionFromTokens(response.sessionTokens)
    }

    // Navigate to inbox if email was verified.
    if (response.isEmailVerified) {
      safeNavigate('/inbox')
      return
    } else {
      setRequestState(RequestState.SUCCESS)
      setErrMsg(null)
      return
    }
  }

  const handleSubmitRegistrationError = (
    error: { error_code: string } | Error | string,
    args: SubmitRegistrationArgs,
  ) => {
    setRequestState(RequestState.ERROR)
    const msg =
      typeof error === 'object' && 'error_code' in error
        ? SUBMIT_ERROR_CODE_TO_MESSAGE[error.error_code]
        : SUBMIT_ERROR_CODE_TO_MESSAGE.default
    setErrMsg(msg)
    Analytics.submitRegistrationFailed({
      errorMessage: undefined,
      errorName: undefined,
      submitRegistrationError: undefined,
      submitRegistrationErrorCode: undefined,
      ...toSubmitRegistrationFailedProps(error, args),
    })
  }

  function toSubmitRegistrationFailedProps(
    error: { error_code: string } | Error | string,
    args: SubmitRegistrationArgs,
  ) {
    const errorDetails =
      typeof error === 'object' && 'error_code' in error
        ? {
            submitRegistrationErrorCode: error.error_code,
          }
        : typeof error === 'object' && !('error_code' in error)
        ? { errorName: error.name, errorMessage: error.message }
        : { submitRegistrationError: error.toString() }
    return {
      submitRegistrationEmail: args.email,
      submitRegistrationFirstName: args.firstName,
      ...errorDetails,
    }
  }

  const resetSubmitErrors = () => {
    setErrMsg(null)
    setRequestState(RequestState.NONE)
  }

  return {
    submitRegistration,
    submitRegistrationErr: errMsg,
    submitRegistrationRequestState: requestState,
    resetSubmitErrors,
  }
}

function getQueryParams() {
  if (Env.isSSR || typeof window === 'undefined') return {}
  const params = new URL(window.location.href).searchParams
  const queryParams: { [key: string]: string } = {}
  for (const [key, value] of params.entries()) {
    queryParams[key] = value
  }
  return queryParams
}

const SUBMIT_ERROR_CODES = {
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_REQUEST: 'INVALID_REQUEST',
  MEMBER_EXISTS_WITH_EMAIL: 'MEMBER_EXISTS_WITH_EMAIL',
  MEMBER_EXISTS_WITH_PHONE: 'MEMBER_EXISTS_WITH_PHONE',
  PHONE_NUMBER_FAILED_E164_PARSE: 'PHONE_NUMBER_FAILED_E164_PARSE',
  STYTCH_ERROR: 'STYTCH_ERROR',
  STYTCH_USER_EXISTS_WITH_EMAIL: 'STYTCH_USER_EXISTS_WITH_EMAIL',
}

const SUBMIT_ERROR_CODE_TO_MESSAGE = {
  [SUBMIT_ERROR_CODES.STYTCH_USER_EXISTS_WITH_EMAIL]:
    'This email has already been registered.',
  [SUBMIT_ERROR_CODES.INTERNAL_SERVER_ERROR]: 'An unknown error occurred.',
  [SUBMIT_ERROR_CODES.STYTCH_ERROR]: 'An unknown error occurred.',
  [SUBMIT_ERROR_CODES.MEMBER_EXISTS_WITH_EMAIL]:
    'An account already exists for this email.',
  [SUBMIT_ERROR_CODES.MEMBER_EXISTS_WITH_PHONE]:
    'An account already exists for this phone number.',
  [SUBMIT_ERROR_CODES.PHONE_NUMBER_FAILED_E164_PARSE]:
    'Invalid phone number entered.',
  default: 'An unknown error occurred.',
  magicLinkError: 'An unknown error occurred.',
}

type SubmitRegistrationRequest = {
  reg: SubmitRegistrationArgs
  registration_token?: string
  token?: string
  stytch_token_type?: string
  invite_code?: string
  isIOSMobileWeb: boolean
}

const submitRegistrationRequest = async (body: SubmitRegistrationRequest) => {
  try {
    const response = await apiCall(
      `${Env.captureApiUrl}/signupFunnel/submitRegistration`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      },
    )
    const data = await response.json()
    return isSuccessStatusCode(response.status)
      ? ok(submitRegistrationResponseSchema.parse(data))
      : err(submitRegistrationErrorSchema.parse(data))
  } catch (e) {
    return e instanceof Error ? err(e) : err(`Unrecognized error: ${String(e)}`)
  }
}

const withTokens = z.object({
  isEmailVerified: z.boolean(),
  wasSessionCreated: z.literal(true),
  sessionTokens: z.object({
    session_token: z.string(),
    session_jwt: z.string(),
  }),
})

const withoutTokens = z.object({
  isEmailVerified: z.boolean(),
  wasSessionCreated: z.literal(false),
})

const submitRegistrationResponseSchema = z.union([withTokens, withoutTokens])

const submitRegistrationErrorSchema = z.object({
  error_code: z.string(),
})
