import { ModelStoreCommands } from '../../../../data/modelStore'
import { triggerAction } from '../../../../events'
import { UpdateTicklerPayload } from '@eleventhlabs/capture-shared'

export const updateRecordTickler = (
  targetId: string | undefined,
  activeRecordIds: string[],
  payload: UpdateTicklerPayload,
  _updateRecordTickler: ModelStoreCommands['updateRecordDate'],
) => {
  const willUpdateEntireSelection =
    targetId === undefined || activeRecordIds.includes(targetId)
  const recordIds =
    !willUpdateEntireSelection && targetId ? [targetId] : activeRecordIds
  if (willUpdateEntireSelection) {
    triggerAction({ target: `update-default`, invalidIds: recordIds })
  }
  for (const id of recordIds) {
    _updateRecordTickler(id, payload)
  }
  return { recordIds }
}
