import {
  layoutStyles,
  LayoutUnstyledProps,
  LayoutUnstyled,
} from '@eleventhlabs/stationary'
import React from 'react'

import { styled } from '../styled'
import { isMobile } from '../../utils/env'

const LayoutRoot = styled.div()
const FixedColumns = styled.div(layoutStyles.fixedColumns)
const StaticColumns = styled.div({
  ...layoutStyles.staticColumns,
  overflow: !isMobile ? 'initial !important' : undefined, // Necessary to enable sticky elements
  overflowX: 'hidden',
})

const LeftColumn = styled.div(layoutStyles.leftColumn)
const RightColumn = styled.div(layoutStyles.rightColumn)
const CenterColumn = styled.div({
  ...layoutStyles.centerColumn,
  minWidth: 0,
})

const LeftColumnPlaceholder = styled.div({
  ...layoutStyles.leftColumnPlaceholder,
  flexShrink: 0,
})
const RightColumnPlaceholder = styled.div({
  ...layoutStyles.rightColumnPlaceholder,
  flexShrink: 0,
})
const CenterColumnPlaceholder = styled.div(layoutStyles.centerColumnPlaceholder)

export type LayoutProps = LayoutUnstyledProps

export const Layout: React.FC<LayoutProps> = ({ slots, ...props }) => (
  <LayoutUnstyled
    slots={{
      root: LayoutRoot,
      fixedColumns: FixedColumns,
      staticColumns: StaticColumns,
      leftColumn: LeftColumn,
      rightColumn: RightColumn,
      centerColumn: CenterColumn,
      leftColumnPlaceholder: LeftColumnPlaceholder,
      rightColumnPlaceholder: RightColumnPlaceholder,
      centerColumnPlaceholder: CenterColumnPlaceholder,
      ...slots,
    }}
    {...props}
  />
)
