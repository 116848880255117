import React, { LazyExoticComponent } from 'react'
import { ConfirmDeleteListModalProps } from '../../../components/SideBar/components/ConfirmDeleteListModal'
import { ResetPasswordModalProps } from '../../../screens/ResetPasswordScreen/components/ResetPasswordModal'

// 1. ADD NEW MODAL NAME HERE
export enum ModalName {
  CONFIRM_DELETE_LIST_MODAL = 'CONFIRM_DELETE_LIST_MODAL',
  CONFIRM_DELETE_RECORDS_MODAL = 'CONFIRM_DELETE_RECORDS_MODAL',
  CONFIRM_EMPTY_TRASH_MODAL = 'CONFIRM_EMPTY_TRASH_MODAL',
  RESET_PASSWORD_MODAL = 'RESET_PASSWORD_MODAL',
  DOWNLOADS_MODAL = 'DOWNLOADS_MODAL',
  // ...
}

// 2. EXPORT MODEL COMPONENT PROPS AND ADD IT HERE
export type ModalComponentProps = {
  [ModalName.CONFIRM_DELETE_LIST_MODAL]: ConfirmDeleteListModalProps
  [ModalName.RESET_PASSWORD_MODAL]: ResetPasswordModalProps
  [ModalName.CONFIRM_DELETE_RECORDS_MODAL]: void
  [ModalName.CONFIRM_EMPTY_TRASH_MODAL]: void
  [ModalName.DOWNLOADS_MODAL]: void
  // ...
}

// 3. IMPORT THE MODEL COMPONENT HERE
export const modalsConfig: { [K in ModalName]: LazyExoticComponent<any> } = {
  [ModalName.CONFIRM_DELETE_LIST_MODAL]: React.lazy(
    () =>
      import('../../../components/SideBar/components/ConfirmDeleteListModal'),
  ),
  [ModalName.CONFIRM_DELETE_RECORDS_MODAL]: React.lazy(
    () =>
      import(
        '../../../screens/TrashScreen/components/DeleteRecordsConfirmation'
      ),
  ),
  [ModalName.CONFIRM_EMPTY_TRASH_MODAL]: React.lazy(
    () =>
      import('../../../screens/TrashScreen/components/EmptyTrashConfirmation'),
  ),
  [ModalName.RESET_PASSWORD_MODAL]: React.lazy(
    () =>
      import(
        '../../../screens/ResetPasswordScreen/components/ResetPasswordModal'
      ),
  ),
  [ModalName.DOWNLOADS_MODAL]: React.lazy(
    () => import('../../../components/DownloadsModal'),
  ),
  // ...
}
