import { useEffect, useMemo } from 'react'

import { useLocalStorage } from '../contexts/LocalStorageContext'
import { useThemeName } from '../contexts/ThemeNameContext'
import { ThemeName, themesConfig } from '../stationary'

export const systemThemeName = 'SYSTEM'

export const useSavedThemeName = () => {
  const [savedThemeName, setSavedThemeName] = useLocalStorage('savedThemeName')
  const { setThemeName } = useThemeName()

  useEffect(() => {
    if (!savedThemeName || !isValidThemeName(savedThemeName)) {
      setSavedThemeName(systemThemeName) // Default to system preference
    } else if (savedThemeName !== systemThemeName) {
      setThemeName(savedThemeName) // Set manually-specified themeName
    } else {
      setThemeName(getAutoSystemThemeName()) // Set automatic system themeName
    }
  }, [savedThemeName, setThemeName])

  useEffect(() => {
    const onMediaQueryChange = (e: MediaQueryListEvent) => {
      if (savedThemeName !== systemThemeName) return

      const autoSystemThemeName = e.matches
        ? themesConfig.StationaryDark.name
        : themesConfig.StationaryLight.name
      setThemeName(autoSystemThemeName) // Set automatic system themeName
    }
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')
    mediaQueryList.addEventListener('change', onMediaQueryChange)

    return () =>
      mediaQueryList.removeEventListener('change', onMediaQueryChange)
  }, [savedThemeName, setThemeName])

  const isDarkMode = useMemo(
    () =>
      savedThemeName === themesConfig.StationaryDark.name ||
      (savedThemeName === systemThemeName &&
        getAutoSystemThemeName() === themesConfig.StationaryDark.name),
    [savedThemeName],
  )

  const isLightMode = useMemo(
    () =>
      savedThemeName === themesConfig.StationaryLight.name ||
      (savedThemeName === systemThemeName &&
        getAutoSystemThemeName() === themesConfig.StationaryLight.name),
    [savedThemeName],
  )

  return { savedThemeName, setSavedThemeName, isDarkMode, isLightMode }
}

const isValidThemeName = (
  themeName: string,
): themeName is typeof systemThemeName | ThemeName =>
  themeName === systemThemeName ||
  themesConfig[themeName as ThemeName] !== undefined

export const getAutoSystemThemeName = (): ThemeName => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return themesConfig.StationaryDark.name
  }
  return themesConfig.StationaryLight.name
}
