import * as z from 'zod'

import { useLocalStorageDict } from '../hooks/useLocalStorageDict'

export type RecordItemExpandState = {
  isExpanded: boolean
  isMaxHeightInTransition: boolean
}

export type RecordItemExpandStateDict = { [id: string]: RecordItemExpandState }

export type SetRecordItemExpandStateDict = React.Dispatch<
  React.SetStateAction<{
    [key: string]: RecordItemExpandState
  }>
>

const keyPrefix = 'recordItemExpandState'
const recordItemExpandStateSchema = z.object({
  isExpanded: z.boolean(),
  isMaxHeightInTransition: z.boolean(),
})
export const useRecordItemExpandState = (
  defaultValue: RecordItemExpandStateDict = {},
): [RecordItemExpandStateDict, SetRecordItemExpandStateDict] => {
  return useLocalStorageDict(
    keyPrefix,
    recordItemExpandStateSchema,
    defaultValue,
  )
}
