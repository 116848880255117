import React from 'react'

import { styled } from '../../../common/stationary'
import {
  FloatingHelpCenterButtonDimensions,
  FloatingHelpCenterButtonSpacing,
} from '../../FloatingHelpCenterButton'
import { useShortcutsGroup } from '../shortcutsGroups'
import { KeyboardShortcutsGroup } from './KeyboardShortcutsGroup'

export const KeyboardShortcutsList: React.FC = () => {
  const shortcutGroups = useShortcutsGroup()

  return (
    <S.List>
      {shortcutGroups.map((group) => (
        <KeyboardShortcutsGroup key={group.title} group={group} />
      ))}
    </S.List>
  )
}

const S = {
  List: styled.div({
    display: `flex`,
    flex: 1,
    width: `100%`,
    paddingLeft: 20,
    flexDirection: `column`,
    overflowY: `scroll`,
    boxSizing: `border-box`,
    '&::-webkit-scrollbar': {
      display: `none`,
    },
    paddingBottom:
      FloatingHelpCenterButtonDimensions.height +
      FloatingHelpCenterButtonSpacing.bottom * 2,
  }),
  Empty: styled.div({
    display: `flex`,
    flex: `0 0 56px`,
    width: `100%`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
  }),
  Text: styled.div(({ theme }) => ({
    display: `flex`,
    ...theme.text.publicSans['13.5:20'],
    fontWeight: 400,
    color: theme.colors.text[700],
  })),
  Query: styled.span(({ theme }) => ({
    lineHeight: `20px`,
    background: theme.colors.alpha.tone.mediumStrong,
    color: theme.colors.text[700],
    borderRadius: 4,
    padding: `0 6px`,
    margin: `0 3px`,
  })),
}
