import React from 'react'
import { isMobile } from '../../../common/utils/env'

import lockIconSrc from '../../../assets/onboarding/lock-icon.png'
import { styled } from '../../../common/stationary'
import { useAuth } from '../../../providers'
import { AuthStyles as S } from '../AuthStyles'
import AuthLogo from './AuthLogo'
import { PASSWORD_RESET, PASSWORD_SET } from '../../../common/utils/uiConstants'

interface ResetPasswordEmailSentMessageProps {
  resetLoginState?: () => void
  showAlternativeLoginMethod: boolean
  handleMagicLinkLoginStart?: () => void
}

const ResetPasswordEmailSentMessage: React.FC<
  ResetPasswordEmailSentMessageProps
> = ({
  resetLoginState,
  showAlternativeLoginMethod,
  handleMagicLinkLoginStart,
}) => {
  const { isUserWithPassword } = useAuth()
  return (
    <S.AuthRelatedMessage>
      <S.AuthHeader>
        <AuthLogo
          href={!isMobile ? '/' : undefined}
          secondaryIconSrc={lockIconSrc}
        />
        <AuthRelatedStyles.AuthTitle>
          <strong>
            We've sent you a link to{' '}
            {isUserWithPassword
              ? PASSWORD_RESET.toLowerCase()
              : PASSWORD_SET.toLowerCase()}{' '}
            your password
          </strong>
        </AuthRelatedStyles.AuthTitle>
      </S.AuthHeader>
      <AuthRelatedStyles.AuthBody>
        <S.ActionHint>
          <strong>Click the link in your inbox </strong> to set your{' '}
          {isUserWithPassword ? '' : 'new'} password and login.
        </S.ActionHint>
        <S.CheckSpamHint>
          <i>Can’t find the email? Check your spam folder!</i>
        </S.CheckSpamHint>
        {resetLoginState && (
          <AuthRelatedStyles.GoToLogin
            onClick={() => {
              resetLoginState()
            }}
          >
            Back to log in page
          </AuthRelatedStyles.GoToLogin>
        )}
        {showAlternativeLoginMethod && (
          <S.AlternativeLoginMethodMessage
            onClick={() => {
              handleMagicLinkLoginStart && handleMagicLinkLoginStart()
            }}
          >
            Log in without password
          </S.AlternativeLoginMethodMessage>
        )}
      </AuthRelatedStyles.AuthBody>
    </S.AuthRelatedMessage>
  )
}

export default ResetPasswordEmailSentMessage

const AuthRelatedStyles = {
  GoToLogin: styled(S.GoToLogin)(() => ({
    marginTop: 32,
    marginBottom: 0,
  })),
  ActionHint: styled(S.ActionHint)(() => ({
    marginTop: 8,
  })),
  AuthTitle: styled(S.AuthTitle)(() => ({
    marginTop: 8,
  })),
  AuthBody: styled(S.AuthBody)(() => ({
    marginTop: 4,
  })),
}
